import { Component, OnInit, Input, OnDestroy, Renderer2 } from '@angular/core';
import { DBService } from '../service/db.service';
import { Router, ActivatedRoute } from "@angular/router";
import { Integer } from 'aws-sdk/clients/comprehend';
import { ToastrService } from 'ngx-toastr';
import { UserLoginService } from "../service/user-login.service";
import { LoggedInCallback, CognitoUtil } from "../service/cognito.service";
import { DecimalPipe } from '@angular/common';
import { EmbedVideoService } from 'ngx-embed-video';
import {Options} from 'sortablejs';

export class workout {
  trainerId: string;
  workoutId: string;
  workoutName: Object;
  customWorkoutName: string;
  workoutExercises: Object[];
  warnings: string;
  workoutStatus: string;
  workoutPercentage: string;
  workoutComments: Object[];
  isChangeAffects: boolean;
  // isPublic:boolean;
}

@Component({
  selector: 'app-addworkout',
  templateUrl: './addworkout.component.html',
  styleUrls: ['./addworkout.component.css']
})

export class AddworkoutComponent implements OnInit, OnDestroy, LoggedInCallback {
  public showVideoPopup: boolean = false;
  public isUpdateWorkout: boolean = false;
  isWorkoutName = true;
  currentEmail: string;
  addWorkoutFunc: boolean = false;
  addWorkout: boolean = true;
  @Input() viewURL: string = "";
  iframeHidden: boolean = false;
  videoHidden: boolean = false;
  showExerciseRejectPopUp: boolean = false;
  workoutName: string = "";
  workoutImg: string;

  iframeURL: any;
  embedYoutubeUrl: string;
  //userId: string;
  exercises: object[];
  droppedItems: object[];
  workoutNames: object[];
  gender: object[];
  id: Integer;
  options: Options = {
    group: 'test'
  };

  constructor(private renderer: Renderer2, private db_service: DBService, public router: Router, public route: ActivatedRoute, private toastr: ToastrService,
    public userService: UserLoginService, private embedService: EmbedVideoService, public cognitoUtil: CognitoUtil) {
    this.userService.isAuthenticated(this);
  }
  workout: workout;
  dropdownSettings_single: any = {};
  closeDropdownSelection = false;
  SelectedWorkoutNames: [];
  selectedGender: Object[];
  currentCategory: string;
  currentGender: string;
  workoutMasters: Object[];

  // Google Var
  private loggedIn: boolean;

  ngOnInit() {
    this.workout = new workout();
    this.userService.currentUserID.subscribe((val) => {
      this.workout.trainerId = val;
    });
    this.currentCategory = localStorage.getItem("ProfileCategory")
    this.currentGender = localStorage.getItem("ProfileGender");
    if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-build-male');
    }
    else if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-build-female');
    }
    else if (this.currentCategory == 'Yoga' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-yoga-male');
    }
    else if (this.currentCategory == 'Yoga' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-yoga-female');
    }
    else if (this.currentCategory == 'Zumba' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-zumba-male');
    }
    else if (this.currentCategory == 'Zumba' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-zumba-female');
    }
    // this.workout.userId = "naresh_1e25dff44asds4654sdsd4";

    // this.getExerciseDataFromServer();

    this.dropdownSettings_single = {
      singleSelection: true,
      enableCheckAll: false,
      idField: 'id',
      textField: 'name',
      allowSearchFilter: true,
      itemsShowLimit: 1,
      closeDropDownOnSelection: this.closeDropdownSelection
    };
    this.getAllMasters();
    this.gender = [
      { "id": "0", "name": "Male" },
      { "id": "1", "name": "Female" }
    ]
  }
  // SaveWorkout() {
  //   this.addWorkoutFunc = true;
  //   this.addWorkout = false;
  // }
  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'bg-img-build-male');
    this.renderer.removeClass(document.body, 'bg-img-yoga-male');
    this.renderer.removeClass(document.body, 'bg-img-zumba-male');
    this.renderer.removeClass(document.body, 'bg-img-build-female');
    this.renderer.removeClass(document.body, 'bg-img-yoga-female');
    this.renderer.removeClass(document.body, 'bg-img-zumba-female');
    this.renderer.addClass(document.body, 'body-main');
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/login']);
    } else {
      // if()
      if (this.loggedIn == true) {
        this.currentEmail = localStorage.getItem('lastAuthUser');
        // if(this.currentEmail != undefined)
        this.getProfileDataFromServer();
      }
      else {
        // this.currentEmail = localStorage.getItem('CognitoIdentityServiceProvider.2io8v4r253hugt5ndbjv9shkfp.LastAuthUser');
        this.currentEmail = this.cognitoUtil.getCurrentUser().getUsername();
        if(this.currentEmail == undefined || this.currentEmail == '' || this.currentEmail == null || this.currentEmail.includes("Google") || this.currentEmail.includes("google"))
        {
          this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
          if(this.currentEmail == undefined)
          this.currentEmail = this.cognitoUtil.session.idToken.payload.email;
        }
        // this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
        this.getProfileDataFromServer();
      }
    }
  }
  getProfileDataFromServer() {
    var index = '/profile/';
    this.db_service.getDataFromServer(this.currentEmail, index).subscribe(
      response => {
        //console.log('Data Retrived succesfully.', response);
        response = response.data[0];
        this.workout.trainerId = response.trainerId;
        //this.getExerciseDataFromServer();

      },
      error => {
        // alert("An error has occurred while retriving profile data.");
        this.toastr.error('', 'An error has occurred while retriving profile data', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      }
    )
  }
  onItemSelect(event, type) {
    // if (event.name == "Others" && type == "workoutName") {
    //   this.isWorkoutName = true;
    // } else {
    //   this.isWorkoutName = false;
    // }
    this.workoutName = event.name;
    //this.workoutImg = "assets/workoutImgs/" + event.name + ".png";
    const work_out = this.workoutMasters.find(x => x["id"] == event.id);
    this.workoutImg = work_out["imgUrl"];
  }
  onItemDeselect(event, type) {
    // if (event.name == "Others" && type == "workoutName") {
    //   this.isWorkoutName = false;
    // }
    this.workoutImg = "";
    this.workoutName = "";
  }
  onItemDrop(e: DragEvent) {
    for (let i = 0; i < this.exercises.length; i++) {
      if (e["data"] == this.exercises[i]["exerciseId"]) {
        var exercise = this.exercises[i];
        // exercise["exerciseName"] = this.exercises[i]["exerciseName"];
        // exercise["setsVal"] = this.exercises[i]["sets"].length;
        // // exercise["repsVal"] = this.exercises[i]["repsVal"];
        // // exercise["restPeriodVal"] = this.exercises[i]["restPeriodVal"];
        // exercise["exerciseId"] = this.exercises[i]["exerciseId"];
        // exercise["thumbnailDestination"] = this.exercises[i]["exerciseVideo"]["videoThumbnailPath"];

        if (this.droppedItems == undefined) {
          // this.id = 1;
          exercise["referenceId"] = 1;
          this.droppedItems = Array.of(exercise);
        } else {
          // this.id = this.droppedItems.length + 1;
          exercise["referenceId"] = this.droppedItems.length + 1;
          this.droppedItems.push(JSON.parse(JSON.stringify(exercise)));
        }
      }
    }
  }
  openVideo(exerciseId) {
    for (let i = 0; i < this.exercises.length; i++) {
      if (exerciseId == this.exercises[i]["exerciseId"]) {
        this.viewURL = this.exercises[i]["exerciseVideo"]["exerciseVideoSource"];
        this.showVideoPopup = !this.showVideoPopup;

        if (this.exercises[i]["exerciseVideo"]["youtubeUrl"] == "") {
          this.iframeHidden = false;
          this.videoHidden = true;
        } else {
          this.iframeHidden = true;
          this.videoHidden = false;
          this.iframeURL = this.embedService.embed(this.viewURL, {
            query: { portrait: 0, color: 'black', autoplay: 1 },
            attr: { width: "100%", height: 450, frameborder: 0, allow: "autoplay" }
          })
          if (this.iframeURL != undefined)
            this.embedYoutubeUrl = this.iframeURL;
        }
        break;
      }

    }

  }
  closePopup() {
    this.showVideoPopup = false;
    this.showExerciseRejectPopUp = false;
  }
  getAllMasters() {
    var index = '/masters/';
    this.db_service.getDataFromServer('workout', index).subscribe(
      response => {
        //console.log('Data Retrived succesfully.', response);
        for (let i = 0; i < response.length; i++) {
          if (response[i]['name'] == "Custom_WorkoutNames") {
            this.workoutNames = response[i]['values'];
            this.workoutMasters = response[i]['values'];
          }
        }
      },
      error => {
        console.log('An error has occurred while retriving masters data.');
      }
    )
  }
  addRest() {
    var exercise = {};
    exercise["exerciseName"] = "Rest";
    exercise["exerciseId"] = "rest_id";
    exercise["restPeriodVal"] = 60;
    // exercise["workoutType"] = {};
    // exercise["mechanicalType"] = {};
    // exercise["mainMuscleWorkout"] = {};
    // exercise["otherMuscleWorkout"] = [];
    // exercise["level"] = {};
    // exercise["sport"] = {};
    // exercise["equipment"] = {};
    // exercise["force"] = {};
    // exercise["sets"] = [];
    // exercise["exerciseVideo"] = {};
    // exercise["instructions"] = "";
    // exercise["trainerId"] = this.workout.trainerId;
    // exercise["creationDate"] = new Date();
    // exercise["lastUpdatedOn"] = new Date();
    // exercise["exerciseStatus"] = "new";
    // exercise["exercisePercentage"] = "0";
    // exercise["exerciseComments"] = [];

    // exercise["repsVal"] = null;
    // exercise["restPeriodVal"] = 60;
    // exercise["thumbnailDestination"] = null;
    if (this.droppedItems == undefined) {
      this.toastr.error('', 'Plese add atleast one exercise before adding rest.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    } else if (this.droppedItems.length == 0) {
      this.toastr.error('', 'Plese add atleast one exercise before adding rest.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    } else {
      //this.id = this.id + 1;
      exercise["referenceId"] = this.droppedItems.length + 1;
      this.droppedItems.push(JSON.parse(JSON.stringify(exercise)));
    }
  }
  RemoveExercise(id) {
    const index = this.droppedItems.findIndex(droppedItem => droppedItem["referenceId"] == id);
    this.droppedItems.splice(index, 1);
  }
  SaveWorkout() {
    var atleastOneAlpha;
    if (this.workout.customWorkoutName != undefined) {
      atleastOneAlpha = this.workout.customWorkoutName.match(".*[a-zA-Z]+.*");
    }
    if (this.workoutName == "") {
      this.toastr.error('', 'Mandatory fields are required', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (this.workout.customWorkoutName == null) {
      this.toastr.error('', 'Mandatory fields are required', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    } else if (this.workoutName == "Others" && atleastOneAlpha == null) {
      this.toastr.error('', 'Workout name should contain atleast one character.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    } else {
      var a = 0;
      const work_out = this.workoutMasters.find(x => x["id"] == this.SelectedWorkoutNames[a]["id"]);
      this.workout.workoutName = work_out;
      // this.workout.gender = this.selectedGender[0];
      if (this.droppedItems != undefined) {
        if (this.droppedItems.length == 0) {
          this.toastr.error('', 'Plese add atleast one exercise to this workout.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
          return;
        }
        if (this.droppedItems[0]["exerciseId"] == "rest_id") {
          this.toastr.error('', 'Cannot congfigure a workout which is starting with rest.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
          return;
        }

      } else if (this.workout.workoutId != undefined && this.droppedItems == undefined) {
        this.toastr.error('', 'Plese add atleast one exercise to this workout.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        return;
      }
      // if (this.workout.workoutId == undefined) {
      //   this.workout.workoutId = this.workout.trainerId.split("-")[0] + JSON.stringify(Math.floor(100000 + Math.random() * 900000));
      // }

      this.workout.workoutExercises = this.droppedItems;
      if (this.droppedItems != undefined) {
        for (var i = 0; i < this.workout.workoutExercises.length; i++) {
          this.workout.workoutExercises[i]["referenceId"] = i + 1;
        }
      }
      if (this.addWorkout != true) {
        for (var i = 0; i < this.droppedItems.length; i++) {
          if (this.droppedItems[i]["exerciseId"] == "rest_id" && (this.droppedItems[i]["restPeriodVal"] <= 0 || this.droppedItems[i]["restPeriodVal"] > 180)) {
            this.toastr.error('', 'Rest period value should be in the range of 1 to 180.', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
              progressBar: true,
              progressAnimation: 'increasing',
              tapToDismiss: true,
            });
            return;
          }
        }
      }

      var index = '/workout';
      if (this.workout.workoutId == undefined) {
        this.workout.workoutStatus = "new";
        this.workout.workoutPercentage = "0";
        this.workout.workoutComments = [];
        this.db_service.saveDataToDB(this.workout, index).subscribe(
          response => {
            //console.log(response.data);
            this.workout.workoutId = response.data;
            //alert('Workout saved successfully.');
            this.toastr.success('', 'Workout saved successfully.', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
              progressBar: true,
              progressAnimation: 'increasing',
              tapToDismiss: true,
            });
            if (this.addWorkout == false) {
              this.router.navigate(['workout']);
            } else {
              this.addWorkoutFunc = true;
              this.addWorkout = false;
              //this.getExerciseDataFromServer();
            }
          },
          error => {
            //alert('there is an error while saving workout');

            this.toastr.error('', 'There is an error while saving workout', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
              progressBar: true,
              progressAnimation: 'increasing',
              tapToDismiss: true,
            });

          }
        )
      } else {

        if (this.addWorkout == false) {
          var workoutRejectCheck = 0;
          for (var i = 0; i < this.workout.workoutExercises.length; i++) {
            if (this.workout.workoutExercises[i]["adminStatus"] == "Rejected") {
              workoutRejectCheck = 1;
              break;
            }
          }
          if (workoutRejectCheck == 0) {

            var index = '/workout/' + this.workout.trainerId + '/' + this.workout.workoutId + '/status';
            this.db_service.getDataFromServer("", index).subscribe(response => {
              if (response.data == true) {
                this.isUpdateWorkout = true;
              } else {
                this.onUpdateWorkout("No");
              }
            })

          } else {
            this.showExerciseRejectPopUp = true;
            return;
          }
        } else {
          this.onUpdateWorkout("No");
        }


      }
    }
  }
  onUpdateWorkout(value) {
    var index = '/workout';
    if (value == "Yes")
      this.workout.isChangeAffects = true;
    else
      this.workout.isChangeAffects = false;
    this.db_service.updateDatainDB(this.workout, index).subscribe(
      response => {
        //alert('Workout saved successfully.');
        this.toastr.success('', 'Workout saved successfully.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        this.workout.isChangeAffects = false;
        if (this.addWorkout == false) {
          this.router.navigate(['workout']);
        } else {
          this.addWorkoutFunc = true;
          this.addWorkout = false;
        }
      },
      error => {
        //alert('there is an error while saving workout');

        this.toastr.error('', 'There is an error while saving workout', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });

      }
    )

  }
  back(page) {
    // if (this.addWorkout == true) {
    if (page == 'page2') {
      if (this.workout.workoutExercises == undefined) {
        this.toastr.error('', 'Please add exercises to workout.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      } else if (this.workout.workoutExercises.length == 0) {
        this.toastr.error('', 'Please add exercises to workout.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      }
    }

    this.router.navigate(['/workout']);
    // } else {
    //   this.addWorkoutFunc = false;
    //   this.addWorkout = true;
    // }
  }
  getExerciseDataFromServer() {
    var atleastOneAlpha;
    if (this.workout.customWorkoutName != undefined) {
      atleastOneAlpha = this.workout.customWorkoutName.match(".*[a-zA-Z]+.*");
    }
    if (this.workoutName == "") {
      this.toastr.error('', 'Mandatory fields are required', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (this.workout.customWorkoutName == null) {
      this.toastr.error('', 'Mandatory fields are required', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    } else if (this.workoutName == "Others" && atleastOneAlpha == null) {
      this.toastr.error('', 'Workout name should contain atleast one character.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    } else {
      var a = 0;
      const work_out = this.workoutMasters.find(x => x["id"] == this.SelectedWorkoutNames[a]["id"]);
      this.workout.workoutName = work_out;
      var index = '/exercise/' + this.workout.trainerId + '/';
      this.db_service.getDataFromServer(this.workout.workoutName["name"], index).subscribe(
        response => {
          // console.log('Data Retrived succesfully.', response);
          //response = Array.of(response);
          this.exercises = response.data;
          if (this.exercises.length > 0) {
            this.SaveWorkout();
          } else {
            this.toastr.error('', 'There are no exercises associated with this workout name.', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
              progressBar: true,
              progressAnimation: 'increasing',
              tapToDismiss: true,
            });
          }
        },
        error => {
          // alert("An error has occurred while retriving data.");
          this.toastr.error('', 'An error has occurred while retriving data.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        }
      )
    }
  }
  addCustomExercise() {
    this.router.navigate(['addexercise']);
  }
}
