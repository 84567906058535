import { Injectable } from '@angular/core';
import S3 from 'aws-sdk/clients/s3';
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { AppGlobals } from "../app.globals";
import { Lambda } from 'aws-sdk/clients/all';
import { String } from 'aws-sdk/clients/cloudtrail';

import { Callback, CognitoUtil } from "./cognito.service";
import * as AWS from "aws-sdk/global";

//import 'rxjs/add/observable/of';
// import { Observable } from "rxjs/Observable";

@Injectable()
export class AWSService {
  //sourceUrl:string;
  response = {
    sourceUrl: '',
    mp4Destination: '',
    thumbnailDestination: '',
    assetId: ''
  }
  payLoad: any;
  payLoadObj: any;
  body: any;
  Job: any;
  Settings: any;
  OutputGroups: object[];
  ObjOutputGroups;
  OutputGroupSettings;
  FileGroupSettings;
  Destination: String;

  constructor(private HttpClient: HttpClient, private globals: AppGlobals) { }

  uploadFileToS3(file, id, bucketName, key, filetype): Observable<any> {

    const bucket = new S3(
      {
        accessKeyId: this.globals.accessKeyId,
        secretAccessKey: this.globals.secretAccessKey,
        region: this.globals.region
      }
    );
    let extn = file.name.split('.').pop();
    let contentType = 'application/octet-stream';
    if (extn == 'html') contentType = "text/html";
    if (extn == 'css') contentType = "text/css";
    if (extn == 'js') contentType = "application/javascript";
    if (extn == 'pdf') contentType = "application/pdf";
    if (extn == 'mp4') contentType = "video/mp4";
    if (extn == 'png' || extn == 'jpg' || extn == 'gif') contentType = "image/" + extn;
    var options = { partSize: 100 * 1024 * 1024, queueSize: 3 };
    var fileName = file.name.replace(/ /g, "_");
    const params = {
      Bucket: bucketName,
      Key: key + id + "/" + fileName,
      Body: file,
      ContentType: contentType
    };
    var Obj = this;
    return Observable.create(observer => {
      bucket.upload(params, options).on('httpUploadProgress', function (evt) {
        console.log("Uploaded :: " + ((evt.loaded * 100) / evt.total) + '%');
      }).send(function (err, data) {
        if (err) {
          console.log(err);
          return observer.next(err);

        }
        if (data) {

          if (filetype == 'video' || filetype == 'yoga_video') {
            Obj.response.sourceUrl = data.Location;
            var lambdainput = {
              key: '',
              bucket: '',
              location: '',
              awsRegion: ''
            }
            lambdainput.key = data.Key;
            lambdainput.bucket = data.Bucket;
            lambdainput.location = data.Location;
            lambdainput.awsRegion = 'us-west-2';
            var lambda = new Lambda(
              {
                accessKeyId: Obj.globals.accessKeyId,
                secretAccessKey: Obj.globals.secretAccessKey,
                region: Obj.globals.region
              }
            );
            var params1 = {
              FunctionName: Obj.globals.LAMBDA_FUNCTION, /* required */
              Payload: JSON.stringify(lambdainput)
            };
            lambda.invoke(params1, function (err, response) {
              if (err) console.log(err, err.stack); // an error occurred
              else {


                Obj.payLoadObj = response.Payload;
                Obj.payLoad = JSON.parse(Obj.payLoadObj);
                Obj.body = JSON.parse(Obj.payLoad["body"]);
                Obj.Job = Obj.body["Job"];
                Obj.Settings = Obj.Job["Settings"];
                Obj.OutputGroups = Obj.Settings["OutputGroups"];
                Obj.ObjOutputGroups = Obj.OutputGroups[1];
                Obj.OutputGroupSettings = Obj.ObjOutputGroups["OutputGroupSettings"];
                Obj.FileGroupSettings = Obj.OutputGroupSettings["FileGroupSettings"];
                Obj.Destination = Obj.FileGroupSettings["Destination"];

                var dest = Obj.Destination.split("/");

                Obj.response.mp4Destination = Obj.globals.CLOUDFRONT_URL + dest[3] + "/" + dest[4] + "/" + dest[5] + "/" + dest[6] + "/" + dest[7] + "/" + dest[8] + ".mp4";
                Obj.response.thumbnailDestination = Obj.globals.CLOUDFRONT_URL + dest[3] + "/" + dest[4] + "/" + dest[5] + "/" + dest[6] + "/Thumbnails/" + dest[8] + ".0000003" + ".jpg";
                Obj.response.assetId = dest[6];
                return observer.next(Obj.response);
              }         // successful response
            });
          } else {
            return observer.next(data);
          }

        }

      })
    })
  }
  deleteDestinationFolder(id, bucketName, key, assetId): Observable<any> {
    const bucket = new S3(
      {
        accessKeyId: this.globals.accessKeyId,
        secretAccessKey: this.globals.secretAccessKey,
        region: this.globals.region
      }
    );
    var params = {
      Bucket: bucketName,
      Prefix: key + id + "/" + 'assets' + "/" + assetId
    };
    return Observable.create(observer => {
      bucket.listObjects(params, function (err, data) {

        //params.Delete = {Objects:[]};
        var params1 = {
          Bucket: bucketName,
          Delete: { Objects: [] }
        };
        data.Contents.forEach(function (content) {
          params1.Delete.Objects.push({ Key: content.Key });
        });

        bucket.deleteObjects(params1, function (err, data) {
          return observer.next(data);
          //console.log("deleted succeesFully....."+data);
        });
      });
    })

  }
  deleteFileFromS3(fileName, id, bucketName, key): Observable<any> {

    const bucket = new S3(
      {
        accessKeyId: this.globals.accessKeyId,
        secretAccessKey: this.globals.secretAccessKey,
        region: this.globals.region
      }
    );
    const params = {
      Bucket: bucketName,
      Key: key + id + "/" + fileName
    };
    return Observable.create(observer => {
      bucket.deleteObject(params, function (err, data) {
        if (err) {
          return observer.next(err);
        } else {
          return observer.next(data);
        }

      })
    })

  }
  checkUrl(bucketName, key): Observable<any> {
    const bucket = new S3(
      {
        accessKeyId: this.globals.accessKeyId,
        secretAccessKey: this.globals.secretAccessKey,
        region: this.globals.region
      }
    );
    var params = {
      Bucket: bucketName,
      Prefix: key
    };
    return Observable.create(observer => {
      bucket.listObjects(params, function (err, data) {
        if (err) {
          return observer.next(err);
        } else {
          if (data.Contents.length == 0) {
            return observer.next(false);
          } else {
            return observer.next(true);
          }

        }
      })
    })
  }
}

@Injectable()
export class AwsUtil {
  public static firstLogin: boolean = false;
  public static runningInit: boolean = false;

  constructor(public cognitoUtil: CognitoUtil) {
    // AWS.config.region = CognitoUtil._REGION;
    AWS.config.region = CognitoUtil._REGION;
    AWS.config.update({
      region: CognitoUtil._REGION,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: ''
      })
    });
  }

  /**
   * This is the method that needs to be called in order to init the aws global creds
   */
  initAwsService(callback: Callback, isLoggedIn: boolean, idToken: string) {

    if (AwsUtil.runningInit) {
      // Need to make sure I don't get into an infinite loop here, so need to exit if this method is running already
      //console.log("AwsUtil: Aborting running initAwsService()...it's running already.");
      // instead of aborting here, it's best to put a timer
      if (callback != null) {
        callback.callback();
        callback.callbackWithParam(null);
      }
      return;
    }


    // console.log("AwsUtil: Running initAwsService()");
    AwsUtil.runningInit = true;


    let mythis = this;
    // First check if the user is authenticated already
    if (isLoggedIn)
      mythis.setupAWS(isLoggedIn, callback, idToken);

  }


  /**
   * Sets up the AWS global params
   *
   * @param isLoggedIn
   * @param callback
   */
  setupAWS(isLoggedIn: boolean, callback: Callback, idToken: string): void {
    //console.log("AwsUtil: in setupAWS()");
    if (isLoggedIn) {
      //console.log("AwsUtil: User is logged in");
      // Setup mobile analytics
      var options = {
        appId: 'k7oibe9calcf9hf7fubq1msp5',
        appTitle: "trainerAppApi"
      };

      // TODO: The mobile Analytics client needs some work to handle Typescript. Disabling for the time being.
      // var mobileAnalyticsClient = new AMA.Manager(options);
      // mobileAnalyticsClient.submitEvents();

      this.addCognitoCredentials(idToken);

      //console.log("AwsUtil: Retrieving the id token");

    }
    else {
      console.log("AwsUtil: User is not logged in");
    }

    if (callback != null) {
      callback.callback();
      callback.callbackWithParam(null);
    }

    AwsUtil.runningInit = false;
  }

  addCognitoCredentials(idTokenJwt: string): void {
    let creds = this.cognitoUtil.buildCognitoCreds(idTokenJwt);

    AWS.config.credentials = creds;

    creds.get(function (err) {
      if (!err) {
        if (AwsUtil.firstLogin) {
          // save the login info to DDB
          this.ddb.writeLogEntry("login");
          AwsUtil.firstLogin = false;
        }
      }
    });
  }

  static getCognitoParametersForIdConsolidation(idTokenJwt: string): {} {
    //console.log("AwsUtil: enter getCognitoParametersForIdConsolidation()");
    let url = 'cognito-idp.' + CognitoUtil._REGION.toLowerCase() + '.amazonaws.com/' + CognitoUtil._USER_POOL_ID;
    let logins: Array<string> = [];
    logins[url] = idTokenJwt;
    let params = {
      IdentityPoolId: CognitoUtil._IDENTITY_POOL_ID, /* required */
      Logins: logins
    };

    return params;
  }

}
