<div class="container-fluid mb-2" style="text-align: right;">
  <button type="button" class="btn btn-default save-exercise-btn"
    routerLink="/trainee/{{program_id}}">BACK</button>
</div>

<section class="mt-100 mb-40">

  <div class="container">
    <div class="col-md-6 mx-auto mb-5 text-center">
      <h3 class="trainee-list-page-main-heading1" style="font-weight:700;">Trainee Details</h3>
    </div>


    <div class="row justify-content-md-center">

      <div class="category-items">
        <a routerLink="/traineedetails/{{program_id}}/{{trainee_id}}">
          <img src="{{traineeImage}}"
            style="border-radius: 89%; border: 7px solid #8bc53f; height: 193px; width: 86%;" *ngIf="traineeImage != undefined"/>
            <img src="assets/images/default_profile_img.png"
            style="border-radius: 89%; border: 7px solid #8bc53f; height: 193px; width: 86%;" *ngIf="traineeImage == undefined"/>
          <h4>Profile</h4>
        </a>

        <a (click)="onCalendarClick()">
          <img src="assets/images/dashboard/calendar.png"
            style="border-radius: 89%; border: 7px solid #8bc53f; height: 193px; width: 86%;" />
          <h4>Calendar</h4>
        </a>

        <a routerLink="/traineeGallery/{{program_id}}/{{trainee_id}}">
          <img src="assets/images/dashboard/progress_images.png"
            style="border-radius: 89%; border: 7px solid #8bc53f; height: 193px; width: 86%;" />
          <h4>Progress photos</h4>
        </a>

        <a routerLink="/traineeReports/{{program_id}}/{{trainee_id}}">
          <img src="assets/images/dashboard/reports.png"
            style="border-radius: 89%; border: 7px solid #8bc53f; height: 193px; width: 86%;" />
          <h4>Reports</h4>
        </a>
      </div>

    </div>
    <!-- isRejectedProfile -->
    <ng-container *ngIf="isRejectedProfile">
      <div class="program_event-popup__wrapper">
        <div class="program_event-pop zoomIn background-shadow-green" style="width:40% !important;">
          <div class="program_event-header">
            <h4>Your profile has rejected, please update your profile.</h4>
          </div>
          <a (click)="closePopup()" class="close-program_event-pop"><i class="fas fa-times"></i></a>
          <div class="program_event-body d-flex justify-content-center">

            <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
              (click)="closePopup()">Cancel</button>

            <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
              (click)="onUpdateProfile()">Update</button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</section>
