<section>
  <div class="container">
    <div class="row">
      <!-- Exercise Search box -->

      <div class="col-md-8 text-center m-auto search__box-locations">
        <!-- <h1 class="heading1">Search for a Trainer/Location</h1> -->
        <div class="search__box d-flex">
          <input
            type="text"
            autocomplete="off"
            name="search"
            placeholder="Search for a Trainer/Location"
            (ngModelChange)="modelChanged()"
            [(ngModel)]="searchTrainer"
          />
          <a
            class="search__box__btn search__box__btn-exercise-open"
            style="cursor: pointer"
          >
            <i class="fas fa-search"></i>
          </a>
          <!-- <ng-multiselect-dropdown style="width:18%; background: #e8e8e8; padding-left: 10px; color: #333; border-radius: 0 25px 25px 0;"
                                            name="city"
                                            [data]="cities"
                                            [settings]="dropdownSettings"
                                            [disabled]="disabled"
                                            [(ngModel)]="SelectedCity">
                                        </ng-multiselect-dropdown> -->
        </div>
      </div>

      <!-- Exercise Search box /-->
    </div>
  </div>
</section>
<section>
  <div class="container-fluid">
    <div class="col">
      <!-- <div class="row"> -->
      <!-- Exercise Video -->

      <!-- *ngIf="searchTrainer == null" -->
      <div class="videos videos-exercise row" *ngIf="showLocDB">
        <div
          class="col-sm-6 col-md-4 col-lg-3 col-xl-2 exercise-video-div-grid"
          style="margin-bottom: 30px"
          *ngFor="let trainer of trainersDataLoctn"
        >
          <div class="video_wrapper">
            <div class="video_file">
              <a>
                <video
                  (click)="openVideo(trainer.mp4Destination,template)"
                  onloadedmetadata="this.muted=true"
                  muted=""
                  loop=""
                  playsinline=""
                  onmouseover="this.play()"
                  onmouseout="this.load();"
                  poster="{{ trainer.thumbnailDestination }}"
                  style="width: 100%"
                >
                  <source _ngcontent-c3="" src="{{ trainer.mp4Destination }}" />
                </video>
              </a>
            </div>
            <div class="video__title">
              <h3>{{ trainer.firstName }} {{ trainer.lastName }}</h3>
              <!-- <i class="fa fa-trash" (click)="deleteExercise(ex.exerciseId)"></i> -->
              <!-- <div class="edit_links">
                                                          <a (click)="editExercise(ex.exerciseId)"><i class="fas fa-pencil-alt"></i></a>
                                                          <a (click)="deleteCheck(ex.exerciseId)"><i class="fas fa-trash-alt"></i></a>
                                                        </div>  -->
            </div>
          </div>
        </div>
      </div>
      <div
        class="search-results"
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        (scrolled)="onScroll()"
      >
        <div class="videos videos-exercise row" *ngIf="showOvrDb">
          <!-- *ngIf="searchTrainer != null"-->
          <div
            class="col-sm-6 col-md-4 col-lg-3 col-xl-2 exercise-video-div-grid"
            style="margin-bottom: 30px"
            *ngFor="let trainer of trainersData | filter: searchTrainer"
          >
            <div class="video_wrapper">
              <div class="video_file">
                <a>
                  <video
                    (click)="
                      openVideo(trainer.profileIntroVideo.videoMp4Destination,template)
                    "
                    onloadedmetadata="this.muted=true"
                    data-toggle="modal"
                    data-target="#contentModal"
                    onloadedmetadata="this.muted=true"
                    muted=""
                    loop=""
                    playsinline=""
                    onmouseover="this.play()"
                    onmouseout="this.load();"
                    poster="{{ trainer.profileIntroVideo.videoThumbnailPath }}"
                    style="width: 100%"
                  >
                    <source
                      _ngcontent-c3=""
                      src="{{ trainer.profileIntroVideo.videoMp4Destination }}"
                    />
                  </video>
                </a>
              </div>
              <div class="video__title">
                <h3>{{ trainer.firstName }} {{ trainer.lastName }}</h3>
                <!-- <i class="fa fa-trash" (click)="deleteExercise(ex.exerciseId)"></i> -->
                <!-- <div class="edit_links">
                                                                <a (click)="editExercise(ex.exerciseId)"><i class="fas fa-pencil-alt"></i></a>
                                                                <a (click)="deleteCheck(ex.exerciseId)"><i class="fas fa-trash-alt"></i></a>
                                                              </div>  -->
              </div>
            </div>
          </div>
          <!-- <div *ngIf="trainersData.length == 0">
                                <span>No trainers found with the name.</span>
                             </div> -->
        </div>
      </div>
      <div style="float: right; color: #8dc63f;">Powerd By <a href="https://vsaastechnologies.com/" target="_blank" style="color: #8dc63f;"> Vsaas Technologies Pvt Ltd</a></div>

      <!-- Exercise Video /-->

      <ng-container *ngIf="hideMenu" >
        <div class="program_links_list">
          <a href="calendar.html">Calendar</a>
          <a href="#">Nutrition</a>
          <a href="#">Workout</a>
        </div>
      </ng-container>
    </div>
    <!-- <ng-container *ngIf="showVideoPopup"  (clickOutside)="onClickedOutside()">
      <div
        class="modal fade"
        id="contentModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style="background: #000000ba"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div
              class="video-pop zoomIn modal-body background-shadow-green"
              style="border-radius: 12px"
            >
              <a
                (click)="closePopup()"
                class="close-video-pop float-right"
                style="cursor: pointer"
              >
                <i class="fas fa-times"></i>
              </a>
              <video
                autoplay=""
                
                loop=""
                muted=""
                playsinline=""
                controls="controls"
                style="width: 100%"
              >
                <source attr.src="{{ videoUrl }}" type="video/mp4" />
              </video>
             </div>
          </div>
        </div>
      </div>
    </ng-container> -->
    <!-- </div> -->
    <ng-template #template>
      <!-- <div class="modal-header">
        <h4 class="modal-title pull-left">Modal</h4>

      </div> -->

      <div class="modal-body">
        <div
              class="video-pop zoomIn modal-body background-shadow-green"
              style="border-radius: 12px"
            >
              <!-- <a
                (click)="closePopup()"
                class="close-video-pop float-right"
                style="cursor: pointer"
              > -->
                <!-- <i class="fas fa-times"></i>
              </a> -->
              <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
              </button>
              <video
                autoplay=""
                
                loop=""
                muted=""
                playsinline=""
                controls="controls"
                style="width: 100%"
              >
                <source attr.src="{{ videoUrl }}" type="video/mp4" />
              </video>
             </div>
      </div>
    </ng-template>
  </div>
</section>
