import { Component, OnInit, OnDestroy, ViewChild, Input, Renderer2, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { CognitoUtil,LoggedInCallback } from "../service/cognito.service";
import { DBService } from '../service/db.service';
// import { HttpClient,HttpBackend } from '@angular/common/http';
import {HttpBackend, HttpClient} from "@angular/common/http";
import { UserLoginService } from "../service/user-login.service";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
// import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css']
})
export class ContentComponent implements OnInit {
  hideMenu: boolean = false;
  // routeRedirect = true;
  @Input() videoUrl: string = "";
  showVideoPopup: boolean = false;
  cities: Array<string> = [];
  trainersDataLoctn: Object[];
  trainersData: Object[];
  // trainersData: any;
  location: string = 'hyderabad';
  // selectedItem: Array <string> = [];
  dropdownSettings: any = {};
  closeDropdownSelection = false;
  disabled = false;
  searchTrainer: string;
  SelectedCity: string;
  showLocDB: boolean = true;
  showOvrDb: boolean = false;
  pagenumber:number = 0;
  pagesize:number = 13;
  notSroclly:boolean = true;
  notEmptyPost:boolean = true;
  modalRef: BsModalRef;
  // bntStyle : string;
  @ViewChild('contentVideo') contentVideo: any;

   constructor(public router: Router,private renderer: Renderer2,
    private db_service: DBService,private httpClient: HttpClient,handler: HttpBackend,
    public cognitoUtil: CognitoUtil, public userService: UserLoginService,private modalService: BsModalService) {
      this.userService.isAuthenticated(this)
      this.httpClient = new HttpClient(handler);
   }

  ngOnInit() {
    //  this.contentVideo.nativeElement.muted = true;
    this.cities = ['By Trainer', 'By Location'];
    // this.selectedItem = ['Pune'];
    this.dropdownSettings = {
      singleSelection: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: false,
      closeDropDownOnSelection: this.closeDropdownSelection
    };
    // this.getDataFromServer();
    // this.SelectedCity = 'By Location';
    // document.body.classList.remove('body-main-build');
    // document.body.classList.remove('body-main-yoga');
    // document.body.classList.remove('body-main-zumba');
    // document.body.classList.add('body-main');
    // if(this.searchTrainer != null){

    // }

    this.getTrainersFromServer();
    this.pagenumber=0;
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'modal-open');
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (isLoggedIn) {
      this.userService.logout();
      this.cognitoUtil.signOut();
      localStorage.clear();
      localStorage.removeItem("ProfileCategory");
      localStorage.removeItem("ProfileGender");
    }
  }

  toggleCloseDropdownSelection() {
    this.closeDropdownSelection = !this.closeDropdownSelection;
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, { closeDropDownOnSelection: this.closeDropdownSelection });
  }

  getTrainersFromServer() {
    var index = '/guest';
    // this.db_service.getDataFromServer("", index).subscribe(
    //   // this.httpClient.get('https://dev.1o1.cloudns.asia/trainerservice/api/trainer/guest/').subscribe(
    //   response => {
    //     // console.log('Data Retrived succesfully.', response);
    //     this.trainersDataLoctn = [];
    //     this.showOvrDb = true;
    //     //this.t
    //     // if (this.location != undefined) {
    //     //   for (var i = 0; i < response.length; i++) {
    //     //     if(response[i].city != null){
    //     //       if (response[i].city.toLowerCase() == "hyderabad") {
    //     //         this.trainersDataLoctn.push(response[i]);
    //     //       }
    //     //   }
    //     //   }
    //     // } else {
    //     //   this.trainersData = response;
    //     // }
    //     // if(this.searchTrainer != null || this.searchTrainer != undefined){
    //     this.trainersData = response;
    //     // }
    //   },
    //   error => {
    //     console.log('There is an error while retriving data.');
    //   }
    // )
    //https://demo.1o1fitness.com/trainerservice/api/trainer/guest/
    // this.httpClient.get<any>('https://demo.1o1fitness.com/trainerservice/api/trainer/guest?pagesize='+this.pagesize+'&pagenumber=' + this.pagenumber).subscribe({
    //     next: response => {
    //       this.trainersDataLoctn = [];
    //       this.showOvrDb = true;
    //       this.trainersData = response.data;
    //     },
    //     error: error => {
    //       console.log('There is an error while retriving data.');
    //     }
    // })

    this.httpClient.get<any>('https://demo.1o1fitness.com/trainerservice/api/trainer/guest?pagesize='+this.pagesize+'&pagenumber=' + this.pagenumber).subscribe({
        next: response => {
          console.log(response);
          this.trainersDataLoctn = [];
          this.showOvrDb = true;
          this.trainersData = response.data;
        },
        error: error => {
          console.log('There is an error while retriving data.');
        }
    })
  }

  openVideo(url,template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template,Object.assign({}, { class: 'modal-dialog-centered' }));
    this.showVideoPopup = !this.showVideoPopup;
    this.videoUrl = url;
    // this.bntStyle = 'trastion-cls';
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  closePopup() {
    this.showVideoPopup = !this.showVideoPopup;
    this.renderer.removeClass(document.body, 'modal-open');
  }
  onClickedOutside() {
    // this.showBox = false;
    this.showVideoPopup = false;
    this.renderer.removeClass(document.body, 'modal-open');
  }
  showMenu() {
    this.hideMenu = !this.hideMenu
  }
  // showSearch(){
  //   if(this.searchTrainer == null || this.searchTrainer == '')
  //       return true;
  //   else
  //       return false;
  // }
  // showDefltSearch(){
  //   if( this.searchTrainer != null)
  //       return true;
  //   else
  //       return false;
  // }
  modelChanged() {
    // console.log("The change is here" + this.searchTrainer)
    if (this.searchTrainer != null || this.searchTrainer != '')
      this.showOvrDb = true;
    this.showLocDB = false;
  }
  onScroll(){
    console.log("scrolled..");
    if(this.notEmptyPost && this.notSroclly){
     this.notSroclly = false;
     this.pagenumber = this.pagenumber + 1;
     this.loadNext();
    }
  }
  loadNext(){
  //   this.httpClient.get<any>('https://demo.1o1fitness.com/trainerservice/api/trainer/guest?pagesize='+this.pagesize+'&pagenumber=' + this.pagenumber).subscribe({
  //     next: response => {
  //     if(response.data.length === 0){
  //       this.notEmptyPost = false;
  //     }
  //       this.trainersData = this.trainersData.concat(response.data);
  //       this.notSroclly = true;
  //     },
  //     error: error => {
  //       console.log('There is an error while retriving data.');
  //     }
  // })
  this.httpClient.get<any>('https://demo.1o1fitness.com/trainerservice/api/trainer/guest?pagesize='+this.pagesize+'&pagenumber=' + this.pagenumber).subscribe({
      next: response => {
      if(response.data.length === 0){
        this.notEmptyPost = false;
      }
        this.trainersData = this.trainersData.concat(response.data);
        this.notSroclly = true;
      },
      error: error => {
        console.log('There is an error while retriving data.');
      }
  })
  }
}
