import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserLoginService } from "../service/user-login.service";
import { LoggedInCallback, CognitoUtil } from "../service/cognito.service";
import { DBService } from '../service/db.service';
import { ToastrService } from 'ngx-toastr';
import {IMyDpOptions} from 'mydatepicker';
import { DatePipe } from '@angular/common'


export class IMyDateModel {
  date: IMyDate;
  jsdate: Date;
  formatted: string;
  epoc: number;
}

export class IMyDate {
  year: number;
  month: number;
  day: number;
}

@Component({
  selector: 'app-trainee-gallery',
  templateUrl: './trainee-gallery.component.html',
  styleUrls: ['./trainee-gallery.component.css']
})
export class TraineeGalleryComponent implements OnInit, OnDestroy {

  currentCategory: string;
  currentGender: string;
  private sub: any;

  currentLogged: string;
  currentEmail: string;
  private loggedIn: boolean;

  // Vars
  userID: string;
  trainee_id: string;
  program_id: string;
  alltrainees: any;
  Trainees: any;
  trainee: any;

  // module constants
  progressphotos:object[];
  fromDate:IMyDateModel;
  toDate:IMyDateModel;

  timezone: string;
  dateFormat:string;

  myDatePickerOptions: IMyDpOptions = {};



  constructor(private renderer: Renderer2, public router: Router,private toastr: ToastrService,
    public route: ActivatedRoute, private db_service: DBService, public datepipe: DatePipe) {
      // this.userService.isAuthenticated(this);
    }

  ngOnInit() {
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.sub = this.route.params.subscribe(params => {
        this.trainee_id = params['traineeId'];
        this.program_id = params['programID'];
      });
    this.currentCategory = localStorage.getItem("ProfileCategory")
        this.currentGender = localStorage.getItem("ProfileGender");
        if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'male') {
            this.renderer.removeClass(document.body, 'body-main');
            this.renderer.addClass(document.body, 'bg-img-build-male');
        }
        else if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'female') {
            this.renderer.removeClass(document.body, 'body-main');
            this.renderer.addClass(document.body, 'bg-img-build-female');
        }
        else if (this.currentCategory == 'Yoga' && this.currentGender == 'male') {
            this.renderer.removeClass(document.body, 'body-main');
            this.renderer.addClass(document.body, 'bg-img-yoga-male');
        }
        else if (this.currentCategory == 'Yoga' && this.currentGender == 'female') {
            this.renderer.removeClass(document.body, 'body-main');
            this.renderer.addClass(document.body, 'bg-img-yoga-female');
        }
        else if (this.currentCategory == 'Zumba' && this.currentGender == 'male') {
            this.renderer.removeClass(document.body, 'body-main');
            this.renderer.addClass(document.body, 'bg-img-zumba-male');
        }
        else if (this.currentCategory == 'Zumba' && this.currentGender == 'female') {
            this.renderer.removeClass(document.body, 'body-main');
            this.renderer.addClass(document.body, 'bg-img-zumba-female');
        }
      //   this.userService.currentUserID.subscribe((val) => {
      //     if (val != '') {
      //         this.userID = val;
      //     }
      // });
      if(this.timezone.includes("asia") || this.timezone.includes("Asia")){
        this.myDatePickerOptions = {
          todayBtnTxt: 'Today',
          dateFormat: 'dd-mm-yyyy',
          firstDayOfWeek: 'mo',
          sunHighlight: true,
          openSelectorOnInputClick: true,
          editableDateField: false,
          inline: false
        }
        this.dateFormat = "dd-MM-yyyy";
      }else{
        this.myDatePickerOptions = {
          todayBtnTxt: 'Today',
          dateFormat: 'mm-dd-yyyy',
          firstDayOfWeek: 'mo',
          sunHighlight: true,
          openSelectorOnInputClick: true,
          editableDateField: false,
          inline: false
        }
        this.dateFormat = "MM-dd-yyyy";
      }

  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'bg-img-build-male');
    this.renderer.removeClass(document.body, 'bg-img-yoga-male');
    this.renderer.removeClass(document.body, 'bg-img-zumba-male');
    this.renderer.removeClass(document.body, 'bg-img-build-female');
    this.renderer.removeClass(document.body, 'bg-img-yoga-female');
    this.renderer.removeClass(document.body, 'bg-img-zumba-female');
    this.renderer.addClass(document.body, 'body-main');
}

getProgressPhotos(){
    var index = '/program/'+this.trainee_id+'/progressphotos';
    this.db_service.getDataFromServer("", index).subscribe(
      response => {
        this.progressphotos = response.data;
        // for(var i=0; i<this.progressphotos.length; i++){
        //     this.progressphotos[i]["creationDate"] = new Date(this.progressphotos[i]["creationDate"]);
        // }
      },
      error => {
        // alert("An error has occurred while retriving profile data.");
        this.toastr.error('', 'An error has occurred while retriving trainee profile data', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      }
    )
}

onDateChanged(event: IMyDateModel, dateType){
  console.log(event.formatted + dateType);
  if((dateType == "fromDate" && this.toDate != undefined) || (dateType == "toDate" && this.fromDate != undefined) ){
    var data = {};
    if(dateType == "fromDate"){
     data =  {
      "fromDate":this.datepipe.transform(event.jsdate, "yyyy-MM-dd"),
      "toDate":this.datepipe.transform(this.toDate.jsdate, "yyyy-MM-dd"),
      "trainee_id":this.trainee_id

     }
    }else{
      data =  {
        "fromDate":this.datepipe.transform(this.fromDate.jsdate, "yyyy-MM-dd"),
        "toDate":this.datepipe.transform(event.jsdate, "yyyy-MM-dd"),
        "trainee_id":this.trainee_id
    }
  }
     var index = '/program/'+this.trainee_id+'/progressphotos/limit';
     this.db_service.getData(data, index).subscribe(
       response => {
         this.progressphotos = response.data;
       },
       error => {
         // alert("An error has occurred while retriving profile data.");
         this.toastr.error('', 'An error has occurred while retriving trainee profile data', {
           timeOut: 3000,
           positionClass: 'toast-top-right',
           progressBar: true,
           progressAnimation: 'increasing',
           tapToDismiss: true,
         });
       }
     )
     }
}



onDateChange(){
  if(this.fromDate != undefined && this.toDate != undefined){
 var data =  {
   "fromDate":this.fromDate,
   "toDate":this.toDate,
   "trainee_id":this.trainee_id

  }
  var index = '/program/'+this.trainee_id+'/progressphotos/limit';
  this.db_service.getData(data, index).subscribe(
    response => {
      this.progressphotos = response.data;
    },
    error => {
      // alert("An error has occurred while retriving profile data.");
      this.toastr.error('', 'An error has occurred while retriving trainee profile data', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
  )
  }

}
// isLoggedIn(message: string, isLoggedIn: boolean) {
//   if (!isLoggedIn) {
//       this.router.navigate(['/login']);
//   } else {
//       // if()
//       if (this.loggedIn == true) {
//           this.currentEmail = localStorage.getItem('lastAuthUser');
//           // if(this.currentEmail != undefined)
//           // this.getProfileDataFromServer();
//       }
//       else {
//           // this.currentEmail = localStorage.getItem('CognitoIdentityServiceProvider.2io8v4r253hugt5ndbjv9shkfp.LastAuthUser');
//           this.currentEmail = this.cognitoUtil.getCurrentUser().getUsername();
//           if(this.currentEmail == undefined || this.currentEmail == '' || this.currentEmail == null || this.currentEmail.includes("Google"))
//           {
//             this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
//           }
//           // this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
//           // this.getProfileDataFromServer();
//       }
//   }
// }

}
