<section class="mt-100 mb-160">
  <div class="container">
    <div class="gender__content">
      <h1 class="heading1 text-center"><span>Gender</span></h1>
      <div class="row">
        <div
          class="d-flex justify-content-md-center gender__wrapper"
          style="width: 100%; margin-bottom: 100px;">
          <div
            class="gender__wrapper-male col-md-2 col mx-0 slidenLeft"
            style="cursor: pointer;"
            (click)="genderSelect('male')"
          ></div>
          <div
            class="gender__wrapper-female col-md-2 col mx-0 slidenRight"
            style="cursor: pointer;"
            (click)="genderSelect('female')"
          ></div>
        </div>
      </div>
    </div>

    <div class="category__content">
      <h1 class="heading1 text-center">
        <span>Category</span>
      </h1>

      <div class="row justify-content-md-center">
        <div class="category-items">
          <a (click)="categorySelect('Bodybuilder')" style="cursor: pointer;">
            <img
              class="excerise-gif"
              src="assets/images/exercise/execise-00001.gif"
              width="200"
            />
            <h4>
              <span class="tickmark" *ngIf="showTickBody">
                <img src="assets/images/tick_img.png" width="30"
              /></span>
              Fitness
            </h4>
          </a>
          <a (click)="categorySelect('Yoga')" style="cursor: pointer;">
            <img
              class="yoga-gif"
              src="assets/images/yoga/yoga-10000.gif"
              width="200"
            />
            <h4>
              <span class="tickmark" *ngIf="showTickYoga">
                <img src="assets/images/tick_img.png" width="30" />
              </span>
              Yoga
            </h4>
          </a>
          <a (click)="categorySelect('Zumba')" style="cursor: pointer;">
            <img
              class="zumba-gif"
              src="assets/images/zumba/zumba_00000.gif"
              width="200"
            />
            <h4>
              <span class="tickmark" *ngIf="showTickZumba">
                <img src="assets/images/tick_img.png" width="30"
              /></span>
              Zumba
            </h4>
          </a>
        </div>
      </div>
      <div class="col-md-12 text-center bottom-buttons-category" style="margin-top: 40px;">
        <button type="button" class="btn btn-circle genderPrevBtn">
          <i class="fas fa-angle-left"></i>
        </button>
        <button
          type="button"
          class="btn btn-circle gendernxtBtn"
          (click)="onInfoSubmit()"
        >
          <i class="fas fa-angle-right"></i>
        </button>
      </div>
    </div>

    <ng-container *ngIf="showPrimary">
      <div class="workout-popup__wrapper container-fluid">
        <div class="workout-pop col-sm-6 col-md-4 zoomIn background-shadow-green">
          <a
            (click)="closePopup()"
            class="close-program_event-pop"
            style="cursor: pointer;"
            ><i class="fas fa-times"></i
          ></a>
          <div class="workout-body col-md-12 p-3" >
            <div class="row" style="text-align: center; ">
              <div class="d-flex col-md-8 py-2 justify-content-center">
              <ng-multiselect-dropdown  class="form-control col-9 col-md-12 mx-auto category-select-style"
                [placeholder]="'Select Primary Category'"
                [settings]="dropdownSettings_single"
                [data]="primaryCategory"
                [(ngModel)]="selectedPrimary"
                name="primaryCategory"
              >
              </ng-multiselect-dropdown>
              </div>
              <div class="d-flex col-md-4 justify-content-center">
                <button
                  type="button"
                  class="btn btn-default save-exercise-btn"
                  style="margin-top: 10px;"
                  (click)="onCategory()"
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</section>
