// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // DEV Cofigurations
  // region: 'us-east-1',
  // identityPoolId: 'us-east-1:32c7c09c-e312-4797-9ead-4dca1a946ef8',
  // userPoolId: ' us-east-1_qLJhtUVOH',
  // clientId: '7d8q4gmo330527b8b8t1oula75',
  // GroupName:'ADMIN_ROLE',
  // googleId: '795381889700-0mqq8utqjtt2i2ho5611t0qgb7pp1c67.apps.googleusercontent.com',
  // cognito_domain_name:'trainer-dev-domain.auth.us-east-1.amazoncognito.com',
  // cognito_idp_endpoint: '',
  // cognito_identity_endpoint: '',
  // sts_endpoint: '',
  // dynamodb_endpoint: '',
  // s3_endpoint: '',

  // Demo Cofigurations
  region: 'us-east-1',
  identityPoolId: 'us-east-1:32c7c09c-e312-4797-9ead-4dca1a946ef8',
  userPoolId: 'us-east-1_SX1wuiOvn',
  clientId: 'k7oibe9calcf9hf7fubq1msp5',
  GroupName:'ADMIN_ROLE',
  googleId: '482085622817-89jhns4iriicsstn77i71p52klu1bcor.apps.googleusercontent.com',
  cognito_domain_name:'trainer1o1.auth.us-east-1.amazoncognito.com',
  cognito_idp_endpoint: '',
  cognito_identity_endpoint: '',
  sts_endpoint: '',
  dynamodb_endpoint: '',
  s3_endpoint: '',

  // UAT Cofigurations
  // region: 'eu-west-3',
  // identityPoolId: 'eu-west-3:11a27836-21fb-48ce-88ad-9267072bb427',
  // userPoolId: 'eu-west-3_yQXgCKztF',
  // clientId: 'b51sn8k20t23vh2aflfgnu0f9',
  // GroupName:'ADMIN_ROLE',
  // googleId: '795381889700-0mqq8utqjtt2i2ho5611t0qgb7pp1c67.apps.googleusercontent.com',
  // cognito_domain_name:'trainer1o1-web-eu.auth.eu-west-3.amazoncognito.com',
  // cognito_idp_endpoint: '',
  // cognito_identity_endpoint: '',
  // sts_endpoint: '',
  // dynamodb_endpoint: '',
  // s3_endpoint: '',

  // Prod Cofigurations
  // region: 'ap-south-1',
  // identityPoolId: 'ap-south-1:cbea5085-3682-4ca6-a8e0-11865c369b73',
  // userPoolId: 'ap-south-1_iNIpq37I4',
  // clientId: '1l89fmchenpkve79as6c2s9i3l',
  // GroupName:'ADMIN_ROLE',
  // googleId: '159288171675-7jp3a53eda2s16kao05qn8m3vcsedv8n.apps.googleusercontent.com',
  // cognito_domain_name:'prodtrainer1o1.auth.ap-south-1.amazoncognito.com',
  // cognito_idp_endpoint: '',
  // cognito_identity_endpoint: '',
  // sts_endpoint: '',
  // dynamodb_endpoint: '',
  // s3_endpoint: '',

  trianee_region: 'us-east-1',
  trianee_identityPoolId: 'us-east-1:1ca83616-ae4c-49cf-97ff-9f6781178d0f',
  trianee_userPoolId: 'us-east-1_gMWu79Y0F',
  trianee_clientId: '63gc7sa1fvgd5tlnim70t3jpqc',
  trianee_GroupName:'TRAINEE_ROLE',
  // trianee_googleId: '482085622817-89jhns4iriicsstn77i71p52klu1bcor.apps.googleusercontent.com',
  trianee_cognito_domain_name:'mobile-staging-domain.auth.us-east-1.amazoncognito.com',
  trianee_cognito_idp_endpoint: '',
  trianee_cognito_identity_endpoint: '',
  trianee_sts_endpoint: '',
  trianee_dynamodb_endpoint: '',
  trianee_s3_endpoint: ''
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
