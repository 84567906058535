import { Component, OnInit, Input, ViewChild, OnDestroy, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { AppGlobals } from '../app.globals';
import { DBService } from '../service/db.service';
import { Integer } from 'aws-sdk/clients/comprehend';
import { ToastrService } from 'ngx-toastr';
import { UserLoginService } from "../service/user-login.service";
import { LoggedInCallback, CognitoUtil } from "../service/cognito.service";
import { EmbedVideoService } from 'ngx-embed-video';
import {Options} from 'sortablejs';

export class workout {
  trainerId: string;
  workoutId: string;
  workoutName: Object;
  customWorkoutName: string;
  workoutExercises: Object[];
  warnings: string;
  workoutStatus: string;
  workoutPercentage: string;
  isChangeAffects: boolean;
}
@Component({
  selector: 'app-viewworkout',
  templateUrl: './viewworkout.component.html',
  styleUrls: ['./viewworkout.component.css']
})
export class ViewworkoutComponent implements OnInit, OnDestroy, LoggedInCallback {
  public showVideoPopup: boolean = false;
  editWorkout: boolean = false;
  viewWorkout: boolean = true;
  currentEmail: string;
  workoutName: string;
  imgPath: string;
  addWorkoutFunc: boolean = false;
  addWorkout: boolean = true;
  gender: object[];
  selectedGender: Object[];
  workoutdisabled: boolean = true;
  options: Options = {
    group: 'test'
  };

  id: Integer;
  @Input() viewURL: string = "";
  @Input() Instructions: string = "";
  @ViewChild('viewworkLoad') viewworkLoad: any;
  constructor(private renderer: Renderer2, public router: Router, public route: ActivatedRoute,
    private globals: AppGlobals, private db_service: DBService, public cognitoUtil: CognitoUtil,
    private toastr: ToastrService, public userService: UserLoginService, private embedService: EmbedVideoService) {
    this.userService.isAuthenticated(this);
  }
  private sub: any;
  workout: workout;
  exercises: object[];
  droppedItems: object[];
  currentCategory: string;
  currentGender: string;
  embedYoutubeUrl: string;
  videoHidden: boolean = false;
  iframeHidden: boolean = false;
  isUpdateWorkout: boolean = false;
  showExerciseRejectPopUp: boolean = false;
  iframeURL: any;
  workoutNames: object[];
  workoutMasters: Object[];
  dropdownSettings_single: any = {};
  closeDropdownSelection = false;
  SelectedWorkoutNames: Object[];
  isWorkoutName: boolean = true;


  // Google Var
  private loggedIn: boolean;

  ngOnInit() {

    this.currentCategory = localStorage.getItem("ProfileCategory")
    this.currentGender = localStorage.getItem("ProfileGender");
    if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-build-male');
    }
    else if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-build-female');
    }
    else if (this.currentCategory == 'Yoga' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-yoga-male');
    }
    else if (this.currentCategory == 'Yoga' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-yoga-female');
    }
    else if (this.currentCategory == 'Zumba' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-zumba-male');
    }
    else if (this.currentCategory == 'Zumba' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-zumba-female');
    }
    this.getAllMasters();
    this.workout = new workout();
    //this.workout.trainerId = "naresh_1e25dff44asds4654sdsd4";
    //  this.getExerciseDataFromServer();
    this.sub = this.route.params.subscribe(params => {
      this.workout.workoutId = params['workoutID'];
    });
    if (this.workout.workoutId != undefined && this.workout.workoutId != "") {
      this.getWorkoutData();
    }
    this.dropdownSettings_single = {
      singleSelection: true,
      enableCheckAll: false,
      idField: 'id',
      textField: 'name',
      allowSearchFilter: true,
      itemsShowLimit: 1,
      closeDropDownOnSelection: this.closeDropdownSelection
    };
    this.gender = [
      { "id": "0", "name": "Male" },
      { "id": "1", "name": "Female" }
    ]
    // this.getWorkoutData();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.renderer.removeClass(document.body, 'bg-img-build-male');
    this.renderer.removeClass(document.body, 'bg-img-yoga-male');
    this.renderer.removeClass(document.body, 'bg-img-zumba-male');
    this.renderer.removeClass(document.body, 'bg-img-build-female');
    this.renderer.removeClass(document.body, 'bg-img-yoga-female');
    this.renderer.removeClass(document.body, 'bg-img-zumba-female');
    this.renderer.addClass(document.body, 'body-main');
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/login']);
    } else {
      // if()
      if (this.loggedIn == true) {
        this.currentEmail = localStorage.getItem('lastAuthUser');
        // if(this.currentEmail != undefined)
        this.getProfileDataFromServer();
      }
      else {
        // this.currentEmail = localStorage.getItem('CognitoIdentityServiceProvider.2io8v4r253hugt5ndbjv9shkfp.LastAuthUser');
        this.currentEmail = this.cognitoUtil.getCurrentUser().getUsername();
        if(this.currentEmail == undefined || this.currentEmail == '' || this.currentEmail == null || this.currentEmail.includes("Google") || this.currentEmail.includes("google"))
        {
          this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
          if(this.currentEmail == undefined)
          this.currentEmail = this.cognitoUtil.session.idToken.payload.email;
        }
        // this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
        this.getProfileDataFromServer();
      }
    }
  }
  getProfileDataFromServer() {
    var index = '/profile/';
    this.db_service.getDataFromServer(this.currentEmail, index).subscribe(
      response => {
        //console.log('Data Retrived succesfully.', response);
        response = response.data[0];
        this.workout.trainerId = response.trainerId;
        //this.getExerciseDataFromServer();
        this.getWorkoutData();
      },
      error => {
        // alert("An error has occurred while retriving profile data.");
      }
    )
  }
  getAllMasters() {
    var index = '/masters/';
    this.db_service.getDataFromServer('workout', index).subscribe(
      response => {
        //console.log('Data Retrived succesfully.', response);
        for (let i = 0; i < response.length; i++) {
          if (response[i]['name'] == "Custom_WorkoutNames") {
            this.workoutNames = response[i]['values'];
            this.workoutMasters = response[i]['values'];
          }
        }
      },
      error => {
        console.log('An error has occurred while retriving masters data.');
      }
    )
  }
  getWorkoutData() {
    var index = '/workout/';
    this.db_service.getDataFromServer(this.workout.workoutId, index).subscribe(
      response => {
        //console.log('Data Retrived succesfully.', response);
        this.workout = response.data;
        this.droppedItems = this.workout.workoutExercises;
        const work_out = this.workoutMasters.find(x => x["id"] == this.workout.workoutName["id"]);
        this.SelectedWorkoutNames = Array.of(work_out);
        var name = this.workout.workoutName;
        //this.selectedGender = Array.of(this.workout.gender);
        this.workoutName = name["name"];
        // if(name["name"]== "Others"){
        //   this.isWorkoutName = true;
        // }else{
        //   this.isWorkoutName = false;
        // }
        this.imgPath = name["imgUrl"];
        // this.getExerciseDataFromServer();

      },
      error => {
        this.toastr.error('', 'An error has occurred while retriving workout data.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      })
  }
  onItemDrop(e: any) {
    for (let i = 0; i < this.exercises.length; i++) {
      if (e["data"] == this.exercises[i]["exerciseId"]) {
        var exercise = this.exercises[i];
        // exercise["exerciseName"] = this.exercises[i]["exerciseName"];
        // exercise["setsVal"] = this.exercises[i]["sets"].length;
        // // exercise["repsVal"] = this.exercises[i]["repsVal"];
        // // exercise["restPeriodVal"] = this.exercises[i]["restPeriodVal"];
        // exercise["exerciseId"] = this.exercises[i]["exerciseId"];
        // exercise["thumbnailDestination"] = this.exercises[i]["exerciseVideo"]["videoThumbnailPath"];

        if (this.droppedItems == undefined) {
          // this.id = 1;
          exercise["referenceId"] = 1;
          this.droppedItems = Array.of(exercise);
        } else {
          // this.id = this.droppedItems.length + 1;
          exercise["referenceId"] = this.droppedItems.length + 1;
          this.droppedItems.push(JSON.parse(JSON.stringify(exercise)));
        }
      }
    }
  }
  addRest() {
    var exercise = {};
    exercise["exerciseName"] = "Rest";
    exercise["exerciseId"] = "rest_id";
    exercise["setsVal"] = null;
    // exercise["repsVal"] = null;
    exercise["restPeriodVal"] = 60;
    // exercise["thumbnailDestination"] = null;
    if (this.droppedItems == undefined) {
      this.toastr.error('', 'Plese add atleast one exercise before adding rest.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    } else if (this.droppedItems.length == 0) {
      this.toastr.error('', 'Plese add atleast one exercise before adding rest.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    } else {
      //this.id = this.id + 1;
      exercise["referenceId"] = this.droppedItems.length + 1;
      this.droppedItems.push(JSON.parse(JSON.stringify(exercise)));
    }
  }
  RemoveExercise(id) {
    const index = this.droppedItems.findIndex(droppedItem => droppedItem["referenceId"] == id);
    this.droppedItems.splice(index, 1);
  }
  SaveWorkout() {
    if (this.droppedItems != undefined && this.droppedItems != null) {
      if (this.droppedItems.length == 0) {
        this.toastr.error('', 'Plese add atleast one exercise to this workout.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        return;

      }

      if (this.droppedItems[0]["exerciseId"] == "rest_id") {
        this.toastr.error('', 'Cannot congfigure a workout which is starting with rest.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        return;
      }
    } else if (this.workout.workoutId != undefined && this.droppedItems == undefined && this.addWorkout != true) {
      this.toastr.error('', 'Plese add atleast one exercise to this workout.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return;
    }
    if (this.addWorkout != true) {
      for (var i = 0; i < this.droppedItems.length; i++) {
        if (this.droppedItems[i]["exerciseId"] == "rest_id" && (this.droppedItems[i]["restPeriodVal"] <= 0 || this.droppedItems[i]["restPeriodVal"] > 180)) {
          this.toastr.error('', 'Rest period value should be in the range of 1 to 180.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
          return;
        }
      }
    }



    this.workout.workoutExercises = this.droppedItems;
    const work_out = this.workoutMasters.find(x => x["id"] == this.SelectedWorkoutNames[0]["id"]);
    this.workout.workoutName = work_out;
    //this.workout.gender = this.selectedGender[0];
    if (this.droppedItems != undefined) {
      for (var i = 0; i < this.workout.workoutExercises.length; i++) {
        this.workout.workoutExercises[i]["referenceId"] = i + 1;
      }
    }

    if (this.addWorkout == false) {
      var workoutRejectCheck = 0;
      for (var i = 0; i < this.workout.workoutExercises.length; i++) {
        if (this.workout.workoutExercises[i]["adminStatus"] == "Rejected") {
          workoutRejectCheck = 1;
          break;
        }
      }
      if (workoutRejectCheck == 0) {
        var index = '/workout/' + this.workout.trainerId + '/' + this.workout.workoutId + '/status';
        this.db_service.getDataFromServer("", index).subscribe(response => {
          if (response.data == true) {
            this.isUpdateWorkout = true;
          } else {
            this.onUpdateWorkout("No");
          }
        })
      } else {
        this.showExerciseRejectPopUp = true;
        return;
      }
    } else {
      this.onUpdateWorkout("No");
    }









  }

  onUpdateWorkout(value) {
    var index = '/workout';
    if (value == "Yes")
      this.workout.isChangeAffects = true;
    else
      this.workout.isChangeAffects = false;
    var index = '/workout';
    this.db_service.updateDatainDB(this.workout, index).subscribe(
      response => {
        //alert('Workout saved successfully.');
        this.toastr.success('', 'Workout updated successfully.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        this.editWorkout = false;
        this.viewWorkout = true;
        this.workout.isChangeAffects = false;
        if (this.addWorkout == false) {
          this.router.navigate(['workout']);
        } else {
          this.addWorkoutFunc = true;
          this.addWorkout = false;
          this.getExerciseDataFromServer();
        }
      },
      error => {
        //alert('there is an error while saving workout');

        this.toastr.error('', 'There is an error while saving workout', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });

      }
    )

  }
  playThis(id) {
    for (let i = 0; i < this.exercises.length; i++) {
      if (id == this.exercises[i]["exerciseId"]) {
        this.viewURL = this.exercises[i]["exerciseVideo"]["exerciseVideoSource"];
        this.Instructions = this.exercises[i]["instructions"]
        this.viewworkLoad.nativeElement.load();
        this.viewworkLoad.nativeElement.play();
      }
    }
  }
  back(page) {
    if (page == 'page2') {
      if (this.workout.workoutExercises == undefined) {
        this.toastr.error('', 'Please add exercises to workout.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      } else if (this.workout.workoutExercises.length == 0) {
        this.toastr.error('', 'Please add exercises to workout.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      }
    }
    this.router.navigate(['workout']);
  }
  edit() {
    this.editWorkout = true;
    this.viewWorkout = false;
  }
  getExerciseDataFromServer() {
    var index = '/exercise/' + this.workout.trainerId + '/';
    this.db_service.getDataFromServer(this.workout.workoutName["name"], index).subscribe(
      response => {
        //console.log('Data Retrived succesfully.', response);
        //response = Array.of(response);
        this.exercises = response.data;
        if (this.droppedItems != null) {
          var exerciseId = this.droppedItems[0]["exerciseId"];
          for (let i = 0; i < this.exercises.length; i++) {
            if (exerciseId == this.exercises[i]["exerciseId"]) {
              if (this.exercises[i]["exerciseVideo"]["youtubeUrl"] == "") {
                this.viewURL = this.exercises[i]["exerciseVideo"]["exerciseVideoSource"];
                this.iframeHidden = false;
                this.videoHidden = true;
                this.Instructions = this.exercises[i]["instructions"]
                this.viewworkLoad.nativeElement.load();
                this.viewworkLoad.nativeElement.play();
              } else {
                this.iframeHidden = true;
                this.videoHidden = false;
                this.iframeURL = this.embedService.embed(this.exercises[i]["exerciseVideo"]["youtubeUrl"], {
                  query: { portrait: 0, color: 'black', autoplay: 1 },
                  attr: { width: 650, height: 360, frameborder: 0, allow: "autoplay" }
                })
                if (this.iframeURL != undefined)
                  this.embedYoutubeUrl = this.iframeURL;
              }
            }
          }
        }
      },
      error => {
        // alert("An error has occurred while retriving exercise  data.");
      }
    )
  }
  openVideo(exerciseId) {

    for (let i = 0; i < this.exercises.length; i++) {
      if (exerciseId == this.exercises[i]["exerciseId"]) {
        if (this.exercises[i]["exerciseVideo"]["youtubeUrl"] == "") {
          this.viewURL = this.exercises[i]["exerciseVideo"]["exerciseVideoSource"];
          if (this.viewWorkout == false) {
            this.showVideoPopup = true;
          } else {
            this.showVideoPopup = false;
          }
          this.iframeHidden = false;
          this.videoHidden = true;
          this.viewworkLoad.nativeElement.load();
          this.viewworkLoad.nativeElement.play();
        } else {
          if (this.viewWorkout == false) {
            this.showVideoPopup = true;
            this.iframeURL = this.embedService.embed(this.exercises[i]["exerciseVideo"]["youtubeUrl"], {
              query: { portrait: 0, color: 'black', autoplay: 1 },
              attr: { width: "100%", height: 450, frameborder: 0, allow: "autoplay" }
            })
          } else {
            this.showVideoPopup = false;
            this.iframeURL = this.embedService.embed(this.exercises[i]["exerciseVideo"]["youtubeUrl"], {
              query: { portrait: 0, color: 'black', autoplay: 1 },
              attr: { width: "100%", height: 450, frameborder: 0, allow: "autoplay" }
            })
          }
          this.iframeHidden = true;
          this.videoHidden = false;
          if (this.iframeURL != undefined)
            this.embedYoutubeUrl = this.iframeURL;
        }
      }
    }

  }
  closePopup() {
    this.showVideoPopup = false;
    this.showExerciseRejectPopUp = false;
  }
}
