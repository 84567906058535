<div class="container ">

  <section>

      <div>
          <h6 class="text-center"><u><b>1o1Fitness Privacy & Cookie Policy</b></u></h6>
      </div>


      <p>1o1fitness India Private Limited. (“<u>1o1Fitness</u>,” “<u>we</u>,” “<u>us</u>,” or “<u>our</u>”) is
          committed to providing you with
          the best products, content and services possible. Transparency related to how we collect, share,
          safeguard and use your information is of significant importance to us. We have developed this
          Privacy &
          Cookie Policy (this “<u>Policy</u>”) to give you a clear understanding of our privacy practices,
          including how
          we process (e.g., collect, use, disclose and store) your personal data, for what purposes we may
          share
          such information and your rights and choices with regard to controlling your personal data. Please
          also
          review the Terms of Use Agreement, which governs your use of the Services (as defined below) we
          offer to
          our customers and users of our Site (as defined below) . Any capitalized terms not defined in this
          Policy shall have the meanings ascribed thereto in the Terms of Use Agreement.
      </p>


      <p>
          This Policy only applies to the information processed by us and our affiliates or any third parties
          acting with our authorization or otherwise provided to us by third parties about you, in each
          instance,
          in connection with your use of (a) www.1o1ftiness.com (the “<u>Site</u>”); (b) the 1o1Fitness
          platform,
          which
          allows you to access, upload, attach, purchase, rent, make available and/or use certain content,
          features, functionality, materials, Provider Content and services (the “<u>Platform</u>”); and (c)
          any
          other
          information, content, applications, features, functionality and services made available on or
          through
          the Site and/or the Platform (the functionality described in clauses (a), (b) and (c) above are
          collectively referred to herein as the “<u>Services</u>”).

      </p>

      <p>
          This Policy applies regardless of the device you use to access the Services and whether you are
          accessing or using the Services in-person, as a registered user or otherwise. The collection and use
          of
          your information as described in this Policy is based on our contractual obligations to you, our
          legitimate business interests, our compliance with applicable legal or regulatory obligations or,
          where
          applicable, your voluntary consent. If you do not agree with the terms of this Policy, then please
          discontinue your utilization of the Services.
      </p>
  </section>

  <section>
      <h6> <span>1.</span> Information We Collect </h6>

      <p>We collect some personal information that relates to your use of the Services. The information and
          data
          we
          may process depends on your interaction with us. The key types of information that we may process
          include
          the following:
      </p>
      <ul>
          <li>
              <p><u>Registration Information</u>: In order to access and use certain features, content, or
                  functions of the Services, you may need to register for a User Account. When you create a
                  User
                  Account, we will collect personal information as necessary to offer and provide the Services
                  (e.g., to give you access to Provider Content or 1o1Fitness Content) you request. Depending
                  on
                  the Services you elect to use, we may ask you to provide your name, email address and phone
                  number. If you choose not to provide some of this information, you may not be able to use
                  all of
                  the features of the Services.</p>
          </li>

          <li>
              <p>
                  <u>Viewing History</u>: If you create a User Account, we may track the number of views of
                  and
                  viewing history associated with the Provider Content you access, view and/or use. You will
                  also
                  be able to see your viewing history via your User Account.
              </p>
          </li>
          <li>
              <p>
                  <u> Payment Information and Purchase History</u>: If you make a purchase while using the
                  Services, your payment information (e.g., credit or debit card number, card type and
                  expiration
                  date and address associated with such credit or debit card) and additional information to
                  fulfill your purchase (e.g., name, date of birth and shipping address) may be processed by
                  us
                  and/or third party payment processors. We also process the details of any such purchases,
                  including the date of purchase and purchase price.
              </p>
          </li>


          <li>
              <p>
                  <u>Third Party Platform Information</u>: If you access the Services by logging in using your
                  account credentials from a third-party owned or operated platform/service (e.g., Apple
                  (iTunes,
                  etc.), YouTube, Facebook, Twitter, LinkedIn, etc.) (each, a “<u>Third Party Platform</u>”),
                  post
                  content from the Services to a social network or use various social media features (e.g.,
                  “Like”
                  button), we may process certain information from the Third Party Platform, such as your
                  username, “likes”, location, birthday, comments and reviews, direct messages you may send to
                  us,
                  interests, photos, friends, status updates and other information you provided to the Third
                  Party
                  Platform in connection with your account. Depending on your account and privacy settings, we
                  may
                  also be able to see information that you post when using these Third Party Platforms whether
                  or
                  not you are using the Services. The information you post or provide to Third Party
                  Platforms, as
                  well as the controls surrounding these disclosures are governed by the respective policies
                  of
                  such Third Party Platforms.
              </p>
          </li>

          <li>
              <p>
                  <u> Comments, Feedback, Customer Service and Other Inquires</u>: You have the ability to use
                  the
                  Services to contact us with questions you may have, request to be contacted about services,
                  provide us with comments and or feedback about the Services (e.g., completing a “Contact Us”
                  form on the Site) or to communicate with other users of the Services and post comments to or
                  otherwise interact with certain aspects of the Services that are available to the public
                  and/or
                  other users. We will process any information you submit, post or provide in the course of
                  such
                  activities to respond to your comments or feedback.
              </p>
          </li>

          <li>
              <p>
                  <u>Personal Data You Choose to Provide for Marketing or Additional Services</u>: We may
                  process
                  personal data that you choose to provide to us when you elect to utilize optional Services.
                  For
                  example, if you sign up to receive newsletters, marketing emails, promotions or other
                  communications regarding the Services, participate in contests, surveys and other promotions
                  related to the Services, we may process your name, email address, mailing address, interests
                  and
                  phone number in order to provide such optional Services. We will make clear what information
                  we
                  may process as a result of your engagement with the optional Services.

              </p>
          </li>
          <li>
              <p>
                  <u> Location and Browser Information</u>: With your permission, we may process the specific
                  geographic location of your device (e.g., by accessing your device’s GPS or IP address) via
                  the
                  Services to provide you with certain offerings, Provider Content or other location-based
                  information. If you no longer wish to have this location information processed, you can
                  withdraw
                  your consent at any time by disabling the location and/or Bluetooth features in the
                  operating
                  system of your device. If you have any questions about disabling you location and/or
                  Bluetooth
                  features, you can contact us at admin@1o1Fitness.com.
              </p>
          </li>
      </ul>

  </section>

  <section>
      <h6> <span>2.</span>How and Why We May Use Your Information </h6>

      <p>
          We may process your personal information (depending on your interaction with us) for a variety of
          reasons that are permitted under data protection laws and in accordance with this Policy, which may
          include the purposes described below. If we use your information for purposes other than those
          mentioned
          below, we will notify you of these purposes before using your information and obtain your consent
          where
          necessary.
      </p>

      <ul>
          <li>

              <p><b>To fulfill our contractual obligations under an existing agreement with you or in
                      anticipation
                      of entering into an agreement with you,
              </b>
            </p>
          </li>

          <li><b>
                  <p>To meet and fulfill our legitimate business interests,</p></b>
          </li>

          <li><b>
                  <p>To comply with our regulatory and legal obligations or to enforce our legal rights, With
                      your
                      consent*</p></b>
          </li>
          <b>[NOTE: INCLUDE AS A FOOTNOTE: *You can withdraw your consent at any time by following the steps
              in
              Section 6 of this Policy.]</b>
      </ul>
  </section>

  <section>

      <h6> <span>3.</span>How We May Share Your Information </h6>
      <p>We will not share your personal information with others except in the following circumstances:</p>

      <ul>
          <li><U>Service Providers</U>: We may partner with other companies to help us operate certain aspects
              of
              our businesses, including the hosting of the Services, operation of our technology
              infrastructure,
              processing of payments and fulfillment of certain of the Services you request from us. </li>
          <li>
              <U> Corporate Transactions or Events</U>: We may disclose your personal information in
              connection
              with a merger, sale of substantially all of our assets, transfer, corporate reorganization or
              other
              disposition of our business.
          </li>
          <li>
              <U>Pursuant to Law, Rule or Regulation</U>: We may share or disclose your information with other
              parties as we deem necessary or appropriate under applicable law, rule or regulation.
          </li>
          <li>
              <u>To Fulfill Your Requests</u>: If you elect to use certain features or functions, including,
              enabling you to upload Provider Content in connection with your utilization of the Services, we
              may
              use your personal information to fulfill your request.
          </li>
          <li>
              <u>With Your Consent</u>: We may share or disclose your personal information if you provide us
              with
              your affirmative consent.

          </li>
      </ul>
      <p>
          We are committed to building relationships with third parties who are dedicated to providing you
          with a
          safe and satisfying experience. We only provide such third parties with access to your information
          to
          the extent reasonably necessary for them to provide the services for which they are contractually
          engaged to provide. We request that all of our service providers maintain commercially reasonable
          standards of security and confidentiality; each is required to agree to keep such information secure
          and
          confidential in line with this Policy.
      </p>

  </section>
  <section>

      <h6> <span>4.</span> How We Protect Your Personal Data</h6>
      <p>
          While no data transmission over the Internet is 100% secure from intrusion, we maintain and have
          implemented physical, electronic, technical and procedural security policies and measures to protect
          the
          personal information that we collect, use and/or store, which are consistent with applicable privacy
          and
          security laws. These policies and measures are designed to prevent against loss, misuse,
          unauthorized
          access, modification and disclosure and unlawful destruction of your personal information. However,
          regardless of our efforts, it is possible that third parties may unlawfully intercept or access
          transmissions or private communications. We also limit access to the Services by our own employees,
          contractors, service providers to those individuals trained in the proper handling of such
          information.
          We request that our third party contractors and service providers follow similar standards of
          security
          and confidentiality. However, despite whatever efforts we take to protect it, no one can guarantee
          the
          complete safety of your information.

      </p>
      <p>

          While we are dedicated to securing your personal information and our systems and Services, you are
          responsible for securing and maintaining the privacy of your credentials (e.g., user name, password
          or
          User Account information) and verifying that personal information we maintain about you is accurate
          and
          current.

      </p>





  </section>



  <section>
      <h6><span>5.</span>Data Retention
      </h6>
      <p>We access, retain, store and use your personal data for the least amount of time necessary to provide you
          with access to the Services) and in accordance with our data retention policies and applicable law.
      </p>
      <h6><span>6.</span>User Choice and Access </h6>
      <p>We strive to provide you with as many choices as possible to access, review, correct, update, suppress or
          otherwise limit our use of the information you have provided or asked a third party to provide to us,
          including:
      </p>
      <ul>
          <li>
              <u>User Account</u>: If you have registered for a User Account to assist us with providing the
              Services, you may have the ability to update, delete and correct your User Account information. To
              update, delete and/or correct information in your User Account please login to your User Account.
          </li>
          <li>
              <u> Contact Information</u>: If you provided contact information in connection with your use of the
              Services, you may have the ability to update, delete and correct your contact information. To
              update, delete and/or correct information in your contact information please login to your User
              Account.

          </li>
      </ul>
      <h6>We will try to comply with your request(s) as soon as reasonably practicable, but no later than within
          the timeframe required under applicable law.</h6>

      <h6><span>7.</span>Your California Privacy Rights</h6>
      <p>
          If you are a resident of the State of California, we provide you with information above on how to
          exercise your choice options such as your right to opt-out or opt-in for use of your personal
          information by third parties for marketing purposes. Therefore, pursuant to the California Civil Code,
          we are not required to maintain or disclose a list of the third parties that received your personal
          information for marketing purposes during the preceding year. If you are a California resident and wish
          to request information about how to exercise your third party disclosure choices or other rights
          afforded under the law of the State of California, please contact us at <a
              href="admin@1o1Fitness.com">admin@1o1Fitness.com.</a> All
          requests must be labeled “Your California Privacy Rights” in the topic line. For all requests, please
          clearly state that the request is related to “Your California Privacy Rights,” include your name, street
          address, city, state, zip code and e-mail address and indicate your preference on how our response to
          your request should be sent (email or postal mail). We will not accept requests via the telephone or by
          fax. We are not responsible for notices that are not labeled or sent properly or that do not have
          complete information.
      </p>

  </section>

  <section>
      <h6><span>8.</span> International Visitors and Data Transfers</h6>
      <p>
          Our operations are supported by a network of computers, cloud-based servers and other infrastructure and
          information technology, including, but not limited to, third-party service providers. We and some of our
          affiliates, partners, servers, vendors and service providers may not be located in your jurisdiction and
          may also be located outside of the European Economic Area and Switzerland. Further, the Services are
          hosted in and managed from the United States of America (“U.S.”). If you visit the Services from a
          location outside the U.S., please be advised that any information you provide may be processed in and/or
          transferred to or from the U.S. or other jurisdictions, where privacy protections may not be as
          comprehensive as those in your jurisdiction.
      </p>
  </section>

  <section>
      <h6><span>9.</span> Other Information You Should Know</h6>
      <p>From time to time, we may modify this Policy to reflect industry initiatives, third party requirements or
          changes in the law, technology, our information processing practices or the features and functionality
          of the Services, and such modifications shall be effective upon posting. When we change this Policy in a
          material way, a notice will be posted on the Services along with the updated Policy. Your continued use
          of the Services after we post a revised Policy (and, where appropriate, notify you of the same)
          signifies your acceptance of the revised Policy. It is therefore important that you review this Policy
          regularly to ensure you are updated as to any revisions made to it. We may continue to process your
          information based on our legitimate interests or in order to fulfill our obligations under a contract
          and/or may also seek your affirmative consent to the terms of the updated Policy. For example, we may
          ask for your consent before implementing new uses of the personal data that we’ve already collected from
          you based on such consent, if such new use was not addressed by the privacy policy under which such
          information was collected or if our legal basis for processing such information changes.
      </p>
      <p>
          The Services may be linked to, rely on and/or be integrated with websites, applications, interfaces,
          services and/or platforms operated by third party companies, including service providers, and Third
          Party Platforms. The Services may also feature advertisements from other third parties. 1o1Fitness is
          not responsible for the privacy practices of any websites, applications, interfaces, services and
          platforms operated by such third parties that are linked to, rely on and/or integrated with the
          Services, or for the privacy practices of third party advertising companies. Once you leave this
          Services via such a link, access a third party application, interface, service, or platform, including
          Third Party Platforms and websites operated by service providers or third parties, or click on or
          navigate to an advertisement, you should check the applicable privacy policy of such third party to
          determine, among other things, how they will handle any personally identifiable or other information
          that they may collect from you.

      </p>

  </section>

  <section>
      <h6> <span>10.</span> Contact Us</h6>
      <p>If you have any questions or concerns about our privacy practices, how we handle your personal data or
          any of the terms or conditions of this Policy, please feel free to contact us at: <a
              href="admin@1o1Fitness.com.">admin@1o1Fitness.com.</a></p>

  </section>

  <section>
      <h6><span>11.</span> Description of Cookies</h6>
      <p>
          A cookie is a small data file that is placed on the hard drive of your device when you visit a website.
          Some cookies are functional and enable additional functionality when a user accesses the website (e.g.,
          allows users to save user preferences or language suggestions). Other cookies are non-functional and
          allow the website and third parties to tailor messages and advertising relevant to a user’s interests.
          When we refer to cookies, this includes other similar technologies such as flash cookies, pixel tags,
          web beacons or bugs (including transparent or clear gifs). We use cookies to keep track of how you use
          the Services so we may provide a personalized service. </p>
  </section>

  <section>
      <h6><span>12.</span>What Cookies We Use?</h6>
      <p>
          We do not place cookies until you elect to continue using the Services after our cookie banner displays.
          We use the following types of cookies:
      </p>
      <ul>
          <li>
              <u>Strictly Necessary Cookies</u>: These cookies are necessary for the Site to function and cannot
              be
              switched off in our systems. They are usually only set in response to actions made by you which
              amount to a request for services, such as setting your privacy preferences, logging in or filling in
              forms. You can set your browser to block or alert you about these cookies, but some parts of the
              Services may not work if you choose to do so.

          </li>
          <li>
              <u>Performance Cookies</u>: These cookies allow us to count visits and traffic sources, so we can
              measure
              and improve the performance of the Services. They help us know which pages are the most and least
              popular and see how visitors move around the Site. All information these cookies collect is
              aggregated and therefore anonymous. If you do not allow these cookies, we will not know when you
              have visited the Services.
          </li>
          <li>
              <u>Functional Cookies</u>: These cookies allow the provision of enhanced functionality and
              personalization,
              such as videos and live chats. They may be set by us or by third party providers whose services we
              have added to our pages. If you do not allow these cookies, then some or all of these
              functionalities may not function properly.
          </li>
      </ul>
      <p>For more information about the kinds of cookies we deploy, the purposes and duration for which they are
          deployed, and how to control the use of cookies on your device please email us at <a href="privacy@1o1
          Fitness.com">privacy@1o1
              Fitness.com.</a>
      </p>
  </section>

  <section>

      <h6><span>13.</span>What Information Is Processed by Cookies</h6>
      <p>
          Our cookies and other tracking technologies will collect persistent identification numbers to enable the
          Services to recognize you as a repeat visitor and apply your settings, preferences you set on the
          Services, actions you take on the Services, and your browsing history. Your data will be processed in
          the EU and in the United States in accordance with this Policy.
      </p>

  </section>

  <section>
      <h6><span>14.</span>How We Deploy Cookies</h6>

      <p>
          <b>Our Cookies.</b> We deploy cookies to help us to identify how users navigate to and around the
          Services, to
          track your use of the Site (e.g., to enable us to ensure the Site is performing properly) and to enable
          some
          of the features within the Site that may be beneficial to you (e.g., managing your session), to
          recognize
          you when you return to the Services (this speeds up your access to the Site as you do not have to log in
          each time), to carry out research and statistical analysis to help improve our content, products and
          services, to secure the login process and to make your online experience more efficient and enjoyable.
          We
          also use cookies to analyze audience size and user experience. Further information is provided in the
          table
          below.
      </p>

      <p>
          <b>Third-party cookies.</b>
          We may also use cookies issued by third parties to display content from third parties. The third party
          suppliers are responsible for the cookies they set on the Services. If you want further information
          please go to the website for the relevant third party.

      </p>
      <p>Our use of cookies helps us deliver an effective and personalized online service to you.</p>
  </section>

  <section>
      <h6> <span> 15.</span>Our Legal Basis for Processing Information Collected by Cookies</h6>
      <p>
          The information we obtain from our use of cookies may contain your personal data but only where you
          voluntarily provide it (e.g., by completing an online form or entering your email address into a
          subscriber box) or where you purchase goods or services from us. We will process your personal data for
          strictly necessary and performance cookies based on our legitimate interests. For functional cookies, we
          will process your personal data based on your consent. For more information about how we process your
          personal information, obtain consent and how you may withdraw consent, please see <u>Sections 1-10</u>
          of this
          Policy.
      </p>
      <p>
          <b>By continuing to browse on the Services you consent to us deploying cookies as described in this
              Policy. If you do not wish to accept cookies from the Services, or would like to stop cookies being
              stored on your device in the future, you can change the settings in your web browser or in your
              operating system preferences on your device or use the links provided below to decline and/or delete
              cookies. See the "Help" section your browser menu for guidance on how to do this. Note that changing
              cookie settings may affect certain features within this website. </b>
      </p>
      <p>
          <b>
              If you have any questions about our use of cookies or other technologies or this Policy, you may
              email us at <a href="admin@1o1Fitness.com">admin@1o1Fitness.com.</a> </b>
      </p>

      <p>
          <b>
              [NOTE TO 1O1FITNESS: TO CONFIRM THAT NO OTHER COOKIES SHOULD BE LISTED IN THIS CHART.] [NOTE:
              CONSIDER DELETING CHART}
          </b>
      </p>

      <table class="table-bordered">
          <thead class="text-center">
              <th colspan="1" rowspan="1">
                  <p>PARTY SETTING COOKIE (E.G., 1O1FITNESS OR
                      PARTNER)</p>
              </th>
              <th colspan="1" rowspan="1">
                  <p>TYPE OF COOKIE</p>
              </th>
              <th colspan="1" rowspan="1">
                  <p>PURPOSE/ FUNCTIONALITY</p>
              </th>
              <th colspan="1" rowspan="1">
                  <p>MORE INFORMATION AND HOW TO ADJUST SPECIFIC COOKIES&rsquo;
                      SETTINGS</p>
              </th>

          </thead>
          <tbody class="text-center">

              <tr>
                  <td colspan="1" rowspan="1">
                      <p>1o1Fitness</p>
                  </td>
                  <td colspan="1" rowspan="1">
                      <p>Functional</p>
                  </td>
                  <td colspan="1" rowspan="1">
                      <p>To detect when visitors have revisited the Site.</p>
                  </td>
                  <td colspan="1" rowspan="1">
                      <p>The application will share the domain and value details to cookies to
                          identify the guest user.</p>
                  </td>
              </tr>
              <tr>
                  <td colspan="1" rowspan="1">
                      <p>Google Analytics</p>
                  </td>
                  <td colspan="1" rowspan="1">
                      <p></p>
                  </td>
                  <td colspan="1" rowspan="1">
                      <p> Google Analytics is a site analytics tool that provides insight on user
                          behavior. These cookies are used to better understand how users are using the Site so we
                          can
                          improve and optimize the Site, creating the best possible experience for users.
                      </p>
                  </td>
                  <td colspan="1" rowspan="1">
                      <p> You can find out how to opt out of these cookies here. </p>
                      <p> This link will open in a new window. 1o1Fitness is not responsible for the content of
                          external websites.</p>
                  </td>
              </tr>
              <tr>
                  <td colspan="1" rowspan="1">
                      <p> Facebook </p>
                  </td>
                  <td colspan="1" rowspan="1">
                      <p> </p>
                  </td>
                  <td colspan="1" rowspan="1">
                      <p> Facebook uses cookies to help us understand the kinds of people who like
                          our Facebook page enabling us to provide more relevant content and develop features that
                          resonate with our users. </p>
                  </td>
                  <td colspan="1" rowspan="1">
                      <p> To learn more or to opt-out of the collection and use of information for ad targeting,
                          visit <a href="www.youronlinechoices.com/uk">www.youronlinechoices.com/uk.</a>
                          This link will open in a new window. 1o1Fitness is not responsible for the content of
                          external websites.
                      </p>
                  </td>
              </tr>
          </tbody>
      </table>

      <p class="text-center">Copyright © 2024 1o1fitness, Inc.</p>

  </section>
</div>