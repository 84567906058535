import { Component, OnInit, Input, OnDestroy, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { UserLoginService } from "../service/user-login.service";
import { LoggedInCallback, CognitoUtil } from "../service/cognito.service";
import { AppGlobals } from '../app.globals';
import { DBService } from '../service/db.service';
import { ToastrService } from 'ngx-toastr';
import { Integer } from 'aws-sdk/clients/lambda';
import { AWSService } from '../service/aws.service';
// import { provideForRootGuard } from '@angular/router/src/router_module';
import { DatePipe } from '@angular/common';
import { IMyDpOptions } from 'mydatepicker';
import moment from 'moment';
import { error } from 'console';
import { YogaDBService } from '../service/YogaDBService';
// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

export class program {
  trainerId: string;
  programId: string;
  programName: string;
  plans: Integer;
  weeks: Integer;
  programDuration: Integer;
  priceInRupees: number;
  currency: Object[];
  description: string;
  programImage: Object;
  status: string;
  adminStatus: string;
  startDate: string;
  packageType: Object;
  enrollmentDate: string;
  nutrition: Object;
  ProgramAddons: Object[];
  priceInDollars: number;
  endDate: string;
  enrollmentEndDate: string;
  programType: Object;
  createdDate: Date;
  lastUpdateOn: Date;
  trainingDays: Object[];
  numberOfSlots: number;
  slots: Object[];
  isContentProgram: boolean;
  isOnlineProgram: boolean;
  numberOfPartcipants: number;
  callDuration: Object;
  externalCallLink: string;
}

export class IMyDateModel {
  date: IMyDate;
  jsdate: Date;
  formatted: string;
  epoc: number;
}

export class IMyDate {
  year: number;
  month: number;
  day: number;
}

@Component({
  selector: 'app-addprogram-yoga',
  templateUrl: './addprogram-yoga.component.html',
  styleUrls: ['./addprogram-yoga.component.css']
})
export class AddprogramYogaComponent implements OnInit, OnDestroy, LoggedInCallback {

  @Input() imgUrl: string = "assets/images/uploadimg.svg";
  currentCategory: string;
  currentGender: string;
  program: program;
  dropdownSettings_single: any = {};
  closeDropdownSelection = false;
  Nutritions: Object[];
  currency: Object[];
  packagetypes: Object[];
  programTypes: Object[];
  noOfSlots: Object[];
  noOfParticipants: Object[];
  selectedPackage: Object[];
  SelectedNutrition: Object[];
  SelectedProgramType: Object[];
  SelectedSlots: Object[];
  SelectedPraticipants: Object[];
  selectedDays: any = [];
  SelectedCurrency: Object[];
  programEdit: boolean = false;
  showCalendarPopup: boolean = false;
  isCurrencyDisabled: boolean = false;
  isPremiumProgram: boolean = false;
  isNutritionShow: boolean = true;
  isIndian: boolean = false;
  country: string;
  timezone: string;
  dateFormat: string;
  frequencies: Array<Object> = [];
  slotsTimes: Array<Object> = [];
  selectedFrequency = [];

  // tax pop up
  taxpopup: boolean = false;
  programPrice: number = 0;
  servicetax_1o1: string = "";
  gst_tax: string = "";
  grand_total: string = "";
  grand_tax_total: number;
  display_currency: string = "";
  tax_details = {
    "service_tax": 0,
    gst_tax: 0,
    min_price_in_rupees: 0,
    min_price_in_dollers: 0
  }


  // Google Var
  googleUserEmail: string;
  private loggedIn: boolean;
  private subcrptn_1: any;
  currentLogged: string;
  currentEmail: string;
  selectedFile: File;
  fileExtension: any;
  loaderHide: boolean = false;
  isImgUploaded: string = "NO";
  private sub: any;
  editTrue: boolean = true;
  isDisabled: boolean = false;
  isDurationDisabled: boolean = false;
  startDate: IMyDateModel;
  endDate: IMyDateModel
  enrollmentEndDate: IMyDateModel;
  enrollmentDate: IMyDateModel;

  stepOne: boolean = true;

  programImage: any = {
    programImg: "",
    imgPath: "",
    imageVerfied: false,
    verifiedOn: Date,
    uploadedOn: Date
  }

  testStartDate;
  testEnrollDate;
  testEnrollEndDate;

  timeintervals: object[];
  selectedEndTime = [];
  selectedStartTime = [];
  duplicate_slots: Object[];
  showSlotsPopup: boolean = false;

  //  selstedDays:String[];
  //  selectedSlots:String[];
  public monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];


  myDatePickerOptions: IMyDpOptions = {};
  isOnlineProgram: boolean;
  isContentProgram: boolean;
  displaySlotsTimes: any;
  nOfP: any;

  constructor(private renderer: Renderer2, private awsService: AWSService, public router: Router, public route: ActivatedRoute,
    public userService: UserLoginService, private yoga_db_service: YogaDBService, private globals: AppGlobals, private db_service: DBService, private toastr: ToastrService
    , public cognitoUtil: CognitoUtil, public datepipe: DatePipe) {
    this.userService.isAuthenticated(this);
  }

  ngOnInit() {
    this.currentCategory = localStorage.getItem("ProfileCategory")
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.program = new program();
    this.program.isContentProgram = true;
    this.program.plans = 1;
    this.program.weeks = 1;
    this.program.programDuration = 1;
    this.sub = this.route.params.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.program.programId = params['programID'];
        this.programEdit = true;
        this.getProgramDataFromerver();
        //this.getProfileDataById();
      }
    });
    // this.showSlotsPopup = true;
    this.selectedDays = [];
    this.selectedFrequency = [];
    this.SelectedSlots = [];
    this.SelectedPraticipants = [];
    this.slotsTimes = [];
    this.displaySlotsTimes = [];
    //  this.selstedDays = [];
    // this.selectedSlots = ["08:00"];
    this.isContentProgram = false;
    this.isOnlineProgram = false;
    this.selectedFrequency = [];
    // this.currentCategory = localStorage.getItem("ProfileCategory")
    this.currentGender = localStorage.getItem("ProfileGender");
    if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-build-male');
    }
    else if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-build-female');
    }
    else if (this.currentCategory == 'Yoga' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-yoga-male');
    }
    else if (this.currentCategory == 'Yoga' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-yoga-female');
    }
    else if (this.currentCategory == 'Zumba' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-zumba-male');
    }
    else if (this.currentCategory == 'Zumba' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-zumba-female');
    }

    this.userService.currentUserID.subscribe((val) => {
      if (val != '') {
        this.program.trainerId = val;
      }
    });
    this.dropdownSettings_single = {
      singleSelection: true,
      enableCheckAll: false,
      idField: 'id',
      textField: 'name',
      allowSearchFilter: false,
      itemsShowLimit: 1,
      closeDropDownOnSelection: this.closeDropdownSelection
    };
    this.Nutritions = [{ "id": "0", "name": "Yes" },
    { "id": "1", "name": "No" }]
    this.currency = [{ "id": "0", "name": "Dollar" },
    { "id": "1", "name": "Rupees" }]
    this.packagetypes = [{ "id": "0", "name": "Beginner" },
    { "id": "1", "name": "Intermediate" },
    { "id": "2", "name": "Advanced" }]
    this.programTypes = [{ "id": "1", "name": "Regular program" },
    { "id": "2", "name": "Premium program" }]
    this.noOfSlots = [{ "id": "1", "name": 1 },
    { "id": "2", "name": 2 },
    { "id": "3", "name": 3 }]
    this.noOfParticipants = [{ "id": "1", "name": 15 },
    { "id": "2", "name": 30 },
    { "id": "3", "name": 50 }]
    this.frequencies = [
      { id: 2, name: '30 minutes' },
      { id: 3, name: '45 minutes' },
      { id: 4, name: '60 minutes' },
    ];
    if (this.timezone.includes("asia") || this.timezone.includes("Asia")) {
      this.myDatePickerOptions = {
        todayBtnTxt: 'Today',
        dateFormat: 'dd-mm-yyyy',
        firstDayOfWeek: 'mo',
        sunHighlight: true,
        openSelectorOnInputClick: true,
        editableDateField: false,
        inline: false
      }
      this.dateFormat = "dd-MM-yyyy";
    } else {
      this.myDatePickerOptions = {
        todayBtnTxt: 'Today',
        dateFormat: 'mm-dd-yyyy',
        firstDayOfWeek: 'mo',
        sunHighlight: true,
        openSelectorOnInputClick: true,
        editableDateField: false,
        inline: false
      }
      this.dateFormat = "MM-dd-yyyy";
    }

    this.timeintervals = [
      { id: 1, name: '00:00' },
      { id: 2, name: '00:05' },
      { id: 3, name: '00:10' },
      { id: 4, name: '00:15' },
      { id: 5, name: '00:20' },
      { id: 6, name: '00:25' },
      { id: 7, name: '00:30' },
      { id: 8, name: '00:35' },
      { id: 9, name: '00:40' },
      { id: 10, name: '00:45' },
      { id: 11, name: '00:50' },
      { id: 12, name: '00:55' },
      { id: 13, name: '01:00' },
      { id: 14, name: '01:05' },
      { id: 15, name: '01:10' },
      { id: 16, name: '01:15' },
      { id: 17, name: '01:20' },
      { id: 18, name: '01:25' },
      { id: 19, name: '01:30' },
      { id: 20, name: '01:35' },
      { id: 21, name: '01:40' },
      { id: 22, name: '01:45' },
      { id: 23, name: '01:50' },
      { id: 24, name: '01:55' },
      { id: 25, name: '02:00' },
      { id: 26, name: '02:05' },
      { id: 27, name: '02:10' },
      { id: 28, name: '02:15' },
      { id: 29, name: '02:20' },
      { id: 30, name: '02:25' },
      { id: 31, name: '02:30' },
      { id: 32, name: '02:35' },
      { id: 33, name: '02:40' },
      { id: 34, name: '02:45' },
      { id: 35, name: '02:50' },
      { id: 36, name: '02:55' },
      { id: 37, name: '03:00' },
      { id: 38, name: '03:05' },
      { id: 39, name: '03:10' },
      { id: 40, name: '03:15' },
      { id: 41, name: '03:20' },
      { id: 42, name: '03:25' },
      { id: 43, name: '03:30' },
      { id: 44, name: '03:35' },
      { id: 45, name: '03:40' },
      { id: 46, name: '03:45' },
      { id: 47, name: '03:50' },
      { id: 48, name: '03:55' },
      { id: 49, name: '04:00' },
      { id: 50, name: '04:05' },
      { id: 51, name: '04:10' },
      { id: 52, name: '04:15' },
      { id: 53, name: '04:20' },
      { id: 54, name: '04:25' },
      { id: 55, name: '04:30' },
      { id: 56, name: '04:35' },
      { id: 57, name: '04:40' },
      { id: 58, name: '04:45' },
      { id: 59, name: '04:50' },
      { id: 60, name: '04:55' },
      { id: 61, name: '05:00' },
      { id: 62, name: '05:05' },
      { id: 63, name: '05:10' },
      { id: 64, name: '05:15' },
      { id: 65, name: '05:20' },
      { id: 66, name: '05:25' },
      { id: 67, name: '05:30' },
      { id: 68, name: '05:35' },
      { id: 69, name: '05:40' },
      { id: 70, name: '05:45' },
      { id: 71, name: '05:50' },
      { id: 72, name: '05:55' },
      { id: 73, name: '06:00' },
      { id: 74, name: '06:05' },
      { id: 75, name: '06:10' },
      { id: 76, name: '06:15' },
      { id: 77, name: '06:20' },
      { id: 78, name: '06:25' },
      { id: 79, name: '06:30' },
      { id: 80, name: '06:35' },
      { id: 81, name: '06:40' },
      { id: 82, name: '06:45' },
      { id: 83, name: '06:50' },
      { id: 84, name: '06:55' },
      { id: 85, name: '07:00' },
      { id: 86, name: '07:05' },
      { id: 87, name: '07:10' },
      { id: 88, name: '07:15' },
      { id: 89, name: '07:20' },
      { id: 90, name: '07:25' },
      { id: 91, name: '07:30' },
      { id: 92, name: '07:35' },
      { id: 93, name: '07:40' },
      { id: 94, name: '07:45' },
      { id: 95, name: '07:50' },
      { id: 96, name: '07:55' },
      { id: 97, name: '08:00' },
      { id: 98, name: '08:05' },
      { id: 99, name: '08:10' },
      { id: 100, name: '08:15' },
      { id: 101, name: '08:20' },
      { id: 102, name: '08:25' },
      { id: 103, name: '08:30' },
      { id: 104, name: '08:35' },
      { id: 105, name: '08:40' },
      { id: 106, name: '08:45' },
      { id: 107, name: '08:50' },
      { id: 108, name: '08:55' },
      { id: 109, name: '09:00' },
      { id: 110, name: '09:05' },
      { id: 111, name: '09:10' },
      { id: 112, name: '09:15' },
      { id: 113, name: '09:20' },
      { id: 114, name: '09:25' },
      { id: 115, name: '09:30' },
      { id: 116, name: '09:35' },
      { id: 117, name: '09:40' },
      { id: 118, name: '09:45' },
      { id: 119, name: '09:50' },
      { id: 120, name: '09:55' },
      { id: 121, name: '10:00' },
      { id: 122, name: '10:05' },
      { id: 123, name: '10:10' },
      { id: 124, name: '10:15' },
      { id: 125, name: '10:20' },
      { id: 126, name: '10:25' },
      { id: 127, name: '10:30' },
      { id: 128, name: '10:35' },
      { id: 129, name: '10:40' },
      { id: 130, name: '10:45' },
      { id: 131, name: '10:50' },
      { id: 132, name: '10:55' },
      { id: 133, name: '11:00' },
      { id: 134, name: '11:05' },
      { id: 135, name: '11:10' },
      { id: 136, name: '11:15' },
      { id: 137, name: '11:20' },
      { id: 138, name: '11:25' },
      { id: 139, name: '11:30' },
      { id: 140, name: '11:35' },
      { id: 141, name: '11:40' },
      { id: 142, name: '11:45' },
      { id: 143, name: '11:50' },
      { id: 144, name: '11:55' },
      { id: 145, name: '12:00' },
      { id: 146, name: '12:05' },
      { id: 147, name: '12:10' },
      { id: 148, name: '12:15' },
      { id: 149, name: '12:20' },
      { id: 150, name: '12:25' },
      { id: 151, name: '12:30' },
      { id: 152, name: '12:35' },
      { id: 153, name: '12:40' },
      { id: 154, name: '12:45' },
      { id: 155, name: '12:50' },
      { id: 156, name: '12:55' },
      { id: 157, name: '13:00' },
      { id: 158, name: '13:05' },
      { id: 159, name: '13:10' },
      { id: 160, name: '13:15' },
      { id: 161, name: '13:20' },
      { id: 162, name: '13:25' },
      { id: 163, name: '13:30' },
      { id: 164, name: '13:35' },
      { id: 165, name: '13:40' },
      { id: 166, name: '13:45' },
      { id: 167, name: '13:50' },
      { id: 168, name: '13:55' },
      { id: 169, name: '14:00' },
      { id: 170, name: '14:05' },
      { id: 171, name: '14:10' },
      { id: 172, name: '14:15' },
      { id: 173, name: '14:20' },
      { id: 174, name: '14:25' },
      { id: 175, name: '14:30' },
      { id: 176, name: '14:35' },
      { id: 177, name: '14:40' },
      { id: 178, name: '14:45' },
      { id: 179, name: '14:50' },
      { id: 180, name: '14:55' },
      { id: 181, name: '15:00' },
      { id: 182, name: '15:05' },
      { id: 183, name: '15:10' },
      { id: 184, name: '15:15' },
      { id: 185, name: '15:20' },
      { id: 186, name: '15:25' },
      { id: 187, name: '15:30' },
      { id: 188, name: '15:35' },
      { id: 189, name: '15:40' },
      { id: 190, name: '15:45' },
      { id: 191, name: '15:50' },
      { id: 192, name: '15:55' },
      { id: 193, name: '16:00' },
      { id: 194, name: '16:05' },
      { id: 195, name: '16:10' },
      { id: 196, name: '16:15' },
      { id: 197, name: '16:20' },
      { id: 198, name: '16:25' },
      { id: 199, name: '16:30' },
      { id: 200, name: '16:35' },
      { id: 201, name: '16:40' },
      { id: 202, name: '16:45' },
      { id: 203, name: '16:50' },
      { id: 204, name: '16:55' },
      { id: 205, name: '17:00' },
      { id: 206, name: '17:05' },
      { id: 207, name: '17:10' },
      { id: 208, name: '17:15' },
      { id: 209, name: '17:20' },
      { id: 210, name: '17:25' },
      { id: 211, name: '17:30' },
      { id: 212, name: '17:35' },
      { id: 213, name: '17:40' },
      { id: 214, name: '17:45' },
      { id: 215, name: '17:50' },
      { id: 216, name: '17:55' },
      { id: 217, name: '18:00' },
      { id: 218, name: '18:05' },
      { id: 219, name: '18:10' },
      { id: 220, name: '18:15' },
      { id: 221, name: '18:20' },
      { id: 222, name: '18:25' },
      { id: 223, name: '18:30' },
      { id: 224, name: '18:35' },
      { id: 225, name: '18:40' },
      { id: 226, name: '18:45' },
      { id: 227, name: '18:50' },
      { id: 228, name: '18:55' },
      { id: 229, name: '19:00' },
      { id: 230, name: '19:05' },
      { id: 231, name: '19:10' },
      { id: 232, name: '19:15' },
      { id: 233, name: '19:20' },
      { id: 234, name: '19:25' },
      { id: 235, name: '19:30' },
      { id: 236, name: '19:35' },
      { id: 237, name: '19:40' },
      { id: 238, name: '19:45' },
      { id: 239, name: '19:50' },
      { id: 240, name: '19:55' },
      { id: 241, name: '20:00' },
      { id: 242, name: '20:05' },
      { id: 243, name: '20:10' },
      { id: 244, name: '20:15' },
      { id: 245, name: '20:20' },
      { id: 246, name: '20:25' },
      { id: 247, name: '20:30' },
      { id: 248, name: '20:35' },
      { id: 249, name: '20:40' },
      { id: 250, name: '20:45' },
      { id: 251, name: '20:50' },
      { id: 252, name: '20:55' },
      { id: 253, name: '21:00' },
      { id: 254, name: '21:05' },
      { id: 255, name: '21:10' },
      { id: 256, name: '21:15' },
      { id: 257, name: '21:20' },
      { id: 258, name: '21:25' },
      { id: 259, name: '21:30' },
      { id: 260, name: '21:35' },
      { id: 261, name: '21:40' },
      { id: 262, name: '21:45' },
      { id: 263, name: '21:50' },
      { id: 264, name: '21:55' },
      { id: 265, name: '22:00' },
      { id: 266, name: '22:05' },
      { id: 267, name: '22:10' },
      { id: 268, name: '22:15' },
      { id: 269, name: '22:20' },
      { id: 270, name: '22:25' },
      { id: 271, name: '22:30' },
      { id: 272, name: '22:35' },
      { id: 273, name: '22:40' },
      { id: 274, name: '22:45' },
      { id: 275, name: '22:50' },
      { id: 276, name: '22:55' },
      { id: 277, name: '23:00' },
      { id: 278, name: '23:05' },
      { id: 279, name: '23:10' },
      { id: 280, name: '23:15' },
      { id: 281, name: '23:20' },
      { id: 282, name: '23:25' },
      { id: 283, name: '23:30' },
      { id: 284, name: '23:35' },
      { id: 285, name: '23:40' },
      { id: 286, name: '23:45' },
      { id: 287, name: '23:50' },
      { id: 288, name: '23:55' }
    ];

    this.getMasterConfigurations();


  }
  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'bg-img-build-male');
    this.renderer.removeClass(document.body, 'bg-img-yoga-male');
    this.renderer.removeClass(document.body, 'bg-img-zumba-male');
    this.renderer.removeClass(document.body, 'bg-img-build-female');
    this.renderer.removeClass(document.body, 'bg-img-yoga-female');
    this.renderer.removeClass(document.body, 'bg-img-zumba-female');
    this.renderer.addClass(document.body, 'body-main');
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/login']);
    } else {
      // if()
      if (this.loggedIn == true) {
        this.currentEmail = localStorage.getItem('lastAuthUser');
        // if(this.currentEmail != undefined)
        this.getProfileDataFromServer();
      }
      else {
        // this.currentEmail = localStorage.getItem('CognitoIdentityServiceProvider.2io8v4r253hugt5ndbjv9shkfp.LastAuthUser');
        this.currentEmail = this.cognitoUtil.getCurrentUser().getUsername();
        if (this.currentEmail == undefined || this.currentEmail == '' || this.currentEmail == null || this.currentEmail.includes("Google") || this.currentEmail.includes("google")) {
          this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
          if (this.currentEmail == undefined)
            this.currentEmail = this.cognitoUtil.session.idToken.payload.email;
        }
        // this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
        this.getProfileDataFromServer();
      }
    }
  }
  getProgramDataFromerver() {
    var index = '/program/';
    this.yoga_db_service.getDataFromServer(this.program.programId, index).subscribe(
      response => {
        this.getProfileDataById();
        this.program = response.data;
        //  this.SelectedCurrency = this.program.currency;
        this.selectedPackage = Array.of(this.program.packageType);
        this.SelectedNutrition = Array.of(this.program.nutrition);
        this.programImage = this.program.programImage;
        this.imgUrl = this.programImage.imgPath;
        this.SelectedProgramType = Array.of(this.program.programType);
        // this.SelectedSlots = Array.of(this.program.programType);



        var d = new Date(this.program.startDate);
        // this.startDate = d;
        // this.startDate = this.datepipe.transform(d, 'yyyy-mm-dd');
        this.startDate = this.getConvertedDate(d);
        //console.log(this.startDate);

        var d2 = new Date(this.program.enrollmentDate);
        //this.enrollmentDate = this.datepipe.transform(d2, 'yyyy-mm-dd');
        this.enrollmentDate = this.getConvertedDate(d2);

        if (this.SelectedProgramType[0]["name"] == "Premium program") {
          this.isPremiumProgram = true;

          var d3 = new Date(this.program.endDate);
          //this.endDate = this.datepipe.transform(d3, 'yyyy-mm-dd');
          this.endDate = this.getConvertedDate(d3);

          if (this.program.enrollmentEndDate != null) {
            var d4 = new Date(this.program.enrollmentEndDate);
            //this.enrollmentEndDate = this.datepipe.transform(d4, 'yyyy-mm-dd');
            this.enrollmentEndDate = this.getConvertedDate(d4);
          }
        }

        if (this.program.isOnlineProgram) {
          this.selectedDays = this.program.trainingDays;
          this.SelectedPraticipants = Array.of(this.noOfParticipants.find(x => x["name"] == this.program.numberOfPartcipants));
          this.SelectedSlots = Array.of(this.noOfSlots.find(x => x["name"] == this.program.numberOfSlots));
          this.selectedFrequency = Array.of(this.program.callDuration);
          this.slotsTimes = this.program.slots;
          this.isOnlineProgram = this.program.isOnlineProgram;
          this.isContentProgram = this.program.isContentProgram;
          this.nOfP = this.program.numberOfPartcipants;
        }


        if (this.program.status == "New") {
          var index = '/calendar/';
          this.db_service.getDataFromServer(this.program.programId, index).subscribe(
            response => {
              var dayCount = 0;
              if (response.data != "") {
                for (let i = 0; i < response.data.weeks.length; i++) {
                  dayCount = dayCount + response.data.weeks[i]["days"].length;
                }
              }
              if (dayCount > 0) {
                this.isDurationDisabled = true;
              } else {
                this.isDurationDisabled = false;
              }

            },
            error => {
              this.toastr.error('', 'An error has occurred getting program data.', {
                timeOut: 5000,
                extendedTimeOut: 1000,
                positionClass: 'toast-top-right',
                progressBar: true,
                progressAnimation: 'increasing',
                tapToDismiss: true
              });
            }
          )
        }


        if (this.program.status != '' && this.program.status != null && this.program.status != "New" && this.program.status != "Rejected") {
          this.editTrue = false;
          this.isDisabled = true;
          this.isDurationDisabled = true;
        } else {
          this.editTrue = true;
          this.isDisabled = false;

        }

      },
      error => {
        // alert("An error has occurred while saving data.");
        this.toastr.error('', 'An error has occurred getting my program data.', {
          timeOut: 5000,
          extendedTimeOut: 1000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true
        });
      }
    )

  }

  getMasterConfigurations() {
    var index = "/masters/configuration/";
    this.db_service.getDataFromServer('1o1_service_tax', index).subscribe(
      response => {
        this.tax_details = response.values;
      },
      error => {
        console.log("error " + error);
      }
    )
  }

  getConvertedDate(dateToConvert: Date) {
    var iMyDate = new IMyDate();
    iMyDate.day = dateToConvert.getDate();
    iMyDate.month = dateToConvert.getMonth() + 1;
    iMyDate.year = dateToConvert.getFullYear();
    var displayDate = new IMyDateModel();
    displayDate.jsdate = dateToConvert;
    displayDate.date = iMyDate;
    displayDate.formatted = this.datepipe.transform(dateToConvert, this.dateFormat);
    displayDate.epoc = dateToConvert.getTime() / 1000;
    return displayDate;
  }
  getProfileDataById() {
    var index = '/profile/';
    this.yoga_db_service.getDataFromServer(this.program.trainerId, index).subscribe(
      response => {
        response = response.data[0];
        this.program.trainerId = response.trainerId;
        this.country = response.address[0].country;
        // this.country = "USA";
        if (this.country.toLowerCase().includes("india")) {
          // this.isCurrencyDisabled = true;
          this.isIndian = true;
        }
        else {
          // this.isCurrencyDisabled = false;
          this.isIndian = false;
        }

        if (response.isNutritionist != undefined) {
          if (response.isNutritionist.status == "Yes") {
            this.isNutritionShow = true;
          } else {
            this.isNutritionShow = false;
          }
        } else {
          this.isNutritionShow = false;
        }
      },
      error => {
        console.log("An error has occurred while retriving profile data.");
      }
    )
  }

  onDateChanged(event: IMyDateModel) {
    this.onDateChange(event.jsdate);
  }
  onDateChange(date) {
    var new_date = moment(date, "DD-MM-YYYY").add('days', (this.program.programDuration * 7) - 1);
    var r_date = new_date.format('YYYY') + "-" + new_date.format('MM') + "-" + new_date.format('DD');
    this.endDate = this.getConvertedDate(new Date(r_date));
  }
  onDaySelect(day) {
    // var daySelected = day;
    if (this.selectedDays.indexOf(day) > -1)
      this.selectedDays.splice(this.selectedDays.indexOf(day), 1);
    else
      this.selectedDays.push(day)

    // this.selectedDays = this.selectedDays.filter((day));
    // console.log(JSON.stringify(this.selectedDays))
  }
  calculate1o1Percentage(currency) {

    // let priceRegex = new RegExp('^\d{1,6}(\.\d{1,2})?$');
    if (this.program.priceInRupees != undefined)
      var rupeeString = this.program.priceInRupees.toString();
    if (this.program.priceInDollars != undefined)
      var dollarString = this.program.priceInDollars.toString();
    var priceRegex = new RegExp('^[0-9]{1,6}(?:[.][0-9]{0,2})?$')

    if (currency == 'Rupees') {
      if (priceRegex.test(rupeeString) === false) {
        this.toastr.error('', 'Program Price in rupees cannot exceeed 6 digits with 2 decimals.', {
          timeOut: 5000,
          extendedTimeOut: 1000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true
        });
      }
      else {
        this.taxpopup = true;
        this.programPrice = this.program.priceInRupees;
        this.display_currency = '₹';
        this.gst_tax = ((this.programPrice * this.tax_details.gst_tax) / 100).toFixed(2);
        this.grand_total = (this.programPrice + ((this.programPrice * this.tax_details.gst_tax) / 100) - ((this.programPrice * this.tax_details.service_tax) / 100)).toFixed(2);
        this.grand_tax_total = this.programPrice + Number(this.gst_tax);
      }
    }
    else {
      if (priceRegex.test(dollarString) === false) {
        this.toastr.error('', 'Program Price in dollars cannot exceeed 6 digits with 2 decimals.', {
          timeOut: 5000,
          extendedTimeOut: 1000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true
        });
      }
      else {
        this.taxpopup = true;
        this.programPrice = this.program.priceInDollars;
        this.display_currency = '$';
        this.grand_total = (this.programPrice - ((this.programPrice * this.tax_details.service_tax) / 100)).toFixed(2);
        this.grand_tax_total = this.programPrice;
      }
    }
    this.servicetax_1o1 = ((this.programPrice * this.tax_details.service_tax) / 100).toFixed(2);
    // }
  }


  getNewFormatedDate(cDate) {
    var d = "";
    if (cDate.getDate() < 10 || cDate.getMonth() < 10) {
      var date = "";
      var mon = "";
      if (cDate.getDate() < 10) {
        date = "0" + cDate.getDate();
      } else {
        date = "" + cDate.getDate();
      }
      console.log(cDate.getMonth());
      if (cDate.getMonth() < 9) {
        mon = "0" + (cDate.getMonth() + 1);
      } else {
        mon = "" + (cDate.getMonth() + 1);
      }
      d = cDate.getFullYear() + "-" + mon + "-" + date;
    } else {
      d = cDate.getFullYear() + "-" + (cDate.getMonth() + 1) + "-" + cDate.getDate();
    }

    return d;
  }
  getProfileDataFromServer() {
    var index = '/profile/';
    this.yoga_db_service.getDataFromServer(this.currentEmail, index).subscribe(
      response => {
        //console.log('Data Retrived succesfully.', response);
        response = response.data[0];
        this.program.trainerId = response.trainerId;
        this.country = response.address[0].country;
        // this.country = "USA";
        if (this.country.toLowerCase().includes("india")) {
          //  this.isCurrencyDisabled = true;
          this.isIndian = true;
        }
        else {
          // this.isCurrencyDisabled = false;
          this.isIndian = false;
        }
        if (response.isNutritionist != undefined) {
          if (response.isNutritionist.status == "Yes") {
            this.isNutritionShow = true;
          } else {
            this.isNutritionShow = false;
          }
        } else {
          this.isNutritionShow = false;
        }
      },
      error => {
        console.log("An error has occurred while retriving profile data.");
      }
    )
  }
  calculate() {
    if (this.program.plans != undefined && this.program.plans != null && this.program.weeks != undefined && this.program.weeks != null) {
      if (this.program.programId != undefined) {
        var index = '/calendar/';
        this.db_service.getDataFromServer(this.program.programId, index).subscribe(
          response => {
            var dayCount = 0;
            if (response.data != "") {
              for (let i = 0; i < response.data.weeks.length; i++) {
                dayCount = dayCount + response.data.weeks[i]["days"].length;
              }
            }
            if (dayCount > 0) {
              this.toastr.error('', 'Calender dependency exist you cannot edit duration.', {
                timeOut: 5000,
                extendedTimeOut: 1000,
                positionClass: 'toast-top-right',
                progressBar: true,
                progressAnimation: 'increasing',
                tapToDismiss: true
              });
            } else {
              this.program.programDuration = this.program.plans * this.program.weeks;
            }
          },
          error => {
            this.toastr.error('', 'An error has occurred getting program data.', {
              timeOut: 5000,
              extendedTimeOut: 1000,
              positionClass: 'toast-top-right',
              progressBar: true,
              progressAnimation: 'increasing',
              tapToDismiss: true
            });
          }
        )

      } else
        this.program.programDuration = this.program.plans * this.program.weeks;
      if (this.startDate != undefined) {
        this.onDateChange(this.startDate.jsdate);
      }
    }
  }
  onItemSelect() {
    if (this.SelectedProgramType[0]["name"] != "Regular program") {
      this.isPremiumProgram = true;
    } else {
      this.isPremiumProgram = false;
      this.isContentProgram = true;
    }
  }
  onItemDeselect() {
    this.isPremiumProgram = false;
    this.isContentProgram = false;
  }

  onParticipantSelect() {
    this.nOfP = this.SelectedPraticipants[0]["name"];
  }
  onSlotSelect() {
    if (this.SelectedSlots[0]["name"] > this.slotsTimes.length) {

      for (let i = this.slotsTimes.length + 1; i <= this.SelectedSlots[0]["name"]; i++) {
        var s = {
          id: i,
          startTime: [],
          endTime: []
        };

        // var ds = {
        //   id: i,
        //   startTime: "",
        //   endTime: ""
        // };
        this.slotsTimes.push(JSON.parse(JSON.stringify(s)));
        // this.displaySlotsTimes.push(JSON.parse(JSON.stringify(s)));
      }

    } else {
      for (let i = this.slotsTimes.length; i > this.SelectedSlots[0]["name"]; i--) {
        this.slotsTimes.splice(-1, 1);
      }
      // this.displaySlotsTimes.splice(-1, 1);
    }
    // var a = this.SelectedSlots[0].name;
  }
  onSlotDeselect() {
    this.slotsTimes = [];
    this.displaySlotsTimes = [];
    // var a = this.SelectedSlots[0].name;
  }

  validateForm(step) {
    if (step == "step1") {
      var atleastOneAlphaPName;
      var atleastOneAlphaPDesc;

      if (this.program.programName != undefined) {
        atleastOneAlphaPName = this.program.programName.match(".*[a-zA-Z]+.*");
      }
      if (this.program.description != undefined) {
        atleastOneAlphaPDesc = this.program.description.match(".*[a-zA-Z]+.*");
      }
      if (this.program.programName == null || this.program.programName == '' || this.program.plans == null || this.program.weeks == null) {
        this.toastr.error('', 'Mandatory fields are required.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        this.stepOne = true;
      } else if (atleastOneAlphaPName == null) {
        this.toastr.error('', 'Program name should contain atleast one character.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      } else if (atleastOneAlphaPDesc == null) {
        this.toastr.error('', 'Please enter valid description.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      } else if (this.program.plans < 1) {
        this.toastr.error('', 'Minimum number of plans should be one.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      }
      else if (this.program.plans > 4) {
        this.toastr.error('', 'Maximum number of plans should be four.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      } else if (this.program.weeks < 1) {
        this.toastr.error('', 'Minimum number of weeks should be one.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      }
      else if (this.program.weeks > 4) {
        this.toastr.error('', 'Maximum number of weeks should be four.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      } else if (this.program.programDuration > 16) {
        this.toastr.error('', 'Program duration should not exceed 16 weeks.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      } else if (this.selectedPackage == undefined) {
        this.toastr.error('', 'Please select package type.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });

      } else if (this.SelectedNutrition == undefined && this.isNutritionShow) {
        this.toastr.error('', 'Please select nutrition.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      } else {
        if (this.SelectedNutrition != undefined && this.isNutritionShow) {
          if (this.SelectedNutrition.length == 0) {
            this.toastr.error('', 'Please select nutrition.', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
              progressBar: true,
              progressAnimation: 'increasing',
              tapToDismiss: true,
            });
          }
        }

        if (this.selectedPackage.length == 0) {
          this.toastr.error('', 'Please select package type.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        }
        if (this.SelectedProgramType.length == 0 && this.isNutritionShow) {
          this.toastr.error('', 'Please select nutrition.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        }
      }


    } else if (step == "step2") {
      // this.saveSlots();
      if (this.SelectedProgramType == undefined) {
        this.toastr.error('', 'Please select program type.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        return;
      }
      if (this.SelectedProgramType.length == 0) {
        this.toastr.error('', 'Please select program type.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        return;
      }
      if (this.isPremiumProgram == true && (!this.isOnlineProgram && !this.isContentProgram)) {
        this.toastr.error('', 'Please check either content or live.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        return;
      }
      var priceRegex = new RegExp('^[0-9]{1,6}(?:[.][0-9]{0,2})?$');
      if (this.country.toLowerCase().includes("india") && this.program.priceInRupees == undefined) {
        this.toastr.error('', 'Please enter price in rupees.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      }
      else if (this.country.toLowerCase().includes("india") && this.program.priceInDollars == undefined) {
        this.toastr.error('', 'Please enter price in dollars.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      }
      else if (this.program.priceInDollars == undefined) {
        this.toastr.error('', 'Please enter price in dollars.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      }

      else if (this.country.toLowerCase().includes("india") && this.program.priceInRupees < this.tax_details.min_price_in_rupees) {
        this.toastr.error('', 'Program price should be minimum ' + this.tax_details.min_price_in_rupees + ' rupees.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        return;
      }

      else if (this.program.priceInRupees != null && priceRegex.test(this.program.priceInRupees.toString()) === false) {
        this.toastr.error('', 'Program Price in rupees cannot exceeed 6 digits with 2 decimals.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        return;
      }

      else if (priceRegex.test(this.program.priceInDollars.toString()) === false) {
        this.toastr.error('', 'Program Price in dollars cannot exceeed 6 digits with 2 decimals.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        return;
      }

      else if (this.program.priceInDollars < this.tax_details.min_price_in_dollers) {
        this.toastr.error('', 'Program price should be minimum ' + this.tax_details.min_price_in_dollers + ' dollars.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        return;
      }


    
    } else {
      var Difference_In_Days;

      if (this.startDate == undefined) {
        this.toastr.error('', 'Please select program start date.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        return;
      }
      else if (this.enrollmentDate == undefined) {
        this.toastr.error('', 'Please select enrollment start date.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        return;
      }

      else {

        this.testStartDate = this.startDate.jsdate;
        this.testEnrollDate = this.enrollmentDate.jsdate;

       

        var checkDate = moment(this.datepipe.transform(new Date(), 'yyyy-MM-dd')).format('YYYY-MM-DD');
        // if (this.startDate != undefined && this.enrollmentDate != undefined) {

          if (checkDate > moment(this.datepipe.transform(this.startDate.jsdate, 'yyyy-MM-dd')).format('YYYY-MM-DD')) {
            this.toastr.error('', 'Program start date sholud be future date.', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
              progressBar: true,
              progressAnimation: 'increasing',
              tapToDismiss: true,
            });
            return;
          }
          if (checkDate > moment(this.datepipe.transform(this.enrollmentDate.jsdate, 'yyyy-MM-dd')).format('YYYY-MM-DD')) {
            this.toastr.error('', 'Program enrollment start date should be current or future date.', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
              progressBar: true,
              progressAnimation: 'increasing',
              tapToDismiss: true,
            });
            return;
          }


          
          if (this.SelectedProgramType[0]["name"] == "Premium program" && this.enrollmentEndDate != undefined) {
            this.testEnrollEndDate = this.enrollmentEndDate.jsdate;
            if (checkDate > moment(this.datepipe.transform(this.testEnrollEndDate, 'yyyy-MM-dd')).format('YYYY-MM-DD')) {
              this.toastr.error('', 'Program enrollment end date sholud be future date.', {
                timeOut: 3000,
                positionClass: 'toast-top-right',
                progressBar: true,
                progressAnimation: 'increasing',
                tapToDismiss: true,
              });
              return;
            }
          }
          var Difference_In_Time = this.testStartDate.getTime() - this.testEnrollDate.getTime();

          // To calculate the no. of days between two dates
          Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
          this.onDateChange(this.testStartDate);
         
        // }

         if (this.testEnrollDate >= this.testStartDate) {
          this.toastr.error('', 'Start date should be future date of enrollment date.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        } 
        // else if (this.SelectedProgramType[0]["name"] != "Premium program" && (Difference_In_Days < 7 || Difference_In_Days > 34)) {
        //   this.toastr.error('', 'Program start date and enrollment start date should have minimum gap of 1 week and maximum one month.', {
        //     timeOut: 3000,
        //     positionClass: 'toast-top-right',
        //     progressBar: true,
        //     progressAnimation: 'increasing',
        //     tapToDismiss: true,
        //   });
        // }
        else if (this.SelectedProgramType[0]["name"] == "Premium program" && this.enrollmentEndDate == undefined) {

          this.toastr.error('', 'Please select enrollment end date.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
          return;

        } else {

          if (this.SelectedProgramType[0]["name"] == "Premium program" && this.testEnrollEndDate != undefined) {

            if (this.testEnrollEndDate <= this.testEnrollDate) {
              this.toastr.error('', 'Enrollment end date should be future date of enrollment date.', {
                timeOut: 3000,
                positionClass: 'toast-top-right',
                progressBar: true,
                progressAnimation: 'increasing',
                tapToDismiss: true,
              });
              return;
            }

            if (this.testStartDate <= this.testEnrollEndDate) {
              this.toastr.error('', 'Start date should be future date of enrollment end date.', {
                timeOut: 3000,
                positionClass: 'toast-top-right',
                progressBar: true,
                progressAnimation: 'increasing',
                tapToDismiss: true,
              });
              return;
            }

            // var Difference_In_Time = 0;
            // var Diff_In_Days = 0;
            // Difference_In_Time = this.testEnrollEndDate.getTime() - this.testEnrollDate.getTime();
            // Diff_In_Days = Difference_In_Time / (1000 * 3600 * 24);
            // if (Diff_In_Days < 7 || Diff_In_Days > 27) {
            //   this.toastr.error('', 'Enrollment end date and enrollment start date should have minimum gap of 1 week and maximum 4 weeks.', {
            //     timeOut: 3000,
            //     positionClass: 'toast-top-right',
            //     progressBar: true,
            //     progressAnimation: 'increasing',
            //     tapToDismiss: true,
            //   });
            //   return;
            // }
            // Difference_In_Time = this.testStartDate.getTime() - this.testEnrollEndDate.getTime();
            // Diff_In_Days = Difference_In_Time / (1000 * 3600 * 24);
            // if (Diff_In_Days != 7) {
            //   this.toastr.error('', 'Enrollment end date and program start date should have gap of 1 week.', {
            //     timeOut: 3000,
            //     positionClass: 'toast-top-right',
            //     progressBar: true,
            //     progressAnimation: 'increasing',
            //     tapToDismiss: true,
            //   });
            //   return;
            // }
          }

          if (this.isOnlineProgram) {

            if (this.selectedDays.length == 0) {
              this.toastr.error('', 'Please select training days.', {
                timeOut: 3000,
                positionClass: 'toast-top-right',
                progressBar: true,
                progressAnimation: 'increasing',
                tapToDismiss: true,
              });
              return;
            }
    
            if (this.SelectedSlots.length == 0) {
              this.toastr.error('', 'Please select number of slots.', {
                timeOut: 3000,
                positionClass: 'toast-top-right',
                progressBar: true,
                progressAnimation: 'increasing',
                tapToDismiss: true,
              });
              return;
            }
    
    
            if (this.SelectedPraticipants.length == 0) {
              this.toastr.error('', 'Please select number of participants.', {
                timeOut: 3000,
                positionClass: 'toast-top-right',
                progressBar: true,
                progressAnimation: 'increasing',
                tapToDismiss: true,
              });
              return;
            }
    
            if (this.selectedFrequency.length == 0) {
              this.toastr.error('', 'Please select call duration.', {
                timeOut: 3000,
                positionClass: 'toast-top-right',
                progressBar: true,
                progressAnimation: 'increasing',
                tapToDismiss: true,
              });
              return;
            }
            this.displaySlotsTimes = [];
            for (var i = 0; i < this.slotsTimes.length; i++) {
              if (this.slotsTimes[i]["startTime"].length == 0) {
                this.toastr.error('', 'Please select slot start time.', {
                  timeOut: 3000,
                  positionClass: 'toast-top-right',
                  progressBar: true,
                  progressAnimation: 'increasing',
                  tapToDismiss: true,
                });
                return;
              }
            
    
                if (this.slotsTimes[i]["endTime"].length == 0) {
                  this.toastr.error('', 'Please select valid time, end time should not cross a day.', {
                    timeOut: 3000,
                    positionClass: 'toast-top-right',
                    progressBar: true,
                    progressAnimation: 'increasing',
                    tapToDismiss: true,
                  });
                  return;
                }
    
                for (var j = 0; j < this.slotsTimes.length; j++) {
                  if (i != j) {
                    var testStartTime = moment(this.slotsTimes[i]["startTime"][0]["name"], "HH:mm:ss a");
                    var testEndTime = moment(this.slotsTimes[i]["endTime"][0]["name"], "HH:mm:ss a");
    
                    var selectedStartTime = moment(this.slotsTimes[j]["startTime"][0]["name"], "HH:mm:ss a");
                    var selectedEndTime = moment(this.slotsTimes[j]["endTime"][0]["name"], "HH:mm:ss a");
    
                    var d1 = moment.duration(testEndTime.diff(selectedStartTime));
                    var d2 = moment.duration(selectedEndTime.diff(testStartTime));
                    var r1 = d1.asMinutes();
                    var r2 = d2.asMinutes();
                    if ((r1 > -15 && r1 < 15) || (r2 > -15 && r2 < 15)) {
                      this.toastr.error('', 'The time interval should be minimum 15 minutes between each slot.', {
                        timeOut: 3000,
                        positionClass: 'toast-top-right',
                        progressBar: true,
                        progressAnimation: 'increasing',
                        tapToDismiss: true,
                      });
                      return;
                    }
    
    
    
                    if ((selectedStartTime >= testStartTime && selectedStartTime <= testEndTime) || (selectedEndTime >= testStartTime && selectedEndTime <= testEndTime)) {
                      this.toastr.error('', 'Please check the existing time intervals.', {
                        timeOut: 3000,
                        positionClass: 'toast-top-right',
                        progressBar: true,
                        progressAnimation: 'increasing',
                        tapToDismiss: true,
                      });
                      return;
                    }
                  }
                
              }
    
              var ds = {
                id: i,
                startTime: this.slotsTimes[i]["startTime"][0]["name"],
                endTime: this.slotsTimes[i]["endTime"][0]["name"]
              };
              this.displaySlotsTimes.push(JSON.parse(JSON.stringify(ds)));
            }
            this.checkExistingSlots();
          }
        }
      }
    }
  }
  checkExistingSlots() {
    var index = '/scheduler/slots/live';
    var queryparams = {
      "trainerId": this.program.trainerId,
      "timezone": this.timezone,
      "programId": this.program.programId
    }
    this.db_service.getQueriedData("", index, queryparams).subscribe(
      response => {

        var db_slots = response.data;

        this.duplicate_slots = [];
        var daysArray = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
        for (var i = 0; i < this.selectedDays.length; i++) {
          var d = new Date(this.testStartDate);
          d.setDate(d.getDate() + ((daysArray.findIndex(x => x == this.selectedDays[i]) + 1) + 7 - d.getDay()) % 7);
          while (d <= new Date(this.endDate.jsdate)) {

            var matched_objects = [];

            for (let k = 0; k < db_slots.length; k++) {
              var check_d = this.datepipe.transform(new Date(db_slots[k]["date"]), 'yyyy-MM-dd');
              if (check_d == this.datepipe.transform(d, 'yyyy-MM-dd')) {
                matched_objects.push(JSON.parse(JSON.stringify(db_slots[k])));
              }

            }
            if (matched_objects.length > 0) {
              for (let j = 0; j < this.slotsTimes.length; j++) {
                d.setHours(parseInt(this.slotsTimes[j]["startTime"][0]["name"].split(":")[0]));
                d.setMinutes(parseInt(this.slotsTimes[j]["startTime"][0]["name"].split(":")[1]));
                d.setSeconds(0);
                d.setMinutes(d.getMinutes() + 60);


                for (let l = 0; l < matched_objects.length; l++) {
                  var testStartTime = moment(matched_objects[l]["startTime"], "HH:mm:ss a");
                  var testEndTime = moment(matched_objects[l]["endTime"], "HH:mm:ss a");

                  var selectedStartTime = moment(this.slotsTimes[j]["startTime"][0]["name"], "HH:mm:ss a");
                  var selectedEndTime = moment(this.slotsTimes[j]["endTime"][0]["name"], "HH:mm:ss a");

                  if ((selectedStartTime >= testStartTime && selectedStartTime <= testEndTime) || (selectedEndTime >= testStartTime && selectedEndTime <= testEndTime) || (testStartTime >= selectedStartTime && testStartTime <= selectedEndTime) || (testEndTime >= selectedStartTime && testEndTime <= selectedEndTime)) {
                    var matchedObj = this.duplicate_slots.find(x => x["slotId"] == matched_objects[l]["slotId"]);
                    if (matchedObj == undefined)
                      this.duplicate_slots.push(JSON.parse(JSON.stringify(matched_objects[l])));
                  }
                }
              }

            }
            d.setDate(d.getDate() + 7)
          }
        }
        if (this.duplicate_slots.length != 0) {
          this.showSlotsPopup = true;
        }
      },
      error => {
        this.toastr.error('', 'There is an error while retrieving slot details.', {
          timeOut: 5000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      });
  }
  saveProgram() {
    if (this.programImage.imgPath != "") {
      this.program.createdDate = new Date();
      this.program.lastUpdateOn = new Date();
      if (this.SelectedNutrition != undefined && this.isNutritionShow)
        this.program.nutrition = this.SelectedNutrition[0];
      this.program.packageType = this.selectedPackage[0];
      this.program.programImage = this.programImage;
      this.program.startDate = this.datepipe.transform(this.testStartDate, 'yyyy-MM-dd');
      this.program.enrollmentDate = this.datepipe.transform(this.testEnrollDate, 'yyyy-MM-dd');
      if(this.endDate != undefined)
      this.program.endDate = this.datepipe.transform(this.endDate.jsdate, 'yyyy-MM-dd');;
      this.program.programType = this.SelectedProgramType[0];
      this.program.enrollmentEndDate = this.datepipe.transform(this.testEnrollEndDate, 'yyyy-MM-dd');
      this.program.currency = [];
      if (this.program.priceInDollars != undefined) {
        this.program.currency.push(this.currency[0]);
      }
      if (this.program.priceInRupees != undefined) {
        this.program.currency.push(this.currency[1]);
      }
      this.program.status = 'New';
      // this.program.status = 'new';
      this.program.isOnlineProgram = this.isOnlineProgram;
      this.program.isContentProgram = this.isContentProgram;
      if (this.isOnlineProgram) {
        this.program.callDuration = this.selectedFrequency[0];
        this.program.trainingDays = this.selectedDays;
        this.program.numberOfSlots = this.SelectedSlots[0]["name"];
        this.program.numberOfPartcipants = this.SelectedPraticipants[0]["name"];
        this.program.slots = this.slotsTimes;
      }


      if (this.program.programId == undefined) {
        var index = '/program';
        this.yoga_db_service.saveDataToDB(this.program, index).subscribe(
          response => {
            this.program.programId = response.data;
            if (this.programEdit == false && this.program.isContentProgram) {
              this.showCalendarPopup = true;
            }
            // if (this.program.isOnlineProgram) {
            //   this.saveSlots();
            // }
            this.toastr.success('', 'Program has been saved successfully', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
              progressBar: true,
              progressAnimation: 'increasing',
              tapToDismiss: true,
            });
            if (!this.program.isContentProgram) {
              this.router.navigate(['/yogaPrograms']);
            }
          },
          error => {
            // alert('there is an error while saving data');
            this.toastr.error('', 'There is an error while saving Program', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
              progressBar: true,
              progressAnimation: 'increasing',
              tapToDismiss: true,
            });
          }
        )
      } else {
        var index = '/program';
        this.yoga_db_service.updateDatainDB(this.program, index).subscribe(
          response => {
            if (this.programEdit == false) {
              this.showCalendarPopup = true;
            }

            // if (this.program.isOnlineProgram) {
            //   this.saveSlots();
            // }
            this.toastr.success('', 'Program has been saved successfully', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
              progressBar: true,
              progressAnimation: 'increasing',
              tapToDismiss: true,
            });
            this.router.navigate(['/yogaPrograms']);
          },
          error => {
            // alert('there is an error while saving data');
            this.toastr.error('', 'There is an error while saving Program', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
              progressBar: true,
              progressAnimation: 'increasing',
              tapToDismiss: true,
            });
          }
        )
      }
    } else {
      this.toastr.error('', 'Please upload image for program', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }


  }
  isInArray(array, word) {
    return array.indexOf(word.toLowerCase()) > -1;
  }
  selectFile(event, filetype) {
    this.selectedFile = event.target.files[0];

    this.selectedFile = event.target.files[0];
    if (this.selectedFile.name.includes("+")) {
      this.toastr.error('', "No special characters allowed like '+'.", {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return;
    }
    //var format = /[ !@#$%^&*()_+\=\[\]{};':"\\|,<>\/?]/;
    // var fileCheck = format.test(this.selectedFile.name);
    this.fileExtension = this.selectedFile.name.split('.').pop();
    // var allowedExtensions = ["jpg","jpeg","png","JPG","JPEG","JFIF","BMP","SVG"];
    // if (fileCheck == true) {
    //   this.toastr.error('', 'Special charecters are not allowed in image file name.', {
    //     timeOut: 3000,
    //     positionClass: 'toast-top-right',
    //     progressBar: true,
    //     progressAnimation: 'increasing',
    //     tapToDismiss: true,
    //   });
    // } else {
    if (this.isInArray(this.globals.ImgallowedExtensions, this.fileExtension)) {
      if (this.program.programImage != undefined) {
        var imageName = this.program.programImage["programImg"].replace(/ /g, "_");
        this.awsService.deleteFileFromS3(imageName, this.program.trainerId, this.globals.DEST_BUCKET, this.globals.PROGIMGFOLDER).subscribe(
          response => {
            this.fileUpload(filetype);
            //console.log('Image deleted successfully.', response);

          },
          error => {
            console.log('There is an error while deleting image.', error);

          })
      } else {
        this.fileUpload(filetype);
      }
    } else {
      this.toastr.error('', 'Please upload only images.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    // }
  }
  fileUpload(filetype) {
    this.loaderHide = !this.loaderHide;

    if (filetype == 'image') {
      this.programImage.programImg = this.selectedFile.name;
      this.awsService.uploadFileToS3(this.selectedFile, this.program.trainerId, this.globals.DEST_BUCKET, this.globals.PROGIMGFOLDER, filetype).subscribe(
        response => {
          this.programImage.imgPath = this.globals.CLOUDFRONT_URL + response.Key;
          this.imgUrl = this.programImage.imgPath;
          this.loaderHide = !this.loaderHide;
          //console.log('Image uploaded successfully.', response);
          this.isImgUploaded = "YES";
          this.toastr.success('', 'Image uploaded successfully.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        },
        error => {
          console.log('There is an error uploading image.', error);
          // console.log('Image uploaded successfully.', response);
          this.loaderHide = !this.loaderHide;
          this.toastr.error('', 'There is an error uploading image.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        })
    }
  }
  Cancel() {
    this.router.navigate(['/yogaPrograms']);
  }
  onCalendarYes() {
    this.router.navigate(['/yogaCalendar/' + this.program.programId]);
    //this.router.navigate(['/program']);
    this.showCalendarPopup = false;
  }
  onCalendarNo() {
    this.router.navigate(['/yogaPrograms']);
    this.showCalendarPopup = false;
  }
  closePopup() {
    this.showCalendarPopup = false;
    this.taxpopup = false;
    this.showSlotsPopup = false;
  }

  saveSlots() {
    var slots = [];
    var daysArray = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    for (var i = 0; i < this.selectedDays.length; i++) {
      var d = new Date(this.testStartDate);
      d.setDate(d.getDate() + ((daysArray.findIndex(x => x == this.selectedDays[i]) + 1) + 7 - d.getDay()) % 7);
      while (d <= new Date(this.endDate.jsdate)) {
        for (let j = 0; j < this.slotsTimes.length; j++) {

          var slot = {};
          d.setHours(parseInt(this.slotsTimes[j]["startTime"][0]["name"].split(":")[0]));
          d.setMinutes(parseInt(this.slotsTimes[j]["startTime"][0]["name"].split(":")[1]));
          d.setSeconds(0);
          slot["date"] = this.datepipe.transform(d, 'yyyy-MM-dd');
          d.setMinutes(d.getMinutes() + 60);

          slot["startTime"] = this.slotsTimes[j]["startTime"][0]["name"];

          slot["title"] = this.slotsTimes[j]["startTime"][0]["name"] + "-" + this.slotsTimes[j]["endTime"][0]["name"];
          slot["endTime"] = this.slotsTimes[j]["endTime"][0]["name"];

          slot["timezone"] = this.timezone;
          var m = this.monthNames[d.getMonth()];
          var yyyy = d.getFullYear();
          var Month = m + "/" + yyyy;
          slot["month"] = Month;
          slot["trainerId"] = this.program.trainerId;
          slot["callType"] = "group";
          slot["programId"] = this.program.programId;
          slot["programName"] = this.program.programName;
          slot["status"] = "open";
          slot["participants"] = [];
          slots.push(JSON.parse(JSON.stringify(slot)));
        }
        d.setDate(d.getDate() + 7)
      }
    }
    var data = {};
    data["slots"] = slots;
    var index = '/scheduler'

    this.db_service.saveDataToDB(data, index).subscribe(
      response => {

        this.toastr.success('', 'Slot details saved successfully', {
          timeOut: 5000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });

      },
      error => {
        this.toastr.error('', 'There is an error while saving slot details.', {
          timeOut: 5000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      });
  }

  calculateEndTime(id) {

  

    for (var i = 0; i < this.slotsTimes.length; i++) {
      if (this.slotsTimes[i]["id"] == id) {

        if (this.selectedFrequency.length == 0) {
          this.toastr.error('', 'Please select call duration.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
          this.slotsTimes[i]["startTime"] = [];
          break;
          // return;
         
        }
       

        var frequency = JSON.parse(this.selectedFrequency[0].name.split(' minutes')[0]);
        var slotStart = this.slotsTimes[i]["startTime"][0]["name"];
        var slotEnd = moment(slotStart, "HH:mm:ss a").add(frequency, 'minutes').format('HH:mm');
        var sT = moment(this.slotsTimes[i]["startTime"][0]["name"], 'HH:mm');
        var eT = moment(slotEnd, 'HH:mm');
        if (sT.isBefore(eT)) {
          var s = this.timeintervals.find(s => s["name"] == slotEnd);
          this.slotsTimes[i]["endTime"] = JSON.parse(JSON.stringify(Array.of(s)));
        } else {
          this.toastr.error('', 'Maximum time should not exceed today.', {
            timeOut: 5000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
          this.slotsTimes[i]["endTime"] = [];
          return;

        }

      }
    }

  }
 
}
