import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Callback, CognitoUtil } from './cognito.service';
import { CallbackTrainee, CognitoTraineeUtil } from './cognitotrainee.service';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class JwtHttpInterceptor implements HttpInterceptor {

  //   private urlapi = 'https://###';
  //   private uri = 'businessunits';
  public idToken: string;

  constructor(public cognitoUtil: CognitoUtil, public cognitoTraineeUtil: CognitoTraineeUtil) {
    //   this.getToken();
  }

  getToken(): Observable<any> {
    return Observable.create(observer => {
      this.cognitoUtil.getIdToken(new IdTokenCallback(result => {
        observer.next(result);
        this.idToken = result;
        // console.log("token interceptor" +this.idToken);
      }));
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // request = request.clone({
    //   setHeaders: {
    //     Authorization: `Bearer ${this.idToken}`
    //   }
    // });

    // return next.handle(request);
    // // getBusinessunits(): Observable<Response> {
    // return this.getToken().flatMap(token => {
    //     request = request.clone({
    //                 setHeaders: {
    //                     Authorization: `Bearer ${token}`
    //                 }
    //                 //  headers: request.headers.set("Authorization", "Bearer ${token}")
    //             });

    //             return next.handle(request);
    //             console.log("Intercptor token : "+ token)

    //     });
    return this.getToken().pipe(
      switchMap(token => {
        request = request.clone(
          {
            setHeaders: { Authorization: `Bearer ${token}` }
          });
        // console.log("Intercptor token : "+ token)
        return next.handle(request);
      })
    )


    // }
  }
}

export class IdTokenCallback implements Callback, CallbackTrainee {

  _valueReturnFunction: any = null;

  constructor(valueReturnFunction: any) {
    this._valueReturnFunction = valueReturnFunction;
  }

  callback() {
  }

  callbackWithParam(result) {
    this._valueReturnFunction(result);
  }
}
