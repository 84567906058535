<!-- HEADER SECTION START -->
<header>
  <nav class="">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-8 col-4">
          <a class="" style="cursor: pointer" (click)="onHeader()">
            <img
              src="assets/images/logov1.gif"
              class="img-responsive main-logo-img-gif"
          /></a>
        </div>
        <div
          class="col-xl-4 col-8 home-icons-grid"
          *ngIf="
            !(
              router.url === '/login' ||
              router.url === '/register' ||
              router.url === '/forgetPassword' ||
              router.url === '/' ||
              router.url.includes('profile') ||
              router.url.includes('confirm') ||
              router.url.includes('gender') ||
              router.url === '/resend' ||
              router.url === '/traineeLogin' ||
              router.url === '/traineeSlot' ||
              router.url === '/privacy'
            )
          "
        >
          <div class="row">
            <a
              class="nav-link fit-dnon fit-home-icon"
              routerLink="/dashboard"
              title="Home"
              style="cursor: pointer"
            >
              <img
                class="header-set-icons"
                src="assets/images/icons/home.png"
                alt=""
              />
            </a>
            <a class="nav-link fit-dnon" title="Fitness" style="cursor: pointer" *ngIf="isFitness == true && router.url === '/dashboard'" (click)="onFitness()">
                <img
                  class="header-set-icon" [ngClass]="{'primaryCat': currentCategory == 'Bodybuilder'}"
                  src="assets/images/icons/fitness.png" width="40"
                />
            </a>
            <a class="nav-link fit-dnon" title="Yoga" style="cursor: pointer"  *ngIf="isYoga == true && router.url === '/dashboard'" (click)="onYoga()">
              <img
                class="header-set-icon" [ngClass]="{'primaryCat': currentCategory == 'Yoga'}"
                src="assets/images/icons/yoga.png" width="40"
              />
            </a>
            <a class="nav-link fit-dnon" title="Zumba" style="cursor: pointer"  *ngIf="isZumba == true && router.url === '/dashboard'" (click)="onZumba()">
              <img
                class="header-set-icon" [ngClass]="{'primaryCat': currentCategory == 'Zumba'}"
                src="assets/images/icons/zumba.png" width="40"
              />
            </a>
            
            <a class="nav-link fit-business"  (click)="onBusinessDetails()" title="Yoga" style="cursor: pointer;">
              <img class="header-set-icon" src="assets/images/icons/Business.png" alt="" width="40"/>
          </a>
          <a class="nav-link fit-1o1logo fit-1o1logo fit-1o1logo-img" title="Switch Category" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="cursor: pointer;">
            <img class="bounce " src="assets/images/logov1.gif" alt="1o1logo" style="width: 90px;"/>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="transform: translate3d(20px, -20px, 0px) !important;" *ngIf="router.url === '/dashboard'">
                <a class="dropdown-item" href="#" style="    position: absolute;   transform: translate(0px, -40px);  color: white;" *ngIf="isFitness == true" (click)="onFitness()">
                    <img class="" src="assets/images/icons/fitness.png" [ngClass]="{'primaryCat': currentCategory == 'Bodybuilder'}" alt="" width="50"/>
                </a>
                <a class="dropdown-item" href="#" style=" position: absolute; transform: translate(-57px, -17px); color: white;" *ngIf="isYoga == true" (click)="onYoga()">
                    <img class="" src="assets/images/icons/yoga.png" [ngClass]="{'primaryCat': currentCategory == 'Yoga'}" alt="" width="50"/>
                </a>
                <a class="dropdown-item" href="#" style=" position: absolute; transform: translate(57px, -17px); color: white;" *ngIf="isZumba == true" (click)="onZumba()">
                    <img class="" src="assets/images/icons/zumba.png" [ngClass]="{'primaryCat': currentCategory == 'Zumba'}"v alt="" width="50"/>
                </a>
              </div>
        </a>
            <a
              class="nav-link position-relative fit-notification"
              title="Notification"
              style="cursor: pointer"
            >
              <img
                class="header-set-icon dropdown-toggle"
                src="assets/images/icons/notification.png" 
                alt="" width="40"
                id="dropdownMenuNotification"
                (click)="getNotification()"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              />
              <span
                class="notification-balloon-bell"
                *ngIf="
                  adminnotificationssize != 0 &&
                  adminnotificationssize != undefined
                "
                >{{ adminnotificationssize }}</span
              >
              <div class="dropdown notification-class-div">
                <div
                  class="dropdown-menu notification-menu"
                  aria-labelledby="dropdownMenuNotification"
                >
                  <!-- <div class="dropdown-item notification-item">
                                                        <div class="row">
                                                          <div class="col-md-2" >
                                                            <img src="http://www.latimes.com/includes/projects/hollywood/portraits/keanu_reeves.jpg" alt="" width="32">
                                                          </div>
                                                          <div class="col-md-10">
                                                            <span>This is test notification and longest notification text. This is test notification and longest notification text.</span>
                                                         </div>
                                                        </div>
                                    
                                                      </div> -->

                  <div
                    class="headerr-dropdown-menu animated fadeInDown"
                    id="notification-dropdown"
                  >
                    <div class="headerr-dropdown-header">
                      <span class="headerr-heading">Notifications</span>
                    </div>
                    <div class="headerr-dropdown-body my-2">
                      <div
                        class="notification-main-div col-md-12 py-2"
                        *ngFor="let notification of adminnotifications"
                      >
                        <div
                          class="row"
                          [routerLink]="notification.navigationUrl"
                        >
                          <div class="col-md-2 col-2">
                            <img
                              attr.src="{{ notification.imgPath }}"
                              alt=""
                              width="40"
                            />
                          </div>
                          <div class="notification-text col-md-10 col-10">
                            <span
                              class="highlight"
                              *ngIf="adminnotifications.length != 0"
                              >{{ notification.message }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div
                        class="notification-main-div col-md-12 py-2"
                        *ngIf="adminnotifications.length == 0"
                      >
                        <div class="row">
                          <div class="col-md-2 col-2">
                            <img
                              src="assets/images/no_notification.png"
                              alt=""
                              width="40"
                            />
                          </div>
                          <div class="notification-text col-md-10 col-10">
                            <span class="highlight"
                              >No new notifications available</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- <a class="dropdown-item notification-item" 
                                                                *ngFor="let notification of adminnotifications">{{notification.message}} <span
                                                                  *ngIf="notification.read == false">new</span> </a> -->
                </div>
              </div>
            </a>

            <a class="nav-link fit-prof-callsch" routerLink="/callscheduler" title="Scheduler" style="cursor: pointer">
              <img
                class="header-set-icon"
                src="assets/images/icons/call-scheduler.png" width="40"
              />
            </a>
            <a class="nav-link fit-profile" style="cursor: pointer">
              <div
                *ngIf="
                  !(
                    router.url.includes('profile') ||
                    router.url.includes('confirm') ||
                    router.url.includes('gender') ||
                    router.url === '/login' ||
                    router.url === '/register' ||
                    router.url === '/forgetPassword' ||
                    router.url === '/' ||
                    router.url === '/resend' ||
                    router.url === '/traineeLogin'
                  )
                "
                title="Profile"
              >
                <img
                  class="img-fluid dropdown-toggle"
                  src="{{ profilepicUrl }}"
                  alt=""
                  id="dropdownMenuProfile"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style="width: 36px; height: 36px; border-radius: 50px"
                />
                <div class="dropdown notification-class-div">
                  <div
                    class="dropdown-menu profile-menu-drop-down"
                    aria-labelledby="dropdownMenuProfile"
                  >
                    <a
                      class="dropdown-item notification-item"
                      (click)="onMyprofile()"
                    >
                      <i class="fas fa-user-circle"></i>
                      Profile</a
                    >
                    <a
                      class="dropdown-item notification-item fit-business-dropdown-item"
                      (click)="onBusinessDetails()" 
                    >
                      <i class="fas fa-user-circle"></i>
                      Business details
                    </a>
                    <a
                      class="dropdown-item notification-item"
                      (click)="logout()"
                    >
                      <i class="fas fa-power-off"></i>
                      Logout</a
                    >
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>

        <div
          class="col-xl-4 col-8 before-login-grid-text"
          *ngIf="
            router.url === '/login' ||
            router.url === '/register' ||
            router.url === '/forgetPassword' ||
            router.url === '/' ||
            router.url === '/resend' ||
            router.url === '/traineeLogin'
          "
        >
          <div class="row float-right">
            <div class="float-right">
              <div class="dup-headerclass text-right">
                <div class="login-block">
                  <a
                    href="#"
                    class="float-left"
                    data-toggle="modal"
                    data-target="#signIn"
                    >Sign In</a
                  >
                  <span class="float-left"></span>
                  <a
                    href="#"
                    class="float-left"
                    data-toggle="modal"
                    data-target="#signUp"
                    >Sign Up</a
                  >
                </div>
                <a
                  class="nav-link location_pulse"
                  style="cursor: pointer"
                  routerLink="/"
                >
                  <i class="fas fa-map-marker-alt"></i>&nbsp;
                  {{ location }}
                  <div class="pulse"></div>
                </a>
                <a class="nav-link" style="cursor: pointer" routerLink="/login">
                  <i class="fas fa-sign-in-alt"></i>&nbsp; Sign In</a
                >
              </div>
            </div>
          </div>
        </div>

        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#collapsibleNavbar"
        >
          <span class="navbar-toggler-icon">
            <i class="fas fa-bars"></i>
          </span>
        </button>
        <div class="collapse navbar-collapse" id="collapsibleNavbar">
          <ul class="navbar-nav">
            <li class="nav-item">
              <!-- <a class="nav-link" *ngIf="!(router.url === '/login' || router.url === '/register' || router.url.includes('profile') || router.url.includes('confirm')  || router.url.includes('gender') || router.url === '/forgetPassword' || router.url === '/content' || router.url === '/resend')" title="Clients" style="font-size: 20px;cursor: pointer;"><i class="fas fa-users"></i></a> -->
            </li>
            <li class="nav-item"></li>
            <li class="nav-item"></li>
            <li class="nav-item"></li>
            <li class="nav-item"></li>
            <li class="nav-item"></li>
            <li class="nav-item"></li>
            <li class="nav-item"></li>
          </ul>
          <div class="login-block">
            <a
              href="#"
              class="float-left"
              data-toggle="modal"
              data-target="#signIn"
              >Sign In</a
            >
            <span class="float-left"></span>
            <a
              href="#"
              class="float-left"
              data-toggle="modal"
              data-target="#signUp"
              >Sign Up</a
            >
          </div>
        </div>
      </div>
    </div>
  </nav>
</header>
<!-- HEADER SECTION END -->
