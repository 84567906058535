<div id="mydiv" *ngIf="loaderHide && this.currentCategory == 'Bodybuilder'">
  <img src="assets/images/loaders/loaderexe.gif" width="10%" class="ajax-loader" />
</div>
<div id="mydiv" *ngIf="loaderHide && this.currentCategory == 'Yoga'">
  <img src="assets/images/loaders/loaderyoga.gif" width="10%" class="ajax-loader" />
</div>
<div id="mydiv" *ngIf="loaderHide && this.currentCategory == 'Zumba'">
  <img src="assets/images/loaders/loaderjumba.gif" width="10%" class="ajax-loader" />
</div>
<section>
  <div class="col-md-10 col-xl-9 mx-auto">
    <div class="row">
      <div class="col-md-9">
        <h1 class="heading1"> <i class="fa fa-plus plus-circle"></i> <span style="margin-left: 10px;">Add Program</span>
        </h1>
        <ng-stepper class="yoga-stp-add-prog" #cdkStepper [linear]="true">

          <cdk-step [stepControl]="stepOne" [optional]="false">
            <ng-template cdkStepLabel>
              <div class="step-bullet">1</div>
              <div class="step-title">Programs <span class="required">*</span></div>
            </ng-template>
            <div class="exercise-content" #stepOne>
              <form class="form-inline trainer-profile-form">
                <div class="form-group col-12">
                  <input type="text" class="form-control col-12" id="exerciseName" name="exerciseName"
                    autocomplete="off" [(ngModel)]="program.programName" #programName="ngModel" minlength="1"
                    placeholder="Program Name*" [disabled]="isDisabled" required>
                  <!-- <span class="floating-label">Exercise Name</span> -->
                  <div *ngIf="programName.invalid && (programName.dirty || programName.touched)"
                    class="alert alert-danger">
                    <!-- <div class="error_alert_msg error_alet_msg_show">
                          All fields are required.
                        </div> -->
                    <!-- <div *ngIf="exerciseName.errors?.required">
                          Exercise Name can't be blank
                        </div> -->
                    <div *ngIf="programName.errors?.minlength">
                      Program Name should have Minimum 2 Character
                    </div>
                    <div *ngIf="programName.errors.required">
                      Program Name should have Minimum 2 Character
                    </div>
                  </div>
                </div>
                <div class="form-group col-12 col-md-6">
                
                  <ng-multiselect-dropdown  class="form-control col-12"
                  [placeholder]="'Package type*'" name="packageType"
                  [settings]="dropdownSettings_single"
                  [data]="packagetypes"
                  [(ngModel)]="selectedPackage"
                  [disabled]="isDisabled"
                >
                </ng-multiselect-dropdown>
                </div>
                <div class="form-group col-12 col-md-6" *ngIf="isNutritionShow">
                 
                  <ng-multiselect-dropdown class="form-control col-12"
                  [placeholder]="'Nutrition*'" name="Nutrition"
                  [settings]="dropdownSettings_single"
                  [data]="Nutritions"
                  [(ngModel)]="SelectedNutrition"
                  [disabled]="isDisabled"
                >
                </ng-multiselect-dropdown>
                </div>
                <div class="form-group col-12">

                  <div class="add_program_duration col-md-12">

                    <div class="row col-4">
                      <div>
                        Plan
                      </div>
                      <input class="col-12 col-md-6 mx-auto" type="number" OnlyPositiveNumbers numericType="number"
                        [(ngModel)]="program.plans" (ngModelChange)="calculate()" name="plans"
                        [disabled]="isDurationDisabled">
                    </div>

                    <div>
                      X
                    </div>


                    <div class="row col-4">
                      <div>
                        Weeks
                      </div>
                      <input class="col-12 col-md-6 mx-auto" type="number" OnlyPositiveNumbers numericType="number"
                        [(ngModel)]="program.weeks" (ngModelChange)="calculate()" name="weeks"
                        [disabled]="isDurationDisabled">
                    </div>

                    <div>
                      =
                    </div>

                    <div class="row col-4">
                      <div>
                        Duration*
                      </div>
                      <input class="col-12 col-md-6 mx-auto" type="number" OnlyPositiveNumbers numericType="number"
                        [(ngModel)]="program.programDuration" name="programDuration" readonly
                        [disabled]="isDurationDisabled" style="background-color: yellowgreen; cursor: not-allowed;">
                    </div>
                  </div>
                </div>
                <div class="form-group col-12">
                  <!-- <input type="text" class="form-control col-12" id="description" name="description"
                    [(ngModel)]="program.description" #programDes="ngModel" maxlength="800" pattern="^[a-zA-Z ]+$"
                    placeholder="Program Description*"> -->
                  <textarea rows="5" class="form-control col-12 textarea-check" autocomplete="off"
                    placeholder="Program Description*" id="description" #programDes="ngModel"
                    [(ngModel)]="program.description" maxlength="800" pattern="^[a-zA-Z ]+$" name="description"
                    [disabled]="isDisabled" style="height: 80px !important">Program Description*</textarea>
                </div>

                <div class="col-12 justify-content-end">
                  <!-- <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;" *ngIf = "exercise.exerciseId != undefined" (click)="EditExercise()">Edit</button> -->
                  <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
                    (click)="Cancel()">Cancel</button>
                  <button type="button" class="btn btn-default save-exercise-btn float-right" style="margin-top: 10px;"
                    cdkStepperNext (click)="validateForm('step1')">Next</button>
                </div>
              </form>
            </div>
          </cdk-step>

          <cdk-step [stepControl]="stepTwo" [optional]="true">
            <ng-template cdkStepLabel>
              <div class="step-bullet">2</div>
              <div class="step-title">Pricing</div>
            </ng-template>
            <div class="exercise-content" #stepTwo>
              <form class="form-inline trainer-profile-form">
                <div class="form-group col-12">
                  
                  <ng-multiselect-dropdown class="form-control col-12 col-md-6"
                  [placeholder]="'Program type*'"  name="programType"
                  [settings]="dropdownSettings_single"  [disabled]="isDisabled"
                  [data]="programTypes"
                  [(ngModel)]="SelectedProgramType"
                  (onSelect)="onItemSelect()"
                    (onDeSelect)="onItemDeselect()"
                >
                </ng-multiselect-dropdown>
                </div>
                <div class="col-md-7 my-2" *ngIf="isPremiumProgram">
                  <div class="row">
                    <div class="form-grou form-check col-md-6">

                      <input class="yoga-prog-input" type="checkbox" [(ngModel)]="isContentProgram" data-md-icheck
                        [ngModelOptions]="{standalone: true}" />
                      <label class="form-check-label" for="">Content Based Program</label>

                    </div>
                    <div class="form-grou form-check col-md-6">

                      <input class="yoga-prog-input" type="checkbox" [(ngModel)]="isOnlineProgram" data-md-icheck
                        [ngModelOptions]="{standalone: true}" />
                      <label class="form-check-label" for="">Live Program</label>
                    </div>
                  </div>
                </div>

                <div class="form-group col-12">

                  <div class="form-group col-10 col-md-5" *ngIf="isIndian">
                    <input type="number" class="form-control col-12" id="priceInRpees" name="priceInRupees"
                      [(ngModel)]="program.priceInRupees" OnlyPositiveNumbers numericType="decimal" maxlength="5"
                      placeholder="Price in ₹*" [disabled]="isDisabled">
                    <span style=" position: absolute; right: 0px;"
                      *ngIf="program.priceInRupees != undefined && program.priceInRupees != 0">₹</span>
                  </div>
                  <div class="form-group col-1 col-md-1" *ngIf="isIndian">
                    <i class="fa fa-info-circle"
                      *ngIf="program.priceInRupees == undefined || program.priceInRupees == 0"
                      title="This price is for indian users" aria-hidden="true"></i>
                    <i class="fa fa-info-circle"
                      *ngIf="program.priceInRupees != undefined && program.priceInRupees != 0"
                      title="This price is for indian users" aria-hidden="true"
                      style="color: yellowgreen; cursor: pointer;" (click)="calculate1o1Percentage('Rupees')"></i>
                  </div>

                  <div class="form-group col-10 col-md-5">
                    <input type="number" class="form-control col-12" id="priceInDollars" name="priceInDollars"
                      [(ngModel)]="program.priceInDollars" OnlyPositiveNumbers numericType="decimal"
                      placeholder="Price in $*" [disabled]="isDisabled">
                    <span style=" position: absolute; right: 0px;"
                      *ngIf="program.priceInDollars != undefined && program.priceInDollars != 0">$</span>
                  </div>
                  <div class="form-group col-1 col-md-1">
                    <i class="fa fa-info-circle"
                      *ngIf="program.priceInDollars == undefined || program.priceInDollars == 0"
                      title="This price is for United state users" aria-hidden="true"></i>
                    <i class="fa fa-info-circle"
                      *ngIf="program.priceInDollars != undefined && program.priceInDollars != 0"
                      title="This price is for United state users" aria-hidden="true"
                      style="color: yellowgreen; cursor: pointer;" (click)="calculate1o1Percentage('Dollers')"></i>
                  </div>
                  <div class="form-group col-12 col-md-6" *ngIf="isCurrencyDisabled">
                    
                    <ng-multiselect-dropdown class="form-control col-12"
                    [placeholder]="'Currency'" name="Currrency"
                    [settings]="dropdownSettings_single"
                    [data]="currency"
                    [(ngModel)]="SelectedCurrency"
                    [disabled]="isDisabled"
                  >
                  </ng-multiselect-dropdown>
                  </div>
                </div>








                <div class="col-12 justify-content-end">
                  <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
                    cdkStepperPrevious>Previous</button>
                  <button type="button" class="btn btn-default save-exercise-btn float-right" style="margin-top: 10px;"
                    cdkStepperNext (click)="validateForm('step2')">Next</button>
                </div>

              </form>
            </div>
          </cdk-step>

          <cdk-step [stepControl]="stepThree" [optional]="true">
            <ng-template cdkStepLabel>
              <div class="step-bullet">3</div>
              <div class="step-title">Schedule</div>
            </ng-template>
            <div class="exercise-content" #stepThree>
              <form class="form-inline trainer-profile-form">



                <div class="form-group d-flex col-12 col-md-6">
                  <div class="col-5 p-0 mt-2">Program start date*</div>
                  <div class="col-7">
                    <!-- <input type="date" class="form-control col-12 launch-date-style"
                    [disabled]="isDisabled" name="startDate" [(ngModel)]="startDate" placeholder="date" (change)="onDateChange()"> -->
                    <my-date-picker name="startDate" class="yg-strt-dt-pker" [(ngModel)]="startDate"
                      [options]="myDatePickerOptions" [disabled]="isDisabled" (dateChanged)="onDateChanged($event)"
                      placeholder="Date"></my-date-picker>
                  </div>
                </div>
                <div class="form-group d-flex col-12 col-md-6">
                  <div class="col-5 p-0 mt-2">Enrollment start date*</div>
                  <div class="col-7">
                    <!-- <input type="date" [disabled]="isDisabled"
                    class="form-control col-12 launch-date-style" name="enrollmentDate" [(ngModel)]="enrollmentDate"
                    placeholder="date"> -->
                    <my-date-picker class="yg-strt-dt-pker" [options]="myDatePickerOptions" name="enrollmentDate"
                      [(ngModel)]="enrollmentDate" [disabled]="isDisabled" placeholder="Date"></my-date-picker>
                  </div>
                </div>

                <div class="form-group d-flex col-12 col-md-6" *ngIf="isPremiumProgram">
                  <div class="col-5 p-0 mt-2">Program end date</div>
                  <div class="col-7">
                    <!-- <input type="date" class="form-control col-12 launch-date-style"
                    [disabled]="true" name="endDate" [(ngModel)]="endDate" placeholder="date"> -->
                    <my-date-picker [options]="myDatePickerOptions" name="endDate" [(ngModel)]="endDate"
                      [disabled]="true" placeholder="Date"></my-date-picker>
                  </div>
                </div>
                <div class="form-group d-flex col-12 col-md-6" *ngIf="isPremiumProgram">
                  <div class="col-5 p-0 mt-2">Enrollment end date</div>
                  <div class="col-7">

                    <!-- <input type="date" [disabled]="isDisabled"
                    class="form-control col-12 launch-date-style" name="enrollmentEndDate" [(ngModel)]="enrollmentEndDate"
                    placeholder="date"> -->

                    <my-date-picker class="yg-strt-dt-pker" [options]="myDatePickerOptions" name="enrollmentEndDate"
                      [(ngModel)]="enrollmentEndDate" [disabled]="isDisabled" placeholder="Date"></my-date-picker>

                  </div>
                </div>
                <div class="col-md-12 my-2" *ngIf="isOnlineProgram == true && isPremiumProgram == true">
                  <div class="row">

                    <div class="form-check-label my-1 col-md-12" for="">Training Days </div>

                    <div class="col-md-12">
                      <div (click)="onDaySelect('Monday')" style="cursor: pointer;"
                        [ngClass]="{'yoga-select-day': selectedDays.includes('Monday')}" class="btn">
                        Mon
                      </div>
                      <div (click)="onDaySelect('Tuesday')" style="cursor: pointer;"
                        [ngClass]="{'yoga-select-day': selectedDays.includes('Tuesday')}" class="btn">
                        Tue
                      </div>
                      <div (click)="onDaySelect('Wednesday')" style="cursor: pointer;"
                        [ngClass]="{'yoga-select-day': selectedDays.includes('Wednesday')}" class="btn">
                        Wed
                      </div>
                      <div (click)="onDaySelect('Thursday')" style="cursor: pointer;"
                        [ngClass]="{'yoga-select-day': selectedDays.includes('Thursday')}" class="btn">
                        Thu
                      </div>
                      <div (click)="onDaySelect('Friday')" style="cursor: pointer;"
                        [ngClass]="{'yoga-select-day': selectedDays.includes('Friday')}" class="btn">
                        Fri
                      </div>
                      <div (click)="onDaySelect('Saturday')" style="cursor: pointer;"
                        [ngClass]="{'yoga-select-day': selectedDays.includes('Saturday')}" class="btn">
                        Sat
                      </div>
                      <div (click)="onDaySelect('Sunday')" style="cursor: pointer;"
                        [ngClass]="{'yoga-select-day': selectedDays.includes('Sunday')}" class="btn">
                        Sun
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 my-2" *ngIf="isOnlineProgram == true && isPremiumProgram == true">
                  <div class="row">
                    <div class="col-md-4 text-left">

                      <div class="my-1 col-md-12">Select number of slots</div>
                      <div class="my-1 col-md-12">
                        
                        <ng-multiselect-dropdown  class="form-control col-12 col-md-12 yoga-select-slots"
                        [placeholder]="'Slots*'"  name="slots"
                        [settings]="dropdownSettings_single"
                        [data]="noOfSlots"
                        [(ngModel)]="SelectedSlots"
                        [disabled]="isDisabled" (onSelect)="onSlotSelect()"
                          (onDeSelect)="onSlotDeselect()"
                      >
                      </ng-multiselect-dropdown>
                      </div>

                    </div>
                    <div class="col-md-4 text-left">


                      <div class="my-1 col-md-12">Select number of participants </div>
                      <div class="my-1 col-md-12">
                        
                        <ng-multiselect-dropdown  class="form-control col-12 col-md-12 yoga-select-slots"
                        [placeholder]="'Participants*'" name="participants"
                        [settings]="dropdownSettings_single"
                        [data]="noOfParticipants"
                        [(ngModel)]="SelectedPraticipants"
                        [disabled]="isDisabled"
                        (onSelect)="onParticipantSelect()" (onDeSelect)="onParticipantSelect()"
                      >
                      </ng-multiselect-dropdown>
                      </div>
                    </div>
                    <div class="col-md-4 text-left">


                      <div class="my-1 col-md-12">Call duration </div>
                      <div class="my-1 col-md-12">
                      
                        <ng-multiselect-dropdown  class="form-control col-12 col-md-12 yoga-select-slots"
                        [placeholder]="'Duration*'"  name="selectedFrequency" 
                        [settings]="dropdownSettings_single"
                        [data]="frequencies"
                        [(ngModel)]="selectedFrequency"
                        [disabled]="isDisabled"
                      >
                      </ng-multiselect-dropdown>

                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-7 my-2" *ngIf="isOnlineProgram == true && isPremiumProgram == true">
                  <div class="row">
                    <div class="col-md-4 text-center">
                      <div class="my-1 col-md-12">Slot</div>
                    </div>
                    <div class="col-md-4 text-center">
                      <div class="my-1 col-md-12">Start time</div>
                    </div>
                    <div class="col-md-4 text-center">
                      <div class="my-1 col-md-12">End Time</div>
                    </div>

                  </div>

                  <div class="row" *ngFor="let slot of slotsTimes">
                    <div class="col-md-4 text-center">

                      <!-- <div class="my-1 col-md-12 text-left">Slot</div> -->
                      <div class="my-1 col-md-12">
                        {{slot.id}}
                      </div>

                    </div>
                    <div class="col-md-4 text-center">

                      <!-- <div class="my-1 col-md-12 text-left">Start Time</div> -->
                      <div class="mb-2">
                       
                        <ng-multiselect-dropdown  id="startTime" name="startTime_{{slot.id}}"
                        [settings]="dropdownSettings_single"
                        [data]="timeintervals"
                        [(ngModel)]="slot.startTime"
                        (onSelect)="calculateEndTime(slot.id)" class="form-control col-12"
                          style="border: 1px solid #9acd32 !important; border-radius: 5px !important; width: -webkit-fill-available"
                      >
                      </ng-multiselect-dropdown>
                      </div>

                    </div>
                    <div class="col-md-4 text-center">


                      <!-- <div class="my-1 col-md-12 text-left">End Time </div> -->
                      <div class="mb-2">
                       
                        <ng-multiselect-dropdown id="endTime" name="endTime_{{slot.id}}"
                        [settings]="dropdownSettings_single"
                        [data]="timeintervals"
                        [(ngModel)]="slot.endTime"
                        class="form-control col-12"
                          style="border: 1px solid #9acd32 !important; border-radius: 5px !important;"
                          [disabled]="true"
                      >
                      </ng-multiselect-dropdown>
                        <!-- <input class="form-control col-12" type=text [(ngModel)]="slot.endTime" name="slotEndDate"
                          style="border: 1px solid #9acd32 !important; border-radius: 5px !important;" readonly> -->
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div class="row col-md-12">
                  <label>Zoom Call Link (Optional)</label>
                  <input type="text" [(ngModel)] = "program.externalCallLink" name="externalCallLink" id="externalCallLink" class="form-control col-6" />
                 </div> -->

                <div class="col-12 justify-content-end">
                  <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
                    cdkStepperPrevious>Previous</button>
                  <button type="button" class="btn btn-default save-exercise-btn float-right" style="margin-top: 10px;"
                    cdkStepperNext (click)="validateForm('step3')">Next</button>
                </div>

              </form>
            </div>
          </cdk-step>

          <cdk-step [stepControl]="stepFour" [optional]="true">
            <ng-template cdkStepLabel>
              <div class="step-bullet">4</div>
              <div class="step-title">Finish</div>
            </ng-template>
            <div class="exercise-content" #stepFour>
              <form class="form-inline trainer-profile-form">
                <div class="container-fluid p-0 mb-3"
                  style="border: 1px solid yellowgreen; border-radius: 10px; background-color: rgba(0, 0, 0, 0.274);">
                  <div class="col-md-3 mx-auto text-center my-3" style="    background: #4e6323;
                    border-radius: 50px;
                    font-size: 18px;
                    padding: 4px;
                    font-weight: 700;
                    border: 2px solid yellowgreen">Program</div>
                  <div class="col-md-12 p-3">
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6 font-weight-bold">
                              Program Name:
                            </div>
                            <div class="col-md-6">
                              {{ program.programName }}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6 font-weight-bold">
                              Program Type:
                            </div>
                            <div class="col-md-6" *ngFor='let ptype of SelectedProgramType'>
                              {{ ptype.name }}
                            </div>
                          </div>

                        </div>
                      </div>

                    </div>
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-3 font-weight-bold"> Program Description:
                        </div>
                        <div class="col-md-9"> {{ program.description }}
                        </div>
                      </div>

                    </div>
                  </div>
                </div>


                <div class="container-fluid p-0 mb-3"
                  style="border: 1px solid yellowgreen; border-radius: 10px; background-color: rgba(0, 0, 0, 0.274);">
                  <div class="col-md-3 mx-auto text-center my-3" style="    background: #4e6323;
                  border-radius: 50px;
                  font-size: 18px;
                  padding: 4px;
                  font-weight: 700;
                  border: 2px solid yellowgreen">Schedule</div>
                  <div class="col-md-12 p-3">
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6 font-weight-bold">
                              Package type:
                            </div>
                            <div class="col-md-6" *ngFor='let packagetype of selectedPackage'>
                              {{ packagetype.name }}
                            </div>
                          </div>
                        </div>




                      </div>

                    </div>
                    <div class="col-md-12 my-2" *ngIf="isOnlineProgram == true && isPremiumProgram == true">
                      <div class="row">

                        <div class="form-check-label my-1 col-md-12" for="">Training Days </div>

                        <div class="col-md-12">
                          <div *ngIf="selectedDays.includes('Monday')" class="btn">
                            Mon
                          </div>
                          <div *ngIf="selectedDays.includes('Tuesday')" class="btn">
                            Tue
                          </div>
                          <div *ngIf="selectedDays.includes('Wednesday')" class="btn">
                            Wed
                          </div>
                          <div *ngIf="selectedDays.includes('Thursday')" class="btn">
                            Thu
                          </div>
                          <div *ngIf="selectedDays.includes('Friday')" class="btn">
                            Fri
                          </div>
                          <div *ngIf="selectedDays.includes('Saturday')" class="btn">
                            Sat
                          </div>
                          <div *ngIf="selectedDays.includes('Sunday')" class="btn">
                            Sun
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 my-2" *ngIf="isOnlineProgram == true && isPremiumProgram == true">
                      <div class="row">

                        <div class="form-check-label my-1 col-md-12" for="">Slot Timing </div>

                        <div class="col-md-12">
                          <div class="btn" *ngFor="let slot of displaySlotsTimes">
                            {{slot.startTime}} - {{slot.endTime}}
                          </div>

                        </div>
                      </div>
                    </div>


                    <div class="col-md-12 my-2" *ngIf="isOnlineProgram == true && isPremiumProgram == true">
                      <div class="row">

                        <div class="form-check-label my-1 col-md-12" for="">Participants </div>

                        <div class="col-md-12">
                          <div class="btn">
                            {{nOfP}}
                          </div>

                        </div>
                      </div>
                    </div>


                  </div>

                </div>




                <div class="container-fluid p-0 mb-3"
                  style="border: 1px solid yellowgreen; border-radius: 10px; background-color: rgba(0, 0, 0, 0.274);">
                  <div class="col-md-3 mx-auto text-center my-3" style="    background: #4e6323;
                  border-radius: 50px;
                  font-size: 18px;
                  padding: 4px;
                  font-weight: 700;
                  border: 2px solid yellowgreen">Pricing</div>
                  <div class="col-md-12 p-3">
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6 font-weight-bold">
                              Price in ₹:
                            </div>
                            <div class="col-md-6">
                              {{ program.priceInRupees }}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6 font-weight-bold">
                              Price in $:
                            </div>
                            <div class="col-md-6">
                              {{ program.priceInDollars }}
                            </div>
                          </div>

                        </div>
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6 font-weight-bold">
                              Enrollment start date
                            </div>
                            <div class="col-md-6">
                              {{ testEnrollDate | date:"mediumDate" }}
                            </div>
                          </div>

                        </div>
                        <!-- <div class="col-md-6">  
                        <div class="row">  
                          <div class="col-md-6 font-weight-bold">
                              Enrollment end date
                          </div>
                          <div class="col-md-6">
                            {{ program.enrollmentEndDate }}
                          </div>
                        </div>
                      
                      </div> -->
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6 font-weight-bold">
                              Program start date
                            </div>
                            <div class="col-md-6">
                              {{ testStartDate | date:"mediumDate"}}
                            </div>
                          </div>

                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div class="col-12 justify-content-end">
                  <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
                    cdkStepperPrevious>Previous</button>
                  <button type="button" class="btn btn-default save-exercise-btn float-right" style="margin-top: 10px;"
                    *ngIf="editTrue" (click)="saveProgram()">Save</button>

                </div>
              </form>
            </div>
          </cdk-step>

        </ng-stepper>
        <!-- <div class="exercise-content">
          <form class="form-inline trainer-profile-form">
            <div class="form-group col-12">
              <input type="text" class="form-control col-12" id="exerciseName" name="exerciseName" autocomplete="off"
                [(ngModel)]="program.programName" #programName="ngModel" minlength="1" placeholder="Program Name*"
                [disabled]="isDisabled">
              <div *ngIf="programName.invalid && (programName.dirty || programName.touched)" class="alert alert-danger">
                <div *ngIf="programName.errors?.minlength">
                  Program Name should have Minimum 2 Character
                </div>
              </div>
            </div>
            <div class="form-group col-12">

              <div class="add_program_duration col-md-12">

                <div class="row col-4">
                  <div>
                    Plan
                  </div>
                  <input class="col-12 col-md-6 mx-auto" type="number" OnlyPositiveNumbers numericType="number" [(ngModel)]="program.plans"
                    (ngModelChange)="calculate()" name="plans" [disabled]="isDurationDisabled">
                </div>

                 <div>
                  X
                </div>


                <div class="row col-4">
                  <div>
                    Weeks
                  </div>
                  <input class="col-12 col-md-6 mx-auto" type="number" OnlyPositiveNumbers numericType="number" [(ngModel)]="program.weeks"
                    (ngModelChange)="calculate()" name="weeks" [disabled]="isDurationDisabled">
                </div>

                <div>
                  =
                </div>

                <div class="row col-4">
                  <div>
                    Duration*
                  </div>
                  <input class="col-12 col-md-6 mx-auto" type="number" OnlyPositiveNumbers numericType="number" [(ngModel)]="program.programDuration"
                    name="programDuration" readonly [disabled]="isDurationDisabled" style="background-color: yellowgreen; cursor: not-allowed;" >
                </div>
              </div>
            </div>
            <div class="form-group col-12">
              <textarea rows="3" class="form-control col-12 textarea-check" autocomplete="off"
                placeholder="Program Description*" id="description" #programDes="ngModel"
                [(ngModel)]="program.description" maxlength="800" pattern="^[a-zA-Z ]+$" name="description"
                [disabled]="isDisabled" style="height: 71px !important">Program Description*</textarea>
            </div>
              <div class="form-group col-10 col-md-5" *ngIf = "isIndian">
                <input type="number" class="form-control col-12" id="priceInRpees" name="priceInRupees" [(ngModel)]="program.priceInRupees"
                OnlyPositiveNumbers numericType="decimal" maxlength="5" placeholder="Price in ₹*" [disabled]="isDisabled">
                <span style=" position: absolute; right: 0px;" *ngIf="program.priceInRupees != undefined && program.priceInRupees != 0">₹</span>
                </div>
                <div class="form-group col-1 col-md-1" *ngIf = "isIndian" >
                  <i class="fa fa-info-circle" *ngIf="program.priceInRupees == undefined || program.priceInRupees == 0" title="This price is for indian users" aria-hidden="true"></i>
                  <i class="fa fa-info-circle" *ngIf="program.priceInRupees != undefined && program.priceInRupees != 0" title="This price is for indian users" aria-hidden="true" style="color: yellowgreen; cursor: pointer;" (click)="calculate1o1Percentage('Rupees')" ></i>
                </div>

                <div class="form-group col-10 col-md-5">
                <input type="number" class="form-control col-12" id="priceInDollars" name="priceInDollars" [(ngModel)]="program.priceInDollars"
                OnlyPositiveNumbers numericType="decimal" placeholder="Price in $*" [disabled]="isDisabled">
                <span style=" position: absolute; right: 0px;" *ngIf="program.priceInDollars != undefined && program.priceInDollars != 0">$</span>
                </div>
                <div class="form-group col-1 col-md-1">
                  <i class="fa fa-info-circle" *ngIf="program.priceInDollars == undefined || program.priceInDollars == 0" title="This price is for United state users" aria-hidden="true"></i>
                  <i class="fa fa-info-circle" *ngIf="program.priceInDollars != undefined && program.priceInDollars != 0" title="This price is for United state users" aria-hidden="true" style="color: yellowgreen; cursor: pointer;" (click)="calculate1o1Percentage('Dollers')"></i>
                </div>
            <div class="form-group col-12 col-md-6" *ngIf = "isCurrencyDisabled">
              <ng-multiselect-dropdown class="form-control col-12" [data]="currency" [placeholder]="'Currency'"
                [(ngModel)]="SelectedCurrency" name="Currrency" [settings]="dropdownSettings_single"
                [disabled]="isDisabled">
              </ng-multiselect-dropdown>
            </div>

            <div class="form-group col-12 col-md-6">
              <ng-multiselect-dropdown class="form-control col-12" [data]="programTypes" [placeholder]="'Program type*'"
                [(ngModel)]="SelectedProgramType" name="programType" [settings]="dropdownSettings_single"
                [disabled]="isDisabled"  (onSelect)="onItemSelect()" (onDeSelect)="onItemDeselect()">
              </ng-multiselect-dropdown>
            </div>

            <div class="form-group col-12 col-md-6" *ngIf="isNutritionShow">
              <ng-multiselect-dropdown class="form-control col-12" [data]="Nutritions" [placeholder]="'Nutrition*'"
                [(ngModel)]="SelectedNutrition" [disabled]="isDisabled" name="Nutrition"
                [settings]="dropdownSettings_single">
              </ng-multiselect-dropdown>
            </div>
            <div class="form-group col-12 col-md-6">
              <ng-multiselect-dropdown class="form-control col-12" [data]="packagetypes" [placeholder]="'Package type*'"
                [(ngModel)]="selectedPackage" [disabled]="isDisabled" name="packageType"
                [settings]="dropdownSettings_single" >
              </ng-multiselect-dropdown>
            </div>
            <div class="form-group d-flex col-12 col-md-6">
              <div class="col-5 p-0 mt-2">Program start date*</div>
              <div class="col-7">
                  <my-date-picker name="startDate"
                    [(ngModel)]="startDate" [options]="myDatePickerOptions" [disabled]="isDisabled" (dateChanged)="onDateChanged($event)"  placeholder="Date"></my-date-picker>
                </div>
            </div>
            <div class="form-group d-flex col-12 col-md-6">
              <div class="col-5 p-0 mt-2">Enrollment start date*</div>
              <div class="col-7">
                <my-date-picker [options]="myDatePickerOptions" name="enrollmentDate" [(ngModel)]="enrollmentDate" [disabled]="isDisabled" placeholder="Date"></my-date-picker>
                </div>
            </div>

            <div class="form-group d-flex col-12 col-md-6" *ngIf="isPremiumProgram">
              <div class="col-5 p-0 mt-2">Program end date</div>
              <div class="col-7">
                  <my-date-picker [options]="myDatePickerOptions" name="endDate" [(ngModel)]="endDate" [disabled]="true" placeholder="Date"></my-date-picker>
                </div>
            </div>
            <div class="form-group d-flex col-12 col-md-6" *ngIf="isPremiumProgram">
              <div class="col-5 p-0 mt-2">Enrollment end date</div>
              <div class="col-7">

                  <my-date-picker [options]="myDatePickerOptions" name="enrollmentEndDate" [(ngModel)]="enrollmentEndDate" [disabled]="isDisabled" placeholder="Date"></my-date-picker>
              </div>
            </div>

            <div class="d-flex col-12 justify-content-end">
              <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;" *ngIf="editTrue"
                (click)="saveProgram()">Save</button>
              <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
                (click)="Cancel()">Cancel</button>
            </div>
          </form>

        </div> -->
      </div>
      <div class="col-md-3 text-center profile-section">
        <h2 class="heading2">{{program.programName}}</h2>
        <div class="profile-pic">
          <div>
            <div for="imgFileUpload" style="margin-top: 200px;">
              <img id="img" attr.src="{{ imgUrl }}" width="100%"
                [ngClass]="{'profile-img': isImgUploaded == 'NO', 'img-fluid': isImgUploaded == 'YES' }"
                style="margin-bottom: 6px; margin-top: 6px;">
            </div>

            <input type="file" id="imgFileUpload" (change)="selectFile($event,'image')" [disabled]="isDisabled"
              #imgUpload>
            <a class="imgFileUpload"><i class="fas fa-pen" style="cursor:pointer" (click)="imgUpload.click()"></i></a>
          </div>
        </div>
      </div>
    </div>
    <!-- showCalendarPopup -->
    <ng-container *ngIf="showCalendarPopup">
      <div class="program_event-popup__wrapper">
        <div class="program_event-pop zoomIn background-shadow-green">
          <div class="program_event-header text-center">
            <h4>Create calendar</h4>
          </div>
          <a (click)="closePopup()" class="close-program_event-pop"><i class="fas fa-times"></i></a>
          <div class="program_event-body d-flex justify-content-center">
            <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
              (click)="onCalendarYes()">Yes</button>
            <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
              (click)="onCalendarNo()">No</button>
          </div>
        </div>
      </div>
    </ng-container>

  </div>
  <!-- showTaxPopup -->
  <ng-container *ngIf="taxpopup">
    <div class="program_event-popup__wrapper p-3">
      <div class="program_event-pop zoomIn background-shadow-green p-2" style="width: 30%;
">
        <div class="program_event-header text-center">
          <h4>Revenue details</h4>
        </div>
        <a (click)="closePopup()" class="close-program_event-pop"><i class="fas fa-times"></i></a>
        <div class="program_event-body d-flex justify-content-center" style="padding: 8px 8px;">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12 my-2">
                <div class="row">
                  <div class="col-md-6 col-6 fit-color-yg" *ngIf="display_currency != '$'">
                    Program price:
                  </div>
                  <div class="col-md-6 col-6 fit-color-yg" *ngIf="display_currency == '$'">
                    Program price <span style="font-size:10px;">(inclusive of tax)</span>
                  </div>
                  <div class="col-md-6 col-6 text-right">
                    {{display_currency}} {{programPrice}}
                  </div>
                </div>
              </div>

              <div class="col-md-12 my-2" *ngIf="display_currency != '$'">
                <div class="row">
                  <div class="col-md-6 col-6 fit-color-yg">
                    GST:
                  </div>
                  <div class="col-md-6 col-6 text-right">
                    {{display_currency}} {{gst_tax}}
                  </div>
                </div>
              </div>

              <div class="col-md-12 my-2">
                <div class="row">
                  <div class="col-md-6 col-6 fit-color-yg">
                    1o1 service fee:
                  </div>
                  <div class="col-md-6 col-6 text-right">
                    (-) {{display_currency}} {{servicetax_1o1}}
                  </div>
                </div>
              </div>

              <div class="col-md-12 py-2" style="border-top:1px solid white;">
                <div class="row">
                  <div class="col-md-6 col-6 fit-color-yg">
                    Trainee total price:
                  </div>
                  <div class="col-md-6 col-6 text-right">
                    {{display_currency}} {{ grand_tax_total }}
                  </div>
                  <div class="col-md-6 col-6 fit-color-yg">
                    Per trainee revenue:
                  </div>
                  <div class="col-md-6 col-6 text-right">
                    {{display_currency}} {{grand_total}}
                  </div>
                </div>
              </div>

              <div class="col-md-12 py-2">
                <div class="row">
                  <div class="col">
                    <i class="fa fa-info-circle" aria-hidden="true"> </i>
                    <span> Trainer will get net revenue after 1o1 service fee deductions.</span>
                  </div>
                </div>
              </div>

              <!-- <div class="btn mx-auto my-2" (click)="closePopup()">OK</div> -->
            </div>
          </div>


        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="showSlotsPopup">
    <div class="program_event-popup__wrapper">
      <div class="program_event-pop zoomIn background-shadow-green" style="width: 60%;">
        <div class="program_event-header text-center">
          <h5 class="modal-title" id="exampleModalLabel" style="font-weight: 700; color: yellowgreen;"> <i
              class="fa fa-times-circle fa-2x text-danger" aria-hidden="true"></i>Your new slots overlapping with the
            following existing slots</h5>
        </div>
        <a (click)="closePopup()" class="close-program_event-pop"><i class="fas fa-times"></i></a>
        <div class="program_event-body d-flex justify-content-center">
          <div class="container-fluid">
            <div class="row">

              <div class="col" style="height: 380px; overflow-y: scroll; overflow-x: hidden;">


                <div class="d-flex p-3" style="border-bottom: 1px solid yellowgreen!important;">
                  <div class="text-left col-md-2 calendar-exercise-bg">

                    <span class="px-2">Call Type</span>
                  </div>
                  <div class="col-md-4 px-1 calendar-exercise-bg text-center">
                    <span>Program Name</span>
                  </div>
                  <div class="col-md-2 px-1 calendar-exercise-bg text-center">
                    <span>Day</span>
                  </div>
                  <div class="col-md-2 px-1 calendar-exercise-bg text-center">
                    <span>Date</span>
                  </div>
                  <div class="col-md-2 px-1 calendar-exercise-bg text-center">
                    <span>Start time</span>
                  </div>

                  <div class="col-md-2 px-1 calendar-exercise-bg text-center">
                    <span>End time</span>
                  </div>

                </div>

                <div class="d-flex p-3" style="border-bottom: 1px solid yellowgreen!important;"
                  *ngFor="let duplicate_slot of duplicate_slots">
                  <div class="text-left col-md-2 calendar-exercise-bg">
                    <span class="px-2"> {{ duplicate_slot.callType | titlecase }} </span>
                  </div>
                  <div class="col-md-4 px-1 calendar-exercise-input text-center py-auto">
                    <!-- <input name="programName" class="rounded-pill col" type="text" [(ngModel)]="duplicate_slot.programName"
                      readonly> -->
                    <span class="px-2">{{ duplicate_slot.programName }}</span>
                  </div>

                  <div class="col-md-2 px-1 calendar-exercise-input text-center py-auto">
                    <!-- <input name="date" class="rounded-pill col" type="text" [(ngModel)]="duplicate_slot.date"
                      readonly> -->
                    <span class="px-2">{{ duplicate_slot.dayName}}</span>
                  </div>

                  <div class="col-md-2 px-1 calendar-exercise-input text-center py-auto">
                    <!-- <input name="date" class="rounded-pill col" type="text" [(ngModel)]="duplicate_slot.date"
                      readonly> -->
                    <span class="px-2">{{ duplicate_slot.date | date}}</span>
                  </div>

                  <div class="col-md-2 px-1 calendar-exercise-input">
                    <input name="startTime" class="rounded-pill col" type="text" [(ngModel)]="duplicate_slot.startTime"
                      readonly>
                  </div>

                  <div class="col-md-2 px-1 calendar-exercise-input">
                    <input name="endTime" class="rounded-pill col" type="text" [(ngModel)]="duplicate_slot.endTime"
                      readonly>
                  </div>

                  <!-- <div class="text-center col-md-2 calendar-exercise-bg">
                    <span *ngIf="exercise.exerciseStatus == 'new' && exercise.exerciseId != 'rest_id'" title="New"><img src="assets/images/new.png" style="width: 32px"></span>
                    <span *ngIf="exercise.exerciseStatus == 'inprogress' && exercise.exerciseId != 'rest_id'" title="Inprogress"><img src="assets/images/inprogress.png" style="width: 32px"></span>
                    <span *ngIf="exercise.exerciseStatus == 'completed' && exercise.exerciseId != 'rest_id'" title="Completed"><img src="assets/images/complete.png" style="width: 32px"></span>
                    <span *ngIf="exercise.exerciseStatus == 'not completed' && exercise.exerciseId != 'rest_id'" title="Not completed"><img src="assets/images/not-completed.png" style="width: 32px"></span>
                  </div>
   -->
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

</section>