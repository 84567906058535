<div class="zoom-meeting-container">
  <h2>Zoom Meeting</h2>
  
  <!-- Meeting status and error messages -->
  <div class="meeting-status">
    <p *ngIf="isJoining" class="status-message">Joining meeting...</p>
    <p *ngIf="!meetingJoined && !isJoining && !errorMessage" class="status-message">Waiting to join meeting...</p>
    <div *ngIf="errorMessage" class="error-container">
      <p class="error-message">{{ errorMessage }}</p>
      <button (click)="joinMeeting()" class="retry-button">Retry</button>
    </div>
  </div>

  <!-- Zoom meeting SDK will render the meeting interface here -->
  <div id="meetingSDKElement" [style.display]="meetingJoined ? 'block' : 'none'">
    <!-- Add video element for self-view -->
    <!-- <video id="selfViewVideo" #selfViewVideo></video> -->
  </div>

  <!-- Custom controls -->
  <div class="meeting-controls" *ngIf="meetingJoined">
    <button (click)="toggleAudio()">Toggle Audio</button>
    <!-- <button (click)="toggleVideo()">Toggle Video</button> -->
    <button (click)="leaveMeeting()">Leave Meeting</button>
  </div>

  <!-- Return to Dashboard button -->
  <div class="dashboard-controls" *ngIf="!meetingJoined">
    <button (click)="navigateToDashboard()">Return to Dashboard</button>
  </div>
</div>