
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { UserRegistrationService } from "../service/user-registration.service";
import { UserLoginService } from "../service/user-login.service";
import { LoggedInCallback } from "../service/cognito.service";
import { ToastrService } from 'ngx-toastr';
import { DBService } from '../service/db.service';

export class profile {
    trainerUserName: string;
    trainerId: string;
    profileStatus: string;
}
@Component({
    selector: 'app-confirm',
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.css']
})
// export class ConfirmComponent implements OnInit, OnDestroy {

//   constructor() { }

//   ngOnInit() {
//   }
// }
export class ConfirmComponent implements OnInit, OnDestroy {
    confirmationCode: string;
    email: string;
    userID: string;
    errorMessage: string;
    private sub: any;
    loaderHide: boolean = false;

    constructor(private db_service: DBService, public regService: UserRegistrationService, public router: Router, public route: ActivatedRoute, private toastr: ToastrService, public userService: UserLoginService) {
        // this.userService.isAuthenticated(this);
        this.router = router;
    }
    profile: profile;
    profileResponse: any;

    ngOnInit() {
        this.profile = new profile();
        this.sub = this.route.params.subscribe(params => {
            this.email = params['username'];
            // this.userID = params['userID'];
            this.profile.trainerUserName = params['username'];
            // this.profile.trainerId = params['userID'];
        });

        this.errorMessage = null;

        // document.body.classList.remove('body-main-build');
        // document.body.classList.remove('body-main-yoga');
        // document.body.classList.remove('body-main-zumba');
        // document.body.classList.add('body-main');
    }

    // isLoggedIn(message: string, isLoggedIn: boolean) {
    //     if (!isLoggedIn) {
    //         this.router.navigate(['/login']);
    //     }
    // }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    SaveProfile() {
        // if(this.profile.videoName != undefined && this.profile.imgName != undefined){
        // // this.loaderHide = !this.loaderHide;
        // this.profile.trainingSince = JSON.stringify(this.SelectedTrainingSince);
        // this.profile.certification = JSON.stringify(this.SelectedCertificates);
        // this.profile.servicesOffered = JSON.stringify(this.SelectedServices);
        this.profile.profileStatus = "created";
        var index = '/profile'
        this.db_service.saveDataToDB(this.profile, index).subscribe(
            response => {
                // this.loaderHide = !this.loaderHide;
                this.profileResponse = Array.of(response);
                // this.router.navigate(['myprofile', this.profileResponse[0].userId]);
                // this.router.navigate(['/login']);
                // this.toastr.success('','Profile created successfully.',{
                //   timeOut: 3000,
                //   positionClass:'toast-top-right',
                //   progressBar: true,
                //   progressAnimation: 'decreasing',
                //   tapToDismiss: true,
                // });
            },
            error => {
                // alert('there is an error while saving data');
                this.toastr.error('', 'There is an error while saving data', {
                    timeOut: 3000,
                    positionClass: 'toast-top-right',
                    progressBar: true,
                    progressAnimation: 'increasing',
                    tapToDismiss: true,
                });
            }
        )
    }

    onConfirmRegistration() {
        if (this.confirmationCode == null || this.confirmationCode == '') {
            this.toastr.error('', 'Mandatory Fields are required', {
                timeOut: 5000,
                extendedTimeOut: 1000,
                positionClass: 'toast-top-right',
                progressBar: true,
                progressAnimation: 'increasing',
                tapToDismiss: true
            });
        } else {
            this.loaderHide = !this.loaderHide;
            this.regService.confirmRegistration(this.email, this.confirmationCode, this);
            // this.SaveProfile();

        }

    }
    resendCode() {
        this.loaderHide = !this.loaderHide;
        this.regService.resendCode(this.email, this);
        // this.getProfileDataFromServer();
    }
    cognitoCallback(message: string, result: any) {
        let countMessage = 0;
        //console.log("message: " + message);
        if (message == "sent") { //error
            this.toastr.success('', 'Please enter 6 digit code sent to your email.', {
                timeOut: 5000,
                extendedTimeOut: 1000,
                positionClass: 'toast-top-right',
                progressBar: true,
                progressAnimation: 'increasing',
                tapToDismiss: true
            });
            this.loaderHide = !this.loaderHide;
        }
        else if (message != null) { //error
            this.errorMessage = message;
            this.loaderHide = !this.loaderHide;
            this.toastr.error('', message, {
                timeOut: 5000,
                extendedTimeOut: 1000,
                positionClass: 'toast-top-right',
                progressBar: true,
                progressAnimation: 'increasing',
                tapToDismiss: true
            });
        }
        else { //success
            // move to the next step
            // console.log("Moving to securehome");
            // this.configs.curUser = result.user;

            this.loaderHide = !this.loaderHide;
            // if( countMessage == 0){
            //   this.toastr.success('', 'User registered successfully', {
            //       timeOut: 5000,
            //       extendedTimeOut: 1000,
            //       positionClass: 'toast-top-right',
            //       progressBar: true,
            //       progressAnimation: 'increasing',
            //       tapToDismiss: true
            //   });
            //   countMessage++;
            // }
            // this.userID = result.accessToken.payload.sub;
            // this.router.navigate(['gender', this.email, this.userID]);
            this.router.navigate(['login']);

        }
    }
}
