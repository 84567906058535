<!-- <div id="mydiv" *ngIf="loaderHide">
  <img src="assets/images/landing_img.gif" width="10%" class="ajax-loader" />
</div> -->
<div id="mydiv" *ngIf="loaderHide && this.currentCategory == 'Bodybuilder'">
  <img src="assets/images/loaders/loaderexe.gif" width="10%" class="ajax-loader" />
</div>
<div id="mydiv" *ngIf="loaderHide && this.currentCategory == 'Yoga'">
  <img src="assets/images/loaders/loaderyoga.gif" width="10%" class="ajax-loader" />
</div>
<div id="mydiv" *ngIf="loaderHide && this.currentCategory == 'Zumba'">
  <img src="assets/images/loaders/loaderjumba.gif" width="10%" class="ajax-loader" />
</div>
<section>
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-lg-9 order-last order-md-1 exercise-left-div">
        <div class="exercise-content add-video">
          <div class="main-heading text-content py-2"><i class="fas fa-play"></i>
            <span *ngIf="asanaModel.asanaId == undefined">Add Video</span>
            <div *ngIf="asanaModel.asanaId != undefined">{{asanaModel.asanaTitle}}</div>
          </div>

          <div class="exercise-upload-video">

            <!-- <div
                id="dropzone"
                (dragover)="onDragOver($event)"
                (drop)="onDrop($event)"
                [ngClass]="{ 'drag-over': isDragOver }"
              >
                Drop files here
            </div> -->

            <div *ngIf="drgDropHidden" (drop)="onDrop($event,'video')" (dragover)="onDragOver($event)" 
              style="height: 350px; position: relative;">
              <input #fileInput type="file"
                style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; opacity: 0; cursor: pointer;"
                (change)="onFileSelected($event,'video')">
              <ng-container *ngTemplateOutlet="customDropzone" (click)="fileInput.click()"></ng-container>
            
            
              <ng-template #customDropzone>
                <div class="text-center border" style="background: rgba(0, 0, 0, 0.42); border-radius: 20px; height: 350px;">
                  <br /><br />
                  <h4><b style="color: rgb(173, 255, 47);"><span class="exer-word-style-a">Drag and drop</span><span
                        class="exer-word-style-b">Click here to add</span></b> a media file to begin the upload </h4>
                  <h4 style="color: rgb(255, 140, 0);">Maximum file size is 35 Mb</h4>
                  <br /><br /><br /><br /><br /><br /><br /><br />
                </div>
              </ng-template>
            </div>

            
            <!-- <progress-bar [progress]="100" [color]="'#488aff'">
              </progress-bar> -->

            <div (click)="closePopup()" class="close-video-pop" *ngIf="videoHidden">
              <!-- <i class="fas fa-times" style="position: absolute;margin-left: 752px;"></i> -->
              <video playsinline="" loop="" controls muted="" class="videoInsert">
                <source src="{{ asanaVideo.asanaVideoSource }}" type="video/mp4">
                Your browser does not support the video tag.
              </video>
            </div>
            <div class="exercise-upload-video mt-3" style="position: relative;" [innerHtml]="embedYoutubeUrl" *ngIf="iframeHidden">
            </div>
            <div class="dummy fas fa-close" style="position: absolute; top: 10px; left: 96%; transform: translate(-10px,50px); color: red;font-size: 20px; cursor: pointer;" title="close" (click)="onVideoPopupClose()" *ngIf="videoHidden == true || iframeHidden == true">
            </div>
           
          </div>
          <form method="post" *ngIf="drgDropHidden">
            <div class="exercise_url-block d-flex align-items-center">
              <div style="color: black;font-weight: 700;"><i class="fab fa-youtube" style="color:red; font-size: 30px;"></i> <span style="display:none"> &nbsp;
                Youtube URL &nbsp;</span></div>
              <div style="width: 80%;"><input type="text" id="YoutubeUrl" name="YoutubeUrl" placeholder="Enter Youtube URL"  autocomplete="nope"
                  [(ngModel)]="asanaVideo.youtubeUrl">
                <i (click)="changeYoutubeURL()" style="color:yellowgreen" class="fas fa-upload youtube-play-btn" title="Click to fetch the YouTube video"></i>
              </div>
              <!-- <div></div> -->
              <!--input type="file" id="fileInput" class="box__file"-->
            </div>
          </form>
        </div>

        <div class="exercise-content add-details">
          <h1 class="main-heading"><i class="far fa-address-card"></i> <span>Add Details</span></h1>

          <form class="exercise-add-details trainer-profile-form">
            <div class="form-group col-12 col-md-6">
              <input type="text" class="form-input-style col-12" id="exerciseName" name="exerciseName" autocomplete="nope"
                [(ngModel)]="asanaModel.asanaTitle" #asanaTitle="ngModel" minlength="1" maxlength="40"
                pattern="^[a-zA-Z -]+$" placeholder="Asana Name*" style="height: calc(2.25rem + -6px);">
              <!-- <span class="floating-label">Exercise Name</span> -->
              <div *ngIf="asanaTitle.invalid && (asanaTitle.dirty || asanaTitle.touched)"
                class="alert alert-danger">
                <!-- <div class="error_alert_msg error_alet_msg_show">
                  All fields are required.
                </div> -->
                <!-- <div *ngIf="exerciseName.errors?.required">
                  Exercise Name can't be blan
                </div> -->
                <div *ngIf="asanaTitle.errors?.minlength">
                  Asana Name should have Minimum 2 Character
                </div>
                <div *ngIf="asanaTitle.errors?.maxlength">
                  Asana Name should have Maximun 40 Character
                </div>
                <div *ngIf="asanaTitle.errors?.pattern">
                  Asana Name should only contain alphabets
                </div>
              </div>
            </div>
            <div class="form-group col-12 col-md-6">
            <ng-multiselect-dropdown  class="form-dropdown-style col-12"
              [placeholder]="'Asana Type'"
              [settings]="dropdownSettings_single" [disabled]="disabled"
              [data]="asanaTypes"  name="SelectedWorkoutType"
              [(ngModel)]="selectedAsanaType" (onSelect)="onItemSelect($event,'workoutType')"
              (onDeSelect)="onItemDeselect($event,'workoutType')"
            >
            </ng-multiselect-dropdown>
            </div>
            <!-- <div class="form-group col-12 col-md-6" *ngIf="isWorkoutType">
              <input type="text" class="form-input-style col-12" id="otherWorkoutType" name="otherWorkoutType" [(ngModel)]="exercise.otherWorkoutType"
                required>
              <span class="floating-label">Please enter other workout type</span>

            </div> -->
            <div class="form-group col-12 col-md-6">
              <!-- <ng-multiselect-dropdown class="form-dropdown-style col-12" [data]="MechanicalTypes"
                (onSelect)="onItemSelect($event,'mechanicalType')" (onDeSelect)="onItemDeselect($event,'workoutType')"
                [placeholder]="'Mechanical Type'" [(ngModel)]="SelectedMechanicalType" name="MechanicalTypes"
                [settings]="dropdownSettings_single" [disabled]="disabled">
              </ng-multiselect-dropdown> -->
              <input type="text" class="form-input-style col-12" id="adoptedName" name="adoptedName" autocomplete="nope"
                [(ngModel)]="asanaModel.adoptedName" #adoptedName="ngModel" minlength="1" maxlength="40"
                pattern="^[a-zA-Z -]+$" placeholder="Adopted Name" style="height: calc(2.25rem + -6px);">
              <!-- <span class="floating-label">Exercise Name</span> -->
              <div *ngIf="adoptedName.invalid && (adoptedName.dirty || adoptedName.touched)"
                class="alert alert-danger">
                <!-- <div class="error_alert_msg error_alet_msg_show">
                  All fields are required.
                </div> -->
                <!-- <div *ngIf="exerciseName.errors?.required">
                  Exercise Name can't be blank
                </div> -->
                <div *ngIf="adoptedName.errors?.minlength">
                  Adopted Name should have Minimum 2 Character
                </div>
                <div *ngIf="adoptedName.errors?.maxlength">
                  Adopted Name should have Maximun 40 Character
                </div>
                <div *ngIf="adoptedName.errors?.pattern">
                  Adopted Name should only contain alphabets
                </div>
              </div>
            </div>
            <!-- <div class="form-group col-12 col-md-6" *ngIf="isMechanicalType">
              <input type="text" class="form-input-style col-12" id="otherMechanicalType" name="otherMechanicalType"
                [(ngModel)]="exercise.otherMechanicalType" required placeholder="Please enter other mechanical type" style="height: calc(2.25rem + -6px);" >
            </div> -->
            <div class="form-group col-12 col-md-6">
              
              <ng-multiselect-dropdown class="form-dropdown-style col-12"
              [placeholder]="'Targeted Muscle'" name="MainMuscleWorkouts"
              [settings]="dropdownSettings" 
              [data]="MainMuscleWorkouts"
              [(ngModel)]="SelectedMainMuscleWorkout"   [disabled]="editDisabled"
              (onSelect)="onItemSelect($event,'mainMuscleWorkout')"
              (onDeSelect)="onItemDeselect($event,'workoutType')"
            >
            </ng-multiselect-dropdown>
            </div>
            <!-- <div class="form-group col-12 col-md-6" *ngIf="isMainMuscleWorkout">
              <input type="text" class="form-dropdown-style col-12" id="otherMainMuscleWokout" name="otherMainMuscleWokout"
                [(ngModel)]="exercise.otherMainMuscleWokout" required>
              <span class="floating-label">Please enter other main muscle workout</span>
            </div> -->
            <!-- <div class="form-group col-12 col-md-6">
              <ng-multiselect-dropdown class="form-dropdown-style col-12" [data]="OtherMuscleWorkouts"
                [placeholder]="'Other Muscle Workouts'" [(ngModel)]="SelectedOtherMuscleWorkouts"
                name="OtherMuscleWorkouts" [settings]="dropdownSettings" [disabled]="disabled">
              </ng-multiselect-dropdown>
            </div> -->
            <div class="form-group col-12 col-md-6">
            
              <ng-multiselect-dropdown class="form-dropdown-style col-12"
              [placeholder]="'Difficult Level'"
              [settings]="dropdownSettings_single" name="Levels"
              [data]="difficultLevel"
              [(ngModel)]="selectedDifficultLevels"
              [disabled]="disabled"
            >
            </ng-multiselect-dropdown>
            </div>
            <!-- <div class="form-group col-12 col-md-6">
              <ng-multiselect-dropdown class="form-dropdown-style col-12" [data]="Sports" [placeholder]="'Sport'"
                [(ngModel)]="SelectedSport" name="Sports" [settings]="dropdownSettings_single_1" [disabled]="disabled">
              </ng-multiselect-dropdown>
            </div>
            <div class="form-group col-12 col-md-6">
              <ng-multiselect-dropdown class="form-dropdown-style col-12" [data]="Equipments" [placeholder]="'Equipment'"
                [(ngModel)]="SelectedEquipment" name="Equipments" [settings]="dropdownSettings_single"
                [disabled]="disabled">
              </ng-multiselect-dropdown>
            </div> -->
            <div class="form-group col-12 col-md-6" *ngIf = "showasanaGoal">
          
              <ng-multiselect-dropdown  class="form-dropdown-style col-12"
              [placeholder]="'Asanas Goal'"
              [settings]="dropdownSettings"
              [data]="asanaGoals"
              [(ngModel)]="selectedasanaGoal"
              name="Forces" [disabled]="disabled"
            >
            </ng-multiselect-dropdown>
            </div>
            <textarea rows="6" class="add-instruction-text border" id="benifits" name="benifits"
              placeholder="Benifits of Asana" [(ngModel)]="asanaModel.benifitsOfAsana" #benifitsOfAsana="ngModel" minlength="20"
              maxlength="2000"></textarea>
            <!-- <span class="floating-label" style="margin-top: -7px;">About</span> -->
            <div *ngIf="benifitsOfAsana.invalid && (benifitsOfAsana.dirty || benifitsOfAsana.touched)" class="alert alert-danger-textarea">
              <!-- <div *ngIf="aboutU.errors?.required">
                  About is mandatory
                </div> -->
              <div *ngIf="benifitsOfAsana.errors?.minlength">
                Benifit of Asana should have minimum 20 characters
              </div>
              <div *ngIf="benifitsOfAsana.errors?.maxlength">
                Benifit of Asana should have maximum 2000 characters
              </div>
            </div>
            <textarea rows="6" class="add-instruction-text border" id="precautions" name="precautions"
              placeholder="Precautions" [(ngModel)]="asanaModel.precautions" #precautions="ngModel" minlength="20"
              maxlength="2000"></textarea>
            <!-- <span class="floating-label" style="margin-top: -7px;">About</span> -->
            <div *ngIf="precautions.invalid && (precautions.dirty || precautions.touched)" class="alert alert-danger-textarea">
              <!-- <div *ngIf="aboutU.errors?.required">
                  About is mandatory
                </div> -->
              <div *ngIf="precautions.errors?.minlength">
                Precautions should have minimum 20 characters
              </div>
              <div *ngIf="precautions.errors?.maxlength">
                Precautions should have maximum 2000 characters
              </div>
            </div>
          </form>

        </div>
        <div class="exercise-content basic-workout">
          <h1 class="main-heading"><i class="fas fa-dumbbell"></i> <span>Add Duration</span></h1>

          <div class="d-flex align-items-baseline exercise_slide_range">
            <div class="col-md-2" style="font-size: 16px;">Sets</div>
            <ng5-slider class="col-md-8" [(value)]="setsVal" [options]="setsOptions"></ng5-slider>
            <div class="col-md-2 text-center">
              <input type="number" OnlyPositiveNumbers numericType="number" name="setsVal" class="slider-range-value"
                [(ngModel)]="setsVal"></div>
          </div>
          <accordion [closeOthers]="oneAtATime">
            <accordion-group heading="SET 1" (click)="toggleCollapsed()" *ngIf="setsVal > 0">
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Reps</div>
                <ng5-slider class="col-md-8" [(value)]="repsVal" [options]="repsOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <!-- <div class="col-md-8" id="reps"></div> -->
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="repsVal"
                    class="slider-range-value" [(ngModel)]="repsVal">
                </div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Number of Breaths</div>
                <ng5-slider class="col-md-8" [(value)]="breathsVal" [options]="breathsOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <!-- <div class="col-md-8" id="reps"></div> -->
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="breathsVal"
                    class="slider-range-value" [(ngModel)]="breathsVal">
                </div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Minutes</div>
                <ng5-slider class="col-md-8" [(value)]="minutesVal" [options]="minutesOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <!-- <div class="col-md-8" id="restPeriod"></div> -->
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="minutesVal"
                    class="slider-range-value" [(ngModel)]="minutesVal" ></div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Seconds</div>
                <ng5-slider class="col-md-8" [(value)]="secondsVal" [options]="seconsOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <!-- <div class="col-md-8" id="restPeriod"></div> -->
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="secondsVal"
                    class="slider-range-value" [(ngModel)]="secondsVal" ></div>
              </div>
            </accordion-group>
            <accordion-group heading="SET 2" (click)="toggleCollapsed()" *ngIf="setsVal > 1">
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Reps</div>
                <ng5-slider class="col-md-8" [(value)]="repsVal1" [options]="repsOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="repsVal1"
                    class="slider-range-value" [(ngModel)]="repsVal1">
                </div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Number of Breaths</div>
                <ng5-slider class="col-md-8" [(value)]="breathsVal1" [options]="breathsOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="breathsVal1"
                    class="slider-range-value" [(ngModel)]="breathsVal1">
                </div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Minutes</div>
                <ng5-slider class="col-md-8" [(value)]="minutesVal1" [options]="minutesOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <!-- <div class="col-md-8" id="restPeriod"></div> -->
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="minutesVal1"
                    class="slider-range-value" [(ngModel)]="minutesVal1" ></div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Seconds</div>
                <ng5-slider class="col-md-8" [(value)]="secondsVal1" [options]="seconsOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <!-- <div class="col-md-8" id="restPeriod"></div> -->
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="secondsVal1"
                    class="slider-range-value" [(ngModel)]="secondsVal1" ></div>
              </div>
            </accordion-group>
            <accordion-group heading="SET 3" (click)="toggleCollapsed()" *ngIf="setsVal > 2">
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Reps</div>
                <ng5-slider class="col-md-8" [(value)]="repsVal2" [options]="repsOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="repsVal2"
                    class="slider-range-value" [(ngModel)]="repsVal2">
                </div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Number of Breaths</div>
                <ng5-slider class="col-md-8" [(value)]="breathsVal2" [options]="breathsOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="breathsVal2"
                    class="slider-range-value" [(ngModel)]="breathsVal2">
                </div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Minutes</div>
                <ng5-slider class="col-md-8" [(value)]="minutesVal2" [options]="minutesOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <!-- <div class="col-md-8" id="restPeriod"></div> -->
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="minutesVal2"
                    class="slider-range-value" [(ngModel)]="minutesVal2" ></div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Seconds</div>
                <ng5-slider class="col-md-8" [(value)]="secondsVal2" [options]="seconsOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <!-- <div class="col-md-8" id="restPeriod"></div> -->
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="secondsVal2"
                    class="slider-range-value" [(ngModel)]="secondsVal2" ></div>
              </div>
            </accordion-group>
            <accordion-group heading="SET 4" (click)="toggleCollapsed()" *ngIf="setsVal > 3">
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Reps</div>
                <ng5-slider class="col-md-8" [(value)]="repsVal3" [options]="repsOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="repsVal3"
                    class="slider-range-value" [(ngModel)]="repsVal3">
                </div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Number of Breaths</div>
                <ng5-slider class="col-md-8" [(value)]="breathsVal3" [options]="breathsOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="breathsVal3"
                    class="slider-range-value" [(ngModel)]="breathsVal3">
                </div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Minutes</div>
                <ng5-slider class="col-md-8" [(value)]="minutesVal3" [options]="minutesOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <!-- <div class="col-md-8" id="restPeriod"></div> -->
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="minutesVal3"
                    class="slider-range-value" [(ngModel)]="minutesVal3" ></div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Seconds</div>
                <ng5-slider class="col-md-8" [(value)]="secondsVal3" [options]="seconsOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <!-- <div class="col-md-8" id="restPeriod"></div> -->
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="secondsVal3"
                    class="slider-range-value" [(ngModel)]="secondsVal3" ></div>
              </div>
            </accordion-group>
            <accordion-group heading="SET 5" (click)="toggleCollapsed()" *ngIf="setsVal > 4">
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Reps</div>
                <ng5-slider class="col-md-8" [(value)]="repsVal4" [options]="repsOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="repsVal4"
                    class="slider-range-value" [(ngModel)]="repsVal4">
                </div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Number of Breaths</div>
                <ng5-slider class="col-md-8" [(value)]="breathsVal4" [options]="breathsOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="breathsVal4"
                    class="slider-range-value" [(ngModel)]="breathsVal4">
                </div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Minutes</div>
                <ng5-slider class="col-md-8" [(value)]="minutesVal4" [options]="minutesOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <!-- <div class="col-md-8" id="restPeriod"></div> -->
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="minutesVal4"
                    class="slider-range-value" [(ngModel)]="minutesVal4" ></div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Seconds</div>
                <ng5-slider class="col-md-8" [(value)]="secondsVal4" [options]="seconsOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <!-- <div class="col-md-8" id="restPeriod"></div> -->
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="secondsVal4"
                    class="slider-range-value" [(ngModel)]="secondsVal4" ></div>
              </div>
            </accordion-group>
            <accordion-group heading="SET 6" (click)="toggleCollapsed()" *ngIf="setsVal > 5">
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Reps</div>
                <ng5-slider class="col-md-8" [(value)]="repsVal5" [options]="repsOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="repsVal5"
                    class="slider-range-value" [(ngModel)]="repsVal5">
                </div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Number of Breaths</div>
                <ng5-slider class="col-md-8" [(value)]="breathsVal5" [options]="breathsOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="breathsVal5"
                    class="slider-range-value" [(ngModel)]="breathsVal5">
                </div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Minutes</div>
                <ng5-slider class="col-md-8" [(value)]="minutesVal5" [options]="minutesOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <!-- <div class="col-md-8" id="restPeriod"></div> -->
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="minutesVal5"
                    class="slider-range-value" [(ngModel)]="minutesVal5" ></div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Seconds</div>
                <ng5-slider class="col-md-8" [(value)]="secondsVal5" [options]="seconsOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <!-- <div class="col-md-8" id="restPeriod"></div> -->
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="secondsVal5"
                    class="slider-range-value" [(ngModel)]="secondsVal5" ></div>
              </div>
            </accordion-group>
            <accordion-group heading="SET 7" (click)="toggleCollapsed()" *ngIf="setsVal > 6">
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Reps</div>
                <ng5-slider class="col-md-8" [(value)]="repsVal6" [options]="repsOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="repsVal6"
                    class="slider-range-value" [(ngModel)]="repsVal6">
                </div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Number of Breaths</div>
                <ng5-slider class="col-md-8" [(value)]="breathsVal6" [options]="breathsOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="breathsVal6"
                    class="slider-range-value" [(ngModel)]="breathsVal6">
                </div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Minutes</div>
                <ng5-slider class="col-md-8" [(value)]="minutesVal6" [options]="minutesOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <!-- <div class="col-md-8" id="restPeriod"></div> -->
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="minutesVal6"
                    class="slider-range-value" [(ngModel)]="minutesVal6" ></div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Seconds</div>
                <ng5-slider class="col-md-8" [(value)]="secondsVal6" [options]="seconsOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <!-- <div class="col-md-8" id="restPeriod"></div> -->
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="secondsVal6"
                    class="slider-range-value" [(ngModel)]="secondsVal6" ></div>
              </div>
            </accordion-group>
            <accordion-group heading="SET 8" (click)="toggleCollapsed()" *ngIf="setsVal > 7">
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Reps</div>
                <ng5-slider class="col-md-8" [(value)]="repsVal7" [options]="repsOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="repsVal7"
                    class="slider-range-value" [(ngModel)]="repsVal7">
                </div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Number of Breaths</div>
                <ng5-slider class="col-md-8" [(value)]="breathsVal7" [options]="breathsOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="breathsVal7"
                    class="slider-range-value" [(ngModel)]="breathsVal7">
                </div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Minutes</div>
                <ng5-slider class="col-md-8" [(value)]="minutesVal7" [options]="minutesOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <!-- <div class="col-md-8" id="restPeriod"></div> -->
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="minutesVal7"
                    class="slider-range-value" [(ngModel)]="minutesVal7" ></div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Seconds</div>
                <ng5-slider class="col-md-8" [(value)]="secondsVal7" [options]="seconsOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <!-- <div class="col-md-8" id="restPeriod"></div> -->
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="secondsVal7"
                    class="slider-range-value" [(ngModel)]="secondsVal7" ></div>
              </div>
            </accordion-group>
            <accordion-group heading="SET 9" (click)="toggleCollapsed()" *ngIf="setsVal > 8">
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Reps</div>
                <ng5-slider class="col-md-8" [(value)]="repsVal8" [options]="repsOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="repsVal8"
                    class="slider-range-value" [(ngModel)]="repsVal8">
                </div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Number of Breaths</div>
                <ng5-slider class="col-md-8" [(value)]="breathsVal8" [options]="breathsOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="breathsVal8"
                    class="slider-range-value" [(ngModel)]="breathsVal8">
                </div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Minutes</div>
                <ng5-slider class="col-md-8" [(value)]="minutesVal8" [options]="minutesOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <!-- <div class="col-md-8" id="restPeriod"></div> -->
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="minutesVal8"
                    class="slider-range-value" [(ngModel)]="minutesVal8" ></div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Seconds</div>
                <ng5-slider class="col-md-8" [(value)]="secondsVal8" [options]="seconsOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <!-- <div class="col-md-8" id="restPeriod"></div> -->
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="secondsVal8"
                    class="slider-range-value" [(ngModel)]="secondsVal8" ></div>
              </div>
            </accordion-group>
            <accordion-group heading="SET 10" (click)="toggleCollapsed()" *ngIf="setsVal > 9">
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Reps</div>
                <ng5-slider class="col-md-8" [(value)]="repsVal9" [options]="repsOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="repsVal9"
                    class="slider-range-value" [(ngModel)]="repsVal9">
                </div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Number of Breaths</div>
                <ng5-slider class="col-md-8" [(value)]="breathsVal9" [options]="breathsOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="breathsVal9"
                    class="slider-range-value" [(ngModel)]="breathsVal9">
                </div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Minutes</div>
                <ng5-slider class="col-md-8" [(value)]="minutesVal9" [options]="minutesOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <!-- <div class="col-md-8" id="restPeriod"></div> -->
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="minutesVal9"
                    class="slider-range-value" [(ngModel)]="minutesVal9" ></div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Seconds</div>
                <ng5-slider class="col-md-8" [(value)]="secondsVal9" [options]="seconsOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <!-- <div class="col-md-8" id="restPeriod"></div> -->
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="secondsVal9"
                    class="slider-range-value" [(ngModel)]="secondsVal9" ></div>
              </div>
            </accordion-group>
          </accordion>
        </div>
        <div class="exercise-content add-instruction">
          <h1 class="main-heading"><i class="fas fa-info"></i> <span>Add Instructions</span></h1>
          <div  class="row" *ngFor="let instruction of instructions let i = index;">
          <textarea rows="3" class="add-instruction-text border col-md-10" id="instructions" name="instructions"
            placeholder="Add your instructions*" [(ngModel)]="instruction.name" #name="ngModel" name="instruction{{i}}" minlength="20"
            maxlength="2000"></textarea>
            
            <div class="col-md-2 my-auto">
              <i class="fa fa-plus mx-1" (click) = "addInstruction()"  *ngIf = "instructions.length == instruction.id" style="border-radius: 50%;border: 2px solid yellowgreen;padding: 10px;color: yellowgreen;" aria-hidden="true"></i>
              <i class="fa fa-minus mx-1"  *ngIf = "instruction.id != 1 && (instructions.length == instruction.id)" (click) = "removeInstruction(i)"   style=" border-radius: 50%;border: 2px solid yellowgreen;padding: 10px;color: red;" aria-hidden="true"></i>
          </div>
          <div *ngIf="instruction.name.invalid && (instruction.name.dirty || instruction.name.touched)" class="alert alert-danger-textarea">
            <!-- <div *ngIf="aboutU.errors?.required">
                About is mandatory
              </div> -->
            <div *ngIf="instruction.name.errors?.minlength">
              Instructions should have minimum 20 characters
            </div>
            <div *ngIf="instruction.name.errors?.maxlength">
              Instructions should have maximum 2000 characters
            </div>
          </div>
        </div>
        <!-- <div> </div> -->
          <!-- <span class="floating-label" style="margin-top: -7px;">About</span> -->
     
          <div class="d-flex p-3">
            <ui-switch defaultBgColor="#8a8585" [(ngModel)]="asanaModel.isPublic"></ui-switch>
            <div class="ml-2 p-1 add-instruction-toggle-text" >Add this to Basic profile video</div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-12 col-lg-3 text-center exercise__add order-first order-md-2 exercise-right-tabs">
        <a class="exercise__add-items active" onclick="exerciseDetails('addVideo', this)">
          <i class="fas fa-play"></i>
          <h3 class="exe-tab-sty">Add Video</h3>
        </a>
        <a class="exercise__add-items" onclick="exerciseDetails('addDetails', this)">
          <i class="far fa-address-card"></i>
          <h3 class="exe-tab-sty">Add Details</h3>
        </a>
        <a class="exercise__add-items" onclick="exerciseDetails('basicWorkout', this)">
          <!-- <i class="fas fa-dumbbell"></i> -->
          <!-- <img src="assets/images/icons/addDuration.png" style="width:50px"> -->
          <i class="fa icon-yogap"></i>
          <h3 class="exe-tab-sty">Add Duration</h3>
        </a>
        <a class="exercise__add-items " onclick="exerciseDetails('addInstructions', this)">
          <i class="fas fa-info"></i>
          <h3 class="exe-tab-sty">Add Instructions</h3>
        </a>
        <div class="col-12 exercise-save-details-div">
          <button type="button" class="btn btn-default save-exercise-btn mx-2"
            (click)="SaveAsana()">Save</button>
          <!-- <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;" *ngIf = "exercise.exerciseId != undefined" (click)="EditExercise()">Edit</button> -->
          <button type="button" class="btn btn-default save-exercise-btn mx-2"
            (click)="Cancel()">Cancel</button>
        </div>
      </div>

    </div>

    <div class="col-12 exercise-save-details-div-btm my-3 text-center">
      <button type="button" class="btn btn-default save-exercise-btn mx-2"
        (click)="SaveAsana()">Save</button>
      <!-- <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;" *ngIf = "exercise.exerciseId != undefined" (click)="EditExercise()">Edit</button> -->
      <button type="button" class="btn btn-default save-exercise-btn mx-2"
        (click)="Cancel()">Cancel</button>
    </div>
    <!-- isUpdateAsana -->
    <ng-container *ngIf="isUpdateAsana">
      <div class="program_event-popup__wrapper">
        <div class="program_event-pop zoomIn background-shadow-green" style="width:38% !important;">
          <div class="program_event-header">
            <h4 style=" line-height: 1.6;">Do you want to apply these changes in dependent workouts and programs
            </h4>
          </div>

          <div class="program_event-body d-flex justify-content-center">

            <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
              (click)="onUpdateExercise('No')">No</button>

            <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
              (click)="onUpdateExercise('Yes')">Yes</button>
          </div>
        </div>
      </div>
    </ng-container>

  </div>
</section>
<script type="application/javascript" src="../../assets/js/custom.js"></script>
