import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DBService } from '../service/db.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { CognitoUtil } from "../service/cognito.service";
import moment from 'moment';
import { UserLoginService } from '../service/user-login.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { YogaDBService } from '../service/YogaDBService';
// import pdfMake from 'pdfmake/build/pdfmake';
// import pdfFonts from 'pdfmake/build/vfs_fonts';
// pdfMake.vfs = pdfFonts.pdfMake.vfs;
// import htmlToPdfmake from 'html-to-pdfmake';
// 
@Component({
  selector: 'app-trainee-reports',
  templateUrl: './trainee-reports.component.html',
  styleUrls: ['./trainee-reports.component.css']
})
export class TraineeReportsComponent implements OnInit, OnDestroy {

  currentCategory: string;
  currentGender: string;
  private sub: any;

  currentLogged: string;
  currentEmail: string;
  private loggedIn: boolean;

  // Vars
  userID: string;
  trainee_id: string;
  program_id: string;
  alltrainees: any;
  Trainees: any;
  trainee: any;


  timezone: string;

  // class Variables
  repot_types: Object[];
  months: Object[];
  selectedMonth: Object[];
  selectedReport: Object[];
  dropdownSettings_single: any = {};
  closeDropdownSelection = false;
  final_programs: Object[];
  loaderHide: boolean = false;
  showPreview: boolean = false;
  workoutPreview: boolean = false;
  asanaPreview: boolean = true;
  queryStartDate: Date;
  queryEndDate: Date;
  trainerName: string;
  programs: object[];
  monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  constructor(private renderer: Renderer2, public router: Router, private toastr: ToastrService,
    public route: ActivatedRoute, private db_service: DBService, private yoga_db_service: YogaDBService, public datepipe: DatePipe, public cognitoUtil: CognitoUtil, public userService: UserLoginService) {
    this.userService.isAuthenticated(this);
  }

  ngOnInit() {
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.sub = this.route.params.subscribe(params => {
      this.trainee_id = params['traineeId'];
      this.program_id = params['programID'];
    });
    this.currentCategory = localStorage.getItem("ProfileCategory")
    this.currentGender = localStorage.getItem("ProfileGender");
    if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-build-male');
    }
    else if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-build-female');
    }
    else if (this.currentCategory == 'Yoga' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-yoga-male');
    }
    else if (this.currentCategory == 'Yoga' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-yoga-female');
    }
    else if (this.currentCategory == 'Zumba' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-zumba-male');
    }
    else if (this.currentCategory == 'Zumba' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-zumba-female');
    }

    this.dropdownSettings_single = {
      singleSelection: true,
      enableCheckAll: false,
      idField: 'id',
      textField: 'name',
      allowSearchFilter: false,
      itemsShowLimit: 1,
      closeDropDownOnSelection: this.closeDropdownSelection
    };
    this.generateMonths();
    if (this.currentCategory == 'Bodybuilder') {
      this.repot_types = [{ "id": "1", "name": "Workout History" },
      { "id": "2", "name": "Mealplan History" }];
    } else {
      this.repot_types = [{ "id": "1", "name": "Asana History" },
      { "id": "2", "name": "Mealplan History" }];
    }

  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'bg-img-build-male');
    this.renderer.removeClass(document.body, 'bg-img-yoga-male');
    this.renderer.removeClass(document.body, 'bg-img-zumba-male');
    this.renderer.removeClass(document.body, 'bg-img-build-female');
    this.renderer.removeClass(document.body, 'bg-img-yoga-female');
    this.renderer.removeClass(document.body, 'bg-img-zumba-female');
    this.renderer.addClass(document.body, 'body-main');
  }

  generateMonths() {
    var today = new Date();
    var d;
    var month;
    var year;
    this.months = [{ "id": 6, "name": "All" }];
    for (var i = 5; i >= 0; i -= 1) {
      d = new Date(today.getFullYear(), today.getMonth() - i, 1);
      month = this.monthNames[d.getMonth()];
      year = d.getFullYear();
      var monthobj = { "id": i, "name": month + "-" + year };
      this.months.push(JSON.parse(JSON.stringify(monthobj)));
    }
  }

  generateReport() {

    if (this.selectedReport == undefined) {
      this.toastr.error('', 'Please select report type.', {
        timeOut: 5000,
        extendedTimeOut: 1000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true
      });
    } else if (this.selectedReport.length == 0) {
      this.toastr.error('', 'Please select report type.', {
        timeOut: 5000,
        extendedTimeOut: 1000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true
      });
    }
    else if (this.selectedMonth == undefined) {
      this.toastr.error('', 'Please select month.', {
        timeOut: 5000,
        extendedTimeOut: 1000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true
      });
    } else if (this.selectedMonth.length == 0) {
      this.toastr.error('', 'Please select month.', {
        timeOut: 5000,
        extendedTimeOut: 1000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true
      });
    } else {
      this.loaderHide = true;

      if (this.selectedMonth[0]["name"] != 'All') {
        var month = this.selectedMonth[0]["name"].split("-");
        var mon = this.monthNames.findIndex(m => m == month[0]);
        this.queryStartDate = new Date(month[1], mon, 1);
        this.queryEndDate = new Date(month[1], mon + 1, 0);

        var currentMonth = this.monthNames[new Date().getMonth()];
        if (month[1] == currentMonth)
          this.queryEndDate = new Date();

      } else {
        var month = this.months[1]["name"].split("-");
        var mon = this.monthNames.findIndex(m => m == month[0]);
        this.queryStartDate = new Date(month[1], mon, 1);
        this.queryEndDate = new Date();
      }
      var startDate = this.datepipe.transform(this.queryStartDate, 'yyyy-MM-dd');
      var endDate = this.datepipe.transform(this.queryEndDate, 'yyyy-MM-dd');


      var index = "";
      if (this.selectedReport[0]["name"] == "Workout History") {
        index = "/program/trainee/workouthistory";
        this.workoutPreview = true;
        this.asanaPreview = false;
      } else if (this.selectedReport[0]["name"] == "Asana History") {
        index = "/program/trainee/asanahistory";
        this.workoutPreview = false;
        this.asanaPreview = true;
      }
      else {
        index = "/program/trainee/mealplanhistory";
        this.workoutPreview = false;
      }

      var data = {
        "trainerId": this.userID,
        "startDate": startDate,
        "endDate": endDate,
        "trainee_id": this.trainee_id
      }
      if (this.currentCategory == 'Bodybuilder') {
        this.db_service.saveDataToDB(data, index).subscribe(
          response => {
            this.getFinalReportData(response);
          },
          error => {
            this.loaderHide = false;
            this.toastr.error('', 'Something went wrong please try again.', {
              timeOut: 5000,
              extendedTimeOut: 1000,
              positionClass: 'toast-top-right',
              progressBar: true,
              progressAnimation: 'increasing',
              tapToDismiss: true
            });
            return;
          }

        )
      }
      else {
        this.yoga_db_service.saveDataToDB(data, index).subscribe(
          response => {
            this.getFinalReportData(response);
          },
          error => {
            this.loaderHide = false;
            this.toastr.error('', 'Something went wrong please try again.', {
              timeOut: 5000,
              extendedTimeOut: 1000,
              positionClass: 'toast-top-right',
              progressBar: true,
              progressAnimation: 'increasing',
              tapToDismiss: true
            });
            return;
          }

        )
      }

    }
  }

  filterWeeks(program) {
    if (this.queryStartDate > new Date(program["startDate"])) {
      var start = moment(this.datepipe.transform(this.queryStartDate, "yyyy-MM-dd"), "YYYY-MM-DD");
      var end = moment(this.datepipe.transform(new Date(program["startDate"]), "yyyy-MM-dd"), "YYYY-MM-DD");
      var diffDays = moment.duration(start.diff(end)).asDays();
      if (diffDays != 0) {
        var dayCount = 0;
        for (let j = 0; j < program["weeks"].length; j++) {
          for (let l = 0; l < program["weeks"][j].days.length; l++) {
            program["weeks"][j].days.splice(0, 1);
            dayCount = dayCount + 1;
            if (diffDays == dayCount)
              break;
          }
          if (diffDays == dayCount) {
            var dateArray;
            if (program["weeks"][j]["days"].length != 0)
              dateArray = program["weeks"][j]["days"][0]["date"].split("/");
            else
              dateArray = program["weeks"][j + 1]["days"][0]["date"].split("/");

            program["startDate"] = new Date(dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0]);
            break;
          }

        }
      }


    }
    if (new Date(program["endDate"]) > this.queryEndDate) {
      var start = moment(this.datepipe.transform(new Date(program["endDate"]), "yyyy-MM-dd"), "YYYY-MM-DD");
      var end = moment(this.datepipe.transform(this.queryEndDate, "yyyy-MM-dd"), "YYYY-MM-DD");
      var diffDays = moment.duration(start.diff(end)).asDays();
      if (diffDays != 0) {
        var dayCount = 0;
        for (let j = program["weeks"].length - 1; j >= 0; j--) {
          for (let l = program["weeks"][j].days.length - 1; l >= 0; l--) {
            program["weeks"][j].days.splice(program["weeks"][j].days.length - 1, 1);
            dayCount = dayCount + 1;
            if (diffDays == dayCount)
              break;
          }
          if (diffDays == dayCount) {
            var dateArray;
            if (program["weeks"][j]["days"].length != 0)
              dateArray = program["weeks"][j]["days"][program["weeks"][j]["days"].length - 1]["date"].split("/");
            else
              dateArray = program["weeks"][j - 1]["days"][program["weeks"][j - 1]["days"].length - 1]["date"].split("/");

            program["endDate"] = new Date(dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0]);
            break;
          }
        }
      }
    }
    return program;
  }

  downloadPdf() {
    this.loaderHide = true;
    setTimeout(() => {
      this.generatePdf();
    }, 5000)
  }
  generatePdf() {
    var data;
    if( this.asanaPreview == true)
     data = document.getElementById('asanaReport');
    else if(this.workoutPreview == true)
    data = document.getElementById('workoutReport');
    else
    data = document.getElementById('mealplanReport');
    // console.log(data);

    html2canvas(data, { allowTaint: true }).then(canvas => {
      let HTML_Width = canvas.width;
      let HTML_Height = canvas.height;
      let top_left_margin = 15;
      let PDF_Width = HTML_Width + (top_left_margin * 2);
      let PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
      let canvas_image_width = HTML_Width;
      let canvas_image_height = HTML_Height;
      let totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
      canvas.getContext('2d');
      let imgData = canvas.toDataURL("image/jpeg", 1.0);
      let pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
      pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);
      for (let i = 1; i <= totalPDFPages; i++) {
        pdf.addPage([PDF_Width, PDF_Height], 'p');
        pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
      }
      if (this.workoutPreview === true) {
        pdf.save("1o1_Workout_Report.pdf");
      }else if(this.asanaPreview == true){
        pdf.save("1o1_Asana_Report.pdf");
      }
      if (this.workoutPreview === false) {
        pdf.save("1o1_Mealplan_Report.pdf");
      }

      this.loaderHide = false;
    });



  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/login']);
    } else {
      // if()
      if (this.loggedIn == true) {
        this.currentEmail = localStorage.getItem('lastAuthUser');
        // if(this.currentEmail != undefined)
        this.getProfileDataFromServer();
      }
      else {
        // this.currentEmail = localStorage.getItem('CognitoIdentityServiceProvider.2io8v4r253hugt5ndbjv9shkfp.LastAuthUser');
        this.currentEmail = this.cognitoUtil.getCurrentUser().getUsername();
        if (this.currentEmail == undefined || this.currentEmail == '' || this.currentEmail == null || this.currentEmail.includes("Google") || this.currentEmail.includes("google")) {
          this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
          if (this.currentEmail == undefined)
            this.currentEmail = this.cognitoUtil.session.idToken.payload.email;
        }
        // this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
        this.getProfileDataFromServer();
      }
    }
  }
  getProfileDataFromServer() {
    if (this.currentCategory == 'Bodybuilder') {
      var index = '/profile/';
      this.db_service.getDataFromServer(this.currentEmail, index).subscribe(
        response => {
          // console.log('Data Retrived succesfully.', response);
          response = response.data[0];
          this.userID = response.trainerId;
          this.trainerName = response.firstName + " " + response.lastName;
          this.getAllPrograms();
        },
        error => {
          console.log("An error has occurred while retriving profile data.");
        }
      )

    } else {

      var index = '/profile/';
      this.yoga_db_service.getDataFromServer(this.currentEmail, index).subscribe(
        response => {
          // console.log('Data Retrived succesfully.', response);
          response = response.data[0];
          this.userID = response.trainerId;
          this.trainerName = response.firstName + " " + response.lastName;
          this.getAllPrograms();
        },
        error => {
          console.log("An error has occurred while retriving profile data.");
        }
      )
    }
  }
  getAllPrograms() {
    if (this.currentCategory == 'Bodybuilder') {
      var index = '/program/all/';
      var queryparams = {
        "pagesize": 0,
        "pagenumber": 0,
        "pagination": false
      }
      this.db_service.getQueriedData(this.userID, index, queryparams).subscribe(
        response => {
          // console.log('Data Retrived succesfully.', response);
          this.programs = response.data;
        },
        error => {
          console.log("An error has occurred while retriving profile data.");
        }
      )
    } else {
      var index = '/program/all/';
      var queryparams = {
        "pagesize": 0,
        "pagenumber": 0,
        "pagination": false
      }
      this.yoga_db_service.getQueriedData(this.userID, index, queryparams).subscribe(
        response => {
          // console.log('Data Retrived succesfully.', response);
          this.programs = response.data;
        },
        error => {
          console.log("An error has occurred while retriving profile data.");
        }
      )
    }
  }


  getFinalReportData(response) {
    if (response.data != null) {
      for (let i = 0; i < response.data.length; i++) {
        response.data[i]["weeks"].sort((a, b) => a.week - b.week);
        for (let k = 0; k < response.data[i]["weeks"].length; k++) {
          response.data[i]["weeks"][k]["days"].sort((a, b) => a.day - b.day);
        }
        var program = this.programs.find(p => p["programId"] == response.data[i]["program_id"]);
        response.data[i]["programName"] = program["programName"];
        response.data[i] = this.filterWeeks(response.data[i]);
      }

      var removeIndexes = [];

      for (let m = 0; m < response.data.length; m++) {
        var current_program = response.data[m];
        for (let i = 0; i < response.data.length; i++) {
          var diffDays = 0;
          if (current_program["calendar_id"] != response.data[i]["calendar_id"]) {
            if ((new Date(response.data[i]["startDate"]) >= new Date(current_program["startDate"]) && new Date(response.data[i]["startDate"]) <= new Date(current_program["endDate"])) && (new Date(response.data[i]["endDate"]) >= new Date(current_program["startDate"]) && new Date(response.data[i]["endDate"]) <= new Date(current_program["endDate"]))) {

              if (new Date(response.data[i]["startDate"]) > new Date(current_program["startDate"])) {
                var start = moment(this.datepipe.transform(new Date(response.data[i]["startDate"]), "yyyy-MM-dd"), "YYYY-MM-DD");
                var end = moment(this.datepipe.transform(new Date(current_program["startDate"]), "yyyy-MM-dd"), "YYYY-MM-DD");
                var startDiffDays = moment.duration(start.diff(end)).asDays();

                var start = moment(this.datepipe.transform(new Date(current_program["endDate"]), "yyyy-MM-dd"), "YYYY-MM-DD");
                var end = moment(this.datepipe.transform(new Date(response.data[i]["endDate"]), "yyyy-MM-dd"), "YYYY-MM-DD");
                var endDiffDays = moment.duration(start.diff(end)).asDays();

                var startCount = 0;
                var endCount = 0;

                for (let j = 0; j < current_program["weeks"].length; j++) {
                  var days = [];
                  for (let l = 0; l < current_program["weeks"][j].days.length; l++) {
                    days.push(JSON.parse(JSON.stringify(current_program["weeks"][j].days[l])));
                    startCount = startCount + 1;
                    if (startDiffDays == startCount)
                      break;
                  }
                  current_program["weeks"][j].days = days;
                  if (startDiffDays == startCount) {
                    break;
                  }
                }

                for (let j = current_program["weeks"].length - 1; j >= 0; j--) {
                  var days = [];
                  for (let l = current_program["weeks"][j].days.length - 1; l >= 0; l--) {
                    days.push(JSON.parse(JSON.stringify(current_program["weeks"][j].days[l])))
                    endCount = endCount + 1;
                    if (endDiffDays == endCount)
                      break;
                  }
                  current_program["weeks"][j].days = days.reverse();
                  if (endDiffDays == endCount) {
                    break;
                  }
                }
              }
              if (new Date(response.data[i]["startDate"]) < new Date(current_program["startDate"])) {
                var start = moment(this.datepipe.transform(new Date(current_program["startDate"]), "yyyy-MM-dd"), "YYYY-MM-DD");
                var end = moment(this.datepipe.transform(new Date(response.data[i]["startDate"]), "yyyy-MM-dd"), "YYYY-MM-DD");
                var startDiffDays = moment.duration(start.diff(end)).asDays();

                var start = moment(this.datepipe.transform(new Date(response.data[i]["endDate"]), "yyyy-MM-dd"), "YYYY-MM-DD");
                var end = moment(this.datepipe.transform(new Date(current_program["endDate"]), "yyyy-MM-dd"), "YYYY-MM-DD");
                var endDiffDays = moment.duration(start.diff(end)).asDays();

                var startCount = 0;
                var endCount = 0;

                for (let j = 0; j < response.data[i]["weeks"].length; j++) {
                  for (let l = 0; l < response.data[i]["weeks"][j].days.length; l++) {
                    response.data[i]["weeks"][j].days.splice(0, 1);
                    startCount = startCount + 1;
                    if (startDiffDays == startCount)
                      break;
                  }
                  if (startDiffDays == startCount) {
                    var dateArray;
                    if (response.data[i]["weeks"][j]["days"].length != 0) {
                      dateArray = response.data[i]["weeks"][j]["days"][0]["date"].split("/");
                    } else {
                      dateArray = response.data[i]["weeks"][j + 1]["days"][0]["date"].split("/");
                    }
                    response.data[i]["startDate"] = new Date(dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0]);
                    break;
                  }
                }

                for (let j = response.data[i]["weeks"].length - 1; j >= 0; j--) {
                  for (let l = response.data[i]["weeks"][j].days.length - 1; l >= 0; l--) {
                    response.data[i]["weeks"][j].days.splice(response.data[i]["weeks"][j].days.length - 1, 1);
                    endCount = endCount + 1;
                    if (endDiffDays == endCount)
                      break;
                  }
                  if (endDiffDays == endCount) {
                    var dateArray;
                    if (response.data[i]["weeks"][j]["days"].length != 0) {
                      dateArray = response.data[i]["weeks"][j]["days"][response.data[i]["weeks"][j]["days"].length - 1]["date"].split("/");
                    } else {
                      dateArray = response.data[i]["weeks"][j - 1]["days"][response.data[i]["weeks"][j - 1]["days"].length - 1]["date"].split("/");
                    }
                    response.data[i]["endDate"] = new Date(dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0]);
                    break;
                  }
                }

              }
              if (new Date(response.data[i]["startDate"]) == new Date(current_program["startDate"])) {
                if (new Date(response.data[i]["creationDate"]) > new Date(current_program["creationDate"])) {
                  var index = response.data.findIndex(p => p["calendar_id"] == current_program["calendar_id"]);
                  removeIndexes.push(JSON.parse(JSON.stringify(index)));
                }
              }
            } else {
              if (new Date(response.data[i]["startDate"]) >= new Date(current_program["startDate"]) && new Date(response.data[i]["startDate"]) <= new Date(current_program["endDate"])) {
                var start = moment(this.datepipe.transform(new Date(current_program["endDate"]), "yyyy-MM-dd"), "YYYY-MM-DD");
                var end = moment(this.datepipe.transform(new Date(response.data[i]["startDate"]), "yyyy-MM-dd"), "YYYY-MM-DD");
                diffDays = moment.duration(start.diff(end)).asDays();
              }
              if (new Date(response.data[i]["endDate"]) >= new Date(current_program["startDate"]) && new Date(response.data[i]["endDate"]) <= new Date(current_program["endDate"])) {
                var start = moment(this.datepipe.transform(new Date(response.data[i]["endDate"]), "yyyy-MM-dd"), "YYYY-MM-DD");
                var end = moment(this.datepipe.transform(new Date(current_program["startDate"]), "yyyy-MM-dd"), "YYYY-MM-DD");
                diffDays = moment.duration(start.diff(end)).asDays();
              }
              if (diffDays != 0) {
                var dayCount = 0;
                for (let j = current_program["weeks"].length - 1; j >= 0; j--) {
                  for (let l = current_program["weeks"][j].days.length - 1; l >= 0; l--) {
                    current_program["weeks"][j].days.splice(current_program["weeks"][j].days.length - 1, 1);
                    dayCount = dayCount + 1;
                    if (diffDays + 1 == dayCount)
                      break;
                  }
                  if (diffDays + 1 == dayCount) {
                    var dateArray;
                    if (current_program["weeks"][j]["days"].length != 0) {
                      dateArray = current_program["weeks"][j]["days"][current_program["weeks"][j]["days"].length - 1]["date"].split("/");
                    } else {
                      dateArray = current_program["weeks"][j - 1]["days"][current_program["weeks"][j - 1]["days"].length - 1]["date"].split("/");
                    }
                    current_program["endDate"] = new Date(dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0]);
                    break;
                  }
                }
              }
            }
          }

        }
      }
      if (removeIndexes.length != 0) {
        var unique = removeIndexes.filter(function (elem, index, self) {
          return index === self.indexOf(elem);
        });
        for (let n = 0; n < unique.length; n++) {
          response.data.splice(n, 1);
        }
      }
      this.final_programs = response.data;
      this.loaderHide = false;
      this.showPreview = true;

    } else {
      this.loaderHide = false;
      this.showPreview = false;
      if (this.workoutPreview) {
        this.toastr.error('', 'No workout history found for selected month.', {
          timeOut: 5000,
          extendedTimeOut: 1000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true
        });
        return;
      } else {
        this.toastr.error('', 'No meal plan history found for selected month.', {
          timeOut: 5000,
          extendedTimeOut: 1000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true
        });
        return;
      }
    }
  }

}