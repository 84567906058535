import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { UserLoginService } from "../service/user-login.service";
import { LoggedInCallback, CognitoUtil } from "../service/cognito.service";
import { DBService } from '../service/db.service';
import { ToastrService } from 'ngx-toastr';
import { Integer } from 'aws-sdk/clients/athena';
import { IMyDpOptions } from 'mydatepicker';
import moment from 'moment';
import { DatePipe } from '@angular/common';
import { YogaDBService } from '../service/YogaDBService';

export class program {
  trainerId: string;
  programId: string;
  programName: string;
  plans: Integer;
  weeks: Integer;
  programDuration: Integer;
  priceInRupees: number;
  currency: Object[];
  description: string;
  programImage: Object;
  status: string;
  adminStatus: string;
  startDate: string;
  packageType: Object;
  enrollmentDate: string;
  nutrition: Object;
  ProgramAddons: Object[];
  priceInDollars: number;
  endDate: string;
  enrollmentEndDate: string;
  programType: Object;
  createdDate: Date;
  lastUpdateOn: Date;
  trainingDays: Object[];
  numberOfSlots: number;
  slots: Object[];
  isContentProgram: boolean;
  isOnlineProgram: boolean;
  numberOfPartcipants: number;
  callDuration: Object;
  externalCallLink: string;
}
@Component({
  selector: 'app-program-yoga',
  templateUrl: './program-yoga.component.html',
  styleUrls: ['./program-yoga.component.css']
})
export class ProgramYogaComponent implements OnInit, OnDestroy, LoggedInCallback {

  currentCategory: string;
  currentGender: string;
  program: program;
  myprograms: any[];
  myprogramsLength: number = 0;
  menuShow: string;
  searchProgram: string;
  // Google Var
  googleUserEmail: string;
  private loggedIn: boolean;
  private subcrptn_1: any;
  currentLogged: string;
  currentEmail: string;
  showDeletePopup: boolean = false;
  showRepublishPopup: boolean = false;
  isBusinessDetails: boolean = false;
  isNewProfile: boolean = false;
  isRejectedProfile; boolean = false;
  showAdminComments: boolean = false;
  showAsanarejectPopUp: boolean = false;
  adminComments: Object[];
  isBusinessDetailsSubmitted: boolean = false;
  currentProgramId: string;
  profileStatus: string;
  pagenumber: number = 0;
  pagesize: number = 17;
  notSroclly: boolean = true;
  notEmptyPost: boolean = true;
  timezone: string;
  duplicate_slots: Object[];
  showSlotsPopup: boolean = false;

  public monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  status: string;

  constructor(private renderer: Renderer2, private db_service: DBService, public router: Router,
    public route: ActivatedRoute, public userService: UserLoginService, private yoga_db_service: YogaDBService, private toastr: ToastrService, public cognitoUtil: CognitoUtil, public datepipe: DatePipe) {
    this.userService.isAuthenticated(this);
  }

  ngOnInit() {
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.program = new program();
    this.currentCategory = localStorage.getItem("ProfileCategory")
    this.currentGender = localStorage.getItem("ProfileGender");
    if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-build-male');
    }
    else if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-build-female');
    }
    else if (this.currentCategory == 'Yoga' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-yoga-male');
    }
    else if (this.currentCategory == 'Yoga' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-yoga-female');
    }
    else if (this.currentCategory == 'Zumba' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-zumba-male');
    }
    else if (this.currentCategory == 'Zumba' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-zumba-female');
    }

    this.userService.currentUserID.subscribe((val) => {
      if (val != '') {
        this.program.trainerId = val;
      }
    });
    if (this.program.trainerId != undefined && this.program.trainerId != "") {
      this.getMyProgramDataFromServer();
    }
  }
  getMyProgramDataFromServer() {
    var index = '/program/all/';
    var queryparams = {
      "pagesize": this.pagesize,
      "pagenumber": 0,
      "pagination": true
    }
    this.yoga_db_service.getQueriedData(this.program.trainerId, index, queryparams).subscribe(
      response => {
        //  console.log('Data Retrived succesfully.', response);
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i]["nutrition"] != undefined && response.data[i]["nutrition"] != null) {
            if (response.data[i]["nutrition"]["name"] == "Yes") {
              response.data[i]["nutritionvalue"] = true;
            } else {
              response.data[i]["nutritionvalue"] = false;
            }
          } else {
            response.data[i]["nutritionvalue"] = false;
          }
        }

        this.myprograms = response.data;
        this.myprogramsLength = this.myprograms.length;
        // this.checkExistingSlots();
      },
      error => {
        // alert("An error has occurred while saving data.");
        this.toastr.error('', 'An error has occurred getting my program data.', {
          timeOut: 5000,
          extendedTimeOut: 1000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true
        });
      }
    )
  }
  onScroll() {
    console.log("scrolled..");
    if (this.notEmptyPost && this.notSroclly) {
      this.notSroclly = false;
      this.pagenumber = this.pagenumber + 1;
      this.loadNext();
    }
  }
  loadNext() {
    var index = '/program/all/';
    var queryparams = {
      "pagesize": this.pagesize,
      "pagenumber": this.pagenumber,
      "pagination": true
    }
    this.yoga_db_service.getQueriedData(this.program.trainerId, index, queryparams).subscribe(
      response => {
        if (response.data != null) {
          if (this.myprograms.length === 0) {
            this.notEmptyPost = false;
          }
          this.myprograms = this.myprograms.concat(response.data);
          this.notSroclly = true;
        }
      },
      error => {
        console.log('There is an error while retriving workouts.');
      }
    )
  }
  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'bg-img-build-male');
    this.renderer.removeClass(document.body, 'bg-img-yoga-male');
    this.renderer.removeClass(document.body, 'bg-img-zumba-male');
    this.renderer.removeClass(document.body, 'bg-img-build-female');
    this.renderer.removeClass(document.body, 'bg-img-yoga-female');
    this.renderer.removeClass(document.body, 'bg-img-zumba-female');
    this.renderer.addClass(document.body, 'body-main');
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/login']);
    } else {
      // if()
      if (this.loggedIn == true) {
        this.currentEmail = localStorage.getItem('lastAuthUser');
        // if(this.currentEmail != undefined)
        this.getProfileDataFromServer();
      }
      else {
        // this.currentEmail = localStorage.getItem('CognitoIdentityServiceProvider.2io8v4r253hugt5ndbjv9shkfp.LastAuthUser');
        this.currentEmail = this.cognitoUtil.getCurrentUser().getUsername();
        if (this.currentEmail == undefined || this.currentEmail == '' || this.currentEmail == null || this.currentEmail.includes("Google") || this.currentEmail.includes("google")) {
          this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
          if (this.currentEmail == undefined)
            this.currentEmail = this.cognitoUtil.session.idToken.payload.email;
        }
        // this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
        this.getProfileDataFromServer();
      }
    }
  }
  saveProgram(status, id) {
    this.program = this.myprograms.find(x => x["programId"] == id);
    if (this.program.adminStatus == "Rejected")
      this.program.adminStatus = "Under Review";

    if (status == "Registered")
      this.program.adminStatus = "New";

    if (status == "Under Review")
      this.program.adminStatus = "Under Review";

    if (status == "New" && this.program.adminStatus == "Approved" && this.program.status == "Approved")
      this.program.adminStatus = "New";

      if(this.program.status == 'Approved'){
        this.status = " you want to unpublish";
      }else{
        this.status = "";
      }

    this.program.status = status;
    var index = '/program';
    this.yoga_db_service.updateDatainDB(this.program, index).subscribe(
      response => {
        let emailModel = {};
        let notificationModel = {}
        let Email = false;
        this.showRepublishPopup = false;
        emailModel['trainerId'] = this.program.trainerId;
        emailModel['programId'] = this.program.programId;
        if (this.program.status == "New" && this.program.adminStatus == "New") {
          emailModel['templateCode'] = "1o1_TPGU_017";

          Email = true

        } if (this.program.status == "Under Review" && this.program.adminStatus == "Under Review") {
          emailModel['templateCode'] = "1o1_TPGRP_030";
          notificationModel['templateCode'] = "1o1_N_TPGPRA_031";
          Email = true;
        }

        if (this.program.status == "Registered") {
          notificationModel['templateCode'] = "1o1_N_TPGPTA_016";
          emailModel['templateCode'] = "1o1_TPGP_015"
          Email = true;
        }

        if (Email == true) {
          notificationModel['userId'] = "Admin",
            notificationModel['trainerId'] = this.program.trainerId;
          notificationModel['programId'] = this.program.programId;
          notificationModel['notificationType'] = "Post";
          this.db_service.savenotification(notificationModel).subscribe(response => {

          })
          this.db_service.sendEmail(emailModel).subscribe(response => {

          })
        }

        this.toastr.success('', 'Your request is submitted successfully.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      },
      error => {
        // alert('there is an error while saving data');
        this.toastr.error('', 'There is an error while submitting your request.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      }
    )

  }

  saveSlots(slots) {
    // var slots = [];
    // var daysArray = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    // for (var i = 0; i <  this.program.trainingDays.length; i++) {
    //   var d = new Date(this.program.startDate);
    //   d.setDate(d.getDate() + ((daysArray.findIndex(x => x == this.program.trainingDays[i]) + 1) + 7 - d.getDay()) % 7);
    //   while (d <= new Date(this.program.endDate)) {
    //     for (let j = 0; j < this.program.slots.length; j++) {

    //       var slot = {};
    //       d.setHours(parseInt(this.program.slots[j]["startTime"][0]["name"].split(":")[0]));
    //       d.setMinutes(parseInt(this.program.slots[j]["startTime"][0]["name"].split(":")[1]));
    //       d.setSeconds(0);
    //       slot["date"] = this.datepipe.transform(d, 'yyyy-MM-dd');
    //       d.setMinutes(d.getMinutes() + 60);

    //       slot["startTime"] = this.program.slots[j]["startTime"][0]["name"];

    //       slot["title"] = this.program.slots[j]["startTime"][0]["name"] + "-" +this.program.slots[j]["endTime"][0]["name"];
    //       slot["endTime"] = this.program.slots[j]["endTime"][0]["name"];

    //       slot["timezone"] = this.timezone;
    //       var m = this.monthNames[d.getMonth()];
    //       var yyyy = d.getFullYear();
    //       var Month = m + "/" + yyyy;
    //       slot["month"] = Month;
    //       slot["trainerId"] = this.program.trainerId;
    //       slot["callType"] = "group";
    //       slot["programId"] = this.program.programId;
    //       slot["programName"] = this.program.programName;
    //       slot["status"] = "open";
    //       slot["participants"] = [];
    //       slots.push(JSON.parse(JSON.stringify(slot)));
    //     }
    //     d.setDate(d.getDate() + 7)
    //   }
    // }
    var data = {};
    data["slots"] = slots;
    var index = '/scheduler'

    this.db_service.saveDataToDB(data, index).subscribe(
      response => {

        this.toastr.success('', 'Slot details saved successfully', {
          timeOut: 5000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });

      },
      error => {
        this.toastr.error('', 'There is an error while saving slot details.', {
          timeOut: 5000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      });
  }

  checkCalendarData(status, id) {
    this.program = this.myprograms.find(x => x["programId"] == id);

    var now = moment(new Date()); //todays date
    var end = moment(this.datepipe.transform(new Date(this.program.startDate), 'yyyy-MM-dd')); // another date
    var duration = moment.duration(end.diff(now));
    var hours = duration.asHours();

    if (hours < 24) {

      this.toastr.error('', 'You can publish the program 24 hours before.', {
        timeOut: 5000,
        extendedTimeOut: 1000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true
      });
      return;

    }

    if (!this.isBusinessDetailsSubmitted) {
      this.isBusinessDetails = true;
      return;
    }
    if (this.profileStatus == "New" || this.profileStatus == "Onhold" || this.profileStatus == "Under Review") {
      this.isNewProfile = true;
      return;
    }
    if (this.profileStatus == "Rejected") {
      this.isRejectedProfile = true;
      return;
    }

    this.program = this.myprograms.find(x => x["programId"] == id);

    if (this.program.isContentProgram) {
      var index = '/calendar/';
      this.yoga_db_service.getDataFromServer(this.program.programId, index).subscribe(
        response => {
          if (response.data != undefined && response.data != "") {
            var dayCount = 0;
            var rejectCheck = 0;
            for (let i = 0; i < response.data.weeks.length; i++) {
              dayCount = dayCount + response.data.weeks[i]["days"].length;
              for (let j = 0; j < response.data.weeks[i]["days"].length; j++) {
                var asanas = response.data.weeks[i]["days"][j]["asanas"];
                if (asanas != undefined && asanas != null) {
                  for (let k = 0; k < asanas.length; k++) {
                    if (asanas[k]["adminStatus"] == "Rejected") {
                      this.showAsanarejectPopUp = true;
                      return;
                    }
                  }

                }
              }

            }
            if (this.program.programDuration * 7 != dayCount) {
              this.toastr.error('', 'You can not publish until all calendar days filled.', {
                timeOut: 5000,
                extendedTimeOut: 1000,
                positionClass: 'toast-top-right',
                progressBar: true,
                progressAnimation: 'increasing',
                tapToDismiss: true
              });
              return;
            }
            if (this.program.isOnlineProgram) {
              this.checkExistingSlots(status, id);
              // this.saveSlots();
            }else{
            this.saveProgram(status, id);
            }

          } else {
            this.toastr.error('', 'You can not publish until all calendar days filled.', {
              timeOut: 5000,
              extendedTimeOut: 1000,
              positionClass: 'toast-top-right',
              progressBar: true,
              progressAnimation: 'increasing',
              tapToDismiss: true
            });
          }

        },
        error => {
          // alert("An error has occurred while saving data.");
          this.toastr.error('', 'An error has occurred getting program data.', {
            timeOut: 5000,
            extendedTimeOut: 1000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true
          });
        }

      )
    } else {
      // this.saveProgram(status, id);
      this.checkExistingSlots(status, id);
      // this.saveSlots();
    }
  }

  onRepublish(id) {
    this.program = this.myprograms.find(x => x["programId"] == id);
    if (this.program.adminStatus == "New" && this.program.status == "New") {
      this.showRepublishPopup = true;
      return;
    }
  }
  deleteProgram(programId) {
    this.currentProgramId = programId;
    this.showDeletePopup = true;
  }
  onContinue() {
    this.router.navigate(['businessdetails', this.program.trainerId]);
  }
  onConfirmDelete() {
    this.program = this.myprograms.find(x => x["programId"] == this.currentProgramId);
    if (this.program.status == "New") {
      var index = '/program/';
      this.yoga_db_service.deleteRecordInDB(this.currentProgramId, index).subscribe(
        response => {
          this.getMyProgramDataFromServer();
          this.showDeletePopup = false;
          this.toastr.success('', 'Program deleted successfully.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        },
        error => {
          this.toastr.error('', 'There is an error while deleting program.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        }
      )
    } else {
      this.toastr.error('', 'You cannot delete the program .', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
  }
  getProfileDataFromServer() {
    var index = '/profile/';
    this.yoga_db_service.getDataFromServer(this.currentEmail, index).subscribe(
      response => {
        // console.log('Data Retrived succesfully.', response);
        response = response.data[0];
        this.program.trainerId = response.trainerId;
        this.isBusinessDetailsSubmitted = response.isBusinessDetailsSubmitted;
        this.profileStatus = response.adminStatus;
        this.getMyProgramDataFromServer();
      },
      error => {
        console.log("An error has occurred while retriving profile data.");
      }
    )
  }
  getAdminComments(id) {
    var program = this.myprograms.find(x => x["programId"] == id);
    this.adminComments = program["approvalComments"];
    this.showAdminComments = true;
  }
  onUpdateProfile() {
    this.router.navigate(['mydata', this.program.trainerId]);
  }
  Onmenu() {
    this.menuShow = "show";
  }

  closePopup() {
    this.showDeletePopup = false;
    this.isBusinessDetails = false;
    this.isRejectedProfile = false;
    this.isNewProfile = false;
    this.showAdminComments = false;
    this.showAsanarejectPopUp = false;
    this.showRepublishPopup = false;
    this.showSlotsPopup = false;
  }

  checkExistingSlots(status, id) {
    var index = '/scheduler/slots/live';
    var queryparams = {
      "trainerId": this.program.trainerId,
      "timezone": this.timezone,
      "programId": this.program.programId
    }
    this.db_service.getQueriedData("", index, queryparams).subscribe(
      response => {

        var db_slots = response.data;
        var duplicate_count = 0;
        var slots = [];
        this.duplicate_slots = [];
        var daysArray = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
        for (var i = 0; i < this.program.trainingDays.length; i++) {
          var d = new Date(this.program.startDate);
          d.setDate(d.getDate() + ((daysArray.findIndex(x => x == this.program.trainingDays[i]) + 1) + 7 - d.getDay()) % 7);
          while (d <= new Date(this.program.endDate)) {
            var slot = {};
            var matched_objects = [];
            slot["date"] = this.datepipe.transform(d, 'yyyy-MM-dd');

            for (let k = 0; k < db_slots.length; k++) {
              var check_d = this.datepipe.transform(new Date(db_slots[k]["date"]), 'yyyy-MM-dd');
              if (check_d == slot["date"]) {
                matched_objects.push(JSON.parse(JSON.stringify(db_slots[k])));
              }

            }
            for (let j = 0; j < this.program.slots.length; j++) {


              d.setHours(parseInt(this.program.slots[j]["startTime"][0]["name"].split(":")[0]));
              d.setMinutes(parseInt(this.program.slots[j]["startTime"][0]["name"].split(":")[1]));
              d.setSeconds(0);
              d.setMinutes(d.getMinutes() + 60);

              if (matched_objects.length > 0) {
                for (let l = 0; l < matched_objects.length; l++) {
                  var testStartTime = moment(matched_objects[l]["startTime"], "HH:mm:ss a");
                  var testEndTime = moment(matched_objects[l]["endTime"], "HH:mm:ss a");

                  var selectedStartTime = moment(this.program.slots[j]["startTime"][0]["name"], "HH:mm:ss a");
                  var selectedEndTime = moment(this.program.slots[j]["endTime"][0]["name"], "HH:mm:ss a");

                  if ((selectedStartTime >= testStartTime && selectedStartTime <= testEndTime) || (selectedEndTime >= testStartTime && selectedEndTime <= testEndTime)) {
                    duplicate_count = 1;
                    var matchedObj = this.duplicate_slots.find(x => x["slotId"] == matched_objects[l]["slotId"]);
                    if (matchedObj == undefined)
                      this.duplicate_slots.push(JSON.parse(JSON.stringify(matched_objects[l])));
                  }
                }
              }
              if (duplicate_count == 0) {
                slot["startTime"] = this.program.slots[j]["startTime"][0]["name"];

                slot["title"] = this.program.slots[j]["startTime"][0]["name"] + "-" + this.program.slots[j]["endTime"][0]["name"];
                slot["endTime"] = this.program.slots[j]["endTime"][0]["name"];

                slot["timezone"] = this.timezone;
                var m = this.monthNames[d.getMonth()];
                var yyyy = d.getFullYear();
                var Month = m + "/" + yyyy;
                slot["month"] = Month;
                slot["trainerId"] = this.program.trainerId;
                slot["callType"] = "group";
                slot["programId"] = this.program.programId;
                slot["programName"] = this.program.programName;
                slot["status"] = "open";
                slot["participants"] = [];
                slot["dayName"] = this.program.trainingDays[i];
                slot["category"] = this.currentCategory;
                slot["frequency"] = this.program.callDuration;
                slot["roomName"] = "";
                slot["roomStatus"] = "new";
                slot["maxParticipants"] = this.program.numberOfPartcipants;
                slot["externalCallLink"] = this.program.externalCallLink;
                slots.push(JSON.parse(JSON.stringify(slot)));
              }
            }
            d.setDate(d.getDate() + 7)
          }
        }
        if (duplicate_count == 0) {
          this.saveProgram(status, id);
          this.saveSlots(slots);
        }else{
          this.showSlotsPopup = true;
        }
      },
      error => {
        this.toastr.error('', 'There is an error while retrieving slot details.', {
          timeOut: 5000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      });
  }

}
