import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { HttpModule } from '@angular/http';
import { AppRoutingModule } from './app-routing.module';
import { DatePipe } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { Ng5SliderModule } from 'ng5-slider';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
// import { NgDragDropModule } from 'ng-drag-drop';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { SortablejsModule } from 'ngx-sortablejs';
import { EmbedVideo } from 'ngx-embed-video';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AmazingTimePickerModule } from 'amazing-time-picker';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MyDatePickerModule } from 'mydatepicker';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {NgStepperModule} from 'angular-ng-stepper';
import { DndModule } from 'ngx-drag-drop';


import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ChartsModule } from 'ng2-charts';
// import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { NgxPaginationModule } from 'ngx-pagination'; // <-- import the module
// import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PopoverModule } from 'ngx-bootstrap/popover';

// services
import { CognitoUtil } from "./service/cognito.service";
import { UserLoginService } from "./service/user-login.service";
import { UserRegistrationService } from "./service/user-registration.service";
import { AppGlobals } from './app.globals';
import { DBService } from './service/db.service';
import { AWSService, AwsUtil } from './service/aws.service';
import { UserParametersService } from './service/user-parameters.service';
import { NutritionService } from './service/nutritionservice';
import { JwtHttpInterceptor } from './service/jwt-interceptot.service'
import { DataService } from './service/data.service';
import { CognitoTraineeUtil } from "./service/cognitotrainee.service";
import { TraineeLoginService } from "./service/trainee-login.service";
import { NutritionixService } from "./service/nutrionixservice";
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
// import { GoogleAwsService } from './service/aws-google.service';?

// / PIPES
import { RoundPipe } from './number.pipe';
//import { DateFormatPipe } from './DateFormatPipe';
import { WeightConverterPipe } from './metrics/weight-converter.pipe';
import { HeightConverterPipe } from './metrics/height-converter.pipe';
import { distanceConverterPipe } from './metrics/distance-converter.pipe';
import { ChunkPipe } from './metrics/chunk-calendar.pipe';
// components
import { AppComponent } from './app.component';
import { ProfilrComponent } from './profilr/profilr.component';
import { ContentComponent } from './content/content.component';
import { LoginComponent } from './login/login.component';
import { GenderComponent } from './gender/gender.component';
import { HeaderComponent } from './header/header.component';
import { RegisterComponent } from './register/register.component';
// import { ConfirmComponent } from './confirm/confirm.component';
import { ExerciseComponent } from './exercise/exercise.component';
// import { CategoryComponent } from './category/category.component';
import { MyprofileComponent } from './myprofile/myprofile.component';
import { FooterComponent } from './footer/footer.component';
import { LandingComponent } from './landing/landing.component';
import { AddexerciseComponent } from './addexercise/addexercise.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { WorkoutComponent } from './workout/workout.component';
import { AddworkoutComponent } from './addworkout/addworkout.component';
import { ForgetpassComponent } from './forgetpass/forgetpass.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { ViewworkoutComponent } from './viewworkout/viewworkout.component';
import { ResendComponent } from './resend/resend.component';
import { ProgramComponent } from './program/program.component';
import { AddprogramComponent } from './addprogram/addprogram.component';
import { CalendarComponent } from './calendar/calendar.component';
import { TraineeComponent } from './trainee/trainee.component';
import { OnlyPositiveNumbersDirective } from './only-positive-numbers.directive';

import { TraineeprofileComponent } from './traineeprofile/traineeprofile.component';
import { TraineeMealplanComponent } from './trainee-mealplan/trainee-mealplan.component';
import { MealplanCalendarComponent } from './trainee-mealplan/mealplan-calendar/mealplan-calendar.component';
import { MealplanGenerationComponent } from './trainee-mealplan/mealplan-generation/mealplan-generation.component';
import { TraineeLoginComponent } from './trainee-video/trainee-login/trainee-login.component';
import { TraineeBookslotComponent } from './trainee-video/trainee-bookslot/trainee-bookslot.component';
import { TwilliVideoComponent } from './twilli-video/twilli-video.component';
import { CalendarSampleComponent } from './calendar-sample/calendar-sample.component';
import { CallSchedulerComponent } from './call-scheduler/call-scheduler.component';
import { TwilioGroupCallComponent } from './twilio-group-call/twilio-group-call.component';
import { TraineeGalleryComponent } from './trainee-gallery/trainee-gallery.component';
import { BussinessDetailsComponent } from './bussiness-details/bussiness-details.component';
import { TraineeDetailsComponent } from './trainee-details/trainee-details.component';
import { ReportsComponent } from './reports/reports.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NutritionComponent } from './nutrition/nutrition.component';
import { AddNutritionComponent } from './add-nutrition/add-nutrition.component';
import { TraineeReportsComponent } from './trainee-reports/trainee-reports.component';
import { YogaDBService } from './service/YogaDBService';
import { AsanasComponent } from './asanas/asanas.component';
import { AddAsanasComponent } from './add-asanas/add-asanas.component';
import { AddprogramYogaComponent } from './addprogram-yoga/addprogram-yoga.component';
import { CalendarYogaComponent } from './calendar-yoga/calendar-yoga.component';
import { ProgramYogaComponent } from './program-yoga/program-yoga.component';
import { YogaMealplanCalendarComponent } from './trainee-mealplan/yoga-mealplan-calendar/yoga-mealplan-calendar.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ZoomViewComponent } from './zoom-view/zoom-view.component';
import { SampleComponent } from './sample/sample.component';

// import { GoogleSigninComponent } from './login/google-signin/google-signin.component';


// let config = new AuthServiceConfig([
//   {
//     id: GoogleLoginProvider.PROVIDER_ID,
//     // provider: new GoogleLoginProvider("761712048016-km29ge3sglprlnh485ji4a3218drm2rr.apps.googleusercontent.com") // Local APP ID
//     provider: new GoogleLoginProvider("761712048016-cbsq7mvq4oggag0ra3uh4p9f3eui9l7i.apps.googleusercontents.com")   // server APP ID

//   }
// ]);

// export function provideConfig() {
//   return config;
// }

@NgModule({

  declarations: [
    // imports: [ModalModule.forRoot(),...],
    AppComponent,
    ProfilrComponent,
    ContentComponent,
    LoginComponent,
    GenderComponent,
    HeaderComponent,
    RegisterComponent,
    // ConfirmComponent,
    ExerciseComponent,
    // CategoryComponent,
    MyprofileComponent,
    FooterComponent,
    LandingComponent,
    AddexerciseComponent,
    DashboardComponent,
    WorkoutComponent,
    AddworkoutComponent,
    ForgetpassComponent,
    ConfirmComponent,
    ViewworkoutComponent,
    ResendComponent,
    ProgramComponent,
    AddprogramComponent,
    CalendarComponent,
    TraineeComponent,
    OnlyPositiveNumbersDirective,
    TraineeprofileComponent,
    RoundPipe,
    WeightConverterPipe,
    HeightConverterPipe,
    distanceConverterPipe,
    ChunkPipe,
    TraineeMealplanComponent,
    MealplanCalendarComponent,
    MealplanGenerationComponent,
    TraineeLoginComponent,
    TraineeBookslotComponent,
    TwilliVideoComponent,
    CalendarSampleComponent,
    CallSchedulerComponent,
    TwilioGroupCallComponent,
    TraineeGalleryComponent,
    BussinessDetailsComponent,
    TraineeDetailsComponent,
    ReportsComponent,
    NutritionComponent,
    AddNutritionComponent,
    TraineeReportsComponent,
    AsanasComponent,
    AddAsanasComponent,
    AddprogramYogaComponent,
    CalendarYogaComponent,
    ProgramYogaComponent,
    YogaMealplanCalendarComponent,
    PrivacyComponent,
    ZoomViewComponent,
    SampleComponent
    // GoogleSigninComponent
    // FieldErrorDisplayComponent,
    // MyProfileComponent
  ],
  imports: [
    ModalModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    // AngularFontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    DropzoneModule,
    NgxDropzoneModule,
    Ng5SliderModule,
    HttpClientModule,
    DndModule,
    // HttpModule/,
    NgMultiSelectDropDownModule.forRoot(),
    BrowserAnimationsModule,
    ToastrModule.forRoot(), // ToastrModule added
    // NgDragDropModule.forRoot(),
    GooglePlaceModule,
    SortablejsModule.forRoot({ animation: 200 }),
    EmbedVideo.forRoot(),
    FlexLayoutModule,
    TooltipModule,
    // BsDatepickerModule.forRoot(),
    AmazingTimePickerModule,
    PaginationModule.forRoot(),
    Ng2SearchPipeModule,
    ChartsModule,
    NgxChartsModule,
    // AutocompleteLibModule,
    UiSwitchModule,
    NgxPaginationModule,
    TooltipModule.forRoot(),
    AccordionModule.forRoot(),
    TabsModule.forRoot(),
    PopoverModule.forRoot(),
    PdfViewerModule,
    MyDatePickerModule,
    InfiniteScrollModule,
    CdkStepperModule,
    NgStepperModule
  ],
  providers: [
    CognitoUtil,
    UserRegistrationService,
    UserLoginService,
    AppGlobals,
    DBService,
    YogaDBService,
    AWSService,
    AwsUtil,
    NutritionService,
    DataService,
    UserParametersService,
    CognitoTraineeUtil,
    TraineeLoginService,
    // GoogleAwsService,
    DatePipe,
    NutritionixService,
    // {provide: LocationStrategy, useClass: HashLocationStrategy},
    { provide: HTTP_INTERCEPTORS, useClass: JwtHttpInterceptor, multi: true },
    // {provide: DROPZONE_CONFIG,useValue: DEFAULT_DROPZONE_CONFIG}
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor,multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
