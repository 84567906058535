<div id="mydiv" *ngIf="loaderHide">
    <img src="assets/images/landing_img.gif" width="10%" class="ajax-loader" />
</div>
<section>
    <div class="container-fluid">
        <div class="common-heading-top-padding">
            <div class="row mb-3">
                <div class="heading1 col-md-7 col-sm-12 mb-4">
                    <img src="assets/images/dashboard/reports.png" style="border: 2px solid #8bc53f;border-radius: 50%;"
                        width="50" />
                    <span><b>Reports</b></span>
                </div>

                <div class="col-md-5 p-1">
                    <button class="btn btn-default save-exercise-btn" style="cursor: pointer;"
                        routerLink="/tprofile/{{program_id}}/{{trainee_id}}" style="margin-top: 10px;float: right;"
                        type="button" tabindex="0">BACK</button>
                </div>

            </div>

            <div class="row mb-3">
                <div class="col col-md-2">
                    <ng-multiselect-dropdown class="form-control col-12"
                    [placeholder]="'Report type'"
                    [settings]="dropdownSettings_single"
                    [data]="repot_types"
                    [(ngModel)]="selectedReport"
                    name="reportType"
            >
                  </ng-multiselect-dropdown>

                </div>
                <div class="col col-md-2">
                    <ng-multiselect-dropdown class="form-control col-12"
                    [placeholder]="'Month'"
                    [settings]="dropdownSettings_single"
                    [data]="months"
                    [(ngModel)]="selectedMonth"
                    name="month"
                  >
                  </ng-multiselect-dropdown>
                </div>

                <div class="col col-md-auto">
                    <button class="btn btn-default save-exercise-btn" style="cursor: pointer;" type="button"
                        tabindex="0" (click)="generateReport()">PREVIEW</button>
                </div>
                <div class="img col-md-1" *ngIf="showPreview">
                    <img src="assets/images/download.png" alt="" title="Download Report Pdf"
                        style="width: 40px; cursor: pointer;" (click)="downloadPdf()">
                </div>
            </div>

        </div>
    </div>

    <!-- Sample workout html -->

    <div id="workoutReport" class="container" style="color: black; background-color: white;border-radius: 3px;"
        *ngIf="showPreview == true && workoutPreview == true">

        <div class="container-fluid my-3">
            <div class="row">
                <div class="img col-md-2">
                    <img src="assets/images/1o1logo1.png" alt="" style="width: 100px">
                </div>
                <div class="text-right col">
                    <strong>Trainer: </strong>
                    <span>{{trainerName}}</span>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="col-md-3 mx-auto"
                style="background: black; color: yellowgreen; padding: 10px;text-align: center; font-weight: 600;border-radius: 30px;font-size: 18px;">
                Workout Report</div>
        </div>
        <br>
        <div *ngFor="let program of final_programs">
            <div class="container-fluid" *ngFor="let week of program.weeks">
                <div class="container-fluid py-2" style="background: black; color: white; font-size: 14px;"
                    *ngIf="week.days.length != 0">
                    <div class="row">
                        <div class="img col text-left">
                            <strong>Program Week: </strong>
                            <span>{{week.week}}</span>
                        </div>
                        <div class="text-right col">
                            <strong>Program: </strong>
                            <span>{{program.programName}}</span>
                        </div>
                    </div>
                </div>
                <div class="container my-4" *ngIf="week.days.length != 0">
                    <table class="table table-striped">
                        <thead style="background: black; color: yellowgreen; font-weight: 700;">
                            <tr>
                                <th style="  width: 180px; text-align: center">Date</th>
                                <th style="  width: 600px; text-align: center">Workout Name</th>
                                <th style="  width: 20px; text-align: center">Notes</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let day of week.days">

                                <td style="text-align: center">{{day.date}}</td>
                                <td style="text-align: center">
                                    <div class="row" style="justify-content: center;">

                                        <div class="d-flex col-auto col text-center"
                                            *ngFor="let workout of day.workouts">
                                            <div>
                                                <input type="checkbox" style="width: 20px;height: 20px;" name="status"
                                                    id="status" [ngModel]="workout.workoutStatus == 'completed'"
                                                    [disabled]="true">
                                            </div>
                                            <!-- <input type="text" name="status" id="status" [(ngModel)]="true"> -->
                                            <div>&nbsp;{{workout.workoutName.name}}</div>
                                        </div>
                                        <div class="d-flex col-auto col text-center" *ngIf="day.rest">
                                            Rest Day
                                        </div>

                                    </div>
                                </td>
                                <td style="text-align: center">
                                    <div *ngFor="let workout of day.workouts">
                                        <span *ngFor="let comment of workout.workoutComments">
                                            {{ comment.comment}}
                                        </span>
                                    </div>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>

            <div class="container my-3">
                <div class="col-md-3 mx-auto"
                    style="background: black; color: yellowgreen; padding: 10px;text-align: center; font-weight: 600;border-radius: 30px;font-size: 18px;">
                    Workout Day Report</div>
            </div>

            <div *ngFor="let week of program.weeks">
                <div class="container-fluid" *ngFor="let day of week.days">
                    <div class="container-fluid py-2" style="background: black; color: white; font-size: 14px;">
                        <div class="row">
                            <!-- <div class="img col text-left"  >
                      <strong>Day </strong>
                     <span>{{day.day}}</span>
                 </div> -->
                            <div class="text-right col">
                                <strong>Date: </strong>
                                <span>{{day.date}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="container my-4" *ngFor="let workout of day.workouts">
                        <div class="container py-2" style="background: black; color: yellowgreen;">Workout Name:
                            {{workout.workoutName.name}} </div>
                        <table class="table table-striped">
                            <thead style=" font-weight: 700;">
                                <tr>
                                    <th style="  width: 300px; text-align: center">Exercise Name</th>
                                    <th style="  width: 100px; text-align: center">Sets</th>
                                    <th style="  width: 100px; text-align: center">Reps</th>
                                    <th style="  width: 100px; text-align: center">Weight</th>
                                    <th style="  width: 100px; text-align: center">Rest Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let exercise of workout.workoutExercises">
                                    <td style="text-align: center">{{exercise.exerciseName}}</td>
                                    <td style="text-align: center">
                                        <div class="row" *ngFor="let set of exercise.sets">
                                            <div class="text-center col">
                                                <span class="my-auto">{{set.setNo}}</span>
                                            </div>

                                        </div>
                                        <div class="row" *ngIf="exercise.exerciseName == 'Rest'">
                                            <div class="text-center col">
                                                <span class="my-auto">0</span>
                                            </div>

                                        </div>
                                    </td>
                                    <td style="text-align: center">
                                        <div class="row" *ngFor="let set of exercise.sets">
                                            <div class="text-center col">
                                                <span class="my-auto">{{set.reputationValue.completed}}</span>
                                            </div>

                                        </div>
                                        <div class="row" *ngIf="exercise.exerciseName == 'Rest'">
                                            <div class="text-center col">
                                                <span class="my-auto">0</span>
                                            </div>

                                        </div>
                                    </td>
                                    <td style="text-align: center">
                                        <div class="row" *ngFor="let set of exercise.sets">
                                            <div class="text-center col">
                                                <span class="my-auto">{{set.maxWeights.completed}}</span>
                                            </div>

                                        </div>
                                        <div class="row" *ngIf="exercise.exerciseName == 'Rest'">
                                            <div class="text-center col">
                                                <span class="my-auto">0</span>
                                            </div>

                                        </div>
                                    </td>
                                    <td style="text-align: center">
                                        <div class="row" *ngFor="let set of exercise.sets">
                                            <div class="text-center col">
                                                <span class="my-auto">{{set.restPeriod.completed}}</span>
                                            </div>

                                        </div>
                                        <div class="row" *ngIf="exercise.exerciseName == 'Rest'">
                                            <div class="text-center col">
                                                <span class="my-auto">{{exercise.restPeriodVal}}</span>
                                            </div>

                                        </div>
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="container my-4" *ngIf="day.rest">
                        <div class="col-md-3 mx-auto"
                            style="background: yellowgreen; color: black; padding: 10px;text-align: center; font-weight: 600;border-radius: 30px;font-size: 18px;">
                            Rest Day</div>
                    </div>
                    <br>
                    <br>

                </div>

            </div>

        </div>

        <br>







        <!-- <div class="container-fluid py-2" style=" position: fixed;  left: 0; bottom: 0; width: 100%;">
            <div class="row">
               <div class="img col text-left"  >
                    <strong>www.1o1fitness.com </strong>
               </div>
               <div class="text-right col"> 
                   <strong>Page:<span>1</span> </strong>
               </div>          
            </div>
    </div>  -->





    </div>
    <!-- overflow-y: scroll; max-height: 500px; -->
    <!--  Meal plan report -->
    <div id="mealplanReport" class="container" style="color: black; background-color: white;border-radius: 3px; "
        *ngIf="showPreview == true && workoutPreview == false && asanaPreview == false">
        <div class="container-fluid my-3">
            <div class="row">
                <div class="img col-md-2">
                    <img src="assets/images/1o1logo1.png" alt="" style="width: 100px">
                </div>
                <div class="text-right col">
                    <strong>Trainer: </strong>
                    <span>{{trainerName}}</span>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="col-md-3 mx-auto"
                style="background: black; color: yellowgreen; padding: 10px;text-align: center; font-weight: 600;border-radius: 30px;font-size: 18px;">
                Meal-Plan Report</div>
        </div>
        <br>
        <div *ngFor="let program of final_programs">
            <div class="container-fluid" *ngFor="let week of program.weeks">
                <div class="container-fluid py-2" style="background: black; color: white; font-size: 14px;"
                    *ngIf="week.days.length != 0">
                    <div class="row">
                        <div class="img col text-left">
                            <strong>Program Week: </strong>
                            <span>{{week.week}}</span>
                        </div>
                        <div class="text-right col">
                            <strong>Program: </strong>
                            <span>{{program.programName}}</span>
                        </div>
                    </div>
                </div>
                <div class="container my-4" *ngIf="week.days.length != 0">
                    <table class="table table-striped">
                        <thead style="background: black; color: yellowgreen; font-weight: 700;">
                            <tr>
                                <th style="  width: 180px; text-align: center">Date</th>
                                <th style="  width: 180px; text-align: center">Breakfast <div>(kcal)</div>
                                </th>
                                <th style="  width: 180px; text-align: center">Lunch <div>(kcal)</div>
                                </th>
                                <th style="  width: 180px; text-align: center">Snacks <div>(kcal)</div>
                                </th>
                                <th style="  width: 180px; text-align: center">Dinner <div>(kcal)</div>
                                </th>
                                <th style="  width: 180px; text-align: center">Total <div>(kcal)</div>
                                </th>
                                <th style="  width: 180px; text-align: center">Water</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let day of week.days">
                                <td style="text-align: center">{{day.date}}</td>
                                <td style="text-align: center">
                                    <div class="row">
                                        <div class="text-center col">
                                            <span
                                                class="my-auto">{{day.mealplan.breakfast.overall_calories_consumed}}</span>
                                        </div>

                                    </div>
                                </td>
                                <td style="text-align: center">
                                    <div class="row">
                                        <div class="text-center col">
                                            <span
                                                class="my-auto">{{day.mealplan.lunch.overall_calories_consumed}}</span>
                                        </div>

                                    </div>
                                </td>
                                <td style="text-align: center">
                                    <div class="row">
                                        <div class="text-center col">
                                            <span
                                                class="my-auto">{{day.mealplan.snacks.overall_calories_consumed}}</span>
                                        </div>

                                    </div>
                                </td>
                                <td style="text-align: center">
                                    <div class="row">
                                        <div class="text-center col">
                                            <span
                                                class="my-auto">{{day.mealplan.dinner.overall_calories_consumed}}</span>
                                        </div>

                                    </div>
                                </td>
                                <td style="text-align: center">
                                    <div class="row">
                                        <div class="text-center col">
                                            <span class="my-auto">{{ day.mealplan.overall_calories_consumed }}</span>
                                        </div>
                                    </div>
                                </td>

                                <td style="text-align: center">
                                    <div
                                        *ngIf="day.mealplan.waterConsumed != undefined && (day.mealplan.waterConsumed | json) != ({} | json)">
                                        {{day.mealplan.waterConsumed.consumed}}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>

            <div class="container">
                <div class="col-md-3 mx-auto"
                    style="background: black; color: yellowgreen; padding: 10px;text-align: center; font-weight: 600;border-radius: 30px;font-size: 18px;">
                    Mealplan Day Report</div>
            </div>
            <br>
            <div *ngFor="let week of program.weeks">
                <div class="container-fluid" *ngFor="let day of week.days">
                    <div class="container-fluid py-2" style="background: black; color: white; font-size: 14px;">
                        <div class="row">
                            <!-- <div class="img col text-left"  >
                  <strong>Day </strong>
                 <span>{{day.day}}</span>
             </div> -->
                            <div class="text-right col">
                                <strong>Date: </strong>
                                <span>{{day.date}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="container mt-3">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="col-md-10 mx-auto">
                                    <div class="row">
                                        <div class="col-md-6 text-center p-2"
                                            style="background:black; color: yellowgreen; font-size: 16px; font-weight: 600; border-bottom-left-radius: 20px;border-top-left-radius: 20px">
                                            Protein</div>
                                        <div class="col-md-6 text-center p-2"
                                            style="background:yellowgreen; color: black; font-weight: 600; border-bottom-right-radius: 20px;border-top-right-radius: 20px">
                                            {{day.mealplan.overall_protein_consumed}}g</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="col-md-10 mx-auto">
                                    <div class="row">
                                        <div class="col-md-6 text-center p-2"
                                            style="background:black; color: yellowgreen; font-size: 16px; font-weight: 600; border-bottom-left-radius: 20px;border-top-left-radius: 20px">
                                            Carbs</div>
                                        <div class="col-md-6 text-center p-2"
                                            style="background:yellowgreen; color: black; font-weight: 600; border-bottom-right-radius: 20px;border-top-right-radius: 20px">
                                            {{day.mealplan.overall_fibre_consumed}}g</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="col-md-10 mx-auto">
                                    <div class="row">
                                        <div class="col-md-6 text-center p-2"
                                            style="background:black; color: yellowgreen; font-size: 16px; font-weight: 600; border-bottom-left-radius: 20px;border-top-left-radius: 20px">
                                            Fat</div>
                                        <div class="col-md-6 text-center p-2"
                                            style="background:yellowgreen; color: black; font-weight: 600; border-bottom-right-radius: 20px;border-top-right-radius: 20px">
                                            {{day.mealplan.overall_fat_consumed}}g</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="container my-4">
                        <div class="container py-2" style="background: black; color: yellowgreen;">
                            <div class="row">
                                <div class="img col text-left">
                                    <strong>Breakfast </strong>

                                </div>
                                <div class="text-right col">
                                    <strong>Calories: </strong>
                                    <span>{{day.mealplan.breakfast.overall_calories_consumed}}</span>
                                </div>
                            </div>
                        </div>
                        <table class="table table-striped">
                            <thead style=" font-weight: 700;">
                                <tr>
                                    <th style="  width: 400px; text-align: center">Food Name</th>
                                    <th style="  width: 100px; text-align: center">Qty</th>
                                    <th style="  width: 100px; text-align: center">Calories</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let food of day.mealplan.breakfast.foodItems">
                                    <td style="text-align: left">{{food.food_name}}</td>
                                    <td style="text-align: center">
                                        <div class="row">
                                            <div class="text-center col">
                                                <span class="my-auto">{{food.serving_qty.consumed}}</span>
                                            </div>

                                        </div>
                                    </td>
                                    <td style="text-align: center">
                                        <div class="row">
                                            <div class="text-center col">
                                                <span class="my-auto">{{food.nf_calories.consumed}}</span>
                                            </div>

                                        </div>
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="container my-4">
                        <div class="container py-2" style="background: black; color: yellowgreen;">
                            <div class="row">
                                <div class="img col text-left">
                                    <strong>Lunch </strong>

                                </div>
                                <div class="text-right col">
                                    <strong>Calories: </strong>
                                    <span>{{day.mealplan.lunch.overall_calories_consumed}}</span>
                                </div>
                            </div>
                        </div>
                        <table class="table table-striped">
                            <thead style=" font-weight: 700;">
                                <tr>
                                    <th style="  width: 400px; text-align: center">Food Name</th>
                                    <th style="  width: 100px; text-align: center">Qty</th>
                                    <th style="  width: 100px; text-align: center">Calories</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let food of day.mealplan.lunch.foodItems">
                                    <td style="text-align: left">{{food.food_name}}</td>
                                    <td style="text-align: center">
                                        <div class="row">
                                            <div class="text-center col">
                                                <span class="my-auto">{{food.serving_qty.consumed}}</span>
                                            </div>

                                        </div>
                                    </td>
                                    <td style="text-align: center">
                                        <div class="row">
                                            <div class="text-center col">
                                                <span class="my-auto">{{food.nf_calories.consumed}}</span>
                                            </div>

                                        </div>
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="container my-4">
                        <div class="container py-2" style="background: black; color: yellowgreen;">
                            <div class="row">
                                <div class="img col text-left">
                                    <strong>Snacks </strong>

                                </div>
                                <div class="text-right col">
                                    <strong>Calories: </strong>
                                    <span>{{day.mealplan.snacks.overall_calories_consumed}}</span>
                                </div>
                            </div>
                        </div>
                        <table class="table table-striped">
                            <thead style=" font-weight: 700;">
                                <tr>
                                    <th style="  width: 400px; text-align: center">Food Name</th>
                                    <th style="  width: 100px; text-align: center">Qty</th>
                                    <th style="  width: 100px; text-align: center">Calories</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let food of day.mealplan.snacks.foodItems">
                                    <td style="text-align: left">{{food.food_name}}</td>
                                    <td style="text-align: center">
                                        <div class="row">
                                            <div class="text-center col">
                                                <span class="my-auto">{{food.serving_qty.consumed}}</span>
                                            </div>

                                        </div>
                                    </td>
                                    <td style="text-align: center">
                                        <div class="row">
                                            <div class="text-center col">
                                                <span class="my-auto">{{food.nf_calories.consumed}}</span>
                                            </div>

                                        </div>
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="container my-4">
                        <div class="container py-2" style="background: black; color: yellowgreen;">
                            <div class="row">
                                <div class="img col text-left">
                                    <strong>Dinner </strong>

                                </div>
                                <div class="text-right col">
                                    <strong>Calories: </strong>
                                    <span>{{day.mealplan.dinner.overall_calories_consumed}}</span>
                                </div>
                            </div>
                        </div>
                        <table class="table table-striped">
                            <thead style=" font-weight: 700;">
                                <tr>
                                    <th style="  width: 400px; text-align: center">Food Name</th>
                                    <th style="  width: 100px; text-align: center">Qty</th>
                                    <th style="  width: 100px; text-align: center">Calories</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let food of day.mealplan.dinner.foodItems">
                                    <td style="text-align: left">{{food.food_name}}</td>
                                    <td style="text-align: center">
                                        <div class="row">
                                            <div class="text-center col">
                                                <span class="my-auto">{{food.serving_qty.consumed}}</span>
                                            </div>

                                        </div>
                                    </td>
                                    <td style="text-align: center">
                                        <div class="row">
                                            <div class="text-center col">
                                                <span class="my-auto">{{food.nf_calories.consumed}}</span>
                                            </div>

                                        </div>
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>









            </div>
            <!-- <div class="container-fluid py-2" style=" position: fixed;  left: 0; bottom: 0; width: 100%;">
            <div class="row">
               <div class="img col text-left"  >
                    <strong>www.1o1fitness.com </strong>
               </div>
               <div class="text-right col"> 
                   <strong>Page:<span>1</span></strong>
               </div>          
            </div>
    </div>  -->
        </div>
    </div>

    <!-- Yoga Asana Model -->
    <div id="asanaReport" class="container" style="color: black; background-color: white;border-radius: 3px;"
        *ngIf="showPreview == true && asanaPreview == true">

        <div class="container-fluid my-3">
            <div class="row">
                <div class="img col-md-2">
                    <img src="assets/images/1o1logo1.png" alt="" style="width: 100px">
                </div>
                <div class="text-right col">
                    <strong>Trainer: </strong>
                    <span>{{trainerName}}</span>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="col-md-3 mx-auto"
                style="background: black; color: yellowgreen; padding: 10px;text-align: center; font-weight: 600;border-radius: 30px;font-size: 18px;">
                Asana Report</div>
        </div>
        <br>
        <div *ngFor="let program of final_programs">
            <div class="container-fluid" *ngFor="let week of program.weeks">
                <div class="container-fluid py-2" style="background: black; color: white; font-size: 14px;"
                    *ngIf="week.days.length != 0">
                    <div class="row">
                        <div class="img col text-left">
                            <strong>Program Week: </strong>
                            <span>{{week.week}}</span>
                        </div>
                        <div class="text-right col">
                            <strong>Program: </strong>
                            <span>{{program.programName}}</span>
                        </div>
                    </div>
                </div>
                <div class="container my-4" *ngIf="week.days.length != 0">
                    <table class="table table-striped">
                        <thead style="background: black; color: yellowgreen; font-weight: 700;">
                            <tr>
                                <th style="  width: 180px; text-align: center">Date</th>
                                <th style="  width: 600px; text-align: center">Asana Name</th>
                                <th style="  width: 20px; text-align: center">Notes</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let day of week.days">

                                <td style="text-align: center">{{day.date}}</td>
                                <td style="text-align: center">
                                    <div class="row" style="justify-content: center;">

                                        <div class="d-flex col-auto col text-center"
                                            *ngFor="let asana of day.asanas">
                                            <div>
                                                <input type="checkbox" style="width: 20px;height: 20px;" name="status"
                                                    id="status" [ngModel]="asana.asanaStatus == 'completed'"
                                                    [disabled]="true">
                                            </div>
                                            <!-- <input type="text" name="status" id="status" [(ngModel)]="true"> -->
                                            <div>&nbsp;{{asana.adoptedName}}</div>
                                        </div>
                                        <div class="d-flex col-auto col text-center" *ngIf="day.rest">
                                            Rest Day
                                        </div>

                                    </div>
                                </td>
                                <td style="text-align: center">
                                    <div *ngFor="let asana of day.asanas">
                                        <span *ngFor="let comment of asana.asanaComments">
                                            {{ comment.comment}}
                                        </span>
                                    </div>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>

            <div class="container my-3">
                <div class="col-md-3 mx-auto"
                    style="background: black; color: yellowgreen; padding: 10px;text-align: center; font-weight: 600;border-radius: 30px;font-size: 18px;">
                    Asana Day Report</div>
            </div>

            <div *ngFor="let week of program.weeks">
                <div class="container-fluid" *ngFor="let day of week.days">
                    <div class="container-fluid py-2" style="background: black; color: white; font-size: 14px;">
                        <div class="row">
                            <!-- <div class="img col text-left"  >
                      <strong>Day </strong>
                     <span>{{day.day}}</span>
                 </div> -->
                            <div class="text-right col">
                                <strong>Date: </strong>
                                <span>{{day.date}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="container my-4" *ngFor="let asana of day.asanas">
                        <div class="container py-2" style="background: black; color: yellowgreen;">Asana Name:
                            {{asana.adoptedName}} </div>
                        <table class="table table-striped">
                            <thead style=" font-weight: 700;">
                                <tr>
                                    <!-- <th style="  width: 300px; text-align: center">Asana Name</th> -->
                                    <th style="  width: 100px; text-align: center">Sets</th>
                                    <th style="  width: 100px; text-align: center">Reps</th>
                                    <th style="  width: 100px; text-align: center">Breath Value</th>
                                    <!-- <th style="  width: 100px; text-align: center">Rest Time</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <!-- <td style="text-align: center">{{exercise.exerciseName}}</td> -->
                                    <td style="text-align: center">
                                        <div class="row" *ngFor="let set of asana.sets">
                                            <div class="text-center col">
                                                <span class="my-auto">{{set.setNo}}</span>
                                            </div>

                                        </div>
                                        <div class="row" *ngIf="asana.adoptedName == 'Rest'">
                                            <div class="text-center col">
                                                <span class="my-auto">0</span>
                                            </div>

                                        </div>
                                    </td>
                                    <td style="text-align: center">
                                        <div class="row" *ngFor="let set of asana.sets">
                                            <div class="text-center col">
                                                <span class="my-auto">{{set.reputationValue.completed}}</span>
                                            </div>

                                        </div>
                                        <div class="row" *ngIf="asana.adoptedName == 'Rest'">
                                            <div class="text-center col">
                                                <span class="my-auto">0</span>
                                            </div>

                                        </div>
                                    </td>
                                    <td style="text-align: center">
                                        <div class="row" *ngFor="let set of asana.sets">
                                            <div class="text-center col">
                                                <span class="my-auto">{{set.breathValue.completed}}</span>
                                            </div>

                                        </div>
                                        <div class="row" *ngIf="asana.adoptedName == 'Rest'">
                                            <div class="text-center col">
                                                <span class="my-auto">0</span>
                                            </div>

                                        </div>
                                    </td>
                                    <!-- <td style="text-align: center">
                                        <div class="row" *ngFor="let set of asana.sets">
                                            <div class="text-center col">
                                                <span class="my-auto">{{set.restPeriod.completed}}</span>
                                            </div>

                                        </div>
                                        <div class="row" *ngIf="asana.adoptedName == 'Rest'">
                                            <div class="text-center col">
                                                <span class="my-auto">{{exercise.restPeriodVal}}</span>
                                            </div>

                                        </div>
                                    </td> -->

                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="container my-4" *ngIf="day.rest">
                        <div class="col-md-3 mx-auto"
                            style="background: yellowgreen; color: black; padding: 10px;text-align: center; font-weight: 600;border-radius: 30px;font-size: 18px;">
                            Rest Day</div>
                    </div>
                    <br>
                    <br>

                </div>

            </div>

        </div>

        <br>

    </div>



</section>