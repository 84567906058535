import { Component, OnInit, OnDestroy, Renderer2, ChangeDetectionStrategy, ViewChild, ElementRef, Output, EventEmitter, TemplateRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from "@angular/router";
import { UserLoginService } from "../../service/user-login.service";
import { LoggedInCallback, CognitoUtil } from "../../service/cognito.service";
import { ToastrService } from 'ngx-toastr';
import { AWSService } from '../../service/aws.service';
import { DBService } from '../../service/db.service';
import { DataService } from '../../service/data.service';
import { EmbedVideoService } from 'ngx-embed-video';
// import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
// import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from 'date-fns';
import { Subject } from 'rxjs';
import moment from 'moment';
import { Integer } from 'aws-sdk/clients/athena';
import {IMyDpOptions} from 'mydatepicker';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ThemeService } from 'ng2-charts';
import { DATE } from 'ngx-bootstrap/chronos/units/constants';
import { error } from 'console';
// import { error } from '@angular/compiler/src/util';
// import { FullCalendarComponent } from '@fullcalendar/angular';
// import { EventInput } from '@fullcalendar/core';
// import dayGridPlugin from '@fullcalendar/daygrid';
// import timeGrigPlugin from '@fullcalendar/timegrid';
// import interactionPlugin from '@fullcalendar/interaction'; // for dateClick

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3'
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF'
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA'
  }
};


export class IMyDateModel {
  date: IMyDate;
  jsdate: Date;
  formatted: string;
  epoc: number;
}

export class IMyDate {
  year: number;
  month: number;
  day: number;
}

export class mealplan {
  trainer_id: string;
  creationDate: Date;
  trainee_id: string;
  mealplan: Object;
  program_id: string
  date: string;
}

export class traineecalender {
  trainee_id: string;
  calendar_id: string;
  trainer_id: string;
  program_id: string;
  weeks: Object[];
  startDate: Date;
  // endDate: Date;
  idleFor: number;
  creationDate: Date;
  lastUpdatedDate: Date;
  trainer_status: string;
}

export class trainee {
  traineeId: string;
  first_name: string;
  last_name: string;
  mobile_no: string;
  gender: string;
  age: Integer;
  currrent_weight: Object;
  trainee_height: Object;
  bmi_bmr: Object;
}
// export class mealplancalendar {
//   trainer_id: string;
//   trainee_id: string;
//   weeks: Object;
//   program_id: string
// }

@Component({
  selector: 'app-mealplan-calendar',
  templateUrl: './mealplan-calendar.component.html',
  styleUrls: ['./mealplan-calendar.component.css']
})
export class MealplanCalendarComponent implements OnInit {

  monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  currentMonth: string = this.monthNames[new Date().getMonth()] + "/" + new Date().getFullYear();

  modalRef: BsModalRef;
  mealplan: mealplan;
  traineecalender: traineecalender;
  //mealplancalendar: mealplancalendar;
  currentCategory: string;
  currentGender: string;
  private sub: any;
  userID: string;
  daysdata: object[];
  mealplandata: object[];
  alltrainees: object[];
  Trainees: object[];
  MealPlans: object[];
  MasterMealPlans = [];
  SelectedTrainee: [];
  SelectedWorkouts: Object[];
  SelectedCardio: any[];
  Workouts: Object[];
  masterWorkouts: Object[];


  traineeImage: string;
  traineeDiet: string;
  iframeURL: any;


  SelectedMeal: [];
  SelectedMasterMeal: [];
  private loggedIn: boolean;
  currentLogged: string;
  currentEmail: string;
  isNutritionist: boolean = false;
  workoutCheck: boolean = false;
  switchMeal: boolean = false;
  isNutrition: boolean = false;
  trainee_id: string;
  program_id: string;
  emitDay: string = "";
  sweek: string = "";
  cardio: string = "";
  embedYoutubeUrl: string;

  dayCheck: any;
  weekCheck: any;

  // workout popup  constants
  dayWorkouts: Object[];
  workoutExercises: Object[];
  currentExercise: Object;
  dayCardio: Object;
  exercisePlayUrl: string;
  showWorkoutsPopup: boolean = false;

  dropdownSettings_single: any = {};
  @Output() dayEvent = new EventEmitter<string>();
  @ViewChild('viewworkLoad') viewworkLoad: any;
  repeats: string[] = [];
  dropdownSettings1: any = {};
  dropdownSettings: any = {};
  closeDropdownSelection = false;
  repeatDays = [
    { id: 1, name: 'M', checked: false },
    { id: 2, name: 'T', checked: false },
    { id: 3, name: 'W', checked: false },
    { id: 4, name: 'T', checked: false },
    { id: 5, name: 'F', checked: false },
    { id: 6, name: 'S', checked: false },
    { id: 7, name: 'S', checked: false }
  ];
  isWorkoutCk: boolean = false;
  isCardioCk: boolean = false;
  isRestCk: boolean = false;
  isRepeatCk: boolean = false;
  isProgressCk: boolean = false;
  isVideoAvailable: boolean = false;
  rest: boolean = false;
  trainee: any;


  isPublish: boolean = false;
  isMealPublish: boolean = false;

  myDatePickerOptions: IMyDpOptions = {};
  timezone: string;
  dateFormat:string;
  programStartDate: IMyDateModel;
  workoutClStartDate:IMyDateModel;
  mealPlanClStartDate:IMyDateModel;
  programType: string;
  program_start_date:string;
  isDateDisabled:boolean = false;
  SLA_Days_To_Publish = 4;
  slaPopUp:boolean = false;
  isTraineeActive:boolean = false;
  isNewLoad:boolean = false;

  cardiodata: object[] = [
    { id: "1", name: 'Running', imgUrl: 'https://d13m95frd7cvoq.cloudfront.net/static-content/cardio/running.png' },
    { id: "2", name: 'Cycling', imgUrl: 'https://d13m95frd7cvoq.cloudfront.net/static-content/cardio/running.png' },
    { id: "3", name: 'Walking', imgUrl: 'https://d13m95frd7cvoq.cloudfront.net/static-content/cardio/images.png' },
    { id: "4", name: 'Rower', imgUrl: 'https://d13m95frd7cvoq.cloudfront.net/static-content/cardio/rower.png' },
    { id: "5", name: 'Elliptical', imgUrl: 'https://d13m95frd7cvoq.cloudfront.net/static-content/cardio/ELLE.png' },
    { id: "6", name: 'General', imgUrl: 'https://d13m95frd7cvoq.cloudfront.net/static-content/cardio/gen.png' }
  ];


  get selectedOptions() { // right now: ['1','3']
    return this.repeatDays
      .filter(opt => opt.checked)
      .map(opt => opt.id)
  }


  constructor(private toastr: ToastrService, private renderer: Renderer2, public router: Router,
    public route: ActivatedRoute, public userService: UserLoginService, private db_service: DBService,
    public datepipe: DatePipe, private awsService: AWSService, private embedService: EmbedVideoService,
    public cognitoUtil: CognitoUtil, public dataService: DataService,private modalService: BsModalService) {
      this.userService.isAuthenticated(this);
     }

  ngOnInit() {
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // this.trainee = new trainee();
    this.currentCategory = localStorage.getItem("ProfileCategory");
    this.currentGender = localStorage.getItem("ProfileGender");
    if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-build-male');
    }
    else if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-build-female');
    }
    else if (this.currentCategory == 'Yoga' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-yoga-male');
    }
    else if (this.currentCategory == 'Yoga' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-yoga-female');
    }
    else if (this.currentCategory == 'Zumba' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-zumba-male');
    }
    else if (this.currentCategory == 'Zumba' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-zumba-female');
    }
    this.userService.currentUserID.subscribe((val) => {
      if (val != '') {
        this.userID = val;
        // this.getMasterMealplans();
      }
    });
    this.dataService.currentRoute.subscribe((value) => {
      if (value != ''&& value == "true") {
        this.switchMeal = true;
      }
    }
    );
    this.sub = this.route.params.subscribe(params => {
      this.trainee_id = params['traineeID'];
      this.program_id = params['programID'];
      // this.getProgramData();
    });

    if (this.userID == undefined || this.userID == "") {
      this.getProfileDataFromServer();
    }
    this.mealplan = new mealplan();
    this.traineecalender = new traineecalender();
    //this.mealplancalendar = new mealplancalendar();
    this.getTraineeData();
    this.dropdownSettings1 = {
      singleSelection: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      closeDropDownOnSelection: this.closeDropdownSelection
    };
    this.dropdownSettings_single = {
      singleSelection: true,
      enableCheckAll: false,
      idField: 'id',
      textField: 'name',
      allowSearchFilter: true,
      itemsShowLimit: 1,
      closeDropDownOnSelection: this.closeDropdownSelection
    };
    this.dropdownSettings = {
      singleSelection: false,
      enableCheckAll: false,
      idField: 'id',
      textField: 'name',
      allowSearchFilter: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      closeDropDownOnSelection: this.closeDropdownSelection
    };
    this.repeats = ['1 week', '2 week', '3 week', '4 week'];


    if(this.timezone.includes("asia") || this.timezone.includes("Asia")){
      this.myDatePickerOptions = {
        todayBtnTxt: 'Today',
        dateFormat: 'dd-mm-yyyy',
        firstDayOfWeek: 'mo',
        sunHighlight: true,
        openSelectorOnInputClick: true,
        editableDateField: false,
        inline: false
      }
      // this.dateFormat = "dd-MM-yyyy";
      this.dateFormat = "mediumDate";
    }else{
      this.myDatePickerOptions = {
        todayBtnTxt: 'Today',
        dateFormat: 'mm-dd-yyyy',
        firstDayOfWeek: 'mo',
        sunHighlight: true,
        openSelectorOnInputClick: true,
        editableDateField: false,
        inline: false
      }
      // this.dateFormat = "MM-dd-yyyy";
      this.dateFormat = "mediumDate";

    }
  }
  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'bg-img-build-male');
    this.renderer.removeClass(document.body, 'bg-img-yoga-male');
    this.renderer.removeClass(document.body, 'bg-img-zumba-male');
    this.renderer.removeClass(document.body, 'bg-img-build-female');
    this.renderer.removeClass(document.body, 'bg-img-yoga-female');
    this.renderer.removeClass(document.body, 'bg-img-zumba-female');
    this.renderer.addClass(document.body, 'body-main');
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {

    if (!isLoggedIn) {
      this.router.navigate(['/login']);
    } else {
      // if()
      if (this.loggedIn == true) {
        this.currentEmail = localStorage.getItem('lastAuthUser');
        // if(this.currentEmail != undefined)
        this.getProfileDataFromServer();
      }
      else {
        // this.currentEmail = localStorage.getItem('CognitoIdentityServiceProvider.2io8v4r253hugt5ndbjv9shkfp.LastAuthUser');
        this.currentEmail = this.cognitoUtil.getCurrentUser().getUsername();
        if(this.currentEmail == undefined || this.currentEmail == '' || this.currentEmail == null || this.currentEmail.includes("Google") || this.currentEmail.includes("google"))
        {
          this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
          if(this.currentEmail == undefined)
          this.currentEmail = this.cognitoUtil.session.idToken.payload.email;
        }
        // this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
        this.getProfileDataFromServer();
      }
    }
  }

  getMasterMealplans(){
    var index = "/mastermeal";
    var queryParsms={"trainerId":this.userID};
    this.db_service.getQueriedData('',index, queryParsms).subscribe(
      response => {
         this.MasterMealPlans = response.data;
         for(var i=0; i<this.MasterMealPlans.length; i++){
          this.MasterMealPlans[i]["id"] =  this.MasterMealPlans[i]["mealId"];
          this.MasterMealPlans[i]["name"] =  this.MasterMealPlans[i]["mealName"];
         }
      },
     error => {
      console.log("Something went wrong while retrieving data.");
     }

    )
  }
  getProfileDataFromServer() {
    var index = '/profile/';
    this.db_service.getDataFromServer(this.currentEmail, index).subscribe(
      response => {
        console.log('Date Retrived succesfully.', response);
        response = response.data[0];
        this.userID = response.trainerId;
        var nutri = response["isNutritionist"];
        if (nutri["status"] == "Yes")
          this.isNutritionist = true;
      },
      error => {
        console.log("An error has occurred while retriving profile data.");
      }
    )
  }

  emitFunction() {
    this.dayEvent.emit(this.emitDay);
    if (this.isMealPlan == true) {
      this.router.navigate(['/mealplanGen/' + this.program_id + "/" + this.trainee_id]);

      this.dataService.broadcastMealplan(this.emitDay, this.trainee_id);
    } else {
      this.addMealPlan();
    }
  }
  viewMealPlan(date) {
    this.router.navigate(['/mealplanGen/' + this.program_id + "/" + this.trainee_id]);
    this.dataService.broadcastMealplan(date, this.trainee_id);
  }
  getTraineeData() {
    var index = '/program/trainee/';
    //this.trainee = new trainee();
    this.trainee = {};
    this.trainee["bmi_bmr"] = {};

       var queryparams = {
            "program_id":this.program_id,
            "trainee_id":this.trainee_id
          }
          this.db_service.getQueriedData("", index, queryparams).subscribe(
      response => {
        console.log('Data retrived succesfully.', response);
        //this.program_id = response.data[0]["program_id"];
        this.trainee = response.data;
        if(this.trainee.isProgramActive == true){
          this.isTraineeActive= true;
        }else{
          this.isTraineeActive= false;
        }
        if (this.trainee.traineeProfileImg != null)
          this.traineeImage = this.trainee.traineeProfileImg.profileImagePath;
        this.traineeDiet = this.trainee.food_preference.food_type[0];
        this.getTraineeCalendarData();
        this.getTraineesData();
      },
      error => {
        // alert("An error has occurred while retriving profile data.");
        this.toastr.error('', 'An error has occurred while retriving trainee profile data', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      }
    )
  }
  addMealPlan() {
    if (this.isMealChoose == true && this.SelectedMeal.length == 0) {
      this.toastr.error('', 'Please select day meal to copy.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return;
    }
    if (this.isMasterMealChoose == true && this.SelectedMasterMeal.length == 0) {
      this.toastr.error('', 'Please select master meal to copy.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return;
    }
    if (this.isRepeatCk == true && this.Selectedrepeat.length == 0 && this.Selectedweek.length == 0) {
      this.toastr.error('', 'Please select repeat values.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return;
    }
    if (this.isRepeatCk == true && this.Selectedrepeat.length == 0 && this.Selectedweek.length == 0 && this.isMealChoose == false) {
      this.toastr.error('', 'Please select a day to reapeat.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return;
    }
    if (this.isCloneMealChoose == true && this.SelectedTrainee.length == 0) {
      this.toastr.error('', 'Please select a trainee to clone calendar data.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return;
    }
    var a = 0;
    if (this.isMealChoose == true) {
      var selected_meal: string = this.SelectedMeal[a]["name"];
      var day_meal = selected_meal.split(" ");
      var selected_day_meal = {};
      for (let i = 0; i < this.mealplandata.length; i++) {
        for (let j = 0; j < this.mealplandata[i]["days"].length; j++) {
          if (JSON.parse(day_meal[1]) == this.mealplandata[i]["days"][j]["day"]) {
            selected_day_meal = this.mealplandata[i]["days"][j]["mealplan"];
          }
        }
      }
      for (let i = 0; i < this.mealplandata.length; i++) {
        for (let j = 0; j < this.mealplandata[i]["days"].length; j++) {
          if (this.day == this.mealplandata[i]["days"][j]["day"]) {
            this.mealplandata[i]["days"][j]["mealplan"] = JSON.parse(JSON.stringify(selected_day_meal));;
          }
        }
      }
      if (this.Selectedweek != undefined) {
        if (this.Selectedweek.length != 0) {
          this.sweek = (this.Selectedweek[a]);
          var w = this.sweek.split(" ");
          var untilWeek = JSON.parse(w[1]);
          var repeatWeek = JSON.parse((this.Selectedrepeat[a].split(" week"))[0]);
          for (let j = this.week; j <= (this.calenderWeeks * this.currentPlan); j++) {
            var nextWeek = j;
            if (nextWeek <= untilWeek) {
              for (let m = 0; m < this.selectedOptions.length; m++) {
                var selDay = ((nextWeek - 1) * 7) + this.selectedOptions[m];
                var selWeekCheck = this.mealplandata.find(x => x["week"] == nextWeek);
                var selDayCheck = undefined;

                for (var n = 0; n < this.mealplandata.length; n++) {
                  if (selDayCheck == undefined) {
                    var checkData = this.mealplandata[n]["days"];
                    selDayCheck = checkData.find(x => x["day"] == selDay);
                  }
                }
                for (let q = 0; q < this.mealplandata.length; q++) {
                  if (this.mealplandata[q]["week"] == nextWeek) {

                    for (let p = 0; p < this.mealplandata[q]["days"].length; p++) {
                      if (this.mealplandata[q]["days"][p]["day"] == selDay) {
                        this.mealplandata[q]["days"][p]["mealplan"] = JSON.parse(JSON.stringify(selected_day_meal));
                      }
                    }
                  }
                }
              }
              j = j + repeatWeek - 1;
            }
          }
        }
      }
      this.updateMealPlan();
    }
    if(this.isMasterMealChoose == true){
      var selected_meal_id: string = this.SelectedMasterMeal[a]["id"];
      // var day_meal = selected_meal.split(" ");
      var selected_day_meal:{} = this.MasterMealPlans.find(x=> x["mealId"] == selected_meal_id);
      delete selected_day_meal["createdOn"];
      delete selected_day_meal["lastUpdatedOn"];
      for (let i = 0; i < this.mealplandata.length; i++) {
        for (let j = 0; j < this.mealplandata[i]["days"].length; j++) {
          if (this.day == this.mealplandata[i]["days"][j]["day"]) {
            this.mealplandata[i]["days"][j]["mealplan"] = JSON.parse(JSON.stringify(selected_day_meal));;
          }
        }
      }
      if (this.Selectedweek != undefined) {
        if (this.Selectedweek.length != 0) {
          this.sweek = (this.Selectedweek[a]);
          var w = this.sweek.split(" ");
          var untilWeek = JSON.parse(w[1]);
          var repeatWeek = JSON.parse((this.Selectedrepeat[a].split(" week"))[0]);
          for (let j = this.week; j <= (this.calenderWeeks * this.currentPlan); j++) {
            var nextWeek = j;
            if (nextWeek <= untilWeek) {
              for (let m = 0; m < this.selectedOptions.length; m++) {
                var selDay = ((nextWeek - 1) * 7) + this.selectedOptions[m];
                var selWeekCheck = this.mealplandata.find(x => x["week"] == nextWeek);
                var selDayCheck = undefined;

                for (var n = 0; n < this.mealplandata.length; n++) {
                  if (selDayCheck == undefined) {
                    var checkData = this.mealplandata[n]["days"];
                    selDayCheck = checkData.find(x => x["day"] == selDay);
                  }
                }
                for (let q = 0; q < this.mealplandata.length; q++) {
                  if (this.mealplandata[q]["week"] == nextWeek) {

                    for (let p = 0; p < this.mealplandata[q]["days"].length; p++) {
                      if (this.mealplandata[q]["days"][p]["day"] == selDay) {
                        this.mealplandata[q]["days"][p]["mealplan"] = JSON.parse(JSON.stringify(selected_day_meal));
                      }
                    }
                  }
                }
              }
              j = j + repeatWeek - 1;
            }
          }
        }
      }
      this.updateMealPlan();
    }
    if (this.isCloneMealChoose == true) {
      var selected_trainee_id: string = this.SelectedTrainee[a]["id"];
      var index = '/mealplan/master';
      var queryParams = {
        "trainee_id": selected_trainee_id,
        "program_id": this.program_id
      }
      this.db_service.getQueriedData('', index, queryParams).subscribe(
        response => {
          console.log('Data retrived succesfully.', response);
          // this.mealplandata = response.data.weeks;
          for (let i = 0; i < response.data.weeks.length; i++) {
            for (let j = 0; j < this.mealplandata.length; j++) {
              for (let k = 0; k < response.data.weeks[i]["days"].length; k++) {
                for (let l = 0; l < this.mealplandata[j]["days"].length; l++) {
                  if (response.data.weeks[i]["days"][k]["day"] == this.mealplandata[j]["days"][l]["day"]) {
                    this.mealplandata[j]["days"][l]["mealplan"] = response.data.weeks[i]["days"][k]["mealplan"];
                    break;
                  }
                }
              }
            }
          }
          this.updateMealPlan();
        },
        error => {
          // alert("An error has occurred while retriving profile data.");
          this.toastr.error('', 'An error has occurred while cloning trainee meal data', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        }
      )
    }

  }
  updateMealPlan() {
    var index = '/mealplan';
    var calendarObj = {};
    calendarObj["weeks"] = this.mealplandata;;
    calendarObj["trainee_id"]= this.traineecalender.trainee_id;
    calendarObj["program_id"]= this.traineecalender.program_id;

    // this.traineecalender.weeks = this.mealplandata;
    // this.traineecalender.trainer_status = "new";
    this.db_service.updateDatainDB(calendarObj, index).subscribe(
      response => {
        this.showEventPopup = false;
        this.toastr.success('', 'Mealplan updated successfully.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      }
    )
  }

  getTraineesData() {
    var index = '/program/Fitness/' + this.program_id + '/trainees/active';
    this.db_service.getDataFromServer('', index).subscribe(
      response => {
        console.log('Data retrived succesfully.', response);
        this.alltrainees = response.data;
        for (let i = 0; i < this.alltrainees.length; i++) {
          if (this.trainee_id != this.alltrainees[i]["trainee_id"]) {
            var traineeObj = {};
            traineeObj["id"] = this.alltrainees[i]["trainee_id"];
            traineeObj["name"] = this.alltrainees[i]["first_name"];
            if (this.Trainees == undefined || this.Trainees == null) {
              this.Trainees = Array.of(traineeObj);
            } else {
              this.Trainees.push(traineeObj);
            }
          }
        }
      },
      error => {
        // alert("An error has occurred while retriving profile data.");
        this.toastr.error('', 'An error has occurred while retriving profile data', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      }
    )
  }
  getTraineeCalendarData() {
    var index = '/program/' + this.program_id + '/calendar/';
    this.db_service.getDataFromServer(this.trainee_id, index).subscribe(
      response => {
        console.log('WORKOUT EXECUTED.', response);
        this.traineecalender = response.data;
        this.daysdata = response.data.weeks;
        if (response.data.trainer_status == undefined || response.data.trainer_status != "published") {
          this.isPublish = true;
        } else{
          this.isPublish = false;
          this.workoutClStartDate = this.getConvertedDate(new Date(response.data.startDate));
        }
        this.getWorkoutDataFromServer();
        if(this.isNewLoad == false || this.isNewLoad == undefined)
        this.getProgramData();
      },
      error => {
        // alert("An error has occurred while retriving profile data.");
        this.toastr.error('', 'An error has occurred while retriving trainee calendar data', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      }
    )
  }
  getMealPlanCalendarData() {
    var index = '/mealplan';
    var queryParams = {
      "trainee_id": this.trainee_id,
      "program_id": this.program_id
    }
    this.db_service.getQueriedData('', index, queryParams).subscribe(
      response => {
        console.log('MEALPLAN EXECUTED.', response);
        if (response.data != null) {
          this.mealplandata = response.data.weeks;
          if (response.data.trainer_status == undefined || response.data.trainer_status != "published") {
            this.isMealPublish = true;
          } else{
            this.isMealPublish = false;
            this.mealPlanClStartDate = this.getConvertedDate( new Date(response.data.startDate));
          }
          if(this.isPublish == false && this.switchMeal == false){
            this.programStartDate = this.workoutClStartDate;
            this.isDateDisabled = true;
          }
          if(this.isMealPublish == false && this.switchMeal == true){
            this.programStartDate = this.mealPlanClStartDate;
            this.isDateDisabled = true;
          }
        } else {
          this.mealplandata = [];
        }
      },
      error => {
        // alert("An error has occurred while retriving profile data.");
        this.toastr.error('', 'An error has occurred while retriving trainee calendar data', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      }
    )
  }
  getProgramData() {
    var index = '/program/';
    this.db_service.getDataFromServer(this.program_id, index).subscribe(
      response => {
        //  this.programdata = response.data;
        this.calendarPlans = response.data["plans"];
        this.calenderWeeks = response.data["weeks"];
        this.calendarDaysArr.length = 0;
        if (response.data.nutrition.name == "Yes"){
          this.isNutrition = true;
          this.getMealPlanCalendarData();
        }else{
          if(this.isPublish == false && this.switchMeal == false){
            this.programStartDate = this.workoutClStartDate;
            this.isDateDisabled = true;
          }
        }
        this.program_start_date = response.data.startDate;
        this.programType =response.data.programType.name;
        if(this.programType != "Regular program"){
          this.programStartDate = this.getConvertedDate(new Date(response.data.startDate));
        }
        this.generateCalendar();

        //this.getWorkoutDataFromServer();
      },
      error => {
        // alert("An error has occurred while saving data.");
        this.toastr.error('', 'An error has occurred getting my program data.', {
          timeOut: 5000,
          extendedTimeOut: 1000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true
        });
      }
    )
  }

  onMealSwich(){
    console.log(this.switchMeal);
    if(this.isPublish == false && this.switchMeal == true){
      this.programStartDate = this.workoutClStartDate;
      this.isDateDisabled = true;
    }
    if(this.isPublish == true && this.switchMeal == true){
      this.isDateDisabled = false;
    }
    if(this.isMealPublish == false && this.switchMeal == false){
      this.programStartDate = this.mealPlanClStartDate;
      this.isDateDisabled = true;
    }
    if(this.isMealPublish == true && this.switchMeal == false){
      this.isDateDisabled = false;
    }
  }

  getConvertedDate(dateToConvert: Date){
    var iMyDate = new IMyDate();
    iMyDate.day = dateToConvert.getDate();
    iMyDate.month = dateToConvert.getMonth() + 1;
    iMyDate.year = dateToConvert.getFullYear();
    var displayDate = new IMyDateModel();
    displayDate.jsdate = dateToConvert;
    displayDate.date = iMyDate;
    displayDate.formatted = this.datepipe.transform(dateToConvert, this.dateFormat);
    displayDate.epoc = dateToConvert.getTime() / 1000;
    return displayDate;
   }

  // Full Calendar

  // @ViewChild('calendar') calendarComponent: FullCalendarComponent; // the #calendar in the template

  calendarVisible = true;
  // calendarPlugins = [dayGridPlugin, timeGrigPlugin, interactionPlugin];
  calendarWeekends = true;

  calendarEvents = [];
  // calendarEvents = [
  //     {title:"abc", date:"2019-10-02"},
  //     {title:"abc", date:"2019-10-04"}
  // ];
  currentClickDate: any;

  // Calendar Vars
  calendarPlans: number = 1;  // Total no of Plans
  currentPlan: number = 1;
  calenderWeeks: number = 4;  // No of week for plan
  calendarDays: number;
  calendarDay: number;
  currentDay: number = 1;
  currentWeeks: number;
  calendarDaysArr: number[] = [];
  calendarDaysStrArr: string[] = [];
  calendarDayNames: string[] = ['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'];
  calendarWeekNames: number[] = [];
  calendarWeekNamesStr: string[] = [];
  calendarWeek: number;
  currentWeek: number;
  public showEventPopup: boolean = false;
  public showWorkoutEventPopup: boolean = false;
  day: number;
  week: number;
  isLastWeek: boolean = false;
  Selectedweek: [];
  Selecteddays: [];
  Selectedrepeat: string[];
  distance: number;
  isMealPlan: boolean = false;
  isMealChoose: boolean = false;
  isCloneMealChoose: boolean = false;
  isMasterMealChoose: boolean = false;

  weekCaluculator(day) {
    var weeknumber = 0;
    if (day <= 7) {
      weeknumber = 1;
    }
    else if (day % 7 === 0) {
      weeknumber = day / 7;
    } else {
      weeknumber = Math.floor(day / 7) + 1;
      // weeknumber = JSON.parse(weeknumber);
    }
    return weeknumber;
  }
  // Calendar Code
  // this method is to generate calendar as per next plan
  onNxtPlan() {
    this.currentPlan += 1;
    this.calendarDaysArr.length = 0;  // making day calendar null
    this.calendarWeekNames.length = 0;  // making week calendar null
    this.calendarDaysStrArr = [];
    this.generateCalendar();  // genrating calendar days
    // this.currentPlan = this.currentPlan++;  // on next incrementing plan
  }

  // this method is for genration of calendar for previous plan
  onPrevPlan() {
    this.currentPlan -= 1;
    this.calendarDaysArr.length = 0;
    this.calendarWeekNames.length = 0;  // making week calendar null
    this.calendarDaysStrArr = [];
    this.generateCalendar();

  }

  // in this method we can find genration of days and pushing them into array as per plan
  generateCalendar() {
    // this.currentPlan = this.currentPlan;
    this.totalDay();  // this method is for creation of of days as per plan
    // console.log("Calendar Days" +this.calendarDays);
    for (var i = 1; i <= this.calendarDays; i++) {
      if (this.currentPlan > 1 && i > this.currentDay) {
        this.calendarDay = i;
        this.calendarDaysArr.push(this.calendarDay);
        this.calendarDaysStrArr.push("Day " + this.calendarDay);
      }
      if (this.currentPlan == 1) {
        this.calendarDay = i;
        this.calendarDaysArr.push(this.calendarDay);
        this.calendarDaysStrArr.push("Day " + this.calendarDay);
      }

    }
    for (var j = 1; j <= this.currentWeeks; j++) {
      if (this.currentPlan > 1 && j > this.currentWeek) {
        // this.calendarDay = i;
        this.calendarWeek = j;
        this.calendarWeekNames.push(this.calendarWeek);
        // this.calendarWeekNamesStr.push("Week "+this.calendarWeek);
      }
      if (this.currentPlan == 1) {
        // this.calendarDay = ;
        this.calendarWeek = j;
        this.calendarWeekNames.push(this.calendarWeek);
        // this.calendarWeekNamesStr.push("Week "+this.calendarWeek);
      }
    }
  }
  totalDay() {
    if (this.currentPlan > 1) {
      this.currentDay = (this.currentPlan - 1) * this.calenderWeeks * 7;
      this.currentWeek = (this.currentPlan * this.calenderWeeks) - this.calenderWeeks;
    }
    else {
      this.currentDay = this.currentPlan * this.calenderWeeks * 7;
      this.currentWeek = this.calenderWeeks;
    }
    this.calendarDays = this.currentPlan * this.calenderWeeks * 7;
    this.currentWeeks = this.currentPlan * this.calenderWeeks;
  }

  showFields(event, type) {
    if (this.switchMeal == true) {
      if (this.isMealPlan == true && type == "create") {
        this.isRepeatCk = false;
        this.isMealChoose = false;
        this.isMasterMealChoose= false;
        this.isCloneMealChoose = false;
        this.isLastWeek = false;
      }
      if (this.isMealChoose == true && type == "choose") {
        this.isMealPlan = false;
        this.isCloneMealChoose = false;
        this.isLastWeek = true;
        this.isMasterMealChoose = false;
      }
      if (this.isMasterMealChoose == true && type == "masterchoose") {
        this.isMealPlan = false;
        this.isCloneMealChoose = false;
        this.isLastWeek = true;
        this.isMealChoose = false;
      }
      if ((this.isMealChoose == false && type == "choose") || (this.isMasterMealChoose == false && type == "masterchoose")) {
        this.isRepeatCk = false;
        this.isLastWeek = false;
      }

      if (this.isCloneMealChoose == true && type == "clone") {
        this.isMealPlan = false;
        this.isMealChoose = false;
        this.isMasterMealChoose = false;
        this.isRepeatCk = false;
        this.isLastWeek = false;
      }
      if (this.isRepeatCk == false && type == "repeat") {
        this.calendarWeekNamesStr = [];
        this.Selectedrepeat = [];
        this.Selectedweek = [];

      }
    } else {
      if (this.isWorkoutCk == true && type == "workout") {
        this.rest = false;
        this.isRestCk = false;
      } if (this.isWorkoutCk == false && type == "workout") {
        this.rest = false;
        this.SelectedWorkouts = [];
      } if (this.isCardioCk == true && type == "cardio") {
        this.rest = false;
        this.isRestCk = false;
      } if (this.isCardioCk == false && type == "cardio") {
        this.rest = false;
        this.cardio = "";
      }

      //  if (this.isRepeatCk == true && type == "repeat") {
      //   this.showRepeat = true;
      // }
      if (this.isRepeatCk == false && type == "repeat") {
        this.calendarWeekNamesStr = [];
        this.Selectedrepeat = [];
        this.Selectedweek = [];

      }
      if (this.isRestCk == true && type == "rest") {
        // this.showCardio = false;
        // this.showWorkout = false;
        this.SelectedWorkouts = [];
        this.SelectedCardio = [];
        this.cardio = "";
        this.rest = true;
        this.isWorkoutCk = false;
        this.isCardioCk = false;
      }
    }
  }
  openModal(day) {

    this.isMealPlan = false;
    this.isMealChoose = false;
    this.isRepeatCk = false;
    this.isLastWeek = false;
    this.isCloneMealChoose = false;
    this.SelectedMeal = [];
    this.SelectedTrainee = [];
    this.Selecteddays = [];
    this.Selectedrepeat = [];
    this.Selectedweek = [];
    this.calendarWeekNamesStr = [];
    this.MealPlans = [];
    this.MasterMealPlans = [];
    this.isMasterMealChoose = false;
    this.SelectedMasterMeal = [];
    this.repeats = [];
    this.getWorkoutDataFromServer();
    this.getMasterMealplans();
    this.week = this.weekCaluculator(day);
    this.distance = 0;
    this.SelectedWorkouts = [];
    this.SelectedCardio = [];
    this.isCardioCk = false;
    this.isWorkoutCk = false;
    this.isRestCk = false;
    this.isProgressCk = false;
    this.rest = false;

     for (let j = 0; j < this.repeatDays.length; j++) {
      this.repeatDays[j].checked = false;
    }

    // this.week = this.weekCaluculator(day);
    this.day = day;
    var index = this.calendarWeekNames.findIndex(x => x == this.week);
    var repeats_value = index;
    var value_repeat = 1;
    for (var i = index; i < this.calendarWeekNames.length; i++) {
      if (i > repeats_value) {
        this.repeats.push(value_repeat + " week");
        value_repeat++;
      }
      if (index == this.calendarWeekNames.length - 1 && i == repeats_value) {
        this.repeats.push(value_repeat + " week");
      }
      // if(this.calendarWeekNames[i] > this.week){
      this.calendarWeekNamesStr.push("Week " + this.calendarWeekNames[i]);
      // }
    }
    if (this.switchMeal == true) {
      this.showEventPopup = true;
      if(this.isNutrition == true && this.isMealPublish == false){
      
        if (this.mealplandata != undefined) {

          var dayData = undefined;
          for (var n = 0; n < this.mealplandata.length; n++) {
            if (dayData == undefined) {
              var checkData = this.mealplandata[n]["days"];
              dayData = checkData.find(x => x["day"] == this.day);
            }
          }
          if (dayData != undefined) {
            var selecteDate = dayData["date"].split("/");
            if (moment(new Date(selecteDate[2] + "-" + selecteDate[1] + "-" +selecteDate[0])).format("YYYY-MM-DD") <= moment(new Date()).format("YYYY-MM-DD")) {
              this.toastr.warning('', 'You cannot edit previous or current day data.', {
                timeOut: 3000,
                positionClass: 'toast-top-right',
                progressBar: true,
                progressAnimation: 'increasing',
                tapToDismiss: true,
              });
              this.showEventPopup = false;
              return;
          }
      }
    }
   }
    }
    else {
       
      this.showWorkoutEventPopup = true;
      if (this.workoutCheck == true) {
        this.showWorkoutEventPopup = false;
      } else {
        if (this.daysdata != undefined) {

          var dayData = undefined;
          for (var n = 0; n < this.daysdata.length; n++) {
            if (dayData == undefined) {
              var checkData = this.daysdata[n]["days"];
              dayData = checkData.find(x => x["day"] == this.day);
            }
          }
          if (dayData != undefined) {
            if(this.isPublish == false){
            var selecteDate = dayData["date"].split("/");
            if (moment(new Date(selecteDate[2] + "-" + selecteDate[1] + "-" +selecteDate[0])).format("YYYY-MM-DD") <= moment(new Date()).format("YYYY-MM-DD")) {
              this.toastr.warning('', 'You cannot edit previous or current day data.', {
                timeOut: 3000,
                positionClass: 'toast-top-right',
                progressBar: true,
                progressAnimation: 'increasing',
                tapToDismiss: true,
              });
              this.showWorkoutEventPopup = false;
              return;
            }
          }

            for (let i = 0; i < dayData["workouts"].length; i++) {
              var workout = dayData["workouts"][i]["workoutName"];
              workout["id"] = dayData["workouts"][i]["workoutId"];
              this.SelectedWorkouts.push(JSON.parse(JSON.stringify(workout)));
            }
            if (this.SelectedWorkouts.length != 0)
              this.isWorkoutCk = true;
            if (Object.keys(dayData["cardio"]).length != 0) {
              this.SelectedCardio = Array.of(dayData["cardio"]);

              this.distance = dayData["cardio"]["distance"]["actual"];

              this.isCardioCk = true;
            }
            this.isRestCk = dayData["rest"];
            this.isProgressCk = dayData["progress_photo"];
          }

        }
      }
    }

    if (this.week == (this.currentPlan * this.calenderWeeks)) {
      this.isLastWeek = false;
    }
    if (this.week != (this.currentPlan * this.calenderWeeks) && this.switchMeal == false) {
      this.isLastWeek = true;
    }
    for (let i = 0; i < this.mealplandata.length; i++) {
      for (let j = 0; j < this.mealplandata[i]["days"].length; j++) {
        if (this.day == this.mealplandata[i]["days"][j]["day"]) {
          this.week = this.mealplandata[i]["week"];
          this.emitDay = this.mealplandata[i]["days"][j]["date"];
        }
      }
    }
    // var index = this.calendarWeekNames.findIndex(x => x == this.week);
    // for (let i = index; i < this.calendarWeekNames.length; i++) {
    //   this.calendarWeekNamesStr.push("Week " + this.calendarWeekNames[i]);
    // }

    for (let i = 0; i < this.mealplandata.length; i++) {
      for (let j = 0; j < this.mealplandata[i]["days"].length; j++) {
        if (this.day != this.mealplandata[i]["days"][j]["day"]) {
          var dayObj = {};
          if (this.mealplandata[i]["days"][j]["mealplan"] != null || this.mealplandata[i]["days"][j]["mealplan"] != undefined) {
            if (this.mealplandata[i]["days"][j]["mealplan"]["breakfast"] != null || this.mealplandata[i]["days"][j]["mealplan"]["dinner"] != null || this.mealplandata[i]["days"][j]["mealplan"]["lunch"] != null || this.mealplandata[i]["days"][j]["mealplan"]["snacks"] != null) {
              dayObj["id"] = this.mealplandata[i]["days"][j]["day"];
              dayObj["name"] = "Day " + this.mealplandata[i]["days"][j]["day"] + " (" + this.mealplandata[i]["days"][j]["mealplan"]["mealName"] + ")";
              if (this.MealPlans == undefined || this.MealPlans == null) {
                this.MealPlans = Array.of(dayObj);
              } else {
                this.MealPlans.push(dayObj);
              }
            }
          }
        }
      }
    }
  }
  // deleteWorkout(id) {
  //   var WorkoutsArray = [];
  //   for (var x = 0; x < this.daysdata.length; x++) {
  //     for (var i = 0; i < this.daysdata[x]["days"].length; i++) {
  //       if (this.daysdata[x]["days"][i]["day"] === this.day) {
  //         const index = this.daysdata[x]["days"][i]["workouts"].findIndex(y => y["workoutId"] === id);
  //         this.daysdata[x]["days"][i]["workouts"].splice(index, 1);
  //       }
  //     }
  //   }
  //   this.showWorkOtPopup = false;
  //   this.workoutCheck = false;
  //   var index = '/calendar'
  //   this.db_service.updateDatainDB(this.traineecalender, index).subscribe(
  //     response => {
  //       this.toastr.success('', 'Workout deleted successfully.', {
  //         timeOut: 3000,
  //         positionClass: 'toast-top-right',
  //         progressBar: true,
  //         progressAnimation: 'increasing',
  //         tapToDismiss: true,
  //       });
  //     },
  //     error => {
  //       this.toastr.error('', 'There is an error while deleting workout.', {
  //         timeOut: 3000,
  //         positionClass: 'toast-top-right',
  //         progressBar: true,
  //         progressAnimation: 'increasing',
  //         tapToDismiss: true,
  //       });
  //     })
  // }
  getWorkoutDataFromServer() {
    var index = '/workout/all/';
    var queryparams={
      "pagesize": 0,
      "pagenumber":0,
      "pagination":false
    }
    this.db_service.getQueriedData(this.traineecalender.trainer_id, index, queryparams).subscribe(
      response => {
        console.log('Data Retrived succesfully.', response);
        this.Workouts = [];
        this.masterWorkouts = response.data;
        for (let i = 0; i < response.data.length; i++) {
          var workout = {};
          if (response.data[i]["workoutExercises"] != undefined && response.data[i]["workoutExercises"].length != 0) {
            workout["name"] = response.data[i]["customWorkoutName"];
            workout["id"] = response.data[i]["workoutId"];
            // workout[0]["imgPath"] = response[i]["imgUrl"];
            if (this.Workouts == undefined || this.Workouts == null) {
              this.Workouts = Array.of(workout);
            } else {
              this.Workouts.push(workout);
            }
          }
        }
      },
      error => {
        console.log('There is an error while retriving workouts.');
      }
    )
  }
  validatePublish(){
    if(this.programStartDate == undefined){
      this.toastr.error('', 'Please select program start date.', {
                timeOut: 3000,
                positionClass: 'toast-top-right',
                progressBar: true,
                progressAnimation: 'increasing',
                tapToDismiss: true,
              });
              return;
    }
    if(this.programType == "Regular program"){
      var p_st_d = moment(this.datepipe.transform(new Date(this.program_start_date), 'yyyy-MM-dd')).format('YYYY-MM-DD');
      if(p_st_d > moment(this.datepipe.transform(this.programStartDate.jsdate, 'yyyy-MM-dd')).format('YYYY-MM-DD')){
        this.toastr.error('', 'Please select valid date as your program starts from '+ this.datepipe.transform(new Date(this.program_start_date), this.dateFormat), {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        return;
      }
      // if(moment(this.datepipe.transform(new Date(), 'yyyy-MM-dd')).format('YYYY-MM-DD') >= moment(this.datepipe.transform(this.programStartDate.jsdate, 'yyyy-MM-dd')).format('YYYY-MM-DD')){
      //   this.toastr.error('', 'Please select future date to publish.', {
      //     timeOut: 3000,
      //     positionClass: 'toast-top-right',
      //     progressBar: true,
      //     progressAnimation: 'increasing',
      //     tapToDismiss: true,
      //   });
      //   return;
      // }
    }
    var selectedDate = moment(this.datepipe.transform(this.programStartDate.jsdate, 'yyyy-MM-dd'),'YYYY-MM-DD');
    var subscriptionDate = moment(this.datepipe.transform(new Date(this.traineecalender["subscriptionDate"]), 'yyyy-MM-dd'),'YYYY-MM-DD');
    var p_start_date = moment(this.datepipe.transform(this.program_start_date, 'yyyy-MM-dd'),'YYYY-MM-DD');
    var duration = moment.duration(selectedDate.diff(subscriptionDate));
    if(duration.asDays() > this.SLA_Days_To_Publish && selectedDate > p_start_date){
      this.slaPopUp = true;
      return;
    }
    this.onPublish();
  }

  onPublish() {
    var publishData = {
      "program_id": this.program_id,
      "trainer_id": this.userID,
      "trainee_id": this.trainee_id,
      "start_date": this.datepipe.transform(this.programStartDate.jsdate, "yyyy-MM-dd"),
      "trainer_status": "published"
    }
    if(this.slaPopUp == true){
      publishData["isSlaCrossed"] = true;
    }
    this.slaPopUp = false;
   
    var index = "";
    if (this.switchMeal == true) {
      index = "/mealplan/calendar/publish";
      var mealplancheck = 0;
      for (let i = 0; i < this.mealplandata.length; i++) {
        for (let j = 0; j < this.mealplandata[i]["days"].length; j++) {
          var mealplan = this.mealplandata[i]["days"][j]["mealplan"];
          if (mealplan == undefined || mealplan == null) {
            mealplancheck = 1;
            break;
          } else if (Object.keys(mealplan).length === 0 && mealplan.constructor === Object) {
            mealplancheck = 1;
          } else {
            if (mealplan["breakfast"] == null && mealplan["dinner"] == null && mealplan["lunch"] == null && mealplan["snacks"] == null) {
              mealplancheck = 1;
            }
          }
        }
        if (mealplancheck == 1) {
          break;
        }

      }
      if (mealplancheck == 1) {
        this.toastr.error('', 'You can not publish until all mealplan days filled.', {
          timeOut: 5000,
          extendedTimeOut: 1000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true
        });
        return;
      }
    }
    else
      index = "/mealplan/calendar/workout/publish";

    this.db_service.saveDataToDB(publishData, index).subscribe(
      response => {
        // if (this.switchMeal == true)
        //   this.getMealPlanCalendarData();
        // else
        //   this.getTraineeCalendarData();

        // let emailModel = {};
        // emailModel['trainerId'] = this.userID;
        // emailModel['programId'] = this.program_id;
        // emailModel['trainee_id'] = this.trainee_id;
        // if (this.switchMeal == true) {
        //   this.getMealPlanCalendarData();
        //   emailModel['templateCode'] = "1o1_TEMPC_028";
        // }

        // else {
        //   this.getTraineeCalendarData();
        //   emailModel['templateCode'] = "1o1_TEWC_029"
        // }
        // this.db_service.sendEmail(emailModel).subscribe(response => {

        // })
        this.isDateDisabled = true;
        let emailModel = {};
        let notificationModel = {};
        emailModel['trainerId'] = this.userID;
        emailModel['programId'] = this.program_id;
        emailModel['trainee_id'] = this.trainee_id;
        notificationModel['userId'] = this.trainee_id;
        notificationModel['notificationType'] = "Post";
        notificationModel['programId'] = this.program_id;
        this.isNewLoad = true;

        if (this.switchMeal == true) {
        this.getMealPlanCalendarData();
        this.isDateDisabled = true;
        this.isMealPublish = false;
        emailModel['templateCode'] = "1o1_TEMPC_028";
        notificationModel['templateCode'] = "1o1_N_TEMPCA_040";
        }

        else {
        this.isPublish = false;
        // this.traineecalender.trainer_status = "published";
        // this.traineecalender.startDate = new Date(this.datepipe.transform(this.programStartDate.jsdate, "yyyy-MM-dd"));
        this.getTraineeCalendarData();
        this.isDateDisabled = true;
        emailModel['templateCode'] = "1o1_TEWC_029"
        notificationModel['templateCode'] = "1o1_N_TEWCA_042";
        }
        this.db_service.sendEmail(emailModel).subscribe(response => {

        })
        this.db_service.savenotification(notificationModel).subscribe(response =>{

        })

        this.toastr.success('', 'Calendar published successfully.', {
          timeOut: 5000,
          extendedTimeOut: 1000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true
        });
      },
      error => {
        // alert("An error has occurred while saving data.");
        this.toastr.error('', 'An error has occurred getting my program data.', {
          timeOut: 5000,
          extendedTimeOut: 1000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true
        });
      }
    )
  }
  SaveData() {
    var arrayIndex = 0;
    if (this.isWorkoutCk == true && this.SelectedWorkouts.length == 0) {
      this.toastr.error('', 'Please select workouts.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return;
    }
    if (this.isCardioCk == true && this.SelectedCardio.length == 0) {
      this.toastr.error('', 'Please select cardio.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return;
    }
    if (this.isCardioCk == false) {
      this.SelectedCardio = [];
    }
    if (this.SelectedCardio.length == 0 && this.SelectedWorkouts.length == 0 && this.isRestCk == false && this.isProgressCk == false) {
      this.toastr.error('', 'Please select any one option.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return;
    }
    if (this.SelectedCardio.length != 0 && (this.distance == undefined || this.distance == 0)) {
      this.toastr.error('', 'Time should be greater than zero.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return;
    }
    if (this.isRepeatCk == true && this.Selectedrepeat.length == 0) {
      this.toastr.error('', 'Please select repeat values.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return;
    }
    if (this.isRepeatCk == true) {
      var value = this.repeatDays.find(x => x["checked"] == true);
      if (value == undefined) {
        this.toastr.error('', 'Please select repeat days.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        return;
      }
    }

    if (this.isRepeatCk == true && this.Selectedweek.length == 0) {
      this.toastr.error('', 'Please select repeat until.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return;
    }
   var dayData = {};
      for (let k = 0; k < this.daysdata.length; k++) {
        if (this.daysdata[k]["week"] == this.week) {
            for (let i = 0; i < this.daysdata[k]["days"].length; i++) {
              if (this.daysdata[k]["days"][i]["day"] == this.day) {

                if (this.rest == true) {
                  this.daysdata[k]["days"][i]["rest"] = this.isRestCk;
                  this.daysdata[k]["days"][i]["workouts"] = [];
                  this.daysdata[k]["days"][i]["cardio"] = {};
                }
                else {
                  if (this.SelectedWorkouts.length != 0) {
                    var workoutsSelected = [];
                    for (var a = 0; a < this.SelectedWorkouts.length; a++) {
                      var workoutExist = this.daysdata[k]["days"][i]["workouts"].find(w=> w["workoutId"] == this.SelectedWorkouts[a]["id"]);
                      if(workoutExist == undefined){
                      var current_workout = this.masterWorkouts.find(x => x["workoutId"] == this.SelectedWorkouts[a]["id"]);
                      current_workout["viewedExercises"] = [];
                      current_workout["notViewedExercise"] = [];
                      workoutsSelected.push(JSON.parse(JSON.stringify(current_workout)));
                      }else{
                        workoutsSelected.push(JSON.parse(JSON.stringify(workoutExist))); 
                      }
                    }
                    this.daysdata[k]["days"][i]["workouts"] = workoutsSelected;
                    this.daysdata[k]["days"][i]["rest"] = this.isRestCk;
                  }else{
                    this.daysdata[k]["days"][i]["workouts"] = [];
                  }
                  if (this.SelectedCardio != undefined) {
                    if (this.SelectedCardio.length != 0) {
                      if(this.daysdata[k]["days"][i]["cardio"] != undefined){
                        if(Object.keys(this.daysdata[k]["days"][i]["cardio"]).length === 0){
                          this.daysdata[k]["days"][i]["cardio"] = this.cardiodata.find(x => x["id"] == [this.SelectedCardio[arrayIndex].id]);
                          this.daysdata[k]["days"][i]["cardio"]["distance"] = {};
                          this.daysdata[k]["days"][i]["cardio"]["distance"]["actual"] = this.distance;
                          this.daysdata[k]["days"][i]["cardio"]["distance"]["completed"] = 0;
                          this.daysdata[k]["days"][i]["cardio"]["cardioStatus"] = "new";
                          this.daysdata[k]["days"][i]["cardio"]["cardioComments"] = [];
                          this.daysdata[k]["days"][i]["cardio"]["metrics"] = "Minutes";
                        }else{
                          if(this.daysdata[k]["days"][i]["cardio"]["id"] != this.SelectedCardio[arrayIndex].id){
                            this.daysdata[k]["days"][i]["cardio"] = this.cardiodata.find(x => x["id"] == [this.SelectedCardio[arrayIndex].id]);
                            this.daysdata[k]["days"][i]["cardio"]["distance"] = {};
                            this.daysdata[k]["days"][i]["cardio"]["distance"]["actual"] = this.distance;
                            this.daysdata[k]["days"][i]["cardio"]["distance"]["completed"] = 0;
                            this.daysdata[k]["days"][i]["cardio"]["cardioStatus"] = "new";
                            this.daysdata[k]["days"][i]["cardio"]["cardioComments"] = [];
                            this.daysdata[k]["days"][i]["cardio"]["metrics"] = "Minutes";
                          }
                        }
                      }else{
                      this.daysdata[k]["days"][i]["cardio"] = this.cardiodata.find(x => x["id"] == [this.SelectedCardio[arrayIndex].id]);
                      this.daysdata[k]["days"][i]["cardio"]["distance"] = {};
                      this.daysdata[k]["days"][i]["cardio"]["distance"]["actual"] = this.distance;
                      this.daysdata[k]["days"][i]["cardio"]["distance"]["completed"] = 0;
                      this.daysdata[k]["days"][i]["cardio"]["cardioStatus"] = "new";
                      this.daysdata[k]["days"][i]["cardio"]["cardioComments"] = [];
                      this.daysdata[k]["days"][i]["cardio"]["metrics"] = "Minutes";
                      }
                    } else {
                      this.daysdata[k]["days"][i]["cardio"] = {}
                    }
                  } else {
                    this.daysdata[k]["days"][i]["cardio"] = {}
                  }
                  this.daysdata[k]["days"][i]["progress_photo"] = this.isProgressCk;
                }
                dayData = this.daysdata[k]["days"][i];
              }
            }
      
           
        }
      }
  
    if (this.Selectedweek != undefined) {
      if (this.Selectedweek.length != 0) {
        this.sweek = (this.Selectedweek[arrayIndex]);
        var w = this.sweek.split(" ");
        var untilWeek = JSON.parse(w[1]);
        var repeatWeek = JSON.parse(this.Selectedrepeat[arrayIndex].split(" week")[0]);
        for (let j = this.week; j <= (this.calenderWeeks * this.currentPlan); j++) {
          var nextWeek = j;
          if (nextWeek <= untilWeek) {
                for (let q = 0; q < this.daysdata.length; q++) {
                  if (this.daysdata[q]["week"] == nextWeek) {
                    for (let m = 0; m < this.selectedOptions.length; m++) {
                      var selDay = ((nextWeek - 1) * 7) + this.selectedOptions[m];
                      for (let p = 0; p < this.daysdata[q]["days"].length; p++) {
                        if (this.daysdata[q]["days"][p]["day"] == selDay) {
                          this.daysdata[q]["days"][p]["workouts"] = dayData["workouts"];
                          this.daysdata[q]["days"][p]["cardio"] = dayData["cardio"];
                          this.daysdata[q]["days"][p]["rest"] = dayData["rest"];
                          this.daysdata[q]["days"][p]["dayStatus"] = dayData["dayStatus"];
                          this.daysdata[q]["days"][p]["dayPercentage"] = dayData["dayPercentage"];
                        }
                      }
                   
                  }
                }
              
            }
            j = j + repeatWeek - 1;
          }
        }
      }
    }
    var calendarObj = {};
    calendarObj["weeks"] = this.daysdata;
    calendarObj["trainee_id"]= this.traineecalender.trainee_id;
    calendarObj["program_id"]= this.traineecalender.program_id;
    // this.traineecalender.weeks = this.daysdata;
    var index = '/mealplan/trainee'
    this.db_service.updateDatainDB(calendarObj, index).subscribe(
      response => {
        this.showWorkoutEventPopup = false;
        this.cardio = "";
        this.toastr.success('', 'Data saved successfully.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      },
      error => {
        this.toastr.error('', 'There is an error while saving data', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      })
  }

  getWorkouts(day,template: TemplateRef<any>) {
    this.day = day;
    this.showWorkoutsPopup = true;
    this.modalRef = this.modalService.show(template,Object.assign({}, { class: 'modal-dialog-centered modal-lg fit-modal-cus' }));
    if (this.daysdata != undefined) {
      var dayData = undefined;
      for (var n = 0; n < this.daysdata.length; n++) {
        if (dayData == undefined) {
          var checkData = this.daysdata[n]["days"];
          dayData = checkData.find(x => x["day"] == day);
        } else {
          break;
        }
      }
      if (dayData != undefined) {
        this.dayWorkouts = [];
        this.workoutExercises = [];
        this.currentExercise = {};
        this.currentExercise["exerciseVideo"] = {};
        this.dayCardio = {};
        this.embedYoutubeUrl = undefined;
        this.exercisePlayUrl = undefined;

        this.dayWorkouts = dayData["workouts"];
        if (this.dayWorkouts.length != 0) {
          this.workoutExercises = this.dayWorkouts[0]["workoutExercises"];
          this.currentExercise = this.workoutExercises[0];
        }
        this.dayCardio = dayData["cardio"];

        if (this.dayWorkouts.length != 0) {
          this.isVideoAvailable = true;
          if (this.currentExercise["exerciseVideo"]["youtubeUrl"] != undefined && this.currentExercise["exerciseVideo"]["youtubeUrl"] != "") {
            this.iframeURL = this.embedService.embed(this.currentExercise["exerciseVideo"]["youtubeUrl"], {
              query: { portrait: 0, color: 'black', autoplay: 1 },
              attr: { width: "100%", height: 250, frameborder: 0, allow: "autoplay" }
            })
            if (this.iframeURL != undefined)
              this.embedYoutubeUrl = this.iframeURL;

          } else {
            this.exercisePlayUrl = this.currentExercise["exerciseVideo"]["exerciseVideoSource"];
            this.viewworkLoad.nativeElement.load();
            this.viewworkLoad.nativeElement.play();
          }
        } else {
          this.isVideoAvailable = false;
          this.viewworkLoad.nativeElement.load();
          this.viewworkLoad.nativeElement.play();
        }

      }

    }
  }
  getWorkoutExercises(workoutId) {
    var workout = this.dayWorkouts.find(x => x["workoutId"] == workoutId);
    this.workoutExercises = workout["workoutExercises"];
    this.currentExercise = this.workoutExercises[0];
    if (this.currentExercise["exerciseVideo"]["youtubeUrl"] != undefined && this.currentExercise["exerciseVideo"]["youtubeUrl"] != "") {
      this.iframeURL = this.embedService.embed(this.currentExercise["exerciseVideo"]["youtubeUrl"], {
        query: { portrait: 0, color: 'black', autoplay: 1 },
        attr: { width: "100%", height: 250, frameborder: 0, allow: "autoplay" }
      })
      if (this.iframeURL != undefined)
        this.embedYoutubeUrl = this.iframeURL;

    } else {
      this.exercisePlayUrl = this.currentExercise["exerciseVideo"]["exerciseVideoSource"];
      this.viewworkLoad.nativeElement.load();
      this.viewworkLoad.nativeElement.play();
    }
  }

  getExerciseInfo(exerciseId) {
    this.currentExercise = this.workoutExercises.find(x => x["exerciseId"] == exerciseId);
    if (this.currentExercise["exerciseVideo"]["youtubeUrl"] != undefined && this.currentExercise["exerciseVideo"]["youtubeUrl"] != "") {
      this.iframeURL = this.embedService.embed(this.currentExercise["exerciseVideo"]["youtubeUrl"], {
        query: { portrait: 0, color: 'black', autoplay: 1 },
        attr: { width: "100%", height: 250, frameborder: 0, allow: "autoplay" }
      })
      if (this.iframeURL != undefined)
        this.embedYoutubeUrl = this.iframeURL;

    } else {
      this.exercisePlayUrl = this.currentExercise["exerciseVideo"]["exerciseVideoSource"];
      this.viewworkLoad.nativeElement.load();
      this.viewworkLoad.nativeElement.play();
    }
  }

  closePopup() {
    this.showEventPopup = false;
    this.showWorkoutEventPopup = false;
    this.calendarWeekNamesStr = [];
    this.slaPopUp = false;
    this.viewworkLoad.nativeElement.stop();

  }
}
