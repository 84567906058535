import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DataService {

  private messageSource = new BehaviorSubject<any>(Array);
  currentMessage = this.messageSource.asObservable();

  // component to component communication - mealplan
  currentDay = new BehaviorSubject('');   // This is to observe current date on day click in mealplan (code : santosh)
  currentTraineeId = new BehaviorSubject('');
  currentRoute = new BehaviorSubject('');

  constructor() { }

  changeMessage(message: any) {
    this.messageSource.next(message)
  }
  broadcastMealplan(date:string, traineeId:string) {
    this.currentDay.next(date);
    this.currentTraineeId.next(traineeId);
}
mealPlanRoute(croute: string){
this.currentRoute.next(croute);
}

}
