<div id="mydiv" *ngIf="loaderHide && this.currentCategory == 'Bodybuilder'">
  <img src="assets/images/loaders/loaderexe.gif" width="10%" class="ajax-loader" />
</div>
<div id="mydiv" *ngIf="loaderHide && this.currentCategory == 'Yoga'">
  <img src="assets/images/loaders/loaderyoga.gif" width="10%" class="ajax-loader" />
</div>
<div id="mydiv" *ngIf="loaderHide && this.currentCategory == 'Zumba'">
  <img src="assets/images/loaders/loaderjumba.gif" width="10%" class="ajax-loader" />
</div>


<section>

  <div class="container-fluid">
    <div class="col-md-10 mx-auto">
      <div class="row">
        <div class="prof-trainer-pic col-6 col-md-3 mx-auto text-center" *ngIf="isFitnessSelected">
          <img width="100%" height="100%" id="img" attr.src="{{ imgUrl }}" class="zoonIn"
            style="height: 150px; border-radius: 5px;">
          <div class="file_upload">
            <div>
              <input type="file" [readonly]="isReadOnly" (change)="selectFile($event,'image',null)"
                [disabled]="disabled" title="image">
              <i class="fas fa-camera fit-fa-camera" style="font-size: 18px;"></i>
            </div>
          </div>
        </div>
        <div class="prof-trainer-pic col-6 col-md-3 mx-auto text-center" *ngIf="isYogaSelected">
          <img width="100%" height="100%" id="img" attr.src="{{ yogaimgUrl }}" class="zoonIn"
            style="height: 150px; border-radius: 5px;">
          <div class="file_upload">
            <div>
              <input type="file" [readonly]="isReadOnly" (change)="onYogaFileSelect($event,'yoga_image', null)"
                [disabled]="disabled" title="image">
              <i class="fas fa-camera fit-fa-camera" style="font-size: 18px;"></i>
            </div>
          </div>
        </div>

        <div class="prof-trainer-details col-12 col-md-9">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="title col-md-6 col-8">
                  <div class="person-details-bg font-weight-bold fit-yg-color px-3">
                    Personal Details
                  </div>
                </div>
                <div class="title col-md-6 col-4 text-right my-auto">
                  <div class="float-right row">
                    <!-- <div class="dropdown">
                                    <div class="dropdown-toggle category-switch-icon-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                      <i class="fa fa-list-alt mx-1 myprofile-edit-i"></i>
                                    </div>
                              
                                    <ng-multiselect-dropdown class="form-control col-12" [data]="categories" [(ngModel)]="userCategories"
                                    [placeholder]="'Category*'" name="Category" [settings]="dropdownSettings" (onSelect)="onServiceSelect($event)"
                                    [disabled]="disabled" (onDeSelect)="onServiceDeselect($event)">
                                  </ng-multiselect-dropdown>
                              </div> -->

                    <div *ngIf='isEditProf'>
                      <i class="fas fa-user-edit mx-1 myprofile-edit-i" (click)="editProfile()"
                        style="padding: 10px 8px; cursor: pointer"   title="Edit" ></i>
                    </div>
                    <div *ngIf='isUpdateProf'>
                      <i class="fas fa-save mx-1 myprofile-edit-i" (click)="validateProfile('save')"  title="Save" style="cursor: pointer"></i>
                    </div>
                    <div><i class="fa fa-comment mx-1 myprofile-edit-i" (click)="getAdminComments()"></i></div>

                    <!-- <i class="fa fa-comment" style="font-size: 40px; color:red;" aria-hidden="true" (click)="getAdminComments()"></i> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 my-3">
              <div class="row">
                <div class="col-md-6">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="form-group col-6 col-md-6">
                        <input type="text" class="form-control col-12" id="firstName" [(ngModel)]="profile.firstName"
                          #firstName="ngModel" name="firstName" [readonly]="true" minlength="1"
                          pattern="^[A-Za-z ]*[A-Za-z][A-Za-z ]*$">
                        <span class="floating-label fit-yg-color fit-floating-label">First Name*</span>
                        <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)"
                          class="alert alert-danger">
                          <div *ngIf="firstName.errors?.minlength">
                            First Name should have Minimum 1 Character
                          </div>
                          <div *ngIf="firstName.errors?.pattern">
                            First Name should only contain alphabets
                          </div>
                        </div>
                      </div>
                      <div class="form-group col-6 col-md-6">
                        <input type="text" class="form-control col-12" id="lastName" [(ngModel)]="profile.lastName"
                          name="lastName" #lastName="ngModel" minlength="1" [readonly]="true"
                          pattern="^[A-Za-z ]*[A-Za-z][A-Za-z ]*$">
                        <span class="floating-label fit-yg-color fit-floating-label">Last Name*</span>
                        <div *ngIf="lastName.invalid  && (lastName.dirty || lastName.touched)"
                          class="alert alert-danger">
                          <div *ngIf="lastName.errors?.minlength">
                            Last Name should have minimum 1 character
                          </div>
                          <div *ngIf="lastName.errors?.pattern">
                            Last Name should only contain alphabets
                          </div>
                        </div>
                      </div>
                      <div class="form-group col-6 col-md-6">
                        <my-date-picker [options]="myDatePickerOptions" name="dateOfBirth" [(ngModel)]="dateOfBirth"
                          placeholder="Date" style="width: 100%;" [disabled]="disabled"></my-date-picker>
                        <span class="floating-label fit-yg-color fit-floating-label">Date of Birth*</span>
                      </div>
                      <div class="form-group col-6 col-md-6">
                        <input type="number" OnlyPositiveNumbers numericType="number" class="form-control col-12"
                          name="phoneNumber" autocomplete="off" [(ngModel)]="profile.phoneNumber"
                          [readonly]="isReadOnly" minlength="10">
                        <span class="floating-label fit-yg-color fit-floating-label">Phone number*</span>
                      </div>
                      <div class="form-group col-12">
                        <input type="text" class="form-control col-12" name="email" autocomplete="off"
                          [(ngModel)]="profile.trainerUserName" [readonly]="true" minlength="10">
                        <span class="floating-label fit-yg-color fit-floating-label">Email*</span>
                      </div>
                      <div class="form-group col-12">
                        <ng-multiselect-dropdown class="form-control col-12 fitness-select-category"
                        [placeholder]="'Categories*'" name="categories"
                        [settings]="dropdownSettings"
                        [data]="categories"
                        [(ngModel)]="userselectedCategories" (onSelect)="onCategorySelect($event)" [disabled]="disabled"
                        (onDeSelect)="onCategoryDeselect($event)"
                      >
                      </ng-multiselect-dropdown>
                        <span class="floating-label fit-yg-color fit-floating-label">Category*</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="form-group col-12 col-md-12">
                        <input type="text" autocomplete="off" class="form-control col-12" id="address" name="address"
                          [(ngModel)]="addressfields.address" #address="ngModel" [readonly]="isReadOnly" minlength="1"
                          maxlength="110" ngx-google-places-autocomplete [options]='googlePlacesOptions'
                          #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)">

                        <span class="floating-label fit-yg-color fit-floating-label">Address*</span>
                        <div *ngIf="address.invalid && (address.dirty || address.touched)" class="alert alert-danger">

                          <div *ngIf="address.errors?.minlength">
                            Address should have minimum 1 character
                          </div>
                          <div *ngIf="address.errors?.maxlength">
                            Address should have maximum 40 character
                          </div>
                        </div>
                      </div>
                      <div class="form-group col-12 col-md-6 ">
                        <input type="text" class="form-control col-12" id="city" name="city" autocomplete="off"
                          [(ngModel)]="addressfields.city" #city="ngModel" minlength="1" maxlength="20"
                          [readonly]="isReadOnly" pattern="^[A-Za-z ]*[A-Za-z][A-Za-z ]*$">
                        <span class="floating-label fit-yg-color fit-floating-label">City*</span>
                        <!-- <span class="floating-label">City</span> -->
                        <div *ngIf="city.invalid && (city.dirty || city.touched)" class="alert alert-danger">
                          <!-- <div class="error_alert_msg error_alet_msg_show">
                                                All fields are required.
                                              </div> -->
                          <!-- <div *ngIf="city.errors?.required">
                                                            City is mandatory
                                                        </div> -->
                          <div *ngIf="city.errors?.minlength">
                            City should have minimum 1 character
                          </div>
                          <div *ngIf="city.errors?.pattern">
                            City should only contain alphabets
                          </div>
                        </div>
                      </div>
                      <div class="form-group col-12 col-md-6">
                        <input type="text" class="form-control col-12" id="state" name="state" autocomplete="off"
                          [(ngModel)]="addressfields.state" #state="ngModel" minlength="1" maxlength="40"
                          [readonly]="isReadOnly" pattern="^[A-Za-z ]*[A-Za-z][A-Za-z ]*$">
                        <span class="floating-label fit-yg-color fit-floating-label">State*</span>
                        <div *ngIf="state.invalid && (state.dirty || state.touched)" class="alert alert-danger">
                          <div *ngIf="state.errors?.minlength">
                            State should have minimum 1 character
                          </div>
                          <div *ngIf="state.errors?.pattern">
                            State should only contain alphabets
                          </div>
                        </div>
                      </div>
                      <div class="form-group col-12 col-md-6">
                        <input type="text" class="form-control col-12" id="country" name="country" autocomplete="off"
                          [(ngModel)]="addressfields.country" #country="ngModel" minlength="1" maxlength="40"
                          [readonly]="isReadOnly" pattern="^[A-Za-z ]*[A-Za-z][A-Za-z ]*$">
                        <span class="floating-label fit-yg-color fit-floating-label">Country*</span>
                        <div *ngIf="country.invalid && (country.dirty || country.touched)" class="alert alert-danger">
                          <!-- <div *ngIf="country.errors?.required">
                                                            Country is mandatory
                                                        </div> -->
                          <div *ngIf="country.errors?.minlength">
                            Country should have minimum 1 character
                          </div>
                          <div *ngIf="country.errors?.pattern">
                            Country should only contain alphabets
                          </div>
                        </div>
                      </div>
                      <div class="form-group col-12 col-md-6">
                        <input type="text" class="form-control col-12" name="pincode" autocomplete="off" id="pincode"
                          [(ngModel)]="addressfields.pincode" #pincode="ngModel" maxlength="9" [readonly]="isReadOnly"
                          pattern="^(0|[1-9][0-9]*)$">
                        <span class="floating-label fit-yg-color fit-floating-label">Pincode*</span>
                        <!-- <span class="floating-label">Pincode</span> -->
                        <div *ngIf="pincode.invalid && (pincode.dirty || pincode.touched)" class="alert alert-danger">
                          <!-- <div *ngIf="pincode.errors?.required">
                                                            Pincode is mandatory
                                                        </div> -->
                          <div *ngIf="pincode.errors?.maxlength">
                            Pincode should have maximum 9 Numerics
                          </div>
                          <div *ngIf="pincode.errors?.pattern">
                            Pincode should only contain Numerics
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>


        </div>
      </div>
    </div>
    <div class="fit-width-profile mx-auto">
      <div class="row">
        <div class="col-md-3 col-12 prof-menu-btn-grp text-center my-auto">
          <div class="row">
            <div class="col-md-12 my-2 category-switch-btn" *ngIf="isFitness">
              <div class="col-md-7 mx-auto catgry-btn py-3" (click)="FilterData('Bodybuilder')" [ngClass]="{'category_selection':isFitnessSelected}"
                style=" cursor: pointer;"> <span><img class="category-switch-icon-1 fit-cat-img-grp"
                    src="assets/images/icons/fitness.png" alt=""></span>
                <div class="col fit-cat-txt-grp fit-yg-color font-weight-bold text-left" style="margin-left: 24px;">
                  Fitness</div>
              </div>
            </div>
            <div class="col-md-12 my-2 category-switch-btn" *ngIf="isYoga">
              <div class="col-md-7 mx-auto catgry-btn py-3" (click)="FilterData('Yoga')" [ngClass]="{'category_selection':isYogaSelected}"
                style=" cursor: pointer;"> <span><img class="category-switch-icon-2 fit-cat-img-grp"
                    src="assets/images/icons/yoga.png" alt=""></span>
                <div class="col fit-cat-txt-grp fit-yg-color font-weight-bold text-left" style="margin-left: 24px;">Yoga
                </div>
              </div>
            </div>
            <div class="col-md-12 my-2 category-switch-btn" *ngIf="isZumba">
              <div class="col-md-7 mx-auto catgry-btn py-3" [ngClass]="{'category_selection':isZumbaSelected}"
                style=" cursor: pointer;"> <span><img class="category-switch-icon-3 fit-cat-img-grp"
                    src="assets/images/icons/zumba.png" alt=""></span>
                <div class="col fit-cat-txt-grp fit-yg-color font-weight-bold text-left" style="margin-left: 24px;">
                  Zumba</div>
              </div>

            </div>
          </div>
        </div>
        <div class="col-md-9 col-12 category-content">
          <div class="category-video prof-intro-video col-md-12 mx-auto ">
            <!-- <div class="row">
                        <div class="d-flex prof-scroll">
                            <div class="col-md-3 my-2">
                                <div class="col-md-12 mx-auto catgry-btn btn">bt1</div>
                            </div>
                            <div class="col-md-3 my-2">
                                <div class="col-md-12 mx-auto catgry-btn btn">bt1</div>
                            </div>
                            <div class="col-md-3 my-2">
                                <div class="col-md-12 mx-auto catgry-btn btn">bt1</div>
                            </div>
                            <div class="col-md-3 my-2">
                                <div class="col-md-12 mx-auto catgry-btn btn">bt1</div>
                            </div>
                            <div class="col-md-3 my-2">
                                <div class="col-md-12 mx-auto catgry-btn btn">bt1</div>
                            </div>
                            <div class="col-md-3 my-2">
                                <div class="col-md-12 mx-auto catgry-btn btn">bt1</div>
                            </div>
                        </div>
                    </div> -->

            <div class="fit-profile-tabs">
              <div class="dropdown dropdown-cat-switchgrp">
                <div class="dropdown-toggle category-switch-icon-toggle" type="button" id="dropdownMenuButton"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <img class="category-switch-icon" src="https://picsum.photos/30/30" alt="">
                </div>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item" href="#"><img class="category-switch-icon-1 fit-cat-img-grp"
                      src="https://picsum.photos/30/30" alt=""></a>
                  <a class="dropdown-item" href="#"><img class="category-switch-icon-2 fit-cat-img-grp"
                      src="https://picsum.photos/30/30" alt=""></a>
                  <a class="dropdown-item" href="#"><img class="category-switch-icon-3 fit-cat-img-grp"
                      src="https://picsum.photos/30/30" alt=""></a>
                </div>
              </div>
              <tabset class="personal-details-tabs">
                <tab heading="Intro Video" id="tab1">
                  <div class="category-video prof-intro-video mt-3 mx-auto text-center fit-overflow-height"
                    *ngIf="isFitnessSelected">
                    <!-- <iframe class="col-md-10 px-0" src="https://www.youtube.com/embed/3qjoXDTuyOE?portrait=0&amp;color=black" frameborder="1" style="border: 1px solid black;border-radius: 7px; box-shadow: 0 0px 20px rgba(154, 246, 123, 0.3); height: 330px;" allowfullscreen="">
                                </iframe> -->
                    <div class="col-md-10 px-0 mx-auto">
                      <video style="border-radius: 5px;  width: auto; max-width: 535px;;border-radius: 5px"
                        onloadedmetadata="this.muted=true" attr.src="{{ videoUrl }}" width="445px" height="auto"
                        muted="" autoplay="" controls controlsList="nodownload">
                        Your browser does not support HTML5 video.
                      </video>
                    </div>
                    <div class="file_upload col-1 mx-auto">
                      <div>
                        <input type="file" [readonly]="isReadOnly" (change)="selectFile($event,'video',null)"
                          [disabled]="disabled" title="Video">
                        <i class="fas fa-video" style="font-size: 18px;"></i>
                      </div>
                    </div>
                  </div>
                  <div class="category-video prof-intro-video mt-3 mx-auto text-center fit-overflow-height"
                    *ngIf="isYogaSelected">
                    <!-- <iframe class="col-md-10 px-0" src="https://www.youtube.com/embed/3qjoXDTuyOE?portrait=0&amp;color=black" frameborder="1" style="border: 1px solid black;border-radius: 7px; box-shadow: 0 0px 20px rgba(154, 246, 123, 0.3); height: 330px;" allowfullscreen="">
                              </iframe> -->
                    <div class="col-md-10 px-0 mx-auto">
                      <video style="border-radius: 5px;  width: auto; max-width: 535px;border-radius: 5px"
                        onloadedmetadata="this.muted=true" attr.src="{{ yogavideoUrl }}" width="445px" height="auto"
                        muted="" autoplay="" controls controlsList="nodownload">
                        Your browser does not support HTML5 video.
                      </video>
                    </div>
                    <div class="file_upload col-1 mx-auto">
                      <div>
                        <input type="file" [readonly]="isReadOnly" (change)="onYogaFileSelect($event,'yoga_video',null)"
                          [disabled]="disabled" title="Video">
                        <i class="fas fa-video" style="font-size: 18px;"></i>
                      </div>
                    </div>
                  </div>
                </tab>
                <tab heading="Experience">
                  <div class="prof-experiece fit-overflow-height" *ngIf="isFitnessSelected">
                    <form class="form-inline" action="">
                      <form class="form-inline trainer-profile-form col-md-12 px-0" action="">
                        <div class="form-group col-12">

                          <ng-multiselect-dropdown class="form-control col-12"
                          [placeholder]="'Training Since*'"
                          [settings]="dropdownSettings_single"
                          [data]="TrainingSince"
                          [(ngModel)]="SelectedTrainingSince"
                          name="trainingSince"
                          [disabled]="disabled"
                        >
                        </ng-multiselect-dropdown>
                        </div>
                        <div class="form-group col-12">
                          <ng-multiselect-dropdown class="form-control col-12"
                          [placeholder]="'Services Offered*'"
                          [settings]="dropdownSettings"
                          [data]="Services"
                          [(ngModel)]="SelectedServices"
                          name="services"
                          (onSelect)="onServiceSelect($event)"
                          [disabled]="disabled"
                          (onDeSelect)="onServiceDeselect($event)"
                        >
                        </ng-multiselect-dropdown>
                        </div>
                        <div class="col-12">
                          <div class="row pt-4" *ngFor="let service of selectedServicesOffered">
                            <div class="form-group col-12 col-md-6">
                              {{service.name}}

                            </div>
                            <div class="form-group col-12 col-md-6 prof-years-active">
                              <input type="number" 
                                     class="form-input-style col-12"
                                     id="yearsActiveFrom" 
                                     name="{{service.name}}" 
                                     autocomplete="off"
                                     [(ngModel)]="service.yearsActiveFrom" 
                                     [disabled]="disabled"
                                     min="0"
                                     #yearsActiveFrom="ngModel"
                                     required>
                              <span class="floating-label">Years active *</span>
                            </div>
                          </div>
                        </div>



                        <div class="form-group col-12 ">

                          <ng-multiselect-dropdown class="form-control col-12"
                          [placeholder]="'Certificates'"
                          [settings]="dropdownSettings"
                          [data]="Certificates"
                          [(ngModel)]="SelectedCertificates"
                          (onSelect)="onItemSelect($event)"
                          (onDeSelect)="onItemDeselect($event)"
                          name="certificates"
                          [disabled]="disabled"
                        >
                        </ng-multiselect-dropdown>

                          <div class="py-2 col-md-6"
                            *ngFor="let certification of totalCertificatesSelected; let in=index">
                            <div class="fit-prof-btn col-md-12 px-3">

                              <div class="row">
                                <div class="col-md-9 text-left">
                                  <span>{{ certification.name }}</span>
                                </div>
                                <div class="col-md-3" style="text-align: end;">
                                  <span class="cer-file_upload text-right mt-0" style="padding: 2px 11px;">
                                    <input type="file" title=""
                                      (change)="selectFile($event,'certificate',certification.name)"
                                      [disabled]="disabled">
                                    <i class="fas fa-file-upload mx-1" style="font-size: 20px; cursor: pointer;"></i>
                                  </span>
                                  <span>
                                    <i class="fas fa-eye mx-1" style="font-size: 20px; cursor: pointer;"
                                      *ngIf="certification.uploadedCertificateName != ''"
                                      title="{{ certification.uploadedCertificateName }}"
                                      (click)="openViewer(certification.certificateDestinationUploadUrl)"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>

                        <div class="form-group col-12">
                          <ng-multiselect-dropdown class="form-control col-12"
                          [placeholder]="'Are you a nutritionist'"
                          [settings]="dropdownSettings_single_1"
                          [data]="Nutritions"
                          [(ngModel)]="SelectedNutrition"
                          name="Nutrition"
                          (onSelect)="onNutritionSelect('fitness')"
                          (onDeSelect)="onNutritionDesSelect('fitness')"
                          [disabled]="disabled"
                        >
                        </ng-multiselect-dropdown>
                        </div>

                        <div class="form-group col-md-6"
                          *ngIf="isNutritionist.status != '' && isNutritionist.status != 'No'">
                          <div class="fit-prof-btn col-md-12 px-3">

                            <div class="row">
                              <div class="col-md-9 text-left">
                                <span *ngIf="isNutritionist.certificateName != ''">{{ isNutritionist.certificateName
                                  }}</span>
                                <span *ngIf="isNutritionist.certificateName == ''">Upload nutrition certificate</span>
                              </div>
                              <div class="col-md-3" style="text-align: end;">
                                <span class="cer-file_upload text-right mt-0" style="padding: 2px 11px;">
                                  <input type="file" title=""
                                    (change)="selectFile($event,'nutrition_certificate', null)" [disabled]="disabled">
                                  <i class="fas fa-file-upload mx-1" style="font-size: 20px; cursor: pointer;"></i>
                                </span>
                                <span>
                                  <i class="fas fa-eye mx-1" style="font-size: 20px; cursor: pointer;"
                                    *ngIf="isNutritionist.certificateName != ''"
                                    (click)="openViewer(isNutritionist.certificateDestinationUploadUrl)"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="form-group col-12"
                          *ngIf="isNutritionist.status != '' && isNutritionist.status != 'No'">
                          <input type="number" OnlyPositiveNumbers numericType="number" class="form-control col-12"
                            id="nutrionistFrom" name="nutrionistFrom" autocomplete="off"
                            [(ngModel)]="isNutritionist.nutrionistFrom" [disabled]="disabled">
                          <span class="floating-label">Years active *</span>
                        </div>
                      </form>
                    </form>

                  </div>
                  <div class="prof-experiece" *ngIf="isYogaSelected">
                    <form class="form-inline" action="">
                      <form class="form-inline trainer-profile-form col-md-12 px-0" action="">
                        <div class="form-group col-12">

                          <ng-multiselect-dropdown class="form-control col-12"
                          [placeholder]="'Training Since*'"
                          [settings]="dropdownSettings_single"
                          [data]="TrainingSince"
                          [(ngModel)]="Yoga_SelectedTrainingSince"
                          name="trainingSince"
                          [disabled]="disabled"
                        >
                        </ng-multiselect-dropdown>

                        </div>
                        <div class="form-group col-12">
                          <ng-multiselect-dropdown class="form-control col-12"
                          [placeholder]="'Specialization'"
                          [settings]="dropdownSettings"
                          [data]="speciliazedIn"
                          [(ngModel)]="SelectedSpecializedlist"
                          (onSelect)="show()"
                          name="SpeciliazedIn"
                          [disabled]="disabled"
                        >
                        </ng-multiselect-dropdown>
                        </div>
                        <div class="form-group col-12">
                          <ng-multiselect-dropdown class="form-control col-12"
                          [placeholder]="'Services Offered*'"
                          [settings]="dropdownSettings"
                          name="services"
                          [data]="Yoga_Services"
                          [(ngModel)]="Yoga_SelectedServices"
                          (onSelect)="onYogaServiceSelect($event)"
                          (onDeSelect)="onYogaServiceDeselect($event)"
                          [disabled]="disabled"
                        >
                        </ng-multiselect-dropdown>
                        </div>

                        <div class="col-12">
                          <div class="row pt-4" *ngFor="let service of Yoga_selectedServicesOffered">
                            <div class="form-group col-12 col-md-6">
                              {{service.name}}

                            </div>
                            <div class="form-group col-12 col-md-6 prof-years-active">
                              <input type="number" 
                                     class="form-input-style col-12"
                                     id="yearsActiveFrom" 
                                     name="{{service.name}}" 
                                     autocomplete="off"
                                     [(ngModel)]="service.yearsActiveFrom" 
                                     [disabled]="disabled"
                                     min="1" 
                                     required>
                              <span class="floating-label">Years active *</span>

                            </div>
                          </div>
                        </div>



                        <div class="form-group col-12 ">

                          <ng-multiselect-dropdown class="form-control col-12"
                          [placeholder]="'Certificates'"
                          [settings]="dropdownSettings"
                          [data]="Yoga_Certificates"
                          name="certificates"
                          [disabled]="disabled"
                          [(ngModel)]="Yoga_SelectedCertificates"
                          (onSelect)="onYogaItemSelect($event)"
                          (onDeSelect)="onYogaItemDeselect($event)"
                        >
                        </ng-multiselect-dropdown>

                          <div class="py-2 col-md-6"
                            *ngFor="let certification of Yoga_totalCertificatesSelected; let in=index">
                            <div class="fit-prof-btn col-md-12 px-3">

                              <div class="row">
                                <div class="col-md-9 text-left">
                                  <span>{{ certification.name }}</span>
                                </div>
                                <div class="col-md-3" style="text-align: end;">
                                  <span class="cer-file_upload text-right mt-0" style="padding: 2px 11px;">
                                    <input type="file" title=""
                                      (change)="onYogaFileSelect($event,'yoga_certificate',certification.name)"
                                      [disabled]="disabled">
                                    <i class="fas fa-file-upload mx-1" style="font-size: 20px; cursor: pointer;"></i>
                                  </span>
                                  <span>
                                    <i class="fas fa-eye mx-1" style="font-size: 20px; cursor: pointer;"
                                      *ngIf="certification.uploadedCertificateName != ''"
                                      title="{{ certification.uploadedCertificateName }}"
                                      (click)="openViewer(certification.certificateDestinationUploadUrl)"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>

                        <div class="form-group col-12">
                          <ng-multiselect-dropdown  class="form-control col-12"
                          [placeholder]="'Are you a nutritionist'"
                          [settings]="dropdownSettings_single_1"
                          [data]="Nutritions"
                          [(ngModel)]="Yoga_SelectedNutrition"
                          name="Nutrition"
                          (onSelect)="onNutritionSelect('yoga')"
                          (onDeSelect)="onNutritionDesSelect('yoga')"
                          [disabled]="disabled"
                        >
                        </ng-multiselect-dropdown>
                        </div>

                        <div class="form-group col-md-6"
                          *ngIf="isYogaNutritionist.status != '' && isYogaNutritionist.status != 'No'">
                          <div class="fit-prof-btn col-md-12 px-3">

                            <div class="row">
                              <div class="col-md-9 text-left">
                                <span *ngIf="isYogaNutritionist.certificateName != ''">{{
                                  isYogaNutritionist.certificateName }}</span>
                                <span *ngIf="isYogaNutritionist.certificateName == ''">Upload nutrition
                                  certificate</span>
                              </div>
                              <div class="col-md-3" style="text-align: end;">
                                <span class="cer-file_upload text-right mt-0" style="padding: 2px 11px;">
                                  <input type="file" title=""
                                    (change)="onYogaFileSelect($event,'yoga_nutrition_certificate', null)"
                                    [disabled]="disabled">
                                  <i class="fas fa-file-upload mx-1" style="font-size: 20px; cursor: pointer;"></i>
                                </span>
                                <span>
                                  <i class="fas fa-eye mx-1" style="font-size: 20px; cursor: pointer;"
                                    *ngIf="isYogaNutritionist.certificateName != ''"
                                    (click)="openViewer(isYogaNutritionist.certificateDestinationUploadUrl)"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="form-group col-12"
                          *ngIf="isYogaNutritionist.status != '' && isYogaNutritionist.status != 'No'">
                          <input type="number" OnlyPositiveNumbers numericType="number" class="form-control col-12"
                            id="nutrionistFrom" name="nutrionistFrom" autocomplete="off"
                            [(ngModel)]="isYogaNutritionist.nutrionistFrom" [disabled]="disabled">
                          <span class="floating-label">Years active *</span>
                        </div>
                      </form>
                    </form>

                  </div>
                </tab>

                <tab heading="Social Connect">
                  <div class="social-connect mt-3 fit-overflow-height">

                    <form class="form-inline trainer-profile-form mysocial-connect-style" action="">
                      <div class="form-group col-12 mt-3 field-social-icons">

                        <input type="text" class="form-control col-12" autocomplete="off"
                          [(ngModel)]="website.sourceUrl" [readonly]="isReadOnly" [disabled]="disabled"
                          name="websiteUrl" required>
                        <i class="fas fa-globe"></i>
                        <span class="floating-label">Website</span>
                      </div>
                      <div class="form-group col-12 field-social-icons">

                        <input type="text" class="form-control col-12" autocomplete="off"
                          [(ngModel)]="facebook.sourceUrl" name="facebookId" [disabled]="disabled" required>
                        <i class="fab fa-facebook"></i>
                        <span class="floating-label">Facebook</span>
                      </div>
                      <div class="form-group col-12 field-social-icons">

                        <input type="text" class="form-control col-12" autocomplete="off"
                          [(ngModel)]="twitter.sourceUrl" name="twitterId" [disabled]="disabled" required>
                        <i class="fab fa-twitter-square"></i>
                        <span class="floating-label">Twitter</span>
                      </div>
                      <div class="form-group col-12 field-social-icons">

                        <input type="text" class="form-control col-12" autocomplete="off"
                          [(ngModel)]="instagram.sourceUrl" name="instagramId" [disabled]="disabled" required>
                        <i class="fab fa-instagram"></i>
                        <span class="floating-label">Instagram</span>
                      </div>

                    </form>

                  </div>
                </tab>
                <tab heading="About">
                  <div class="about fit-overflow-height" *ngIf="isFitnessSelected">
                    <div class="form-group col-12 mt-3"
                      style="margin-top: 14px; border:1px solid rgba(255, 255, 255, 0.514); border-radius: 15px;">
                      <textarea class="form-control col-12 textarea-check " [(ngModel)]="profile.about" name="about"
                        style="height: 122px; border: solid 2px #fff0;" [readonly]="isReadOnly" #aboutU="ngModel"
                        minlength="20" maxlength="800">About*</textarea>
                      <span class="floating-label" style="top: -22px;">About*</span>
                      <div *ngIf="aboutU.invalid && (aboutU.dirty || aboutU.touched)"
                        class="alert alert-danger-textarea">
                        <!-- <div *ngIf="aboutU.errors?.required">
                                                                About is mandatory
                                                              </div> -->
                        <div *ngIf="aboutU.errors?.minlength">
                          About should have minimum 20 character
                        </div>
                        <div *ngIf="aboutU.errors?.maxlength">
                          About should have maximum 800 character
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-12"
                      style="margin-top: 14px; border:1px solid rgba(255, 255, 255, 0.514); border-radius: 15px;">
                      <textarea class="form-control col-12 textarea-check " [(ngModel)]="achievement.achievement"
                        name="achievements" [disabled]="disabled" style="height: 122px; border: solid 2px #fff0;"
                        required>Achievements</textarea>
                      <span class="floating-label" style="top: -24px;">Achievements</span>
                    </div>
                  </div>
                  <div class="about fit-overflow-height" *ngIf="isYogaSelected">
                    <div class="form-group col-12 mt-3"
                      style="margin-top: 14px; border:1px solid rgba(255, 255, 255, 0.514); border-radius: 15px;">
                      <textarea class="form-control col-12 textarea-check " [(ngModel)]="yogaProfile.about" name="about"
                        style="height: 122px; border: solid 2px #fff0;" [readonly]="isReadOnly" #aboutU="ngModel"
                        minlength="20" maxlength="800">About*</textarea>
                      <span class="floating-label" style="top: -22px;">About*</span>
                      <div *ngIf="aboutU.invalid && (aboutU.dirty || aboutU.touched)"
                        class="alert alert-danger-textarea">
                        <!-- <div *ngIf="aboutU.errors?.required">
                                                              About is mandatory
                                                            </div> -->
                        <div *ngIf="aboutU.errors?.minlength">
                          About should have minimum 20 character
                        </div>
                        <div *ngIf="aboutU.errors?.maxlength">
                          About should have maximum 800 character
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-12"
                      style="margin-top: 14px; border:1px solid rgba(255, 255, 255, 0.514); border-radius: 15px;">
                      <textarea class="form-control col-12 textarea-check " [(ngModel)]="yoga_achievement.achievement"
                        name="achievements" [disabled]="disabled" style="height: 122px; border: solid 2px #fff0;"
                        required>Achievements</textarea>
                      <span class="floating-label" style="top: -24px;">Achievements</span>
                    </div>
                  </div>
                </tab>
              </tabset>
            </div>
          </div>
          <!-- <div class="col-md-12 col-12">
                    <div class="col-md-8 mx-auto">
                        <div class="row">
                            <div class="category-video prof-intro-video ">
                                <iframe src="https://www.youtube.com/embed/3qjoXDTuyOE?portrait=0&amp;color=black" frameborder="1" style="border: 1px solid black;border-radius: 7px; box-shadow: 0 0px 20px rgba(154, 246, 123, 0.3);" allowfullscreen="">
                                </iframe>
                            </div>
                            
                            <div class="prof-experiece">
                                experience
                            </div>
                            <div class="social-connect">
                                social-connect
                            </div>
                            <div class="about">
                                About
                            </div>
                        </div>
                    </div>                  
                </div> -->
        </div>



      </div>
    </div>

    <ng-container *ngIf="showAdminComments">
      <div class="video-popup__wrapper">
        <div class="video-pop zoomIn">

          <div class="program_event-body d-flex justify-content-center">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12">
                  <div class="add_program_duration">
                    <div class="col-12">
                      <div class="row">
                        <div class="col"  style="text-align: center">
                          <b>Comments</b>
                        </div>
                      </div>
                      <div class="row">

                        <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                          <div style="display: grid"><b>Created by</b> </div>
                        </div>
                        <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                          <div style="display: grid"><b>Date</b> </div>
                        </div>

                        <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                          <div style="display: grid"><b>Status</b> </div>
                        </div>

                        <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                          <div style="display: grid"><b>Comment</b>
                          </div>
                        </div>

                      </div>

                      <div class="row" *ngFor="let comment of adminComments">
                        <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                          <div style="display: grid"><span>{{ comment.updatedBy }}</span> </div>
                        </div>

                        <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                          <div style="display: grid"><span>{{ comment.updateDate | date }} </span> </div>
                        </div>

                        <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                          <div style="display: grid"><span>{{ comment.action }} </span> </div>
                        </div>

                        <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                          <div style="display: grid"><span>{{ comment.comment }}</span>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="d-flex col-12 justify-content-end">
                      <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
                        (click)="closePopup()">
                        Ok
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

</section>