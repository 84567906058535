import { Component, OnInit, Input, ViewChild, OnDestroy, Renderer2, ɵConsole, NgZone, Inject, } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { UserLoginService } from "../service/user-login.service";
import { LoggedInCallback, CognitoUtil } from "../service/cognito.service";
import { AppGlobals } from '../app.globals';
import { DBService } from '../service/db.service';
import { ToastrService } from 'ngx-toastr';
import { Integer } from 'aws-sdk/clients/lambda';
import { AWSService } from '../service/aws.service';
import { FlexAlignStyleBuilder } from '@angular/flex-layout';
import { EmbedVideoService } from 'ngx-embed-video';
import moment from 'moment';
import { range } from "lodash";
import { DatePipe } from '@angular/common';
import { ThemeService } from 'ng2-charts';
import { TwilioDBService } from '../service/twilio.dbservice';
import { isThisQuarter } from 'date-fns';
import { IMyDpOptions } from 'mydatepicker';
import { YogaDBService } from '../service/YogaDBService';
import { DOCUMENT } from '@angular/common';
import { TraineeDBService } from '../service/trainee.service';
import ZoomMtgEmbedded from '@zoom/meetingsdk/embedded';
import { log } from 'console';


export class CalendarDay {
  public date: Date;
  public title: string;
  public isPastDate: boolean;
  public isPastMonth: boolean;
  public isToday: boolean;

  public getDateString() {
    return this.date.toISOString().split("T")[0]
  }

  constructor(d: Date) {
    this.date = d;
    this.isPastDate = d.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);
    this.isPastMonth = d.getMonth() < new Date().getMonth();
    this.isToday = d.setHours(0, 0, 0, 0) == new Date().setHours(0, 0, 0, 0);
  }

}
export class IMyDateModel {
  date: IMyDate;
  jsdate: Date;
  formatted: string;
  epoc: number;
}

export class IMyDate {
  year: number;
  month: number;
  day: number;
}
export interface CalendarDate {
  mDate: moment.Moment;
  selected?: boolean;
  today?: boolean;
}
@Component({
  selector: 'app-call-scheduler',
  templateUrl: './call-scheduler.component.html',
  styleUrls: ['./call-scheduler.component.css']
})
export class CallSchedulerComponent implements OnInit {

  // Vars
  userID: string;

  daysdata: any;
  @Input() imgUrl: string = "assets/images/uploadimg.svg";
  currentCategory: string;
  currentGender: string;
  currentLogged: string;
  currentEmail: string;
  // calender: calender;
  // Calendar Vars
  calendarDayNames: string[] = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  // Full calendar sample
  // public CalendarDay[] = [];
  public calendar: CalendarDay[] = [];
  public date: Date;
  public title: string;
  public isPastDate: boolean;
  public monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  public displayMonth: string;
  private monthIndex: number = 0;
  currentClickDate: any;
  currentDate: Date;
  startTime: any;
  endTime: any;
  frequency: number;
  Month: string;
  current_slot_id: string;
  currentSlotId: string;
  currentSchedulerId: string;
  callType: string;
  showAddSlot: boolean = false;
  showEditSlot: boolean = false;
  showGroupCallDetails: boolean = false;
  show1o1CallList: boolean = false;
  showGroupCallList: boolean = false;
  showReschedulePopup: boolean = false;
  showCancelPopup: boolean = false;
  showDeletePopup: boolean = false;
  is1o1: boolean = true;
  isGroup: boolean = false;
  upComming1o1Slots: Object[];
  upCommingGroupCalls: Object[];
  roomName: string;
  public displayYear: number;
  private loggedIn: boolean;
  //daysData:any[];


  myDatePickerOptions: IMyDpOptions = {};

  currentMonth: string = this.monthNames[new Date().getMonth()] + "/" + new Date().getFullYear();
  rescheduledDate: string = "";
  selectedReDate: any;

  callTypes: object[];
  slots: object[];
  timeintervals: object[];
  programs: object[];
  selectedProgram: object[];
  viewProgramName: string = "";
  slot = {
    "slotId": "",
    "startTime": "",
    "endTime": "",
    "status": "",
    "participants": [],
    "roomName": "",
    "roomType": "",
    "maxParticipants": 0,
    "roomStatus": "new",
    "callType": "",
    "participantsBooked": 0,
    "programId": "",
    "programName": "",
    "frequency": {},
    "delay": {},
    "trainerId": "",
    "timezone": "",
    "date": "",
    "startDateTimeInUTC": "",
    "endDateTimeInUTC": "",
    "title": "",
    "externalCallLink": ""
  }

  newslot = {
    "slotId": "",
    "startTime": "",
    "endTime": "",
    "status": "open",
    "participants": [],
    "roomName": "",
    "roomType": "",
    "maxParticipants": 0,
    "roomStatus": "new",
    "callType": "",
    "participantsBooked": 0,
    "programId": "",
    "programName": "",
    "schedulerId": "",
    "hostIdentity": ""
  }

  day = {
    "slots": [],
    "deleteSlotIds":""
  }


  dropdownSettings: any = {};
  closeDropdownSelection = false;
  frequencies: Array<Object> = [];
  delays: Array<Object> = [];
  selectedFrequency = [];
  selectedCallType = [];
  selectedDelay = [];
  numberOfSlots: number;
  isLive: boolean = false;
  maximunParticipants: number;
  cancelType: string;
  dropdownSettings_single: any = {};
  dropdownSettings_single_program: any = {};
  availableSlots: Object[];
  selectedSlot = [];
  selectedEndTime: string = "";
  selectedStartTime = [];
  viewStartTime: string = "";
  timezone: string;
  dateFormat: string;
  availableSlotsOnData: object[];
  redirectRoomType: string;
  disabled: boolean = false;
  participants: string[];
  client = ZoomMtgEmbedded.createClient();

  constructor(private renderer: Renderer2, private awsService: AWSService, public router: Router,
    public route: ActivatedRoute, public userService: UserLoginService, private embedService: EmbedVideoService, private globals: AppGlobals,
    private db_service: DBService, private yoga_db_service: YogaDBService, private toastr: ToastrService, private twilio_dbservice: TwilioDBService, public datepipe: DatePipe, public cognitoUtil: CognitoUtil,
    @Inject(DOCUMENT) document, private ngZone: NgZone ,private traineeService:TraineeDBService) {
    this.userService.isAuthenticated(this);
  }

  ngOnInit() {
    // this.calender = new calender();
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    console.log("Current user time zone:     " + this.timezone)
    this.currentCategory = localStorage.getItem("ProfileCategory")
    this.currentGender = localStorage.getItem("ProfileGender");
    if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-build-male');
    }
    else if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-build-female');
    }
    else if (this.currentCategory == 'Yoga' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-yoga-male');
    }
    else if (this.currentCategory == 'Yoga' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-yoga-female');
    }
    else if (this.currentCategory == 'Zumba' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-zumba-male');
    }
    else if (this.currentCategory == 'Zumba' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-zumba-female');
    }
    // this.currentDate = moment();
    // this.selectedDate = moment(this.currentDate).format('DD/MM/YYYY');
    // this.generateCalendar();
    this.generateCalendarDays(this.monthIndex);

    this.userService.currentUserID.subscribe((val) => {
      if (val != '') {
        this.userID = val;
        this.getSchedulerDataFromServer();
      }
    });
    this.frequencies = [
      // { id: 1, name: '15 minutes' },
      // { id: 2, name: '30 minutes' },
      { id: 3, name: '45 minutes' },
      { id: 4, name: '60 minutes' },
    ];
    this.delays = [
      { id: 1, name: '5 minutes' },
      { id: 2, name: '10 minutes' },
      { id: 3, name: '15 minutes' },
      { id: 4, name: '30 minutes' },
    ];
    this.callTypes = [
      { id: 1, name: '1 o 1' },
      { id: 2, name: 'Live' }
    ];
    this.timeintervals = [
      { id: 1, name: '00:00' },
      { id: 2, name: '00:05' },
      { id: 3, name: '00:10' },
      { id: 4, name: '00:15' },
      { id: 5, name: '00:20' },
      { id: 6, name: '00:25' },
      { id: 7, name: '00:30' },
      { id: 8, name: '00:35' },
      { id: 9, name: '00:40' },
      { id: 10, name: '00:45' },
      { id: 11, name: '00:50' },
      { id: 12, name: '00:55' },
      { id: 13, name: '01:00' },
      { id: 14, name: '01:05' },
      { id: 15, name: '01:10' },
      { id: 16, name: '01:15' },
      { id: 17, name: '01:20' },
      { id: 18, name: '01:25' },
      { id: 19, name: '01:30' },
      { id: 20, name: '01:35' },
      { id: 21, name: '01:40' },
      { id: 22, name: '01:45' },
      { id: 23, name: '01:50' },
      { id: 24, name: '01:55' },
      { id: 25, name: '02:00' },
      { id: 26, name: '02:05' },
      { id: 27, name: '02:10' },
      { id: 28, name: '02:15' },
      { id: 29, name: '02:20' },
      { id: 30, name: '02:25' },
      { id: 31, name: '02:30' },
      { id: 32, name: '02:35' },
      { id: 33, name: '02:40' },
      { id: 34, name: '02:45' },
      { id: 35, name: '02:50' },
      { id: 36, name: '02:55' },
      { id: 37, name: '03:00' },
      { id: 38, name: '03:05' },
      { id: 39, name: '03:10' },
      { id: 40, name: '03:15' },
      { id: 41, name: '03:20' },
      { id: 42, name: '03:25' },
      { id: 43, name: '03:30' },
      { id: 44, name: '03:35' },
      { id: 45, name: '03:40' },
      { id: 46, name: '03:45' },
      { id: 47, name: '03:50' },
      { id: 48, name: '03:55' },
      { id: 49, name: '04:00' },
      { id: 50, name: '04:05' },
      { id: 51, name: '04:10' },
      { id: 52, name: '04:15' },
      { id: 53, name: '04:20' },
      { id: 54, name: '04:25' },
      { id: 55, name: '04:30' },
      { id: 56, name: '04:35' },
      { id: 57, name: '04:40' },
      { id: 58, name: '04:45' },
      { id: 59, name: '04:50' },
      { id: 60, name: '04:55' },
      { id: 61, name: '05:00' },
      { id: 62, name: '05:05' },
      { id: 63, name: '05:10' },
      { id: 64, name: '05:15' },
      { id: 65, name: '05:20' },
      { id: 66, name: '05:25' },
      { id: 67, name: '05:30' },
      { id: 68, name: '05:35' },
      { id: 69, name: '05:40' },
      { id: 70, name: '05:45' },
      { id: 71, name: '05:50' },
      { id: 72, name: '05:55' },
      { id: 73, name: '06:00' },
      { id: 74, name: '06:05' },
      { id: 75, name: '06:10' },
      { id: 76, name: '06:15' },
      { id: 77, name: '06:20' },
      { id: 78, name: '06:25' },
      { id: 79, name: '06:30' },
      { id: 80, name: '06:35' },
      { id: 81, name: '06:40' },
      { id: 82, name: '06:45' },
      { id: 83, name: '06:50' },
      { id: 84, name: '06:55' },
      { id: 85, name: '07:00' },
      { id: 86, name: '07:05' },
      { id: 87, name: '07:10' },
      { id: 88, name: '07:15' },
      { id: 89, name: '07:20' },
      { id: 90, name: '07:25' },
      { id: 91, name: '07:30' },
      { id: 92, name: '07:35' },
      { id: 93, name: '07:40' },
      { id: 94, name: '07:45' },
      { id: 95, name: '07:50' },
      { id: 96, name: '07:55' },
      { id: 97, name: '08:00' },
      { id: 98, name: '08:05' },
      { id: 99, name: '08:10' },
      { id: 100, name: '08:15' },
      { id: 101, name: '08:20' },
      { id: 102, name: '08:25' },
      { id: 103, name: '08:30' },
      { id: 104, name: '08:35' },
      { id: 105, name: '08:40' },
      { id: 106, name: '08:45' },
      { id: 107, name: '08:50' },
      { id: 108, name: '08:55' },
      { id: 109, name: '09:00' },
      { id: 110, name: '09:05' },
      { id: 111, name: '09:10' },
      { id: 112, name: '09:15' },
      { id: 113, name: '09:20' },
      { id: 114, name: '09:25' },
      { id: 115, name: '09:30' },
      { id: 116, name: '09:35' },
      { id: 117, name: '09:40' },
      { id: 118, name: '09:45' },
      { id: 119, name: '09:50' },
      { id: 120, name: '09:55' },
      { id: 121, name: '10:00' },
      { id: 122, name: '10:05' },
      { id: 123, name: '10:10' },
      { id: 124, name: '10:15' },
      { id: 125, name: '10:20' },
      { id: 126, name: '10:25' },
      { id: 127, name: '10:30' },
      { id: 128, name: '10:35' },
      { id: 129, name: '10:40' },
      { id: 130, name: '10:45' },
      { id: 131, name: '10:50' },
      { id: 132, name: '10:55' },
      { id: 133, name: '11:00' },
      { id: 134, name: '11:05' },
      { id: 135, name: '11:10' },
      { id: 136, name: '11:15' },
      { id: 137, name: '11:20' },
      { id: 138, name: '11:25' },
      { id: 139, name: '11:30' },
      { id: 140, name: '11:35' },
      { id: 141, name: '11:40' },
      { id: 142, name: '11:45' },
      { id: 143, name: '11:50' },
      { id: 144, name: '11:55' },
      { id: 145, name: '12:00' },
      { id: 146, name: '12:05' },
      { id: 147, name: '12:10' },
      { id: 148, name: '12:15' },
      { id: 149, name: '12:20' },
      { id: 150, name: '12:25' },
      { id: 151, name: '12:30' },
      { id: 152, name: '12:35' },
      { id: 153, name: '12:40' },
      { id: 154, name: '12:45' },
      { id: 155, name: '12:50' },
      { id: 156, name: '12:55' },
      { id: 157, name: '13:00' },
      { id: 158, name: '13:05' },
      { id: 159, name: '13:10' },
      { id: 160, name: '13:15' },
      { id: 161, name: '13:20' },
      { id: 162, name: '13:25' },
      { id: 163, name: '13:30' },
      { id: 164, name: '13:35' },
      { id: 165, name: '13:40' },
      { id: 166, name: '13:45' },
      { id: 167, name: '13:50' },
      { id: 168, name: '13:55' },
      { id: 169, name: '14:00' },
      { id: 170, name: '14:05' },
      { id: 171, name: '14:10' },
      { id: 172, name: '14:15' },
      { id: 173, name: '14:20' },
      { id: 174, name: '14:25' },
      { id: 175, name: '14:30' },
      { id: 176, name: '14:35' },
      { id: 177, name: '14:40' },
      { id: 178, name: '14:45' },
      { id: 179, name: '14:50' },
      { id: 180, name: '14:55' },
      { id: 181, name: '15:00' },
      { id: 182, name: '15:05' },
      { id: 183, name: '15:10' },
      { id: 184, name: '15:15' },
      { id: 185, name: '15:20' },
      { id: 186, name: '15:25' },
      { id: 187, name: '15:30' },
      { id: 188, name: '15:35' },
      { id: 189, name: '15:40' },
      { id: 190, name: '15:45' },
      { id: 191, name: '15:50' },
      { id: 192, name: '15:55' },
      { id: 193, name: '16:00' },
      { id: 194, name: '16:05' },
      { id: 195, name: '16:10' },
      { id: 196, name: '16:15' },
      { id: 197, name: '16:20' },
      { id: 198, name: '16:25' },
      { id: 199, name: '16:30' },
      { id: 200, name: '16:35' },
      { id: 201, name: '16:40' },
      { id: 202, name: '16:45' },
      { id: 203, name: '16:50' },
      { id: 204, name: '16:55' },
      { id: 205, name: '17:00' },
      { id: 206, name: '17:05' },
      { id: 207, name: '17:10' },
      { id: 208, name: '17:15' },
      { id: 209, name: '17:20' },
      { id: 210, name: '17:25' },
      { id: 211, name: '17:30' },
      { id: 212, name: '17:35' },
      { id: 213, name: '17:40' },
      { id: 214, name: '17:45' },
      { id: 215, name: '17:50' },
      { id: 216, name: '17:55' },
      { id: 217, name: '18:00' },
      { id: 218, name: '18:05' },
      { id: 219, name: '18:10' },
      { id: 220, name: '18:15' },
      { id: 221, name: '18:20' },
      { id: 222, name: '18:25' },
      { id: 223, name: '18:30' },
      { id: 224, name: '18:35' },
      { id: 225, name: '18:40' },
      { id: 226, name: '18:45' },
      { id: 227, name: '18:50' },
      { id: 228, name: '18:55' },
      { id: 229, name: '19:00' },
      { id: 230, name: '19:05' },
      { id: 231, name: '19:10' },
      { id: 232, name: '19:15' },
      { id: 233, name: '19:20' },
      { id: 234, name: '19:25' },
      { id: 235, name: '19:30' },
      { id: 236, name: '19:35' },
      { id: 237, name: '19:40' },
      { id: 238, name: '19:45' },
      { id: 239, name: '19:50' },
      { id: 240, name: '19:55' },
      { id: 241, name: '20:00' },
      { id: 242, name: '20:05' },
      { id: 243, name: '20:10' },
      { id: 244, name: '20:15' },
      { id: 245, name: '20:20' },
      { id: 246, name: '20:25' },
      { id: 247, name: '20:30' },
      { id: 248, name: '20:35' },
      { id: 249, name: '20:40' },
      { id: 250, name: '20:45' },
      { id: 251, name: '20:50' },
      { id: 252, name: '20:55' },
      { id: 253, name: '21:00' },
      { id: 254, name: '21:05' },
      { id: 255, name: '21:10' },
      { id: 256, name: '21:15' },
      { id: 257, name: '21:20' },
      { id: 258, name: '21:25' },
      { id: 259, name: '21:30' },
      { id: 260, name: '21:35' },
      { id: 261, name: '21:40' },
      { id: 262, name: '21:45' },
      { id: 263, name: '21:50' },
      { id: 264, name: '21:55' },
      { id: 265, name: '22:00' },
      { id: 266, name: '22:05' },
      { id: 267, name: '22:10' },
      { id: 268, name: '22:15' },
      { id: 269, name: '22:20' },
      { id: 270, name: '22:25' },
      { id: 271, name: '22:30' },
      { id: 272, name: '22:35' },
      { id: 273, name: '22:40' },
      { id: 274, name: '22:45' },
      { id: 275, name: '22:50' },
      { id: 276, name: '22:55' },
      { id: 277, name: '23:00' },
      { id: 278, name: '23:05' },
      { id: 279, name: '23:10' },
      { id: 280, name: '23:15' },
      { id: 281, name: '23:23' },
      { id: 282, name: '23:25' },
      { id: 283, name: '23:30' },
      { id: 284, name: '23:35' },
      { id: 285, name: '23:40' },
      { id: 286, name: '23:45' },
      { id: 287, name: '23:50' },
      { id: 288, name: '23:55' }
    ];
    this.dropdownSettings = {
      singleSelection: true,
      enableCheckAll: false,
      idField: 'id',
      textField: 'name',
      allowSearchFilter: true,
      itemsShowLimit: 1,
      closeDropDownOnSelection: this.closeDropdownSelection
    };
    this.dropdownSettings_single = {
      singleSelection: true,
      enableCheckAll: false,
      idField: 'slotId',
      textField: 'slot',
      allowSearchFilter: true,
      itemsShowLimit: 1,
      closeDropDownOnSelection: this.closeDropdownSelection
    };

    this.dropdownSettings_single_program = {
      singleSelection: true,
      enableCheckAll: false,
      idField: 'programId',
      textField: 'programName',
      allowSearchFilter: true,
      itemsShowLimit: 1,
      closeDropDownOnSelection: this.closeDropdownSelection
    };
    if (this.timezone.includes("asia") || this.timezone.includes("Asia")) {
      this.myDatePickerOptions = {
        todayBtnTxt: 'Today',
        dateFormat: 'dd-mm-yyyy',
        firstDayOfWeek: 'mo',
        sunHighlight: true,
        openSelectorOnInputClick: true,
        editableDateField: false,
        inline: false
      }
      this.dateFormat = "dd-MM-yyyy";
    } else {
      this.myDatePickerOptions = {
        todayBtnTxt: 'Today',
        dateFormat: 'mm-dd-yyyy',
        firstDayOfWeek: 'mo',
        sunHighlight: true,
        openSelectorOnInputClick: true,
        editableDateField: false,
        inline: false
      }
      this.dateFormat = "MM-dd-yyyy";
    }
  }
  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'bg-img-build-male');
    this.renderer.removeClass(document.body, 'bg-img-yoga-male');
    this.renderer.removeClass(document.body, 'bg-img-zumba-male');
    this.renderer.removeClass(document.body, 'bg-img-build-female');
    this.renderer.removeClass(document.body, 'bg-img-yoga-female');
    this.renderer.removeClass(document.body, 'bg-img-zumba-female');
    this.renderer.addClass(document.body, 'body-main');
    this.renderer.removeClass(document.body, 'modal-open');
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {

    if (!isLoggedIn) {
      this.router.navigate(['/login']);
    } else {
      // if()
      if (this.loggedIn == true) {
        this.currentEmail = localStorage.getItem('lastAuthUser');
        // if(this.currentEmail != undefined)
        this.getProfileDataFromServer();
      }
      else {
        // this.currentEmail = localStorage.getItem('CognitoIdentityServiceProvider.2io8v4r253hugt5ndbjv9shkfp.LastAuthUser');
        this.currentEmail = this.cognitoUtil.getCurrentUser().getUsername();
        if (this.currentEmail == undefined || this.currentEmail == '' || this.currentEmail == null || this.currentEmail.includes("Google") || this.currentEmail.includes("google")) {
          this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
          if (this.currentEmail == undefined)
            this.currentEmail = this.cognitoUtil.session.idToken.payload.email;
        }
        // this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
        this.getProfileDataFromServer();
      }
    }
  }
  getProfileDataFromServer() {
    var index = '/profile/';
    if (this.currentCategory == 'Bodybuilder') {
      this.db_service.getDataFromServer(this.currentEmail, index).subscribe(
        response => {
           console.log('Data Retrived succesfully, from profile.', response);
          response = response.data[0];
          this.userID = response.trainerId;
          this.getSchedulerDataFromServer();
        },
        error => {
          console.log("An error has occurred while retriving profile data.");
        }
      )
    } else {
      this.yoga_db_service.getDataFromServer(this.currentEmail, index).subscribe(
        response => {
           console.log('Data Retrived succesfully.', response);
          response = response.data[0];
          this.userID = response.trainerId;
          this.getSchedulerDataFromServer();
        },
        error => {
          console.log("An error has occurred while retriving profile data.");
        }
      )
    }
  }

  private generateCalendarDays(monthIndex: number): void {
    // we reset our calendar
    this.calendar = [];

    // we set the date
    let day: Date = new Date(new Date().setMonth(new Date().getMonth() + monthIndex));

    // set the dispaly month for UI
    this.displayMonth = this.monthNames[day.getMonth()];
    this.displayYear = day.getFullYear();

    let startingDateOfCalendar = this.getStartDateForCalendar(day);

    let dateToAdd = startingDateOfCalendar;

    for (var i = 0; i < 42; i++) {
      this.calendar.push(new CalendarDay(new Date(dateToAdd)));
      dateToAdd = new Date(dateToAdd.setDate(dateToAdd.getDate() + 1));
    }

    this.currentMonth = this.displayMonth + "/" + this.displayYear;
    if (this.userID != undefined)
      this.getSchedulerDataFromServer();

  }

  private getStartDateForCalendar(selectedDate: Date) {
    // for the day we selected let's get the previous month last day
    let lastDayOfPreviousMonth = new Date(selectedDate.setDate(0));

    // start by setting the starting date of the calendar same as the last day of previous month
    let startingDateOfCalendar: Date = lastDayOfPreviousMonth;

    // but since we actually want to find the last Monday of previous month
    // we will start going back in days intil we encounter our last Monday of previous month
    if (startingDateOfCalendar.getDay() != 1) {
      do {
        startingDateOfCalendar = new Date(startingDateOfCalendar.setDate(startingDateOfCalendar.getDate() - 1));
      } while (startingDateOfCalendar.getDay() != 1);
    }

    return startingDateOfCalendar;
  }

  public increaseMonth() {
    this.monthIndex++;
    this.generateCalendarDays(this.monthIndex);

  }

  public decreaseMonth() {
    this.monthIndex--
    this.generateCalendarDays(this.monthIndex);

  }

  public setCurrentMonth() {
    this.monthIndex = 0;
    this.generateCalendarDays(this.monthIndex);
  }


  getSchedulerDataFromServer() {
    var index = '/scheduler/slots';
    var data = {
      "trainerId": this.userID,
      "month": this.currentMonth,
      "timezone": this.timezone,
    }
    this.db_service.getData(data, index).subscribe(
      response => {
        console.log('Data Retrived succesfully.', response);
        this.daysdata = response.data;
        // for (var i = 0; i < this.daysdata.length; i++) {
        //   var testDate = new Date(this.daysdata[i]["date"]);
        //   testDate.setHours(0);
        //   testDate.setMinutes(0);
        //   this.daysdata[i]["date"] = testDate;
        // }
      },
      error => {
        console.log("An error has occurred while retriving profile data.");
      }
    )
  }

  compareDates(date1, date2) {
    var d = this.datepipe.transform(date1, 'yyyy-MM-dd');
    var d1 = this.datepipe.transform(date2, 'yyyy-MM-dd')
    // var value = d == d1;
    // console.log(value);
    return d == d1;
  }

  onCallTypeChange(type) {
    if (type == "group") {
      this.is1o1 = false;
      this.isGroup = true;
      var index = '/program/all/';
      var queryparams = {
        "pagesize": 0,
        "pagenumber": 0,
        "pagination": false
      }
      this.db_service.getQueriedData(this.userID, index, queryparams).subscribe(
        response => {
          // console.log('Data Retrived succesfully.', response);
          this.programs = response.data;
        },
        error => {
          console.log("An error has occurred while retriving profile data.");
        }
      )
    } else {
      this.isGroup = false;
      this.selectedProgram = [];
      this.is1o1 = true;
    }
  }

  saveSlot() {  // Validate if start time is selected
    if (this.selectedStartTime == undefined || this.selectedStartTime.length == 0) {
      this.toastr.error('', 'Please select start time.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return;
    }
    if (this.endTime == "") {  // Validate if end time is provided
      this.toastr.error('', 'Please provide valid time intervals.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return;
    }
  // Calculate hours and minutes from the selected start time
    var minutes = this.selectedStartTime[0]["name"].split(':')[1];
    var hours = this.selectedStartTime[0]["name"].split(':')[0];

      // Calculate the start and end time for validation
    var start = moment(this.currentClickDate);
    var returned_endate = moment(start).add(hours, 'hours').add(minutes, 'minutes');
    var end = moment(this.currentDate);


    var duration = moment.duration(returned_endate.diff(end));
    var cal_hours = duration.asHours();

    console.log(cal_hours);
    // if (cal_hours < 24) {
    //   this.toastr.warning('', 'You can schedule calls before 24 hours. ', {
    //     timeOut: 3000,
    //     positionClass: 'toast-top-right',
    //     progressBar: true,
    //     progressAnimation: 'increasing',
    //     tapToDismiss: true,
    //   });
    //   return;
    // }
    if (this.isGroup == true) { 
  // Validate if the program is selected for group scheduling
      if (this.selectedProgram == undefined || this.selectedProgram.length == 0) {
        this.toastr.error('', 'Please select progaram.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        return;
      }
    }  // Validate the number of slots
    if (this.numberOfSlots == undefined || this.numberOfSlots == 0) {
      this.toastr.error('', 'Please enter number of slots.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return;
    }   // Validate the duration of slot
    if (this.selectedFrequency == undefined || this.selectedFrequency.length == 0) {
      this.toastr.error('', 'Please select duration of slot.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return;
    } 
      // Validate the delay
    if (this.selectedDelay.length == 0 || this.selectedDelay == undefined) {
      this.toastr.error('', 'Please select delay.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return;
    }


    var existingDaySlots = [];

    //for (var i = 0; i < this.daysdata.length; i++) {
    //    if (this.compareDates(this.daysdata[i]["date"], this.currentClickDate) == true) {
    if (this.daysdata != undefined) {   // Check for existing slots on the selected date
      var slots = this.daysdata;
      for (let k = 0; k < slots.length; k++) {
        if (this.compareDates(slots[k]["date"], this.currentClickDate) == true) {
          if (slots[k]["status"] != "deleted") {
            var testStartTime = moment(slots[k]["startTime"], "HH:mm:ss a");
            var testEndTime = moment(slots[k]["endTime"], "HH:mm:ss a");

            var selectedStartTime = moment(this.selectedStartTime[0]["name"], "HH:mm:ss a");
            var selectedEndTime = moment(this.selectedEndTime, "HH:mm:ss a");

            if ((selectedStartTime >= testStartTime && selectedStartTime <= testEndTime) || (selectedEndTime >= testStartTime && selectedEndTime <= testEndTime)) {
              this.toastr.error('', 'Please check the existing time intervals.', {
                timeOut: 3000,
                positionClass: 'toast-top-right',
                progressBar: true,
                progressAnimation: 'increasing',
                tapToDismiss: true,
              });
              return;
            }
            existingDaySlots.push(slots[k]);
          }
        }
      }
    }
    //  }
    //  }

    var m = this.monthNames[this.currentClickDate.getMonth()];
    var yyyy = this.currentClickDate.getFullYear();
    this.Month = m + "/" + yyyy;
    // console.log(this.Month);

    for (let i = 0; i < this.slots.length; i++) { // Prepare slots for saving
      this.slots[i]["date"] = this.datepipe.transform(this.currentClickDate, 'yyyy-MM-dd');
      //  this.slots[i]["date"] = this.currentClickDate;
      this.slots[i]["title"] = this.startTime + "-" + this.endTime;
      this.slots[i]["frequency"] = this.selectedFrequency[0];
      this.slots[i]["delay"] = this.selectedDelay[0];
      this.slots[i]["timezone"] = this.timezone;
      this.slots[i]["month"] = this.Month;
      this.slots[i]["category"] = this.currentCategory;
      this.slots[i]["trainerId"] = this.userID;
    }

    var deleteSlotIds = "";
    if (this.isGroup == true) {
      for(let i=0; i<existingDaySlots.length; i++){
      
          if(existingDaySlots[i]["programId"] != "" && existingDaySlots[i]["programId"] == this.slots[0]["programId"]){
           if(deleteSlotIds == "")
           deleteSlotIds = existingDaySlots[i]["slotId"];
           else
           deleteSlotIds = deleteSlotIds +","+existingDaySlots[i]["slotId"];
        }
      }
    }

    console.log("deleteSlotIds :  " + deleteSlotIds);

    this.day.slots = this.slots;
    this.day.deleteSlotIds = deleteSlotIds;
    console.log(this.day);
    this.saveSlotDetails(this.day, 'save')

  }

  saveSlotDetails(data, type) {
    var index = '/scheduler'
    if (this.current_slot_id == undefined) {  // Check if there is no current slot ID (i.e., it's a new slot)
      // data.creationDate = new Date();
      // data.lastUpdatedOn = new Date();
      // data.schedulerId = null; 

    // Save new slot details to the database
      this.db_service.saveDataToDB(data, index).subscribe( 
        response => {
          if (type == 'save') {

            this.toastr.success('', 'Slot details saved successfully', {
              timeOut: 5000,
              positionClass: 'toast-top-right',
              progressBar: true,
              progressAnimation: 'increasing',
              tapToDismiss: true,
            });
             // Prepare and save a notification
            let notificationModel = {};
            notificationModel['templateCode'] = "1o1_N_CALLSCHEDULECRE_129";
            notificationModel['date'] = data.slots[0]['date'];
            notificationModel['trainerId'] = data.slots[0]['trainerId'];
            this.db_service.savenotification(notificationModel).subscribe((retrivedData: any) => {

            })
            // Close the modal and refresh the scheduler data
            this.showAddSlot = false;
            this.renderer.removeClass(document.body, 'modal-open');
            this.getSchedulerDataFromServer();
          } else {  // Show success message for deleting slot details
            this.toastr.error('', 'Slot deleted successfully.', {
              timeOut: 5000,
              positionClass: 'toast-top-right',
              progressBar: true,
              progressAnimation: 'increasing',
              tapToDismiss: true,
            });
            this.getSchedulerDataFromServer();
          }
        },
        error => {
          this.toastr.error('', 'There is an error while saving slot details.', {
            timeOut: 5000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        });
    } else {
       // Update existing slot details
      data.schedulerId = this.current_slot_id;
      this.db_service.updateDatainDB(data, index).subscribe(
        response => {
          if (type == 'save') {
            this.toastr.success('', 'Slot details updated successfully', {
              timeOut: 5000,
              positionClass: 'toast-top-right',
              progressBar: true,
              progressAnimation: 'increasing',
              tapToDismiss: true,
            });
            this.getSchedulerDataFromServer();
          }
        },
        error => {
          this.toastr.error('', 'There is an error while saving slot details.', {
            timeOut: 5000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        });
    }
  }

  onReschedule(slotId) {
  // Find the slot by slotId
  var slot = this.daysdata.find(s => s.slotId == slotId);

  // Extract hours and minutes from the slot's start time
  var minutes = slot.startTime.split(':')[1];
  var hours = slot.startTime.split(':')[0];

  // Set the current date and time
  this.currentDate = new Date();

  // Get the start moment of the current click date
  var start = moment(this.currentClickDate);

  // Calculate the end time by adding the slot's duration to the start time
  var returned_endate = moment(start).add(hours, 'hours').add(minutes, 'minutes');
  var end = moment(this.currentDate);

  // Calculate the duration between the current time and the end time of the slot
  var duration = moment.duration(returned_endate.diff(end));
  var cal_hours = duration.asHours();

  // Log the calculated hours for debugging
  console.log(cal_hours);

  // Check if the slot is within 24 hours
  if (cal_hours < 24) {
    // Show a warning if the slot is within 24 hours
    this.toastr.warning('', 'You can reschedule slot before 24 hours. ', {
      timeOut: 3000,
      positionClass: 'toast-top-right',
      progressBar: true,
      progressAnimation: 'increasing',
      tapToDismiss: true,
    });
    return;
  }

  // Check if the selected date is before the current date
  if (moment(this.currentClickDate).format("YYYY-MM-DD") < moment(this.currentDate).format("YYYY-MM-DD")) {
    // Show a warning if the selected date is before the current date
    this.toastr.warning('', 'You cannot reschedule a call for a previous date. ', {
      timeOut: 3000,
      positionClass: 'toast-top-right',
      progressBar: true,
      progressAnimation: 'increasing',
      tapToDismiss: true,
    });
    return;
  }

  // Show the reschedule popup and hide other elements
  this.showReschedulePopup = true;
  this.show1o1CallList = false;
  this.showGroupCallList = false;

  // Set the current slot ID and clear the selected slot and current date
  this.currentSlotId = slotId;
  this.selectedSlot = [];
  this.currentDate = null;
}

  //get Trainee details
  getTraineeDetails(date, slotId, type) {
    console.log(slotId,type)
    this.currentClickDate = date;
    this.upComming1o1Slots = [];
    this.upCommingGroupCalls = [];
    if (type == "1o1") {
      // for (var i = 0; i < this.daysdata.length; i++) {
      //   if (this.compareDates(this.daysdata[i]["date"], date) == true) {
      //     for (var j = 0; j < this.daysdata[i]["slots"].length; j++) {
      //       if ((this.daysdata[i]["slots"][j]["status"] == "booked" && this.daysdata[i]["slots"][j]["callType"] == type) || this.daysdata[i]["slots"][j]["status"] == "pending" && this.daysdata[i]["slots"][j]["callType"] == type) {
      //         for (var k = 0; k < this.daysdata[i]["slots"][j]["participants"].length; k++) {
      //           var participnt = this.daysdata[i]["slots"][j]["participants"][k];
      //           participnt["slotId"] = this.daysdata[i]["slots"][j]["slotId"];
      //           participnt["startTime"] = this.daysdata[i]["slots"][j]["startTime"];
      //           participnt["endTime"] = this.daysdata[i]["slots"][j]["endTime"];
      //           participnt["status"] = this.daysdata[i]["slots"][j]["status"];
      //           participnt["callType"] = this.daysdata[i]["slots"][j]["callType"];
      //           participnt["schedulerId"] = this.daysdata[i]["schedulerId"];
      //           this.upComming1o1Slots.push(JSON.parse(JSON.stringify(participnt)));
      //         }
      //       }
      //     }
      //   }
      // }

      for (var i = 0; i < this.daysdata.length; i++) {
        if (this.compareDates(this.daysdata[i]["date"], date) == true) {
          if (this.daysdata[i]["slotId"] == slotId) {
            var participnt = this.daysdata[i]["participants"][0];
            participnt["slotId"] = this.daysdata[i]["slotId"];
            participnt["startTime"] = this.daysdata[i]["startTime"];
            participnt["endTime"] = this.daysdata[i]["endTime"];
            participnt["status"] = this.daysdata[i]["status"];
            participnt["callType"] = this.daysdata[i]["callType"];
            this.upComming1o1Slots.push(JSON.parse(JSON.stringify(participnt)));
          }
        }
      }
      this.show1o1CallList = true;
    } else {
      // var schedulerdata = this.daysdata.find(x => x["schedulerId"] == schedulerId);
      this.slot = this.daysdata.find(x => x["slotId"] == slotId);
      // this.slot.schedulerId = schedulerId;
      for (var k = 0; k < this.slot["participants"].length; k++) {
        var participnt = this.slot["participants"][k];
        participnt["slotId"] = slotId;
        participnt["startTime"] = this.slot.startTime;
        participnt["endTime"] = this.slot.endTime;
        participnt["callType"] = this.slot.callType;
        participnt["maxParticipants"] = this.slot.maxParticipants;
        this.upCommingGroupCalls.push(JSON.parse(JSON.stringify(participnt)));
      }
      this.showGroupCallList = true;
    }


  }
  // calculate end time based selected inputs
  calculateEndTime() {
    if (this.selectedStartTime != undefined && this.numberOfSlots != undefined && this.selectedFrequency != undefined && this.selectedDelay != undefined) {
      if (this.selectedStartTime.length != 0 && this.numberOfSlots != 0 && this.selectedFrequency.length != 0 && this.selectedDelay.length != 0) {
        this.startTime = this.selectedStartTime[0]["name"];
        var sTime = moment(this.selectedStartTime[0]["name"], "HH:mm:ss a");
        this.frequency = JSON.parse(this.selectedFrequency[0].name.split(' minutes')[0]);
        var delay = JSON.parse(this.selectedDelay[0].name.split(' minutes')[0]);
        var slotStart = this.selectedStartTime[0]["name"];
        var slotEnd;
        this.slots = [];
        for (let i = 0; i < this.numberOfSlots; i++) {
          slotEnd = moment(slotStart, "HH:mm:ss a").add(this.frequency, 'minutes').format('HH:mm');
          var sT = moment(this.startTime, 'HH:mm');
          var eT = moment(slotEnd, 'HH:mm');
          if (sT.isBefore(eT)) {
            this.newslot.slotId = "1o1-" + Math.floor(1000 + Math.random() * 9000);
            this.newslot.startTime = slotStart;
            this.newslot.endTime = slotEnd;
            this.newslot.hostIdentity = this.userID;
            if (this.isGroup == true) {
              this.newslot.callType = "group";
              var k = 0;
              this.newslot.programId = this.selectedProgram[k]["programId"];
              this.newslot.programName = this.selectedProgram[k]["programName"];
              this.newslot.maxParticipants = 50;
            } else {
              this.newslot.callType = "1o1";
              this.newslot.maxParticipants = 1;
              this.newslot.programId = "";
              this.newslot.programName = "";
            }
            var upnewSlot = JSON.parse(JSON.stringify(this.newslot));
            if (this.slots != undefined) {
              this.slots.push(upnewSlot);
            } else {
              this.slots = Array.of(upnewSlot);
            }
            slotStart = moment(slotEnd, "HH:mm:ss a").add(delay, 'minutes').format('HH:mm');
          } else {
            this.toastr.error('', 'Maximum time should not exceed today.', {
              timeOut: 5000,
              positionClass: 'toast-top-right',
              progressBar: true,
              progressAnimation: 'increasing',
              tapToDismiss: true,
            });
            slotEnd = "";
            return;
          }
        }
        var index = this.timeintervals.findIndex(x => x["name"] == slotEnd);
        this.endTime = slotEnd;
        this.selectedEndTime = this.timeintervals[index]["name"];
      }
    }
  }


  onAddSlot(date) {
    // Set the current click date and the current date
    this.currentClickDate = date;
    this.currentDate = new Date();
    // if (moment(this.currentClickDate).format("YYYY-MM-DD") < moment(this.currentDate).format("YYYY-MM-DD")) {
    //   this.toastr.warning('', 'You cannot add slot to previous date. ', {
    //     timeOut: 3000,
    //     positionClass: 'toast-top-right',
    //     progressBar: true,
    //     progressAnimation: 'increasing',
    //     tapToDismiss: true,
    //   });
    //   return;
    // }

    // if (moment(this.currentClickDate).format("YYYY-MM-DD") == moment(this.currentDate).format("YYYY-MM-DD")) {
    //   this.toastr.warning('', 'You can schedule calls before 24 hours. ', {
    //     timeOut: 3000,
    //     positionClass: 'toast-top-right',
    //     progressBar: true,
    //     progressAnimation: 'increasing',
    //     tapToDismiss: true,
    //   });
    //   return;
    // }
    console.log("clicked")
    // Update the UI to show the add slot form and hide other elements

    this.showGroupCallDetails = false;
    this.showEditSlot = false;
    this.showGroupCallList = false;
    this.show1o1CallList = false;
    this.isGroup = false;
    this.showAddSlot = true;
    this.is1o1 = true;
    this.selectedDelay = [];  // Reset the selected options and number of slots
    this.selectedFrequency = [];
    this.selectedProgram = [];
    this.selectedStartTime = [];
    this.selectedEndTime = "";
    this.numberOfSlots = undefined;
  }
  onEditSlot(date, slotId) {
    this.currentClickDate = date;
    this.currentDate = new Date();
    this.disabled = false;
    //this.currentSchedulerId = schedulerId;
    //var schedulerdata = this.daysdata;
    this.slot = this.daysdata.find(x => x["slotId"] == slotId);
    if (this.slot.callType == "1o1") {
      this.is1o1 = true;
      this.isGroup = false;
      if (moment(this.currentClickDate).format("YYYY-MM-DD") < moment(this.currentDate).format("YYYY-MM-DD")) {
        this.toastr.warning('', 'You cannot edit previous date slot. ', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        return;
      }
    } else {
      this.isGroup = true;
      this.is1o1 = false;
      var index = '/program/all/';
      var queryparams = {
        "pagesize": 0,
        "pagenumber": 0,
        "pagination": false
      }
      this.yoga_db_service.getQueriedData(this.userID, index, queryparams).subscribe(
        response => {
          // console.log('Data Retrived succesfully.', response);
          this.programs = response.data;
          this.selectedProgram = Array.of(this.programs.find(x => x["programId"] == this.slot.programId));
          // this.disabled = true;
        },
        error => {
          console.log("An error has occurred while retriving profile data.");
        }
      )
    }

    this.showAddSlot = false;
    this.showEditSlot = true;
    this.currentSlotId = slotId;
    this.selectedStartTime = Array.of(this.timeintervals.find(x => x["name"] == this.slot.startTime));
    this.selectedEndTime = this.slot.endTime;
  }
  onSlotEditSubmit() {
    var actualStartTime = moment(this.slot.startTime, "HH:mm:ss a");
    var actualEndTime = moment(this.slot.endTime, "HH:mm:ss a");
    var updatedTime = moment(this.selectedStartTime[0]["name"], "HH:mm:ss a");
    if (updatedTime < actualStartTime) {
      this.toastr.warning('', 'Please check time intervals. ', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return;
    }
    if (updatedTime >= actualEndTime) {
      this.toastr.warning('', 'Please check time intervals. ', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return;
    }
    this.slot.startTime = this.selectedStartTime[0]["name"];
    if (this.isGroup == true) {
      this.slot.programName = this.selectedProgram[0]["prograName"];
      this.slot.programId = this.selectedProgram[0]["programId"];
      this.slot.callType = "group";
      this.slot.maxParticipants = 50;
    } else {
      this.slot.callType = "1o1";
      this.slot.maxParticipants = 1;
    }
    // this.slot.schedulerId = this.currentSchedulerId;

    var index = '/scheduler/slot';
    this.db_service.updateDatainDB(this.slot, index).subscribe(
      response => {
        this.toastr.success('', 'Slot updated successfully. ', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        this.showEditSlot = false;
        this.renderer.removeClass(document.body, 'modal-open');
        this.getSchedulerDataFromServer();
      },
      error => {
        this.toastr.error('', 'An error has occurred while updating slot."', {
          timeOut: 5000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      }
    )

  }

  onDateChanged(event: IMyDateModel) {
    var index = "/scheduler/slots/day"
    var selectedDate = this.datepipe.transform(event.jsdate, "yyyy-MM-dd");
    this.rescheduledDate = selectedDate;
    var querydata = {
      "trainerId": this.userID,
      "date": selectedDate,
      "timezone": this.timezone
    };
    this.db_service.getQueriedData("", index, querydata).subscribe(
      response => {
        // console.log('Data Retrived succesfully.', response);
        this.availableSlots = [];
        this.availableSlotsOnData = response.data;
        //  for (let i = 0; i < response.data.length; i++) {
        for (let j = 0; j < this.availableSlotsOnData.length; j++) {
          if (this.availableSlotsOnData[j]["status"] == "open" && this.availableSlotsOnData[j]["participants"].length == 0 && this.availableSlotsOnData[j]["callType"] == "1o1") {
            var Obj = {};
            Obj["slot"] = this.availableSlotsOnData[j]["startTime"] + "-" + this.availableSlotsOnData[j]["endTime"];
            Obj["slotId"] = this.availableSlotsOnData[j]["slotId"];
            var abc = JSON.parse(JSON.stringify(Obj));
            this.availableSlots.push(abc);
          }
        }
        if (this.availableSlotsOnData.length == 0) {
          this.toastr.warning('', 'There is no available slots on selected date. ', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
          return;
        }
        //  }
      })
  }


  // onDateChange() {
  //   var index = "/scheduler/slots/day"
  //   var selectedDate = this.datepipe.transform(this.currentDate, 'yyyy-MM-dd');
  //   var data = {
  //     "trainerId": this.userID,
  //     "date": selectedDate,
  //     "timezone":this.timezone
  //   };
  //   this.db_service.getData(data, index).subscribe(
  //     response => {
  //       // console.log('Data Retrived succesfully.', response);
  //       this.availableSlots = [];
  //       this.availableSlotsOnData = response.data;
  //     //  for (let i = 0; i < response.data.length; i++) {
  //         for (let j = 0; j <  this.availableSlotsOnData.length; j++) {
  //           if ( this.availableSlotsOnData[j]["status"] == "open" && this.availableSlotsOnData[j]["participants"].length == 0 &&  this.availableSlotsOnData[j]["callType"] == "1o1") {
  //             var Obj = {};
  //             Obj["slot"] =  this.availableSlotsOnData[j]["startTime"] + "-" +  this.availableSlotsOnData[j]["endTime"];
  //             Obj["slotId"] =  this.availableSlotsOnData[j]["slotId"] ;
  //             var abc = JSON.parse(JSON.stringify(Obj));
  //             this.availableSlots.push(abc);
  //           }
  //         }
  //     //  }
  //     })
  // }
  onRescheduleSubmit() {

    if (moment(new Date(this.rescheduledDate)).format("YYYY-MM-DD") <= moment(new Date()).format("YYYY-MM-DD")) {
      this.toastr.warning('', 'You can reschedule call for future dates. ', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return;
    }

    if (this.selectedSlot == undefined) {
      this.toastr.error('', 'Please select a slot to reschedule. ', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return;
    }

    if (this.selectedSlot.length == 0) {
      this.toastr.error('', 'Please select a slot to reschedule. ', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return;
    }

    //  var selectedValue = this.selectedSlot[0]["slotId"].split("-");
    var scheduledSlotId = this.selectedSlot[0]["slotId"];
    //var scheduledSchedulerId = selectedValue[2];
    var index = "/scheduler/slots/reschedule"
    var data = {
      //  currentSchedulerId: this.currentSchedulerId,
      currentSlotId: this.currentSlotId,
      // scheduledSchedulerId: scheduledSchedulerId,
      scheduledSlotId: scheduledSlotId
    }
    this.db_service.saveDataToDB(data, index).subscribe(
      response => {
        this.toastr.success('', 'Slot rescheduled successfully', {
          timeOut: 5000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        let notificationModel = {};
        notificationModel['currentSlotId'] = data.currentSlotId;
        notificationModel['scheduledSlotId'] = data.scheduledSlotId;
        notificationModel['templateCode'] = "1o1_N_CALLRESCHEDULEDREMAINDER_134";
        this.db_service.savenotification(notificationModel).subscribe(retrivedData => {
          this.getSchedulerDataFromServer();
          this.showReschedulePopup = !this.showReschedulePopup;
          this.renderer.removeClass(document.body, 'modal-open');
        }, error => {
          this.getSchedulerDataFromServer();
          this.showReschedulePopup = !this.showReschedulePopup;
          this.renderer.removeClass(document.body, 'modal-open');
        })
        // this.getSchedulerDataFromServer();
        // this.showReschedulePopup = !this.showReschedulePopup;
        // this.renderer.removeClass(document.body, 'modal-open');
      },
      error => {
        this.toastr.error('', 'There is an error while rescheduling slot .', {
          timeOut: 5000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      });
  }

 onCancelSlot() {
  // Construct the index path for the slot to be cancelled
  var index = "/scheduler/slots/" + this.currentSlotId;

  // Send a request to update the database (presumably to delete the slot data)
  this.db_service.updateDatainDB("", index).subscribe(
    response => {
      // Refresh the scheduler data from the server
      this.getSchedulerDataFromServer();

      // Close any open popups related to the slot
      this.closePopup();

      // Show a success message indicating the slot has been cancelled
      this.toastr.error('', 'Slot cancelled successfully.', {
        timeOut: 5000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });

      // Prepare and save a notification about the cancelled slot
      let notificationModel = {};
      notificationModel['templateCode'] = "1o1_N_CALLCANCELLEDREMAINDER_133";
      notificationModel['currentSlotId'] = this.currentSlotId;
      this.db_service.savenotification(notificationModel).subscribe((retrivedData: any) => {
        // Handle the response from saving the notification, if needed
      });
    },
    error => {
      // Show an error message if cancelling the slot fails
      this.toastr.error('', 'An error has occurred while cancelling slot.', {
        timeOut: 5000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
  );
}


  onCancelClick(slotId, startTime) {
    console.log(this.startTime,'startTime')
    var minutes = this.slot.startTime.split(':')[1];
    var hours = this.slot.startTime.split(':')[0];

    // var date = moment(this.currentClickDate, "hh:mm:ss A")
    //   .add(hours, 'hours')
    //   .add(minutes, 'minutes');

    var start = moment(this.currentClickDate);

    var returned_endate = moment(start).add(hours, 'hours').add(minutes, 'minutes');
    var end = moment(this.currentDate);


    var duration = moment.duration(returned_endate.diff(end));
    var cal_hours = duration.asHours();

    console.log(cal_hours);
    if (cal_hours < 24) {
      this.toastr.warning('', 'You can cancel slot before 24 hours. ', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return;
    }

    this.currentDate = new Date();
    if (moment(this.currentClickDate).format("YYYY-MM-DD") < moment(this.currentDate).format("YYYY-MM-DD")) {
      this.toastr.warning('', 'You can not cancel call for previous date. ', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return;
    }
    this.showCancelPopup = true;
    this.currentSlotId = slotId;
    //this.currentSchedulerId = schedulerId;
  }

  onJoinRoom(slotId, callType) {   // we can join room by using specific slotid and calltype
    this.currentDate = new Date(); 
    // if (moment(this.currentClickDate).format("YYYY-MM-DD") < moment(this.currentDate).format("YYYY-MM-DD")) {
    //   this.toastr.warning('', 'You can not initiate call for previous date. ', {
    //     timeOut: 3000,
    //     positionClass: 'toast-top-right',
    //     progressBar: true,
    //     progressAnimation: 'increasing',
    //     tapToDismiss: true,
    //   });
    //   return;
    // }

    // if (moment(this.currentClickDate).format("YYYY-MM-DD") > moment(this.currentDate).format("YYYY-MM-DD")) {
    //   this.toastr.warning('', 'You can not initiate call for future date. ', {
    //     timeOut: 3000,
    //     positionClass: 'toast-top-right',
    //     progressBar: true,
    //     progressAnimation: 'increasing',
    //     tapToDismiss: true,
    //   });
    //   return;
    // }

    this.currentSlotId = slotId;  
    //this.currentSchedulerId = schedulerId;
    this.callType = callType;
    this.redirectRoomType = this.callType;
    var index = "/scheduler/slots/";    //ase URL path for fetching scheduler slots from the server

    this.db_service.getDataFromServer(this.currentSlotId, index).subscribe(   // Subscribe to the observable returned by the service, which fetches data from the server

      response => {     // On successful response from the server

        console.log('Data Retrived succesfully.', response);
        var slot = response.data; //
        var meetingDetails = {     // Create an object with meeting details extracted from the slot data
          "signature":slot.signature,
          "meetingNumber":slot.meetingNumber,
          "password":slot.password,
          "sdkKey":slot.sdkKey
        }
        this.redirectToZoomComponent(meetingDetails)  //all the meeting details redirect to zoom comp
        
        // console.log("externalCallLink :   " + slot.externalCallLink)
      //   if(slot.externalCallLink == null || slot.externalCallLink == undefined){
      //   if (slot.roomName == "" || slot.roomName == null) {
      //     this.createRoom();
      //     this.participants = [];
      //     for (var i = 0; i < slot.participants.length; i++) {
      //       this.participants.push(slot.participants[i].trainee_id);
      //     }
      //   } else {
      //     if (slot.roomStatus == "completed") {
      //       this.toastr.success('', 'This room has already completed.', {
      //         timeOut: 5000,
      //         positionClass: 'toast-top-right',
      //         progressBar: true,
      //         progressAnimation: 'increasing',
      //         tapToDismiss: true,
      //       });
      //     } else {
      //       var roomName = this.currentSlotId;
      //       this.generateTwilioToken(roomName, 'trainer_' + this.userID);
      //     }
      //   }
      // }else{
      //   window.open(slot.externalCallLink, "_blank");
      // }
      },
      error => {
        console.log("An error has occurred while retriving profile data.");
      }
    )
  }
  createRoom() {
  var index = "/room";
  var roomName = this.currentSlotId;
  var roomType = "";

  // Determine the room type based on the call type
  if (this.callType == "1o1")
    roomType = "peer2peer";
  else
    roomType = "group";

  // Prepare the data for creating the room
  var data = {
    "name": roomName,
    "type": roomType
  };

  // Set the redirect room type to the current call type
  this.redirectRoomType = this.callType;

  // Call the Twilio service to create a room
  this.twilio_dbservice.createRoom(data, index).subscribe(
    response => {
      // Generate a Twilio token for the trainer
      this.generateTwilioToken(roomName, 'trainer_' + this.userID);

      // Prepare the slot details with the response data from Twilio
      var slotDetails = {
        "slotId": this.currentSlotId,
        // "schedulerId": this.currentSchedulerId,
        "roomName": response.data.uniqueName,
        "roomType": response.data.type,
        "roomStatus": response.data.status,
        "roomSid": response.data.sid
      };

      // Update the slot details
      this.updateSlotDetails(slotDetails);

      // Prepare data for sending push notification to trainees
      let index1 = "/scheduler/notification";
      var data = {
        trainee_id: this.participants
      };

      // Send push notification
      this.db_service.sendPushNotification(data, index1).subscribe((retrivedData: any) => {
        console.log(retrivedData.data.message);
      });
    },
    error => {
      console.log("An error has occurred while creating the room.");
    }
  );
}

  updateSlotDetails(data) {
    var index = '/scheduler/slot';
    this.db_service.updateDatainDB(data, index).subscribe(
      response => {
        console.log("Data updated successfully." + response)
      },
      error => {
        console.log("An error has occurred while retriving profile data.");
      }
    )
  }
  generateTwilioToken(roomName, trainerName) {
    this.roomName = roomName;
    var index = "/token";
    var queryParams = {
      "roomName": roomName,
      "name": trainerName
    }
    this.twilio_dbservice.getToken("", index, queryParams).subscribe(
      response => {
        // console.log('Token generated succesfully.', response);
        // console.log("Token:  " + response.token);
        if (this.redirectRoomType == "1o1")
          this.router.navigate(['twilioVideo', response.token, this.roomName]);
        else
          this.router.navigate(['twilioGroup', response.token, this.roomName]);
      },
      error => {
        console.log("An error has occurred while generating the token.");
      });
  }

  onDeleteSlot() {
    this.showDeletePopup = true;
  }
  onConfirmDelete() {
    var index = "/scheduler/slots/" + this.currentSlotId;
    this.db_service.deleteRecordInDB("", index).subscribe(
      response => {
        this.showEditSlot = false;
        this.showDeletePopup = false;
        this.renderer.removeClass(document.body, 'modal-open');
        let notificationModel = {};
        notificationModel['currentSlotId'] = this.currentSlotId;
        notificationModel['templateCode'] = "1o1_N_CALLDELETEDREMAINDER_132";
        this.db_service.savenotification(notificationModel).subscribe((retrivedData: any) => {
          this.getSchedulerDataFromServer();
        }, error => {
          this.getSchedulerDataFromServer();
        })
        // this.getSchedulerDataFromServer();
        this.toastr.success('', 'Slot deleted successfully.', {
          timeOut: 5000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });


      },
      error => {
        this.toastr.error('', 'An error has occurred while deleting slot."', {
          timeOut: 5000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      }
    )
  }
  onCancellSlot() {
    var index = "/scheduler/slots/" + this.currentSlotId;
    this.db_service.updateDatainDB("", index).subscribe(
      response => {
        this.getSchedulerDataFromServer();
        this.showCancelPopup = false;
        this.closePopup();
        this.renderer.removeClass(document.body, 'modal-open');
        this.toastr.success('', 'Slot cancelled successfully.', {
          timeOut: 5000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        let notificationModel = {};
        notificationModel['templateCode'] = "1o1_N_CALLCANCELLEDREMAINDER_133";
        notificationModel['currentSlotId'] = this.currentSlotId;
        this.db_service.savenotification(notificationModel).subscribe((retrivedData: any) => {

        })
      },
      error => {
        this.toastr.error('', 'An error has occurred while cancelling slot."', {
          timeOut: 5000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      }
    )
  }
  closePopup() {
    this.showAddSlot = false;
    this.showEditSlot = false;
    this.showGroupCallList = false;
    this.show1o1CallList = false;
    this.showReschedulePopup = false;
    this.showCancelPopup = false;
    this.showDeletePopup = false;
    this.disabled = false;
    this.showGroupCallDetails = false;
    this.renderer.removeClass(document.body, 'modal-open');
  }
 onViewSlot(date, slotId) {
  // Set the current click date and current date
  this.currentClickDate = date;
  this.currentDate = new Date();

  // Initialize variables
  this.disabled = false;
  this.slot = this.daysdata.find(x => x["slotId"] == slotId);

  // Set flags for the type of call
  this.isGroup = true;
  this.is1o1 = false;

  // Define the index and query parameters for retrieving program data
  var index = '/program/all/';
  var queryparams = {
    "pagesize": 0,
    "pagenumber": 0,
    "pagination": false
  };

  // Retrieve program data
  this.yoga_db_service.getQueriedData(this.userID, index, queryparams).subscribe(
    response => {
      // Find the program related to the current slot
      var program = response.data.find(x => x["programId"] == this.slot.programId);

      // Set the view program name
      this.viewProgramName = program["programName"];
      this.disabled = true;

      // Update UI to show the edit slot form and hide the add slot form
      this.showAddSlot = false;
      this.showEditSlot = true;

      // Set current slot ID and times
      this.currentSlotId = slotId;
      this.viewStartTime = this.slot.startTime;
      this.selectedEndTime = this.slot.endTime;

      // Show group call details
      this.showGroupCallDetails = true;
    },
    error => {
      console.log("An error has occurred while retrieving profile data.");
    }
  );
}


 initiateCall() {
  // Retrieve meeting data from the trainee service
  this.traineeService.getMeetingData().subscribe(
    (response: any) => {
      let meetingDetails = response.data;
      
      // Redirect to the Zoom component with the meeting details
      this.redirectToZoomComponent(meetingDetails);
    },
    (error) => {
      console.error('API Error:', error);
    }
  );
}

redirectToZoomComponent(meetingDetails: any) {
  // Navigate to the Zoom component, passing meetingDetails as state
  this.router.navigate(['/meet', this.currentSlotId], { state: { data: meetingDetails } });
}


}
