import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trainee-mealplan',
  templateUrl: './trainee-mealplan.component.html',
  styleUrls: ['./trainee-mealplan.component.css']
})
export class TraineeMealplanComponent implements OnInit {

  isShowCalendar : boolean = false;
  constructor() { }

  ngOnInit() {
  }

}
