import { Injectable } from '@angular/core';

@Injectable()
export class AppGlobals {
  readonly accessKeyId: string = 'AKIAZXR4BJ4IEOOSIQV3';
  readonly secretAccessKey: string = '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';
  readonly region: string = 'ap-south-1';
  readonly EXERCISE_FOLDER = 'Exercise/';
  readonly PROFILE_FOLDER = 'ProfileIntro/';
  readonly IMGFOLDER = 'ProfileImg/';
  readonly PROGIMGFOLDER = 'ProgramImg/';
  readonly SORCE_BUCKET = 'vod-watchfolder-src';
  readonly DEST_BUCKET = 'vod-dest-streaming';
  readonly CER_FOLDER = 'Certificates/';
  readonly Business_FOLDER = 'Business/';
  readonly CLOUDFRONT_URL = 'https://d13m95frd7cvoq.cloudfront.net/';
  readonly LAMBDA_FUNCTION = 'VODLambdaConvert';
  readonly ImgallowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG", "JFIF", "BMP"];
  readonly Cer_allowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG", "JFIF", "BMP", "pdf"];
  readonly VideoallowedExtensions = ["mp4"];
  readonly Vidyo_Host = "prod.vidyo.io";
  readonly Vidyo_Key = "43cfb48beefd4d5db701c34642f44e5a";
  readonly Video_appID = "54ff1c.vidyo.io";





  // // LOCAL SERVER CONFIG
  // readonly SERVER_LINK = 'http://localhost:8080/api/trainer';
  // readonly TRAINEE_SERVER_LINK = 'http://localhost:8081/api';
  // readonly TWILIO_SERVER_LINK = 'http://localhost:8085/api/twilio';
  // readonly NOTIFICATION_SERVER_LINK = 'http://localhost:8086/api/notifications';
  // readonly YOGA_SERVER_LINK = 'http://localhost:8082/api/trainer/yoga';


  // readonly DEPLOY_URL = "https://1o1fitness.com";
  // readonly DEPLOY_URL = "https://dev.1o1.cloudns.asia";
  readonly DEPLOY_URL = "https://demo.1o1fitness.com";
  // readonly DEPLOY_URL = "https://uat.1o1fitness.com";
  // readonly DEPLOY_URL = "https://saicharanbollampally.in:9091";
  // readonly TRAINEE_DEPLOY_URL = "https://saicharanbollampally.in:9092";

  // REMOTE SERVER CONFIG urls
  readonly SERVER_LINK = this.DEPLOY_URL +'/trainerservice/api/trainer';
  // readonly SERVER_LINK = this.DEPLOY_URL +'/api/trainer';
  readonly NUTRITION_SERVER_LINK = this.DEPLOY_URL + '/nutritionservice';
  readonly NOTIFICATION_SERVER_LINK = this.DEPLOY_URL + '/notificationservice/api/notifications';
  readonly TWILIO_SERVER_LINK = this.DEPLOY_URL + '/twilioservice/api/twilio';
  readonly TRAINEE_SERVER_LINK = this.DEPLOY_URL + '/traineeservice/api';
  // readonly TRAINEE_SERVER_LINK = this.TRAINEE_DEPLOY_URL + '/api';
  readonly YOGA_SERVER_LINK = this.DEPLOY_URL + '/trainerserviceyoga/api/trainer/yoga';
  // readonly ZOOM_CALL_INIT = this.DEPLOY_URL +  '/traineeservice/api/zoom/signature';
  // readonly ZOOM_CALL_INIT = this.DEPLOY_URL +  '/traineeservice/api/zoom/signature?host=1';
  readonly ZOOM_CALL_INIT = this.DEPLOY_URL +  '/traineeservice/api/zoom/participant/signature?host=1';
  // nutrition constants
  Jumbo = 70.9;
  Large = 56.7;
  Medium = 49.6;
  Small = 42.5;
  oz = 0.035274;
  one_oz = 28.3495;

  // Macros constants
  gramCarbs = 4;
  gramProtein = 4;
  gramFat = 9;

  // constants of daily values
  dailyValueOfCarbohydrate = 300;
  dailyValueOfFat = 65;
  dailyValueOfFibre = 25;
  dailyValueOfProtein = 50;


  // Nutritionix api constants
  readonly XAPPID = '99a9215b';
  readonly XAPPKEY = '411f28ce68a972db7dec151444d8bcea';
  readonly SEARCHURL = 'https://trackapi.nutritionix.com/v2/search/instant';
  readonly GETNUTRITIONURL = 'https://trackapi.nutritionix.com/v2/natural/nutrients';
  readonly SEARCHBRANDEDURL = 'https://trackapi.nutritionix.com/v2/search/item?nix_item_id=';
}
