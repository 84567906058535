import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { DBService } from '../service/db.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UserLoginService } from '../service/user-login.service';
import { ToastrService } from 'ngx-toastr';
import { CognitoUtil, LoggedInCallback } from '../service/cognito.service';
import { AppGlobals } from '../app.globals';
import { AWSService } from '../service/aws.service';
import { YogaDBService } from '../service/YogaDBService';

(<any> window).pdfWorkerSrc = 'assets/pdf.worker.js';

export class businessDetails {
  trainerId: String;
  bankAccountDetails: Object;
  businessDocuments: Object[];
}

@Component({
  selector: 'app-bussiness-details',
  templateUrl: './bussiness-details.component.html',
  styleUrls: ['./bussiness-details.component.css']
})

export class BussinessDetailsComponent implements OnInit, OnDestroy, LoggedInCallback {

  public showIndianform: boolean = false;
  public showOtherCountryform: boolean = false;
  public showselectedpreview: boolean = false;
  public showunnecssarydata: boolean = false;
  public showUploadform = false;
  public showimagepreview: boolean = false;
  public showpdfviewer: boolean = false;
  public showselectedpreview1: boolean = false;
  public showimagepreview1: boolean = false;
  public showpdfviewer1: boolean = false;
  public showbankaccountdetails = false;
  public showuploaddocumentslist = true;
  businessDetails: businessDetails;
  currentCategory: string;
  userCategories : any;
  currentGender: string;
  selectedFile: File;
  fileExtension: any;
  loaderHide: boolean = false;
  showpreivewuploaddocument: string;
  dropdownSettings_single: any = {};
  awsdocuments = [];
  closeDropdownSelection = false;
  GSTINpreview = 'OFF';
  PANpreview = 'OFF';
  VOIDpreview = 'OFF';
  USIDpreview = 'OFF'
  W9preview = 'OFF';
  // Google Var
  googleUserEmail: string;
  private loggedIn: boolean;
  private subcrptn_1: any;
  currentLogged: string;
  currentEmail: string;
  country: string;
  GSTINURL: string = "";
  GSTINType: string = "";
  PANType: string = "";
  VOIDType: string = "";
  W9FORMTYPE: string = "";
  USIDTYPE:string = "";
  PANURL: string = "";
  VOIDChequeURL: string = "";
  W9FormURL: string = "";
  USIDUrl:string="";
  currentSelectedDocument: string;
  isFitness : boolean = false;
  isYoga : boolean = false;
  isZumba: boolean = false;
  banklist = [];
  banksavinglist = [];
  isSave: boolean = true;
  isEdit: boolean = false;
  private sub: any;

  bankAccountTypes: Object[];
  bankNames: Object[];
  SelectedBank = [];
  SelectedAccountType = [];


  bankDetails = {
    accountHolderName: "",
    accountNumber: "",
    accountType: "",
    bankName: "",
    country: "",
    ifscCode: "",
    routingNumber: "",
    currency: "",
    accountHolderType: ""
  }
  businessDocument = {
    name: "",
    documentType: "",
    uploadedDocumentName: "",
    documentDestionationUploadUrl: "",
    documentVerified: ""
  }

  constructor(private renderer: Renderer2, private db_service: DBService, public router: Router,
    public route: ActivatedRoute,  private yoga_db_service: YogaDBService,private globals: AppGlobals, private awsService: AWSService, public userService: UserLoginService, private toastr: ToastrService, public cognitoUtil: CognitoUtil) {
    this.userService.isAuthenticated(this);
  }

  ngOnInit() {
    this.businessDetails = new businessDetails();
    this.sub = this.route.params.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.businessDetails.trainerId = params['trainerId'];
        this.getProfileDataById();
      }
    });

    // this.showIndianform = true;
    this.currentCategory = localStorage.getItem("ProfileCategory")
    this.currentGender = localStorage.getItem("ProfileGender");
    this.userCategories = JSON.parse(localStorage.getItem("userCategory"));
    this.userCategories.forEach(e => {
                                      if(e.category_name == "Bodybuilder")
                                      this.isFitness =true
                                      else if(e.category_name == "Yoga")
                                      this.isYoga = true
                                      else
                                      this.isZumba = true;
      
    });
    if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-build-male');
    }
    else if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-build-female');
    }
    else if (this.currentCategory == 'Yoga' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-yoga-male');
    }
    else if (this.currentCategory == 'Yoga' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-yoga-female');
    }
    else if (this.currentCategory == 'Zumba' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-zumba-male');
    }
    else if (this.currentCategory == 'Zumba' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-zumba-female');
    }

    this.userService.currentUserID.subscribe((val) => {
      if (val != '') {
        this.businessDetails.trainerId = val;
      }
    });


    this.dropdownSettings_single = {
      singleSelection: true,
      enableCheckAll: false,
      idField: 'id',
      textField: 'name',
      allowSearchFilter: true,
      itemsShowLimit: 1,
      closeDropDownOnSelection: this.closeDropdownSelection
    };

  }
  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'bg-img-build-male');
    this.renderer.removeClass(document.body, 'bg-img-yoga-male');
    this.renderer.removeClass(document.body, 'bg-img-zumba-male');
    this.renderer.removeClass(document.body, 'bg-img-build-female');
    this.renderer.removeClass(document.body, 'bg-img-yoga-female');
    this.renderer.removeClass(document.body, 'bg-img-zumba-female');
    this.renderer.addClass(document.body, 'body-main');
  }
  isLoggedIn(message: string, isLoggedIn: boolean): void {
    if (!isLoggedIn) {
      this.router.navigate(['/login']);
    } else {
      // if()
      if (this.loggedIn == true) {
        this.currentEmail = localStorage.getItem('lastAuthUser');
        // if(this.currentEmail != undefined)
        this.getProfileDataFromServer();
      }
      else {
        // this.currentEmail = localStorage.getItem('CognitoIdentityServiceProvider.2io8v4r253hugt5ndbjv9shkfp.LastAuthUser');
        this.currentEmail = this.cognitoUtil.getCurrentUser().getUsername();
        if(this.currentEmail == undefined || this.currentEmail == '' || this.currentEmail == null || this.currentEmail.includes("Google") || this.currentEmail.includes("google"))
        {
          this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
          if(this.currentEmail == undefined)
          this.currentEmail = this.cognitoUtil.session.idToken.payload.email;
        }
        // this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
        this.getProfileDataFromServer();
      }
    }
  }
  Bankdetails() {
    this.showbankaccountdetails = true;
    this.showuploaddocumentslist = false;
  }
  uploaddetails() {
    this.showbankaccountdetails = false;
    this.showuploaddocumentslist = true;
  }
  savedetails() {
    this.businessDetails.bankAccountDetails = this.bankDetails;

    // if (this.businessDetails.bankAccountDetails['accountHolderName'] == "" || this.businessDetails.bankAccountDetails['accountNumber'] == "") {
    //   this.toastr.error('', 'Please provide required bank account details', {
    //     timeOut: 3000,
    //     positionClass: 'toast-top-right',
    //     progressBar: true,
    //     progressAnimation: 'increasing',
    //     tapToDismiss: true,
    //   });
    //   return;
    // }
    // if (this.SelectedAccountType == undefined) {
    //   this.toastr.error('', 'Please provide required bank account details', {
    //     timeOut: 3000,
    //     positionClass: 'toast-top-right',
    //     progressBar: true,
    //     progressAnimation: 'increasing',
    //     tapToDismiss: true,
    //   });
    //   return;
    // }
    // if (this.SelectedAccountType.length == 0) {
    //   this.toastr.error('', 'Please provide required bank account details', {
    //     timeOut: 3000,
    //     positionClass: 'toast-top-right',
    //     progressBar: true,
    //     progressAnimation: 'increasing',
    //     tapToDismiss: true,
    //   });
    //   return;
    // }

    // if (this.SelectedBank == undefined) {
    //   this.toastr.error('', 'Please provide required bank account details', {
    //     timeOut: 3000,
    //     positionClass: 'toast-top-right',
    //     progressBar: true,
    //     progressAnimation: 'increasing',
    //     tapToDismiss: true,
    //   });
    //   return;
    // }
    // if (this.SelectedBank.length == 0) {
    //   this.toastr.error('', 'Please provide required bank account details', {
    //     timeOut: 3000,
    //     positionClass: 'toast-top-right',
    //     progressBar: true,
    //     progressAnimation: 'increasing',
    //     tapToDismiss: true,
    //   });
    //   return;
    // }

    // if (this.country == "India" && (this.businessDetails.bankAccountDetails['ifscCode'] == undefined || this.businessDetails.bankAccountDetails['ifscCode'] == "")) {
    //   this.toastr.error('', 'Please provide valid IFSC code', {
    //     timeOut: 3000,
    //     positionClass: 'toast-top-right',
    //     progressBar: true,
    //     progressAnimation: 'increasing',
    //     tapToDismiss: true,
    //   });
    //   return;
    // }
    // var regex = "^[A-Z]{4}0[A-Z0-9]{6}$";

    // var trigger = this.businessDetails.bankAccountDetails['ifscCode'];
    // var regexp = new RegExp('^[A-Z]{4}0[A-Z0-9]{6}$');
    // var test = regexp.test(trigger);

    // //var isValid = this.businessDetails.bankAccountDetails['ifscCode'].match(regex);
    // if (test != true) {
    //   this.toastr.error('', 'Please provide valid IFSC code', {
    //     timeOut: 3000,
    //     positionClass: 'toast-top-right',
    //     progressBar: true,
    //     progressAnimation: 'increasing',
    //     tapToDismiss: true,
    //   });
    //   return;
    // }


    // if (this.SelectedBank != undefined && this.SelectedBank.length != 0) {
    //   var bankName = JSON.parse(JSON.stringify(this.SelectedBank[0]["name"]));
    //   this.businessDetails.bankAccountDetails['bankName'] = bankName;
    // }
    // else {
    //   this.businessDetails.bankAccountDetails['bankName'] = "";

    // }
    // if (this.SelectedAccountType != undefined && this.SelectedAccountType.length != 0) {
    //   var accountType = JSON.parse(JSON.stringify(this.SelectedAccountType[0]["name"]));
    //   this.businessDetails.bankAccountDetails['accountType'] = accountType;
    // } else {
    //   this.businessDetails.bankAccountDetails['accountType'] = "";
    // }
    this.businessDetails.businessDocuments = this.awsdocuments;
    if (this.businessDetails.businessDocuments.length != 0) {
      this.businessDetails["isBusinessDetailsSubmitted"] = true;
      var index = '/businessdetails';
      if (this.isSave == true) {
        if(this.isFitness){
        this.db_service.saveDataToDB(this.businessDetails, index).subscribe(
          response => {
            this.showselectedpreview = false
            this.GSTINpreview = 'OFF';
            this.PANpreview = 'OFF';
            this.VOIDpreview = 'OFF';
            this.W9preview = 'OFF';
            this.preview(this.currentSelectedDocument);
            // this.router.navigate(['/program']);
            this.toastr.success('', response['message'], {
              timeOut: 3000,
              positionClass: 'toast-top-right',
              progressBar: true,
              progressAnimation: 'increasing',
              tapToDismiss: true,
            });
            if (response.data != null) {
              this.getProfileDataFromServer();

            }

          },
          error => {
            this.toastr.error('', 'An error has occured while retriving data', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
              progressBar: true,
              progressAnimation: 'increasing',
              tapToDismiss: true,
            });
          }
        )
        }
        if(this.isYoga){
          this.yoga_db_service.saveDataToDB(this.businessDetails, index).subscribe(
            response => {
              this.showselectedpreview = false
              this.GSTINpreview = 'OFF';
              this.PANpreview = 'OFF';
              this.VOIDpreview = 'OFF';
              this.W9preview = 'OFF';
              this.preview(this.currentSelectedDocument);
              // this.router.navigate(['/program']);
              this.toastr.success('', response['message'], {
                timeOut: 3000,
                positionClass: 'toast-top-right',
                progressBar: true,
                progressAnimation: 'increasing',
                tapToDismiss: true,
              });
              if (response.data != null) {
                this.getProfileDataFromServer();
  
              }
  
            },
            error => {
              this.toastr.error('', 'An error has occured while retriving data', {
                timeOut: 3000,
                positionClass: 'toast-top-right',
                progressBar: true,
                progressAnimation: 'increasing',
                tapToDismiss: true,
              });
            }
          )
        }
      } else {
        if(this.isFitness){
        this.db_service.updateDatainDB(this.businessDetails, index).subscribe(
          response => {
            this.showselectedpreview = false
            this.GSTINpreview = 'OFF';
            this.PANpreview = 'OFF';
            this.VOIDpreview = 'OFF';
            this.W9preview = 'OFF';

            this.preview(this.currentSelectedDocument);
            this.toastr.success('', response['message'], {
              timeOut: 3000,
              positionClass: 'toast-top-right',
              progressBar: true,
              progressAnimation: 'increasing',
              tapToDismiss: true,
            });
            if (response.data != null) {
              this.getProfileDataFromServer();
            }
          },
          error => {
            this.toastr.error('', 'An error has occured while retriving data', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
              progressBar: true,
              progressAnimation: 'increasing',
              tapToDismiss: true,
            });
          }
        )
        }
        if(this.isYoga){
          this.yoga_db_service.updateDatainDB(this.businessDetails, index).subscribe(
            response => {
              this.showselectedpreview = false
              this.GSTINpreview = 'OFF';
              this.PANpreview = 'OFF';
              this.VOIDpreview = 'OFF';
              this.W9preview = 'OFF';
  
              this.preview(this.currentSelectedDocument);
              this.toastr.success('', response['message'], {
                timeOut: 3000,
                positionClass: 'toast-top-right',
                progressBar: true,
                progressAnimation: 'increasing',
                tapToDismiss: true,
              });
              if (response.data != null) {
                this.getProfileDataFromServer();
              }
            },
            error => {
              this.toastr.error('', 'An error has occured while retriving data', {
                timeOut: 3000,
                positionClass: 'toast-top-right',
                progressBar: true,
                progressAnimation: 'increasing',
                tapToDismiss: true,
              });
            }
          )
        }
      }
       this.router.navigate(['/dashboard']);

    } else {
      if (this.country == "India") {
        this.toastr.error('', 'Please upload atleast one Document', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      } else {
        this.toastr.error('', 'Please upload W9 Form', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      }

    }
  }

  getMasters() {
    var index = '/masters/';
    this.db_service.getDataFromServer('business_details', index).subscribe(
      response => {
        //console.log('Data Retrived succesfully.', response);
        for (var i = 0; i < response.length; i++) {
          if (this.bankDetails.country == "India") {
            if (response[i]['name'] == "AccountTypes") {
              this.bankAccountTypes = response[i]['values'];
            } if (response[i]['name'] == "BankNames") {
              this.bankNames = response[i]['values'];
            }
          } else {

            if (response[i]['name'] == "AccountTypes_US") {
              this.bankAccountTypes = response[i]['values'];
            } if (response[i]['name'] == "BankNames_Us") {
              this.bankNames = response[i]['values'];
            }
          }
        }
        this.getBusinessdetailsFromServer();
      },
      error => {
        console.log("An error has occurred while retriving masters data.");
      }
    )
  }
  getProfileDataById() {
    this.showselectedpreview = false;
    var index = '/profile/';
    if(localStorage.getItem("ProfileCategory") != "Yoga"){
    this.db_service.getDataFromServer(this.businessDetails["trainerId"], index).subscribe(
      response => {
        //console.log('Data Retrived succesfully.', response);
        response = response.data[0];
        this.businessDetails["trainerId"] = response.trainerId;
        this.country = response.address[0].country;
        // this.country = "USA";
        if (this.country.toLowerCase().includes("india")) {
          this.showIndianform = true;
          this.bankDetails.country = "India";
        }
        else {
          this.showOtherCountryform = true;
          this.bankDetails.country = "United States";

          // this.banklist = ['AMERICAN BANK', 'CITI BANK', 'Wells Fargo', 'U.S.BANK'];
          // this.banksavinglist = ["Checking accounts", "Money market accounts", "Individual retirement accounts"];
        }
        // this.showIndianform = false;
        // this.showOtherCountryform = true;
        this.getMasters();
      },
      error => {
        console.log("An error has occurred while retriving profile data.");
      }
    )
    }else{
      this.yoga_db_service.getDataFromServer(this.businessDetails["trainerId"], index).subscribe(
        response => {
          //console.log('Data Retrived succesfully.', response);
          response = response.data[0];
          this.businessDetails["trainerId"] = response.trainerId;
          this.country = response.address[0].country;
          // this.country = "USA";
          if (this.country.toLowerCase().includes("india")) {
            this.showIndianform = true;
            this.bankDetails.country = "India";
          }
          else {
            this.showOtherCountryform = true;
            this.bankDetails.country = "United States";
  
            // this.banklist = ['AMERICAN BANK', 'CITI BANK', 'Wells Fargo', 'U.S.BANK'];
            // this.banksavinglist = ["Checking accounts", "Money market accounts", "Individual retirement accounts"];
          }
          // this.showIndianform = false;
          // this.showOtherCountryform = true;
          this.getMasters();
        },
        error => {
          console.log("An error has occurred while retriving profile data.");
        }
      )
    }
  }
  getProfileDataFromServer() {
    this.showselectedpreview = false;
    var index = '/profile/';
    if(this.currentCategory != "Yoga"){
    this.db_service.getDataFromServer(this.currentEmail, index).subscribe(
      response => {
        //console.log('Data Retrived succesfully.', response);
        response = response.data[0];
        this.businessDetails["trainerId"] = response.trainerId;
        this.country = response.address[0].country;
        // this.country = "USA";
        if (this.country.toLowerCase().includes("india")) {
          this.showIndianform = true;
          this.bankDetails.country = "India";

        }
        else {
          this.showOtherCountryform = true;
          this.bankDetails.country = "United States";

          // this.banklist = ['AMERICAN BANK', 'CITI BANK', 'Wells Fargo', 'U.S.BANK'];
          // this.banksavinglist = ["Checking accounts", "Money market accounts", "Individual retirement accounts"];
        }
        this.getMasters();
      },
      error => {
        console.log("An error has occurred while retriving profile data.");
      }
    )
    }else{
      this.yoga_db_service.getDataFromServer(this.currentEmail, index).subscribe(
        response => {
          //console.log('Data Retrived succesfully.', response);
          response = response.data[0];
          this.businessDetails["trainerId"] = response.trainerId;
          this.country = response.address[0].country;
          // this.country = "USA";
          if (this.country.toLowerCase().includes("india")) {
            this.showIndianform = true;
            this.bankDetails.country = "India";
  
          }
          else {
            this.showOtherCountryform = true;
            this.bankDetails.country = "United States";
  
            // this.banklist = ['AMERICAN BANK', 'CITI BANK', 'Wells Fargo', 'U.S.BANK'];
            // this.banksavinglist = ["Checking accounts", "Money market accounts", "Individual retirement accounts"];
          }
          this.getMasters();
        },
        error => {
          console.log("An error has occurred while retriving profile data.");
        }
      )
    }
  }

  getBusinessdetailsFromServer() {
    var index = '/businessdetails/';
    this.db_service.getDataFromServer(this.businessDetails["trainerId"], index).subscribe(
      response => {
        // console.log('Data Retrived succesfully.', response);
        this.isEdit = true;
        this.isSave = false;
        if (response.data != null) {
          response = response.data[0];
          var bankName = this.bankNames.find(x => x["name"] == response.bankAccountDetails["bankName"]);
          var accountType = this.bankAccountTypes.find(x => x["name"] == response.bankAccountDetails["accountType"]);
          this.SelectedBank = Array.of(bankName);
          this.SelectedAccountType = Array.of(accountType);
          this.bankDetails = response.bankAccountDetails;
          this.awsdocuments = response.businessDocuments;
          var documentlist = response.businessDocuments;
          for (let i = 0; i < documentlist.length; i++) {
            if (documentlist[i]['name'] == "GSTIN") {
              this.GSTINURL = documentlist[i].documentDestionationUploadUrl;
              this.GSTINType = documentlist[i].documentType;
              if (this.GSTINURL != undefined && this.GSTINURL != "") {
                this.preview("GSTIN");
              }
            }
            if (documentlist[i]['name'] == "PAN") {
              this.PANURL = documentlist[i].documentDestionationUploadUrl;
              this.PANType = documentlist[i].documentType;
              if (this.GSTINURL == undefined || this.GSTINURL == "") {
                this.preview("PAN");
              }
            }
            if (documentlist[i]['name'] == "VOID Cheque") {
              this.VOIDChequeURL = documentlist[i].documentDestionationUploadUrl;
              this.VOIDType = documentlist[i].documentType;
              if ((this.GSTINURL == undefined || this.GSTINURL == "") && (this.PANURL == undefined || this.PANURL == "")) {
                this.preview("VOID Cheque");
              }
            }
            if (documentlist[i]['name'] == "W9 Form") {
              this.W9FormURL = documentlist[i].documentDestionationUploadUrl;
              this.W9FORMTYPE = documentlist[i].documentType;
              if ((this.W9FormURL != undefined || this.W9FormURL != "") && this.showOtherCountryform) {
                this.preview("W9 Form");
              }
            }
            if (documentlist[i]['name'] == "USID") {
              this.USIDUrl = documentlist[i].documentDestionationUploadUrl;
              this.USIDTYPE = documentlist[i].documentType;
              if ((this.W9FormURL == undefined || this.W9FormURL == "") && this.showOtherCountryform) {
                this.preview("USID");
              }
            }


          }

        }
        else {
          this.isEdit = false;
          this.isSave = true;
          // this.toastr.error('', 'There is no business details registered with us.', {
          //   timeOut: 3000,
          //   positionClass: 'toast-top-right',
          //   progressBar: true,
          //   progressAnimation: 'increasing',
          //   tapToDismiss: true,
          // });
        }




      },
      error => {
        this.isEdit = false;
        this.isSave = true;
        // this.toastr.error('', 'There are no business details registered with us.', {
        //   timeOut: 3000,
        //   positionClass: 'toast-top-right',
        //   progressBar: true,
        //   progressAnimation: 'increasing',
        //   tapToDismiss: true,
        // });
      }
    )


  }


  isInArray(array, word) {
    return array.indexOf(word.toLowerCase()) > -1;
  }

  showUploadforms() {
    this.showUploadform = true;
  }
  selectFile(event, filetype, cer_name) {
    if(event.target.files.length !=0){
    this.selectedFile = event.target.files[0];

    if(this.selectedFile.name.includes("+")){
      this.toastr.error('', "No special characters allowed like '+'.", {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return;
    }

    var type = event.target.files[0].type;
    // var format = /[!@#$%^&*()_+\=\[\]{};':"\\|,<>\/?]/;
    // var fileCheck = format.test(this.selectedFile.name);

    this.fileExtension = this.selectedFile.name.split('.').pop();

    // if (fileCheck == true) {
    //   this.toastr.error('', 'Special characters are not allowed in certificate file name.', {
    //     timeOut: 3000,
    //     positionClass: 'toast-top-right',
    //     progressBar: true,
    //     progressAnimation: 'increasing',
    //     tapToDismiss: true,
    //   });
    // } else {
    if (this.isInArray(this.globals.Cer_allowedExtensions, this.fileExtension)) {
      var URL = "";
      for (let i = 0; i < this.awsdocuments.length; i++) {
        if (this.awsdocuments[i]['name'] == cer_name) {
          URL = this.awsdocuments[i]['name'];
        }

      }
      if (URL != "") {
        var urlName = URL.replace(/ /g, "_");
        this.awsService.deleteFileFromS3(urlName, this.businessDetails.trainerId, this.globals.DEST_BUCKET, this.globals.Business_FOLDER).subscribe(
          response => {
            this.fileUpload(filetype, cer_name, type);
            //console.log('Certificate deleted successfully.', response);

          },
          error => {
            console.log('There is an error while deleting certificate.', error);

          })
      } else {
        this.fileUpload(filetype, cer_name, type);
      }
    } else {
      this.GSTINURL = "";
      this.PANURL = "";
      this.VOIDChequeURL = "";
      this.toastr.error('', 'Please upload pdf or image files.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      // }
    }
  }else{
    this.resetPreview(cer_name);
  }
  }

  fileUpload(filetype, cer_name, type) {
    this.loaderHide = !this.loaderHide;
    this.businessDocument.name = cer_name;
    this.awsService.uploadFileToS3(this.selectedFile, this.businessDetails.trainerId, this.globals.DEST_BUCKET, this.globals.Business_FOLDER, filetype).subscribe(
      response => {
        this.businessDocument.documentDestionationUploadUrl = this.globals.CLOUDFRONT_URL + response.Key;
        this.businessDocument.documentType = type;
        if (cer_name == 'GSTIN') {
          this.GSTINURL = this.businessDocument.documentDestionationUploadUrl;
          this.GSTINType = this.businessDocument.documentType;
          this.preview("GSTIN");
        } else if (cer_name == "PAN") {
          this.PANURL = this.businessDocument.documentDestionationUploadUrl;
          this.PANType = this.businessDocument.documentType;
          this.preview("PAN");
        } else if (cer_name == "VOID Cheque") {
          this.VOIDChequeURL = this.businessDocument.documentDestionationUploadUrl;
          this.VOIDType = this.businessDocument.documentType;
          this.preview("VOID Cheque");
        }else if (cer_name == "USID") {
          this.USIDUrl = this.businessDocument.documentDestionationUploadUrl;
          this.USIDTYPE = this.businessDocument.documentType;
          this.preview("USID");
         } else {
          this.W9FormURL = this.businessDocument.documentDestionationUploadUrl;
          this.W9FORMTYPE = this.businessDocument.documentType;
          this.preview("W9 Form");
        }
        this.businessDocument.name = cer_name;
        this.businessDocument.documentType = type
        this.businessDocument.documentVerified = 'true';
        this.businessDocument.uploadedDocumentName = this.selectedFile.name
        var matchingvalue = 0;
        // this.awsdocuments.push(JSON.parse(JSON.stringify(this.businessDocument)));
        if (this.awsdocuments.length == 0) {
          // this.awsdocuments.push(JSON.parse(JSON.stringify(this.businessDocument)));
        } else {
          for (let i = 0; i < this.awsdocuments.length; i++) {
            if (this.awsdocuments[i]['name'] == cer_name) {
              this.awsdocuments[i] = JSON.parse(JSON.stringify(this.businessDocument));
              matchingvalue = matchingvalue + 1;
            }

          }
        }
        if (matchingvalue == 0) {
          this.awsdocuments.push(JSON.parse(JSON.stringify(this.businessDocument)));
        }


        //   if(this.awsdocuments.length == 0){
        //     this.awsdocuments.push(this.businessDocument);

        //   }
        //   else{
        //  for(var i = 0 ; i < this.awsdocuments.length ; i++){
        //       if(this.awsdocuments[i]['name'] == this.businessDocument.name){
        //           this.awsdocuments[i]= this.businessDocument;
        //       }else{
        //         this.awsdocuments[this.awsdocuments.length].push(this.businessDocument);
        //       }
        //  }
        // }



        //this.certification.certificateDestinationUploadUrl = response.Location;
        // this.showselectedpreview = false
        // this.GSTINpreview = 'OFF';
        // this.PANpreview = 'OFF';
        // this.VOIDpreview = 'OFF';
        // this.W9preview = 'OFF';
        this.loaderHide = !this.loaderHide;
        //console.log('Certificate uploaded successfully.', response);
        this.toastr.success('', 'Document uploaded successfully.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      },
      error => {
        console.log('There is an error uploading certificate.', error);
        // console.log('Image uploaded successfully.', response);
        this.loaderHide = !this.loaderHide;
        this.toastr.error('', 'There is an error uploading certificate.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      })

    // this.certification.uploadedCertificateName = this.selectedFile.name;

  }
  download() {
    window.open("https://www.irs.gov/pub/irs-pdf/fw9.pdf", "_blank");
  }

  openSampleDocument(val) {
    this.showselectedpreview = true;

    this.GSTINpreview = 'OFF';
    this.PANpreview = 'OFF';
    this.VOIDpreview = 'OFF';
    this.W9preview = 'OFF';
    this.USIDpreview = 'OFF';
    this.showimagepreview = true;
    this.showpdfviewer = false;

    if (val == "GSTIN")
      this.showpreivewuploaddocument = "assets/images/GSTIN.jpg";
    else if (val == "PAN")
      this.showpreivewuploaddocument = "assets/images/PANCARD.jpg";
    else if (val == "VOID Cheque" && this.showOtherCountryform)
      this.showpreivewuploaddocument = "assets/images/voidcheck.png";
    else if (val == "VOID Cheque" && this.showOtherCountryform == false)
      this.showpreivewuploaddocument = "assets/images/indian_void.jpg";
    else if (val == "USID")
      this.showpreivewuploaddocument = "assets/images/License.jpg";
    else
      this.showpreivewuploaddocument = "assets/images/w9form.png";
  }
  preview(val) {
    this.currentSelectedDocument = val;
    this.showselectedpreview = true;
    if (val == "GSTIN") {
      this.GSTINpreview = 'ON';
      this.PANpreview = 'OFF';
      this.VOIDpreview = 'OFF';
      this.W9preview = 'OFF';
      this.USIDpreview = 'OFF';
      if (this.GSTINType.includes('image')) {
        this.showimagepreview = true;
        this.showpdfviewer = false;
        this.showpreivewuploaddocument = this.GSTINURL;
      } else {
        this.showimagepreview = false;
        this.showpdfviewer = true;
        if(this.showpreivewuploaddocument != this.GSTINURL)
        this.showpreivewuploaddocument = this.GSTINURL;
      }
    } else if (val == "PAN") {
      this.GSTINpreview = 'OFF';
      this.PANpreview = 'ON';
      this.VOIDpreview = 'OFF';
      this.W9preview = 'OFF';
      this.USIDpreview = 'OFF';
      if (this.PANType.includes('image')) {
        this.showimagepreview = true;
        this.showpdfviewer = false;
        this.showpreivewuploaddocument = this.PANURL;
      } else {
        this.showimagepreview = false;
        this.showpdfviewer = true;
        // if(this.showpreivewuploaddocument != this.PANURL)
        this.showpreivewuploaddocument = this.PANURL;
      }
    } else if (val == "VOID Cheque") {
      this.GSTINpreview = 'OFF';
      this.PANpreview = 'OFF';
      this.VOIDpreview = 'ON';
      this.W9preview = 'OFF';
      this.USIDpreview = 'OFF';
      if (this.VOIDType.includes('image')) {
        this.showimagepreview = true;
        this.showpdfviewer = false;
        this.showpreivewuploaddocument = this.VOIDChequeURL;
      } else {
        this.showimagepreview = false;
        this.showpdfviewer = true;
        if(this.showpreivewuploaddocument != this.VOIDChequeURL)
        this.showpreivewuploaddocument = this.VOIDChequeURL;
      }
    }else if (val == "USID"){
      this.GSTINpreview = 'OFF';
      this.PANpreview = 'OFF';
      this.VOIDpreview = 'OFF';
      this.W9preview = 'OFF';
      this.USIDpreview = 'ON';
      if (this.USIDTYPE.includes('image')) {
        this.showimagepreview = true;
        this.showpdfviewer = false;
        this.showpreivewuploaddocument = this.USIDUrl;
      } else {
        this.showOtherCountryform = true;
        this.showimagepreview = false;
        this.showpdfviewer = true;
        if(this.showpreivewuploaddocument != this.USIDUrl)
        this.showpreivewuploaddocument = this.USIDUrl;
      }
    } else {
      this.GSTINpreview = 'OFF';
      this.PANpreview = 'OFF';
      this.VOIDpreview = 'OFF';
      this.W9preview = 'ON';
      this.USIDpreview = 'OFF';
      if (this.W9FORMTYPE.includes('image')) {

        this.showimagepreview = true;
        this.showpdfviewer = false;
        this.showpreivewuploaddocument = this.W9FormURL;
      } else {
        this.showOtherCountryform = true;
        this.showimagepreview = false;
        this.showpdfviewer = true;
        if(this.showpreivewuploaddocument != this.W9FormURL)
        this.showpreivewuploaddocument = this.W9FormURL;
      }
    }
  }
  resetPreview(cer_name){
    if (cer_name == 'GSTIN') {
      this.GSTINURL = this.businessDocument.documentDestionationUploadUrl;
      this.GSTINType = this.businessDocument.documentType;
      this.preview("GSTIN");
    } else if (cer_name == "PAN") {
      this.PANURL = this.businessDocument.documentDestionationUploadUrl;
      this.PANType = this.businessDocument.documentType;
      this.preview("PAN");
    } else if (cer_name == "VOID Cheque") {
      this.VOIDChequeURL = this.businessDocument.documentDestionationUploadUrl;
      this.VOIDType = this.businessDocument.documentType;
      this.preview("VOID Cheque");
    } else if (cer_name == "USID") {
      this.USIDUrl = this.businessDocument.documentDestionationUploadUrl;
      this.USIDTYPE = this.businessDocument.documentType;
      this.preview("USID");
    } else {
      this.W9FormURL = this.businessDocument.documentDestionationUploadUrl;
      this.W9FORMTYPE = this.businessDocument.documentType;
      this.preview("W9 Form");
    }
  }
}




