import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { UserLoginService } from "../service/user-login.service";
import { LoggedInCallback, CognitoUtil } from "../service/cognito.service";
import { DBService } from '../service/db.service';
import { ToastrService } from 'ngx-toastr';
import { Integer } from 'aws-sdk/clients/athena';
import { YogaDBService } from '../service/YogaDBService';
// import { GridOptions } from "ag-grid-community";

export class program {
  trainerId: string;
  programId: string;
  programName: string;
  plans: Integer;
  weeks: Integer;
  programDuration: Integer;
  price: number;
  currency: Object[];
  description: string;
  programImage: Object;
  status: string;
  adminStatus: string;
  startDate: Date;
  packageType: Object;
  enrollmentDate: Date;
  nutrition: Object;
  ProgramAddons: Object[];
  createdDate: Date;
  lastUpdateOn: Date;
  maxBmi: number;
  noOfSubscriptions: number;
}
@Component({
  selector: 'app-trainee',
  templateUrl: './trainee.component.html',
  styleUrls: ['./trainee.component.css']
})
export class TraineeComponent implements OnInit, OnDestroy, LoggedInCallback {

  currentCategory: string;
  currentGender: string;
  private sub: any;
  trainees: Object[];
  program: program;
  nutrition: string;
  googleUserEmail: string;
  private loggedIn: boolean;
  private subcrptn_1: any;
  currentLogged: string;
  currentEmail: string;
  public showProflPopup: boolean = false;
  searchTrainee: string;
  programImagePath: string;
  dropdownList = [];
  dropdownSettings = {};
  activeTrainees: Object[];
  pastTrainees: Object[];
  traineesLength: number = 0;
  activeTraineesLength: number = 0;
  pastTraineesLength: number = 0;
  trainee_id: string;
  program_id: string;

  rows = [];
  joke = 'knock knock';
  page: number = 1;

  category: string;
  // categoryObject: any = [];
  // isFitness: boolean;
  // isYoga: boolean;
  // isZumba: boolean;

  constructor(private db_service: DBService, private renderer: Renderer2, public router: Router,
    public route: ActivatedRoute, public userService: UserLoginService, private yoga_db_service: YogaDBService, public cognitoUtil: CognitoUtil, private toastr: ToastrService) {
    this.userService.isAuthenticated(this);
    // this.fetch((data) => {
    //   this.rows = data.splice(0, 5);
    // });
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.program_id = params['programID'];
  });
    this.program = new program();
    this.currentCategory = localStorage.getItem("ProfileCategory")
    this.currentGender = localStorage.getItem("ProfileGender");
    if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-build-male');
    }
    else if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-build-female');
    }
    else if (this.currentCategory == 'Yoga' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-yoga-male');
    }
    else if (this.currentCategory == 'Yoga' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-yoga-female');
    }
    else if (this.currentCategory == 'Zumba' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-zumba-male');
    }
    else if (this.currentCategory == 'Zumba' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-zumba-female');
    }
    this.category = localStorage.getItem("ProfileCategory");
    console.log("Current category:  " + this.category);

    this.sub = this.route.params.subscribe(params => {
      this.program.programId = params['programID'];
      this.getProgramDataFromerver();
    });
    this.userService.currentUserID.subscribe((val) => {
      if (val != '') {
        // this.program.trainerId = val;
      }
    });
    this.dropdownList = [
      { item_id: 1, item_text: 'Calendar' },
      { item_id: 2, item_text: 'Meal Plan' },
      { item_id: 3, item_text: 'Progress' },
      { item_id: 4, item_text: 'Connect' },
      { item_id: 5, item_text: 'Body Stats' }
    ];
    this.dropdownSettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    
   
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'bg-img-build-male');
    this.renderer.removeClass(document.body, 'bg-img-yoga-male');
    this.renderer.removeClass(document.body, 'bg-img-zumba-male');
    this.renderer.removeClass(document.body, 'bg-img-build-female');
    this.renderer.removeClass(document.body, 'bg-img-yoga-female');
    this.renderer.removeClass(document.body, 'bg-img-zumba-female');
    this.renderer.addClass(document.body, 'body-main');
  }

  // fetch(cb) {
  //   const req = new XMLHttpRequest();
  //   req.open('GET', `assets/data/company.json`);

  //   req.onload = () => {
  //     cb(JSON.parse(req.response));
  //   };

  //   req.send();
  // }
  getProgramDataFromerver() {
    if(this.category == "Bodybuilder"){
    var index = '/program/';
    this.db_service.getDataFromServer(this.program.programId, index).subscribe(
      response => {
        this.program = response.data;
        this.programImagePath = this.program.programImage["imgPath"];
        if( this.program.nutrition != undefined)
        this.nutrition = this.program.nutrition["name"];
        this.getTraineesData();
      },
      error => {
        // alert("An error has occurred while saving data.");
        this.toastr.error('', 'An error has occurred getting my program data.', {
          timeOut: 5000,
          extendedTimeOut: 1000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true
        });
      }
    )

  }else{
    var index = '/program/';
    this.yoga_db_service.getDataFromServer(this.program.programId, index).subscribe(
      response => {
        this.program = response.data;
        this.programImagePath = this.program.programImage["imgPath"];
        if( this.program.nutrition != undefined)
        this.nutrition = this.program.nutrition["name"];
        this.getTraineesData();
      },
      error => {
        // alert("An error has occurred while saving data.");
        this.toastr.error('', 'An error has occurred getting my program data.', {
          timeOut: 5000,
          extendedTimeOut: 1000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true
        });
      }
    )
  }
}
  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/login']);
    } else {
      // if()
      if (this.loggedIn == true) {
        this.currentEmail = localStorage.getItem('lastAuthUser');
        // if(this.currentEmail != undefined)
        this.getProfileDataFromServer();
      }
      else {
        // this.currentEmail = localStorage.getItem('CognitoIdentityServiceProvider.2io8v4r253hugt5ndbjv9shkfp.LastAuthUser');
        this.currentEmail = this.cognitoUtil.getCurrentUser().getUsername();
        if(this.currentEmail == undefined || this.currentEmail == '' || this.currentEmail == null || this.currentEmail.includes("Google") || this.currentEmail.includes("google"))
        {
          this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
          if(this.currentEmail == undefined)
          this.currentEmail = this.cognitoUtil.session.idToken.payload.email;
        }
        // this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
        this.getProfileDataFromServer();
      }
    }
  }
  getTraineesData() {
    if(this.category == "Bodybuilder"){
    var index = '/program/Fitness/' + this.program.programId + '/trainees/all';
    this.db_service.getDataFromServer("", index).subscribe(
      response => {
        //  console.log('Data retrived succesfully.', response);
        this.activeTrainees = response.data.filter(x => x.isProgramActive == true);
        this.pastTrainees = response.data.filter(x => x.isProgramActive == false);
        this.activeTraineesLength = this.activeTrainees.length;
        this.pastTraineesLength = this.pastTrainees.length;
        this.trainees = this.activeTrainees;

      },
      error => {
        console.log("An error occured while retrieving data.")
        // alert("An error has occurred while retriving profile data.");
        // this.toastr.error('', 'An error has occurred while retriving profile data', {
        //   timeOut: 3000,
        //   positionClass: 'toast-top-right',
        //   progressBar: true,
        //   progressAnimation: 'increasing',
        //   tapToDismiss: true,
        // });
      }
    )
    }else{
      var index = '/program/Yoga/' + this.program.programId + '/trainees/all';
      this.db_service.getDataFromServer("", index).subscribe(
        response => {
          //  console.log('Data retrived succesfully.', response);
          this.activeTrainees = response.data.filter(x => x.isProgramActive == true);
          this.pastTrainees = response.data.filter(x => x.isProgramActive == false);
          this.activeTraineesLength = this.activeTrainees.length;
          this.pastTraineesLength = this.pastTrainees.length;
          this.trainees = this.activeTrainees;
  
        },
        error => {
          // alert("An error has occurred while retriving profile data.");
          this.toastr.error('', 'An error has occurred while retriving profile data', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        }
      )
    }
  }
  onPastClients() {
    this.trainees = this.pastTrainees;
  }
  onActiveClients() {
    this.trainees = this.activeTrainees;
  }
  getProfileDataFromServer() {
    if(this.category == "Bodybuilder"){
    var index = '/profile/';
    this.db_service.getDataFromServer(this.currentEmail, index).subscribe(
      response => {
        //console.log('Data Retrived succesfully.', response);
        response = response.data[0];
        // this.program.trainerId = response.trainerId;
        // this.getMyProgramDataFromServer();
      },
      error => {
        console.log("An error has occurred while retriving profile data.");
      }
    )
    }else{
      var index = '/profile/';
      this.yoga_db_service.getDataFromServer(this.currentEmail, index).subscribe(
        response => {
          //console.log('Data Retrived succesfully.', response);
          response = response.data[0];
          // this.program.trainerId = response.trainerId;
          // this.getMyProgramDataFromServer();
        },
        error => {
          console.log("An error has occurred while retriving profile data.");
        }
      )
    }
  }



  onClickOnView(trainee_id) {
    if(this.category == "Bodybuilder"){
    this.trainee_id = trainee_id;
    var index = '/program/trainee/';
    var queryparams = {
      "program_id":this.program.programId,
      "trainee_id":trainee_id
    }
    this.db_service.getQueriedData("", index, queryparams).subscribe(
      response => {
        // this.program_id = response.data[0]["program_id"];
        // this.trainee = response.data[0]["trainee_height"];
        if (!response.data["profile_submission"]) {
          this.toastr.error('', 'Trainee profile submission is pending.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
          return;
        } else {
          this.router.navigate(['/tprofile/' + this.program_id + "/" + this.trainee_id]);
        }

      },
      error => {
        // alert("An error has occurred while retriving profile data.");
        this.toastr.error('', 'An error has occurred while retriving trainee profile data', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      }
    )
    }else{
      this.trainee_id = trainee_id;
      var index = '/program/trainee/';
      var queryparams = {
        "program_id":this.program.programId,
        "trainee_id":trainee_id
      }
      this.yoga_db_service.getQueriedData("", index, queryparams).subscribe(
        response => {
          // this.program_id = response.data[0]["program_id"];
          // this.trainee = response.data[0]["trainee_height"];
          if (!response.data["profile_submission"]) {
            this.toastr.error('', 'Trainee profile submission is pending.', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
              progressBar: true,
              progressAnimation: 'increasing',
              tapToDismiss: true,
            });
            return;
          } else {
            this.router.navigate(['/tprofile/' + this.program_id + "/" + this.trainee_id]);
          }
  
        },
        error => {
          // alert("An error has occurred while retriving profile data.");
          this.toastr.error('', 'An error has occurred while retriving trainee profile data', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        }
      )
    }
  }
  // show(){
  //   this.showProflPopup = true;
  // }
  // onNavigate(){

  // }
  onBack(){
    if(this.currentCategory == 'Bodybuilder')
      this.router.navigate(['/program']);
    if(this.currentCategory == 'Yoga') 
      this.router.navigate(['/yogaPrograms']);
  }
}
