<div class="fit-px-mt-5">
  <div class="heading1 mb-4 col-md-6 col-6" >
    <img src="assets/images/icons/reports.png" style="width: 35px; height: 35px; border: 1px solid yellowgreen; border-radius: 50%;"/><b>Reports</b>
  </div>
</div>

<div class="container-fluid">
  <div class="col-md-10 mx-auto" >
      <div class="row">
          <div class="col-md-6 my-1 mx-auto" #containerRef>
              <div class="col-md-12  chart-background">
                  <div class="row">
                    <!-- (window:resize)="onResize($event)" -->
                      <div class="text-center col-md-12 fit-chart-heading">Subscriptions</div>
                      <div class="col-md-12 ">
                          <ngx-charts-bar-vertical-2d  [yAxisTickFormatting]="yAxisTickFormattingFn"
                          [view]="[containerRef.offsetWidth, 400]" [scheme]="subscriptioncolorScheme" [showGridLines] ="true" [showDataLabel] ="true"
                          [tooltipDisabled] = "tooltipDisabled" [groupPadding] = "subscriptionsbarPadding" [results]="Subscriptions"
                           [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel"
                          [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="subscriptionxAxisLabel" [yAxisLabel]="subscriptionyAxisLabel"
                          [legendTitle]="legendTitle"></ngx-charts-bar-vertical-2d>
                      </div>
                  </div>
              </div>
          </div>

          <div class="col-md-6 my-1 mx-auto" #containerRef2>
              <div class="col-md-12  chart-background">
                  <div class="row">
                      <div class="text-center col-md-12 fit-chart-heading">Total Revenue</div>
                      <div class="col-md-12">
                          <ngx-charts-bar-vertical-2d [view]="[containerRef2.offsetWidth, 400]" [tooltipDisabled] = "tooltipDisabled" [showDataLabel] ="true" [groupPadding] = "totalrevenuePadding" [scheme]="totalrevenuecolorScheme" [results]="Totalrevenue" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="totalrevenuexAxisLabel" [yAxisLabel]="totalrevenueyAxisLabel" [legendTitle]="legendTitle"></ngx-charts-bar-vertical-2d>
                      </div>
                  </div>
              </div>
          </div>

          <div class="col-md-6 my-1 mx-auto" #containerRef3>
              <div class="col-md-12  chart-background">
                  <div class="row">
                      <div class="text-center col-md-12 fit-chart-heading">Programs</div>
                      <div class="col-md-12">
                          <ngx-charts-pie-chart [view]="[containerRef3.offsetWidth, 400]"  [scheme]="colorScheme"  [results]="single" [gradient]="gradient" [legend]="showLegend" [legendTitle] = "legendTitle" [labels]="showLabels" [doughnut]="isDoughnut" ></ngx-charts-pie-chart>
                      </div>
                  </div>
              </div>
          </div>

          <div class="col-md-6 my-1 mx-auto" #containerRef4>
              <div class="col-md-12  chart-background">
                  <div class="row">
                      <div class="col-md-12 text-center fit-chart-heading">Calendar Publish</div>
                      <div class="col-md-12">
                          <ngx-charts-bar-vertical-2d [yAxisTickFormatting]="yAxisTickFormattingFn" [tooltipDisabled] = "tooltipDisabled" [showDataLabel] ="true"  [groupPadding] = "calendarPadding"  [view]="[containerRef4.offsetWidth, 400]" [scheme]="calendarcolorScheme"  [results]="multi" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="subscriptionxAxisLabel" [yAxisLabel]="subscriptionyAxisLabel" [legendTitle]="legendTitle"></ngx-charts-bar-vertical-2d>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-md-6 my-1 mx-auto" #containerRef5>
              <div class="col-md-12  chart-background">
                  <div class="row">
                      <div class="col-md-12 text-center fit-chart-heading">Call usage</div>
                      <div class="col-md-12">
                          <ngx-charts-bar-vertical-2d [view]="[containerRef5.offsetWidth, 400]" [tooltipDisabled] = "tooltipDisabled" [showDataLabel] ="true" [groupPadding] = "callPadding"   [scheme]="subscriptioncolorScheme"   [results]="callusage" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="subscriptionxAxisLabel" [yAxisLabel]="subscriptionyAxisLabel" [legendTitle]="legendTitle"></ngx-charts-bar-vertical-2d>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
