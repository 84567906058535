<!--The content below is only a placeholder and can be replaced.-->


<!-- HEADER SECTION START -->
  <app-header *ngIf="routeRedirect"></app-header>
<!-- HEADER SECTION END -->


  <router-outlet></router-outlet>
<!-- <router-outlet name="loginpopup"></router-outlet> -->

<!-- FOOTER SECTION START -->
 <!-- <app-footer *ngIf="routeRedirect"></app-footer> -->
<!-- FOOTER SECTION END -->

