<section>
  <div class="container-fluid">
    <div class="fit-px-mt-5">
      <div class="row">
        <div class="col">
          <div class="row">
            <div class="container-fluid">
              <div>
                <div class="heading1 mb-4" style="display: flex; float: left;">
                  <!-- <img width="90%" src="assets/images/icons/scheduler.png"/> -->
                  <img src="assets/images/icons/scheduler.png" style="width: 42px; height: 42px;" />
                  <h1><b>Scheduler</b></h1>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div>
                <button type="button" class="btn btn-circle prevBtn mx-0" (click)="decreaseMonth()">
                  <i class="fas fa-angle-left"></i>
                </button>
                <span style="font-size: 20px; font-weight: bold; padding: 6px;">
                  {{ displayMonth }} {{ displayYear }}
                </span>
                <button type="button" class="btn btn-circle nextBtn mx-0" id="nextBtn" (click)="increaseMonth()">
                  <i class="fas fa-angle-right"></i>
                </button>
                <!-- <button
                  type="button"
                  class="btn btn-default save-exercise-btn"
                  style="margin-top: 10px; float: right;"
                  routerLink="/program"
                >
                  BACK
                </button> -->
              </div>
            </div>
            <div class="calendar__table">
              <div class="head_layout">
                <div class="head_row" fxLayout="row wrap" fxLayoutAlign="flex-start" style="width: 100%; display: grid !important; grid-template-columns: repeat(7, 1fr) !important;
                text-align: center !important;">
                  <!-- <div class="head_content" style="width: 10.5%;"></div> -->
                  <ng-container *ngFor="let dayName of calendarDayNames">
                    <div class="head_content" fxFlex="0 1 calc(14.2%)"
                      style="color: rgb(255, 255, 255); font-weight: 700;">
                      {{ dayName }}
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="body_layout d-flex" style="width: 100%; ">
                <div class="body_row call-schd-row" fxLayout="row wrap" fxLayoutAlign="flex-start" style="width: 100%; display: grid !important; grid-template-columns: repeat(7, 1fr) !important;
                text-align: center !important;">
                  <ng-container *ngFor="let day of calendar; let i = index">
                    <div [ngClass]="{
                        'past-month': day.isPastMonth,
                        today: day.isToday
                      }" class="body_content" fxFlex="0 1 calc(14.21%)" style="padding: 5px;">
                      <div class="cal-day d-flex">

                        <div class="edit-day col" (click)="onAddSlot(day.date)" data-toggle="modal"
                          data-target="#showAddSlot-popup">
                          <a _ngcontent-c7="" class="add-fa-icon" title="Add slot"><img src="assets/images/add-sch.png">
                          </a>
                          <!-- <i class="fa fa-pencil-square-o"></i>
                        <strong style="cursor: pointer;"> Edit</strong> -->
                        </div>


                        <div class="col-auto">
                          <strong class="float-right" [ngClass]="{
                            'past-month': day.isPastMonth,
                            today: day.isToday
                          }">{{ day.date.getDate() }}
                          </strong>
                        </div>
                      </div>

                      <div class="time-slot-day col-md-10 mx-auto"
                        style="max-height:77px; overflow-y: auto; overflow-x: hidden; max-width: inherit; ">
                        <div class="time-slot-name col-md-10 p-0">
                          <!-- <div *ngFor="let dy of slots"> -->

                          <div *ngFor="let s of daysdata">
                            <div *ngIf="compareDates(day.date,s.date)" class="d-flex">
                              <div class="col-md-12 p-0"><strong>{{s.startTime}} -
                                  {{s.endTime}}</strong>
                              </div>

                              <a *ngIf="s.status == 'rescheduled'" style="cursor: pointer;" title="Slot rescheduled">
                                <img class="time-slot-img" src="assets/images/reschedule.png" />
                              </a>

                              <a *ngIf="s.status == 'cancelled'" style="cursor: pointer;" title="Slot cancelled">
                                <img class="time-slot-img" src="assets/images/call-cancel.png" />
                              </a>

                              <a *ngIf="s.status == 'completed'" style="cursor: pointer;" title="Call completed">
                                <img class="time-slot-img" src="assets/images/call-complete.png" />
                              </a>
                              <!-- New code -->
                              <!-- <a *ngIf="(s.status == 'open')">
                                <img class="time-slot-img" src="assets/images/icons/call-enable.png"
                                  (click)="initiateCall()" data-toggle="modal"
                                  style="cursor: pointer;" title="Start Call" />
                              </a> -->

                              <a *ngIf="(s.status == 'booked' && s.callType == '1o1') || s.status == 'pending'">
                                <img class="time-slot-img" src="assets/images/icons/user-do.png"
                                  (click)="getTraineeDetails(day.date, s.slotId,'1o1')" data-toggle="modal"
                                  data-target="#show1o1CallList-popup" style="cursor: pointer;" title="Trainees" />
                              </a>
                              <a *ngIf="s.status == 'open'  && s.participants.length == 0 && s.callType == '1o1'">
                                <img class="time-slot-img" src="assets/images/icons/user-no.png"
                                  style="cursor: pointer;" title="No trainees" />
                              </a>
                              <a *ngIf="s.status == 'open'  && s.participants.length == 0 && s.callType == 'group'">
                                <img class="time-slot-img" src="assets/images/icons/Group 81.png"
                                  style="cursor: pointer;" title="No trainees" />
                              </a>
                              <a
                                *ngIf="(s.status == 'booked' || (s.status == 'open'  && s.participants.length != 0) || s.status == 'pending') && s.callType=='1o1'">
                                <img class="time-slot-img" src="assets/images/icons/edit-pencil.png"
                                  style="cursor: pointer;" title="No edit" />
                                <!-- <i class="fa fa-info-circle" aria-hidden="true"
                                     style="color: yellowgreen; cursor: pointer;" ></i> -->
                              </a>
                              <a *ngIf="s.status == 'open' && s.participants.length == 0 && s.callType=='1o1'">
                                <img class="time-slot-img" src="assets/images/icons/edit-do.png"
                                  (click)="onEditSlot(day.date, s.slotId)" data-toggle="modal"
                                  data-target="#showEditSlot-popup" style="cursor: pointer;" title="Edit" />

                              </a>


                              <a *ngIf="s.participants.length != 0 && s.callType == 'group' && s.status != 'cancelled'">
                                <img class="time-slot-img" src="assets/images/icons/Group-call.png"
                                  (click)="getTraineeDetails(day.date, s.slotId,'group')" data-toggle="modal"
                                  data-target="#showGroupCallList-popup" style="cursor: pointer;" title="Trainees" />
                              </a>

                              <a *ngIf="s.callType == 'group' && currentCategory == 'Yoga'">
                                <!-- <img class="time-slot-img" src="assets/images/icons/edit-do.png"
                                    (click)="onEditSlot(day.date, s.slotId)" data-toggle="modal"
                                    data-target="#showEditSlot-popup" style="cursor: pointer;" title="Edit" /> -->
                                <i class="fa fa-info-circle" aria-hidden="true" (click)="onViewSlot(day.date, s.slotId)"
                                  data-toggle="modal" data-target="#showViewSlot-popup"
                                  style="color: yellowgreen; cursor: pointer;"></i>
                              </a>

                            </div>
                          </div>
                          <!-- </div> -->
                        </div>
                        <!-- <div class="col-sm-4 col-md-5 text-right p-0">
                          <a>
                            <img class="time-slot-img" src="assets/images/icons/user-do.png" />
                          </a>
                          <a>
                            <img class="time-slot-img" src="assets/images/icons/user-no.png" />
                          </a>
                          <a>
                            <img class="time-slot-img" src="assets/images/icons/group.png" />
                          </a>
                          <a>
                            <img class="time-slot-img" src="assets/images/icons/edit-pencil.png" />
                          </a>
                          <a>
                            <img class="time-slot-img" src="assets/images/icons/edit-do.png" />
                          </a>
                        </div> -->
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="showAddSlot">
      <div class="workout-popup__wrapper modal fade" id="showAddSlot-popup" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="upcoming-popup modal-dialog calendar-sched-pop zoomIn exercise-vid-popup background-shadow-green">
          <div class="workout-body modal-content">
            <a (click)="closePopup()" class="close-program_event-pop" style="position: absolute; left: 94%; top: 1%;"><i
                class="fas fa-times"></i></a>
            <div class="container-fluid" style="font-size: 15px;">
              <div class="row">
                <div class="col-md-12 py-2 d-flex" style="border-bottom: 1px dashed #919191;">
                  <div class="col-md-6 p-0 text-left font-weight-bolder" style="font-size: 18px;">Add Slot
                  </div>
                  <div class="col-md-6 p-0 text-right">{{ currentClickDate | date: "dd/MM/yyyy" }}
                  </div>
                </div>
                <div class="col-md-10 mx-auto mt-2">
                  <div class="row">
                    <div class="col-md-5 col-5 py-1 d-flex text-right">
                      <input id="radio-1" name="is1o1" type="radio" checked style="margin: 5px; width: 15px;"
                        [checked]="is1o1" (change)="onCallTypeChange('1o1')">
                      <img src="assets/images/icons/single-call.png" alt="add" width="15" height="15"
                        style="margin: 5px;">
                      <label for="radio-1" class="radio-label">1o1 Call</label>
                    </div>
                    <div class="col-md-6 col-6 py-1 d-flex text-left" *ngIf="currentCategory != 'Yoga'">
                      <input id="radio-1" name="isGroup" type="radio" style="margin: 5px; width: 15px;"
                        [checked]="isGroup" (change)="onCallTypeChange('group')">
                      <img src="assets/images/icons/Group-call.png" alt="add" width="30" height="15"
                        style="margin: 5px;">
                      <label for="radio-1" class="radio-label">Group Call</label>
                    </div> 
                  </div>

                </div>

                <div class="col-md-12 mt-2">
                  <div class="row">
                    <div class="form-group col-md-6 col-6 p-1" style="margin-bottom: 15px !important;">
                      <!-- <input class="form-control col-12" type=time style="border: 1px solid #9acd32 !important; border-radius: 5px !important;"> -->
                      <ng-multiselect-dropdown id="startTime" name="startTime"
                      [disabled]="disabled"
                      [settings]="dropdownSettings"
                      [data]="timeintervals"
                      [(ngModel)]="selectedStartTime"
                      (onSelect)="calculateEndTime()" required
                      class="form-control col-12 schedule-call-style-dropdown"
                      style="border: 1px solid #9acd32 !important; border-radius: 5px !important;"
                    >
                    </ng-multiselect-dropdown>
                      <span class="floating-label"
                        style="top: -14px !important; left: 21px !important; background: #000 !important; font-size: 13px;color: #d2d2d2;">Start
                        time</span>
                      <!-- <i class="fa fa-clock-o" aria-hidden="true"
                        style="position: absolute; left: 160px; top: 11px;"></i> -->
                    </div>
                    <div class="form-group col-md-6 col-6 p-1" style="margin-bottom: 15px !important;">
                      <input class="form-control col-12" type=text [(ngModel)]="selectedEndTime" [disabled]="true"
                        style="border: 1px solid #9acd32 !important; border-radius: 5px !important;">
                      <!-- <ng-multiselect-dropdown id="endTime" name="endTime" [data]="timeintervals" [disabled]="true"
                        [(ngModel)]="selectedEndTime" [settings]="dropdownSettings" required
                        class="form-control col-12 schedule-call-style-dropdown"
                        style="border: 1px solid #9acd32 !important; border-radius: 5px !important;">
                      </ng-multiselect-dropdown> -->
                      <span class="floating-label"
                        style="top: -14px !important; left: 21px !important; background: #000 !important;font-size: 13px;color: #d2d2d2;">End
                        time</span>
                      <!-- <i class="fa fa-clock-o" aria-hidden="true"
                        style="position: absolute; left: 160px; top: 11px;"></i> -->
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="row">
                    <div class="form-group col-md-6 col-12 p-1" *ngIf="isGroup" style="margin-bottom: 15px !important;">
                      <ng-multiselect-dropdown  class="form-control col-12" name="program"
                      
                      [settings]="dropdownSettings_single_program"
                      [data]="programs"
                      [(ngModel)]="selectedProgram"
                      [disabled]="disabled"
                      style="border: 1px solid #9acd32 !important; border-radius: 5px !important;"
                    >
                    </ng-multiselect-dropdown>
                    <span class="floating-label"
                    style="top: -14px !important; left: 21px !important; background: #000 !important;font-size: 13px;color: #d2d2d2;">Program</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="row">
                    <div class="form-group col-md-4 p-1">
                      <input class="form-control col-12" type="number" OnlyPositiveNumbers numericType="number"
                        id="numberOfSlots" name="numberOfSlots" [(ngModel)]="numberOfSlots" (input)="calculateEndTime()"
                        style="border: 1px solid #9acd32 !important; border-radius: 5px !important;">
                      <span class="floating-label"
                        style="top: -11px !important;left: 9px !important;background: #000 !important;">Number of
                        slots</span>
                    </div>
                    <div class="form-group col-md-4 p-1">
                      <!-- <input class="form-control col-12"
                        style="border: 1px solid #9acd32 !important; border-radius: 5px !important;"> -->
                        <ng-multiselect-dropdown  class="form-control col-12" name="frequency"
                        [settings]="dropdownSettings"
                         [data]="frequencies"
                         [(ngModel)]="selectedFrequency"
                         [disabled]="disabled"
                         style="border: 1px solid #9acd32 !important; border-radius: 5px !important;"
                           (onSelect)="calculateEndTime()"
                       >
                       </ng-multiselect-dropdown>
                      <span class="floating-label"
                        style="top: -11px !important; left: 19px !important; background: #000 !important;font-size: 13px;color: #d2d2d2;">Duration
                        slot</span>

                    </div>
                    <div class="form-group col-md-4 p-1">
                      <!-- <input class="form-control col-12"
                        style="border: 1px solid #9acd32 !important; border-radius: 5px !important;"> -->
                        <ng-multiselect-dropdown class="form-control col-12" name="delay"
                        [settings]="dropdownSettings"
                        [data]="delays"
                        [(ngModel)]="selectedDelay"
                        [disabled]="disabled"
                        (onSelect)="calculateEndTime()"
                        style="border: 1px solid #9acd32 !important; border-radius: 5px !important;"
                      >
                      </ng-multiselect-dropdown>
                      <span class="floating-label"
                        style="top: -11px !important; left: 19px !important; background: #000 !important;font-size: 13px;color: #d2d2d2;">Delayed
                        slots</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-9 p-0 text-center mx-auto">
                  <div class="btn mx-4" style="padding: 4px 29px;" (click)="closePopup()">Cancel
                  </div>
                  <div class="btn mx-3" style="padding: 4px 29px;" (click)="saveSlot()">
                    Save
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="workout-footer d-flex col-12 justify-content-end">
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="showEditSlot">
      <div class="workout-popup__wrapper modal fade" id="showEditSlot-popup" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div
          class="modal-dialog calendar-sched-pop upcoming-popup p-0 zoomIn exercise-vid-popup background-shadow-green">


          <div class="workout-body modal-content">
            <a (click)="closePopup()" class="close-program_event-pop" style="position: absolute; left: 94%; top: 1%;"><i
                class="fas fa-times"></i></a>
            <div class="container-fluid" style="font-size: 15px;">
              <div class="row">
                <div class="col-md-12 py-2 d-flex" style="border-bottom: 1px dashed #919191;">
                  <div class="col-md-6 p-0 text-left font-weight-bolder" style="font-size: 18px;">Edit Slot
                  </div>
                 
                  <div class="col-md-6 p-0 text-right">{{ currentClickDate | date: "dd/MM/yyyy" }}
                  </div>
                </div>
                <div class="col-md-8 mx-auto mt-2 p-1">
                  <div class="row">
                    <div class="col-md-5 col-5 py-1 d-flex text-right">
                      <input id="radio-1" name="is1o1" type="radio" checked style="margin: 5px; width: 15px;"
                        [checked]="is1o1" (change)="onCallTypeChange('1o1')">
                      <img src="assets/images/icons/single-call.png" alt="add" width="15" height="15"
                        style="margin: 5px;">
                      <label for="radio-1" class="radio-label">1o1 Call</label>
                    </div>
                    <div class="col-md-6 col-6 py-1 d-flex text-left">
                      <input id="radio-1" name="isGroup" type="radio" style="margin: 5px; width: 15px;"
                        [checked]="isGroup" (change)="onCallTypeChange('group')" *ngIf="currentCategory != 'Yoga'">
                      <img src="assets/images/icons/Group-call.png" alt="add" width="30" height="15"
                        style="margin: 5px;" *ngIf="currentCategory != 'Yoga'">
                      <label for="radio-1" class="radio-label" *ngIf="currentCategory != 'Yoga'">Group Call</label>
                      <img src="assets/images/icons/add-icon.png" class="schedule-add-icon" alt="add"
                        style="margin: 5px;" (click)="onAddSlot(currentClickDate)">
                    </div>
                  </div>

                </div>
                <div class="col-md-12">
                  <div class="row">
                    <div class="form-group col-md-6 col-6" *ngIf="isGroup">
                      <ng-multiselect-dropdown class="form-control col-12" name="program"
                      [settings]="dropdownSettings_single_program"
                      [data]="programs"
                      [(ngModel)]="selectedProgram"
                      [disabled]="disabled"
                      style="border: 1px solid #9acd32 !important; border-radius: 5px !important;"
                    >
                    </ng-multiselect-dropdown>
                      <span class="floating-label"
                        style="top: -14px !important; left: 21px !important; background: #000 !important;">Choose
                        Program</span>
                       
                    </div>
                  </div>
                </div>
                <div class="col-md-12 mt-2">
                  <div class="row">
                    <div class="form-group mb-0 col-md-5 col-5 ">
                      <ng-multiselect-dropdown id="startTime" name="startTime"
                      [disabled]="disabled"
                      [settings]="dropdownSettings"
                      [data]="timeintervals"
                      [(ngModel)]="selectedStartTime"
                      (onSelect)="calculateEndTime()" required class="form-control col-12"
                      style="border: 1px solid #9acd32 !important; border-radius: 5px !important;"
                    >
                    </ng-multiselect-dropdown>
                      <!-- <input class="form-control col-12" type=time style="border: 1px solid #9acd32 !important;  border-radius: 5px !important;font-size:14px !important; font-weight:500 !important;"> -->
                      <span class="floating-label"
                        style="top: -14px !important; left: 21px !important; background: #000 !important;">Start
                        time</span>
                      <!-- <i class="fa fa-clock-o" aria-hidden="true" style="position: absolute; left: 130px; top: 11px;"></i> -->
                    </div>
                    <div class="form-group mb-0 col-md-5 col-5">
                      <!-- <ng-multiselect-dropdown id="endTime" name="endTime" [data]="timeintervals" [disabled]="true"
                        [(ngModel)]="selectedEndTime" [settings]="dropdownSettings" required class="form-control col-12"
                        style="border: 1px solid #9acd32 !important; border-radius: 5px !important;">
                      </ng-multiselect-dropdown> -->
                      <input class="form-control col-12" type=text [(ngModel)]="selectedEndTime" [disabled]="true"
                        style="border: 1px solid #9acd32 !important; border-radius: 5px !important;">
                      <!-- <input class="form-control col-12" type=time style="border: 1px solid #9acd32 !important;  border-radius: 5px !important;"> -->
                      <span class="floating-label"
                        style="top: -14px !important; left: 21px !important; background: #000 !important;">End
                        time</span>
                      <!-- <i class="fa fa-clock-o" aria-hidden="true" style="position: absolute; left: 130px; top: 11px;"></i> -->
                    </div>
                     <div class="col-md-2 col-2">
                      <img src="assets/images/icons/delete-call.png" alt="add" width="22" height="26"
                        style="margin: 5px;" (click)="onDeleteSlot()">
                    </div> 
                  </div>
                </div>

                <div class="col-md-9 mt-3 p-0 text-center mx-auto">
                  <div class="btn mx-4" style="padding: 4px 29px;" (click)="closePopup()">Cancel
                  </div>
                  <div class="btn mx-3" style="padding: 4px 29px;" (click)="onSlotEditSubmit()">
                    Save
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="workout-footer d-flex col-12 justify-content-end">
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="show1o1CallList">
      <div class="workout-popup__wrapper modal fade" id="show1o1CallList-popup" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div
          class="modal-dialog upcoming-popup calendar-sched-pop p-0 zoomIn exercise-vid-popup background-shadow-green"
          style="top: 40% !important;">


          <div class="workout-body modal-content">
            <a (click)="closePopup()" class="close-program_event-pop" style="position: absolute; left: 94%; top: 1%;"><i
                class="fas fa-times"></i></a>
            <div class="container-fluid" style="font-size: 15px;">
              <div class="row">
                <div class="col-md-12 py-2 d-flex" style="border-bottom: 1px dashed #919191;">
                  <div class="col-md-9 p-0 text-left font-weight-bolder" style="font-size: 18px;">Upcoming Calls
                  </div>
                  <div class="col-md-3">
                    <div class="text-right">
                      <img src="assets/images/icons/single-call.png" alt="add" width="15" height="15"
                        style="margin: 5px;">
                      <span for="radio-1" class="radio-label">1o1 Call</span>
                    </div>
                  </div>
                </div>
                <div class="container-fluid" style="height: 203px; overflow-y: scroll; overflow-x: hidden;">
                  <div class="row" style="border-bottom: 1px solid yellowgreen;"
                    *ngFor="let participant of upComming1o1Slots">
                    <div class="col-md-12 my-2">
                      <div class="row">
                        <div class="col-md-12 font-weight-bolder">
                          <span style="color:#8dc63f">Name: </span>
                          <span>{{participant.first_name}}</span>
                          <span>{{participant.last_name}}</span>
                        </div>



                        <div class="col-md-12">

                          <span class="text-right font-weight-bolder" style="color:#8dc63f">Slot: </span>
                          <span class="">{{participant.startTime}}-</span>
                          <span class="">{{participant.endTime}}</span>

                        </div>
                      </div>
                    </div>
                    <div class="col-md-3 my-3 user-avatar">
                      <img class="rounded-circle" src="{{participant.traineeProfileImg.profileImagePath}}" alt="image"
                        style="width: 100px; height: 100px;" *ngIf="participant.traineeProfileImg != undefined">
                      <img class="rounded-circle" src="assets/images/default_profile_img.png" alt="image"
                        style="width: 100px; height: 100px;" *ngIf="participant.traineeProfileImg == undefined">
                    </div>
                    <div class="col-md-5 my-3">
                      <div class="d-flex col-md-12">
                        <div class="col-md-6 text-right font-weight-bolder" style="color:#8dc63f"> Age:</div>
                        <div class="col-md-6 text-left p-0" *ngIf="participant.age"> {{participant.age}}</div>
                      </div>
                      <div class="d-flex col-md-12">
                        <div class="col-md-6 text-right font-weight-bolder" style="color:#8dc63f"> Height:</div>
                        <div class="col-md-6 text-left p-0" *ngIf="participant.trainee_height">{{participant.trainee_height.height | number : '1.2-2' }}
                          {{participant.trainee_height.metrics}} {{participant.trainee_height.metric}} </div>
                      </div>
                      <div class="d-flex col-md-12">
                        <div class="col-md-6 text-right font-weight-bolder" style="color:#8dc63f"> Weight:</div>
                        <div class="col-md-6 text-left p-0" *ngIf="participant.currrent_weight">{{participant.currrent_weight.weight | number : '1.2-2' }}
                          {{participant.currrent_weight.metric}}</div>
                      </div>

                    </div>
                    <div class="col-md-6 mx-auto my-3" *ngIf="participant.status != 'pending'">
                      <div class="col-md-12 col-6 mx-auto">
                        <img src="assets/images/icons/call-enable.png" alt="profile-image"
                          style="height: 50px; width: 50px;"
                          (click)="onJoinRoom(participant.slotId, participant.callType)">
                        <img src="assets/images/icons/call-reschedule.png" alt="profile-image"
                          style="height: 50px; width: 50px;" (click)="onReschedule(participant.slotId)">
                        <img src="assets/images/icons/call-end.png" alt="profile-image"
                          style="height: 50px; width: 50px;"
                          (click)="onCancelClick(participant.slotId, participant.startTime)">

                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="workout-footer d-flex col-12 justify-content-end">
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="showGroupCallList">
      <div class="workout-popup__wrapper  modal fade" id="showGroupCallList-popup" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog calendar-sched-pop p-0 zoomIn exercise-vid-popup background-shadow-green"
          style="top: 40% !important; width: 37% !important;">


          <div class="workout-body modal-content">
            <a (click)="closePopup()" class="close-program_event-pop" style="position: absolute; left: 94%; top: 1%;"><i
                class="fas fa-times"></i></a>
            <div class="container-fluid" style="font-size: 15px;">
              <div class="row">
                <div class="col-md-12 py-2 d-flex" style="border-bottom: 1px dashed #919191;">
                  <div class="col-md-8 p-0 text-left font-weight-bolder">
                    <div style="font-size: 18px;">Upcoming Calls </div>
                    <div class="col-md-12">
                      <div class="text-right">
                        <img alt="add" height="15" src="assets/images/icons/Group-call.png"
                          style="margin: 5px; width: 30px;">
                        <span class="radio-label" for="radio-1">1o1 Group Call</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="col-md-12 p-0 d-flex text-right">
                      <div class="float-right">
                        <span class="text-right font-weight-bolder" style="color:#8dc63f">Participants: </span>
                        <span class="text-right ">{{slot.maxParticipants}}</span>
                      </div>
                    </div>
                    <div class="col-md-12 p-0 d-flex text-right">
                      <div class="float-right">
                        <span class="text-right font-weight-bolder" style="color:#8dc63f">Slot: </span>
                        <span class="">{{slot.startTime}}-</span>
                        <span class="">{{slot.endTime}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="container-fluid" style="height: 203px; overflow-y: scroll;">
                  <div class="row" *ngFor="let participant of upCommingGroupCalls">
                    <div class="col-md-12 my-2">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="row">
                            <div class="col-md-12 text-right font-weight-bolder"><span style="color:#8dc63f">Name:
                              </span> <span>{{participant.first_name}}</span> <span>{{participant.last_name}}</span>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="col-md-3 my-3 user-avatar">
                      <img class="rounded-circle" src="{{participant.traineeProfileImg.profileImagePath}}" alt="image"
                      style="width: 100px; height: 100px;" *ngIf="participant.traineeProfileImg != undefined">
                    <img class="rounded-circle" src="assets/images/default_profile_img.png" alt="image"
                      style="width: 100px; height: 100px;" *ngIf="participant.traineeProfileImg == undefined">
                    </div>
                    <div class="col-md-5 mt-3 p-0">
                      <div class="d-flex col-md-12">
                        <div class="col-md-6 text-right font-weight-bolder" style="color:#8dc63f"> Age:</div>
                        <div class="col-md-6 text-left p-0" *ngIf="participant.age"> {{participant.age}}</div>
                      </div>
                      <div class="d-flex col-md-12">
                        <div class="col-md-6 text-right font-weight-bolder" style="color:#8dc63f"> Height:</div>
                        <div class="col-md-6 text-left p-0" *ngIf="participant.trainee_height"> {{participant.trainee_height.height}}
                          {{participant.trainee_height.metrics}}</div>
                      </div>
                      <div class="d-flex col-md-12">
                        <div class="col-md-6 text-right font-weight-bolder" style="color:#8dc63f"> Weight:</div>
                        <div class="col-md-6 text-left p-0" *ngIf="participant.currrent_weight">{{participant.currrent_weight.weight}}
                          {{participant.currrent_weight.metric}}</div>
                      </div>
                      
                    </div>

                  </div>

                </div>

              </div>
              <div class="col-md-auto mx-auto" style="transform: translateY(23%);">
                <div class="col-md-12 col-6 mx-auto">
                  <img src="assets/images/icons/call-enable.png" alt="profile-image" style="height: 50px; width: 50px;"
                    (click)="onJoinRoom(slot.slotId,slot.callType,slot.schedulerId)">
                  <!-- <img src="assets/images/icons/call-reschedule.png" alt="profile-image"
                    style="height: 50px; width: 50px;"> -->
                  <img src="assets/images/icons/call-end.png" alt="profile-image" style="height: 50px; width: 50px;"
                    (click)="onCancelClick(slot.slotId,slot.schedulerId,slot.startTime)">
                </div>
              </div>
            </div>

          </div>
          <div class="workout-footer d-flex col-12 justify-content-end">
          </div>
        </div>
      </div>
    </ng-container>


    <ng-container *ngIf="showReschedulePopup">
      <div class="workout-popup__wrapper">
        <div class="calendar-sched-pop p-0 zoomIn exercise-vid-popup background-shadow-green"
          style="top: 40% !important; width: 42% !important;">

          <a (click)="closePopup()" class="close-program_event-pop" style="position: absolute; left: 94%; top: 1%;"><i
              class="fas fa-times"></i></a>
          <div class="workout-body">
            <div class="container-fluid" style="font-size: 15px;">
              <div class="row">
                <div class="col-md-12 py-2 d-flex" style="border-bottom: 1px dashed #919191;">
                  <div class="col-md-12 p-0 text-left font-weight-bolder">
                    <div style="font-size: 18px;">Reschedule Slot</div>
                  </div>
                </div>
                <div class="container-fluid">
                  <div class="py-3 container-fluid">
                    <div class="row">

                      <div class="col-md-6 mx-auto">
                        <div class="d-flex">
                          <div class="col-md-2 my-1">Date:</div>
                          <div class="col">
                            <!-- <input type="date" (change)="onDateChange()" [(ngModel)]="currentDate"> -->
                            <my-date-picker name="selectedReDate" [(ngModel)]="selectedReDate"
                              [options]="myDatePickerOptions" (dateChanged)="onDateChanged($event)" placeholder="date">
                            </my-date-picker>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 mx-auto">

                        <div class="col-md-12">
                          <div class="d-flex">
                            <span class="my-1"> Available Slots: </span>
                            <span>
                              <ng-multiselect-dropdown class="form-control fit-call-schedular-drop-down"
                              [placeholder]="'Select slot'" name="selectedSlot"
                              [settings]="dropdownSettings_single"
                              [data]="availableSlots"
                              [(ngModel)]="selectedSlot"
                           >
                            </ng-multiselect-dropdown>
                            </span>
                          </div>
                        </div>


                      </div>
                    </div>

                  </div>
                  <div class="col-md-10 mx-auto py-2">

                    <textarea class="form-control" aria-label="With textarea" rows="5" [(ngModel)]="message"
                      [placeholder]="'Comments'"></textarea>

                  </div>

                  <div class="col-md-9 p-0 text-center mx-auto">

                    <div class="btn mx-3" style="padding: 4px 29px;" (click)="onRescheduleSubmit()">
                      Save
                    </div>
                  </div>
                  <div class="col-md-6 mx-auto" style="transform: translateY(23%);">
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="workout-footer d-flex col-12 justify-content-end">
          </div>

        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="showCancelPopup">
      <div class="workout-popup__wrapper">
        <div class="calendar-sched-pop p-0 zoomIn exercise-vid-popup background-shadow-green"
          style="top: 40% !important; width: 37% !important;">

          <a (click)="closePopup()" class="close-program_event-pop" style="position: absolute; left: 94%; top: 1%;"><i
              class="fas fa-times"></i></a>
          <div class="workout-body">
            <div class="container-fluid" style="font-size: 15px;">
              <div class="row">
                <div class="col-md-12 text-center" style="    font-size: 18px;"> Are you sure to cancel </div>
                <div class="col-md-6 mx-auto my-3 text-center">

                  <div class="btn" (click)="onCancelSlot()"> Yes </div>
                  <div class="btn" (click)="closePopup()"> No </div>

                </div>
              </div>
            </div>

          </div>
          <div class="workout-footer d-flex col-12 justify-content-end">
          </div>
        </div>
      </div>
    </ng-container>
    <!-- showDeletePopup -->
    <ng-container *ngIf="showDeletePopup">
      <div class="program_event-popup__wrapper">
        <div class="program_event-pop zoomIn background-shadow-green">
          <div class="program_event-header text-center">
            <h4>Are you sure</h4>
          </div>
          <a (click)="closePopup()" class="close-program_event-pop"><i class="fas fa-times"></i></a>
          <div class="program_event-body d-flex justify-content-center">
            <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
              (click)="onConfirmDelete()">Yes</button>
            <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
              (click)="closePopup()">No</button>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="showGroupCallDetails">
      <div class="workout-popup__wrapper modal fade" id="showViewSlot-popup" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div
          class="modal-dialog calendar-sched-pop upcoming-popup p-0 zoomIn exercise-vid-popup background-shadow-green">


          <div class="workout-body modal-content">
            <a (click)="closePopup()" class="close-program_event-pop" style="position: absolute; left: 94%; top: 1%;"><i
                class="fas fa-times"></i></a>
            <div class="container-fluid" style="font-size: 15px;">
              <div class="row">
                <div class="col-md-12 py-2 d-flex" style="border-bottom: 1px dashed #919191;">
                  
                  <div class="col-md-6 p-0 text-left font-weight-bolder" style="font-size: 18px;">
                    Slot Deatils
                  </div>
                  <div class="col-md-6 p-0 text-right">{{ currentClickDate | date: "dd/MM/yyyy" }}
                  </div>
                </div>
                <div class="col-md-8 mx-auto mt-2 p-1">
                  <div class="row">
                    <div class="col-md-6 col-6 py-1 d-flex text-left">
                      <!-- <input id="radio-1" name="isGroup" type="radio" style="margin: 5px; width: 15px;"
                        [checked]="isGroup"  [disabled]="true">
                      <img src="assets/images/icons/Group-call.png" alt="add" width="30" height="15"
                        style="margin: 5px;">
                      <label for="radio-1" class="radio-label">Group Call</label> -->
                    
                    </div>
                  </div>

                </div>
                <div class="col-md-12">
                  <div class="row">
                    <div class="form-group col-md-6 col-6" *ngIf="isGroup">
                      <input class="form-control col-12" type=text [(ngModel)]="viewProgramName" [disabled]="true"
                      style="border: 1px solid #9acd32 !important; border-radius: 5px !important;">
                      
                        <span class="floating-label"
                        style="top: -14px !important; left: 21px !important; background: #000 !important;">
                        Program Name</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 mt-2">
                  <div class="row">
                    <div class="form-group mb-0 col-md-5 col-5 ">
                      <!-- <ng-multiselect-dropdown id="startTime" name="startTime" [data]="timeintervals"
                        [disabled]="disabled" [(ngModel)]="selectedStartTime" [settings]="dropdownSettings"
                        (onSelect)="calculateEndTime()" required class="form-control col-12"
                        style="border: 1px solid #9acd32 !important; border-radius: 5px !important;">
                      </ng-multiselect-dropdown> -->
                      <input class="form-control col-12" type=text [(ngModel)]="viewStartTime" [disabled]="true"
                        style="border: 1px solid #9acd32 !important; border-radius: 5px !important;">

                      <!-- <input class="form-control col-12" type=time style="border: 1px solid #9acd32 !important;  border-radius: 5px !important;font-size:14px !important; font-weight:500 !important;"> -->
                      <span class="floating-label"
                        style="top: -14px !important; left: 21px !important; background: #000 !important;">Start
                        time</span>
                      <!-- <i class="fa fa-clock-o" aria-hidden="true" style="position: absolute; left: 130px; top: 11px;"></i> -->
                    </div>
                    <div class="form-group mb-0 col-md-5 col-5">
                      <!-- <ng-multiselect-dropdown id="endTime" name="endTime" [data]="timeintervals" [disabled]="true"
                        [(ngModel)]="selectedEndTime" [settings]="dropdownSettings" required class="form-control col-12"
                        style="border: 1px solid #9acd32 !important; border-radius: 5px !important;">
                      </ng-multiselect-dropdown> -->
                      <input class="form-control col-12" type=text [(ngModel)]="selectedEndTime" [disabled]="true"
                        style="border: 1px solid #9acd32 !important; border-radius: 5px !important;">
                      <!-- <input class="form-control col-12" type=time style="border: 1px solid #9acd32 !important;  border-radius: 5px !important;"> -->
                      <span class="floating-label"
                        style="top: -14px !important; left: 21px !important; background: #000 !important;">End
                        time</span>
                      <!-- <i class="fa fa-clock-o" aria-hidden="true" style="position: absolute; left: 130px; top: 11px;"></i> -->
                    </div>
                    <!-- <div class="col-md-2 col-2">
                      <img src="assets/images/icons/delete-call.png" alt="add" width="22" height="26"
                        style="margin: 5px;" (click)="onDeleteSlot()">
                    </div> -->
                  </div>
                </div>

                <!-- <div class="col-md-9 mt-3 p-0 text-center mx-auto">
                  <div class="btn mx-4" style="padding: 4px 29px;" (click)="closePopup()">Cancel
                  </div>
                  <div class="btn mx-3" style="padding: 4px 29px;" (click)="onSlotEditSubmit()">
                    Save
                  </div>
                </div> -->
              </div>
            </div>

          </div>
          <div class="workout-footer d-flex col-12 justify-content-end">
          </div>
        </div>
      </div>
    </ng-container>

  </div>
  
</section>