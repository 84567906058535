import { Component, OnInit, OnDestroy, Renderer2, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { AppGlobals } from "../../app.globals";
import { Router, ActivatedRoute } from "@angular/router";
import { TraineeLoginService } from "../../service/trainee-login.service";
import { LoggedInCallback, CognitoTraineeUtil } from "../../service/cognitotrainee.service";
import { DBService } from '../../service/db.service';
import { TraineeDBService } from '../../service/trainee.service';
import { AWSService } from '../../service/aws.service';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { TwilioDBService } from '../../service/twilio.dbservice';

@Component({
    selector: 'app-trainee-bookslot',
    templateUrl: './trainee-bookslot.component.html',
    styleUrls: ['./trainee-bookslot.component.css'],
    providers: [DatePipe]
})
export class TraineeBookslotComponent implements OnInit, AfterViewInit, OnDestroy, LoggedInCallback {

    dayData: any;
    currentDate: string;
    currentCategory: string;
    currentGender: string;
    slots: object[];
    selectedTrainer: [];
    totalDays: object[];
    private sub: any;
    trainerId: string;
    date: string;
    trainersData: any;
    trainerData: any;
    dropdownSettings_single: any = {};
    userData: any;
    closeDropdownSelection = false;
    trainee_id = "5d0b8cc1-b0d7-430f";
    trainee: any;
    slotBooked: boolean;

    monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];
    days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    currentMonth: string = this.monthNames[new Date().getMonth()] + "/" + new Date().getFullYear();

    // Google Var
    googleUserEmail: string;
    private loggedIn: boolean;
    private subcrptn_1: any;
    currentLogged: string;
    currentEmail: string;
    isNutritionist: boolean = false;
    // Vars
    userID: string;

    // DOM element refrence
    @ViewChild('connectionStatus') connectionStatus: ElementRef;
    @ViewChild('helperText') helperText: ElementRef;
    @ViewChild('helperPlugInDownload') helperPlugInDownload: ElementRef;
    @ViewChild('helperAppDownload') helperAppDownload: ElementRef;
    @ViewChild('logintext') loginInput: ElementRef;
    @ViewChild('meetingtext') meetingInput: ElementRef;
    @ViewChild('hosttext') HostNameInput: ElementRef;
    @ViewChild('tokentext') tokenInput: ElementRef;

    login = {
        userName: '',
        meetingRoom: '',
        hostName: '',
        token: ''
    };
    tockendata = {
        "key": "",
        "appID": "",
        "userName": "",
        "expireTime": ""
    }
    disableJoinButton = false;
    isLoading = false;
    errorOccured = false;
    TIMEOUTOCCUR = false;
    roomName: string;
    redirectRoomType: any;
    /**
    * Description: Constructor of login component
    * @param object  vidyoClientService
    * @param object  router
    */
    constructor(private renderer: Renderer2, public router: Router, public route: ActivatedRoute, public userService: TraineeLoginService
        , private db_service: DBService, private trainee_db_service: TraineeDBService, private toastr: ToastrService, private awsService: AWSService, private datePipe: DatePipe,
        private globals: AppGlobals, private twilio_dbservice: TwilioDBService, public cognitoUtil: CognitoTraineeUtil, public datepipe: DatePipe,) {
        this.userService.isAuthenticated(this);
        // subscribe to sdkLoadConfirmed observer this observer inform about the sdk intialization status

    }

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.trainee_id = params['traineeId'];
        })
        this.currentCategory = localStorage.getItem("ProfileCategory")
        this.currentGender = localStorage.getItem("ProfileGender");
        if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'male') {
            this.renderer.removeClass(document.body, 'body-main');
            this.renderer.addClass(document.body, 'bg-img-build-male');
        }
        else if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'female') {
            this.renderer.removeClass(document.body, 'body-main');
            this.renderer.addClass(document.body, 'bg-img-build-female');
        }
        else if (this.currentCategory == 'Yoga' && this.currentGender == 'male') {
            this.renderer.removeClass(document.body, 'body-main');
            this.renderer.addClass(document.body, 'bg-img-yoga-male');
        }
        else if (this.currentCategory == 'Yoga' && this.currentGender == 'female') {
            this.renderer.removeClass(document.body, 'body-main');
            this.renderer.addClass(document.body, 'bg-img-yoga-female');
        }
        else if (this.currentCategory == 'Zumba' && this.currentGender == 'male') {
            this.renderer.removeClass(document.body, 'body-main');
            this.renderer.addClass(document.body, 'bg-img-zumba-male');
        }
        else if (this.currentCategory == 'Zumba' && this.currentGender == 'female') {
            this.renderer.removeClass(document.body, 'body-main');
            this.renderer.addClass(document.body, 'bg-img-zumba-female');
        }
        // this.userService.currentUserID.subscribe((val) => {
        //     if (val != '') {
        //         this.userID = val;
        //         this.getSchedulerDataFromServer();
        //     }
        // });
        this.getAllTrainersFromDB();
        this.dropdownSettings_single = {
            singleSelection: true,
            enableCheckAll: false,
            idField: 'id',
            textField: 'name',
            allowSearchFilter: true,
            itemsShowLimit: 1,
            closeDropDownOnSelection: this.closeDropdownSelection
        };
        this.getTraineeData();
    }
    ngOnDestroy() {
        this.renderer.removeClass(document.body, 'bg-img-build-male');
        this.renderer.removeClass(document.body, 'bg-img-yoga-male');
        this.renderer.removeClass(document.body, 'bg-img-zumba-male');
        this.renderer.removeClass(document.body, 'bg-img-build-female');
        this.renderer.removeClass(document.body, 'bg-img-yoga-female');
        this.renderer.removeClass(document.body, 'bg-img-zumba-female');
        this.renderer.addClass(document.body, 'body-main');
    }
    isLoggedIn(message: string, isLoggedIn: boolean) {

        var localGoogleUser = localStorage.getItem('isloggedIn');
        if (localGoogleUser != undefined) {
            this.loggedIn = JSON.parse(localGoogleUser);
            isLoggedIn = true;
        }
        if (!isLoggedIn) {
            this.router.navigate(['/content']);
        } else {
            // if()
            if (this.loggedIn == true) {
                this.currentEmail = localStorage.getItem('lastAuthUser');
                // if(this.currentEmail != undefined)
                // this.getProfileDataFromServer();
            }
            else {
                // this.currentEmail = localStorage.getItem('CognitoIdentityServiceProvider.2io8v4r253hugt5ndbjv9shkfp.LastAuthUser');
                this.currentEmail = this.cognitoUtil.getCurrentUser().getUsername();
                // if(this.currentEmail != undefined)
                // this.getProfileDataFromServer();
            }
        }
    }
    getProfileDataFromServer() {
        // var index = '/profile/';
        // this.db_service.getDataFromServer(this.currentEmail, index).subscribe(
        //     response => {
        //         console.log('Data Retrived succesfully.', response);
        //         response = response[0];
        //         this.userData = response;
        //         this.userID = response.trainerId;
        //         var nutri = JSON.parse(response["isNutritionist"]);
        //         if (nutri[0]["name"] == "Yes")
        //             this.isNutritionist = true;
        //         // this.geExerciseDataFromServer();

        //     },
        //     error => {
        //         console.log("An error has occurred while retriving profile data.");
        //     }
        // )
    }
    getAllTrainersFromDB() {
        var index = '/profile/';
        this.db_service.getDataFromServer("", index).subscribe(
            response => {
                // console.log('Data Retrived succesfully.', response);
                this.trainersData = response;
                for (var i = 0; i < this.trainersData.length; i++) {
                    this.trainersData[i]["id"] = this.trainersData[i].trainerId;
                    this.trainersData[i]["name"] = this.trainersData[i].firstName + " " + this.trainersData[i].lastName;
                }
            },
            error => {
                console.log('There is an error while retriving data.');
            }
        )
    }
    getTrainerData() {
        var index = '/profile/';
        this.db_service.getDataFromServer(this.currentEmail, index).subscribe(
            response => {
                // console.log('Data Retrived succesfully.', response);
                response = response[0];
                this.trainerData = response;

            },
            error => {
                console.log("An error has occurred while retriving profile data.");
            }
        )
    }


    onDateChange() {
        var index = "/scheduler"
        var selectedDate = this.datepipe.transform(this.date, 'yyyy-MM-dd');
        var data = {
            "trainee_id": this.trainee_id,
            "date": selectedDate
        };
        // data.trainee_id = "";

        this.trainee_db_service.getData(data, index).subscribe(
            response => {
                if (response.data != null) {
                    this.dayData = response.data;
                    this.slots = [];
                    for (var j = 0; j < this.dayData.length; j++) {
                        for (let i = 0; i < this.dayData[j].slots.length; i++) {

                            if (this.dayData[j].slots[i]["callType"] == "1o1") {
                                this.dayData[j].slots[i]["name"] = this.dayData[j].firstName;
                                this.dayData[j].slots[i]["schedulerId"] = this.dayData[j].schedulerId;
                                this.dayData[j].slots[i]["imgUrl"] = this.dayData[j].profileImage.profileImagePath;
                                if (this.dayData[j].slots[i].participants.length != 0) {
                                    this.dayData[j].slots[i]["trainee_id"] = this.dayData[j].slots[i].participants[0]["trainee_id"];
                                }
                            }
                            else {
                                if (this.dayData[j].slots[i]["programId"] == this.trainee.program_id) {
                                    var count = 0;
                                    for (let k = 0; k < this.dayData[j].slots[i].participants.length; k++) {

                                        if (this.dayData[j].slots[i].participants[k]["trainee_id"] == this.trainee_id) {
                                            this.dayData[j].slots[i]["name"] = this.dayData[j].firstName;
                                            this.dayData[j].slots[i]["schedulerId"] = this.dayData[j].schedulerId;
                                            this.dayData[j].slots[i]["imgUrl"] = this.dayData[j].profileImage.profileImagePath;
                                            this.dayData[j].slots[i]["trainee_id"] = this.dayData[j].slots[i].participants[k]["trainee_id"];
                                            this.dayData[j].slots[i]["status"] = "booked";
                                            count = 1;
                                            break;
                                        }
                                    }
                                    if (count == 0) {
                                        this.dayData[j].slots[i]["name"] = this.dayData[j].firstName;
                                        this.dayData[j].slots[i]["schedulerId"] = this.dayData[j].schedulerId;
                                        this.dayData[j].slots[i]["imgUrl"] = this.dayData[j].profileImage.profileImagePath;
                                        if (this.dayData[j].slots[i].participants.length != 0) {
                                            this.dayData[j].slots[i]["trainee_id"] = this.dayData[j].slots[i].participants[0]["trainee_id"];
                                        }
                                    }
                                }
                            }
                            this.slots.push(this.dayData[j].slots[i]);

                        }

                    }
                    this.slotBooked = false;
                    for (let i = 0; i < this.slots.length; i++) {
                        if (this.slots[i]["participants"][0] == this.trainee_id) {
                            this.slotBooked = true;
                        }
                    }

                } else {
                    this.toastr.error('', 'There is no appointments.', {
                        timeOut: 3000,
                        positionClass: 'toast-top-right',
                        progressBar: true,
                        progressAnimation: 'increasing',
                        tapToDismiss: true,
                    });
                }

            },
            error => {
                console.log("An error has occurred while retriving profile data.");
            }
        )
    }


    getTraineeData() {
        var index = "/scheduler/"
        this.trainee_db_service.getDataFromServer(this.trainee_id, index).subscribe(
            response => {
                if (response.data != null) {
                    this.trainee = response.data;
                }

            },
            error => {
                console.log("An error has occurred while retriving profile data.");
            }
        )
    }



    getData() {
        var i = 0;
        this.trainerId = this.selectedTrainer[i]["id"];
        this.trainerData = this.trainersData.find(x => x["trainerId"] == this.trainerId);
        // var index = '/profile/';
        // this.db_service.getDataFromServer(this.trainerId, index).subscribe(
        //     response => {
        //         console.log('Data Retrived succesfully.', response);
        //     this.trainerData = response;
        var stringDate = this.datePipe.transform(this.date);
        var d = new Date(stringDate);
        var dd = d.getDate();
        var mm = d.getMonth() + 1; //January is 0!
        var d1 = "";
        var m1 = "";
        var yyyy = d.getFullYear();
        if (dd < 10) {
            d1 = '0' + dd;
        }
        else {
            d1 = dd.toString();
        }
        if (mm < 10) {
            m1 = '0' + mm;
        }
        else {
            m1 = mm.toString();
        }
        this.currentDate = d1 + "-" + m1 + "-" + yyyy;
        var id = this.date + "," + this.trainerId;
        var index = '/scheduler/day/';
        this.db_service.getDataFromServer(id, index).subscribe(
            response => {
                //console.log('Data Retrived succesfully.', response);
                if (response.length != 0) {
                    this.dayData = response[0].days[0];
                    this.slots = response[0].days[0].slots;
                    for (let i = 0; i < this.slots.length; i++) {
                        this.slots[i]["imgPath"] = this.trainerData.imgPath;
                        this.slots[i]["name"] = this.trainerData.name;
                    }
                } else {
                    this.toastr.error('', 'There is no appointments.', {
                        timeOut: 3000,
                        positionClass: 'toast-top-right',
                        progressBar: true,
                        progressAnimation: 'increasing',
                        tapToDismiss: true,
                    });
                }
            },
            error => {
                console.log("An error has occurred while retriving scheduler data.");
            }
        )
        // },
        // error => {
        //     console.log("An error has occurred while retriving profile data.");
        // }
        //)

    }

    onJoinRoom(slotId, schedulerId) {

        var index = "/scheduler/slots";
        var queryParams = {
            "schedulerId": schedulerId,
            "slotId": slotId
        }
        this.trainee_db_service.getQueriedData("", index, queryParams).subscribe(
            response => {
                //console.log('Data retrieved succesfully.', response);
                if (response.data.roomName != "") {
                    this.GenerateTwilioToken(response.data.roomName, this.trainee.trainee_id);
                    this.redirectRoomType = response.data.callType;
                }
                else {
                    this.toastr.error('', 'Call not yet initiated by trainer.', {
                        timeOut: 3000,
                        positionClass: 'toast-top-right',
                        progressBar: true,
                        progressAnimation: 'increasing',
                        tapToDismiss: true,
                    });
                }

            },
            error => {
                console.log("An error has occurred while retriving profile data.");
            }
        )

    }

    GenerateTwilioToken(roomName, trainee_id) {
        var index = "/token";
        this.roomName = roomName;
        var queryParams = {
            "roomName": roomName,
            "name": trainee_id
        }
        this.twilio_dbservice.getToken("", index, queryParams).subscribe(
            response => {
                // console.log('Token generated succesfully.', response);
                //  console.log("Token:  " + response);
                if (this.redirectRoomType == '1o1')
                    this.router.navigate(['twilioVideo', response.token, this.roomName]);
                else
                    this.router.navigate(['twilioGroup', response.token, this.roomName]);
            },
            error => {
                console.log("An error has occurred while generating the token.");
            });
    }

    bookAppointment(slotId, schedulerId) {
        if (this.slotBooked != true) {
            var index = "/scheduler/slots"

            var data = {
                "trainee_id": this.trainee_id,
                "slotId": slotId,
                "schedulerId": schedulerId
            };
            this.trainee_db_service.saveDataToDB(data, index).subscribe(
                response => {
                    this.toastr.success('', 'Slot booked successfully.', {
                        timeOut: 3000,
                        positionClass: 'toast-top-right',
                        progressBar: true,
                        progressAnimation: 'increasing',
                        tapToDismiss: true,
                    });
                    this.onDateChange();
                },
                error => {
                    this.toastr.error('', 'There is no appointments.', {
                        timeOut: 3000,
                        positionClass: 'toast-top-right',
                        progressBar: true,
                        progressAnimation: 'increasing',
                        tapToDismiss: true,
                    });
                }
            )
        } else {
            this.toastr.error('', 'You have already booked a slot for this day.', {
                timeOut: 3000,
                positionClass: 'toast-top-right',
                progressBar: true,
                progressAnimation: 'increasing',
                tapToDismiss: true,
            });
        }
    }
    GenerateToken(startTime, endTime) {
        var index = '/scheduler/trainer/';
        var data = {
            "trainerId": this.trainerId,
            "month": this.currentMonth
        }
        this.db_service.getData(data, index).subscribe(
            response => {
                // console.log('Data Retrived succesfully.', response);
                this.totalDays = response.days;
                var data = JSON.parse(JSON.stringify(response.days));
                //    this.events= data;
                //    this.timezone = response.timezone;
                // for(let i=0; i<this.events.length;i++){
                //   // var SD = JSON.parse(data[i].start)
                //     this.events[i].start = new Date(startOfDay(new Date(JSON.parse(data[i].start))));
                //     this.events[i].end = new Date(startOfDay(new Date(JSON.parse(data[i].end))));
                //     this.events[i].actions = this.actions;
                // }
                var ck = data.find(x => x["date"] == this.currentDate);
                var slotCheck;
                if (ck != undefined) {
                    slotCheck = ck["slots"].find(x => x["traineeId"] == this.userID);
                }
                if (slotCheck != undefined) {
                    if (slotCheck["token"] != "") {
                        this.login.hostName = this.globals.Vidyo_Host;
                        this.login.token = slotCheck["token"];
                        this.login.userName = this.userData.firstName;
                        this.login.meetingRoom = slotCheck["resource_id"];

                    } else {
                        var id = Math.random().toString(36).slice(-6);
                        this.tockendata.appID = this.globals.Video_appID;
                        this.tockendata.key = this.globals.Vidyo_Key;
                        // var tm = slot.split("-");
                        var sTime = moment(startTime, "HH:mm:ss a");
                        var eTime = moment(endTime, "HH:mm:ss a");
                        var duration = eTime.diff(sTime, 'seconds');
                        this.tockendata.expireTime = JSON.stringify(duration);
                        this.tockendata.userName = "vsaas";
                        var input = this.tockendata.key + "-" + this.tockendata.appID + "-" + this.tockendata.userName + "-" + this.tockendata.expireTime;
                        var index = '/video_chat/token/';
                        this.db_service.getToken(input, index).subscribe(
                            response => {
                                this.login.token = response;
                                this.login.hostName = this.globals.Vidyo_Host;
                                this.login.meetingRoom = id;
                                this.login.userName = this.userData.firstName;
                                slotCheck["token"] = response;
                                slotCheck["resource_id"] = id;

                                ck["trainerId"] = this.trainerId;
                                this.updateSlot(ck);

                            },
                            error => {
                                console.log("An error has occurred while generating token for call.");
                            })
                    }
                }

            },
            error => {
                console.log("An error has occurred while retriving profile data.");
            }
        )
    }
    updateSlot(dayData) {
        var index = '/scheduler/day'
        this.db_service.saveDataToDB(dayData, index).subscribe(
            response => {
                this.getSchedulerDataFromServer();
                // console.log("slot updated.");
            }, error => {
                console.log("error in updating slot.")
            })
    }

    getSchedulerDataFromServer() {
        var index = '/scheduler/trainer/';
        var data = {
            "trainerId": this.userID,
            "month": this.currentMonth
        }
        this.db_service.getData(data, index).subscribe(
            response => {
                //  console.log('Data Retrived succesfully.', response);
                this.totalDays = response.days;
            },
            error => {
                console.log("An error has occurred while retriving profile data.");
            }
        )
    }
    /**
  * Description: Focus on user name input box after login page load
  */
    ngAfterViewInit() {
        // Focus on user name input box after login page load
        this.loginInput.nativeElement.focus();
    }

    /**
    * Description: Join the meeting
    */


}

