import { Component, OnInit, Input, ViewChild, OnDestroy, Renderer2, ChangeDetectorRef } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { DBService } from '../service/db.service';
import { AWSService } from '../service/aws.service';
import { AppGlobals } from '../app.globals';
import { GooglePlaceModule, GooglePlaceDirective } from "ngx-google-places-autocomplete";
import { UserLoginService } from "../service/user-login.service";
import { LoggedInCallback, CognitoUtil } from "../service/cognito.service";
import { ToastrService } from 'ngx-toastr';
import { IMyDpOptions } from 'mydatepicker';
import { DatePipe } from '@angular/common';
import moment from 'moment';
import { YogaDBService } from '../service/YogaDBService';


export class profile {
  trainerId: string;
  trainerUserName: string;
  profileStatus: string;
  registraionType: string;
  adminStatus: string;
  creationDate: Date;
  gender: string;
  category: Object[];
  firstName: string;
  lastName: string;
  phoneNumber: string;
  trainerTimezone: string;
  address: Object[];
  trainerSocialAccounts: Object[];
  trainingSince: Object;
  serviceOffered: Object[];
  certification: Object[];
  isNutritionist: Object;
  about: string;
  achievements: Object[];
  profileImage: Object;
  profileIntroVideo: Object;
  lastUpdatedDate: Date;
  dateOfBirth: string;
  approvalComments;
  isBusinessDetailsSubmitted;
  specializedIn:Object[];
}
export class yogaProfile {
  trainerId: string;
  trainerUserName: string;
  profileStatus: string;
  registraionType: string;
  adminStatus: string;
  creationDate: Date;
  gender: string;
  category: Object[];
  firstName: string;
  phoneNumber: string;
  lastName: string;
  trainerTimezone: string;
  address: Object[];
  trainerSocialAccounts: Object[];
  trainingSince: Object;
  serviceOffered: Object[];
  certification: Object[];
  isNutritionist: Object;
  about: string;
  achievements: Object[];
  profileImage: Object;
  profileIntroVideo: Object;
  lastUpdatedDate: Date;
  dateOfBirth: string;
  approvalComments;
  isBusinessDetailsSubmitted;
  specializedIn:Object[];
}

export class IMyDateModel {
  date: IMyDate;
  jsdate: Date;
  formatted: string;
  epoc: number;
}

export class IMyDate {
  year: number;
  month: number;
  day: number;
}
@Component({
  selector: 'app-myprofile',
  templateUrl: './myprofile.component.html',
  styleUrls: ['./myprofile.component.css']
})

export class MyprofileComponent implements OnInit, OnDestroy, LoggedInCallback {
  @Input() yValue: number = 100;
  @Input() videoUrl: string = "";
  @Input() imgUrl: string = "";
  @Input() yogavideoUrl: string = "";
  @Input() yogaimgUrl: string = "";
  @Input() thumbUrl: string = "";
  @ViewChild("placesRef") placesRef: GooglePlaceDirective;
  @ViewChild('videoPlay') videoPlay: any;
  @ViewChild('yogaVideoPlay') yogaVideoPlay: any;
  progVal: number = 0;
  userId: string = "";
  private sub: any;
  FOLDER = 'ProfileIntro/';
  IMGFOLDER = 'ProfileImg/'
  selectedFile: File;
  profileResponse: any;
  Services: object[];
  TrainingSince: object[];
  Certificates: object[];

  Yoga_Services: object[];
  Yoga_TrainingSince: object[];
  Yoga_Certificates: object[];

  adminComments: object[];
  fileExtension: any;
  loaderHide: boolean = false;
  showAdminComments: boolean = false;
  isShowCertificate: boolean = false;
  showimagepreview: boolean = false;
  showpdfpreview: boolean = false;
  category: string;
  yogacategory = "";
  showpreivewdocument: string;
  isCertificate: boolean = false;
  isYogaCertificate: boolean = false;
  googlePlacesOptions = {
    types: [],
    componentRestrictions: { country: ["US", "IN"] }
  };

  profileImage: any = {
    profileImageName: "",
    profileImagePath: "",
    profileVerfied: false,
    verifiedOn: Date,
    uploadedOn: new Date()
  }

  yogaprofileImage: any = {
    profileImageName: "",
    profileImagePath: "",
    profileVerfied: false,
    verifiedOn: Date,
    uploadedOn: new Date()
  }

  profileIntroVideo: any = {
    profileVideoName: "",
    videoThumbnailPath: "",
    videosourceUrl: "",
    videoDestinationCheck: false,
    videoMp4Destination: "",
    videoVerfied: false,
    verifiedOn: Date,
    videouploadedOn: new Date()
  }

  yogaprofileIntroVideo: any = {
    profileVideoName: "",
    videoThumbnailPath: "",
    videosourceUrl: "",
    videoDestinationCheck: false,
    videoMp4Destination: "",
    videoVerfied: false,
    verifiedOn: Date,
    videouploadedOn: new Date()
  }

  addressfields: any = {
    address: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    isPrimary: true,
    lat_logAttributes: {
      latitude: "",
      logitude: ""
    }
  }


  achievement: any = {
    achievement: ""
  }

  yoga_achievement: any = {
    achievement: ""
  }


  website: any = {
    socialAccount: "website",
    sourceUrl: "",
    status: "",
    verifiedOn: Date
  }
  facebook: any = {
    socialAccount: "facebook",
    sourceUrl: "",
    status: "",
    verifiedOn: Date
  }
  twitter: any = {
    socialAccount: "twitter",
    sourceUrl: "",
    status: "",
    verifiedOn: Date
  }
  instagram: any = {
    socialAccount: "instagram",
    sourceUrl: "",
    status: "",
    verifiedOn: Date
  }

  isNutritionist = {
    status: "",
    nutrionistFrom: "",
    certificateName: "",
    certificateDestinationUploadUrl: ""
  }

  isYogaNutritionist = {
    status: "",
    nutrionistFrom: "",
    certificateName: "",
    certificateDestinationUploadUrl: ""
  }

  certification = {
    certificateName: "",
    uploadedCertificateName: "",
    certifiedDate: Date,
    certificateDestinationUploadUrl: ""
  }

  yogaCertification = {
    certificateName: "",
    uploadedCertificateName: "",
    certifiedDate: Date,
    certificateDestinationUploadUrl: ""
  }

  serviceOffered = {
    serviceType: "",
    yearsActiveFrom: ""
  }

  SelectedTrainingSince: Object[];
  SelectedCertificates: Object[];
  SelectedServices: Object[];
  selectedServicesOffered: any;
  totalCertificatesSelected: any;
  SelectedNutrition: Object[];

  Yoga_SelectedTrainingSince: Object[];
  Yoga_SelectedCertificates: Object[];
  Yoga_SelectedServices: Object[];
  Yoga_selectedServicesOffered: any;
  Yoga_totalCertificatesSelected: any;
  Yoga_SelectedNutrition: Object[];

  speciliazedIn : object[];
  SelectedSpecializedlist : any;

  gender: Array<string> = [];
  dropdownSettings: any = {};
  dropdownSettings_single: any = {};
  dropdownSettings_gender: any = {};
  dropdownSettings_single_1: any = {};
  closeDropdownSelection = false;
  disabled = true;

  myDatePickerOptions: IMyDpOptions = {};
  dateOfBirth: IMyDateModel;
  Nutritions: Object[];
  userCategories: any;
  userselectedCategories : any;
  categories: any;
  isFitness: boolean;
  isYoga: boolean;
  isZumba: boolean;
  isFitnessSelected: boolean = false;
  isYogaSelected: boolean= false;
  isZumbaSelected: boolean= false;
  primCat: any;
  profilesData : any;

  constructor(private renderer: Renderer2, private toastr: ToastrService, private HttpClient: HttpClient,
    public router: Router, public userService: UserLoginService, public route: ActivatedRoute, private globals: AppGlobals,
    private yoga_db_service: YogaDBService, private db_service: DBService, private awsService: AWSService, private cdr: ChangeDetectorRef, public cognitoUtil: CognitoUtil, public datepipe: DatePipe) {
    this.userService.isAuthenticated(this);
  }
  profile: profile;
  yogaProfile: yogaProfile;
  currentCategory: string;
  currentGender: string;
  // Google Var
  googleUserEmail: string;
  private loggedIn: boolean;

  isEditProf = true;
  isUpdateProf = false;
  isReadOnly = true;

  isImgUploaded: string = "NO";
  timezone: string;
  dateFormat: string;

  //gangadhar code
 
  ngOnInit() {
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // this.getAllMasters();
    this.currentCategory = localStorage.getItem("ProfileCategory")
    this.currentGender = localStorage.getItem("ProfileGender");
    this.setCategories();
    // // this.userCategories = JSON.parse(localStorage.getItem("userCategory"));
    // // if(this.userCategories == null || this.userCategories.length == 0)
    // //   this.userCategories = JSON.parse(localStorage.getItem("CategoryItems"));

    // // this.userselectedCategories = this.userCategories;
    // for (var i = 0; i < this.userCategories.length; i++) {
    //   if (this.userCategories[i].category_name == 'Bodybuilder') {
    //     this.isFitness = true;
    //     this.userselectedCategories[i]['id'] = "0";
    //     this.userselectedCategories[i]["name"] = "Fitness"
    //     // break;
    //   }
    //   if (this.userCategories[i].category_name == "Yoga") {
    //     this.isYoga = true;
    //     this.userselectedCategories[i]['id'] = "1";
    //     this.userselectedCategories[i]["name"] = "Yoga"
    //     // break;
    //   }
    //   if (this.userCategories[i].category_name == "Zumba") {
    //     this.isZumba = true;
    //     this.userselectedCategories[i]['id'] = "2";
    //     this.userselectedCategories[i]["name"] = "Zumba"
    //     // break;
    //   }
    //   if (this.userCategories[i]["primary"] == true) {
    //     this.primCat = this.userCategories[i]["category_name"];
    //   }
    // }
    this.categories = [{"id":"0", "name":"Fitness"},
    {"id":"1", "name":"Yoga"}, 
    {"id":"2", "name":"Zumba"}] 
    this.speciliazedIn = [{"id":"0", "name":"Morning Yoga"},
    {"id":"1", "name":"Therapeutic"}, 
    {"id":"2", "name":"Immunity Booster"},
    {"id":"3", "name":"Fat Burning"},
    {"id":"4", "name":"Stress and Relax"}] 

    if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-build-male');
    }
    else if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-build-female');
    }
    else if (this.currentCategory == 'Yoga' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-yoga-male');
    }
    else if (this.currentCategory == 'Yoga' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-yoga-female');
    }
    else if (this.currentCategory == 'Zumba' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-zumba-male');
    }
    else if (this.currentCategory == 'Zumba' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-zumba-female');
    }

    this.gender = ['Male', 'Female'];
    this.dropdownSettings_gender = {
      singleSelection: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      closeDropDownOnSelection: this.closeDropdownSelection
    };
    this.sub = this.route.params.subscribe(params => {
      this.userId = params['userID'];
      this.getProfileDataFromServer(this.primCat);
      // this.getProfileCategories(); //gangadhar code for categories
    });
    // this.getAllMasters();
    this.dropdownSettings = {
      singleSelection: false,
      enableCheckAll: false,
      idField: 'id',
      textField: 'name',
      allowSearchFilter: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      closeDropDownOnSelection: this.closeDropdownSelection
    };
    this.dropdownSettings_single = {
      singleSelection: true,
      enableCheckAll: false,
      idField: 'id',
      textField: 'name',
      allowSearchFilter: true,
      itemsShowLimit: 1,
      closeDropDownOnSelection: this.closeDropdownSelection
    };

    if (this.timezone.includes("asia") || this.timezone.includes("Asia")) {
      this.myDatePickerOptions = {
        todayBtnTxt: 'Today',
        dateFormat: 'dd-mm-yyyy',
        firstDayOfWeek: 'mo',
        sunHighlight: true,
        openSelectorOnInputClick: true,
        editableDateField: false,
        inline: false
      }
      this.dateFormat = "dd-MM-yyyy";
    } else {
      this.myDatePickerOptions = {
        todayBtnTxt: 'Today',
        dateFormat: 'mm-dd-yyyy',
        firstDayOfWeek: 'mo',
        sunHighlight: true,
        openSelectorOnInputClick: true,
        editableDateField: false,
        inline: false
      }
      this.dateFormat = "MM-dd-yyyy";
    }
    this.Nutritions = [{ "id": "0", "name": "Yes" },
    { "id": "1", "name": "No" }];
  
    this.dropdownSettings_single_1 = {
      singleSelection: true,
      enableCheckAll: false,
      idField: 'id',
      textField: 'name',
      allowSearchFilter: false,
      itemsShowLimit: 1,
      closeDropDownOnSelection: this.closeDropdownSelection
    };
  }

   //setting categories
   setCategories(){
     this.userCategories = JSON.parse(localStorage.getItem("userCategory"));
    if(this.userCategories == null || this.userCategories.length == 0)
      this.userCategories = JSON.parse(localStorage.getItem("CategoryItems"));

    this.userselectedCategories = this.userCategories;
    for (var i = 0; i < this.userCategories.length; i++) {
      if (this.userCategories[i].category_name == 'Bodybuilder') {
        this.isFitness = true;
        this.userselectedCategories[i]['id'] = "0";
        this.userselectedCategories[i]["name"] = "Fitness"
        // break;
      }
      if (this.userCategories[i].category_name == "Yoga") {
        this.isYoga = true;
        this.userselectedCategories[i]['id'] = "1";
        this.userselectedCategories[i]["name"] = "Yoga"
        // break;
      }
      if (this.userCategories[i].category_name == "Zumba") {
        this.isZumba = true;
        this.userselectedCategories[i]['id'] = "2";
        this.userselectedCategories[i]["name"] = "Zumba"
        // break;
      }
      if (this.userCategories[i]["primary"] == true) {
        this.primCat = this.userCategories[i]["category_name"];
      }
    }
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
    this.renderer.removeClass(document.body, 'bg-img-build-male');
    this.renderer.removeClass(document.body, 'bg-img-yoga-male');
    this.renderer.removeClass(document.body, 'bg-img-zumba-male');
    this.renderer.removeClass(document.body, 'bg-img-build-female');
    this.renderer.removeClass(document.body, 'bg-img-yoga-female');
    this.renderer.removeClass(document.body, 'bg-img-zumba-female');
    this.renderer.addClass(document.body, 'body-main');
  }


  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/login']);
    }

    // if (!isLoggedIn) {
    //   this.router.navigate(['/login']);
    // } else {
    //   // if()
    //   if (this.loggedIn == true) {
    //     this.currentEmail = localStorage.getItem('lastAuthUser');
    //     // if(this.currentEmail != undefined)
    //     this.getProfileDataFromServer();
    //   }
    //   else {
    //     // this.currentEmail = localStorage.getItem('CognitoIdentityServiceProvider.2io8v4r253hugt5ndbjv9shkfp.LastAuthUser');
    //     this.currentEmail = this.cognitoUtil.getCurrentUser().getUsername();
    //     if(this.currentEmail == undefined || this.currentEmail == '' || this.currentEmail == null || this.currentEmail.includes("Google"))
    //     {
    //       this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
    //       if(this.currentEmail == undefined)
    //       this.currentEmail = this.cognitoUtil.session.idToken.payload.email;
    //     }
    //     // this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
    //     this.getProfileDataFromServer();
    //   }
    // }
  }
  toggleCloseDropdownSelection() {
    this.closeDropdownSelection = !this.closeDropdownSelection;
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, { closeDropDownOnSelection: this.closeDropdownSelection });
  }
  ////////////////////////////////////gangadhar code ////////////////////////////////////////////////////////
  // getProfileCategories(){
  //   var index = "/profile/category/"
  //   this.db_service.getDataFromServer(this.userId,index).subscribe(response =>{
  //     this.profileCategories = [];
  //     for(var i = 0 ; i < response.data.length ; i++){
  //           this.profileCategories.push(response.data[i]["category"][0]);
  //     }
  //     console.log(this.profileCategories);
  //   })
  // }
  ///////////////////////////////////////////gangadhar code ends heree///////////////////////////////////////////////////////

FilterData(selection){
  this.isFitnessSelected = false;
  this.isYogaSelected = false;
  this.isZumbaSelected = false;
  if(selection == "Bodybuilder")
  this.isFitnessSelected = true;
  else if(selection == "Yoga")
  this.isYogaSelected = true;
  else
  this.isZumbaSelected = true;
}
  
  getProfileDataFromServer(selection) {
    var index = '/profile/category/';
    this.profile = new profile();
    this.yogaProfile = new yogaProfile();
    this.db_service.getDataFromServer(this.userId, index).subscribe(
      response => {
        if(response.data.length != 0){
            this.profilesData = response.data;
            // this.FilterData(selection);
        }
        if(selection == "Bodybuilder")
          this.isFitnessSelected = true;
        if(selection == "Yoga")
          this.isYogaSelected = true;
       if(selection == "Zumba")
          this.isZumbaSelected = true;
        

        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i]["category"][0]["category_name"] == "Bodybuilder") {
                this.profile = response.data[i];
                this.thumbUrl = this.profile.profileIntroVideo["videoThumbnailPath"];
                this.yogaProfile.trainerId = this.profile.trainerId;
                this.yogaProfile.profileStatus = this.profile.profileStatus
                this.yogaProfile.registraionType = this.profile.registraionType;
                this.yogaProfile.trainerTimezone = this.profile.trainerTimezone;
                this.yogaProfile.isBusinessDetailsSubmitted = this.profile.isBusinessDetailsSubmitted;
                // this.SelectedCertificates = this.profile.certification;
                this.totalCertificatesSelected = this.profile.certification;
                this.SelectedTrainingSince = Array.of(this.profile.trainingSince);
                this.profileIntroVideo = this.profile.profileIntroVideo;
                this.profileImage = this.profile.profileImage;
                this.achievement = this.profile.achievements[0];
                this.adminComments = this.profile.approvalComments;
                // this.SelectedSpecializedlist = [];
                // this.SelectedServices = this.profile.serviceOffered;
                this.isNutritionist.status = this.profile.isNutritionist["status"];
                this.isNutritionist.certificateDestinationUploadUrl = this.profile.isNutritionist["certificateDestinationUploadUrl"];
                this.isNutritionist.certificateName = this.profile.isNutritionist["certificateName"];
                this.isNutritionist.nutrionistFrom = this.profile.isNutritionist["nutrionistFrom"];
                this.SelectedNutrition = this.Nutritions.filter(x => x["name"] == this.profile.isNutritionist["status"]);
                this.dateOfBirth = this.getConvertedDate(new Date(this.profile.dateOfBirth));
        
        
                for (let i = 0; i < this.profile.address.length; i++) {
                  if (this.profile.address[i]["isPrimary"] == true) {
                    this.addressfields = this.profile.address[i];
                  }
                }
        
        
                for (let i = 0; i < this.profile.trainerSocialAccounts.length; i++) {
                  if (this.profile.trainerSocialAccounts[i]["socialAccount"] == "website") {
                    this.website = this.profile.trainerSocialAccounts[i];
                  }
                  if (this.profile.trainerSocialAccounts[i]["socialAccount"] == "facebook") {
                    this.facebook = this.profile.trainerSocialAccounts[i];
                  }
                  if (this.profile.trainerSocialAccounts[i]["socialAccount"] == "twitter") {
                    this.twitter = this.profile.trainerSocialAccounts[i];
                  }
                  if (this.profile.trainerSocialAccounts[i]["socialAccount"] == "instagram") {
                    this.instagram = this.profile.trainerSocialAccounts[i];
                  }
                }
                for (let i = 0; i < this.profile.category.length; i++) {
                  // if (this.profile.category[i]["primary"] == true) {
                    this.category = this.profile.category[i]["category_name"];
                  // }
                }
                // this.videoUrl = response[0].mp4Destination;
                this.imgUrl = this.profile.profileImage["profileImagePath"];
                if (this.profile.profileIntroVideo["videoDestinationCheck"] == false) {
                  this.checkVideoUrl(this.profile.profileIntroVideo["videoMp4Destination"]);
                }
                else {
                  this.videoUrl = this.profile.profileIntroVideo["videoMp4Destination"];
                }
               

          }
         if (response.data[i]["category"][0]["category_name"] == "Yoga"){
            this.profile.firstName = response.data[i].firstName;
            this.profile.lastName = response.data[i].lastName;
            this.profile.trainerUserName = response.data[i].trainerUserName;
            this.profile.phoneNumber = response.data[i].phoneNumber;
            this.profile.profileStatus = response.data[i].profileStatus;
            this.profile.registraionType = response.data[i].registraionType;
            this.profile.trainerTimezone = response.data[i].trainerTimezone;
            this.profile.address = response.data[i].address;
            this.profile.gender = response.data[i].gender;
            this.profile.isBusinessDetailsSubmitted = response.data[i].isBusinessDetailsSubmitted
            this.yogaProfile = response.data[i];
            this.profile.trainerId = this.yogaProfile.trainerId;
            this.thumbUrl = this.yogaProfile.profileIntroVideo["videoThumbnailPath"];
            // this.SelectedCertificates = this.profile.certification;
            this.Yoga_totalCertificatesSelected = this.yogaProfile.certification;
            this.Yoga_SelectedTrainingSince = Array.of(this.yogaProfile.trainingSince);
            this.yogaprofileIntroVideo = this.yogaProfile.profileIntroVideo;
            this.yogaprofileImage = this.yogaProfile.profileImage;
            this.yoga_achievement = this.yogaProfile.achievements[0];
            this.adminComments = this.yogaProfile.approvalComments;
            this.SelectedSpecializedlist = this.yogaProfile.specializedIn;
            // this.SelectedServices = this.profile.serviceOffered;
            this.isYogaNutritionist.status = this.yogaProfile.isNutritionist["status"];
            this.isYogaNutritionist.certificateDestinationUploadUrl = this.yogaProfile.isNutritionist["certificateDestinationUploadUrl"];
            this.isYogaNutritionist.certificateName = this.yogaProfile.isNutritionist["certificateName"];
            this.isYogaNutritionist.nutrionistFrom = this.yogaProfile.isNutritionist["nutrionistFrom"];
            this.Yoga_SelectedNutrition = this.Nutritions.filter(x => x["name"] == this.yogaProfile.isNutritionist["status"]);
            this.dateOfBirth = this.getConvertedDate(new Date(this.yogaProfile.dateOfBirth));
    
    
            for (let i = 0; i < this.yogaProfile.address.length; i++) {
              if (this.yogaProfile.address[i]["isPrimary"] == true) {
                this.addressfields = this.yogaProfile.address[i];
              }
            }
    
    
            for (let i = 0; i < this.yogaProfile.trainerSocialAccounts.length; i++) {
              if (this.yogaProfile.trainerSocialAccounts[i]["socialAccount"] == "website") {
                this.website = this.yogaProfile.trainerSocialAccounts[i];
              }
              if (this.yogaProfile.trainerSocialAccounts[i]["socialAccount"] == "facebook") {
                this.facebook = this.yogaProfile.trainerSocialAccounts[i];
              }
              if (this.yogaProfile.trainerSocialAccounts[i]["socialAccount"] == "twitter") {
                this.twitter = this.yogaProfile.trainerSocialAccounts[i];
              }
              if (this.yogaProfile.trainerSocialAccounts[i]["socialAccount"] == "instagram") {
                this.instagram = this.yogaProfile.trainerSocialAccounts[i];
              }
            }
            for (let i = 0; i < this.yogaProfile.category.length; i++) {
              // if (this.profile.category[i]["primary"] == true) {
                this.yogacategory = this.yogaProfile.category[i]["category_name"];
              // }
            }
            // this.videoUrl = response[0].mp4Destination;
            this.yogaimgUrl = this.yogaProfile.profileImage["profileImagePath"];
            if (this.yogaProfile.profileIntroVideo["videoDestinationCheck"] == false) {
              this.checkVideoUrl(this.yogaProfile.profileIntroVideo["videoMp4Destination"]);
            }
            else {
              this.yogavideoUrl = this.yogaProfile.profileIntroVideo["videoMp4Destination"];
            }
           
          
          }
          this.getAllMasters();
        }
        
        
        
      },
      error => {
        // alert("An error has occurred while retriving profile data.");
        this.toastr.error('', 'An error has occurred while retriving profile data.', {
          timeOut: 5000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      }
    )
  }
  handleAddressChange(event) {
    var addressComponents = event["address_components"];
    for (let i = 0; i < addressComponents.length; i++) {
      if (addressComponents[i]["types"][0] == "postal_code") {
        this.addressfields.pincode = addressComponents[i]["long_name"];
      }
      if (addressComponents[i]["types"][0] == "country" || addressComponents[i]["types"][0] == "political") {
        this.addressfields.country = addressComponents[i]["long_name"];
      }
      if (addressComponents[i]["types"][0] == "administrative_area_level_1" || addressComponents[i]["types"][0] == "political") {
        this.addressfields.state = addressComponents[i]["long_name"];
      }
      if (addressComponents[i]["types"][0] == "locality") {
        this.addressfields.city = addressComponents[i]["long_name"];
      }
    }
    // var getIndex = addressComponents[0]["long_name"];
    var formatted_address = event["formatted_address"];
    var spiltAddress = formatted_address.split(this.addressfields.city);
    var subAddress = "";
    subAddress = spiltAddress[0].slice(0, -2);
    // if(spiltAddress[0] != ""){
    //   subAddress = spiltAddress[0].slice(0, -2);
    //   subAddress =subAddress+address;
    // }else{
    //   subAddress = address.substring(1);
    // }
    this.addressfields.address = event.name + "," + subAddress;
    this.profile.address = Array.of(this.addressfields);
  }
  getAllMasters() {
    var index = '/masters/';
    this.db_service.getDataFromServer('profile', index).subscribe(
      response => {
        //  console.log('Data Retrived succesfully.', response);
        if(this.category == undefined || this.category == "")
        this.category = "Bodybuilder"

      if(this.category == "Bodybuilder"){
        for (let i = 0; i < response.length; i++) {
          if (response[i]['name'] == "Services") {
            var values = response[i]['values'];
            for (let j = 0; j < values.length; j++) {
              if (this.category == values[j]["Catagory"]) {
                if (this.Services == undefined) {
                  this.Services = Array.of(values[j]);
                } else {
                  this.Services.push(values[j]);
                }

              }
            }
          }
          if (response[i]['name'] == "Certificates") {
            var values = response[i]['values'];
            for (let k = 0; k < values.length; k++) {
              if (this.category == values[k]["Catagory"]) {
                if (this.Certificates == undefined) {
                  this.Certificates = Array.of(values[k]);
                } else {
                  this.Certificates.push(values[k]);
                }

              }
            }
          }
          if (response[i]['name'] == "TrainingSince") {
            this.TrainingSince = response[i]['values'];
          }


        }
        this.selectedServicesOffered = [];
        this.SelectedServices = [];
        if (this.profile.serviceOffered != undefined) {
          for (let i = 0; i < this.profile.serviceOffered.length; i++) {
            var service = {};
            service["name"] = this.profile.serviceOffered[i]["serviceType"];
            service["yearsActiveFrom"] = JSON.parse(this.profile.serviceOffered[i]["yearsActiveFrom"]);
            this.selectedServicesOffered.push(JSON.parse(JSON.stringify(service)));
            var s = this.Services.find(service => service["name"] == this.profile.serviceOffered[i]["serviceType"]);
            this.SelectedServices.push(JSON.parse(JSON.stringify(s)));
          }
        }
        this.SelectedCertificates = [];
        if(this.profile.certification != undefined){
        for (let k = 0; k < this.profile.certification.length; k++) {
          var c = this.Certificates.find(cer => cer["name"] == this.profile.certification[k]["name"]);
          this.SelectedCertificates.push(JSON.parse(JSON.stringify(c)));
        }
      }
      }
       
        this.getAllYogaMasters();

      },
      error => {
        // alert("An error has occurred while saving data.");
        this.toastr.error('', 'An error has occurred while saving data.', {
          timeOut: 5000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      }
    )
  }
  getAllYogaMasters() {
    var index = '/masters/';
    this.db_service.getDataFromServer('profile', index).subscribe(
      response => {
        //  console.log('Data Retrived succesfully.', response);
        if(this.yogacategory == "" || this.yogacategory == undefined)
        this.yogacategory = "Yoga";
        if(this.yogacategory == "Yoga"){
        for (let i = 0; i < response.length; i++) {
          if (response[i]['name'] == "Services") {
            var values = response[i]['values'];
            for (let j = 0; j < values.length; j++) {
              if (this.yogacategory == values[j]["Catagory"]) {
                if (this.Yoga_Services == undefined) {
                  this.Yoga_Services = Array.of(values[j]);
                } else {
                  this.Yoga_Services.push(values[j]);
                }

              }
            }
          }
          if (response[i]['name'] == "Certificates") {
            var values = response[i]['values'];
            for (let k = 0; k < values.length; k++) {
              if (this.yogacategory == values[k]["Catagory"]) {
                if (this.Yoga_Certificates == undefined) {
                  this.Yoga_Certificates = Array.of(values[k]);
                } else {
                  this.Yoga_Certificates.push(values[k]);
                }

              }
            }
          }
          if (response[i]['name'] == "TrainingSince") {
            this.TrainingSince = response[i]['values'];
          }


        }
        this.Yoga_selectedServicesOffered = [];
        this.Yoga_SelectedServices = [];
        if (this.yogaProfile.serviceOffered != undefined) {
          for (let i = 0; i < this.yogaProfile.serviceOffered.length; i++) {
            var service = {};
            service["name"] = this.yogaProfile.serviceOffered[i]["serviceType"];
            service["yearsActiveFrom"] = JSON.parse(this.yogaProfile.serviceOffered[i]["yearsActiveFrom"]);
            this.Yoga_selectedServicesOffered.push(JSON.parse(JSON.stringify(service)));
            var s = this.Yoga_Services.find(service => service["name"] == this.yogaProfile.serviceOffered[i]["serviceType"]);
            this.Yoga_SelectedServices.push(JSON.parse(JSON.stringify(s)));
          }
        }
        this.Yoga_SelectedCertificates = [];
        if(this.yogaProfile.certification !=  undefined)
        for (let k = 0; k < this.yogaProfile.certification.length; k++) {
          var c = this.Yoga_Certificates.find(cer => cer["name"] == this.yogaProfile.certification[k]["name"]);
          this.Yoga_SelectedCertificates.push(JSON.parse(JSON.stringify(c)));
        }
      }
      },
      error => {
        // alert("An error has occurred while saving data.");
        this.toastr.error('', 'An error has occurred while saving data.', {
          timeOut: 5000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      }
    )
  }
  editProfile() {
    this.isEditProf = false;
    this.isUpdateProf = true;
    this.isReadOnly = false;
    this.disabled = false;
  }
  SaveProfile(type) { 
    if (this.isFitness && (this.profile.gender == undefined || this.profile.gender.length == 0 || this.profile.firstName == null || this.profile.firstName == '' || this.profile.lastName == null || this.profile.lastName == '' || this.profile.address == null || this.addressfields.city == null || this.addressfields.pincode == null || this.addressfields.address == '' || this.addressfields.city == '' || this.addressfields.pincode == '' || this.SelectedTrainingSince == undefined || this.SelectedServices == undefined || this.SelectedTrainingSince.length == 0 || this.SelectedServices.length == 0 || this.profile.about == null || this.profile.about == '')) {
      this.toastr.error('', 'Mandatory fields are required in Fitness', {
        timeOut: 5000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (this.isYoga && (this.profile.gender == undefined || this.profile.gender.length == 0 || this.profile.firstName == null || this.profile.firstName == '' || this.profile.lastName == null || this.profile.lastName == '' || this.profile.address == null || this.addressfields.city == null || this.addressfields.pincode == null || this.addressfields.address == '' || this.addressfields.city == '' || this.addressfields.pincode == '' || this.Yoga_SelectedTrainingSince == undefined || this.Yoga_SelectedServices == undefined || this.Yoga_SelectedTrainingSince.length == 0 || this.Yoga_SelectedServices.length == 0 || this.yogaProfile.about == null || this.yogaProfile.about == '')) {
      this.toastr.error('', 'Mandatory fields are required in Yoga', {
        timeOut: 5000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (this.isFitness && (this.profileIntroVideo.profileVideoName == undefined || this.profileImage.profileImageName == undefined || this.profileIntroVideo.profileVideoName == '' || this.profileImage.profileImageName == '')){
      this.toastr.error('', 'Profile pic & Introduction video are mandatory in Fitness.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (this.isYoga && (this.yogaprofileIntroVideo.profileVideoName == undefined || this.yogaprofileImage.profileImageName == undefined || this.yogaprofileIntroVideo.profileVideoName == '' || this.yogaprofileImage.profileImageName == '')){
      this.toastr.error('', 'Profile pic & Introduction video are mandatory in Yoga.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else {
      if(this.isFitness){
      this.profile.certification = [];
      this.profile.trainerSocialAccounts = [];
      this.profile.achievements = [];
      this.profile.serviceOffered = [];
      this.profile.profileIntroVideo = this.profileIntroVideo;
      this.profile.profileImage = this.profileImage;
      this.profile.trainingSince = this.SelectedTrainingSince[0];
      if (this.SelectedCertificates != undefined) {
        for (var i = 0; i < this.SelectedCertificates.length; i++) {
          this.certification.certificateName = this.SelectedCertificates[i]["name"];
          this.profile.certification.push(this.certification);
        }
      }
      if (this.SelectedServices != undefined) {
        for (let i = 0; i < this.selectedServicesOffered.length; i++) {
          this.serviceOffered.serviceType = this.selectedServicesOffered[i]["name"];
          this.serviceOffered.yearsActiveFrom = this.selectedServicesOffered[i]["yearsActiveFrom"];
          this.profile.serviceOffered.push(JSON.parse(JSON.stringify(this.serviceOffered)));
        }
      }
      if (this.SelectedCertificates != undefined) {
        this.profile.certification = this.totalCertificatesSelected;
      }

      this.profile.isNutritionist = this.isNutritionist;

      this.profile.address = Array.of(this.addressfields);

      this.profile.trainerSocialAccounts.push(this.website);
      this.profile.trainerSocialAccounts.push(this.facebook);
      this.profile.trainerSocialAccounts.push(this.twitter);
      this.profile.trainerSocialAccounts.push(this.instagram);
      this.profile.achievements.push(this.achievement);
      this.profile.specializedIn = [];

      // if (this.profile.adminStatus == "Rejected")
        this.profile.adminStatus = "Under Review";

      this.profile.dateOfBirth = this.datepipe.transform(this.dateOfBirth.jsdate, "yyyy-MM-dd");

    }
    if(this.isYoga){
      this.yogaProfile.certification = [];
      this.yogaProfile.firstName = this.profile.firstName;
    
      this.yogaProfile.lastName  = this.profile.lastName;
      this.yogaProfile.gender = this.profile.gender;
      this.yogaProfile.phoneNumber = this.profile.phoneNumber;
      this.yogaProfile.trainerUserName= this.profile.trainerUserName;
      this.yogaProfile.trainerSocialAccounts = [];
      this.yogaProfile.achievements = [];
      this.yogaProfile.serviceOffered = [];
      this.yogaProfile.profileIntroVideo = this.yogaprofileIntroVideo;
      this.yogaProfile.profileImage = this.yogaprofileImage;
      this.yogaProfile.trainingSince = this.Yoga_SelectedTrainingSince[0];
      this.yogaProfile.specializedIn = this.SelectedSpecializedlist;
      if (this.Yoga_SelectedCertificates != undefined) {
        for (var i = 0; i < this.Yoga_SelectedCertificates.length; i++) {
          this.yogaCertification.certificateName = this.Yoga_SelectedCertificates[i]["name"];
          this.yogaProfile.certification.push(this.yogaCertification);
        }
      }
      if (this.Yoga_SelectedServices != undefined) {
        for (let i = 0; i < this.Yoga_selectedServicesOffered.length; i++) {
          this.serviceOffered.serviceType = this.Yoga_selectedServicesOffered[i]["name"];
          this.serviceOffered.yearsActiveFrom = this.Yoga_selectedServicesOffered[i]["yearsActiveFrom"];
          this.yogaProfile.serviceOffered.push(JSON.parse(JSON.stringify(this.serviceOffered)));
        }
      }
      if (this.Yoga_SelectedCertificates != undefined) {
        this.yogaProfile.certification = this.Yoga_totalCertificatesSelected;
      }

      this.yogaProfile.isNutritionist = this.isYogaNutritionist;

      this.yogaProfile.address = Array.of(this.addressfields);

      this.yogaProfile.trainerSocialAccounts.push(this.website);
      this.yogaProfile.trainerSocialAccounts.push(this.facebook);
      this.yogaProfile.trainerSocialAccounts.push(this.twitter);
      this.yogaProfile.trainerSocialAccounts.push(this.instagram);
      this.yogaProfile.achievements.push(this.yoga_achievement);

      // if (this.yogaProfile.adminStatus == "Rejected")
        this.yogaProfile.adminStatus = "Under Review";

      this.yogaProfile.dateOfBirth = this.datepipe.transform(this.dateOfBirth.jsdate, "yyyy-MM-dd");




    }
    if(this.isFitness && this.isYoga){
    var index = '/profile'
    this.db_service.updateDatainDB(this.profile, index).subscribe(
      response => {
        this.profileResponse = Array.of(response);
        if (type == "save") {
          this.loaderHide = false;
          this.isEditProf = true;
          this.isUpdateProf = false;
         
      
      }
      else {
        this.loaderHide = false;
        // console.log('destVideoCheck updated.')
      }
    },
      error => {
        this.loaderHide = false;
        this.toastr.error('', 'There is an error while updating your profile.', {
          timeOut: 5000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      
      }
    )

    this.yoga_db_service.updateDatainDB(this.yogaProfile,index).subscribe( response =>{
        this.sendEmailandNotification(this.yogaProfile.trainerId)
        this.toastr.success('', 'Profile updated successfully', {
          timeOut: 5000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
    })
    }else if(this.isFitness){
      var index = '/profile'
      this.db_service.updateDatainDB(this.profile, index).subscribe(
        response => {
          this.profileResponse = Array.of(response);
          if (type == "save") {
            this.loaderHide = false;
            this.isEditProf = true;
            this.isUpdateProf = false;
            this.toastr.success('', 'Profile updated successfully', {
              timeOut: 5000,
              positionClass: 'toast-top-right',
              progressBar: true,
              progressAnimation: 'increasing',
              tapToDismiss: true,
            });
           
            this.sendEmailandNotification(this.profile.trainerId)
        }
        else {
          this.loaderHide = false;
          // console.log('destVideoCheck updated.')
        }
      },
        error => {
          this.loaderHide = false;
          this.toastr.error('', 'There is an error while updating your profile.', {
            timeOut: 5000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        
        }
      )
    }else{
      this.yoga_db_service.updateDatainDB(this.yogaProfile,index).subscribe( response =>{
        if (type == "save") {
          this.loaderHide = false;
          this.isEditProf = true;
          this.isUpdateProf = false;
          this.toastr.success('', 'Profile updated successfully', {
            timeOut: 5000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
       
        
          this.sendEmailandNotification(this.yogaProfile.trainerId)
      }
      else {
        this.loaderHide = false;
        // console.log('destVideoCheck updated.')
      }
    })

    }
  }
  }
  isInArray(array, word) {
    return array.indexOf(word.toLowerCase()) > -1;
  }

  sendEmailandNotification(trainerId){
    let notificationModel = {};
    let emailModel = {};
    notificationModel['userId'] = "Admin",
    notificationModel['trainerId'] = trainerId;
    notificationModel['templateCode'] = "1o1_N_TPUTA_014";
    notificationModel['notificationType'] = "Post";
    this.db_service.savenotification(notificationModel).subscribe(response => {

    })
    emailModel['trainerId'] = trainerId;
    emailModel['templateCode'] = "1o1_TPU_013";
    this.db_service.sendEmail(emailModel).subscribe(response => {

    })
    

    this.router.navigate(['dashboard']);
  }

  onCategorySelect(event){
      var obj = {};
      obj['category_name'] = event.name;
      obj['primary'] = false;
      this.userselectedCategories.push(JSON.parse(JSON.stringify(obj)));
    
      this.cdr.detectChanges(); 
      if(event.name == "Fitness")this.isFitness = true
      else if(event.name= "Yoga")this.isYoga = true;
      else this.isZumba = true;

  }
  onCategoryDeselect(event){
      if(event.name == "Bodybuilder")
        event.name = "Fitness"
      const index = this.userselectedCategories.findIndex(item => item.name == event.name);
      if(event.name == "Fitness")
          event.name = "Bodybuilder"
      const index2 = this.userCategories.findIndex(item => item.category_name == event.name);
      if(index2 > -1){
        this.toastr.error('', 'Selected categories cannot be changed.', {
          timeOut: 5000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      this.setCategories();
        return
      }else{
        this.userselectedCategories.splice(index, 1);
        this.cdr.detectChanges();
        if(event.name == "Fitness")this.isFitness = false
        else if(event.name= "Yoga")this.isYoga = false;
        else this.isZumba = false;
      }
  }

  onServiceSelect(event) {
    var obj = {};
    obj["name"] = event.name
    obj["yearsActiveFrom"] = "";
    this.selectedServicesOffered.push(JSON.parse(JSON.stringify(obj)));
    this.cdr.detectChanges();
    // for (let i = 0; i < this.selectedServicesOffered.length; i++) {
    //   this.selectedServicesOffered[i]["yearsActiveFrom"] = "";
    // }
  }
  onServiceDeselect(event) {
    // console.log("deslect event" + event);
    // const index = this.selectedServicesOffered.indexOf(event.id, 0);
    // const index = this.selectedServicesOffered.findIndex(event => event.id);
    const index = this.selectedServicesOffered.findIndex(item => item.name == event.name);
    if (index > -1) {
      this.selectedServicesOffered.splice(index, 1);
      this.cdr.detectChanges();
    }
    // this.selectedServicesOffered =  this.selectedServicesOffered.filter(obj => obj !== event);
  }

 

  getAdminComments() {
    if (this.adminComments != undefined && this.adminComments.length != 0) {
      this.showAdminComments = true;
    } else {
      this.toastr.error('', 'There are no comments.', {
        timeOut: 5000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
  }
  onYogaFileSelect(event, filetype, cer_name) {

    this.selectedFile = event.target.files[0];
    if(this.selectedFile.name.includes("+")){
      this.toastr.error('', "No special characters allowed like '+'.", {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return;
    }
   

    this.fileExtension = this.selectedFile.name.split('.').pop();

    if (filetype == 'yoga_image') {
    
      if (this.isInArray(this.globals.ImgallowedExtensions, this.fileExtension)) {
        if (this.yogaprofileImage.profileImageName != "") {
          var imageName = this.yogaprofileImage.profileImageName.replace(/ /g, "_");
          this.awsService.deleteFileFromS3(imageName, this.profile.trainerId, this.globals.DEST_BUCKET, this.globals.IMGFOLDER).subscribe(
            response => {
              this.onFileUpload(filetype, null);

            },
            error => {
              console.log('There is an error while deleting image.', error);

            })
        } else {
          this.onFileUpload(filetype, null);
        }
      } else {
        this.toastr.error('', 'Please upload only images.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
       
      }
    }
    else if (filetype == 'yoga_certificate') {
     
      if (this.isInArray(this.globals.Cer_allowedExtensions, this.fileExtension)) {
        if (this.yogaCertification.certificateDestinationUploadUrl != "") {
          var certificateName = this.yogaCertification.uploadedCertificateName.replace(/ /g, "_");
          this.awsService.deleteFileFromS3(certificateName, this.profile.trainerId, this.globals.DEST_BUCKET, this.globals.CER_FOLDER).subscribe(
            response => {
              this.onFileUpload(filetype, cer_name);
            
            },
            error => {
              console.log('There is an error while deleting certificate.', error);

            })
        } else {
          this.onFileUpload(filetype, cer_name);
        }
      } else {
        this.toastr.error('', 'Please upload pdf format files.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      }
    }
    else if (filetype == 'yoga_nutrition_certificate') {
      if (this.isInArray(this.globals.Cer_allowedExtensions, this.fileExtension)) {
        if (this.isYogaNutritionist.certificateDestinationUploadUrl != "") {
          var certificateName = this.isYogaNutritionist.certificateName.replace(/ /g, "_");
          this.awsService.deleteFileFromS3(certificateName, this.profile.trainerId, this.globals.DEST_BUCKET, this.globals.CER_FOLDER).subscribe(
            response => {
              this.onFileUpload(filetype, null);
            },
            error => {
              console.log('There is an error while deleting certificate.', error);

            })
        } else {
          this.onFileUpload(filetype, null);
        }
      } else {
        this.toastr.error('', 'Please upload pdf format files.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        // }
      }
    }
    else {
      
      if (this.isInArray(this.globals.VideoallowedExtensions, this.fileExtension)) {

        if (this.yogaprofileIntroVideo.profileVideoName != "") {
          var videoName = this.yogaprofileIntroVideo.profileVideoName.replace(/ /g, "_");
          this.awsService.deleteFileFromS3(videoName, this.profile.trainerId, this.globals.DEST_BUCKET, this.globals.PROFILE_FOLDER).subscribe(
            response => {
              //this.fileUpload(filetype);
              // console.log('Video deleted successfully.', response);
              var assetId = this.yogaprofileIntroVideo.videoMp4Destination.split("/");
              this.awsService.deleteDestinationFolder(this.profile.trainerId, this.globals.DEST_BUCKET, this.globals.PROFILE_FOLDER, assetId[6]).subscribe(
                response => {
                  this.onFileUpload(filetype, null);
                },
                error => {
                  console.log('There is an error deleting video from destination folder.', error);
                })

            },
            error => {
              console.log('There is an error deleting video from source folder.', error);
            })
        } else {
          this.onFileUpload(filetype, null);
        }

      } else {
        this.toastr.error('', 'Please upload only videos with mp4 format.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
       
      }
    }
  }

  selectFile(event, filetype, cer_name) {

    this.selectedFile = event.target.files[0];
    if (this.selectedFile.name.includes("+")) {
      this.toastr.error('', "No special characters allowed like '+'.", {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return;
    }
    // var format = /[!@#$%^&*()_+\=\[\]{};':"\\|,<>\/?]/;
    // var fileCheck = format.test(this.selectedFile.name);

    this.fileExtension = this.selectedFile.name.split('.').pop();

    if (filetype == 'image') {
      // var allowedExtensions = ["jpg","jpeg","png","JPG","JPEG","JFIF","BMP","SVG"];
      // if (fileCheck == true) {
      //   this.toastr.error('', 'Special characters are not allowed in image file name.', {
      //     timeOut: 3000,
      //     positionClass: 'toast-top-right',
      //     progressBar: true,
      //     progressAnimation: 'increasing',
      //     tapToDismiss: true,
      //   });
      // } else {
      if (this.isInArray(this.globals.ImgallowedExtensions, this.fileExtension)) {
        if (this.profileImage.profileImageName != "") {
          var imageName = this.profileImage.profileImageName.replace(/ /g, "_");
          this.awsService.deleteFileFromS3(imageName, this.profile.trainerId, this.globals.DEST_BUCKET, this.globals.IMGFOLDER).subscribe(
            response => {
              this.fileUpload(filetype, null);
              //console.log('Image deleted successfully.', response);

            },
            error => {
              console.log('There is an error while deleting image.', error);

            })
        } else {
          this.fileUpload(filetype, null);
        }
      } else {
        this.toastr.error('', 'Please upload only images.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        // }
      }
    }
    else if (filetype == 'certificate') {
      // if (fileCheck == true) {
      //   this.toastr.error('', 'Special characters are not allowed in certificate file name.', {
      //     timeOut: 3000,
      //     positionClass: 'toast-top-right',
      //     progressBar: true,
      //     progressAnimation: 'increasing',
      //     tapToDismiss: true,
      //   });
      // } else {
      if (this.isInArray(this.globals.Cer_allowedExtensions, this.fileExtension)) {
        if (this.certification.certificateDestinationUploadUrl != "") {
          var certificateName = this.certification.uploadedCertificateName.replace(/ /g, "_");
          this.awsService.deleteFileFromS3(certificateName, this.profile.trainerId, this.globals.SORCE_BUCKET, this.globals.CER_FOLDER).subscribe(
            response => {
              this.fileUpload(filetype, cer_name);
              //  console.log('Certificate deleted successfully.', response);

            },
            error => {
              console.log('There is an error while deleting certificate.', error);

            })
        } else {
          this.fileUpload(filetype, cer_name);
        }
      } else {
        this.toastr.error('', 'Please upload pdf format files.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        // }
      }
    }
    else if (filetype == 'nutrition_certificate') {
      // if (fileCheck == true) {
      //   this.toastr.error('', 'Special characters are not allowed in certificate file name.', {
      //     timeOut: 3000,
      //     positionClass: 'toast-top-right',
      //     progressBar: true,
      //     progressAnimation: 'increasing',
      //     tapToDismiss: true,
      //   });
      // } else {
      if (this.isInArray(this.globals.Cer_allowedExtensions, this.fileExtension)) {
        if (this.isNutritionist.certificateDestinationUploadUrl != "") {
          var certificateName = this.isNutritionist.certificateName.replace(/ /g, "_");
          this.awsService.deleteFileFromS3(certificateName, this.profile.trainerId, this.globals.SORCE_BUCKET, this.globals.CER_FOLDER).subscribe(
            response => {
              this.fileUpload(filetype, null);
              //  console.log('Certificate deleted successfully.', response);

            },
            error => {
              console.log('There is an error while deleting certificate.', error);

            })
        } else {
          this.fileUpload(filetype, null);
        }
      } else {
        this.toastr.error('', 'Please upload pdf format files.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        // }
      }
    }
    else {
      // if (fileCheck == true) {
      //   this.toastr.error('', 'Special characters are not allowed in video file name.', {
      //     timeOut: 3000,
      //     positionClass: 'toast-top-right',
      //     progressBar: true,
      //     progressAnimation: 'increasing',
      //     tapToDismiss: true,
      //   });
      // } else {
      if (this.isInArray(this.globals.VideoallowedExtensions, this.fileExtension)) {

        if (this.profileIntroVideo.profileVideoName != "") {
          var videoName = this.profileIntroVideo.profileVideoName.replace(/ /g, "_");
          this.awsService.deleteFileFromS3(videoName, this.profile.trainerId, this.globals.SORCE_BUCKET, this.globals.PROFILE_FOLDER).subscribe(
            response => {
              //this.fileUpload(filetype);
              // console.log('Video deleted successfully.', response);
              var assetId = this.profileIntroVideo.videoMp4Destination.split("/");
              this.awsService.deleteDestinationFolder(this.profile.trainerId, this.globals.DEST_BUCKET, this.globals.PROFILE_FOLDER, assetId[6]).subscribe(
                response => {
                  this.fileUpload(filetype, null);
                },
                error => {
                  console.log('There is an error deleting video from destination folder.', error);
                })

            },
            error => {
              console.log('There is an error deleting video from source folder.', error);
            })
        } else {
          this.fileUpload(filetype, null);
        }

      } else {
        this.toastr.error('', 'Please upload only videos with mp4 format.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        // }
      }
    }
  }
  fileUpload(filetype, cer_name) {
    this.loaderHide = !this.loaderHide;

    if (filetype == 'image') {
      this.profileImage.profileImageName = this.selectedFile.name;
      this.awsService.uploadFileToS3(this.selectedFile, this.profile.trainerId, this.globals.DEST_BUCKET, this.globals.IMGFOLDER, filetype).subscribe(
        response => {
          // var path = response.Key;
          // var splittedpath = path.split("/");
          this.profileImage.profileImagePath = this.globals.CLOUDFRONT_URL + response.Key;
          this.imgUrl = this.profileImage.profileImagePath;
          // this.profileImage.uploadedOn = new Date().toString();
          this.loaderHide = !this.loaderHide;
          // console.log('Image uploaded successfully.', response);
          this.isImgUploaded = "YES";
          this.toastr.success('', 'Image uploaded successfully.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        },
        error => {
          console.log('There is an error uploading image.', error);
          // console.log('Image uploaded successfully.', response);
          this.loaderHide = !this.loaderHide;
          this.toastr.error('', 'There is an error uploading image.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        })
    }
    else if (filetype == 'certificate') {
      for (let i = 0; i < this.totalCertificatesSelected.length; i++) {
        if (this.totalCertificatesSelected[i]["name"] == cer_name) {
          this.totalCertificatesSelected[i]["uploadedCertificateName"] = this.selectedFile.name;
        }
      }
      // this.certification.uploadedCertificateName = this.selectedFile.name;
      this.awsService.uploadFileToS3(this.selectedFile, this.profile.trainerId, this.globals.SORCE_BUCKET, this.globals.CER_FOLDER, filetype).subscribe(
        response => {
          for (let i = 0; i < this.totalCertificatesSelected.length; i++) {
            if (this.totalCertificatesSelected[i]["name"] == cer_name) {
              this.totalCertificatesSelected[i]["certificateDestinationUploadUrl"] = response.Location;
            }
          }
          //this.certification.certificateDestinationUploadUrl = response.Location;
          this.loaderHide = !this.loaderHide;
          //  console.log('Certificate uploaded successfully.', response);
          this.toastr.success('', 'Certificate uploaded successfully.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        },
        error => {
          console.log('There is an error uploading certificate.', error);
          // console.log('Image uploaded successfully.', response);
          this.loaderHide = !this.loaderHide;
          this.toastr.error('', 'There is an error uploading certificate.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        })
    } else if (filetype == 'nutrition_certificate') {
      this.isNutritionist.certificateName = this.selectedFile.name;
      this.awsService.uploadFileToS3(this.selectedFile, this.profile.trainerId, this.globals.SORCE_BUCKET, this.globals.CER_FOLDER, filetype).subscribe(
        response => {
          this.isNutritionist.certificateDestinationUploadUrl = response.Location;
          this.loaderHide = !this.loaderHide;
          // console.log('Certificate uploaded successfully.', response);
          this.toastr.success('', 'Certificate uploaded successfully.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        },
        error => {
          console.log('There is an error uploading certificate.', error);
          // console.log('Image uploaded successfully.', response);
          this.loaderHide = !this.loaderHide;
          this.toastr.error('', 'There is an error uploading certificate.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        })
    } else {
      this.profileIntroVideo.profileVideoName = this.selectedFile.name;
      this.profileIntroVideo.videoDestinationCheck = "false";
      this.awsService.uploadFileToS3(this.selectedFile, this.profile.trainerId, this.globals.SORCE_BUCKET, this.globals.PROFILE_FOLDER, filetype).subscribe(
        response => {
          //this.profile.key = response.Key;
          this.profileIntroVideo.videosourceUrl = response.sourceUrl;
          this.profileIntroVideo.videoMp4Destination = response.mp4Destination;
          this.profileIntroVideo.videoThumbnailPath = response.thumbnailDestination;
          //this.profileIntroVideo.videouploadedOn = new Date().toString();
          this.thumbUrl = response.thumbnailDestination;
          this.videoUrl = response.sourceUrl;
          //  console.log('Video uploaded Successfully.', response);
          // alert("Video uploaded successfully.")
          this.loaderHide = !this.loaderHide;
          this.toastr.success('', 'Video uploaded successfully.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
          this.videoPlay.nativeElement.muted = true;
          this.videoPlay.nativeElement.play();
        },
        error => {
          console.log('There is an error uploading video.', error);
          this.loaderHide = !this.loaderHide;
          this.toastr.error('', 'There is an error uploading video.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        })
    }
  }

  onFileUpload(filetype, cer_name) {
    this.loaderHide = !this.loaderHide;

    if (filetype == 'yoga_image') {
      this.yogaprofileImage.profileImageName = this.selectedFile.name;
      this.awsService.uploadFileToS3(this.selectedFile, this.profile.trainerId, this.globals.DEST_BUCKET, this.globals.IMGFOLDER, filetype).subscribe(
        response => {
          // var path = response.Key;
          // var splittedpath = path.split("/");
          this.yogaprofileImage.profileImagePath = this.globals.CLOUDFRONT_URL + response.Key;
          this.yogaimgUrl = this.yogaprofileImage.profileImagePath;
          // this.profileImage.uploadedOn = new Date().toString();
          this.loaderHide = !this.loaderHide;
          // console.log('Image uploaded successfully.', response);
          this.isImgUploaded = "YES";
          this.toastr.success('', 'Image uploaded successfully.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        },
        error => {
          console.log('There is an error uploading image.', error);
          // console.log('Image uploaded successfully.', response);
          this.loaderHide = !this.loaderHide;
          this.toastr.error('', 'There is an error uploading image.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        })
    }
    else if (filetype == 'yoga_certificate') {
      for (let i = 0; i < this.Yoga_totalCertificatesSelected.length; i++) {
        if (this.Yoga_totalCertificatesSelected[i]["name"] == cer_name) {
          this.Yoga_totalCertificatesSelected[i]["uploadedCertificateName"] = this.selectedFile.name;
        }
      }
      // this.certification.uploadedCertificateName = this.selectedFile.name;
      this.awsService.uploadFileToS3(this.selectedFile, this.profile.trainerId, this.globals.DEST_BUCKET, this.globals.CER_FOLDER, filetype).subscribe(
        response => {
          for (let i = 0; i < this.Yoga_totalCertificatesSelected.length; i++) {
            if (this.Yoga_totalCertificatesSelected[i]["name"] == cer_name) {
              this.Yoga_totalCertificatesSelected[i]["certificateDestinationUploadUrl"] = this.globals.CLOUDFRONT_URL + response.Key;
            }
          }
          //this.certification.certificateDestinationUploadUrl = response.Location;
          this.loaderHide = !this.loaderHide;
          //  console.log('Certificate uploaded successfully.', response);
          this.toastr.success('', 'Certificate uploaded successfully.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        },
        error => {
          console.log('There is an error uploading certificate.', error);
          // console.log('Image uploaded successfully.', response);
          this.loaderHide = !this.loaderHide;
          this.toastr.error('', 'There is an error uploading certificate.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        })
    } else if (filetype == 'yoga_nutrition_certificate') {
      this.isYogaNutritionist.certificateName = this.selectedFile.name;
      this.awsService.uploadFileToS3(this.selectedFile, this.profile.trainerId, this.globals.DEST_BUCKET, this.globals.CER_FOLDER, filetype).subscribe(
        response => {
          this.isYogaNutritionist.certificateDestinationUploadUrl = this.globals.CLOUDFRONT_URL + response.Key;
          this.loaderHide = !this.loaderHide;
          // console.log('Certificate uploaded successfully.', response);
          this.toastr.success('', 'Certificate uploaded successfully.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        },
        error => {
          console.log('There is an error uploading certificate.', error);
          // console.log('Image uploaded successfully.', response);
          this.loaderHide = !this.loaderHide;
          this.toastr.error('', 'There is an error uploading certificate.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        })
    } else {
      this.yogaprofileIntroVideo.profileVideoName = this.selectedFile.name;
      this.yogaprofileIntroVideo.videoDestinationCheck = "false";
      this.awsService.uploadFileToS3(this.selectedFile, this.profile.trainerId, this.globals.SORCE_BUCKET, this.globals.PROFILE_FOLDER, filetype).subscribe(
        response => {
          //this.profile.key = response.Key;
          this.yogaprofileIntroVideo.videosourceUrl = response.sourceUrl;
          this.yogaprofileIntroVideo.videoMp4Destination = response.mp4Destination;
          this.yogaprofileIntroVideo.videoThumbnailPath = response.thumbnailDestination;
          //this.profileIntroVideo.videouploadedOn = new Date().toString();
          this.thumbUrl = response.thumbnailDestination;
          this.yogavideoUrl = response.sourceUrl;
          //  console.log('Video uploaded Successfully.', response);
          // alert("Video uploaded successfully.")
          this.loaderHide = !this.loaderHide;
          this.toastr.success('', 'Video uploaded successfully.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
          this.yogaVideoPlay.nativeElement.muted = true;
          this.yogaVideoPlay.nativeElement.play();
        },
        error => {
          console.log('There is an error uploading video.', error);
          this.loaderHide = !this.loaderHide;
          this.toastr.error('', 'There is an error uploading video.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        })
    }
  }
  show(){
    console.log(this.SelectedSpecializedlist);
  }
  getConvertedDate(dateToConvert: Date) {
    var iMyDate = new IMyDate();
    iMyDate.day = dateToConvert.getDate();
    iMyDate.month = dateToConvert.getMonth() + 1;
    iMyDate.year = dateToConvert.getFullYear();
    var displayDate = new IMyDateModel();
    displayDate.jsdate = dateToConvert;
    displayDate.date = iMyDate;
    displayDate.formatted = this.datepipe.transform(dateToConvert, this.dateFormat);
    displayDate.epoc = dateToConvert.getTime() / 1000;
    return displayDate;
  }
  onItemDeselect(event) {
    if (this.SelectedCertificates.length == 0) {
      this.isCertificate = false;
    }
    const index = this.totalCertificatesSelected.findIndex(item => item.name == event.name);
    if (index > -1) {
      this.totalCertificatesSelected.splice(index, 1);
      this.cdr.detectChanges();
    }
  }
  onItemSelect(event) {
    if(this.totalCertificatesSelected == undefined)
        this.totalCertificatesSelected = [];
    var obj = {};
    obj["name"] = event.name
    obj["uploadedCertificateName"] = "";
    obj["certifiedDate"] = new Date();
    obj["certificateDestinationUploadUrl"] = "";
    this.totalCertificatesSelected.push(JSON.parse(JSON.stringify(obj)));

    // if (this.SelectedCertificates.length != 0) {
    //   this.totalCertificatesSelected = this.SelectedCertificates;
    //   for (let i = 0; i < this.totalCertificatesSelected.length; i++) {
    //     this.totalCertificatesSelected[i]["uploadedCertificateName"] = "";
    //     this.totalCertificatesSelected[i]["certifiedDate"] = Date;
    //     this.totalCertificatesSelected[i]["certificateDestinationUploadUrl"] = "";
    //   }
    //   this.isCertificate = true;
    // }
  }

  onYogaItemDeselect(event) {
    if (this.Yoga_SelectedCertificates.length == 0) {
      this.isYogaCertificate = false;
    }
    const index = this.Yoga_totalCertificatesSelected.findIndex(item => item.name == event.name);
    if (index > -1) {
      this.Yoga_totalCertificatesSelected.splice(index, 1);
      this.cdr.detectChanges();
    }
  }
  onYogaItemSelect(event) {
    var obj = {};
    obj["name"] = event.name
    obj["uploadedCertificateName"] = "";
    obj["certifiedDate"] = new Date();
    obj["certificateDestinationUploadUrl"] = "";
    if(this.Yoga_totalCertificatesSelected == undefined)
      this.Yoga_totalCertificatesSelected = [];
      
    this.Yoga_totalCertificatesSelected.push(JSON.parse(JSON.stringify(obj)));
  }

  onYogaServiceSelect(event) {
    var obj = {};
    obj["name"] = event.name
    obj["yearsActiveFrom"] = "";
    this.Yoga_selectedServicesOffered.push(JSON.parse(JSON.stringify(obj)));
    this.cdr.detectChanges();
    // for (let i = 0; i < this.selectedServicesOffered.length; i++) {
    //   this.selectedServicesOffered[i]["yearsActiveFrom"] = "";
    // }
  }
  onYogaServiceDeselect(event) {
    // console.log("deslect event" + event);
    // const index = this.selectedServicesOffered.indexOf(event.id, 0);
    // const index = this.selectedServicesOffered.findIndex(event => event.id);
    const index = this.Yoga_selectedServicesOffered.findIndex(item => item.name == event.name);
    if (index > -1) {
      this.Yoga_selectedServicesOffered.splice(index, 1);
      this.cdr.detectChanges();
    }
    // this.selectedServicesOffered =  this.selectedServicesOffered.filter(obj => obj !== event);
  }

  onNutritionSelect(type) {
    if(type == "yoga"){
      if (this.Yoga_SelectedNutrition.length != 0) {
        this.isYogaNutritionist.status = this.Yoga_SelectedNutrition[0]["name"];
      }
    }else{
    if (this.SelectedNutrition.length != 0) {
      this.isNutritionist.status = this.SelectedNutrition[0]["name"];
    }
  }
  }
  onNutritionDesSelect(type) {
    if(type != "yoga")
    this.isNutritionist.status = 'No';
    else
    this.isYogaNutritionist.status = 'No';
  }

  closePopup() {
    this.showAdminComments = false;
    this.isShowCertificate = false;
  }
  checkVideoUrl(Url) {
    var dest = Url.split("/");
    var FolderPath = dest[3] + "/" + dest[4] + "/" + dest[5] + "/" + dest[6] + "/" + dest[7];
    this.awsService.checkUrl(this.globals.DEST_BUCKET, FolderPath).subscribe(
      response => {
        if (response == "false") {
          this.videoUrl = this.profileIntroVideo.videosourceUrl;
        } else {
          this.videoUrl = this.profileIntroVideo.videoMp4Destination;
          this.profileIntroVideo.videoDestinationCheck = true;
          this.SaveProfile('videoCheck');
        }
      },
      error => {
        this.videoUrl = this.profileIntroVideo.videosourceUrl;
      }
    );
  }
  validateProfile() {
    let websiteRegex = new RegExp('^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/?[a-zA-Z0-9#]+\/?)*$');
    let regexpAlpha = new RegExp('^[A-Za-z ]*[A-Za-z][A-Za-z ]*$')
    let regexpNum = new RegExp('^(0|[1-9][0-9]*)$');
    let regexpPhoneNum = new RegExp('^((\\+91-?)|0)?[0-9]{10}$');
    if (this.profile.firstName == null || this.profile.firstName == '' || this.profile.lastName == null || this.profile.lastName == '') {
      this.toastr.error('', 'Mandatory fields are required', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (this.profile.phoneNumber == null || this.dateOfBirth == null || this.profile.phoneNumber == undefined || this.dateOfBirth == undefined) {
      this.toastr.error('', 'Mandatory fields are required', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (regexpAlpha.test(this.profile.firstName) === false || regexpAlpha.test(this.profile.lastName) === false) {
      this.toastr.error('', 'Please provide valid Information', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (regexpPhoneNum.test(this.profile.phoneNumber) === false) {
      this.toastr.error('', 'Please provide valid phone number', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (this.addressfields.address == null || this.addressfields.city == null || this.addressfields.address == null || this.addressfields.address == '' || this.addressfields.city == '' || this.addressfields.pincode == '') {
      this.toastr.error('', 'Mandatory fields are required', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (regexpAlpha.test(this.addressfields.city) === false || regexpAlpha.test(this.addressfields.state) === false || regexpAlpha.test(this.addressfields.country) === false || regexpNum.test(this.addressfields.pincode) === false) {
      this.toastr.error('', 'Please provide valid Information', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if ((this.isFitness)&&(this.SelectedTrainingSince == undefined || this.SelectedServices == undefined || this.SelectedTrainingSince.length == 0 || this.SelectedServices.length == 0)) {
      this.toastr.error('', ' Mandatory fields are required in fitness ', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if ((this.isYoga)&&(this.Yoga_SelectedTrainingSince == undefined || this.Yoga_SelectedServices == undefined || this.Yoga_SelectedTrainingSince.length == 0 || this.Yoga_SelectedServices.length == 0)) {
      this.toastr.error('', ' Mandatory fields are required in yoga ', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (this.isFitness && this.SelectedServices.length != 0 && this.selectedServicesOffered.find(x => x["yearsActiveFrom"] == '')) {

      this.toastr.error('', ' Please specify years active in services tab in fitness ', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });

    }
    else if (this.isYoga && this.Yoga_SelectedServices.length != 0 && this.Yoga_selectedServicesOffered.find(x => x["yearsActiveFrom"] == '')) {
      this.toastr.error('', ' Please specify years active in services tab in yoga ', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });

    }
    else if (this.isFitness && this.SelectedCertificates != undefined && this.SelectedCertificates.length != 0 && this.totalCertificatesSelected.find(x => x["uploadedCertificateName"] == '')) {

      this.toastr.error('', ' Selected certificates should be uploaded in fitness ', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });

    }
    else if (this.isYoga && this.Yoga_SelectedCertificates != undefined && this.Yoga_SelectedCertificates.length != 0 && this.Yoga_totalCertificatesSelected.find(x => x["uploadedCertificateName"] == '')) {

      this.toastr.error('', ' Selected certificates should be uploaded in yoga', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });

    }
    else if (this.isFitness && this.isNutritionist.status == 'Yes' && (this.isNutritionist.certificateName == "" || this.isNutritionist.nutrionistFrom == '')) {
      if (this.isNutritionist.certificateName == "") {
        this.toastr.error('', ' Please upload certificate for nutritionist in fitness ', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      }
      
      else if (this.isNutritionist.nutrionistFrom == "") {
        this.toastr.error('', ' Please specify years active as nutritionist', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      } else if (+this.isNutritionist.nutrionistFrom <= 0) {
        this.toastr.error('', ' Please specify years active as nutritionist greater than zero.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      }


    }
    else if (this.isYoga && this.isYogaNutritionist.status == 'Yes' && (this.isYogaNutritionist.certificateName == "" || this.isYogaNutritionist.nutrionistFrom == '')) {
      if (this.isNutritionist.certificateName == "") {
        this.toastr.error('', ' Please upload certificate for nutritionist in yoga ', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      }
   
      else if (+this.isYogaNutritionist.nutrionistFrom <= 0) {
        this.toastr.error('', ' Please specify years active as nutritionist greater than zero.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      }
         
      else if (this.isYogaNutritionist.nutrionistFrom == "") {
        this.toastr.error('', ' Please specify years active as nutritionist', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      }

    }
    else if (this.isFitness && (this.profile.about == null || this.profile.about == '')) {
      this.toastr.error('', 'Mandatory field is required in fitness', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (this.isYoga && (this.yogaProfile.about == null || this.yogaProfile.about == '')) {
      this.toastr.error('', 'Mandatory field is required in yoga', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (this.isFitness && this.profile.about.length < 20) {
      this.toastr.error('', 'About field should have minimum 20 characters in fitness', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (this.isYoga && this.yogaProfile.about.length < 20) {
      this.toastr.error('', 'About field should have minimum 20 characters in yoga', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }

    else {

      var dob = moment(this.datepipe.transform(this.dateOfBirth.jsdate, "yyyy-MM-dd"), 'YYYY-MM-DD');
      var todayDate = moment(this.datepipe.transform(new Date(), "yyyy-MM-dd"), 'YYYY-MM-DD');
      var duration = moment.duration(todayDate.diff(dob));
      var years = duration.asYears();

      console.log(years);
      if (years < 16) {
        this.toastr.error('', 'Minimum age should be 16 years.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        return;
      }
      this.SaveProfile('save');

    }
  }

  
  openViewer(url) {
    if (url.toLowerCase().includes('.pdf')) {
      // this.showpdfpreview = true;
      // this.showimagepreview = false;
      window.open(url, "_blank");
    } else {
      this.showpreivewdocument = url;
      this.isShowCertificate = true;
      this.showimagepreview = true;
      this.showpdfpreview = false;
    }
  }

  // pdfPreview() {
  //   window.open("https://www.irs.gov/pub/irs-pdf/fw9.pdf", "_blank");
  // }
}
