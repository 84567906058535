import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import * as AWS from "aws-sdk/global";
import * as awsservice from "aws-sdk/lib/service";
import * as CognitoIdentity from "aws-sdk/clients/cognitoidentity";
import { CognitoAuth } from 'amazon-cognito-auth-js';
import { Router } from '@angular/router';



export interface CognitoCallback {
    cognitoCallback(message: string, result: any): void;

    handleMFAStep?(challengeName: string, challengeParameters: ChallengeParameters, callback: (confirmationCode: string) => any): void;
}

export interface LoggedInCallback {
    isLoggedIn(message: string, loggedIn: boolean): void;
}

export interface ChallengeParameters {
    CODE_DELIVERY_DELIVERY_MEDIUM: string;

    CODE_DELIVERY_DESTINATION: string;
}

export interface Callback {
    callback(): void;

    callbackWithParam(result: any): void;
}

@Injectable()
export class CognitoUtil {

    // cognito auth tokens
    authData: any;
    auth: any;
    session: any;

    public static _REGION = environment.region;
    public region = environment.region;

    public static _IDENTITY_POOL_ID = environment.identityPoolId;
    public static _USER_POOL_ID = environment.userPoolId;
    public static _CLIENT_ID = environment.clientId;
    public static _GROUP_NAME = environment.GroupName;
    // Cognito Group

    public static _POOL_DATA: any = {
        UserPoolId: CognitoUtil._USER_POOL_ID,
        ClientId: CognitoUtil._CLIENT_ID
    };

    constructor(private router: Router) {
        AWS.config.update({
            region: this.region,
            credentials: new AWS.CognitoIdentityCredentials({
                IdentityPoolId: ''
            })
        });
        AWS.config.region = this.region;
        AWS.config.update({ accessKeyId: 'null', secretAccessKey: 'null' });
        this.getAuthInstance();
    }

    public cognitoCreds: AWS.CognitoIdentityCredentials;


    getUserPool() {
        if (environment.cognito_idp_endpoint) {
            CognitoUtil._POOL_DATA.endpoint = environment.cognito_idp_endpoint;
        }
        return new CognitoUserPool(CognitoUtil._POOL_DATA);
    }

    getCurrentUser() {
        return this.getUserPool().getCurrentUser();
    }

    // AWS Stores Credentials in many ways, and with TypeScript this means that
    // getting the base credentials we authenticated with from the AWS globals gets really murky,
    // having to get around both class extension and unions. Therefore, we're going to give
    // developers direct access to the raw, unadulterated CognitoIdentityCredentials
    // object at all times.
    setCognitoCreds(creds: AWS.CognitoIdentityCredentials) {
        this.cognitoCreds = creds;
    }

    getCognitoCreds() {
        return this.cognitoCreds;
    }

    // This method takes in a raw jwtToken and uses the global AWS config options to build a
    // CognitoIdentityCredentials object and store it for us. It also returns the object to the caller
    // to avoid unnecessary calls to setCognitoCreds.

    buildCognitoCreds(idTokenJwt: string) {
        let url = 'cognito-idp.' + CognitoUtil._REGION.toLowerCase() + '.amazonaws.com/' + CognitoUtil._USER_POOL_ID;
        if (environment.cognito_idp_endpoint) {
            url = environment.cognito_idp_endpoint + '/' + CognitoUtil._USER_POOL_ID;
        }
        let logins: CognitoIdentity.LoginsMap = {};
        logins[url] = idTokenJwt;
        let params = {
            IdentityPoolId: CognitoUtil._IDENTITY_POOL_ID, /* required */
            Logins: logins
        };
        let serviceConfigs = <awsservice.ServiceConfigurationOptions>{};
        if (environment.cognito_identity_endpoint) {
            serviceConfigs.endpoint = environment.cognito_identity_endpoint;
        }
        let creds = new AWS.CognitoIdentityCredentials(params, serviceConfigs);
        this.setCognitoCreds(creds);
        return creds;
    }


    getCognitoIdentity(): string {
        return this.cognitoCreds.identityId;
    }
    // getUser(){
    //     return localStorage.getItem('CognitoIdentityServiceProvider.2io8v4r253hugt5ndbjv9shkfp.LastAuthUser');
    // }

    getAccessToken(callback: Callback): void {
        if (callback == null) {
            throw ("CognitoUtil: callback in getAccessToken is null...returning");
        }
        if (this.getCurrentUser() != null) {
            this.getCurrentUser().getSession(function (err, session) {
                if (err) {
                    //console.log("CognitoUtil: Can't set the credentials:" + err);
                    callback.callbackWithParam(null);
                }
                else {
                    if (session.isValid()) {
                        callback.callbackWithParam(session.getAccessToken().getJwtToken());
                    }
                }
            });
        }
        else {
            callback.callbackWithParam(null);
        }
    }

    getIdToken(callback: Callback): void {
        if (callback == null) {
            throw ("CognitoUtil: callback in getIdToken is null...returning");
        }
        if (this.getCurrentUser() != null)
            this.getCurrentUser().getSession(function (err, session) {
                if (err) {
                    // console.log("CognitoUtil: Can't set the credentials:" + err);
                    callback.callbackWithParam(null);
                }
                else {
                    if (session.isValid()) {
                        callback.callbackWithParam(session.getIdToken().getJwtToken());
                    } else {
                        console.log("CognitoUtil: Got the id token, but the session isn't valid");
                    }
                }
            });
        else
            callback.callbackWithParam(null);
    }

    getRefreshToken(callback: Callback): void {
        if (callback == null) {
            throw ("CognitoUtil: callback in getRefreshToken is null...returning");
        }
        if (this.getCurrentUser() != null)
            this.getCurrentUser().getSession(function (err, session) {
                if (err) {
                    // console.log("CognitoUtil: Can't set the credentials:" + err);
                    callback.callbackWithParam(null);
                }

                else {
                    if (session.isValid()) {
                        callback.callbackWithParam(session.getRefreshToken());
                    }
                }
            });
        else
            callback.callbackWithParam(null);
    }

    refresh(): void {
        this.getCurrentUser().getSession(function (err, session) {
            if (err) {
                // console.log("CognitoUtil: Can't set the credentials:" + err);
            }

            else {
                if (session.isValid()) {
                    // console.log("CognitoUtil: refreshed successfully");
                } else {
                    //  console.log("CognitoUtil: refreshed but session is still not valid");
                }
            }
        });
    }

    // by default, say it's localhost
    getAuthInstance() {
      this.authData = {
        ClientId: environment.clientId,
        AppWebDomain: environment.cognito_domain_name,
        TokenScopesArray: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    //    RedirectUriSignIn: 'http://localhost:4200/login',
        // RedirectUriSignIn: 'https://dev.1o1.cloudns.asia/Trainers/login',
        RedirectUriSignIn: 'https://demo.1o1fitness.com/Trainers/login',
        // RedirectUriSignIn: 'https://uat.1o1fitness.com/Trainers/login',
        // RedirectUriSignIn: 'https://1o1fitness.com/Trainers/login',
        UserPoolId: environment.userPoolId,
        // RedirectUriSignOut: 'http://localhost:4200',
        // RedirectUriSignOut: 'https://dev.1o1.cloudns.asia/Trainers/',
        RedirectUriSignOut: 'https://demo.1o1fitness.com/Trainers/',
        // RedirectUriSignOut: 'https://uat.1o1fitness.com/Trainers/',
        // RedirectUriSignOut: 'https://1o1fitness.com/Trainers/',
        AdvancedSecurityDataCollectionFlag: false,
        IdentityProvider : 'Google', // e.g. 'Facebook',
        // ResponseType: 'code',
      }
      // const GoogleOauthUrl = 'https://' + this.authData.AppWebDomain + '/oauth2/authorize'
      //              + '?redirect_uri=' + this.authData.RedirectUriSignIn
      //              + '&response_type=code'
      //              + '&client_id=' + this.authData.ClientId
      //              + '&identity_provider=Google';


      // console.log("google:" +GoogleOauthUrl);

      this.auth = new CognitoAuth(this.authData);

    this.auth.userhandler = {
      // onSuccess: session => {
      //   console.log('Signin success');
      //   this.signedIn(session);
      // },
      // onFailure: error => {
      //   console.log('Error: ' + error);
      //   this.onFailureMethod();
      // }

      onSuccess: (result) => {
        // alert('Sign in success');
        console.log("sucess" +result);
        this.signedIn(result);
        // this.ngOnInit();
      },
      onFailure: (err) => {
        // alert('Sign in failed');
        this.onFailureMethod();
        console.log("Sign in failed" +err);
      }
    }

    //alert(this.router.url);
    this.auth.useCodeGrantFlow();
    return this.auth;
    // this.auth.parseCognitoWebResponse(this.router.url);
  }

  signedIn(session) {
    this.session = session;
  }

  onFailureMethod() {
    this.session = undefined;
  }

  get accessToken() {
    return this.session && this.session.getAccessToken().getJwtToken();
  }

  get isAuthenticated() {
    return this.auth.isUserSignedIn();
  }

  login() {
    this.auth.getSession();
    this.auth.parseCognitoWebResponse(this.router.url);
  }

  signOut() {
    this.auth.signOut();
  }
}
