import { Component, OnInit } from '@angular/core';
import { UserRegistrationService } from "../service/user-registration.service";
import { CognitoCallback } from "../service/cognito.service";
import { DBService } from '../service/db.service';
import { Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-resend',
  templateUrl: './resend.component.html',
  styleUrls: ['./resend.component.css']
})
export class ResendComponent implements CognitoCallback {

  email: string;
  userId: string;
  loaderHide: boolean = false;

  constructor(private db_service: DBService, public registrationService: UserRegistrationService, public router: Router, private toastr: ToastrService) { }

  resendCode() {
    if (this.email == null) {
      // console.log("Toaster");
      this.toastr.error('', 'Mandatory fields are required', {
        timeOut: 5000,
        extendedTimeOut: 1000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'decreasing',
        tapToDismiss: true
      });
    }
    else {
      this.loaderHide = !this.loaderHide;
      this.registrationService.resendCode(this.email, this);
      // this.getProfileDataFromServer();
    }
  }

  ngOnInit() {

    // document.body.classList.remove('body-main-build');
    // document.body.classList.remove('body-main-yoga');
    // document.body.classList.remove('body-main-zumba');
    // document.body.classList.add('body-main');
  }

  getProfileDataFromServer() {
    var index = '/profile/';
    this.db_service.getDataFromServer(this.email, index).subscribe(
      response => {
        //console.log('Data Retrived succesfully.', response);
        response = response[0];
        this.userId = response.trainerId;
      },
      error => {
        // alert("An error has occurred while retriving profile data.");
      }
    )
  }

  cognitoCallback(error: any, result: any) {
    if (error != null) {
      this.loaderHide = !this.loaderHide;
      if (error === 'Username/client id combination not found.') {
        this.toastr.error('', 'Invalid email, please enter your registered email.', {
          timeOut: 5000,
          extendedTimeOut: 1000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'decreasing',
          tapToDismiss: true
        });
      }
      else if (error === 'sent') {
        this.toastr.error('', 'Please SignIn with your registered email to confirm with active verification code sent to your email ID.', {
          timeOut: 5000,
          extendedTimeOut: 1000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'decreasing',
          tapToDismiss: true
        });
      }
      else {
        this.toastr.error('', error, {
          timeOut: 5000,
          extendedTimeOut: 1000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'decreasing',
          tapToDismiss: true
        });
      }
      // this.errorMessage = "Something went wrong...please try again";
    } else {
      this.loaderHide = !this.loaderHide;
      this.toastr.success('', 'Verification code has been sent to your registered email', {
        timeOut: 5000,
        extendedTimeOut: 1000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'decreasing',
        tapToDismiss: true
      });
      this.router.navigate(['confirm', this.email, this.userId]);
    }
  }
}
