import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { UserLoginService } from "../service/user-login.service";
import { LoggedInCallback, CognitoUtil } from "../service/cognito.service";
import { DBService } from '../service/db.service';
import { ToastrService } from 'ngx-toastr';
import { Integer } from 'aws-sdk/clients/athena';
import {IMyDpOptions} from 'mydatepicker';
import moment from 'moment';
import { DatePipe } from '@angular/common';
import { error } from 'console';
import { YogaDBService } from '../service/YogaDBService';


@Component({
  selector: 'app-nutrition',
  templateUrl: './nutrition.component.html',
  styleUrls: ['./nutrition.component.css']
})
export class NutritionComponent implements OnInit, OnDestroy, LoggedInCallback {

  currentCategory: string;
  currentGender: string;
  searchMeal: string;
  // Google Var
  googleUserEmail: string;
  private loggedIn: boolean;
  private subcrptn_1: any;
  currentLogged: string;
  currentEmail: string;
  myNutritionLength: number = 0;
  trainerId:string;
  currentMealId:string;
  showMealDeletePopup:boolean = false;
  mealplans: [];

  constructor(private renderer: Renderer2, private db_service: DBService, private yoga_db_service: YogaDBService, public router: Router,
    public route: ActivatedRoute, public userService: UserLoginService, private toastr: ToastrService, public cognitoUtil: CognitoUtil, public datepipe: DatePipe) {
    this.userService.isAuthenticated(this);
  }

  ngOnInit() {
    this.currentCategory = localStorage.getItem("ProfileCategory")
    this.currentGender = localStorage.getItem("ProfileGender");
    if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-build-male');
    }
    else if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-build-female');
    }
    else if (this.currentCategory == 'Yoga' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-yoga-male');
    }
    else if (this.currentCategory == 'Yoga' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-yoga-female');
    }
    else if (this.currentCategory == 'Zumba' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-zumba-male');
    }
    else if (this.currentCategory == 'Zumba' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-zumba-female');
    }


    this.userService.currentUserID.subscribe((val) => {
      if (val != '') {
        this.trainerId = val;
     }
    });

    if (this.trainerId != undefined && this.trainerId != "") {
      this.getMealPlanDataFromServer();
    }

    if (this.trainerId == undefined || this.trainerId == "") {
      this.getProfileDataFromServer();
    }

  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'bg-img-build-male');
    this.renderer.removeClass(document.body, 'bg-img-yoga-male');
    this.renderer.removeClass(document.body, 'bg-img-zumba-male');
    this.renderer.removeClass(document.body, 'bg-img-build-female');
    this.renderer.removeClass(document.body, 'bg-img-yoga-female');
    this.renderer.removeClass(document.body, 'bg-img-zumba-female');
    this.renderer.addClass(document.body, 'body-main');
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/login']);
    } else {
      // if()
      if (this.loggedIn == true) {
        this.currentEmail = localStorage.getItem('lastAuthUser');
        // if(this.currentEmail != undefined)
        this.getProfileDataFromServer();
      }
      else {
        // this.currentEmail = localStorage.getItem('CognitoIdentityServiceProvider.2io8v4r253hugt5ndbjv9shkfp.LastAuthUser');
        this.currentEmail = this.cognitoUtil.getCurrentUser().getUsername();
        if(this.currentEmail == undefined || this.currentEmail == '' || this.currentEmail == null || this.currentEmail.includes("Google") || this.currentEmail.includes("google"))
        {
          this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
          if(this.currentEmail == undefined)
          this.currentEmail = this.cognitoUtil.session.idToken.payload.email;
        }
        // this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
        this.getProfileDataFromServer();
      }
    }
  }

  getProfileDataFromServer() {
    var index = '/profile/';
    if(this.currentCategory == 'Bodybuilder'){
    this.db_service.getDataFromServer(this.currentEmail, index).subscribe(
      response => {
        response = response.data[0];
        this.trainerId = response.trainerId;
        this.getMealPlanDataFromServer();
      },
      error => {
        console.log("An error has occurred while retriving profile data.");
      }
    )
    }else{
      this.yoga_db_service.getDataFromServer(this.currentEmail, index).subscribe(
        response => {
          response = response.data[0];
          this.trainerId = response.trainerId;
          this.getMealPlanDataFromServer();
        },
        error => {
          console.log("An error has occurred while retriving profile data.");
        }
      )
    }
  }

  getMealPlanDataFromServer(){
    var index = "/mastermeal";
    var queryParsms={"trainerId": this.trainerId};
    if(this.currentCategory == 'Bodybuilder'){
    this.db_service.getQueriedData('',index, queryParsms).subscribe(
      response => {
         this.mealplans = response.data;
         this.myNutritionLength =  this.mealplans.length;
      },
     error => {
      console.log("Something went wrong while retrieving data.");
     }
    
    )
    }else{
      this.yoga_db_service.getQueriedData('',index, queryParsms).subscribe(
        response => {
           this.mealplans = response.data;
           this.myNutritionLength =  this.mealplans.length;
        },
       error => {
        console.log("Something went wrong while retrieving data.");
       }
      
      )
    }
  }

  deleteMeal(id) {
    this.currentMealId = id;
    this.showMealDeletePopup = true;
  }
  closePopup(){
    this.showMealDeletePopup = false;
  }

  onDeleteMeal(){
    this.showMealDeletePopup = false;
    var index = "/mastermeal/";
    if(this.currentCategory == 'Bodybuilder'){
    this.db_service.deleteRecordInDB(this.currentMealId, index).subscribe(
      response=>{
        this.toastr.success('', 'Deleted successfully.', {
          timeOut: 5000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        this.getMealPlanDataFromServer();
      },
      error =>{
        console.log("Something went wrong while retrieving data.");
      }
    )
    }else{
      this.yoga_db_service.deleteRecordInDB(this.currentMealId, index).subscribe(
        response=>{
          this.toastr.success('', 'Deleted successfully.', {
            timeOut: 5000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
          this.getMealPlanDataFromServer();
        },
        error =>{
          console.log("Something went wrong while retrieving data.");
        }
      )
    }
  }
}



