<section>
  <div class="container-fluid">
    <div class="fit-px-mt-5">
            <div class=" container-fluid">
              <div class="row">
                  <div class="heading1 mb-4 col-md-6 col-6" >
                    <i class="far fa-calendar-alt"></i><b>Calendar</b>
                  </div>
                  <div class="text-right col-md-6 col-6">
                    <button type="button" class="btn btn-default save-exercise-btn"
                    routerLink="/tprofile/{{program_id}}/{{trainee_id}}">BACK</button>
                  </div>
                </div>
                <!-- <div class="col-3" style="display: flex;"> <h1>Idle Weight:</h1>
                <input type="text" class="calender-input-idle-for col-3" placeholder="" name="idleFor" [(ngModel)]="calender.idleFor">
              </div> -->
            </div>
          <div class="container">
            <div class="row">
              <div class="col-md-6 mx-auto">
                <div class="trainee-profile-details-div p-2" style="border: 1px solid yellowgreen; border-radius: 10px;">
                  <div class="row">
                    <div class="col-md-3 text-center">
                      <div class="" >
                        <img class="rounded-circle" src="{{traineeImage}}" alt="image" style="width: 100px;height: 100px;" *ngIf="traineeImage != undefined">
                        <img class="rounded-circle" src="assets/images/default_profile_img.png" alt="image" style="width: 100px;height: 100px;" *ngIf="traineeImage == undefined">
                      </div>
                      <div class="col-md-12 text-center" style="font-size: 18px;font-weight: 600;text-transform: capitalize;"><b class = "word-ellipsis-div">{{trainee.first_name}}</b></div>
                    </div>

                    <div class="col-md-9" style="font-size: 14px;">
                      <div class="row">
                        <div class="col-md-5 col-8 mx-auto">
                          <div>
                            <span class="mp-trainee-width"><b>Gender: </b></span>
                            <span>{{trainee.gender}}</span>
                          </div>
                          <div>
                            <span class="mp-trainee-width"><b>Age: </b></span>
                            <span>{{trainee.age}}</span>
                          </div>
                          <div>
                            <span class="mp-trainee-width"><b>Height: </b></span>
                            <span>{{trainee.trainee_height.height | number : '1.2-2' }} </span><span>{{trainee.trainee_height.metric}}</span>
                          </div>
                          <div>
                            <span class="mp-trainee-width"><b>Weight: </b></span>
                            <span>{{trainee.currrent_weight.weight  | number : '1.2-2' }} </span>
                            <span>{{trainee.currrent_weight.metric}}</span>
                          </div>
                        </div>
                        <div class="col-md-7 col-8 mx-auto">
                          <div>
                            <span class="mp-trainee-width "><b>Daily Activity: </b></span>
                            <span>{{trainee.activity_level}}</span>
                          </div>
                          <div>
                            <span class="mp-trainee-width "><b>Diet: </b></span>
                            <span>{{traineeDiet}}</span>
                          </div>
                          <div>
                            <span class="mp-trainee-width"><b>BMR: </b></span>
                            <span>{{trainee.bmi_bmr.bmr_value}}</span>

                          </div>
                          <div>
                            <span class="mp-trainee-width "><b>BMI: </b></span
                              ><span>{{trainee.bmi_bmr.bmi_value}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                  <div class="col-md-6 col-6">
                    <button type="button" class="btn btn-circle prevBtn" *ngIf="currentPlan > 1" (click)="onPrevPlan()">
                      <i class="fas fa-angle-left"></i>
                    </button>
                    <span style="font-size: 22px; font-weight: bold;">Plan {{ currentPlan }}</span>
                    <button type="button" class="btn btn-circle nextBtn" *ngIf="currentPlan != calendarPlans" id="nextBtn"
                      (click)="onNxtPlan()">
                      <i class="fas fa-angle-right"></i>
                    </button>


                  </div>
                  <div class="col-md-6 col-6">
                  <div class="d-flex float-right" *ngIf="isNutrition">
                    <div class="p-2">
                      <div style="font-size: 20px; font-weight: 600;">Meal Plan</div>
                    </div>
                    <ui-switch class="p-2 swtich-mealplan" defaultBgColor="grey" [(ngModel)]="switchMeal" (change)="onMealSwich()"></ui-switch>
                  </div>
                  </div>
              </div>
            </div>
            <div class="col-md-12 mt-3">
              <div class="row">
              <div class="col-md-3">
                <div class="d-flex">
                <span style="min-width: 90px; max-width: 120px; font-weight:700;">Start date:</span>
                  <my-date-picker [options]="myDatePickerOptions" id="programStartDate" name="programStartDate" [(ngModel)]="programStartDate" placeholder="date" style="width: 100%;" [disabled]="isDateDisabled"></my-date-picker>
                </div>
                </div>
              <div class="col-md-9">
                <button type="button" class="btn btn-default save-exercise-btn m-0" style="margin-top: 10px;float: right;"
                *ngIf="isPublish == true && switchMeal == false && isTraineeActive == true" (click)="validatePublish()">PUBLISH</button>
              <button type="button" class="btn btn-default save-exercise-btn m-0" style="margin-top: 10px;float: right;"
                *ngIf="isMealPublish == true && switchMeal == true  && isTraineeActive == true" (click)="validatePublish()">PUBLISH</button>
                </div>
              </div>
              </div>

            <div class="calendar__table">
              <div class="head_layout">
                <!-- <div class="head_row">
                </div> -->
                <div class="head_row" fxLayout="row wrap" fxLayoutAlign="flex-start">
                  <div class="head_content fit-empty-div" style="width:10.5%;"></div>
                  <ng-container *ngFor="let dayName of calendarDayNames">
                    <div class="head_content" fxFlex="0 1 calc(12.7%)"
                      style="color:rgb(255, 255, 255);font-weight: 700;"> {{ dayName }}</div>
                  </ng-container>
                </div>
              </div>
              <div class="body_layout d-flex" style="width:100%;">
                <div class="body_column" style="width:10.5%;" fxLayout="column wrap" fxLayoutAlign="flex-start">
                  <ng-container *ngFor="let week of calendarWeekNames">
                    <div class="column_content calendar__week" style="margin-top: 48px;">
                      WEEK {{ week }}<br>
                    </div>
                  </ng-container>
                </div>
                <div class="body_row" fxLayout="row wrap" fxLayoutAlign="flex-start" style="width:89.5%;">
                  <ng-container *ngFor="let day of calendarDaysArr">
                    <div class="body_content" fxFlex="0 1 calc(14.21%)">
                      <div style="text-align: center;"> Day {{ day }} </div>


                      <div *ngFor="let dy of daysdata">
                        <div *ngFor="let d of dy.days">
                          <div class="container-fluid calendar-cell-scroll" *ngIf="switchMeal == false">
                            <div class="row calendar-cell-scroll" *ngIf="d.day == day">
                              <div class="container-fluid text-center">
                                <div class="row py-2 ">
                                    <div class="col px-1 py-1" style="cursor: pointer;" (click)="openModal(day)">
                                      <img class="cal-img" title="Add workout" src="assets/images/icons/exe_add.png" >
                                    </div>
                                    <div class="col px-1 py-1" data-toggle="modal" data-target="#exampleModal" style="cursor: pointer;" (click)="getWorkouts(day,template)" *ngIf="d.workouts.length!=0">
                                      <img class="cal-img" title="View workouts" *ngIf="d.dayStatus == 'new'" src="assets/images/icons/exe_view.png" >
                                      <img class="cal-img" title="View workouts" *ngIf="d.dayStatus == 'inprogress'" src="assets/images/workout-inprogress.png" >
                                      <img class="cal-img" title="View workouts" *ngIf="d.dayStatus == 'completed'" src="assets/images/workout-complete.png" >
                                      <img class="cal-img" title="View workouts" *ngIf="d.dayStatus == 'not completed'" src="assets/images/workout-notcompleted.png" >
                                    </div>

                                    <div class="col px-1 py-1" data-toggle="modal" style="cursor: pointer;"  *ngIf="d.rest">
                                      <img class="cal-img" title="Rest" src="assets/images/Group 10.png" >
                                    </div>
                                  </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                      <div *ngIf="switchMeal">
                        <div *ngFor="let dy of mealplandata">
                          <div *ngFor="let d of dy.days">

                              <div *ngIf="d.day == day">

                                <div class="col-md-12 px-1">
                                  <div class="d-flex">

                                    <div class="col px-1 py-3" >
                                      <img class="cal-img" title="Add meal plan" src="assets/images/icons/exe_add.png" style="cursor: pointer;" (click)="openModal(day)"/>
                                    </div>
                                    <div class="col px-1 py-3" *ngIf="d.mealplan != null && (d.mealplan.breakfast != null || d.mealplan.dinner != null || d.mealplan.lunch != null || d.mealplan.snacks != null) && (d.mealplan.mealPlanStatus == null || d.mealplan.mealPlanStatus == 'new')">
                                      <img
                                      class="cal-img" src="assets/images/mealbowl.png" width="10"  title="View meal plan"
                                      (click)="viewMealPlan(d.date)"  />
                                    </div>
                                    <div class="col px-1 py-3"  *ngIf="d.mealplan != null && (d.mealplan.breakfast != null || d.mealplan.dinner != null || d.mealplan.lunch != null || d.mealplan.snacks != null) && d.mealplan.mealPlanStatus == 'inprogress'">
                                      <img class="cal-img" title="View meal plan" width="10" (click)="viewMealPlan(d.date)" (click)="viewMealPlan(d.date)"  src="assets/images/meal-inprogress.png" style="cursor: pointer;" (click)="openModal(day)" />
                                    </div>
                                    <div class="col px-1 py-3" *ngIf="d.mealplan != null && (d.mealplan.breakfast != null || d.mealplan.dinner != null || d.mealplan.lunch != null || d.mealplan.snacks != null) && d.mealplan.mealPlanStatus == 'completed'">
                                      <img class="cal-img" title="View meal plan" width="10" (click)="viewMealPlan(d.date)" (click)="viewMealPlan(d.date)"  src="assets/images/meal-completed.png" style="cursor: pointer;" (click)="openModal(day)"  />
                                    </div>
                                      <div class="col px-1 py-3" *ngIf="d.mealplan != null && (d.mealplan.breakfast != null || d.mealplan.dinner != null || d.mealplan.lunch != null || d.mealplan.snacks != null) && d.mealplan.mealPlanStatus == 'not completed'">
                                      <img class="cal-img" title="View meal plan" width="10" (click)="viewMealPlan(d.date)" (click)="viewMealPlan(d.date)"  src="assets/images/meal-notcompleted.png" style="cursor: pointer;" (click)="openModal(day)"  />



                                    </div>

                                    <!-- <div class="col px-1" style="cursor: pointer;" (click)="openModal(day)" *ngIf="d.mealplan == null || (d.mealplan.breakfast == null && d.mealplan.dinner == null && d.mealplan.lunch == null && d.mealplan.snacks == null)">
                                      <img class="cal-img" title="Add workout" src="assets/images/icons/exe_add.png" >
                                    </div>

                                  <div style="font-size: 18px; font-weight: 300; cursor:pointer;" *ngIf="d.mealplan != null && (d.mealplan.breakfast != null || d.mealplan.dinner != null || d.mealplan.lunch != null || d.mealplan.snacks != null)">
                                    <img
                                      class="cal-img" src="assets/images/mealbowl.png" width="10"
                                      (click)="viewMealPlan(d.date)" />
                                  </div> -->
                                </div>
                              </div>
                              </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        <!-- </div> -->
      <!-- </div> -->
    </div>
    <ng-container *ngIf="showEventPopup">
      <div class="program_event-popup__wrapper">
        <div class="program_event-pop zoomIn">
          <div class="program_event-header text-center">
            <h3>Add To Day {{day}}</h3>
          </div>
          <a (click)="closePopup()" class="close-program_event-pop"><i class="fas fa-times"></i></a>
          <div class="program_event-body">
            <label class="container">Create Meal
              <input type="checkbox" name="chechbox" [(ngModel)]="isMealPlan" (change)="showFields($event,'create')">
              <span class="checkmark"></span>
            </label>
            <label class="container">Copy Meal
              <input type="checkbox" name="chechbox" [(ngModel)]="isMealChoose" (change)="showFields($event,'choose')">
              <span class="checkmark"></span>
            </label>
            <ng-multiselect-dropdown class="form-control col-12"
            [placeholder]="'Meal'"
            [settings]="dropdownSettings_single"
            [data]="MealPlans"
            [(ngModel)]="SelectedMeal"
            name="mealPlans"
            *ngIf="isMealChoose == true"
          >
          </ng-multiselect-dropdown>

            <label class="container">Copy Master Meal
              <input type="checkbox" name="chechbox" [(ngModel)]="isMasterMealChoose" (change)="showFields($event,'masterchoose')">
              <span class="checkmark"></span>
            </label>
            <ng-multiselect-dropdown class="form-control col-12"
            [placeholder]="'Meal'"
            [settings]="dropdownSettings_single"
            [data]="MasterMealPlans"
            [(ngModel)]="SelectedMasterMeal"
            name="mealPlans"
            *ngIf="isMasterMealChoose == true"
          >
          </ng-multiselect-dropdown>


            <label class="container" *ngIf="isLastWeek == true && isMealPublish == true">Repeat
              <input type="checkbox" name="checkbox" [(ngModel)]="isRepeatCk" (change)="showFields($event,'repeat')">
              <span class="checkmark"></span>
            </label>
            <ng-multiselect-dropdown class="form-control col-12"
            [placeholder]="'Repeat every'"
            [settings]="dropdownSettings1"
            [data]="repeats"
            [(ngModel)]="Selectedrepeat"
            name="repeats"
            *ngIf="isRepeatCk == true"
          >
          </ng-multiselect-dropdown>
            <div id="week-repeat-weekdays" class="ui-buttonset" *ngIf="isRepeatCk == true"
              style="font-size:13px;margin-top: 10px;">
              Week on
              <label *ngFor="let option of repeatDays" class="ui-button ui-widget ui-state-default ui-button-text-only"
                [ngClass]="{'ui-state-active': option.checked}"><span class="ui-button-text">{{ option.name }}</span>
                <input type="checkbox" name="repeatDays" id="week-dayname1" value="option.value"
                  [(ngModel)]="option.checked" class="week-day-repeat ui-helper-hidden-accessible"></label>

            </div>
            <ng-multiselect-dropdown class="form-control col-12"
              [placeholder]="'Repeat Untill'"
              [settings]="dropdownSettings1"
              [data]="calendarWeekNamesStr"
              [(ngModel)]="Selectedweek"
              name="weeks"
              *ngIf="isRepeatCk == true"
            >
            </ng-multiselect-dropdown>
            <label class="container" *ngIf="isMealPublish == true" >Clone Meal Plan
              <input type="checkbox" name="chechbox" [(ngModel)]="isCloneMealChoose"
                (change)="showFields($event,'clone')">
              <span class="checkmark"></span>
            </label>
            <ng-multiselect-dropdown class="form-control col-12"
            [placeholder]="'Select trainee'"
            [settings]="dropdownSettings_single"
            [data]="Trainees"
            [(ngModel)]="SelectedTrainee"
            name="trainees"
            *ngIf="isCloneMealChoose == true"
          >
          </ng-multiselect-dropdown>

          </div>
          <div class="program_event-footer d-flex col-12 justify-content-end">
            <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
              (click)="emitFunction()">Add</button>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="showWorkoutEventPopup">
      <div class="program_event-popup__wrapper">
        <div class="program_event-pop zoomIn">
          <div class="program_event-header text-center">
            <h3>Add To Day {{day}}</h3>
          </div>
          <a (click)="closePopup()" class="close-program_event-pop"><i class="fas fa-times"></i></a>
          <div class="program_event-body">
            <label class="container">Workout
              <input type="checkbox" name="chechbox" [(ngModel)]="isWorkoutCk" (change)="showFields($event,'workout')">
              <span class="checkmark"></span>
            </label>
            <ng-multiselect-dropdown  class="form-control col-12"
            [placeholder]="'Workouts'"
            [settings]="dropdownSettings"
            [data]="Workouts"
            [(ngModel)]="SelectedWorkouts"
            name="Workouts"
            *ngIf="isWorkoutCk == true && isRestCk == false"
          >
          </ng-multiselect-dropdown>

            <label class="container">Cardio
              <input type="checkbox" name="checkbox" [(ngModel)]="isCardioCk" (change)="showFields($event,'cardio')">
              <span class="checkmark"></span>
            </label>
            <!-- <input type="text" id="cardio"  name="cardio" [(ngModel)] = "cardio" *ngIf="isCardioCk == true && isRestCk == false" style="opacity:2; width:150px;margin-left: 25px"> -->
            <ng-multiselect-dropdown  class="form-control col-12"
            [placeholder]="'Cardio'"
            [settings]="dropdownSettings_single"
            [data]="cardiodata"
            [(ngModel)]="SelectedCardio"
            name="Cardio"
            *ngIf="isCardioCk == true && isRestCk == false"
          >
          </ng-multiselect-dropdown>

            <div class="form-group col-12 col-md-6" *ngIf="isCardioCk">Time
              <input type="number" OnlyPositiveNumbers numericType="number" class="form-control col-12" id="time"
                name="time" [(ngModel)]="distance" placeholder="Time" required>
            </div>
            <label class="container">Progress Photo
              <input type="checkbox" name="checkbox" [(ngModel)]="isProgressCk"
                (change)="showFields($event,'progress')">
              <span class="checkmark"></span>
            </label>
            <label class="container">Rest
              <input type="checkbox" name="checkbox" [(ngModel)]="isRestCk" (change)="showFields($event,'rest')">
              <span class="checkmark"></span>
            </label>
            <label class="container" *ngIf="isPublish">Repeat
              <input type="checkbox" name="checkbox" [(ngModel)]="isRepeatCk" (change)="showFields($event,'repeat')">
              <span class="checkmark"></span>
            </label>
            <ng-multiselect-dropdown  class="form-control col-12"
            [placeholder]="'Repeat every'"
            [settings]="dropdownSettings1"
            [data]="repeats"
            [(ngModel)]="Selectedrepeat"
            name="repeats"
            *ngIf="isRepeatCk == true"
          >
          </ng-multiselect-dropdown>

            <div id="week-repeat-weekdays" class="ui-buttonset" *ngIf="isRepeatCk == true"
              style="font-size:13px;margin-top: 10px;">
              Week on
              <label *ngFor="let option of repeatDays" class="ui-button ui-widget ui-state-default ui-button-text-only"
                [ngClass]="{'ui-state-active': option.checked}"><span class="ui-button-text">{{ option.name }}</span>
                <input type="checkbox" name="repeatDays" id="week-dayname1" value="option.value"
                  [(ngModel)]="option.checked" class="week-day-repeat ui-helper-hidden-accessible"></label>

            </div>
            <ng-multiselect-dropdown class="form-control col-12"
            [placeholder]="'Repeat Untill'"
            [settings]="dropdownSettings1"
            [data]="calendarWeekNamesStr"
            [(ngModel)]="Selectedweek"
            name="weeks"
            *ngIf="isRepeatCk == true"
          >
          </ng-multiselect-dropdown>

          </div>
          <div class="program_event-footer d-flex col-12 justify-content-end">
            <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
              (click)="SaveData()">Add</button>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- <div class="modal fade " id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
      style=" background: #0000008c;" *ngIf="showWorkoutsPopup">

      <div class="modal-dialog modal-dialog-centered modal-lg ">
        <div class="modal-content p-4 background-shadow-green" style="border-radius: 20px;">
          <div class="modal-header border-bottom-0">
            <h5 class="modal-title" id="exampleModalLabel" style="font-weight: 700; color: yellowgreen;">Day {{ day }}
              Program</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="right: 22px;">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="container-fluid p-0">
              <div class="row">
                <div class="col-md-3 py-3" style="overflow-y: scroll;overflow-x: hidden;">
                  <div class="col d-flex my-3 text-left p-2 exercise-list-active-div exercise-list-div"
                    *ngFor="let workout of dayWorkouts" (click)="getWorkoutExercises(workout.workoutId)">
                    <div class="p-2">
                      <img class="calendar-exerise-img-size" src="{{ workout.workoutName.imgUrl }}">
                    </div>
                    <div>
                      <div class="calendar-exercise-title-fnt">{{ workout.workoutName.name }}</div>
                      <div class="d-flex">
                      <span class="calendar-exercise-title-fnt my-1" style="font-weight: 500; font-size: 14px; width: 50px;">Status: </span>
                      <span *ngIf="workout.workoutStatus == 'new'" title="New"><img src="assets/images/new.png" style="width: 30px"></span>
                      <span *ngIf="workout.workoutStatus == 'inprogress'" title="Inprogress"><img src="assets/images/inprogress.png" style="width: 30px"></span>
                      <span *ngIf="workout.workoutStatus == 'completed'" title="Completed"><img src="assets/images/complete.png" style="width: 30px"></span>
                      <span *ngIf="workout.workoutStatus == 'not completed'" title="Not completed"><img src="assets/images/not-completed.png" style="width: 30px"></span>
                    </div>
                    </div>
                  </div>

                  <div class="col d-flex my-3 text-left p-2 exercise-list-active-div exercise-list-div" *ngIf="(dayCardio | json) != '{}'">
                    <div class="p-2">
                      <img class="calendar-exerise-img-size" src="{{ dayCardio.imgUrl }}">
                    </div>
                    <div class="col">
                      <div class="row">
                      <span class="calendar-exercise-title-fnt fit-exe-width" style="font-weight: 500; font-size: 14px;">{{ dayCardio.name }}</span>
                      <span class="calendar-exercise-title-fnt fit-exe-width" style="font-weight: 500; font-size: 14px;">{{ dayCardio.distance.actual }} minutes </span>

                      <div *ngIf="dayCardio.cardioStatus == 'new'" title="New" class="d-flex"><span class="calendar-exercise-title-fnt my-1 fit-exe-width" style="font-weight: 500; font-size: 14px; width: 50px;">Status: </span><img src="assets/images/new.png" style="width: 32px"></div>
                      <div *ngIf="dayCardio.cardioStatus == 'inprogress'" title="Inprogress" class="d-flex"><span class="calendar-exercise-title-fnt my-1 fit-exe-width" style="font-weight: 500; font-size: 14px; width: 50px;">Status: </span><img src="assets/images/inprogress.png" style="width: 32px"></div>
                      <div *ngIf="dayCardio.cardioStatus == 'completed'" title="Completed" class="d-flex"><span class="calendar-exercise-title-fnt my-1 fit-exe-width" style="font-weight: 500; font-size: 14px; width: 50px;">Status: </span><img src="assets/images/complete.png" style="width: 32px"></div>
                      <div *ngIf="dayCardio.cardioStatus == 'not completed'" title="Not completed" class="d-flex"><span class="calendar-exercise-title-fnt my-1 fit-exe-width" style="font-weight: 500; font-size: 14px; width: 50px;">Status: </span><img src="assets/images/not-completed.png" style="width: 32px"></div>
                    </div>
                  </div>
                  </div>
                </div>
                <div class="col-md-5" style="height: 380px; overflow-y: scroll; overflow-x: hidden;">


                  <div class="d-flex p-3" style="border-bottom: 1px solid yellowgreen!important;">
                    <div class="text-left col-md-6 calendar-exercise-bg">

                      <span class="px-2"> Exercise Name</span>
                    </div>
                    <div class="col-md-2 px-1 calendar-exercise-bg text-center">
                      <span>Sets</span>
                    </div>
                    <div class="col-md-2 px-1 calendar-exercise-bg text-center">
                      <span>Reps</span>
                    </div>
                    <div class="col-md-2 px-1 calendar-exercise-bg text-center">
                      <span>Status</span>
                    </div>

                  </div>

                  <div class="d-flex p-3" style="border-bottom: 1px solid yellowgreen!important;"
                    *ngFor="let exercise of workoutExercises">
                    <div class="text-left col-md-6 calendar-exercise-bg" (click)="getExerciseInfo(exercise.exerciseId)">
                      <i class="fas fa-play" *ngIf="exercise.exerciseId != 'rest_id'"></i>
                      <i class="fas fa-stopwatch" *ngIf="exercise.exerciseId == 'rest_id'"></i>
                      <span class="px-2"> {{ exercise.exerciseName }}</span>
                    </div>
                    <div class="col-md-2 px-1 calendar-exercise-input">
                      <input name="sets" class="rounded-pill col" type="text" [(ngModel)]="exercise.sets.length"
                        *ngIf="exercise.exerciseId != 'rest_id'" readonly>
                    </div>

                    <div class="col-md-2 px-1 calendar-exercise-input">
                      <input name="sets" class="rounded-pill col" type="text" [(ngModel)]="exercise.restPeriodVal"
                        *ngIf="exercise.exerciseId == 'rest_id'" readonly>
                    </div>
                    <div class="text-center col-md-2 calendar-exercise-bg">
                      <span *ngIf="exercise.exerciseStatus == 'new' && exercise.exerciseId != 'rest_id'" title="New"><img src="assets/images/new.png" style="width: 32px"></span>
                      <span *ngIf="exercise.exerciseStatus == 'inprogress' && exercise.exerciseId != 'rest_id'" title="Inprogress"><img src="assets/images/inprogress.png" style="width: 32px"></span>
                      <span *ngIf="exercise.exerciseStatus == 'completed' && exercise.exerciseId != 'rest_id'" title="Completed"><img src="assets/images/complete.png" style="width: 32px"></span>
                      <span *ngIf="exercise.exerciseStatus == 'not completed' && exercise.exerciseId != 'rest_id'" title="Not completed"><img src="assets/images/not-completed.png" style="width: 32px"></span>
                    </div>

                  </div>

                </div>
                <div class="col-md-4">
                  <div class="mb-2">
                    <div _ngcontent-c7="" class="exercise-upload-video" *ngIf="isVideoAvailable">

                      <video #viewworkLoad onloadedmetadata="this.muted=true" autoplay="" loop="" muted="" playsinline="" controls style="width: 100%;"
                        *ngIf="currentExercise.exerciseVideo.youtubeUrl == undefined || currentExercise.exerciseVideo.youtubeUrl == ''"
                        class="videoInsert">
                        <source attr.src="{{exercisePlayUrl}}" type="video/mp4">
                      </video>
                      <div class="exercise-upload-video" [innerHtml]="embedYoutubeUrl"
                        *ngIf="currentExercise.exerciseVideo.youtubeUrl != undefined && currentExercise.exerciseVideo.youtubeUrl != ''">
                      </div>
                    </div>
                  </div>
                  <div class="col text-justify" style="height: 100px; overflow-y: scroll;">
                    {{ currentExercise.instructions }}
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer border-top-0">

          </div>
        </div>
      </div>
    </div> -->
    <ng-template #template>
    <!-- <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content"> -->
        <div class="modal-header  border-bottom-0">
          <h5 class="modal-title" id="exampleModalLabel" style="font-weight: 700; color: yellowgreen;">Day {{ day }}
            Program</h5>
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
              <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-3 py-3" style="overflow-y: scroll;overflow-x: hidden;">
                <div class="col d-flex my-3 text-left p-2 exercise-list-active-div exercise-list-div"
                  *ngFor="let workout of dayWorkouts" (click)="getWorkoutExercises(workout.workoutId)">
                  <div class="p-2">
                    <img class="calendar-exerise-img-size" src="{{ workout.workoutName.imgUrl }}">
                  </div>
                  <div>
                    <div class="calendar-exercise-title-fnt">{{ workout.workoutName.name }}</div>
                    <div class="d-flex">
                    <span class="calendar-exercise-title-fnt my-1" style="font-weight: 500; font-size: 14px; width: 50px;">Status: </span>
                    <span *ngIf="workout.workoutStatus == 'new'" title="New"><img src="assets/images/new.png" style="width: 30px"></span>
                    <span *ngIf="workout.workoutStatus == 'inprogress'" title="Inprogress"><img src="assets/images/inprogress.png" style="width: 30px"></span>
                    <span *ngIf="workout.workoutStatus == 'completed'" title="Completed"><img src="assets/images/complete.png" style="width: 30px"></span>
                    <span *ngIf="workout.workoutStatus == 'not completed'" title="Not completed"><img src="assets/images/not-completed.png" style="width: 30px"></span>
                  </div>
                  </div>
                </div>

                <div class="col d-flex my-3 text-left p-2 exercise-list-active-div exercise-list-div" *ngIf="(dayCardio | json) != '{}'">
                  <div class="p-2">
                    <img class="calendar-exerise-img-size" src="{{ dayCardio.imgUrl }}">
                  </div>
                  <div class="col">
                    <div class="row">
                    <span class="calendar-exercise-title-fnt fit-exe-width" style="font-weight: 500; font-size: 14px;">{{ dayCardio.name }}</span>
                    <span class="calendar-exercise-title-fnt fit-exe-width" style="font-weight: 500; font-size: 14px;">{{ dayCardio.distance.actual }} minutes </span>

                    <div *ngIf="dayCardio.cardioStatus == 'new'" title="New" class="d-flex"><span class="calendar-exercise-title-fnt my-1 fit-exe-width" style="font-weight: 500; font-size: 14px; width: 50px;">Status: </span><img src="assets/images/new.png" style="width: 32px"></div>
                    <div *ngIf="dayCardio.cardioStatus == 'inprogress'" title="Inprogress" class="d-flex"><span class="calendar-exercise-title-fnt my-1 fit-exe-width" style="font-weight: 500; font-size: 14px; width: 50px;">Status: </span><img src="assets/images/inprogress.png" style="width: 32px"></div>
                    <div *ngIf="dayCardio.cardioStatus == 'completed'" title="Completed" class="d-flex"><span class="calendar-exercise-title-fnt my-1 fit-exe-width" style="font-weight: 500; font-size: 14px; width: 50px;">Status: </span><img src="assets/images/complete.png" style="width: 32px"></div>
                    <div *ngIf="dayCardio.cardioStatus == 'not completed'" title="Not completed" class="d-flex"><span class="calendar-exercise-title-fnt my-1 fit-exe-width" style="font-weight: 500; font-size: 14px; width: 50px;">Status: </span><img src="assets/images/not-completed.png" style="width: 32px"></div>
                  </div>
                </div>
                </div>
              </div>
              <div class="col-md-5" style="height: 380px; overflow-y: scroll; overflow-x: hidden;">


                <div class="d-flex p-3" style="border-bottom: 1px solid yellowgreen!important;">
                  <div class="text-left col-md-6 calendar-exercise-bg">

                    <span class="px-2"> Exercise Name</span>
                  </div>
                  <div class="col-md-2 px-1 calendar-exercise-bg text-center">
                    <span>Sets</span>
                  </div>
                  <div class="col-md-2 px-1 calendar-exercise-bg text-center">
                    <span>Reps</span>
                  </div>
                  <div class="col-md-2 px-1 calendar-exercise-bg text-center">
                    <span>Status</span>
                  </div>

                </div>

                <div class="d-flex p-3" style="border-bottom: 1px solid yellowgreen!important;"
                  *ngFor="let exercise of workoutExercises">
                  <div class="text-left col-md-6 calendar-exercise-bg" (click)="getExerciseInfo(exercise.exerciseId)">
                    <i class="fas fa-play" *ngIf="exercise.exerciseId != 'rest_id'"></i>
                    <i class="fas fa-stopwatch" *ngIf="exercise.exerciseId == 'rest_id'"></i>
                    <span class="px-2"> {{ exercise.exerciseName }}</span>
                  </div>
                  <div class="col-md-2 px-1 calendar-exercise-input">
                    <input name="sets" class="rounded-pill col" type="text" [(ngModel)]="exercise.sets.length"
                      *ngIf="exercise.exerciseId != 'rest_id'" readonly>
                  </div>

                  <div class="col-md-2 px-1 calendar-exercise-input">
                    <input name="sets" class="rounded-pill col" type="text" [(ngModel)]="exercise.restPeriodVal"
                      *ngIf="exercise.exerciseId == 'rest_id'" readonly>
                  </div>
                  <div class="text-center col-md-2 calendar-exercise-bg">
                    <span *ngIf="exercise.exerciseStatus == 'new' && exercise.exerciseId != 'rest_id'" title="New"><img src="assets/images/new.png" style="width: 32px"></span>
                    <span *ngIf="exercise.exerciseStatus == 'inprogress' && exercise.exerciseId != 'rest_id'" title="Inprogress"><img src="assets/images/inprogress.png" style="width: 32px"></span>
                    <span *ngIf="exercise.exerciseStatus == 'completed' && exercise.exerciseId != 'rest_id'" title="Completed"><img src="assets/images/complete.png" style="width: 32px"></span>
                    <span *ngIf="exercise.exerciseStatus == 'not completed' && exercise.exerciseId != 'rest_id'" title="Not completed"><img src="assets/images/not-completed.png" style="width: 32px"></span>
                  </div>

                </div>

              </div>
              <div class="col-md-4">
                <div class="mb-2">
                  <div _ngcontent-c7="" class="exercise-upload-video" *ngIf="isVideoAvailable">

                    <video #viewworkLoad onloadedmetadata="this.muted=true" autoplay="" loop="" muted="" playsinline="" controls style="width: 100%;"
                      *ngIf="currentExercise.exerciseVideo.youtubeUrl == undefined || currentExercise.exerciseVideo.youtubeUrl == ''"
                      class="videoInsert">
                      <source attr.src="{{exercisePlayUrl}}" type="video/mp4">
                    </video>
                    <div class="exercise-upload-video" [innerHtml]="embedYoutubeUrl"
                      *ngIf="currentExercise.exerciseVideo.youtubeUrl != undefined && currentExercise.exerciseVideo.youtubeUrl != ''">
                    </div>
                  </div>
                </div>
                <div class="col text-justify" style="height: 100px; overflow-y: scroll;">
                  {{ currentExercise.instructions }}
                </div>

              </div>
            </div>
          </div>
        </div>
      <!-- </div>
    </div> -->
  </ng-template> 
    <ng-container *ngIf="slaPopUp">
      <div class="program_event-popup__wrapper">
        <div class="program_event-pop zoomIn background-shadow-green" style="width:33% !important;">
          <div class="program_event-header">
            <h4>You have crossed SLA time to publish still do you want to continue
            </h4>
          </div>

          <div class="program_event-body d-flex justify-content-center">

            <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
              (click)="closePopup()">No</button>

            <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
              (click)="onPublish()">Yes</button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</section>
