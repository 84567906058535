import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { UserLoginService } from "../service/user-login.service";
import { LoggedInCallback, CognitoUtil } from "../service/cognito.service";
import { DBService } from '../service/db.service';
import { AWSService } from '../service/aws.service';
import { YogaDBService } from '../service/YogaDBService';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy, LoggedInCallback {

    currentCategory: string;
    currentGender: string;
    private sub: any;

    // Google Var
    googleUserEmail: string;
    private loggedIn: boolean;
    private subcrptn_1: any;
    currentLogged: string;
    currentEmail: string;
    isNutritionist: boolean = false;
    isRejectedProfile; boolean = false;
    // Vars
    userID: string;
    userCategories = [];

    constructor(private renderer: Renderer2, public router: Router, public route: ActivatedRoute,
        public userService: UserLoginService, private yoga_db_service: YogaDBService, private db_service: DBService, private awsService: AWSService,
        public cognitoUtil: CognitoUtil) {
        this.userService.isAuthenticated(this);
    }

    ngOnInit() {
        this.getCategoryDataFromServer();
        this.currentCategory = localStorage.getItem("ProfileCategory")
        this.currentGender = localStorage.getItem("ProfileGender");
        if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'male') {
            this.renderer.removeClass(document.body, 'body-main');
            this.renderer.addClass(document.body, 'bg-img-build-male');
        }
        else if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'female') {
            this.renderer.removeClass(document.body, 'body-main');
            this.renderer.addClass(document.body, 'bg-img-build-female');
        }
        else if (this.currentCategory == 'Yoga' && this.currentGender == 'male') {
            this.renderer.removeClass(document.body, 'body-main');
            this.renderer.addClass(document.body, 'bg-img-yoga-male');
        }
        else if (this.currentCategory == 'Yoga' && this.currentGender == 'female') {
            this.renderer.removeClass(document.body, 'body-main');
            this.renderer.addClass(document.body, 'bg-img-yoga-female');
        }
        else if (this.currentCategory == 'Zumba' && this.currentGender == 'male') {
            this.renderer.removeClass(document.body, 'body-main');
            this.renderer.addClass(document.body, 'bg-img-zumba-male');
        }
        else if (this.currentCategory == 'Zumba' && this.currentGender == 'female') {
            this.renderer.removeClass(document.body, 'body-main');
            this.renderer.addClass(document.body, 'bg-img-zumba-female');
        }

        this.userService.currentUserID.subscribe((val) => {
            if (val != '') {
                this.userID = val;
            }
        });
    }
    ngOnDestroy() {
        this.renderer.removeClass(document.body, 'bg-img-build-male');
        this.renderer.removeClass(document.body, 'bg-img-yoga-male');
        this.renderer.removeClass(document.body, 'bg-img-zumba-male');
        this.renderer.removeClass(document.body, 'bg-img-build-female');
        this.renderer.removeClass(document.body, 'bg-img-yoga-female');
        this.renderer.removeClass(document.body, 'bg-img-zumba-female');
        this.renderer.addClass(document.body, 'body-main');
    }

    isLoggedIn(message: string, isLoggedIn: boolean) {

      if (!isLoggedIn) {
        this.router.navigate(['/login']);
      } else {
        // if()
        if (this.loggedIn == true) {
          this.currentEmail = localStorage.getItem('lastAuthUser');
          // if(this.currentEmail != undefined)
          this.getProfileDataFromServer();
        }
        else {
          // this.currentEmail = localStorage.getItem('CognitoIdentityServiceProvider.2io8v4r253hugt5ndbjv9shkfp.LastAuthUser');
          this.currentEmail = this.cognitoUtil.getCurrentUser().getUsername();
          if(this.currentEmail == undefined || this.currentEmail == '' || this.currentEmail == null || this.currentEmail.includes("Google") || this.currentEmail.includes("google"))
          {
            this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
            if(this.currentEmail == undefined)
            this.currentEmail = this.cognitoUtil.session.idToken.payload.email;
          }
          // this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
          this.getProfileDataFromServer();
        }
      }
    }
    closePopup() {
        this.isRejectedProfile = false;
    }

    onUpdateProfile() {
        this.router.navigate(['mydata', this.userID]);
    }

    getProfileDataFromServer() {
        var index = '/profile/';
        if(localStorage.getItem("ProfileCategory") != "Yoga"){
        this.db_service.getDataFromServer(this.currentEmail, index).subscribe(
            response => {
                // console.log('Data Retrived succesfully.', response);
               
                response = response.data[0];
                this.userID = response.trainerId;
                var nutri = response["isNutritionist"];
                if (nutri["status"] == "Yes")
                    this.isNutritionist = true;
                if (response["adminStatus"] == "Rejected") {
                    this.isRejectedProfile = true;
                }
            },
            error => {
                console.log("An error has occurred while retriving profile data.");
            }
        )
        }else{
            this.yoga_db_service.getDataFromServer(this.currentEmail, index).subscribe(
                response => {
                    // console.log('Data Retrived succesfully.', response);
                    response = response.data[0];
                    this.userID = response.trainerId;
                    var nutri = response["isNutritionist"];
                    if (nutri["status"] == "Yes")
                        this.isNutritionist = true;
                    if (response["adminStatus"] == "Rejected") {
                        this.isRejectedProfile = true;
                    }
                },
                error => {
                    console.log("An error has occurred while retriving profile data.");
                }
            )
        }
    }

    getCategoryDataFromServer(){
        var index = '/profile/category/';
        this.db_service.getDataFromServer(this.currentEmail, index).subscribe(
          response => {
            var responceSample = response;
            this.userCategories = [];
            for(var i = 0 ; i < responceSample.data.length ; i++){
                  this.userCategories.push(responceSample.data[i]["category"][0]);
            }
            localStorage.setItem("userCategory",JSON.stringify(this.userCategories));
          })
    }
}
