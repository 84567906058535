import { Component, OnInit, Input, ViewChild, OnDestroy, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { UserLoginService } from "../service/user-login.service";
import { LoggedInCallback, CognitoUtil } from "../service/cognito.service";
import { AppGlobals } from '../app.globals';
import { DBService } from '../service/db.service';
import { ToastrService } from 'ngx-toastr';
import { Integer } from 'aws-sdk/clients/lambda';
import { AWSService } from '../service/aws.service';
import { FlexAlignStyleBuilder } from '@angular/flex-layout';
import { EmbedVideoService } from 'ngx-embed-video';
import moment from 'moment';
import { range } from "lodash";


// export class calender {
//   trainerId: string;
//   programId: string;
//   calendarId: string;
//   weeks: Object[];
//   startDate: Date;
//   endDate: Date;
//   idleFor: number;
//   creationDate: Date;
//   lastUpdatedDate: Date;
// }

export class CalendarDay {
  public date: Date;
  public title: string;
  public isPastDate: boolean;
  public isPastMonth: boolean;
  public isToday: boolean;

  public getDateString() {
    return this.date.toISOString().split("T")[0]
  }

  constructor(d: Date) {
    this.date = d;
    this.isPastDate = d.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);
    this.isPastMonth = d.getMonth() < new Date().getMonth();
    this.isToday = d.setHours(0, 0, 0, 0) == new Date().setHours(0, 0, 0, 0);
  }

}

@Component({
  selector: 'app-calendar-sample',
  templateUrl: './calendar-sample.component.html',
  styleUrls: ['./calendar-sample.component.css']
})

export class CalendarSampleComponent implements OnInit {
  daysdata: object[];
  @Input() imgUrl: string = "assets/images/uploadimg.svg";
  currentCategory: string;
  currentGender: string;
  currentLogged: string;
  currentEmail: string;
  // calender: calender;
  // Calendar Vars
  calendarDayNames: string[] = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  // Full calendar sample
  // public CalendarDay[] = [];
  public calendar: CalendarDay[] = [];
  public date: Date;
  public title: string;
  public isPastDate: boolean;
  public monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  public displayMonth: string;
  public displayYear: number;
  private monthIndex: number = 0;
  showAddSlot: boolean = false;


  constructor(private renderer: Renderer2, private awsService: AWSService, public router: Router,
    public route: ActivatedRoute, public userService: UserLoginService, private embedService: EmbedVideoService, private globals: AppGlobals,
    private db_service: DBService, private toastr: ToastrService, public cognitoUtil: CognitoUtil) {
    this.userService.isAuthenticated(this);
  }

  ngOnInit() {
    // this.calender = new calender();

    this.currentCategory = localStorage.getItem("ProfileCategory")
    this.currentGender = localStorage.getItem("ProfileGender");
    if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-build-male');
    }
    else if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-build-female');
    }
    else if (this.currentCategory == 'Yoga' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-yoga-male');
    }
    else if (this.currentCategory == 'Yoga' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-yoga-female');
    }
    else if (this.currentCategory == 'Zumba' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-zumba-male');
    }
    else if (this.currentCategory == 'Zumba' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-zumba-female');
    }
    // this.currentDate = moment();
    // this.selectedDate = moment(this.currentDate).format('DD/MM/YYYY');
    // this.generateCalendar();
    this.generateCalendarDays(this.monthIndex);
  }
  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'bg-img-build-male');
    this.renderer.removeClass(document.body, 'bg-img-yoga-male');
    this.renderer.removeClass(document.body, 'bg-img-zumba-male');
    this.renderer.removeClass(document.body, 'bg-img-build-female');
    this.renderer.removeClass(document.body, 'bg-img-yoga-female');
    this.renderer.removeClass(document.body, 'bg-img-zumba-female');
    this.renderer.addClass(document.body, 'body-main');
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/login']);
    } else {

      // this.currentEmail = localStorage.getItem('CognitoIdentityServiceProvider.2io8v4r253hugt5ndbjv9shkfp.LastAuthUser');
      this.currentEmail = this.cognitoUtil.getCurrentUser().getUsername();
      if(this.currentEmail == undefined || this.currentEmail == '' || this.currentEmail == null || this.currentEmail.includes("Google") || this.currentEmail.includes("google"))
        {
          this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
        }
      // this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
      this.getProfileDataFromServer();

    }
  }
  getProfileDataFromServer() {
    var index = '/profile/';
    this.db_service.getDataFromServer(this.currentEmail, index).subscribe(
      response => {
        //console.log('Data Retrived succesfully.', response);
        response = response.data[0];
        // this.calender.trainerId = response.trainerId;
      },
      error => {
        console.log("An error has occurred while retriving profile data.");
      }
    )
  }

  private generateCalendarDays(monthIndex: number): void {
    // we reset our calendar
    this.calendar = [];

    // we set the date
    let day: Date = new Date(new Date().setMonth(new Date().getMonth() + monthIndex));

    // set the dispaly month for UI
    this.displayMonth = this.monthNames[day.getMonth()];
    this.displayYear = day.getFullYear();
    let startingDateOfCalendar = this.getStartDateForCalendar(day);

    let dateToAdd = startingDateOfCalendar;

    for (var i = 0; i < 42; i++) {
      this.calendar.push(new CalendarDay(new Date(dateToAdd)));
      dateToAdd = new Date(dateToAdd.setDate(dateToAdd.getDate() + 1));
    }
  }

  private getStartDateForCalendar(selectedDate: Date) {
    // for the day we selected let's get the previous month last day
    let lastDayOfPreviousMonth = new Date(selectedDate.setDate(0));

    // start by setting the starting date of the calendar same as the last day of previous month
    let startingDateOfCalendar: Date = lastDayOfPreviousMonth;

    // but since we actually want to find the last Monday of previous month
    // we will start going back in days intil we encounter our last Monday of previous month
    if (startingDateOfCalendar.getDay() != 1) {
      do {
        startingDateOfCalendar = new Date(startingDateOfCalendar.setDate(startingDateOfCalendar.getDate() - 1));
      } while (startingDateOfCalendar.getDay() != 1);
    }

    return startingDateOfCalendar;
  }

  public increaseMonth() {
    this.monthIndex++;
    this.generateCalendarDays(this.monthIndex);
  }

  public decreaseMonth() {
    this.monthIndex--
    this.generateCalendarDays(this.monthIndex);
  }

  public setCurrentMonth() {
    this.monthIndex = 0;
    this.generateCalendarDays(this.monthIndex);
  }

  onAddSlot(date: string) {
    this.showAddSlot = true;
  }
  closePopup() {
    this.showAddSlot = false;
  }
}
