import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { DBService } from '../service/db.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from "@angular/router";
import { UserLoginService } from "../service/user-login.service";
import { LoggedInCallback, CognitoUtil } from "../service/cognito.service";

export class workout {
  trainerId: string;
  workoutId: string;
  workoutName: Object;
  customWorkoutName: string;
  workoutExercises: Object[];
  warnings: string;
}
@Component({
  selector: 'app-workout',
  templateUrl: './workout.component.html',
  styleUrls: ['./workout.component.css']
})
export class WorkoutComponent implements OnInit, OnDestroy, LoggedInCallback {

  constructor(private renderer: Renderer2, private db_service: DBService, private toastr: ToastrService,
    public router: Router, public route: ActivatedRoute, public userService: UserLoginService, public cognitoUtil: CognitoUtil) {
    this.userService.isAuthenticated(this);
  }
  currentEmail: string;
  workout: workout;
  workouts: object[];
  currentCategory: string;
  currentGender: string;
  searchWorkout: string;
  showDeletePopup: boolean = false;
  showAdminComments: boolean = false;
  adminComments: Object[];
  currentWorkoutId: string;
  // Google Var
  private loggedIn: boolean;
  workoutsLength: number;
  exerciseLength: number;
  pagenumber:number = 0;
  pagesize:number = 18;
  notSroclly:boolean = true;
  notEmptyPost:boolean = true;

  ngOnInit() {
    this.currentCategory = localStorage.getItem("ProfileCategory")
    this.currentGender = localStorage.getItem("ProfileGender");
    if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-build-male');
    }
    else if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-build-female');
    }
    else if (this.currentCategory == 'Yoga' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-yoga-male');
    }
    else if (this.currentCategory == 'Yoga' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-yoga-female');
    }
    else if (this.currentCategory == 'Zumba' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-zumba-male');
    }
    else if (this.currentCategory == 'Zumba' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-zumba-female');
    }

    this.workout = new workout();
    this.userService.currentUserID.subscribe((val) => {
      this.workout.trainerId = val;
    });
    if (this.workout.trainerId == undefined && this.workout.trainerId == "") {
      this.getProfileDataFromServer();
    }
    //this.workout.trainerId = "naresh_1e25dff44asds4654sdsd4";
    if (this.workout.trainerId != undefined && this.workout.trainerId != "") {
      this.getWorkoutDataFromServer();
    }

  }
  ngOnDestroy() {
    // this.sub.unsubscribe();
    this.renderer.removeClass(document.body, 'bg-img-build-male');
    this.renderer.removeClass(document.body, 'bg-img-yoga-male');
    this.renderer.removeClass(document.body, 'bg-img-zumba-male');
    this.renderer.removeClass(document.body, 'bg-img-build-female');
    this.renderer.removeClass(document.body, 'bg-img-yoga-female');
    this.renderer.removeClass(document.body, 'bg-img-zumba-female');
    this.renderer.addClass(document.body, 'body-main');
  }
  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/login']);
    } else {
      // if()
      if (this.loggedIn == true) {
        this.currentEmail = localStorage.getItem('lastAuthUser');
        // if(this.currentEmail != undefined)
        this.getProfileDataFromServer();
      }
      else {
        // this.currentEmail = localStorage.getItem('CognitoIdentityServiceProvider.2io8v4r253hugt5ndbjv9shkfp.LastAuthUser');
        this.currentEmail = this.cognitoUtil.getCurrentUser().getUsername();
        if(this.currentEmail == undefined || this.currentEmail == '' || this.currentEmail == null || this.currentEmail.includes("Google") || this.currentEmail.includes("google"))
        {
          this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
          if(this.currentEmail == undefined)
          this.currentEmail = this.cognitoUtil.session.idToken.payload.email;
        }
        // this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
        this.getProfileDataFromServer();
      }
    }
  }
  getProfileDataFromServer() {
    var index = '/profile/';
    this.db_service.getDataFromServer(this.currentEmail, index).subscribe(
      response => {
        // console.log('Data Retrived succesfully.', response);
        response = response.data[0];
        this.workout.trainerId = response.trainerId;
        this.getWorkoutDataFromServer();
      },
      error => {
        //  alert("An error has occurred while retriving profile data.");
      }
    )
  }
  getWorkoutDataFromServer() {
    var index = '/workout/all/';
    var queryparams={
      "pagesize": this.pagesize,
      "pagenumber":0,
      "pagination":true
    }
    this.db_service.getQueriedData(this.workout.trainerId, index, queryparams).subscribe(
      response => {
        // console.log('Data Retrived succesfully.', response);
        this.getExerciseDataFromServer();
        if (response.data != null) {
          //response = Array.of(response);
          this.workouts = response.data;
          this.workoutsLength = this.workouts.length;
        } else {
          this.workouts = [];
          this.workoutsLength = 0;
        }
        // for (let i = 0; i < this.workouts.length; i++) {
        //   var workoutName = this.workouts[i]["workoutName"];
        //   this.workouts[i]["name"] = workoutName[0]["name"];
        //   this.workouts[i]["imgPath"] = workoutName[0]["imgUrl"];
        // }
      },
      error => {
        console.log('There is an error while retriving workouts.');
      }
    )
  }
  onScroll(){
    console.log("scrolled..");
    if(this.notEmptyPost && this.notSroclly){
     this.notSroclly = false;
     this.pagenumber = this.pagenumber + 1;
     this.loadNext();
    }
  }
  loadNext(){
    var index = '/workout/all/';
    var queryparams={
      "pagesize": this.pagesize,
      "pagenumber":this.pagenumber,
      "pagination":true
    }
    this.db_service.getQueriedData(this.workout.trainerId, index, queryparams).subscribe(
      response => {
       if(response.data != null){
        if( this.workouts.length === 0){
          this.notEmptyPost = false;
        }
          this.workouts = this.workouts.concat(response.data);
          this.notSroclly = true;
      }
      },
      error => {
        console.log('There is an error while retriving workouts.');
      }
    )
  }
  editWorkout(id) {
    //  console.log(id);
    this.router.navigate(['viewworkout', id]);
  }
  deleteWorkout(id) {
    this.currentWorkoutId = id;
    var index = '/workout/' + this.workout.trainerId + '/' + id + '/status';
    this.db_service.getDataFromServer("", index).subscribe(response => {
      if (response.data == true) {
        this.toastr.error('', 'Cannot delete, Dependencies exist.', {
          timeOut: 5000,
          extendedTimeOut: 1000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true
        });
      } else {
        this.showDeletePopup = true;
      }
    })


  }
  onConfirmDelete() {
    var index = '/workout/';
    this.db_service.deleteRecordInDB(this.currentWorkoutId, index).subscribe(
      response => {
        this.showDeletePopup = false;
        this.toastr.success('', 'Workout deleted successfully.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        this.getWorkoutDataFromServer();
      },
      error => {
        //alert("An error has occurred while deleting record.");
        this.toastr.error('', 'An error has occurred while deleting record.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      }
    )
  }
  getAdminComments(id) {
    var workout = this.workouts.find(x => x["workoutId"] == id);
    this.adminComments = workout["approvalComments"];
    this.showAdminComments = true;
  }
  onAddWorkout() {
    if (this.exerciseLength > 0) {
      this.router.navigate(['addworkout']);
    } else {
      this.toastr.error('', 'Please add atleast one exercise before adding workout.', {
        timeOut: 5000,
        extendedTimeOut: 1000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true
      });
    }
  }

  getExerciseDataFromServer() {
    var index = '/exercise/all/';
    var queryparams={
      "pagesize": this.pagesize,
      "pagenumber":this.pagenumber,
      "pagination":false
    }
    this.db_service.getQueriedData(this.workout.trainerId, index, queryparams).subscribe(
      response => {
        // console.log('Data Retrived succesfully.', response);
        if (response.data != null) {
          this.exerciseLength = response.data.length;
        } else {
          this.exerciseLength = 0;
        }
      },
      error => {
        // alert("An error has occurred while saving data.");
        this.toastr.error('', 'An error has occurred while retrieving data.', {
          timeOut: 5000,
          extendedTimeOut: 1000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true
        });
      }
    )
  }
  closePopup() {
    this.showDeletePopup = false;
    this.showAdminComments = false;
  }
}
