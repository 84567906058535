import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import * as AWS from "aws-sdk/global";
import * as awsservice from "aws-sdk/lib/service";
import * as CognitoIdentity from "aws-sdk/clients/cognitoidentity";
import { Router } from '@angular/router';



export interface CognitoCallback {
    cognitoCallback(message: string, result: any): void;

    handleMFAStep?(challengeName: string, challengeParameters: ChallengeParameters, callback: (confirmationCode: string) => any): void;
}

export interface LoggedInCallback {
    isLoggedIn(message: string, loggedIn: boolean): void;
}

export interface ChallengeParameters {
    CODE_DELIVERY_DELIVERY_MEDIUM: string;

    CODE_DELIVERY_DESTINATION: string;
}

export interface CallbackTrainee {
    callback(): void;

    callbackWithParam(result: any): void;
}

@Injectable()
export class CognitoTraineeUtil {

    // cognito auth tokens
    authData: any;
    auth: any;
    session: any;

    public static _REGION = environment.trianee_region;
    public region = environment.trianee_region;

    public static _IDENTITY_POOL_ID = environment.trianee_identityPoolId;
    public static _USER_POOL_ID = environment.trianee_userPoolId;
    public static _CLIENT_ID = environment.trianee_clientId;
    public static _GROUP_NAME = environment.trianee_GroupName;
    // Cognito Group

    public static _POOL_DATA: any = {
        UserPoolId: CognitoTraineeUtil._USER_POOL_ID,
        ClientId: CognitoTraineeUtil._CLIENT_ID
    };

    constructor(private router: Router) {
        AWS.config.update({
            region: this.region,
            credentials: new AWS.CognitoIdentityCredentials({
                IdentityPoolId: ''
            })
        });
        AWS.config.region = this.region;
        AWS.config.update({ accessKeyId: 'null', secretAccessKey: 'null' });
    }

    public cognitoCreds: AWS.CognitoIdentityCredentials;


    getUserPool() {
        if (environment.trianee_cognito_idp_endpoint) {
            CognitoTraineeUtil._POOL_DATA.endpoint = environment.trianee_cognito_idp_endpoint;
        }
        return new CognitoUserPool(CognitoTraineeUtil._POOL_DATA);
    }

    getCurrentUser() {
        return this.getUserPool().getCurrentUser();
    }

    // AWS Stores Credentials in many ways, and with TypeScript this means that
    // getting the base credentials we authenticated with from the AWS globals gets really murky,
    // having to get around both class extension and unions. Therefore, we're going to give
    // developers direct access to the raw, unadulterated CognitoIdentityCredentials
    // object at all times.
    setCognitoCreds(creds: AWS.CognitoIdentityCredentials) {
        this.cognitoCreds = creds;
    }

    getCognitoCreds() {
        return this.cognitoCreds;
    }

    // This method takes in a raw jwtToken and uses the global AWS config options to build a
    // CognitoIdentityCredentials object and store it for us. It also returns the object to the caller
    // to avoid unnecessary calls to setCognitoCreds.

    buildCognitoCreds(idTokenJwt: string) {
        let url = 'cognito-idp.' + CognitoTraineeUtil._REGION.toLowerCase() + '.amazonaws.com/' + CognitoTraineeUtil._USER_POOL_ID;
        if (environment.trianee_cognito_idp_endpoint) {
            url = environment.trianee_cognito_idp_endpoint + '/' + CognitoTraineeUtil._USER_POOL_ID;
        }
        let logins: CognitoIdentity.LoginsMap = {};
        logins[url] = idTokenJwt;
        let params = {
            IdentityPoolId: CognitoTraineeUtil._IDENTITY_POOL_ID, /* required */
            Logins: logins
        };
        let serviceConfigs = <awsservice.ServiceConfigurationOptions>{};
        if (environment.trianee_cognito_identity_endpoint) {
            serviceConfigs.endpoint = environment.trianee_cognito_identity_endpoint;
        }
        let creds = new AWS.CognitoIdentityCredentials(params, serviceConfigs);
        this.setCognitoCreds(creds);
        return creds;
    }


    getCognitoIdentity(): string {
        return this.cognitoCreds.identityId;
    }
    // getUser(){
    //     return localStorage.getItem('CognitoIdentityServiceProvider.2io8v4r253hugt5ndbjv9shkfp.LastAuthUser');
    // }

    getAccessToken(callback: CallbackTrainee): void {
        if (callback == null) {
            throw ("CognitoUtil: callback in getAccessToken is null...returning");
        }
        if (this.getCurrentUser() != null) {
            this.getCurrentUser().getSession(function (err, session) {
                if (err) {
                    // console.log("CognitoUtil: Can't set the credentials:" + err);
                    callback.callbackWithParam(null);
                }
                else {
                    if (session.isValid()) {
                        callback.callbackWithParam(session.getAccessToken().getJwtToken());
                    }
                }
            });
        }
        else {
            callback.callbackWithParam(null);
        }
    }

    getIdToken(callback: CallbackTrainee): void {
        if (callback == null) {
            throw ("CognitoUtil: callback in getIdToken is null...returning");
        }
        if (this.getCurrentUser() != null)
            this.getCurrentUser().getSession(function (err, session) {
                if (err) {
                    // console.log("CognitoUtil: Can't set the credentials:" + err);
                    callback.callbackWithParam(null);
                }
                else {
                    if (session.isValid()) {
                        callback.callbackWithParam(session.getIdToken().getJwtToken());
                    } else {
                        // console.log("CognitoUtil: Got the id token, but the session isn't valid");
                    }
                }
            });
        else
            callback.callbackWithParam(null);
    }

    getRefreshToken(callback: CallbackTrainee): void {
        if (callback == null) {
            throw ("CognitoUtil: callback in getRefreshToken is null...returning");
        }
        if (this.getCurrentUser() != null)
            this.getCurrentUser().getSession(function (err, session) {
                if (err) {
                    // console.log("CognitoUtil: Can't set the credentials:" + err);
                    callback.callbackWithParam(null);
                }

                else {
                    if (session.isValid()) {
                        callback.callbackWithParam(session.getRefreshToken());
                    }
                }
            });
        else
            callback.callbackWithParam(null);
    }

    refresh(): void {
        this.getCurrentUser().getSession(function (err, session) {
            if (err) {
                // console.log("CognitoUtil: Can't set the credentials:" + err);
            }

            else {
                if (session.isValid()) {
                    //  console.log("CognitoUtil: refreshed successfully");
                } else {
                    // console.log("CognitoUtil: refreshed but session is still not valid");
                }
            }
        });
    }


}
