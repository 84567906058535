<div id="mydiv" *ngIf="loaderHide">
  <img src="assets/images/landing_img.png" width="10%" class="ajax-loader" />
</div>
<div class="container login-div-style text-center mb-3">
  <img _ngcontent-c1="" class="img-responsive mb-3" src="assets/images/logov1.gif" style="width:150px;">

  <div class="row">
    <div class="col-11 col-sm-12 col-md-6 col-lg-6 login__wrapper m-auto">
      <h1 class="text-center login-heading-style"><span>Sign Up</span></h1>
      <div class="login__form-section">
        <!-- <form class="" (ngsubmit) ="f.form.valid && onRegister()" #f="ngForm"> -->
        <form class="">
          <div class="signUp__fileds">
            <div class="form-group fit-my-4">
              <input type="text" id="inputName" class="login-form-control col-12"
                placeholder="Name*" autocomplete="off" #username="ngModel" [(ngModel)]="registrationUser.name"
                name="username" minlength="1" pattern="^[a-zA-Z ]*$">
              <i class="fas fa-user login-fa-icon-style"></i>
              <!-- <span class="floating-label"> Name</span> -->
              <div *ngIf="username.invalid && (username.dirty || username.touched)" class="alert alert-danger">
                <!-- <div class="error_alert_msg error_alet_msg_show">
                    All fields are required.
                  </div> -->
                <!-- <div *ngIf="username.errors?.required">
                  Name is required
                </div> -->
                <div *ngIf="username.errors?.minlength">
                  Name should have Minimum 1 Character
                </div>
                <div *ngIf="username.errors?.pattern">
                  Name should only contain alphabets
                </div>
              </div>
            </div>
            <div class="form-group fit-my-4">
              <input type="email" id="inputEmail" class="login-form-control col-12"
                placeholder="Email*" autocomplete="off" #useremail="ngModel" [(ngModel)]="registrationUser.email" name="useremail"
                 pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
              <i class="fas fa-envelope login-fa-icon-style"></i>
              <!-- <span class="floating-label"> E-mail ID</span> -->
              <div *ngIf="useremail.invalid && (useremail.dirty || useremail.touched)" class="alert alert-danger">
                <!-- <div class="error_alert_msg error_alet_msg_show">
                    All fields are required.
                  </div> -->
                <!-- <div *ngIf="useremail.errors?.required">
                  Email is required
                </div> -->
                <!-- <div *ngIf="useremail.errors?.minlength && useremail.touched">
                      E-mail ID should have Minimum 1 Character
                  </div> -->
                <div *ngIf="useremail.errors?.pattern">
                  Please include '@' in the Email.
                </div>
              </div>
            </div>

            <div class="form-group fit-my-4">
              <input [type]="show ? 'text' : 'password'" id="inputPassword" class="login-form-control col-12"
                placeholder="Password*" autocomplete="no" #userPassword="ngModel" [(ngModel)]="registrationUser.password"
                name="password" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[a-zA-Z0-9\S]{8,}$">
              <!-- <span class="floating-label"> Password</span> -->
              <i class="fas fa-key login-fa-icon-style"></i>
              <i _ngcontent-c2="" class="fas fa-eye login-fa-icon-style" (click)="showPassword()" *ngIf="showSplash" style="top: 9px; left: 88%;"></i>
              <i _ngcontent-c2="" class="fas fa-eye-slash login-fa-icon-style" (click)="showPassword()" *ngIf="showEye" style="top: 9px; left: 88%;"></i>
              <div *ngIf="userPassword.invalid && (userPassword.dirty || userPassword.touched)" class="alert alert-danger">
                <!-- <div class="error_alert_msg error_alet_msg_show">
                    All fields are required.
                  </div> -->
                <!-- <div *ngIf="userPassword.errors?.required">
                  Password is required
                </div> -->
                <!-- <div *ngIf="useremail.errors?.minlength && useremail.touched">
                      E-mail ID should have Minimum 1 Character
                  </div> -->
                <div *ngIf="userPassword.errors?.pattern">
                  Password must be at least 8 characters with 1 uppercase, 1 number, and 1 special character
                  <!-- - Password require uppercase letters
                      - Password require lowercase letters
                      - Password Require numbers
                      - Password require special character -->
                </div>
              </div>


            </div>
          </div>
          <div class="text-center btn-margin">
            <button class="btn btn-circle nextBtn" id="signupSubmit" (click)="onRegister()" type="submit"><i class="fas fa-angle-right"></i></button>
          </div>
        </form>
      </div>
    </div>
    <!-- Confirmation Code -->
    <!-- <div class="col-sm-12 col-md-6 col-lg-3 login__wrapper m-auto" *ngIf="confirmPageHide">
      <h1 class="heading1 text-center"><span><b>Confirmation</b></span></h1>
      <div class="login__form-section">
        <form class="" #signUpForm="ngForm">
          <div class="signUp__fileds">
            <div class="form-group">
              <input type="text" id="confirmationCode" class="form-control col-12" style="border-width: 1px;" placeholder="Confirm"
               [(ngModel)]="confirmationCode" name="confirmationCode" required>
            </div>
          </div>
          <div class="text-center btn-margin">
            <button class="btn btn-default submitBtn" (click)="onConfirmRegistration()" id="confirmRegistration" type="submit">Confirm</button>
          </div>
        </form>
      </div>
    </div> -->
  </div>
</div>
