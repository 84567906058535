<section>
  <div class="container-fluid">
    <div class="col" style="padding: 0px 50px 30px;">
      <div class="row">
        <h1 class="heading1 col-md-6 col-sm-12"> <img src="assets/images/exerciseimg.png" width="50" />
          <span><b>BOOK APPOINTMENT</b></span></h1>
      </div>
      <div class="row">
        <!-- <form class="form-inline"> -->
        <!-- <div class="form-group col-12 col-md-3">
            <ng-multiselect-dropdown class="form-control col-12" [data]="trainersData" [(ngModel)]="selectedTrainer"
            [placeholder]="'Select trainer'" name="selectedTrainer" [settings]="dropdownSettings_single">
        </ng-multiselect-dropdown>
        </div> -->
        <div class="form-group col-12 col-md-3">

          <input type="date" class="form-control col-12" id="date" name="date" autocomplete="nope" [(ngModel)]="date"
            (change)="onDateChange();" placeholder="Date*">
        </div>
        <!-- <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;" (click)="getData()">Submit</button> -->
        <!-- </form> -->
      </div>
      <div class="videos videos-exercise row">
        <div class="col-sm-6 col-md-3 col-lg-3" *ngFor="let slot of slots">
          <div class="doctorsListwrp" style="height: 250px; padding: 10px 0px 0px 40px;">
            <div _ngcontent-c1="" class="dthumbwrp">
              <img _ngcontent-c1="" alt="" src="{{slot.imgUrl}}">
            </div>
            <div _ngcontent-c1="" class="ddetailswrp">
              <h3 _ngcontent-c1="">{{slot.name}}</h3>
              <!-- <p _ngcontent-c1=""> <b>Time : </b>9:00 to 10:30 AM&amp;  <b>TimeZone: </b> IST&amp;  <b>Program : </b> 12Week Lean </p> -->
              <p>Time: {{slot.startTime}} to {{slot.endTime}}</p>
              <p>TimeZone: IST</p>
            </div>
            <div class="ddetailbtn justify-content-end">
              <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
                *ngIf="slot.status == 'open'" (click)="bookAppointment(slot.slotId, slot.schedulerId)">Book an
                Appointment</button>
              <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
                *ngIf="slot.status == 'booked' && slot.trainee_id == trainee_id"
                (click)="onJoinRoom(slot.slotId, slot.schedulerId)">Join Meeting</button>
              <button type="button" class="btn btn-default" style="margin-top: 10px;" [disabled]="true"
                *ngIf="slot.status == 'booked' && slot.trainee_id != trainee_id">Booked</button>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</section>