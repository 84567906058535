import { Component, OnInit, Input } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { AwsUtil } from "./service/aws.service";
import { UserLoginService } from "./service/user-login.service";
import { CognitoUtil, LoggedInCallback } from "./service/cognito.service";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, LoggedInCallback {
  title = 'Trainers';
  routeRedirect: boolean = false;

  constructor(private router: Router, public awsUtil: AwsUtil, public userService: UserLoginService, public cognito: CognitoUtil) {
    // on route change to '/landing', set the variable routeRedirect to false
    //  console.log("AppComponent: constructor");
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (event['url'].includes('/twilioVideo') || event['url'].includes('/twilioGroup')) {
          this.routeRedirect = false;
        } else {
          // console.log("NU")
          this.routeRedirect = true;
        }
      }
    });
  }
  ngOnInit() {
    // this.loadScript('http://www.some-library.com/library.js');
    // this.loadScript('assets/js/custom.js');
    // console.log("AppComponent: Checking if the user is already authenticated");
    this.userService.isAuthenticated(this);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (document.getElementById('custom_js') != null) {
          document.getElementById('custom_js').remove();
        }
        const node = document.createElement('script');
        node.src = 'assets/js/custom.js';
        node.type = 'text/javascript';
        node.async = false;
        node.id = 'custom_js';
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);
      }
    });
  }


  isLoggedIn(message: string, isLoggedIn: boolean) {
    //  console.log("AppComponent: the user is authenticated: " + isLoggedIn);
    let mythis = this;
    this.cognito.getIdToken({
      callback() {

      },
      callbackWithParam(token: any) {
        // Include the passed-in callback here as well so that it's executed downstream
        //console.log("AppComponent: calling initAwsService in callback")
        mythis.awsUtil.initAwsService(null, isLoggedIn, token);
      }
    });
  }

  public loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }
}
