import { Component, OnInit, OnDestroy, Inject, NgZone, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { TraineeDBService } from '../service/trainee.service';
import ZoomMtgEmbedded, { 
  BreakoutRoomStatus, 
  ExecutedFailure, 
  Participant, 
  SuspensionViewType,
  InitOptions,
  JoinOptions,
  VideoOptions
} from '@zoom/meetingsdk/embedded';
import { DBService } from '../service/db.service';

@Component({
  selector: 'app-zoom-view',
  templateUrl: './zoom-view.component.html',
  styleUrls: ['./zoom-view.component.css']
})
export class ZoomViewComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('selfViewVideo') selfViewVideo!: ElementRef<HTMLVideoElement>;

  client = ZoomMtgEmbedded.createClient();
  meetingDetails: any;
  errorMessage: string = '';
  meetingJoined: boolean = false;
  isJoining: boolean = false;
  slotID: string = "";
  private sub: any;

  constructor(
    public httpClient: HttpClient,
    @Inject(DOCUMENT) document,
    private ngZone: NgZone,
    private traineeService: TraineeDBService,
    private router: Router,
    private db_service: DBService,
    public route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.meetingDetails = history.state.data;
    this.sub = this.route.params.subscribe(params => {
      this.slotID = params['userID'];
    });
  }

  ngAfterViewInit() {
    this.initializeZoomClient();
  }

  ngOnDestroy() {
    this.leaveMeeting();
  }

  initializeZoomClient() {
    let meetingSDKElement = document.getElementById('meetingSDKElement');

    if (!meetingSDKElement) {
      console.error('Failed to find meetingSDKElement');
      this.errorMessage = 'Failed to initialize the meeting client. Please try again.';
      return;
    }

    const videoOptions: VideoOptions = {
      isResizable: false,
      viewSizes: {
        default: {
          width: 1200,
          height: 700
        }
      },
      popper: {
        disableDraggable: true
      },
      defaultViewType: SuspensionViewType.Gallery
    };

    const initOptions: InitOptions = {
      zoomAppRoot: meetingSDKElement,
      language: 'en-US',
      customize: {
        video: videoOptions
      }
    };

    this.ngZone.runOutsideAngular(() => {
      this.client.init(initOptions).then(() => {
        console.log('Zoom client initialized');
        this.joinMeeting();
      }).catch((error: ExecutedFailure) => {
        console.error('Failed to initialize Zoom client:', error);
        this.handleError(error);
      });
    });
  }

  joinMeeting() {
    if (this.isJoining || this.meetingJoined) {
      console.warn('Already joining or in a meeting');
      return;
    }

    this.isJoining = true;

    const joinOptions: JoinOptions = {
      signature: this.meetingDetails.signature,
      sdkKey: this.meetingDetails.sdkKey,
      meetingNumber: this.meetingDetails.meetingNumber,
      password: this.meetingDetails.password,
      userName: 'Trainer'
    };

    this.client.join(joinOptions).then(() => {
      console.log('Joined meeting successfully');
      this.meetingJoined = true;
      this.isJoining = false;
      this.setupEventListeners();
    }).catch((error: ExecutedFailure) => {
      console.error('Failed to join meeting:', error);
      this.errorMessage = 'Failed to join the meeting. Please check your connection and try again.';
      this.isJoining = false;
      this.handleError(error);
    });
  }

  handleError(error: ExecutedFailure) {
    switch(error.type) {
      case 'INVALID_PARAMETERS':
        if (error.reason === 'Signature is invalid.' || error.reason === 'The signature has expired.') {
          this.errorMessage = 'Failed to join the meeting. The signature is invalid or has expired. Please try rejoining with a new signature.';
          this.refreshSignature();
        } else {
          this.errorMessage = `Failed to join the meeting. Error: ${error.reason}`;
        }
        break;
      // ... other cases remain the same
    }
  }

  refreshSignature() {
    var index = "/scheduler/slots/"; 
    this.db_service.getDataFromServer(this.slotID, index).subscribe(
      response => {
        console.log('Data Retrieved successfully.', response);
        var slot = response.data;
        this.meetingDetails.signature = slot.signature;
        this.joinMeeting();
      },
      error => {
        console.error('Failed to refresh signature:', error);
        this.errorMessage = 'Failed to refresh the meeting signature. Please try again later.';
      }
    );
  }

  setupEventListeners() {
    this.client.on('connection-change', (payload: any) => {
      console.log('Connection state changed:', payload);
      if (payload.state === 'Closed') {
        this.ngZone.run(() => {
          this.meetingJoined = false;
          this.isJoining = false;
          this.navigateToDashboard();
        });
      }
      if (payload.state === 'Connected') {
        this.switchToGalleryView();
      }
    });

    this.client.on('room-state-change', (payload: { status: BreakoutRoomStatus }) => {
      console.log('Room state changed:', payload);
      if (payload.status === BreakoutRoomStatus.Closed) {
        this.ngZone.run(() => {
          this.meetingJoined = false;
          this.isJoining = false;
          this.navigateToDashboard();
        });
      }
    });

    // Add other event listeners as needed
  }

  toggleAudio() {
    if (!this.meetingJoined) return;
    const currentUser = this.client.getCurrentUser();
    if (currentUser) {
      this.client.mute(!currentUser.muted, currentUser.userId);
    }
  }

  leaveMeeting() {
    if (this.meetingJoined) {
      this.client.leaveMeeting().then(() => {
        console.log('Left the meeting');
        this.meetingJoined = false;
        this.isJoining = false;
        this.navigateToDashboard();
      }).catch((error: ExecutedFailure) => {
        console.error('Error leaving meeting:', error);
        this.handleError(error);
      });
    } else {
      this.navigateToDashboard();
    }
  }

  switchToGalleryView() {
    this.ngZone.runOutsideAngular(() => {
      new Promise<void>((resolve, reject) => {
        const ribbon = document.querySelector('button[title="Ribbon"]') as HTMLButtonElement;
        if (ribbon) {
          ribbon.click();
          console.log('-- switching to gallery view --');
          resolve();
        } else {
          reject(new Error('Ribbon button not found'));
        }
      }).then(() => {
        const xpath = "//span[text()='Gallery']";
        const galleryView = document.evaluate(xpath, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue as HTMLElement;
        if (galleryView) {
          galleryView.click();
        } else {
          throw new Error('Gallery view option not found');
        }
      }).catch((error) => {
        console.log('--- error when switching to gallery view --> ', error);
      });
    });
  }

  navigateToDashboard() {
    this.ngZone.run(() => {
      this.router.navigate(['/dashboard']);
    });
  }
}