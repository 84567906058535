<section class="desktop-version">
  <div class="container-fluid">
    <div class="col" id="workOut">
      <!-- <div class="row">
        <h1 class="heading1 col-md-6 col-sm-12">
          <img src="{{ workoutImg }}" width="50" />
          <span *ngIf="isWorkoutName == false">{{ workoutName }}</span>
          <span *ngIf="isWorkoutName == true">{{
            workout.customWorkoutName
          }}</span>
        </h1>
      </div> -->
      <!-- Exercise Video -->
      <div class="row" *ngIf="addWorkoutFunc">
        <div class="col-md-7" dndDropzone (dndDrop)="onItemDrop($event)">
          <h2 class="sub_heading text-right" style="margin-bottom: 20px;">
            Rest Interval
            <a style="cursor:pointer" (click)="addRest()" class="add__exercise__btn"><i class="fa fa-plus"></i></a>
          </h2>

          <div class="add__rest add__workout__video">
            <div class="add__rest_row add__rest_header">
              <div class="rep_move"></div>
              <div><b>Exercise Name</b></div>
              <div><b>Sets</b></div>
              <!-- <div><b>Target</b></div>-->
              <div><b>Rest Period (seconds)</b></div>
              <div></div>
            </div>

            <ul id="sortable" style="cursor: pointer;" [sortablejs]="droppedItems"  [sortablejsOptions]="options">
              <div class="text-center col" *ngIf="droppedItems == undefined || droppedItems.length == 0">
                <div _ngcontent-c2="" class="sub_heading text-right ng-star-inserted py-5 my-5 col text-center"
                  style="margin-bottom: 20px;"> Drag exercises to add here</div>
              </div>
              <li *ngFor="let item of droppedItems">
                <div class="add__rest_row">
                  <div class="rep_move">
                    <i class="fas fa-grip-vertical" style="cursor: grabbing;"></i>
                  </div>
                  <div class="rep__video text-content">
                    <i class="fas fa-play" *ngIf="item.exerciseId != 'rest_id'"
                      (click)="openVideo(item.exerciseId)" data-toggle="modal" data-target="#contentModal"></i>
                    <i class="fas fa-stopwatch" *ngIf="item.exerciseId == 'rest_id'"></i>
                    <span>&nbsp; {{ item.exerciseName }}</span>
                  </div>
                  <div>
                    <input type="text" readonly [(ngModel)]="item.sets.length" *ngIf="item.exerciseId != 'rest_id'"
                      name="sets" />
                  </div>
                  <!-- <div><input type="text" [(ngModel)]="item.repsVal" *ngIf="item.exerciseId !='rest_id'" name="repsVal"></div> -->
                  <div><input type="number" OnlyPositiveNumbers numericType="number" *ngIf="item.exerciseId =='rest_id'"
                      value="40 Sec" [(ngModel)]="item.restPeriodVal" name="restPeriodVal"></div>
                  <div>
                    <a class="delete__rest" (click)="RemoveExercise(item.referenceId)"><i
                        class="fas fa-trash-alt"></i></a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="d-flex col-12 justify-content-end">
            <button type="button" class="btn btn-default" style="margin-top: 10px;" (click)="back('page2')">
              Back
            </button>
            <button type="button" class="btn btn-default" style="margin-top: 10px;" (click)="SaveWorkout()">
              Save
            </button>
          </div>
        </div>
        <div class="col-md-5">
          <h1 class="sub_heading" style="margin-bottom: 30px; text-align: right;">
            <i style="cursor: pointer;" (click)="addCustomExercise()" class="fa fa-plus addCustomExerciseIcon"></i>
            Add Custom Exercise
          </h1>

          <div class="videos videos-exercise add__workout__video row" *ngIf="exercises.length != 0">
            <div class="col-sm-6 col-md-4" style="
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              " *ngFor="let ex of exercises.slice().reverse()">
              <a dndDraggable [dndDraggable]="ex.exerciseId" style="cursor: pointer;">
                <video class="videoInsert"  onmouseover="this.play()" onmouseout="this.load();" loop muted playsinline style="width: 100%;" poster="{{ ex.exerciseVideo.videoThumbnailPath }}">
                  <source src="ex.exerciseVideo.exerciseVideoSource" type="video/mp4" /></video></a>
              <h1 class="text-content">{{ ex.exerciseName }}</h1>
            </div>

          </div>
          <div *ngIf="exercises.length == 0">
            <h2> There are no exercises associated with workout</h2>
          </div>
        </div>
      </div>

      <!-- Exercise Video /-->
    </div>
    <div class="container" *ngIf="addWorkout">
      <h1 class="heading1">
        <i class="fa fa-plus plus-circle"></i> <span>Add Workout</span>
      </h1>
      <div class="row">
        <div class="col-md-6">
          <div class="add__workout">
            <form class="">
              <!-- <div class="row"> -->
              <div class="form-group col-sm-12">
                
                <ng-multiselect-dropdown class="form-control col-12"
                [placeholder]="'Workout Name'" name="SelectedWorkoutNames"
                [settings]="dropdownSettings_single"
                [data]="workoutNames"
                [(ngModel)]="SelectedWorkoutNames" [disabled]="disabled"
                (onSelect)="onItemSelect($event, 'workoutName')" (onDeSelect)="onItemDeselect($event, 'workoutName')"
              >
              </ng-multiselect-dropdown>
              </div>

              <div class="form-group col-sm-12" *ngIf="isWorkoutName">
                <input type="text" class="form-control" autocomplete="off" #customWorkoutName="ngModel"
                  [(ngModel)]="workout.customWorkoutName" name="customWorkoutName" required />
                <span class="floating-label">Custom Name*</span>
              </div>

              <!-- <div class="form-group col-12 col-md-6">
              <ng-multiselect-dropdown class="form-control col-12" [data]="gender"
              [placeholder]="'Gender'" [(ngModel)]="selectedGender" name="SelectedWorkoutNames" [settings]="dropdownSettings_single"
              [disabled]="disabled">
            </ng-multiselect-dropdown> -->
              <!-- </div> -->
              <!-- </div> -->
              <!-- <div class="row">
            <div class="form-group col-12 col-md-6">
              <input type="text" class="form-control" [(ngModel)]="workout.age" name="age"
                required>
              <span class="floating-label">Age*</span>
            </div>
            <div class="form-group col-12 col-md-6">
              <input type="text" class="form-control" [(ngModel)]="workout.weight" name="weight"
                required>
              <span class="floating-label">Weight*</span>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-sm-12">
              <input type="text" class="form-control" [(ngModel)]="workout.bmiIdeal" name="bmiIdeal"
                required>
              <span class="floating-label">Ideal BMI*</span>
            </div>
          </div> -->
              <!-- <div class="row"> -->
              <div class="form-group col-sm-12">
                <textarea rows="5" class="add-instruction-text" id="instructions" name="instructions"
                  placeholder="Description" [(ngModel)]="workout.warnings"
                  style="background: rgba(255, 255, 255, 0.2); color: #fff;"></textarea>
              </div>
              <!-- </div> -->
              <!-- <div class="form-group col-sm-12 d-flex">
                <ui-switch defaultBgColor="#8a8585" [(ngModel)]="workout.isPublic" name="isPublic"></ui-switch>
                <div class="ml-2 p-1 add-instruction-toggle-text" >Add this to Public Workouts</div>
              </div> -->
            </form>

            <div class="d-flex col-12 justify-content-between">
              <button type="button" class="btn btn-circle prevBtn" style="margin-top: 10px;" (click)="back('page1')">
                <i class="fas fa-angle-left"></i>
              </button>
              <button type="button" class="btn btn-circle nextBtn" style="margin-top: 10px;"
                (click)="getExerciseDataFromServer()">
                <i class="fas fa-angle-right"></i>
              </button>
            </div>
            <!-- <div class="trainer-profile-buttons d-flex justify-content-between">
                  <button type="button" class="btn btn-circle prevBtn" (click)="downProgressBar()">
                    <i class="fas fa-angle-left"></i>
                  </button>
                  <button type="button" class="btn btn-circle nextBtn" id="nextBtn" (click)="upProgressBar()">
                    <i class="fas fa-angle-right"></i>
                  </button>
                </div> -->
          </div>
        </div>

        <div class="col-md-6">
          <div class="add__workout_icon-box m-auto" *ngIf="workoutImg != undefined && workoutImg != ''">
            <img src="{{ workoutImg }}" width="200" />
          </div>
        </div>
      </div>
    </div>
    <!-- showVideoPopup -->
    <ng-container *ngIf="showVideoPopup">
      <div class="modal fade " id="contentModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style="background: #000000ba;">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="video-pop zoomIn modal-body background-shadow-green" style="border-radius: 12px;">
              <a (click)="closePopup()" class="close-video-pop float-right" style="cursor: pointer;">
            <i class="fas fa-times"></i></a>
          <video autoplay="" loop="" onloadedmetadata="this.muted=true" muted="" playsinline="" controls style="width: 100%;" *ngIf="videoHidden">
            <source attr.src="{{viewURL}}" type="video/mp4" />
          </video>
          <div class="exercise-upload-video" [innerHtml]="embedYoutubeUrl" *ngIf="iframeHidden"></div>
        </div>
      </div>
    </div>
  </div>
    </ng-container>
    <!-- isUpdateWorkout -->
    <ng-container *ngIf="isUpdateWorkout">
      <div class="program_event-popup__wrapper">
        <div class="program_event-pop zoomIn background-shadow-green" style="width:38% !important;">
          <div class="program_event-header">
            <h4>Do you want to apply these changes in dependent programs
            </h4>
          </div>

          <div class="program_event-body d-flex justify-content-center">

            <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
              (click)="onUpdateWorkout('No')">No</button>

            <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
              (click)="onUpdateWorkout('Yes')">Yes</button>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- showExerciseRejectPopUp -->
    <ng-container *ngIf="showExerciseRejectPopUp">
      <div class="program_event-popup__wrapper">
        <div class="program_event-pop zoomIn background-shadow-green" style="width:40% !important;">
          <div class="program_event-header">
            <h4>Please update workout exercises which are rejected
            </h4>
          </div>

          <div class="program_event-body d-flex justify-content-center">

            <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
              (click)="closePopup()">Ok</button>
          </div>
        </div>
      </div>
    </ng-container>

    <!--//////*******   ADD WORKOUT START  ******//////-->
  </div>
</section>

<section class="mobile-version">
  <div class="container">
    <div class="text-center" style="font-size: 18px; font-weight: 600;"> This page works in web browser only </div>
  </div>

</section>
