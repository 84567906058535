<div id="mydiv" *ngIf="loaderHide && this.currentCategory == 'Bodybuilder'">
  <img src="assets/images/loaders/loaderexe.gif" width="10%" class="ajax-loader" />
</div>
<div id="mydiv" *ngIf="loaderHide && this.currentCategory == 'Yoga'">
  <img src="assets/images/loaders/loaderyoga.gif" width="10%" class="ajax-loader" />
</div>
<div id="mydiv" *ngIf="loaderHide && this.currentCategory == 'Zumba'">
  <img src="assets/images/loaders/loaderjumba.gif" width="10%" class="ajax-loader" />
</div>
<section>
  <div class="col-md-9 mx-auto">
    <div class="row">
      <div class="col-md-8">
        <h1 class="heading1"> <i class="fa fa-plus plus-circle"></i> <span style="margin-left: 10px;">Add Program</span>
        </h1>
        <div class="exercise-content">
          <form class="form-inline trainer-profile-form">
            <div class="form-group col-12">
              <input type="text" class="form-control col-12" id="exerciseName" name="exerciseName" autocomplete="off"
                [(ngModel)]="program.programName" #programName="ngModel" minlength="1" placeholder="Program Name*"
                [disabled]="isDisabled">
              <!-- <span class="floating-label">Exercise Name</span> -->
              <div *ngIf="programName.invalid && (programName.dirty || programName.touched)" class="alert alert-danger">
                <!-- <div class="error_alert_msg error_alet_msg_show">
                      All fields are required.
                    </div> -->
                <!-- <div *ngIf="exerciseName.errors?.required">
                      Exercise Name can't be blank
                    </div> -->
                <div *ngIf="programName.errors?.minlength">
                  Program Name should have Minimum 2 Character
                </div>
              </div>
            </div>
            <div class="form-group col-12">

              <div class="add_program_duration col-md-12">

                <div class="row col-4">
                  <div>
                    Plan
                  </div>
                  <input class="col-12 col-md-6 mx-auto" type="number" OnlyPositiveNumbers numericType="number" [(ngModel)]="program.plans"
                    (ngModelChange)="calculate()" name="plans" [disabled]="isDurationDisabled">
                </div>

                 <div>
                  X
                </div>


                <div class="row col-4">
                  <div>
                    Weeks
                  </div>
                  <input class="col-12 col-md-6 mx-auto" type="number" OnlyPositiveNumbers numericType="number" [(ngModel)]="program.weeks"
                    (ngModelChange)="calculate()" name="weeks" [disabled]="isDurationDisabled">
                </div>

                <div>
                  =
                </div>

                <div class="row col-4">
                  <div>
                    Duration*
                  </div>
                  <input class="col-12 col-md-6 mx-auto" type="number" OnlyPositiveNumbers numericType="number" [(ngModel)]="program.programDuration"
                    name="programDuration" readonly [disabled]="isDurationDisabled" style="background-color: yellowgreen; cursor: not-allowed;" >
                </div>
              </div>
            </div>
            <div class="form-group col-12">
              <!-- <input type="text" class="form-control col-12" id="description" name="description"
                [(ngModel)]="program.description" #programDes="ngModel" maxlength="800" pattern="^[a-zA-Z ]+$"
                placeholder="Program Description*"> -->
              <textarea rows="3" class="form-control col-12 textarea-check" autocomplete="off"
                placeholder="Program Description*" id="description" #programDes="ngModel"
                [(ngModel)]="program.description" maxlength="800" pattern="^[a-zA-Z ]+$" name="description"
                [disabled]="isDisabled" style="height: 71px !important">Program Description*</textarea>
            </div>
            <!-- <div class="form-group col-12">
              <textarea rows="3" class="form-control col-12 textarea-check" autocomplete="off" placeholder="Achievements" id="achievements"
              #achievementsU="ngModel" [(ngModel)]="profile.achievements"  maxlength="800" name="achievements">Achievemnets</textarea>
              <div *ngIf="achievementsU.invalid && (achievementsU.dirty || achievementsU.touched)" class="alert alert-danger-textarea">

                <div *ngIf="achievementsU.errors?.maxlength">
                  Achievements should have maximum 800 character
                </div>
                </div>
              </div> -->
              <div class="form-group col-10 col-md-5" *ngIf = "isIndian">
                <input type="number" class="form-control col-12" id="priceInRpees" name="priceInRupees" [(ngModel)]="program.priceInRupees"
                OnlyPositiveNumbers numericType="decimal" maxlength="5" placeholder="Price in ₹*" [disabled]="isDisabled">
                <span style=" position: absolute; right: 0px;" *ngIf="program.priceInRupees != undefined && program.priceInRupees != 0">₹</span>
                </div>
                <div class="form-group col-1 col-md-1" *ngIf = "isIndian" >
                  <i class="fa fa-info-circle" *ngIf="program.priceInRupees == undefined || program.priceInRupees == 0" title="This price is for indian users" aria-hidden="true"></i>
                  <i class="fa fa-info-circle" *ngIf="program.priceInRupees != undefined && program.priceInRupees != 0" title="This price is for indian users" aria-hidden="true" style="color: yellowgreen; cursor: pointer;" (click)="calculate1o1Percentage('Rupees')" ></i>
                </div>

                <div class="form-group col-10 col-md-5">
                <input type="number" class="form-control col-12" id="priceInDollars" name="priceInDollars" [(ngModel)]="program.priceInDollars"
                OnlyPositiveNumbers numericType="decimal" placeholder="Price in $*" [disabled]="isDisabled">
                <span style=" position: absolute; right: 0px;" *ngIf="program.priceInDollars != undefined && program.priceInDollars != 0">$</span>
                </div>
                <div class="form-group col-1 col-md-1">
                  <i class="fa fa-info-circle" *ngIf="program.priceInDollars == undefined || program.priceInDollars == 0" title="This price is for United state users" aria-hidden="true"></i>
                  <i class="fa fa-info-circle" *ngIf="program.priceInDollars != undefined && program.priceInDollars != 0" title="This price is for United state users" aria-hidden="true" style="color: yellowgreen; cursor: pointer;" (click)="calculate1o1Percentage('Dollers')"></i>
                </div>
            <div class="form-group col-12 col-md-6" *ngIf = "isCurrencyDisabled">
              <!-- <input type="number" class="form-control col-12" id="currency" name="currency"
              [(ngModel)]="program.currency" OnlyPositiveNumbers numericType="decimal"
              placeholder="currency*"> -->
             
              <ng-multiselect-dropdown class="form-control col-12"
              [placeholder]="'Currency'"  name="Currrency"
              [settings]="dropdownSettings_single"
              [data]="currency"
              [(ngModel)]="SelectedCurrency"
              [disabled]="isDisabled"
            >
            </ng-multiselect-dropdown>
            </div>

            <div class="form-group col-12 col-md-6">
              <!-- <input type="number" class="form-control col-12" id="currency" name="currency"
              [(ngModel)]="program.currency" OnlyPositiveNumbers numericType="decimal"
              placeholder="currency*"> -->
         
              <ng-multiselect-dropdown  class="form-control col-12"
              [placeholder]="'Program type*'" name="programType"
              [settings]="dropdownSettings_single"
              [data]="programTypes"
              [(ngModel)]="SelectedProgramType"
              [disabled]="isDisabled"  (onSelect)="onItemSelect()" (onDeSelect)="onItemDeselect()"
            >
            </ng-multiselect-dropdown>
            </div>

            <div class="form-group col-12 col-md-6" *ngIf="isNutritionShow">
            
              <ng-multiselect-dropdown class="form-control col-12"
              [placeholder]="'Nutrition*'" name="Nutrition"
              [settings]="dropdownSettings_single"
              [data]="Nutritions"
              [(ngModel)]="SelectedNutrition"
              [disabled]="isDisabled"
            >
            </ng-multiselect-dropdown>
            </div>
            <div class="form-group col-12 col-md-6">
            
              <ng-multiselect-dropdown class="form-control col-12"
              [placeholder]="'Package type*'" name="packageType"
              [settings]="dropdownSettings_single"
              [data]="packagetypes"
              [(ngModel)]="selectedPackage"
              [disabled]="isDisabled"
            >
            </ng-multiselect-dropdown>
            </div>
            <!-- <div class="form-group col-12 col-md-6">
              <input type="number" class="form-control col-12" id="price" name="maxBmi" [(ngModel)]="program.maxBmi"
                OnlyPositiveNumbers numericType="decimal" placeholder="max bmi*" [disabled]="isDisabled" >
            </div> -->
            <div class="form-group d-flex col-12 col-md-6">
              <div class="col-5 p-0 mt-2">Program start date*</div>
              <div class="col-7">
                <!-- <input type="date" class="form-control col-12 launch-date-style"
                  [disabled]="isDisabled" name="startDate" [(ngModel)]="startDate" placeholder="date" (change)="onDateChange()"> -->
                  <my-date-picker name="startDate"
                    [(ngModel)]="startDate" [options]="myDatePickerOptions" [disabled]="isDisabled" (dateChanged)="onDateChanged($event)"  placeholder="Date"></my-date-picker>
                </div>
            </div>
            <div class="form-group d-flex col-12 col-md-6">
              <div class="col-5 p-0 mt-2">Enrollment start date*</div>
              <div class="col-7">
                <!-- <input type="date" [disabled]="isDisabled"
                  class="form-control col-12 launch-date-style" name="enrollmentDate" [(ngModel)]="enrollmentDate"
                  placeholder="date"> -->
                <my-date-picker [options]="myDatePickerOptions" name="enrollmentDate" [(ngModel)]="enrollmentDate" [disabled]="isDisabled" placeholder="Date"></my-date-picker>
                </div>
            </div>

            <div class="form-group d-flex col-12 col-md-6" *ngIf="isPremiumProgram">
              <div class="col-5 p-0 mt-2">Program end date</div>
              <div class="col-7">
                <!-- <input type="date" class="form-control col-12 launch-date-style"
                  [disabled]="true" name="endDate" [(ngModel)]="endDate" placeholder="date"> -->
                  <my-date-picker [options]="myDatePickerOptions" name="endDate" [(ngModel)]="endDate" [disabled]="true" placeholder="Date"></my-date-picker>
                </div>
            </div>
            <div class="form-group d-flex col-12 col-md-6" *ngIf="isPremiumProgram">
              <div class="col-5 p-0 mt-2">Enrollment end date</div>
              <div class="col-7">

                <!-- <input type="date" [disabled]="isDisabled"
                  class="form-control col-12 launch-date-style" name="enrollmentEndDate" [(ngModel)]="enrollmentEndDate"
                  placeholder="date"> -->

                  <my-date-picker [options]="myDatePickerOptions" name="enrollmentEndDate" [(ngModel)]="enrollmentEndDate" [disabled]="isDisabled" placeholder="Date"></my-date-picker>

                </div>
            </div>

            <div class="d-flex col-12 justify-content-end">
              <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;" *ngIf="editTrue"
                (click)="saveProgram()">Save</button>
              <!-- <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;" *ngIf = "exercise.exerciseId != undefined" (click)="EditExercise()">Edit</button> -->
              <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
                (click)="Cancel()">Cancel</button>
            </div>
          </form>

        </div>
      </div>
      <div class="col-md-4 text-center profile-section">
        <h2 class="heading2">{{program.programName}}</h2>
        <div class="profile-pic">
          <div>
            <div for="imgFileUpload" style="margin-top: 200px;">
              <!-- class="profile-img" -->
              <img id="img" attr.src="{{ imgUrl }}" width="100%"
                [ngClass]="{'profile-img': isImgUploaded == 'NO', 'img-fluid': isImgUploaded == 'YES' }"
                style="margin-bottom: 6px; margin-top: 6px;">
            </div>

            <input type="file" id="imgFileUpload" (change)="selectFile($event,'image')" [disabled]="isDisabled"
              #imgUpload>
            <a class="imgFileUpload"><i class="fas fa-pen" style="cursor:pointer" (click)="imgUpload.click()"></i></a>
          </div>
        </div>
      </div>
    </div>
    <!-- showCalendarPopup -->
    <ng-container *ngIf="showCalendarPopup">
      <div class="program_event-popup__wrapper">
        <div class="program_event-pop zoomIn background-shadow-green">
          <div class="program_event-header text-center">
            <h4>Create calendar</h4>
          </div>
          <a (click)="closePopup()" class="close-program_event-pop"><i class="fas fa-times"></i></a>
          <div class="program_event-body d-flex justify-content-center">
            <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
              (click)="onCalendarYes()">Yes</button>
            <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
              (click)="onCalendarNo()">No</button>
          </div>
        </div>
      </div>
    </ng-container>

</div>
<!-- showTaxPopup -->
<ng-container *ngIf="taxpopup">
  <div class="program_event-popup__wrapper p-3">
    <div class="program_event-pop zoomIn background-shadow-green p-2" style="width: 30%;
">
      <div class="program_event-header text-center">
        <h4>Revenue details</h4>
      </div>
      <a (click)="closePopup()" class="close-program_event-pop"><i class="fas fa-times"></i></a>
      <div class="program_event-body d-flex justify-content-center" style="padding: 8px 8px;">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12 my-2">
              <div class="row">
                <div class="col-md-6 col-6 fit-color-yg" *ngIf="display_currency != '$'">
                 Program price:
              </div>
              <div class="col-md-6 col-6 fit-color-yg" *ngIf="display_currency == '$'">
                Program price <span style="font-size:10px;">(inclusive of tax)</span>
             </div>
              <div class="col-md-6 col-6 text-right">
                {{display_currency}} {{programPrice}}
             </div>
            </div>
            </div>

            <div class="col-md-12 my-2" *ngIf="display_currency != '$'">
              <div class="row">
                <div class="col-md-6 col-6 fit-color-yg">
                 GST:
              </div>
              <div class="col-md-6 col-6 text-right">
                {{display_currency}} {{gst_tax}}
             </div>
            </div>
            </div>

            <div class="col-md-12 my-2">
              <div class="row">
                <div class="col-md-6 col-6 fit-color-yg">
                 1o1 service fee:
              </div>
              <div class="col-md-6 col-6 text-right">
                (-) {{display_currency}} {{servicetax_1o1}}
             </div>
            </div>
            </div>

            <div class="col-md-12 py-2" style="border-top:1px solid white;">
              <div class="row">
                <div class="col-md-6 col-6 fit-color-yg">
                  Trainee total price:
                </div>
                <div class="col-md-6 col-6 text-right">
                  {{display_currency}} {{ grand_tax_total }}
                </div>
                <div class="col-md-6 col-6 fit-color-yg">
                  Per trainee revenue:
                </div>
                <div class="col-md-6 col-6 text-right">
                  {{display_currency}} {{grand_total}}
                </div>
              </div>
            </div>

            <div class="col-md-12 py-2">
              <div class="row">
                <div class="col">
                <i class="fa fa-info-circle" aria-hidden="true"> </i>
                <span> Trainer will get net revenue after 1o1 service fee deductions.</span>
                </div>
              </div>
            </div>

            <!-- <div class="btn mx-auto my-2" (click)="closePopup()">OK</div> -->
          </div>
        </div>


      </div>
    </div>
  </div>
</ng-container>
</section>
