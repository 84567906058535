import { Component, OnInit, OnDestroy, Renderer2, Input } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { UserLoginService } from "../service/user-login.service";
import { TraineeLoginService } from "../service/trainee-login.service";
import { Callback, CognitoUtil, LoggedInCallback } from "../service/cognito.service";
import { UserParametersService } from "../service/user-parameters.service";
import { HttpClient } from '@angular/common/http';
import { DBService } from '../service/db.service';
import { YogaDBService } from '../service/YogaDBService';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {

  currentUser: any;
  currentEmail: string;
  currentCategory: string;
  currentGender: string;
  userName: string = "";
  userID: string = "";
  location: string = "Hyderabad";
  @Input() profilepicUrl: string = "assets/images/uploadimg.png";
  //isLoggedIn$: Observable<boolean>;

  // Google Va
  googleUserEmail: string;
  private loggedIn: boolean;
  currentLogged: string;
  currentUrl: any;

  // cognito auth tokens
  authData: any;
  auth: any;
  session: any;

  //Other attributes
  Subscriptions: any[];
  Totalrevenue : any[];
  programs : any[];
  single : any[];
  multi : any[];
  callusage : any[];
  view: any[];
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = true;
  showSubscriptionsLegend :boolean = true;
  showProgramsLegend:boolean =true;
  showRevenueLegend : boolean = true;
  showCalendarLegend : boolean = true;
  showCallLegend : boolean = true;
  showXAxisLabel: boolean = false;
  showYAxisLabel: boolean = false;
  legendTitle: string = 'Details';
  showLabels: boolean = true;
  isDoughnut: boolean = false;
  legendPosition: string = 'below';
  subscriptionsbarPadding:number = 1000;
  totalrevenuePadding:number = 1000;
  calendarPadding : number = 1000;
  tooltipDisabled :boolean = true;
  callPadding :number = 15;
  showGridLines:boolean = true;

  // options for subscriptions
  subscriptionxAxisLabel: string = 'Programs';
  subscriptionyAxisLabel: string = 'Subscriptions';
  // subscriptioncolorScheme = {
  //   domain: ['#8BC53F', '#FFB300']
  // };
  subscriptioncolorScheme = {
    domain: ['#7da330', '#FFB300']
  };
 // options for Total INcome
 totalrevenuexAxisLabel: string = 'Programs';
 totalrevenueyAxisLabel: string = 'Income';
 totalrevenuecolorScheme = {
   domain: ['#7da330', '#FFB300','Blue', '#FF6600']
 };
 //options for Programs status
 colorScheme = {
  domain: ['#7da330', '#FF6600', '#C7B42C']
};
calendarcolorScheme= {
  domain: ['#7da330', '#FFB300','Blue']
};
  constructor(private renderer: Renderer2,private db_service: DBService, public router: Router, public userService: UserLoginService,
    public userParams: UserParametersService,public cognitoUtil: CognitoUtil, private httpClient: HttpClient, private yoga_db_service: YogaDBService,
    public traineeService: TraineeLoginService) {
      this.view = [0, 500];
    this.userService.isAuthenticated(this)

  }

  onResize(event) {
    this.view = [event.target.innerWidth / 1, 400];
}
  ngOnInit() {

    this.currentCategory = localStorage.getItem("ProfileCategory")
    this.currentGender = localStorage.getItem("ProfileGender");
    if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-build-male');
    }
    else if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-build-female');
    }
    else if (this.currentCategory == 'Yoga' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-yoga-male');
    }
    else if (this.currentCategory == 'Yoga' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-yoga-female');
    }
    else if (this.currentCategory == 'Zumba' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-zumba-male');
    }
    else if (this.currentCategory == 'Zumba' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-zumba-female');
    }

    this.currentLogged = localStorage.getItem("isloggedIn")
    this.userService.currentUserID.subscribe((val) => {
      if (val != '') {
        this.userID = val;
        this.getProfile();
      }
    });


  }
  generatereports(userID){
    var queryParams = {
      "reportsFor":"subscriptions"
    }
    var queryParams2 = {
      "reportsFor":"programs"
    }
    var queryParams3 = {
      "reportsFor":"Totalrevenue"
    }
    var queryParams4 = {
      "reportsFor":"Calendars"
    }
    var queryParams5 = {
      "reportsFor":"Calls"
    }
if(this.currentCategory == "Bodybuilder"){
    this.db_service.generateReports(userID,queryParams).subscribe(response => {
      if(response['data'].length != 0){
      this.Subscriptions = response['data'];
      if(this.Subscriptions.length == 2)
      this.subscriptionsbarPadding = 150
      if(this.Subscriptions.length == 3)
      this.subscriptionsbarPadding = 50
      if(this.Subscriptions.length == 4)
      this.subscriptionsbarPadding = 50
      if(this.Subscriptions.length == 5)
      this.subscriptionsbarPadding = 50
      if(this.Subscriptions.length >= 6)
      this.subscriptionsbarPadding = 30
      }
      else
      this.Subscriptions = [{
        name: "",
      series: [
        {
          name: "Active subscriptions",
          value: 0
        },
        {
          name: "Inactive subscriptions",
          value: 0
        }]
      }]
       })
    this.db_service.generateReports(userID,queryParams2).subscribe(response => {
      // this.single = response['data'];
      if(response['data'].length != 0)
      this.single = response['data'];
    })
    this.db_service.generateReports(userID,queryParams3).subscribe(response => {
      this.Totalrevenue = response['data'];
      if(response['data'].length != 0){
      this.Totalrevenue = response['data'];
      if(this.Totalrevenue.length == 2)
      this.totalrevenuePadding = 150
      if(this.Totalrevenue.length == 3)
      this.totalrevenuePadding = 50
      if(this.Totalrevenue.length == 4)
      this.totalrevenuePadding = 50
      if(this.Totalrevenue.length == 5)
      this.totalrevenuePadding = 35
      if(this.Totalrevenue.length >= 6)
      this.totalrevenuePadding = 30
      }
      else
      this.Totalrevenue = [{
        name: "",
      series: [
        {
          name: "Price in rupees",
          value: 0
        },
        {
          name: "Price in dollars",
          value: 0
        },  {
          name: "Refund in rupees",
          value: 0
        },  {
          name: "Refund in dollars",
          value: 0
        }]
      }]
    })
    this.db_service.generateReports(userID,queryParams4).subscribe(response => {
      // this.multi = response['data'];
      if(response['data'].length != 0){
      this.multi = response['data'];
      if(this.multi.length == 2)
      this.calendarPadding = 150;
      if(this.multi.length == 3)
      this.calendarPadding = 50
      if(this.multi.length == 4)
      this.calendarPadding = 50
      if(this.multi.length == 5)
      this.calendarPadding = 40
      if(this.multi.length >= 6)
      this.calendarPadding = 10

      }
      else
      this.multi = [{
        name: "",
      series: [
        {
          name: "Active subscriptions",
          value: 0
        },
        {
          name: "Published workouts",
          value: 0
        },{
          name: "Published mealplans",
          value: 0
        }]
      }]
    })
    this.db_service.generateReports(userID,queryParams5).subscribe(response => {
      // this.single = response['data'];
      if(response['data'].length != 0)
      this.callusage = response['data'];

      // this.callusage = [
      //   {
      //     "name": "JANUARY",
      //     "series": [
      //       {
      //         "name": "2010",
      //         "value": 7300000
      //       },
      //       {
      //         "name": "2011",
      //         "value": 8940000
      //       }
      //     ]
      //   },
      //   {
      //     "name": "FEBRAUARY",
      //     "series": [
      //       {
      //         "name": "2010",
      //         "value": 7300000
      //       },
      //       {
      //         "name": "2011",
      //         "value": 8940000
      //       }
      //     ]
      //   },
      //   {
      //     "name": "MARCH",
      //     "series": [
      //       {
      //         "name": "2010",
      //         "value": 7300000
      //       },
      //       {
      //         "name": "2011",
      //         "value": 8940000
      //       }
      //     ]
      //   },
      //   {
      //     "name": "APRIL",
      //     "series": [
      //       {
      //         "name": "2010",
      //         "value": 7300000
      //       },
      //       {
      //         "name": "2011",
      //         "value": 8940000
      //       }
      //     ]
      //   },
      //   {
      //     "name": "MAY",
      //     "series": [
      //       {
      //         "name": "2010",
      //         "value": 7300000
      //       },
      //       {
      //         "name": "2011",
      //         "value": 8940000
      //       }
      //     ]
      //   },
      //   {
      //     "name": "JUNE",
      //     "series": [
      //       {
      //         "name": "2010",
      //         "value": 7300000
      //       },
      //       {
      //         "name": "2011",
      //         "value": 8940000
      //       }
      //     ]
      //   },
      //   {
      //     "name": "JULY",
      //     "series": [
      //       {
      //         "name": "2010",
      //         "value": 7300000
      //       },
      //       {
      //         "name": "2011",
      //         "value": 8940000
      //       }
      //     ]
      //   },
      //   {
      //     "name": "AUGUST",
      //     "series": [
      //       {
      //         "name": "2010",
      //         "value": 7300000
      //       },
      //       {
      //         "name": "2011",
      //         "value": 8940000
      //       }
      //     ]
      //   },
      //   {
      //     "name": "SEPTEMBER",
      //     "series": [
      //       {
      //         "name": "2010",
      //         "value": 7300000
      //       },
      //       {
      //         "name": "2011",
      //         "value": 8940000
      //       }
      //     ]
      //   },
      //   {
      //     "name": "OCTOBER",
      //     "series": [
      //       {
      //         "name": "2010",
      //         "value": 7300000
      //       },
      //       {
      //         "name": "2011",
      //         "value": 8940000
      //       }
      //     ]
      //   },
      //   {
      //     "name": "NOVEMBER",
      //     "series": [
      //       {
      //         "name": "2010",
      //         "value": 7300000
      //       },
      //       {
      //         "name": "2011",
      //         "value": 8940000
      //       }
      //     ]
      //   },
      //   {
      //     "name": "DECEMBER",
      //     "series": [
      //       {
      //         "name": "2010",
      //         "value": 7300000
      //       },
      //       {
      //         "name": "2011",
      //         "value": 8940000
      //       }
      //     ]
      //   },



      // ]
    })
  }else{
    
    this.db_service.generateYogaReports(userID,queryParams).subscribe(response => {
      if(response['data'].length != 0){
      this.Subscriptions = response['data'];
      if(this.Subscriptions.length == 2)
      this.subscriptionsbarPadding = 150
      if(this.Subscriptions.length == 3)
      this.subscriptionsbarPadding = 50
      if(this.Subscriptions.length == 4)
      this.subscriptionsbarPadding = 50
      if(this.Subscriptions.length == 5)
      this.subscriptionsbarPadding = 50
      if(this.Subscriptions.length >= 6)
      this.subscriptionsbarPadding = 30
      }
      else
      this.Subscriptions = [{
        name: "",
      series: [
        {
          name: "Active subscriptions",
          value: 0
        },
        {
          name: "Inactive subscriptions",
          value: 0
        }]
      }]
       })
    this.db_service.generateYogaReports(userID,queryParams2).subscribe(response => {
      // this.single = response['data'];
      if(response['data'].length != 0)
      this.single = response['data'];
    })
    this.db_service.generateYogaReports(userID,queryParams3).subscribe(response => {
      this.Totalrevenue = response['data'];
      if(response['data'].length != 0){
      this.Totalrevenue = response['data'];
      if(this.Totalrevenue.length == 2)
      this.totalrevenuePadding = 150
      if(this.Totalrevenue.length == 3)
      this.totalrevenuePadding = 50
      if(this.Totalrevenue.length == 4)
      this.totalrevenuePadding = 50
      if(this.Totalrevenue.length == 5)
      this.totalrevenuePadding = 35
      if(this.Totalrevenue.length >= 6)
      this.totalrevenuePadding = 30
      }
      else
      this.Totalrevenue = [{
        name: "",
      series: [
        {
          name: "Price in rupees",
          value: 0
        },
        {
          name: "Price in dollars",
          value: 0
        },  {
          name: "Refund in rupees",
          value: 0
        },  {
          name: "Refund in dollars",
          value: 0
        }]
      }]
    })
    this.db_service.generateYogaReports(userID,queryParams4).subscribe(response => {
      // this.multi = response['data'];
      if(response['data'].length != 0){
      this.multi = response['data'];
      if(this.multi.length == 2)
      this.calendarPadding = 150;
      if(this.multi.length == 3)
      this.calendarPadding = 50
      if(this.multi.length == 4)
      this.calendarPadding = 50
      if(this.multi.length == 5)
      this.calendarPadding = 40
      if(this.multi.length >= 6)
      this.calendarPadding = 10

      }
      else
      this.multi = [{
        name: "",
      series: [
        {
          name: "Active subscriptions",
          value: 0
        },
        {
          name: "Published workouts",
          value: 0
        },{
          name: "Published mealplans",
          value: 0
        }]
      }]
    })
    this.db_service.generateYogaReports(userID,queryParams5).subscribe(response => {
      // this.single = response['data'];
      if(response['data'].length != 0)
      this.callusage = response['data'];
    });
  }
}
public yAxisTickFormattingFn = this.yAxisTickFormatting.bind(this);
yAxisTickFormatting(val) {
  if (val % 1 === 0) {
    return val.toLocaleString();
  } else {
    return '';
  }
}
 ngOnDestroy() {
    this.renderer.removeClass(document.body, 'bg-img-build-male');
    this.renderer.removeClass(document.body, 'bg-img-yoga-male');
    this.renderer.removeClass(document.body, 'bg-img-zumba-male');
    this.renderer.removeClass(document.body, 'bg-img-build-female');
    this.renderer.removeClass(document.body, 'bg-img-yoga-female');
    this.renderer.removeClass(document.body, 'bg-img-zumba-female');
    this.renderer.addClass(document.body, 'body-main');
}
  getProfile() {
    var index = '/profile/';
    this.currentCategory = localStorage.getItem("ProfileCategory");
    if(this.currentCategory == 'BodyBuilder'){
    this.db_service.getDataFromServer(this.userID, index).subscribe(
      response => {
        if (response != null) {
          //console.log('Data Retrived succesfully.', response);

          if (response.data.length != 0 && response.data[0] != null) {
            response = response.data[0];

            if (response.profileImage != null) {
              this.profilepicUrl = response.profileImage.profileImagePath;

            }
          }
        }

      },
      error => {
        console.log("An error has occurred while retriving profile data.");
      }
    )
    }else{
      
    this.yoga_db_service.getDataFromServer(this.userID, index).subscribe(
      response => {
        if (response != null) {
          //console.log('Data Retrived succesfully.', response);

          if (response.data.length != 0 && response.data[0] != null) {
            response = response.data[0];

            if (response.profileImage != null) {
              this.profilepicUrl = response.profileImage.profileImagePath;

            }
          }
        }

      },
      error => {
        console.log("An error has occurred while retriving profile data.");
      }
    )
    }
  }
  getProfileDataFromServer() {
    var index = '/profile/';
    this.currentCategory = localStorage.getItem("ProfileCategory");
    if(this.currentCategory == 'BodyBuilder'){
    this.db_service.getDataFromServer(this.currentEmail, index).subscribe(
      response => {
        //console.log('Data Retrived succesfully.', response);
        // if (response != null) {
        //   if (response.data.length != 0 && response.data[0] != null) {
            response = response.data[0];
            this.userID = response.trainerId;
            this.generatereports(this.userID);
            // this.profilepicUrl = response.im
            if (response.profileImage != null) {
              this.profilepicUrl = response.profileImage.profileImagePath;
            }
            // this.profileResponse = response;
        //   }
        // }

      },
      error => {
        // alert("An error has occurred while retriving profile data.");
      }
    )
    }else{
      
    this.yoga_db_service.getDataFromServer(this.currentEmail, index).subscribe(
      response => {
        //console.log('Data Retrived succesfully.', response);
        // if (response != null) {
        //   if (response.data.length != 0 && response.data[0] != null) {
            response = response.data[0];
            this.userID = response.trainerId;
            this.generatereports(this.userID);
            // this.profilepicUrl = response.im
            if (response.profileImage != null) {
              this.profilepicUrl = response.profileImage.profileImagePath;
            }
            // this.profileResponse = response;
        //   }
        // }

      },
      error => {
        // alert("An error has occurred while retriving profile data.");
      }
    )
    }
  }
  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/login']);
    } else {
      // if()
      if (this.loggedIn == true) {
        this.currentEmail = localStorage.getItem('lastAuthUser');
        // if(this.currentEmail != undefined)
        this.getProfileDataFromServer();
      }
      else {
        // this.currentEmail = localStorage.getItem('CognitoIdentityServiceProvider.2io8v4r253hugt5ndbjv9shkfp.LastAuthUser');
        this.currentEmail = this.cognitoUtil.getCurrentUser().getUsername();
        if(this.currentEmail == undefined || this.currentEmail == '' || this.currentEmail == null || this.currentEmail.includes("Google") || this.currentEmail.includes("google"))
        {
          this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
          if(this.currentEmail == undefined)
          this.currentEmail = this.cognitoUtil.session.idToken.payload.email;
        }
        // this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
        this.getProfileDataFromServer();
      }
    }
  }
}
