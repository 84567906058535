import { Component, OnInit, TemplateRef, Input, ViewChild, OnDestroy, Renderer2 } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { AppGlobals } from '../app.globals';
import { DBService } from '../service/db.service';
import { AWSService } from '../service/aws.service';
import { integer } from 'aws-sdk/clients/cloudfront';
import { UserLoginService } from "../service/user-login.service";
import { LoggedInCallback, CognitoUtil } from "../service/cognito.service";
import { ToastrService } from 'ngx-toastr';
import { EmbedVideoService } from 'ngx-embed-video';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

declare var $: any;
export class exercise {
  exerciseId: string;
  exerciseName: string;
  workoutType: Object;
  otherWorkoutType: Object;
  mechanicalType: Object;
  otherMechanicalType: string;
  mainMuscleWorkout: Object;
  otherMainMuscleWorkout: string;
  otherMuscleWorkout: string;
  level: Object;
  sport: Object;
  equipment: Object;
  force: Object;
  sets: Object[];
  exerciseVideo: Object;
  instructions: string;
  trainerId: string;
  isPublic: boolean;
  isPublicDate: Date;
  creationDate: Date;
  lastUpdatedOn: Date;
  exerciseWarnings: string;
}

@Component({
  selector: 'app-exercise',
  templateUrl: './exercise.component.html',
  styleUrls: ['./exercise.component.css']
})
export class ExerciseComponent implements OnInit, OnDestroy, LoggedInCallback {

  modalRef: BsModalRef;
  
  public showVideoPopup: boolean = false;
  showExdeletePopup: boolean = false;
  @Input() activeUrl: string = "";
  @Input() imgURL: string = "";
  videoHidden: boolean = false;
  iframeHidden: boolean = false;
  embedYoutubeUrl: string;
  searchExercise: string;
  @ViewChild('exerciseVideo') exerciseVideo: any;
  // constructor(private modalService: BsModalService) { }
  exercise: exercise;
  exercises: object[];
  Object: object;
  workouts: object[];
  currentEmail: string;
  currentCategory: string;
  currentGender: string;
  iframeURL: any;
  isPublic: boolean = false;
  currentExerciseId: string;
  exerciseLength: integer;
  publicexercises: Object[];
  exerciseVideoData = {
    exerciseVideoSource: "",
    exerciseVideoName: "",
    videoThumbnailPath: "",
    videoSourceUrl: "",
    videoDestinationCheck: false,
    videoMp4Destination: "",
    VideoVerfied: "",
    verifiedOn: Date,
    VideoUploadedOn: new Date(),
    youtubeUrl: "",
  }
  showDeletePopup: boolean = false;
  showAdminComments: boolean = false;
  adminComments: Object[];
  // Google Var
  private loggedIn: boolean;
  pagenumber:number = 0;
  pagesize:number = 17;
  notSroclly:boolean = true;
  notEmptyPost:boolean = true;


  constructor(private renderer: Renderer2, private toastr: ToastrService, private HttpClient: HttpClient,
    public router: Router, public userService: UserLoginService, public route: ActivatedRoute, private globals: AppGlobals,
    private db_service: DBService, private awsService: AWSService, private embedService: EmbedVideoService, public cognitoUtil: CognitoUtil
    ,private modalService: BsModalService) {
    this.userService.isAuthenticated(this);
  }

  ngOnInit() {

    this.currentCategory = localStorage.getItem("ProfileCategory")
    this.currentGender = localStorage.getItem("ProfileGender");
    if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-build-male');
    }
    else if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-build-female');
    }
    else if (this.currentCategory == 'Yoga' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-yoga-male');
    }
    else if (this.currentCategory == 'Yoga' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-yoga-female');
    }
    else if (this.currentCategory == 'Zumba' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-zumba-male');
    }
    else if (this.currentCategory == 'Zumba' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-zumba-female');
    }

    this.exercise = new exercise();
    this.userService.currentUserID.subscribe((val) => {
      this.exercise.trainerId = val;
    });

    if (this.exercise.trainerId == undefined && this.exercise.trainerId == "") {
      this.getProfileDataFromServer();
    }

    // this.exercise.userId = "naresh_1e25dff44asds4654sdsd4";
    if (this.exercise.trainerId != undefined && this.exercise.trainerId != "") {
      this.getExerciseDataFromServer();
    }

    // this.exerciseVideo.nativeElement.muted = true;
    // this.loadScript('../../assets/js/custom.js');
  }

  ngOnDestroy() {
    // this.sub.unsubscribe();
    this.renderer.removeClass(document.body, 'bg-img-build-male');
    this.renderer.removeClass(document.body, 'bg-img-yoga-male');
    this.renderer.removeClass(document.body, 'bg-img-zumba-male');
    this.renderer.removeClass(document.body, 'bg-img-build-female');
    this.renderer.removeClass(document.body, 'bg-img-yoga-female');
    this.renderer.removeClass(document.body, 'bg-img-zumba-female');
    this.renderer.addClass(document.body, 'body-main');
    this.renderer.removeClass(document.body, 'modal-open');
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {

    if (!isLoggedIn) {
      this.router.navigate(['/login']);
    } else {
      // if()
      if (this.loggedIn == true) {
        this.currentEmail = localStorage.getItem('lastAuthUser');
        // if(this.currentEmail != undefined)
        this.getProfileDataFromServer();
      }
      else {
        // this.currentEmail = localStorage.getItem('CognitoIdentityServiceProvider.2io8v4r253hugt5ndbjv9shkfp.LastAuthUser');
        this.currentEmail = this.cognitoUtil.getCurrentUser().getUsername();
        if(this.currentEmail == undefined || this.currentEmail == '' || this.currentEmail == null || this.currentEmail.includes("Google") || this.currentEmail.includes("google"))
        {
          this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
          if(this.currentEmail == undefined)
          this.currentEmail = this.cognitoUtil.session.idToken.payload.email;
        }
        // this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
        this.getProfileDataFromServer();
      }
    }
  }
  getProfileDataFromServer() {
    var index = '/profile/';
    this.db_service.getDataFromServer(this.currentEmail, index).subscribe(
      response => {
        //console.log('Data Retrived succesfully.', response);
        response = response.data[0];
        this.exercise.trainerId = response.trainerId;
        this.getExerciseDataFromServer();
      },
      error => {
        this.toastr.error('', 'An error has occurred while retriving profile data.', {
          timeOut: 5000,
          extendedTimeOut: 1000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true
        });
      }
    )
  }
  openModal(exercise,template: TemplateRef<any>) {

    this.modalRef = this.modalService.show(template,Object.assign({}, { class: 'modal-dialog-centered' }));
    if (exercise["exerciseVideo"]["youtubeUrl"] == "" && exercise["exerciseVideo"]["videoDestinationCheck"] == false) {
      var dest = exercise["exerciseVideo"]["videoMp4Destination"].split("/");
      var FolderPath = dest[3] + "/" + dest[4] + "/" + dest[5] + "/" + dest[6] + "/" + dest[7];
      this.awsService.checkUrl(this.globals.DEST_BUCKET, FolderPath).subscribe(
        response => {
          if (response != "false") {
            exercise["exerciseVideo"]["videoDestinationCheck"] = true;
            exercise["exerciseVideo"]["exerciseVideoSource"] = exercise["exerciseVideo"]["videoMp4Destination"];
            this.SaveExercise(exercise, 'edit');
          }
          // this.showVideoPopup = !this.showVideoPopup;'
          this.openVideo(false, exercise["exerciseVideo"]["exerciseVideoSource"]);
        },
        error => {
          // this.showVideoPopup = !this.showVideoPopup;
          this.openVideo(false, exercise["exerciseVideo"]["exerciseVideoSource"]);
        })
    } else if (exercise["exerciseVideo"]["youtubeUrl"] == "" && exercise["exerciseVideo"]["videoDestinationCheck"] == true) {
      this.openVideo(false, exercise["exerciseVideo"]["exerciseVideoSource"]);
    } else {
      this.openVideo(true, exercise["exerciseVideo"]["exerciseVideoSource"]);
      // this.showVideoPopup = !this.showVideoPopup;
    }
    // this.activeUrl = this.exercise.activeUrl;
  }

  // openModal(template: TemplateRef<any>, exerciseId) {
  // this.videoUrl = sourceUrl;
  //     this.modalRef = this.modalService.show(template);
  // }


  SaveExercise(data, type) {
    if (type == 'save')
      this.exercise.exerciseVideo["exerciseVideoSource"] = this.exercise.exerciseVideo["videoSourceUrl"];
    var index = '/exercise'
    this.db_service.updateDatainDB(data, index).subscribe(
      response => {
        // console.log("destvideoCheck updated Succesfully .")
      },
      error => {
        console.log('there is an error while updating destvideoCheck');
      }

    )
  }

  getExerciseDataFromServer() {
    var index = '/exercise/all/';
    var queryparams={
      "pagesize": this.pagesize,
      "pagenumber":0,
      "pagination":true
    }
    this.db_service.getQueriedData(this.exercise.trainerId, index, queryparams).subscribe(
      response => {
        //console.log('Data Retrived succesfully.', response);
        this.publicexercises = [];
        this.exerciseLength = response.data.length;
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i]["isPublic"] == true) {
            this.publicexercises.push(JSON.parse(JSON.stringify(response.data[i])));
          }
        }
        if (this.isPublic == false) {
          this.exercises = response.data;
        } else {
          this.exercises = this.publicexercises;
        }
      },
      error => {
        // alert("An error has occurred while saving data.");
        this.toastr.error('', 'An error has occurred while saving data.', {
          timeOut: 5000,
          extendedTimeOut: 1000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true
        });
      }
    )
  }

  onScroll(){
    console.log("scrolled..");
    if(this.notEmptyPost && this.notSroclly){
     this.notSroclly = false;
     this.pagenumber = this.pagenumber + 1;
     this.loadNext();
    }
  }
  loadNext(){
    var index = '/exercise/all/';
    var queryparams={
      "pagesize": this.pagesize,
      "pagenumber":this.pagenumber,
      "pagination":true
    }
    this.db_service.getQueriedData(this.exercise.trainerId, index, queryparams).subscribe(
      response => {
       if(response.data != null){
        if( this.exercises.length === 0){
          this.notEmptyPost = false;
        }
        this.exercises = this.exercises.concat(response.data);
        this.publicexercises = [];
        this.notSroclly = true;
        for (let i = 0; i < this.exercises.length; i++) {
          if (response.data[i]["isPublic"] == true) {
            this.publicexercises.push(JSON.parse(JSON.stringify(response.data[i])));
          }
        }
        if (this.isPublic == false) {
          this.exercises = response.data;
        } else {
          this.exercises = this.publicexercises;
        }
      }
      },
      error => {
        console.log('There is an error while retriving exercises.');
      }
    )
  }
  deleteEx(id) {
    this.currentExerciseId = id;
    this.showExdeletePopup = true;
  }

  deleteExercise() {

    for (let i = 0; i < this.exercises.length; i++) {
      if (this.exercises[i]["exerciseId"] == this.currentExerciseId) {
        if (this.exercises[i]["youtubeUrl"] == null) {
          this.awsService.deleteFileFromS3(this.exercises[i]["videoName"], this.exercises[i]["trainerId"], this.globals.DEST_BUCKET, this.globals.EXERCISE_FOLDER).subscribe(
            response => {
              // console.log('Video deleted from dest folder successfully.', response);
              this.awsService.deleteFileFromS3(this.exercises[i]["videoName"], this.exercises[i]["trainerId"], this.globals.SORCE_BUCKET, this.globals.EXERCISE_FOLDER).subscribe(
                response => {
                  // console.log('Video deleted from source folder successfully.', response);
                  var index = '/exercise/';

                  this.db_service.deleteRecordInDB(this.currentExerciseId, index).subscribe(
                    response => {
                      this.toastr.success('', 'Exercise deleted successfully.', {
                        timeOut: 3000,
                        positionClass: 'toast-top-right',
                        progressBar: true,
                        progressAnimation: 'increasing',
                        tapToDismiss: true,
                      });
                      this.renderer.removeClass(document.body, 'modal-open');
                      this.getExerciseDataFromServer();
                    },
                    error => {
                      this.toastr.error('', 'An error has occurred while deleting record.', {
                        timeOut: 3000,
                        positionClass: 'toast-top-right',
                        progressBar: true,
                        progressAnimation: 'increasing',
                        tapToDismiss: true,
                      });
                    }
                  )
                },
                error => {
                  console.log('There is an error while deleting source video.', error);

                })
            },
            error => {
              console.log('There is an error while deleting dest video.', error);

            })
        } else {
          var index = '/exercise/delete/';

          this.db_service.deleteRecordFromDB(this.currentExerciseId, index).subscribe(
            response => {
              this.toastr.success('', 'Record deleted successfully.', {
                timeOut: 3000,
                positionClass: 'toast-top-right',
                progressBar: true,
                progressAnimation: 'increasing',
                tapToDismiss: true,
              });
              this.renderer.removeClass(document.body, 'modal-open');
              this.getExerciseDataFromServer();
            },
            error => {
              this.toastr.error('', 'An error has occurred while deleting record.', {
                timeOut: 3000,
                positionClass: 'toast-top-right',
                progressBar: true,
                progressAnimation: 'increasing',
                tapToDismiss: true,
              });
            }
          )
        }
      }
    }
    this.showExdeletePopup = false;

  }
  deleteCheck(id) {
    // this.showDeletePopup = true;
    var index = '/workout/all/';
    var queryparams={
      "pagesize": this.pagesize,
      "pagenumber":this.pagenumber,
      "pagination":false
    }
    this.db_service.getQueriedData(this.exercise.trainerId, index, queryparams).subscribe(
      response => {
        // console.log('Data Retrived succesfully.', response);
        this.workouts = response.data;
        for (let i = 0; i < this.workouts.length; i++) {
          if (this.workouts[i]["workoutExercises"] != null) {
            var workoutExercises = this.workouts[i]["workoutExercises"];
            for (let k = 0; k < workoutExercises.length; k++) {
              if (workoutExercises[k]["exerciseId"] == id) {
                this.toastr.error('', 'This exercise is reffered in workouts, you can not delete this exercise.', {
                  timeOut: 3000,
                  positionClass: 'toast-top-right',
                  progressBar: true,
                  progressAnimation: 'increasing',
                  tapToDismiss: true,
                });
                return;
              }
            }
          }
        }
        this.deleteEx(id);
      },
      error => {
        console.log('There is an error while retriving workouts.');
      })
  }
  disablePublic(exId) {
    var current_exercise = this.exercises.find(x => x["exerciseId"] == exId);
    current_exercise["isPublic"] = false;
    var index = '/exercise'
    this.db_service.updateDatainDB(current_exercise, index).subscribe(
      response => {
        this.getExerciseDataFromServer();
        this.toastr.success('', 'exercise updated successfully.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      },
      error => {
        console.log('there is an error while updating exercise');
      }

    )
  }
  enablePublic() {
    this.getExerciseDataFromServer();
  }
  editExercise(id) {
    // console.log(id);
    this.router.navigate(['editexercise', id]);
  }
  openVideo(youtubeUrl, url) {
    this.showVideoPopup = !this.showVideoPopup;
    if (youtubeUrl == false) {
      this.activeUrl = url;
      this.iframeHidden = false;
      this.videoHidden = true;
    } else {
      this.iframeHidden = true;
      this.videoHidden = false;
      this.iframeURL = this.embedService.embed(url, {
        query: { portrait: 0, color: 'black', autoplay: 1 },
        attr: { width: "100%", height: 450, frameborder: 0, allow: "autoplay" }
      })
      if (this.iframeURL != undefined)
        this.embedYoutubeUrl = this.iframeURL;
    }
  }
  getAdminComments(id) {
    var exercise = this.exercises.find(x => x["exerciseId"] == id);
    this.adminComments = exercise["approvalComments"];
    this.showAdminComments = true;
  }
  closePopup() {
    this.showVideoPopup = false;
    this.showExdeletePopup = false;
    this.showAdminComments = false;
    this.renderer.removeClass(document.body, 'modal-open');
  }
}

