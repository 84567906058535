<section>
  <div class="container-fluid px-5">
      <div class="col-md-10 mx-auto">
  <div class="row">
      <div class="col-6 col-md-6">
        <!-- <i class="fa fa-arrow-left mx-3" aria-hidden="true" style="
            padding: 11px;
            border: 3px solid yellowgreen;
            border-radius: 50%;" routerLink="/tprofile/{{program_id}}/{{trainee_id}}"></i> -->
        <img class="rounded-circle" src="{{traineeImage}}" alt="Profile image" width="50" height="50" *ngIf="traineeImage!=undefined">
        <img class="rounded-circle" src="assets/images/default_profile_img.png" alt="Default image" width="50" height="50" *ngIf="traineeImage==undefined">    
          <span class="trainee-profile-heading py-2 px-2" style="font-size: 2rem;"> <b>Trainee Profile</b></span>
      </div>
      <div class="col-md-6 col-5">
        <button class="btn btn-default save-exercise-btn mr-0 float-right" style="cursor: pointer;"
        routerLink="/tprofile/{{program_id}}/{{trainee_id}}" type="button"
          tabindex="0">BACK</button>
      </div>
    </div>
</div>
  </div>
  <div class="col-md-11 mx-auto pt-3" style="font-size: 15px;">
      <div class="row">
          <div class="col-md-4">
              <div class="col-md-12 trainee-profile-div-bg">
                  <div class="col p-3  text-center" >
                      <img class="rounded trainee-profile-image mx-auto" src="{{traineeImage}}" alt="Girl in a jacket"*ngIf="traineeImage!=undefined">
                      <img class="rounded trainee-profile-image mx-auto" src="assets/images/default_profile_img.png" alt="Girl in a jacket" *ngIf="traineeImage==undefined">
                  </div>
                  <div class="col-md-12 py-3">
                      <div class="row">
                          <div class="col-md-12 d-flex pb-3">
                              <span class="col-md-5 text-right font-weight-bold trainee-det-yg">First Name : </span>
                              <span class="col-md-7 text-left trainee-name-wrap"> {{trainee.first_name | titlecase}}   </span>
                          </div>
                          <div class="col-md-12 d-flex pb-3">
                              <span class="col-md-5 text-right font-weight-bold trainee-det-yg">Last Name : </span>
                              <span class="col-md-7 text-left trainee-name-wrap"> {{trainee.last_name | titlecase}}   </span>
                          </div>
                          <div class="col-md-12 d-flex pb-3">
                              <span class="col-md-5 text-right font-weight-bold trainee-det-yg">Gender : </span>
                              <span class="col-md-7 text-left trainee-name-wrap"> {{trainee.gender | titlecase}} </span>
                          </div>
                          <div class="col-md-12 d-flex pb-3">
                            <span class="col-md-5 text-right font-weight-bold trainee-det-yg">Age : </span>
                            <span class="col-md-7 text-left trainee-name-wrap"> {{trainee.age}} years</span>
                        </div>
                          <!-- <div class="col-md-12 d-flex pb-3">
                            <span class="col-md-5 text-right font-weight-bold trainee-det-yg">Mobile : </span>
                            <span class="col-md-7 text-left trainee-name-wrap"> {{ trainee.mobile_no }} </span>
                        </div> -->
                          <div class="col-md-12 d-flex pb-3">
                            <span class="col-md-5 text-right font-weight-bold trainee-det-yg">Date of Birth : </span>
                            <span class="col-md-7 text-left trainee-name-wrap"> {{trainee.date_of_birth | date}}  </span>
                        </div>
                        <div class="col-md-12 d-flex pb-3">
                            <span class="col-md-5 text-right font-weight-bold trainee-det-yg">Weight : </span>
                            <span class="col-md-7 text-left trainee-name-wrap"> {{trainee.currrent_weight.weight | number : '1.2-2' }} {{trainee.currrent_weight.metric}} </span>
                        </div>
                        <div class="col-md-12 d-flex pb-3">
                            <span class="col-md-5 text-right font-weight-bold trainee-det-yg">Height : </span>
                            <span class="col-md-7 text-left trainee-name-wrap"> {{trainee.trainee_height.height | number : '1.2-2' }} {{trainee.trainee_height.metric}}</span>
                        </div>
                        <div class="col-md-12 d-flex pb-3">
                            <span class="col-md-5 text-right font-weight-bold trainee-det-yg">Activity Level : </span>
                            <span class="col-md-7 text-left "> {{trainee.activity_level | titlecase}} </span>
                        </div>


                     </div>
                  </div>
              </div>
          </div>
          <div class="col-md-4">
              <div class="col-md-12 trainee-profile-div-bg">
                  <div class="col-md-12 py-3">
                      <div class="row">
                        <div class="col-md-12 d-flex pb-3">
                            <span class="col-md-8 text-right font-weight-bold trainee-det-yg">Primary goal : </span>
                            <span class="col-md-4 text-left "> {{trainee.primary_goal | titlecase}}</span>
                        </div>
                          <div class="col-md-12 d-flex pb-3">
                              <span class="col-md-8 text-right font-weight-bold trainee-det-yg">Best workout Days : </span>
                              <div class="row">
                              <div class="food-fav-style" *ngFor = "let day of trainee.best_workout_day.days"> {{day | titlecase}} </div>
                            </div>
                          </div>
                          <div class="col-md-12 d-flex pb-3">
                            <span class="col-md-8 text-right font-weight-bold trainee-det-yg">Time spent : </span>
                            <span class="col-md-4 text-left "> {{trainee.best_workout_day.time_spent | titlecase}} </span>
                        </div>
                          <div class="col-md-12 d-flex pb-3">
                              <span class="col-md-8 text-right font-weight-bold trainee-det-yg">Food Preference : </span>
                              <span class="col-md-4 text-left "> {{trainee.food_preference.food_type[0] | titlecase}} </span>
                          </div>
                          <div class="col-md-12 d-flex pb-3">
                            <span class="col-md-8 text-right font-weight-bold trainee-det-yg">Favorite Meat : </span>
                            <div class="row">
                                <div class="food-fav-style" *ngFor = "let food of trainee.food_preference.non_veg_preference"> {{food | titlecase}} </div>
                            </div>
                        </div>
                          <div class="col-md-12 d-flex pb-3">
                              <span class="col-md-8 text-right font-weight-bold trainee-det-yg">Meals Consumed per day : </span>
                              <span class="col-md-4 text-left "> {{trainee.food_preference.no_of_meals_day | titlecase}} </span>
                          </div>
                          <div class="col-md-12 d-flex pb-3">
                              <span class="col-md-8 text-right font-weight-bold trainee-det-yg">Smoke or Alcohol : </span>
                              <span class="col-md-4 text-left "> {{trainee.smoke_alcohol_consumption.status | titlecase}} </span>
                          </div>
                          <div class="col-md-12 d-flex pb-3">
                            <span class="col-md-8 text-right font-weight-bold trainee-det-yg">How often do you consume alcohol ? : </span>
                            <span class="col-md-4 text-left "> {{trainee.smoke_alcohol_consumption.alcohol_consumption | titlecase}} </span>
                        </div>
                        <div class="col-md-12 d-flex pb-3">
                            <span class="col-md-8 text-right font-weight-bold trainee-det-yg">How often do you smoke ? </span>
                            <span class="col-md-4 text-left "> {{trainee.smoke_alcohol_consumption.smoking_consumption | titlecase}} </span>
                        </div>
                          <div class="col-md-12 d-flex pb-3">
                            <span class="col-md-8 text-right font-weight-bold trainee-det-yg">BMI : </span>
                            <span class="col-md-4 text-left "> {{trainee.bmi_bmr.bmi_value}} </span>
                        </div>
                        <div class="col-md-12 d-flex pb-3">
                            <span class="col-md-8 text-right font-weight-bold trainee-det-yg">BMR : </span>
                            <span class="col-md-4 text-left "> {{trainee.bmi_bmr.bmr_value}} </span>
                        </div>
                        <div class="col-md-12 d-flex pb-3">
                            <span class="col-md-8 text-right font-weight-bold trainee-det-yg">Sleep Duration : </span>
                            <span class="col-md-4 text-left "> {{trainee.sleep_duration}} </span>
                        </div>
                        <div class="col-md-12 d-flex pb-3">
                            <span class="col-md-8 text-right font-weight-bold trainee-det-yg">Sleep Quality : </span>
                            <span class="col-md-4 text-left "> {{trainee.sleep_quality | titlecase}} </span>
                        </div>


                     </div>
                  </div>
              </div>
          </div>
          <div class="col-md-4">
              <div class="col-md-12 trainee-profile-div-bg">
                  <div class="col-md-12 py-3">
                      <div class="row">



                          <div class="col-md-12 d-flex pb-3">
                              <span class="col-md-12 font-weight-bold text-center trainee-det-yg"><u>Medical History :</u> </span>
                          </div>
                          <div class="col-md-12 row pb-3">
                            <div class="col-md-12 font-weight-bold trainee-det-yg">Any disabilities : </div>
                            <div class="col-md-12 medic-fix-height"> {{trainee.medical_history.any_disability }} </div>
                          </div>
                          <div class="col-md-12 row pb-3">
                            <div class="col-md-12 font-weight-bold trainee-det-yg">Food item(s) are allergic : </div>
                            <div class="col-md-12 medic-fix-height"> {{trainee.medical_history.any_food_allergies}}
                            </div>
                          </div>
                          <div class="col-md-12 row pb-3">
                            <div class="col-md-12 font-weight-bold trainee-det-yg">Surgeries or medical issue(s) : </div>
                            <div class="col-md-12 medic-fix-height"> {{trainee.medical_history.any_medical_surgerie_issues}} </div>
                          </div>
                          <div class="col-md-12 row pb-3" *ngIf="trainee.previous_workout_history.status != 'no'">
                            <div class="col-md-12 font-weight-bold trainee-det-yg">Previous workout History : </div>
                            <div class="col-md-12 medic-fix-height"> {{trainee.previous_workout_history.description}} </div>
                          </div>

                     </div>
                  </div>
              </div>
          </div>

      </div>
  </div>
</section>
