import { Component, OnInit, TemplateRef, Input, ViewChild, OnDestroy, Renderer2 } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { AppGlobals } from '../app.globals';
import { DBService } from '../service/db.service';
import { AWSService } from '../service/aws.service';
import { integer } from 'aws-sdk/clients/cloudfront';
import { UserLoginService } from "../service/user-login.service";
import { LoggedInCallback, CognitoUtil } from "../service/cognito.service";
import { ToastrService } from 'ngx-toastr';
import { EmbedVideoService } from 'ngx-embed-video';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { YogaDBService } from '../service/YogaDBService';


declare var $: any;
// export class exercise {
//   exerciseId: string;
//   exerciseName: string;
//   workoutType: Object;
//   otherWorkoutType: Object;
//   mechanicalType: Object;
//   otherMechanicalType: string;
//   mainMuscleWorkout: Object;
//   otherMainMuscleWorkout: string;
//   otherMuscleWorkout: string;
//   level: Object;
//   sport: Object;
//   equipment: Object;
//   force: Object;
//   sets: Object[];
//   exerciseVideo: Object;
//   instructions: string;
//   trainerId: string;
//   isPublic: boolean;
//   isPublicDate: Date;
//   creationDate: Date;
//   lastUpdatedOn: Date;
//   exerciseWarnings: string;
// }

export class asanaModel{
  asanaId : string;
  trainerId : string;
  asanaVideo: string;
  asanaTitle : string;
  asanaType:Object;
  sets:Object[];
  adoptedName : string;
  targetMuscle: Object[];
  difficultyLevel:object;
  asanaGoal:Object[];
  therapeutic:object;
  benifitsOfAsana:string;
  precautions:string;
  isPublic: boolean;
  isPublicDate: Date;
  creationDate: Date;
  lastUpdatedOn: Date;
  approvalComments;
}


@Component({
  selector: 'app-asanas',
  templateUrl: './asanas.component.html',
  styleUrls: ['./asanas.component.css']
})
export class AsanasComponent implements OnInit {

  
  modalRef: BsModalRef;
  
  public showVideoPopup: boolean = false;
  showExdeletePopup: boolean = false;
  @Input() activeUrl: string = "";
  @Input() imgURL: string = "";
  videoHidden: boolean = false;
  iframeHidden: boolean = false;
  embedYoutubeUrl: string;
  // searchExercise: string;
  searchAsana:string;
  // @ViewChild('exerciseVideo') exerciseVideo: any;
  @ViewChild('asanaVideo') asanaVideo: any;
  // constructor(private modalService: BsModalService) { }
  // exercise: exercise;
  asanaModel: asanaModel;
  // exercises: object[];
  asanas:object[];
  Object: object;
  workouts: object[];
  currentEmail: string;
  currentCategory: string;
  currentGender: string;
  iframeURL: any;
  isPublic: boolean = false;
  // currentExerciseId: string;
  currentasanaId : string;
  // exerciseLength: integer;
  asanaLength;integer;
  // publicexercises: Object[];
  publicasanas: Object[];
  // exerciseVideoData = {
  //   exerciseVideoSource: "",
  //   exerciseVideoName: "",
  //   videoThumbnailPath: "",
  //   videoSourceUrl: "",
  //   videoDestinationCheck: false,
  //   videoMp4Destination: "",
  //   VideoVerfied: "",
  //   verifiedOn: Date,
  //   VideoUploadedOn: new Date(),
  //   youtubeUrl: "",
  // }

  asanaVideoData = {
    asanaVideoSource: "",
    asanaVideoName: "",
    videoThumbnailPath: "",
    videoSourceUrl: "",
    videoDestinationCheck: false,
    videoMp4Destination: "",
    VideoVerfied: "",
    verifiedOn: Date,
    VideoUploadedOn: new Date(),
    youtubeUrl: "",
  }
  showDeletePopup: boolean = false;
  showAdminComments: boolean = false;
  adminComments: Object[];
  // Google Var
  private loggedIn: boolean;
  pagenumber:number = 0;
  pagesize:number = 17;
  notSroclly:boolean = true;
  notEmptyPost:boolean = true;


  constructor(private renderer: Renderer2, private toastr: ToastrService, private HttpClient: HttpClient,
    public router: Router, public userService: UserLoginService, private yoga_db_service: YogaDBService, public route: ActivatedRoute, private globals: AppGlobals,
    private db_service: DBService, private awsService: AWSService, private embedService: EmbedVideoService, public cognitoUtil: CognitoUtil
    ,private modalService: BsModalService) {
    this.userService.isAuthenticated(this);
  }

  ngOnInit() {

    this.currentCategory = localStorage.getItem("ProfileCategory")
    this.currentGender = localStorage.getItem("ProfileGender");
    if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-build-male');
    }
    else if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-build-female');
    }
    else if (this.currentCategory == 'Yoga' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-yoga-male');
    }
    else if (this.currentCategory == 'Yoga' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-yoga-female');
    }
    else if (this.currentCategory == 'Zumba' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-zumba-male');
    }
    else if (this.currentCategory == 'Zumba' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-zumba-female');
    }

    // this.exercise = new exercise();
    this.asanaModel =new asanaModel();
    this.userService.currentUserID.subscribe((val) => {
      this.asanaModel.trainerId = val;
    });

    if (this.asanaModel.trainerId == undefined && this.asanaModel.trainerId == "") {
      this.getProfileDataFromServer();
    }

    // this.exercise.userId = "naresh_1e25dff44asds4654sdsd4";
    if (this.asanaModel.trainerId != undefined && this.asanaModel.trainerId != "") {
      this.getAsanaDataFromServer();
    }

    // this.exerciseVideo.nativeElement.muted = true;
    // this.loadScript('../../assets/js/custom.js');
  }

  ngOnDestroy() {
    // this.sub.unsubscribe();
    this.renderer.removeClass(document.body, 'bg-img-build-male');
    this.renderer.removeClass(document.body, 'bg-img-yoga-male');
    this.renderer.removeClass(document.body, 'bg-img-zumba-male');
    this.renderer.removeClass(document.body, 'bg-img-build-female');
    this.renderer.removeClass(document.body, 'bg-img-yoga-female');
    this.renderer.removeClass(document.body, 'bg-img-zumba-female');
    this.renderer.addClass(document.body, 'body-main');
    this.renderer.removeClass(document.body, 'modal-open');
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {

    if (!isLoggedIn) {
      this.router.navigate(['/login']);
    } else {
      // if()
      if (this.loggedIn == true) {
        this.currentEmail = localStorage.getItem('lastAuthUser');
        // if(this.currentEmail != undefined)
        this.getProfileDataFromServer();
      }
      else {
        // this.currentEmail = localStorage.getItem('CognitoIdentityServiceProvider.2io8v4r253hugt5ndbjv9shkfp.LastAuthUser');
        this.currentEmail = this.cognitoUtil.getCurrentUser().getUsername();
        if(this.currentEmail == undefined || this.currentEmail == '' || this.currentEmail == null || this.currentEmail.includes("Google") || this.currentEmail.includes("google"))
        {
          this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
          if(this.currentEmail == undefined)
          this.currentEmail = this.cognitoUtil.session.idToken.payload.email;
        }
        // this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
        this.getProfileDataFromServer();
      }
    }
  }
  getProfileDataFromServer() {
    var index = '/profile/';
    this.yoga_db_service.getDataFromServer(this.currentEmail, index).subscribe(
      response => {
        //console.log('Data Retrived succesfully.', response);
        response = response.data[0];
        this.asanaModel.trainerId = response.trainerId;
        this.getAsanaDataFromServer();
      },
      error => {
        this.toastr.error('', 'An error has occurred while retriving profile data.', {
          timeOut: 5000,
          extendedTimeOut: 1000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true
        });
      }
    )
  }
  openModal(asana,template: TemplateRef<any>) {

    this.modalRef = this.modalService.show(template,Object.assign({}, { class: 'modal-dialog-centered' }));
    if (asana["asanaVideo"]["youtubeUrl"] == "" && asana["asanaVideo"]["videoDestinationCheck"] == false) {
      var dest = asana["asanaVideo"]["videoMp4Destination"].split("/");
      var FolderPath = dest[3] + "/" + dest[4] + "/" + dest[5] + "/" + dest[6] + "/" + dest[7];
      this.awsService.checkUrl(this.globals.DEST_BUCKET, FolderPath).subscribe(
        response => {
          if (response != "false") {
            asana["asanaVideo"]["videoDestinationCheck"] = true;
            asana["asanaVideo"]["asanaVideoSource"] = asana["asanaVideo"]["videoMp4Destination"];
            this.SaveAsana(asana, 'edit');
          }
          // this.showVideoPopup = !this.showVideoPopup;'
          this.openVideo(false, asana["asanaVideo"]["asanaVideoSource"]);
        },
        error => {
          // this.showVideoPopup = !this.showVideoPopup;
          this.openVideo(false, asana["asanaVideo"]["asanaVideoSource"]);
        })
    } else if (asana["asanaVideo"]["youtubeUrl"] == "" && asana["asanaVideo"]["videoDestinationCheck"] == true) {
      this.openVideo(false, asana["asanaVideo"]["asanaVideoSource"]);
    } else {
      this.openVideo(true, asana["asanaVideo"]["asanaVideoSource"]);
      // this.showVideoPopup = !this.showVideoPopup;
    }
    // this.activeUrl = this.exercise.activeUrl;
  }

  // openModal(template: TemplateRef<any>, exerciseId) {
  // this.videoUrl = sourceUrl;
  //     this.modalRef = this.modalService.show(template);
  // }


  SaveAsana(data, type) {
    if (type == 'save')
      this.asanaModel.asanaVideo["asanaVideoSource"] = this.asanaModel.asanaVideo["videoSourceUrl"];
    var index = '/asana'
    this.yoga_db_service.updateDatainDB(data, index).subscribe(
      response => {
        // console.log("destvideoCheck updated Succesfully .")
      },
      error => {
        console.log('there is an error while updating destvideoCheck');
      }

    )
  }

  getAsanaDataFromServer() {
    var index = '/asana';
    // var queryparams={
    //   "pagesize": this.pagesize,
    //   "pagenumber":0,
    //   "pagination":true
    // }
    var queryparams={
      "trainerId": this.asanaModel.trainerId,
    }
    this.yoga_db_service.getQueriedData("", index,queryparams).subscribe(
      response => {
        //console.log('Data Retrived succesfully.', response);
        this.publicasanas = [];
        if(response.data != null || response.data != undefined){
        this.asanaLength = response.data.length;
        if(response.data.length != 0){
        for (let i = 0; i < this.asanaLength; i++) {
          if (response.data[i]["isPublic"] == true) {
            this.publicasanas.push(JSON.parse(JSON.stringify(response.data[i])));
          }
        }
      }
        if (this.isPublic == false) {
          this.asanas = response.data;
        } else {
          this.asanas = this.publicasanas;
        }
      }else{
        this.asanas = this.publicasanas;
        this.asanaLength = 0;
      }
      },
      error => {
        // alert("An error has occurred while saving data.");
        this.toastr.error('', 'An error has occurred while saving data.', {
          timeOut: 5000,
          extendedTimeOut: 1000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true
        });
      }
    )
  }

  onScroll(){
    console.log("scrolled..");
    if(this.notEmptyPost && this.notSroclly){
     this.notSroclly = false;
     this.pagenumber = this.pagenumber + 1;
     this.loadNext();
    }
  }
  loadNext(){
    var index = '/exercise/all/';
    var queryparams={
      "pagesize": this.pagesize,
      "pagenumber":this.pagenumber,
      "pagination":true
    }
    this.db_service.getQueriedData(this.asanaModel.trainerId, index, queryparams).subscribe(
      response => {
       if(response.data != null){
        if( this.asanas.length === 0){
          this.notEmptyPost = false;
        }
        this.asanas = this.asanas.concat(response.data);
        this.publicasanas = [];
        this.notSroclly = true;
        for (let i = 0; i < this.asanas.length; i++) {
          if (response.data[i]["isPublic"] == true) {
            this.publicasanas.push(JSON.parse(JSON.stringify(response.data[i])));
          }
        }
        if (this.isPublic == false) {
          this.asanas = response.data;
        } else {
          this.asanas = this.publicasanas;
        }
      }
      },
      error => {
        console.log('There is an error while retriving exercises.');
      }
    )
  }
  deleteEx(id) {
    this.currentasanaId = id;
    this.showExdeletePopup = true;
  }

  deleteAsana() {

    for (let i = 0; i < this.asanas.length; i++) {
      if (this.asanas[i]["asanaId"] == this.currentasanaId) {
        if (this.asanas[i]["youtubeUrl"] == null) {
          this.awsService.deleteFileFromS3(this.asanas[i]["videoName"], this.asanas[i]["trainerId"], this.globals.DEST_BUCKET, this.globals.EXERCISE_FOLDER).subscribe(
            response => {
              // console.log('Video deleted from dest folder successfully.', response);
              this.awsService.deleteFileFromS3(this.asanas[i]["videoName"], this.asanas[i]["trainerId"], this.globals.SORCE_BUCKET, this.globals.EXERCISE_FOLDER).subscribe(
                response => {
                  // console.log('Video deleted from source folder successfully.', response);
                  var index = '/asana/';

                  this.yoga_db_service.deleteRecordInDB(this.currentasanaId, index).subscribe(
                    response => {
                      this.toastr.success('', 'Asana deleted successfully.', {
                        timeOut: 3000,
                        positionClass: 'toast-top-right',
                        progressBar: true,
                        progressAnimation: 'increasing',
                        tapToDismiss: true,
                      });
                      this.renderer.removeClass(document.body, 'modal-open');
                      this.getAsanaDataFromServer();
                    },
                    error => {
                      this.toastr.error('', 'An error has occurred while deleting record.', {
                        timeOut: 3000,
                        positionClass: 'toast-top-right',
                        progressBar: true,
                        progressAnimation: 'increasing',
                        tapToDismiss: true,
                      });
                    }
                  )
                },
                error => {
                  console.log('There is an error while deleting source video.', error);

                })
            },
            error => {
              console.log('There is an error while deleting dest video.', error);

            })
        } else {
          var index = '/asana/';

          this.db_service.deleteRecordFromDB(this.currentasanaId, index).subscribe(
            response => {
              this.toastr.success('', 'Record deleted successfully.', {
                timeOut: 3000,
                positionClass: 'toast-top-right',
                progressBar: true,
                progressAnimation: 'increasing',
                tapToDismiss: true,
              });
              this.renderer.removeClass(document.body, 'modal-open');
              this.getAsanaDataFromServer();
            },
            error => {
              this.toastr.error('', 'An error has occurred while deleting record.', {
                timeOut: 3000,
                positionClass: 'toast-top-right',
                progressBar: true,
                progressAnimation: 'increasing',
                tapToDismiss: true,
              });
            }
          )
        }
      }
    }
    this.showExdeletePopup = false;

  }
  deleteCheck(id) {
    // this.showDeletePopup = true;
    // var index = '/workout/all/';
    // var queryparams={
    //   "pagesize": this.pagesize,
    //   "pagenumber":this.pagenumber,
    //   "pagination":false
    // }
    // this.db_service.getQueriedData(this.asanaVideo.trainerId, index, queryparams).subscribe(
    //   response => {
    //     // console.log('Data Retrived succesfully.', response);
    //     this.workouts = response.data;
    //     for (let i = 0; i < this.workouts.length; i++) {
    //       if (this.workouts[i]["workoutExercises"] != null) {
    //         var workoutExercises = this.workouts[i]["workoutExercises"];
    //         for (let k = 0; k < workoutExercises.length; k++) {
    //           if (workoutExercises[k]["exerciseId"] == id) {
    //             this.toastr.error('', 'This exercise is reffered in workouts, you can not delete this exercise.', {
    //               timeOut: 3000,
    //               positionClass: 'toast-top-right',
    //               progressBar: true,
    //               progressAnimation: 'increasing',
    //               tapToDismiss: true,
    //             });
    //             return;
    //           }
    //         }
    //       }
    //     }
    //     this.deleteEx(id);
    //   },
    //   error => {
    //     console.log('There is an error while retriving workouts.');
    //   })
    this.deleteEx(id);
  }
  disablePublic(exId) {
    var current_asna = this.asanas.find(x => x["asanaId"] == exId);
    current_asna["isPublic"] = false;
    var index = '/exercise'
    this.yoga_db_service.updateDatainDB(current_asna, index).subscribe(
      response => {
        this.getAsanaDataFromServer();
        this.toastr.success('', 'asana updated successfully.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      },
      error => {
        console.log('there is an error while updating exercise');
      }

    )
  }
  enablePublic() {
    this.getAsanaDataFromServer();
  }
  editAsana(id) {
    // console.log(id);
    this.router.navigate(['editAsanas', id]);
  }
  openVideo(youtubeUrl, url) {
    this.showVideoPopup = !this.showVideoPopup;
    if (youtubeUrl == false) {
      this.activeUrl = url;
      this.iframeHidden = false;
      this.videoHidden = true;
    } else {
      this.iframeHidden = true;
      this.videoHidden = false;
      this.iframeURL = this.embedService.embed(url, {
        query: { portrait: 0, color: 'black', autoplay: 1 },
        attr: { width: "100%", height: 450, frameborder: 0, allow: "autoplay" }
      })
      if (this.iframeURL != undefined)
        this.embedYoutubeUrl = this.iframeURL;
    }
  }
  getAdminComments(id) {
    var asana = this.asanas.find(x => x["asanaId"] == id);
    this.adminComments = asana["approvalComments"];
    this.showAdminComments = true;
  }
  closePopup() {
    this.showVideoPopup = false;
    this.showExdeletePopup = false;
    this.showAdminComments = false;
    this.renderer.removeClass(document.body, 'modal-open');
  }

}
