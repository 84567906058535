<section class="desktop-version">
  <div class="container-fluid">
    <div class="col-10 mx-auto mt-3">

      <div class="row">
        <h1 class="heading1 col-md-6 col-sm-12"> <img src="{{imgPath}}" width="50" />
          <span *ngIf="workoutName !='Others'">{{workoutName}}</span>
          <span *ngIf="workoutName =='Others'">{{workout.customWorkoutName}}</span>
        </h1>
        <!-- Exercise Search box -->
        <div class="col-md-6">
          <div class="d-flex justify-content-end">
            <!-- <div class="search__box search__box-exercise search__box-exercise-closed d-flex">
                  <input type="text" name="search" placeholder="Search for a Video">
                  <a href="#" class="search__box__btn search__box__btn-exercise-open"><i class="fas fa-search"></i></a>
                  <a href="#" data-toggle="modal" data-target="#exerciseSerachFilters" class="search-filters">
                    <i class="fas fa-filter"></i>
                  </a>
                </div>
                <a href="add-workout.html" class="add__exercise__btn"><i class="fa fa-plus"></i></a> -->
          </div>
        </div>
        <!-- Exercise Search box /-->
      </div>



      <!-- Exercise Video -->
      <div class="row" *ngIf="addWorkoutFunc">
        <div class="col-md-7" dndDropzone (dndDrop)="onItemDrop($event)">
          <h2 *ngIf="editWorkout" class="sub_heading text-right" style="margin-bottom: 20px;">Rest Interval
            <a style="cursor:pointer" (click)="addRest()" class="add__exercise__btn"><i class="fa fa-plus"></i></a>
            <!-- <i style="cursor:pointer" (click)="addRest()"
              class="fas fa-plus"></i> -->
          </h2>

          <div class="add__rest add__workout__video ">
            <div class="add__rest_row add__rest_header">
              <div class="rep_move"></div>
              <div><b>Exercise Name</b></div>
              <div><b>Sets</b></div>
              <div><b>Rest Period (seconds)</b></div>
              <div></div>
            </div>
            <ul style="cursor:pointer" [sortablejs]="droppedItems"  [sortablejsOptions]="options">
              <div class="text-center col" *ngIf="droppedItems == undefined || droppedItems.length == 0">
                <div _ngcontent-c2="" class="sub_heading text-right ng-star-inserted py-5 my-5 col text-center"
                  style="margin-bottom: 20px;"> Drag excercises to add </div>
              </div>
              <li *ngFor="let item of droppedItems">
                <div class="add__rest_row">
                  <div class="rep_move"><i class="fas fa-grip-vertical" style="cursor: grabbing"></i></div>
                  <div class="rep__video">
                    <i class="fas fa-play" *ngIf="item.exerciseId !='rest_id'" (click)="openVideo(item.exerciseId)" data-toggle="modal" data-target="#contentModal"></i>
                    <i class="fas fa-stopwatch" *ngIf="item.exerciseId =='rest_id'"></i>
                    <span>&nbsp; {{item.exerciseName}}</span>
                  </div>
                  <div><input type="text" readonly [(ngModel)]="item.sets.length" *ngIf="item.exerciseId !='rest_id'"
                      name="sets"></div>
                  <div><input type="number" OnlyPositiveNumbers numericType="number" *ngIf="item.exerciseId =='rest_id'"
                      value="40 Sec" [(ngModel)]="item.restPeriodVal" name="restPeriodVal"></div>
                  <div>
                    <a class="delete__rest" (click)="RemoveExercise(item.referenceId)" *ngIf="editWorkout"><i
                        class="fas fa-trash-alt"></i></a>
                  </div>
                </div>
              </li>
            </ul>

          </div>

          <div class="d-flex col-12 justify-content-end">
            <button type="button" class="btn btn-default" style="margin-top: 10px;"
              (click)="back('page2')">Back</button>
            <button type="button" class="btn btn-default" *ngIf="viewWorkout" (click)="edit()"
              style="margin-top: 10px;">Edit</button>
            <button type="button" class="btn btn-default" *ngIf="editWorkout" (click)="SaveWorkout()"
              style="margin-top: 10px;">Save</button>
          </div>


        </div>

        <div class="col-md-5" *ngIf="viewWorkout">

          <div style="margin-bottom: 20px;">
            <video #viewworkLoad onloadedmetadata="this.muted=true" autoplay="" loop="" muted="" playsinline="" controls style="width: 100%;"
              *ngIf="videoHidden" class="videoInsert">
              <source attr.src="{{viewURL}}" type="video/mp4">
            </video>
            <div class="exercise-upload-video" [innerHtml]="embedYoutubeUrl" *ngIf="iframeHidden"></div>
          </div>

          <div>
            {{Instructions}}
          </div>

        </div>
        <div class="videos videos-exercise add__workout__video  col-md-5 row" *ngIf="editWorkout">
          <div class="col-sm-6 col-md-4" *ngFor="let ex of exercises.slice().reverse()">
            <a  [dndDraggable]="ex.exerciseId" style="cursor:pointer;">
              <video #viewworkLoad loop muted playsinline onmouseover="this.play()" onmouseout="this.load();" playsinline=""
                poster="{{ex.exerciseVideo.videoThumbnailPath}}" class="videoInsert">
                <source>
              </video>
            </a>
            <h1>{{ex.exerciseName}}</h1>
          </div>
        </div>
      </div>

      <!-- Exercise Video /-->

    </div>
    <ng-container *ngIf="showVideoPopup">
      <div class="modal fade " id="contentModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style="background: #000000ba;">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
        <div class="video-pop zoomIn modal-body background-shadow-green" style="border-radius: 12px;">
          <a (click)="closePopup()" class="close-video-pop float-right" style="cursor: pointer;">
            <i class="fas fa-times"></i></a>
          <video #viewworkLoad autoplay="" loop="" muted="" playsinline="" controls style="width: 100%;"
            *ngIf="videoHidden">
            <source attr.src="{{viewURL}}" type="video/mp4">
          </video>
          <div class="exercise-upload-video" [innerHtml]="embedYoutubeUrl" *ngIf="iframeHidden"></div>
        </div>
      </div>
      </div>
      </div>
      </ng-container>
    <div class="container" *ngIf="addWorkout">
      <div class="row">

        <div class="col-md-6">
          <div class="add__workout">

            <form class="">
              <div class="form-group col-sm-12">
                <ng-multiselect-dropdown class="form-control col-12"
                [placeholder]="'Workout Name'"
                [settings]="dropdownSettings_single" name="SelectedWorkoutNames"
                [data]="workoutNames"
                [(ngModel)]="SelectedWorkoutNames" [disabled]="workoutdisabled"
                (onSelect)="onItemSelect($event,'workoutName')" (onDeSelect)="onItemDeselect($event,'workoutName')"
              >
              </ng-multiselect-dropdown>
              </div>

              <div class="form-group col-sm-12" *ngIf="isWorkoutName">
                <input type="text" class="form-control" [(ngModel)]="workout.customWorkoutName" name="customWorkoutName"
                  required>
                <span class="floating-label">Custom Name*</span>
              </div>
              <!-- <div class="form-group col-sm-12">
                <ng-multiselect-dropdown class="form-control col-12" [data]="gender"
                [placeholder]="'Gender'" [(ngModel)]="selectedGender" name="selectedGender" [settings]="dropdownSettings_single"
                [disabled]="disabled">
              </ng-multiselect-dropdown>
              </div>
              <div class="form-group col-sm-12">
                <input type="text" class="form-control" [(ngModel)]="workout.age" name="age"
                  required>
                <span class="floating-label">age*</span>
              </div>
              <div class="form-group col-sm-12">
                <input type="text" class="form-control" [(ngModel)]="workout.weight" name="weight"
                  required>
                <span class="floating-label">Weight*</span>
              </div>
              <div class="form-group col-sm-12">
                <input type="text" class="form-control" [(ngModel)]="workout.bmiIdeal" name="bmiIdeal"
                  required>
                <span class="floating-label">Ideal BMI*</span>
              </div> -->
              <div class="form-group col-sm-12">
                <textarea rows="5" class="add-instruction-text" id="instructions" name="instructions"
                  placeholder="Description" [(ngModel)]="workout.warnings"
                  style="background: rgba(255, 255, 255, 0.2); color: #fff;"></textarea>
              </div>
            </form>

            <div class="d-flex col-12 justify-content-between">
              <button type="button" class="btn btn-circle prevBtn" style="margin-top: 10px;" (click)="back('page1')">
                <i class="fas fa-angle-left"></i>
              </button>
              <button type="button" class="btn btn-circle nextBtn" style="margin-top: 10px;" (click)="SaveWorkout()">
                <i class="fas fa-angle-right"></i>
              </button>
            </div>
            <!-- <div class="trainer-profile-buttons d-flex justify-content-between">
                    <button type="button" class="btn btn-circle prevBtn" (click)="downProgressBar()">
                      <i class="fas fa-angle-left"></i>
                    </button>
                    <button type="button" class="btn btn-circle nextBtn" id="nextBtn" (click)="upProgressBar()">
                      <i class="fas fa-angle-right"></i>
                    </button>
                  </div> -->
          </div>
        </div>

        <div class="col-md-6">
          <div class="add__workout_icon-box m-auto"><img src="{{imgPath}}" width="200" /></div>
        </div>
      </div>
    </div>
    <!-- isUpdateWorkout -->
    <ng-container *ngIf="isUpdateWorkout">
      <div class="program_event-popup__wrapper">
        <div class="program_event-pop zoomIn background-shadow-green" style="width:33% !important;">
          <div class="program_event-header">
            <h4>Do you want to apply these changes in dependent programs
            </h4>
          </div>

          <div class="program_event-body d-flex justify-content-center">

            <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
              (click)="onUpdateWorkout('No')">No</button>

            <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
              (click)="onUpdateWorkout('Yes')">Yes</button>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- showExerciseRejectPopUp -->
    <ng-container *ngIf="showExerciseRejectPopUp">
      <div class="program_event-popup__wrapper">
        <div class="program_event-pop zoomIn background-shadow-green" style="width:33% !important;">
          <div class="program_event-header">
            <h4>Please update workout exercises which were rejected
            </h4>
          </div>

          <div class="program_event-body d-flex justify-content-center">

            <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
              (click)="closePopup()">Ok</button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</section>

<section class="mobile-version">
  <div class="container">
    <div class="text-center" style="font-size: 18px; font-weight: 600;"> This page works in web browser only </div>
  </div>

</section>
