import { Component, OnDestroy, OnInit, Input, ViewChild, Renderer2, ChangeDetectorRef } from '@angular/core';
import moment from 'moment';
import * as $ from 'jquery';
import { HttpClient } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { DBService } from '../service/db.service';
import { AWSService } from '../service/aws.service';
import { AppGlobals } from '../app.globals';
import { ToastrService } from 'ngx-toastr';
import { GooglePlaceDirective } from "ngx-google-places-autocomplete";
import { UserLoginService } from "../service/user-login.service";
import { DataService } from "./../service/data.service";
import { CognitoUtil } from '../service/cognito.service';
import {IMyDpOptions} from 'mydatepicker';
import { DatePipe } from '@angular/common';
import { YogaDBService } from '../service/YogaDBService';

export class profile {
  trainerId: string;
  trainerUserName: string;
  profileStatus: string;
  registraionType: string;
  adminStatus: string;
  creationDate: Date;
  gender: string;
  category: Object[];
  firstName: string;
  phoneNumber: string;
  lastName: string;
  trainerTimezone: string;
  address: Object[];
  trainerSocialAccounts: Object[];
  trainingSince: Object;
  serviceOffered: Object[];
  certification: Object[];
  isNutritionist: Object;
  about: string;
  achievements: Object[];
  specializedIn:Object[];
  profileImage: Object;
  profileIntroVideo: Object;
  lastUpdatedDate: Date;
  dateOfBirth: String;
}
export class yogaProfile {
  trainerId: string;
  trainerUserName: string;
  profileStatus: string;
  registraionType: string;
  adminStatus: string;
  creationDate: Date;
  gender: string;
  category: Object[];
  firstName: string;
  phoneNumber: string;
  lastName: string;
  trainerTimezone: string;
  address: Object[];
  trainerSocialAccounts: Object[];
  trainingSince: Object;
  serviceOffered: Object[];
  certification: Object[];
  isNutritionist: Object;
  about: string;
  achievements: Object[];
  profileImage: Object;
  profileIntroVideo: Object;
  specializedIn:Object[];
  lastUpdatedDate: Date;
  dateOfBirth: String;
}

export class IMyDateModel {
  date: IMyDate;
  jsdate: Date;
  formatted: string;
  epoc: number;
}

export class IMyDate {
  year: number;
  month: number;
  day: number;
}
@Component({
  selector: 'app-profilr',
  templateUrl: './profilr.component.html',
  styleUrls: ['./profilr.component.css']
})
export class ProfilrComponent implements OnInit, OnDestroy {
  @Input() yValue: number = 100;
  @Input() videoUrl: string = ""; // assets/videos/gym-video.mp4
  @Input() imgUrl: string = "assets/images/uploadimg.svg";
  @Input() yogaVideoUrl: string = ""; // assets/videos/gym-video.mp4
  @Input() yogaImgUrl: string = "assets/images/uploadimg.svg";
  @Input() thumbUrl: string = "assets/images/uploadvideo.svg";
  @ViewChild("placesRef") placesRef: GooglePlaceDirective;
  @ViewChild('videoPlay') videoPlay: any;
  @ViewChild('yogaVideoPlay') yogaVideoPlay: any;
  loaderHide: boolean = false;
  // isCertificate: boolean = false;
  isNutri: boolean = false;
  isYogaNutri: boolean = false;
  isServ: boolean = false;
  isYogaServ: boolean = false;
  isCert: boolean = false;
  isYogaCert: boolean = false;
  isImgUploaded: string = "NO";
  isYogaImgUploaded:string = "NO";
  fileExtension: any;
  googlePlacesOptions = {
    types: [],
    componentRestrictions: { country: ["US", "IN"] }
  };

  step: number = 1;

  ProgressMaleBody: boolean = false;
  ProgressFemaleBody: boolean = false;
  ProgressMaleYoga: boolean = false;
  ProgressFemaleYoga: boolean = false;
  ProgressMaleZumba: boolean = false;
  ProgressFemaleZumba: boolean = false;

  // City Dropdown setting
  cities: Array<string> = [];
  dropdownSettings: any = {};
  dropdownSettings_single: any = {};
  dropdownSettings_single_1: any = {};

  masters_cities: Object[];
  countries: Object[];
  states: Object[];

  closeDropdownSelection = false;
  disabled = false;
  Services: object[];
  TrainingSince: object[];
  Certificates: object[];
  SelectedTrainingSince: object[];
  SelectedCertificates: [];
  SelectedServices: [];
  SelectedNutrition: Object[];
  selectedServicesOffered: any;
  totalCertificatesSelected: any;


  Yoga_Services: object[];
  // Yoga_TrainingSince: object[];
  Yoga_Certificates: object[];
  Yoga_SelectedTrainingSince: object[];
  Yoga_SelectedCertificates: [];
  Yoga_SelectedServices: [];
  Yoga_SelectedNutrition: Object[];
  Yoga_selectedServicesOffered: any;
  Yoga_totalCertificatesSelected: any;

  speciliazedIn : object[];
  SelectedSpecializedlist : any;

  addressfields = {
    address: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    isPrimary: true,
    lat_logAttributes: {
      latitude: "",
      logitude: ""
    }
  }

  profileIntroVideo = {
    profileVideoName: "",
    videoThumbnailPath: "",
    videosourceUrl: "",
    videoDestinationCheck: "",
    videoMp4Destination: "",
    videoVerfied: false,
    verifiedOn: Date
    // videouploadedOn: new Date().toString()
  }

  yogaProfileIntroVideo = {
    profileVideoName: "",
    videoThumbnailPath: "",
    videosourceUrl: "",
    videoDestinationCheck: "",
    videoMp4Destination: "",
    videoVerfied: false,
    verifiedOn: Date
    // videouploadedOn: new Date().toString()
  }

  profileImage = {
    profileImageName: "",
    profileImagePath: "",
    profileVerfied: false,
    verifiedOn: Date
    // uploadedOn: new Date().toString()
  }

  yogaProfileImage = {
    profileImageName: "",
    profileImagePath: "",
    profileVerfied: false,
    verifiedOn: Date
    // uploadedOn: new Date().toString()
  }


  achievement = {
    achievement: ""
  }
  yoga_achievement = {
    achievement: ""
  }

  website = {
    socialAccount: "website",
    sourceUrl: "",
    status: "",
    verifiedOn: Date
  }
  facebook = {
    socialAccount: "facebook",
    sourceUrl: "",
    status: "",
    verifiedOn: Date
  }
  twitter = {
    socialAccount: "twitter",
    sourceUrl: "",
    status: "",
    verifiedOn: Date
  }
  instagram = {
    socialAccount: "instagram",
    sourceUrl: "",
    status: "",
    verifiedOn: Date
  }

  isNutritionist = {
    status: "",
    nutrionistFrom: "",
    certificateName: "",
    certificateDestinationUploadUrl: ""
  }

  isYogaNutritionist = {
    status: "",
    nutrionistFrom: "",
    certificateName: "",
    certificateDestinationUploadUrl: ""
  }

  certification = {
    certificateName: "",
    uploadedCertificateName: "",
    certifiedDate: Date,
    certificateDestinationUploadUrl: ""
  }

  yogaCertification = {
    certificateName: "",
    uploadedCertificateName: "",
    certifiedDate: Date,
    certificateDestinationUploadUrl: ""
  }

  serviceOffered = {
    serviceType: "",
    yearsActiveFrom: ""
  }



  progVal: number = 0;

  myDatePickerOptions: IMyDpOptions = {};

  constructor(public cognitoUtil: CognitoUtil, private renderer: Renderer2, private HttpClient: HttpClient, public router: Router, public userService: UserLoginService,
    public route: ActivatedRoute, private globals: AppGlobals, private db_service: DBService, private yoga_db_service: YogaDBService, private awsService: AWSService,
    private toastr: ToastrService, private data: DataService, private cdr: ChangeDetectorRef, public datepipe: DatePipe) {
    //this.userService.isAuthenticated(this);

  }
  profile: profile;
  yogaProfile:yogaProfile;
  selectedFile: File;
  profileResponse: any;
  private sub: any;
  cognitosUserId: any;
  logedInType: string;
  googleUserEmail: string;
  // Google Var
  private loggedIn: boolean;
  Nutritions: Object[];
  category: string;

  categorySelected: any = [];

  timezone: string;
  dateFormat:string;
  dateOfBirth: IMyDateModel;
  categoryObject: any = [];
  isFitness: boolean;
  isYoga: boolean;
  isZumba: boolean;

  ngOnInit() {
    this.speciliazedIn = [{"id":"0", "name":"Morning Yoga"},
    {"id":"1", "name":"Therapeutic"}, 
    {"id":"2", "name":"Immunity Booster"},
    {"id":"3", "name":"Fat Burning"},
    {"id":"4", "name":"Stress and Relax"}] 
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // this.videoPlay.nativeElement.muted = true;
    // this.videoPlay.nativeElement.play();
    this.profile = new profile();
    this.yogaProfile = new yogaProfile();
    this.data.currentMessage.subscribe(message => this.categorySelected = message)
    //console.log("category selected" + this.categorySelected);
    // here we are getting trainer timezone
    if (typeof Intl === 'object' && typeof Intl.DateTimeFormat === 'function') {
      // get timezone string from Intl object
      //  console.log(Intl.DateTimeFormat().resolvedOptions().timeZone)
      this.profile.trainerTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    this.userService.currentUserID.subscribe((val) => {
      this.cognitosUserId = val;
    });
    this.userService.logedInType.subscribe((val) => {
      this.profile.registraionType = val;
      // console.log("logedInType" + this.profile.registraionType)
    });
    var userType = this.cognitoUtil.getCurrentUser().getUsername();
    if (userType.includes('Google') || userType.includes('google')) {
      this.profile.registraionType = "Google";
    }
    else {
      this.profile.registraionType = "Cognito";
    }
    this.sub = this.route.params.subscribe(params => {
      this.profile.gender = params['genType'];
      this.profile.trainerUserName = params['username'];
      this.profile.trainerId = params['userID'];
      this.yogaProfile.trainerId = params['userID'];

      // checking for if subscription for gender page is empty if true redirecting to gender page
      if (this.categorySelected == undefined || this.categorySelected == null || this.categorySelected.name === "Array") {
        this.router.navigate(["gender", this.profile.trainerUserName, this.profile.trainerId]);
      }
      // if false get primary category
      // const index = this.categorySelected.findIndex(item => item.id == event.id);
      this.profile.category = this.categorySelected;
      for (let i = 0; i < this.categorySelected.length; i++) {
        if (this.categorySelected[i].primary == true) {
          this.category = this.categorySelected[i].category_name;
        }
      }
      if (this.profile.gender == 'male' && this.category == 'Bodybuilder') {
        this.ProgressMaleBody = true;
        this.renderer.removeClass(document.body, 'body-main');
        this.renderer.addClass(document.body, 'bg-img-build-male');
      }
      if (this.profile.gender == 'female' && this.category == 'Bodybuilder') {
        this.ProgressFemaleBody = true;
        this.renderer.removeClass(document.body, 'body-main');
        this.renderer.addClass(document.body, 'bg-img-build-female');
      }
      if (this.profile.gender == 'male' && this.category == 'Yoga') {
        this.ProgressMaleYoga = true;
        this.renderer.removeClass(document.body, 'body-main');
        this.renderer.addClass(document.body, 'bg-img-yoga-male');
      }
      if (this.profile.gender == 'female' && this.category == 'Yoga') {
        this.ProgressFemaleYoga = true;
        this.renderer.removeClass(document.body, 'body-main');
        this.renderer.addClass(document.body, 'bg-img-yoga-female');
      }
      if (this.profile.gender == 'male' && this.category == 'Zumba') {
        this.ProgressMaleZumba = true;
        this.renderer.removeClass(document.body, 'body-main');
        this.renderer.addClass(document.body, 'bg-img-zumba-male');
      }
      if (this.profile.gender == 'female' && this.category == 'Zumba') {
        this.ProgressFemaleZumba = true;
        this.renderer.removeClass(document.body, 'body-main');
        this.renderer.addClass(document.body, 'bg-img-zumba-female');
      }
      
      this.categoryObject = JSON.parse(localStorage.getItem("CategoryItems"));
      console.log("category local : " +this.categoryObject);
      for(var i = 0; i < this.categoryObject.length; i++) {
        if (this.categoryObject[i].category_name == 'Bodybuilder') {
            this.isFitness = true;
            // break;
        }
        if(this.categoryObject[i].category_name == "Yoga"){
            this.isYoga = true;
            // break;
        }
        if(this.categoryObject[i].category_name == "Zumba"){
            this.isZumba = true;
            // break;
        }
      }

      if(this.timezone.includes("asia") || this.timezone.includes("Asia")){
        this.myDatePickerOptions = {
          todayBtnTxt: 'Today',
          dateFormat: 'dd-mm-yyyy',
          firstDayOfWeek: 'mo',
          sunHighlight: true,
          openSelectorOnInputClick: true,
          editableDateField: false,
          inline: false
        }
        this.dateFormat = "dd-MM-yyyy";
      }else{
        this.myDatePickerOptions = {
          todayBtnTxt: 'Today',
          dateFormat: 'mm-dd-yyyy',
          firstDayOfWeek: 'mo',
          sunHighlight: true,
          openSelectorOnInputClick: true,
          editableDateField: false,
          inline: false
        }
        this.dateFormat = "MM-dd-yyyy";
      }
    });

    this.cities = ['Mumbai', 'New Delhi', 'Bangaluru', 'Pune', 'Navsari'];
    // this.selectedItem = ['Pune'];
    this.dropdownSettings = {
      singleSelection: false,
      enableCheckAll: false,
      idField: 'id',
      textField: 'name',
      allowSearchFilter: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      closeDropDownOnSelection: this.closeDropdownSelection
    };
    this.dropdownSettings_single = {
      singleSelection: true,
      enableCheckAll: false,
      idField: 'id',
      textField: 'name',
      allowSearchFilter: true,
      itemsShowLimit: 1,
      closeDropDownOnSelection: this.closeDropdownSelection
    };
    this.dropdownSettings_single_1 = {
      singleSelection: true,
      enableCheckAll: false,
      idField: 'id',
      textField: 'name',
      allowSearchFilter: false,
      itemsShowLimit: 1,
      closeDropDownOnSelection: this.closeDropdownSelection
    };
    this.Nutritions = [{ "id": "0", "name": "Yes" },
    { "id": "1", "name": "No" }]

    this.getAllMasters();
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
    this.renderer.removeClass(document.body, 'bg-img-build-male');
    this.renderer.removeClass(document.body, 'bg-img-yoga-male');
    this.renderer.removeClass(document.body, 'bg-img-zumba-male');
    this.renderer.removeClass(document.body, 'bg-img-build-female');
    this.renderer.removeClass(document.body, 'bg-img-yoga-female');
    this.renderer.removeClass(document.body, 'bg-img-zumba-female');
    this.renderer.addClass(document.body, 'body-main');

  }
  focusFunction() {
    $('#address').attr('autocomplete', 'new-password');
  }
  handleAddressChange(event) {
    var addressComponents = event["address_components"];

    for (let i = 0; i < addressComponents.length; i++) {
      if (addressComponents[i]["types"][0] == "postal_code") {
        this.addressfields.pincode = addressComponents[i]["long_name"];
      }
      if (addressComponents[i]["types"][0] == "country" || addressComponents[i]["types"][0] == "political") {
        this.addressfields.country = addressComponents[i]["long_name"];
      }
      if (addressComponents[i]["types"][0] == "administrative_area_level_1" || addressComponents[i]["types"][0] == "political") {
        this.addressfields.state = addressComponents[i]["long_name"];
      }
      if (addressComponents[i]["types"][0] == "locality") {
        this.addressfields.city = addressComponents[i]["long_name"];
      }
    }
    var formatted_address = event["formatted_address"];
    var spiltAddress = formatted_address.split(this.addressfields.city);
    var subAddress = "";
    subAddress = spiltAddress[0].slice(0, -2);

    this.addressfields.address = event.name + "," + subAddress;
    this.profile.address = Array.of(this.addressfields);
  }
  toggleCloseDropdownSelection() {
    this.closeDropdownSelection = !this.closeDropdownSelection;
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, { closeDropDownOnSelection: this.closeDropdownSelection });
  }
  getAllMasters() {
    var index = '/masters/';
    this.db_service.getDataFromServer('profile', index).subscribe(
      response => {
        // console.log('Data Retrived succesfully.', response);
        for (let i = 0; i < response.length; i++) {
          if (response[i]['name'] == "Services") {
            var values = response[i]['values'];
            for (let j = 0; j < values.length; j++) {
              for(var k = 0; k < this.categoryObject.length; k++) {
                // this.category = this.categoryObject[k].category_name;
              if (this.categoryObject[k].category_name == values[j]["Catagory"] && this.categoryObject[k].category_name == 'Bodybuilder') {
                if (this.Services == undefined) {
                  this.Services = Array.of(values[j]);
                  // console.log("services" + this.Services);
                } else {
                  this.Services.push(values[j]);
                  // console.log("services" + this.Services);
                }
              }



            if(this.categoryObject[k].category_name == values[j]["Catagory"] && this.categoryObject[k].category_name == "Yoga"){
              if (this.Yoga_Services == undefined) {
                this.Yoga_Services = Array.of(values[j]);
                // console.log("services" + this.Services);
              } else {
                this.Yoga_Services.push(values[j]);
                // console.log("services" + this.Services);
              }
            }
            if(this.categoryObject[k].category_name == values[j]["Catagory"] && this.categoryObject[k].category_name == "Zumba"){
              if (this.Yoga_Services == undefined) {
                this.Yoga_Services = Array.of(values[j]);
                // console.log("services" + this.Services);
              } else {
                this.Yoga_Services.push(values[j]);
                // console.log("services" + this.Services);
              }
            }

            }
            }
          }
          if (response[i]['name'] == "Certificates") {
            var values = response[i]['values'];
            for (let j = 0; j < values.length; j++) {
              for(var k = 0; k < this.categoryObject.length; k++) {
                // this.category = this.categoryObject[k].category_name;
              if (this.categoryObject[k].category_name == values[j]["Catagory"] && this.categoryObject[k].category_name == 'Bodybuilder') {
                if (this.Certificates == undefined) {
                  this.Certificates = Array.of(values[j]);
                  // console.log("services" + this.Services);
                } else {
                  this.Certificates.push(values[j]);
                  // console.log("services" + this.Services);
                }
              }

            if(this.categoryObject[k].category_name == values[j]["Catagory"] && this.categoryObject[k].category_name == "Yoga"){
              if (this.Yoga_Certificates == undefined) {
                this.Yoga_Certificates = Array.of(values[j]);
                // console.log("services" + this.Services);
              } else {
                this.Yoga_Certificates.push(values[j]);
                // console.log("services" + this.Services);
              }
            }
            if(this.categoryObject[k].category_name == values[j]["Catagory"] && this.categoryObject[k].category_name == "Zumba"){
              if (this.Yoga_Certificates == undefined) {
                this.Yoga_Certificates = Array.of(values[j]);
                // console.log("services" + this.Services);
              } else {
                this.Yoga_Certificates.push(values[j]);
                // console.log("services" + this.Services);
              }
            }

            }

            }
          }
          if (response[i]['name'] == "TrainingSince") {
            this.TrainingSince = response[i]['values'];
          }
        }
      },
      error => {
        console.log("An error has occurred while retriving masters data.");
      }
    )


    var index = '/masters/country';
    this.db_service.getDataFromServer('', index).subscribe(
      response => {
        // console.log('Data Retrived succesfully.', response);
        this.countries = response;
      },
      error => {
        console.log("An error has occurred while retriving masters data.");
      }
    )
  }

  onCountryChange(country_id) {
    var index = '/masters/country';
    this.db_service.getDataFromServer(country_id, index).subscribe(
      response => {
        //  console.log('Data Retrived succesfully.', response);
        this.states = response;
      },
      error => {
        console.log("An error has occurred while retriving masters data.");
      }
    )
  }

  onStateChange(state_id) {
    var index = '/masters/state';
    this.db_service.getDataFromServer(state_id, index).subscribe(
      response => {
        // console.log('Data Retrived succesfully.', response);
        this.masters_cities = response;
      },
      error => {
        console.log("An error has occurred while retriving masters data.");
      }
    )
  }
  SaveProfile() {
    var f_cat = this.categoryObject.find(c=>c["category_name"] == "Bodybuilder");
    var y_cat = this.categoryObject.find(c=>c["category_name"] == "Yoga");
    // var z_cat = this.categoryObject.find(c=>c["category_name"] == "Zumba");
    if(f_cat != undefined ){
      if (this.profileIntroVideo.profileVideoName == undefined || this.profileImage.profileImageName == undefined || this.profileIntroVideo.profileVideoName == '' || this.profileImage.profileImageName == '') {
        this.toastr.error('', 'Profile pic & Introduction video are mandatory.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        return;
      }

      this.profile.certification = [];
      this.profile.trainerSocialAccounts = [];
      this.profile.achievements = [];
      this.profile.serviceOffered = [];
      this.profile.profileIntroVideo = this.profileIntroVideo;
      this.profile.profileImage = this.profileImage;
      this.profile.trainingSince = this.SelectedTrainingSince[0];
      if (this.SelectedCertificates != undefined) {
        for (var i = 0; i < this.SelectedCertificates.length; i++) {
          this.certification.certificateName = this.SelectedCertificates[i]["name"];
          this.profile.certification.push(this.certification);
        }
      }
      if (this.SelectedServices != undefined) {
        for (let i = 0; i < this.selectedServicesOffered.length; i++) {
          this.serviceOffered.serviceType = this.selectedServicesOffered[i]["name"];
          this.serviceOffered.yearsActiveFrom = this.selectedServicesOffered[i]["yearsActiveFrom"];
          this.profile.serviceOffered.push(JSON.parse(JSON.stringify(this.serviceOffered)));
        }
      }
      if (this.SelectedCertificates != undefined) {
        this.profile.certification = this.totalCertificatesSelected;
      }
      // this.profile.serviceOffered = this.SelectedServices;

      this.profile.isNutritionist = this.isNutritionist;
      this.profile.profileStatus = "registered";
      // this.profile.registraionType= "Cognito";
      this.profile.adminStatus = "New";
      this.profile.address = Array.of(this.addressfields);

      this.profile.trainerSocialAccounts.push(this.website);
      this.profile.trainerSocialAccounts.push(this.facebook);
      this.profile.trainerSocialAccounts.push(this.twitter);
      this.profile.trainerSocialAccounts.push(this.instagram);
      this.profile.achievements.push(this.achievement);
      this.profile.specializedIn = [];
      this.profile.dateOfBirth = this.datepipe.transform(this.dateOfBirth.jsdate, "yyyy-MM-dd");
      this.profile.category = Array.of(f_cat);
    }
    if(y_cat != undefined ){
      if (this.yogaProfileIntroVideo.profileVideoName == undefined || this.yogaProfileImage.profileImageName == undefined || this.yogaProfileIntroVideo.profileVideoName == '' || this.yogaProfileImage.profileImageName == '') {
        this.toastr.error('', 'Profile pic & Introduction video are mandatory for yoga.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        return;
      }

      this.yogaProfile.certification = [];
      this.yogaProfile.trainerSocialAccounts = [];
      this.yogaProfile.achievements = [];
      this.yogaProfile.serviceOffered = [];
      this.yogaProfile.profileIntroVideo = this.yogaProfileIntroVideo;
      this.yogaProfile.profileImage = this.yogaProfileImage;
      this.yogaProfile.specializedIn = this.SelectedSpecializedlist;
      this.yogaProfile.trainingSince = this.Yoga_SelectedTrainingSince[0]; //santosh code
      if (this.Yoga_SelectedCertificates != undefined) {
        for (var i = 0; i < this.Yoga_SelectedCertificates.length; i++) {
          this.certification.certificateName = this.Yoga_SelectedCertificates[i]["name"];
          this.yogaProfile.certification.push(this.certification);
        }
      }
      if (this.Yoga_SelectedServices != undefined) {
        for (let i = 0; i < this.Yoga_selectedServicesOffered.length; i++) {
          this.serviceOffered.serviceType = this.Yoga_selectedServicesOffered[i]["name"];
          this.serviceOffered.yearsActiveFrom = this.Yoga_selectedServicesOffered[i]["yearsActiveFrom"];
          this.yogaProfile.serviceOffered.push(JSON.parse(JSON.stringify(this.serviceOffered)));
        }
      }
      if (this.Yoga_SelectedCertificates != undefined) {
        this.yogaProfile.certification = this.Yoga_totalCertificatesSelected;
      }
      // this.profile.serviceOffered = this.SelectedServices;

      this.yogaProfile.isNutritionist = this.isYogaNutritionist;
      this.yogaProfile.profileStatus = "registered";
      // this.profile.registraionType= "Cognito";
      this.yogaProfile.adminStatus = "New";
      this.yogaProfile.address = Array.of(this.addressfields);

      this.yogaProfile.trainerSocialAccounts.push(this.website);
      this.yogaProfile.trainerSocialAccounts.push(this.facebook);
      this.yogaProfile.trainerSocialAccounts.push(this.twitter);
      this.yogaProfile.trainerSocialAccounts.push(this.instagram);
      this.yogaProfile.achievements.push(this.yoga_achievement);
      this.yogaProfile.dateOfBirth = this.datepipe.transform(this.dateOfBirth.jsdate, "yyyy-MM-dd");
      this.yogaProfile.category = Array.of(y_cat);
      this.yogaProfile.firstName = this.profile.firstName;
      this.yogaProfile.gender = this.profile.gender;
      this.yogaProfile.lastName = this.profile.lastName;
      this.yogaProfile.phoneNumber = this.profile.phoneNumber;
      this.yogaProfile.registraionType = this.profile.registraionType;
      this.yogaProfile.trainerTimezone =  this.profile.trainerTimezone;
      this.yogaProfile.trainerUserName = this.profile.trainerUserName;
    }
     if(f_cat != undefined){
      this.loaderHide = !this.loaderHide;
      var index = '/profile'
      this.db_service.saveDataToDB(this.profile, index).subscribe(
        response => {
          this.yogaProfile.trainerId = response.data.trainerId;
          this.saveYogaProfile("fitness_Category");
          this.loaderHide = !this.loaderHide;
          //this.profileResponse = Array.of(response);
          if (this.googleUserEmail != null) {
            // this.authService.signOut();
            this.router.navigate(['']);
          }
          else {
            // this.router.navigate(['']);
            // this.userService.logout();
            this.logedInType = "Cognito";
            this.profile.trainerId;
            this.userService.broadcastLoginChange(this.profile.trainerId, this.profileImage.profileImagePath, this.logedInType)
            localStorage.setItem("ProfileCategory", this.category);
            localStorage.setItem("ProfileGender", this.profile.gender);
            this.router.navigate(['dashboard']);

          }
          // this.onGoogleLogout();
          let emailModel = {};
          let notificationModel = {}
          notificationModel['userId'] = "Admin",
          notificationModel['trainerId'] = response['data']['trainerId'];
          notificationModel['templateCode'] = "1o1_N_TPTA_012";
          notificationModel['notificationType'] = "Post";
          this.db_service.savenotification(notificationModel).subscribe(response =>{

          })
          emailModel['trainerId'] = response['data']['trainerId'];
          emailModel['templateCode'] = "1o1_TPC_011";
          this.db_service.sendEmail(emailModel).subscribe(response => {

          })
          notificationModel['userId'] = response['data']['trainerId'];
          notificationModel['templateCode'] = "1o1_N_TW_038"
          this.db_service.savenotification(notificationModel).subscribe(response =>{

          })
         
        },
        error => {
          // alert('there is an error while saving data');
          this.loaderHide = !this.loaderHide;
          this.toastr.error('', 'There is an error while saving data', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        }
      )
      }
      else{
        this.saveYogaProfile("yoga_Category");
      }
  }
  saveYogaProfile(category){
    var index = '/profile'
    this.yoga_db_service.saveDataToDB(this.yogaProfile, index).subscribe(
      response  => {
        this.loaderHide = !this.loaderHide;

        this.toastr.success('', 'Profile created successfully.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });

        if(category == "yoga_Category"){
          if (this.googleUserEmail != null) {
            // this.authService.signOut();
            this.router.navigate(['']);
          }
          else {
            // this.router.navigate(['']);
            // this.userService.logout();
            this.logedInType = "Cognito";
            this.yogaProfile.trainerId;
            this.userService.broadcastLoginChange(this.yogaProfile.trainerId, this.yogaProfileImage.profileImagePath, this.logedInType)
            localStorage.setItem("ProfileCategory", this.category);
            localStorage.setItem("ProfileGender", this.yogaProfile.gender);
            this.router.navigate(['dashboard']);
  
          }
          // this.onGoogleLogout();
          let emailModel = {};
          let notificationModel = {}
          notificationModel['userId'] = "Admin",
          notificationModel['trainerId'] = response['data']['trainerId'];
          notificationModel['templateCode'] = "1o1_N_TPTA_012";
          notificationModel['notificationType'] = "Post";
          this.db_service.savenotification(notificationModel).subscribe(response =>{
  
          })
          emailModel['trainerId'] = response['data']['trainerId'];
          emailModel['templateCode'] = "1o1_TPC_011";
          this.db_service.sendEmail(emailModel).subscribe(response => {
  
          })
          notificationModel['userId'] = response['data']['trainerId'];
          notificationModel['templateCode'] = "1o1_N_TW_038"
          this.db_service.savenotification(notificationModel).subscribe(response =>{
  
          })
        }
      });
     
  }
  upProgressBar() {
    let websiteRegex = new RegExp('^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/?[a-zA-Z0-9#]+\/?)*$');
    let regexpAlpha = new RegExp('^[A-Za-z ]*[A-Za-z][A-Za-z ]*$')
    let regexpNum = new RegExp('^(0|[1-9][0-9]*)$');
    let regexpPhoneNum = new RegExp('^((\\+91-?)|0)?[0-9]{10}$');
    if (this.step === 1 && (this.profile.firstName == null || this.profile.firstName == '' || this.profile.lastName == null || this.profile.lastName == '')) {
      this.toastr.error('', 'Mandatory fields are required', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (this.step === 1 && (this.profile.phoneNumber == null || this.dateOfBirth == null || this.profile.phoneNumber == undefined || this.dateOfBirth == undefined)) {
      this.toastr.error('', 'Mandatory fields are required', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (this.step === 1 && (regexpAlpha.test(this.profile.firstName) === false || regexpAlpha.test(this.profile.lastName) === false)) {
      this.toastr.error('', 'Please provide valid Information', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (this.step === 1 && regexpPhoneNum.test(this.profile.phoneNumber) === false) {
      this.toastr.error('', 'Please provide valid phone number', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (this.step === 2 && (this.addressfields.address == null || this.addressfields.city == null || this.addressfields.address == null || this.addressfields.address == '' || this.addressfields.city == '' || this.addressfields.pincode == '')) {
      this.toastr.error('', 'Mandatory fields are required', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (this.step === 2 && (regexpAlpha.test(this.addressfields.city) === false || regexpAlpha.test(this.addressfields.state) === false || regexpAlpha.test(this.addressfields.country) === false || regexpNum.test(this.addressfields.pincode) === false)) {
      this.toastr.error('', 'Please provide valid Information', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (this.step === 3 && this.isFitness && (this.SelectedTrainingSince == undefined || this.SelectedServices == undefined || this.SelectedTrainingSince.length == 0 || this.SelectedServices.length == 0)) {
      this.toastr.error('', ' Mandatory fields are required in fitness', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (this.step === 3 && this.isYoga &&(this.Yoga_SelectedTrainingSince == undefined || this.Yoga_SelectedServices == undefined || this.Yoga_SelectedTrainingSince.length == 0 || this.Yoga_SelectedServices.length == 0)) {
      this.toastr.error('', ' Mandatory fields are required in yoga ', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (this.step === 3 && this.isFitness &&(this.SelectedServices.length != 0 && this.selectedServicesOffered.find(x => x["yearsActiveFrom"] == ''))) {

      this.toastr.error('', ' Please specify years active in services tab in fitness ', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });

    }
    else if (this.step === 3 && this.isYoga &&(this.Yoga_SelectedServices.length != 0 && this.Yoga_selectedServicesOffered.find(x => x["yearsActiveFrom"] == ''))) {

      this.toastr.error('', ' Please specify years active in services tab in yoga ', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });

    }
    else if (this.step === 3 && this.isFitness && this.SelectedCertificates != undefined && this.totalCertificatesSelected.find(x => x["uploadedCertificateName"] == '')) {

      this.toastr.error('', ' Selected certificates should be uploded in fitness', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });

    }
    else if (this.step === 3 && this.isYoga && this.Yoga_SelectedCertificates != undefined && this.Yoga_totalCertificatesSelected.find(x => x["uploadedCertificateName"] == '')) {

      this.toastr.error('', ' Selected certificates should be uploded in yoga', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });

    }
    else if (this.step === 3 && this.isFitness && this.isNutritionist.status == 'Yes' && (this.isNutritionist.certificateName == "" || this.isNutritionist.nutrionistFrom == '')) {
      if (this.isNutritionist.certificateName == "") {
        this.toastr.error('', ' Please upload certificate for nutritionist in fitness ', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      }
      else if (this.step === 3 && this.isYoga && this.isYogaNutritionist.status == 'Yes' && (this.isYogaNutritionist.certificateName == "" || this.isYogaNutritionist.nutrionistFrom == '')) {
        if (this.isYogaNutritionist.certificateName == "") {
          this.toastr.error('', ' Please upload certificate for nutritionist in yoga ', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        }
      }
      else if (this.isNutritionist.nutrionistFrom == "" && this.isFitness) {
        this.toastr.error('', ' Please specify years active as nutritionist', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      }
      else if (this.isYogaNutritionist.nutrionistFrom == "" && this.isYoga) {
        this.toastr.error('', ' Please specify years active as nutritionist', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      }

    }
    // || this.profile.about.length < 20
    else if (this.step === 4 && this.isFitness &&(this.profile.about == null || this.profile.about == '')) {
      // console.log(this.profile.about.length);
      this.toastr.error('', 'Mandatory field is required in fitness', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (this.step === 4 && this.isYoga &&(this.yogaProfile.about == null || this.yogaProfile.about == '')) {
      // console.log(this.profile.about.length);
      this.toastr.error('', 'Mandatory field is required in yoga', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (this.step === 4 && this.isFitness && this.profile.about.length < 20) {
      this.toastr.error('', 'About field should have minimum 20 characters in fitness', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (this.step === 4 && this.isYoga &&this.yogaProfile.about.length < 20) {
      this.toastr.error('', 'About field should have minimum 20 characters in yoga', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    // else if (this.step === 6){
    //   this.videoPlay.nativeElement.muted = true;
    // }
    // else if (this.step === 5 && this.website.sourceUrl != undefined && this.website.sourceUrl != null && this.website.sourceUrl != "" && websiteRegex.test(this.website.sourceUrl) === false) {
    //   this.toastr.error('', 'Please provide valid website url', {
    //     timeOut: 3000,
    //     positionClass: 'toast-top-right',
    //     progressBar: true,
    //     progressAnimation: 'increasing',
    //     tapToDismiss: true,
    //   });
    // }
    else {
      if(this.step == 1){
        var dob = moment(this.datepipe.transform(this.dateOfBirth.jsdate, "yyyy-MM-dd"), 'YYYY-MM-DD');
        var todayDate = moment(this.datepipe.transform(new Date(), "yyyy-MM-dd"), 'YYYY-MM-DD');
        var duration = moment.duration(todayDate.diff(dob));
        var years = duration.asYears();

        console.log(years);
        if(years < 16){
          this.toastr.error('', 'Minimum age should be 16 years.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
          return;
        }
        }

      // console.log(websiteRegex.test(this.website.sourceUrl));
      this.step = this.step + 1;
      this.yValue = this.yValue - 20;
      this.progVal = this.progVal + 20;
    }
  }
  downProgressBar() {
    this.step = this.step - 1;
    this.yValue = this.yValue + 20;
    this.progVal = this.progVal - 20;
  }
  isInArray(array, word) {
    return array.indexOf(word.toLowerCase()) > -1;
  }
  onServiceSelect(type) {
    if(type != "yoga"){
    this.selectedServicesOffered = this.SelectedServices;
    this.cdr.detectChanges();
    for (let i = 0; i < this.selectedServicesOffered.length; i++) {
      this.selectedServicesOffered[i]["yearsActiveFrom"] = "";
    }
    if(this.selectedServicesOffered.length != 0)
    this.isServ = true;
    else
    this.isServ = false;
  }else{
    this.Yoga_selectedServicesOffered = this.Yoga_SelectedServices;
    this.cdr.detectChanges();
    for (let i = 0; i < this.Yoga_selectedServicesOffered.length; i++) {
      this.Yoga_selectedServicesOffered[i]["yearsActiveFrom"] = "";
    }
    if(this.Yoga_selectedServicesOffered.length != 0)
    this.isYogaServ = true;
    else
    this.isYogaServ = false;
  }
  }
  onServiceDeselect(event, type) {
    // console.log("deslect event" + event);
    // const index = this.selectedServicesOffered.indexOf(event.id, 0);
    // const index = this.selectedServicesOffered.findIndex(event => event.id);
    if(type != "yoga"){
    const index = this.selectedServicesOffered.findIndex(item => item.id == event.id);
    if (index > -1) {
      this.selectedServicesOffered.splice(index, 1);
      this.cdr.detectChanges();
    }
    if(this.selectedServicesOffered.length == 0)
    this.isServ = false;
    else
    this.isServ = true;
  }else{
    const index = this.Yoga_selectedServicesOffered.findIndex(item => item.id == event.id);
    if (index > -1) {
      this.Yoga_selectedServicesOffered.splice(index, 1);
      this.cdr.detectChanges();
    }
    if(this.Yoga_selectedServicesOffered.length == 0)
    this.isYogaServ = false;
    else
    this.isYogaServ = true;
  }
    // this.selectedServicesOffered =  this.selectedServicesOffered.filter(obj => obj !== event);
  }

  selectFile(event, filetype, cer_name) {

    this.selectedFile = event.target.files[0];
    if(this.selectedFile.name.includes("+")){
      this.toastr.error('', "No special characters allowed like '+'.", {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return;
    }
    // var format = /[!@#$%^&*()_+\=\[\]{};':"\\|,<>\/?]/;
    // var fileCheck = format.test(this.selectedFile.name);

    this.fileExtension = this.selectedFile.name.split('.').pop();

    if (filetype == 'image') {
      // var allowedExtensions = ["jpg","jpeg","png","JPG","JPEG","JFIF","BMP","SVG"];
      // if (fileCheck == true) {
      //   this.toastr.error('', 'Special characters are not allowed in image file name.', {
      //     timeOut: 3000,
      //     positionClass: 'toast-top-right',
      //     progressBar: true,
      //     progressAnimation: 'increasing',
      //     tapToDismiss: true,
      //   });
      // } else {
      if (this.isInArray(this.globals.ImgallowedExtensions, this.fileExtension)) {
        if (this.profileImage.profileImageName != "") {
          var imageName = this.profileImage.profileImageName.replace(/ /g, "_");
          this.awsService.deleteFileFromS3(imageName, this.profile.trainerId, this.globals.DEST_BUCKET, this.globals.IMGFOLDER).subscribe(
            response => {
              this.fileUpload(filetype, null);
              //console.log('Image deleted successfully.', response);

            },
            error => {
              console.log('There is an error while deleting image.', error);

            })
        } else {
          this.fileUpload(filetype, null);
        }
      } else {
        this.toastr.error('', 'Please upload only images.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        // }
      }
    }
    else if (filetype == 'certificate') {
      // if (fileCheck == true) {
      //   this.toastr.error('', 'Special characters are not allowed in certificate file name.', {
      //     timeOut: 3000,
      //     positionClass: 'toast-top-right',
      //     progressBar: true,
      //     progressAnimation: 'increasing',
      //     tapToDismiss: true,
      //   });
      // } else {
      if (this.isInArray(this.globals.Cer_allowedExtensions, this.fileExtension)) {
        if (this.certification.certificateDestinationUploadUrl != "") {
          var certificateName = this.certification.uploadedCertificateName.replace(/ /g, "_");
          this.awsService.deleteFileFromS3(certificateName, this.profile.trainerId, this.globals.DEST_BUCKET, this.globals.CER_FOLDER).subscribe(
            response => {
              this.fileUpload(filetype, cer_name);
              //  console.log('Certificate deleted successfully.', response);

            },
            error => {
              console.log('There is an error while deleting certificate.', error);

            })
        } else {
          this.fileUpload(filetype, cer_name);
        }
      } else {
        this.toastr.error('', 'Please upload pdf format files.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        // }
      }
    }
    else if (filetype == 'nutrition_certificate') {
      // if (fileCheck == true) {
      //   this.toastr.error('', 'Special characters are not allowed in certificate file name.', {
      //     timeOut: 3000,
      //     positionClass: 'toast-top-right',
      //     progressBar: true,
      //     progressAnimation: 'increasing',
      //     tapToDismiss: true,
      //   });
      // } else {
      if (this.isInArray(this.globals.Cer_allowedExtensions, this.fileExtension)) {
        if (this.isNutritionist.certificateDestinationUploadUrl != "") {
          var certificateName = this.isNutritionist.certificateName.replace(/ /g, "_");
          this.awsService.deleteFileFromS3(certificateName, this.profile.trainerId, this.globals.DEST_BUCKET, this.globals.CER_FOLDER).subscribe(
            response => {
              this.fileUpload(filetype, null);
              //  console.log('Certificate deleted successfully.', response);

            },
            error => {
              console.log('There is an error while deleting certificate.', error);

            })
        } else {
          this.fileUpload(filetype, null);
        }
      } else {
        this.toastr.error('', 'Please upload pdf format files.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        // }
      }
    }
    else {
      // if (fileCheck == true) {
      //   this.toastr.error('', 'Special characters are not allowed in video file name.', {
      //     timeOut: 3000,
      //     positionClass: 'toast-top-right',
      //     progressBar: true,
      //     progressAnimation: 'increasing',
      //     tapToDismiss: true,
      //   });
      // } else {
      if (this.isInArray(this.globals.VideoallowedExtensions, this.fileExtension)) {

        if (this.profileIntroVideo.profileVideoName != "") {
          var videoName = this.profileIntroVideo.profileVideoName.replace(/ /g, "_");
          this.awsService.deleteFileFromS3(videoName, this.profile.trainerId, this.globals.DEST_BUCKET, this.globals.PROFILE_FOLDER).subscribe(
            response => {
              //this.fileUpload(filetype);
              // console.log('Video deleted successfully.', response);
              var assetId = this.profileIntroVideo.videoMp4Destination.split("/");
              this.awsService.deleteDestinationFolder(this.profile.trainerId, this.globals.DEST_BUCKET, this.globals.PROFILE_FOLDER, assetId[6]).subscribe(
                response => {
                  this.fileUpload(filetype, null);
                },
                error => {
                  console.log('There is an error deleting video from destination folder.', error);
                })

            },
            error => {
              console.log('There is an error deleting video from source folder.', error);
            })
        } else {
          this.fileUpload(filetype, null);
        }

      } else {
        this.toastr.error('', 'Please upload only videos with mp4 format.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        // }
      }
    }
  }
  fileUpload(filetype, cer_name) {
    this.loaderHide = !this.loaderHide;

    if (filetype == 'image') {
      this.profileImage.profileImageName = this.selectedFile.name;
      this.awsService.uploadFileToS3(this.selectedFile, this.profile.trainerId, this.globals.DEST_BUCKET, this.globals.IMGFOLDER, filetype).subscribe(
        response => {
          // var path = response.Key;
          // var splittedpath = path.split("/");
          this.profileImage.profileImagePath = this.globals.CLOUDFRONT_URL + response.Key;
          this.imgUrl = this.profileImage.profileImagePath;
          // this.profileImage.uploadedOn = new Date().toString();
          this.loaderHide = !this.loaderHide;
          // console.log('Image uploaded successfully.', response);
          this.isImgUploaded = "YES";
          this.toastr.success('', 'Image uploaded successfully.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        },
        error => {
          console.log('There is an error uploading image.', error);
          // console.log('Image uploaded successfully.', response);
          this.loaderHide = !this.loaderHide;
          this.toastr.error('', 'There is an error uploading image.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        })
    }
    else if (filetype == 'certificate') {
      for (let i = 0; i < this.totalCertificatesSelected.length; i++) {
        if (this.totalCertificatesSelected[i]["name"] == cer_name) {
          this.totalCertificatesSelected[i]["uploadedCertificateName"] = this.selectedFile.name;
        }
      }
      // this.certification.uploadedCertificateName = this.selectedFile.name;
      this.awsService.uploadFileToS3(this.selectedFile, this.profile.trainerId, this.globals.DEST_BUCKET, this.globals.CER_FOLDER, filetype).subscribe(
        response => {
          for (let i = 0; i < this.totalCertificatesSelected.length; i++) {
            if (this.totalCertificatesSelected[i]["name"] == cer_name) {
              this.totalCertificatesSelected[i]["certificateDestinationUploadUrl"] = this.globals.CLOUDFRONT_URL + response.Key;
            }
          }
          //this.certification.certificateDestinationUploadUrl = response.Location;
          this.loaderHide = !this.loaderHide;
          //  console.log('Certificate uploaded successfully.', response);
          this.toastr.success('', 'Certificate uploaded successfully.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        },
        error => {
          console.log('There is an error uploading certificate.', error);
          // console.log('Image uploaded successfully.', response);
          this.loaderHide = !this.loaderHide;
          this.toastr.error('', 'There is an error uploading certificate.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        })
    } else if (filetype == 'nutrition_certificate') {
      this.isNutritionist.certificateName = this.selectedFile.name;
      this.awsService.uploadFileToS3(this.selectedFile, this.profile.trainerId, this.globals.DEST_BUCKET, this.globals.CER_FOLDER, filetype).subscribe(
        response => {
          this.isNutritionist.certificateDestinationUploadUrl = this.globals.CLOUDFRONT_URL + response.Key;
          this.loaderHide = !this.loaderHide;
          // console.log('Certificate uploaded successfully.', response);
          this.toastr.success('', 'Certificate uploaded successfully.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        },
        error => {
          console.log('There is an error uploading certificate.', error);
          // console.log('Image uploaded successfully.', response);
          this.loaderHide = !this.loaderHide;
          this.toastr.error('', 'There is an error uploading certificate.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        })
    } else {
      this.profileIntroVideo.profileVideoName = this.selectedFile.name;
      this.profileIntroVideo.videoDestinationCheck = "false";
      this.awsService.uploadFileToS3(this.selectedFile, this.profile.trainerId, this.globals.SORCE_BUCKET, this.globals.PROFILE_FOLDER, filetype).subscribe(
        response => {
          //this.profile.key = response.Key;
          this.profileIntroVideo.videosourceUrl = response.sourceUrl;
          this.profileIntroVideo.videoMp4Destination = response.mp4Destination;
          this.profileIntroVideo.videoThumbnailPath = response.thumbnailDestination;
          //this.profileIntroVideo.videouploadedOn = new Date().toString();
          this.thumbUrl = response.thumbnailDestination;
          this.videoUrl = response.sourceUrl;
          //  console.log('Video uploaded Successfully.', response);
          // alert("Video uploaded successfully.")
          this.loaderHide = !this.loaderHide;
          this.toastr.success('', 'Video uploaded successfully.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
          this.videoPlay.nativeElement.muted = true;
          this.videoPlay.nativeElement.play();
        },
        error => {
          console.log('There is an error uploading video.', error);
          this.loaderHide = !this.loaderHide;
          this.toastr.error('', 'There is an error uploading video.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        })
    }
  }

  onItemDeselect(event, type) {
    if(type != "yoga"){
    if (this.SelectedCertificates.length == 0) {
      // this.isCertificate = false;
      this.isCert = false;
    }
    else{
      this.isCert = true;
    }
    const index = this.totalCertificatesSelected.findIndex(item => item.id == event.id);
    if (index > -1) {
      this.totalCertificatesSelected.splice(index, 1);
      this.cdr.detectChanges();
    }
  }else{
    if (this.Yoga_SelectedCertificates.length == 0) {
      // this.isCertificate = false;
      this.isYogaCert = false;
    }
    else{
      this.isYogaCert = true;
    }
    const index = this.Yoga_totalCertificatesSelected.findIndex(item => item.id == event.id);
    if (index > -1) {
      this.Yoga_totalCertificatesSelected.splice(index, 1);
      this.cdr.detectChanges();
    }
  }
    
  }
  onItemSelect(type) {
    if(type != "yoga"){
    if (this.SelectedCertificates.length != 0) {
      this.totalCertificatesSelected = this.SelectedCertificates;
      for (let i = 0; i < this.totalCertificatesSelected.length; i++) {
        this.totalCertificatesSelected[i]["uploadedCertificateName"] = "";
        this.totalCertificatesSelected[i]["certifiedDate"] = Date;
        this.totalCertificatesSelected[i]["certificateDestinationUploadUrl"] = "";
      }
      // this.isCertificate = true;
      this.isCert = true;
    }
    else
    this.isCert = false;
  }else{
    if (this.Yoga_SelectedCertificates.length != 0) {
      this.Yoga_totalCertificatesSelected = this.Yoga_SelectedCertificates;
      for (let i = 0; i < this.Yoga_totalCertificatesSelected.length; i++) {
        this.Yoga_totalCertificatesSelected[i]["uploadedCertificateName"] = "";
        this.Yoga_totalCertificatesSelected[i]["certifiedDate"] = Date;
        this.Yoga_totalCertificatesSelected[i]["certificateDestinationUploadUrl"] = "";
      }
      // this.isCertificate = true;
      this.isYogaCert = true;
    }
    else
    this.isYogaCert = false;
  }

  }

  onNutritionSelect(type) {
    if(type != "yoga"){
    if (this.SelectedNutrition.length != 0) {
      this.isNutritionist.status = this.SelectedNutrition[0]["name"];
      if(this.SelectedNutrition[0]["name"] == "Yes")
      this.isNutri = true;
      else
      this.isNutri = false;
    }
  }else{
    if (this.Yoga_SelectedNutrition.length != 0) {
      this.isYogaNutritionist.status = this.Yoga_SelectedNutrition[0]["name"];
      if(this.Yoga_SelectedNutrition[0]["name"] == "Yes")
      this.isYogaNutri = true;
      else
      this.isYogaNutri = false;
    }
  }
  }

  onNutriDeselect(event, type){
    if(type != "yoga"){
    if(this.SelectedNutrition.length == 0)
    this.isNutri = false;
    }else{
      if(this.Yoga_SelectedNutrition.length == 0)
      this.isYogaNutri = false;
    }
  }

  onYogaFileSelect(event, filetype, cer_name) {

    this.selectedFile = event.target.files[0];
    if(this.selectedFile.name.includes("+")){
      this.toastr.error('', "No special characters allowed like '+'.", {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return;
    }
   

    this.fileExtension = this.selectedFile.name.split('.').pop();

    if (filetype == 'yoga_image') {
    
      if (this.isInArray(this.globals.ImgallowedExtensions, this.fileExtension)) {
        if (this.yogaProfileImage.profileImageName != "") {
          var imageName = this.yogaProfileImage.profileImageName.replace(/ /g, "_");
          this.awsService.deleteFileFromS3(imageName, this.profile.trainerId, this.globals.DEST_BUCKET, this.globals.IMGFOLDER).subscribe(
            response => {
              this.onFileUpload(filetype, null);

            },
            error => {
              console.log('There is an error while deleting image.', error);

            })
        } else {
          this.onFileUpload(filetype, null);
        }
      } else {
        this.toastr.error('', 'Please upload only images.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
       
      }
    }
    else if (filetype == 'yoga_certificate') {
     
      if (this.isInArray(this.globals.Cer_allowedExtensions, this.fileExtension)) {
        if (this.yogaCertification.certificateDestinationUploadUrl != "") {
          var certificateName = this.yogaCertification.uploadedCertificateName.replace(/ /g, "_");
          this.awsService.deleteFileFromS3(certificateName, this.profile.trainerId, this.globals.DEST_BUCKET, this.globals.CER_FOLDER).subscribe(
            response => {
              this.onFileUpload(filetype, cer_name);
            
            },
            error => {
              console.log('There is an error while deleting certificate.', error);

            })
        } else {
          this.onFileUpload(filetype, cer_name);
        }
      } else {
        this.toastr.error('', 'Please upload pdf format files.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      }
    }
    else if (filetype == 'yoga_nutrition_certificate') {
      if (this.isInArray(this.globals.Cer_allowedExtensions, this.fileExtension)) {
        if (this.isYogaNutritionist.certificateDestinationUploadUrl != "") {
          var certificateName = this.isYogaNutritionist.certificateName.replace(/ /g, "_");
          this.awsService.deleteFileFromS3(certificateName, this.profile.trainerId, this.globals.DEST_BUCKET, this.globals.CER_FOLDER).subscribe(
            response => {
              this.onFileUpload(filetype, null);
            },
            error => {
              console.log('There is an error while deleting certificate.', error);

            })
        } else {
          this.onFileUpload(filetype, null);
        }
      } else {
        this.toastr.error('', 'Please upload pdf format files.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        // }
      }
    }
    else {
      
      if (this.isInArray(this.globals.VideoallowedExtensions, this.fileExtension)) {

        if (this.yogaProfileIntroVideo.profileVideoName != "") {
          var videoName = this.yogaProfileIntroVideo.profileVideoName.replace(/ /g, "_");
          this.awsService.deleteFileFromS3(videoName, this.profile.trainerId, this.globals.DEST_BUCKET, this.globals.PROFILE_FOLDER).subscribe(
            response => {
              //this.fileUpload(filetype);
              // console.log('Video deleted successfully.', response);
              var assetId = this.yogaProfileIntroVideo.videoMp4Destination.split("/");
              this.awsService.deleteDestinationFolder(this.profile.trainerId, this.globals.DEST_BUCKET, this.globals.PROFILE_FOLDER, assetId[6]).subscribe(
                response => {
                  this.onFileUpload(filetype, null);
                },
                error => {
                  console.log('There is an error deleting video from destination folder.', error);
                })

            },
            error => {
              console.log('There is an error deleting video from source folder.', error);
            })
        } else {
          this.onFileUpload(filetype, null);
        }

      } else {
        this.toastr.error('', 'Please upload only videos with mp4 format.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
       
      }
    }
  }

  onFileUpload(filetype, cer_name) {
    this.loaderHide = !this.loaderHide;

    if (filetype == 'yoga_image') {
      this.yogaProfileImage.profileImageName = this.selectedFile.name;
      this.awsService.uploadFileToS3(this.selectedFile, this.profile.trainerId, this.globals.DEST_BUCKET, this.globals.IMGFOLDER, filetype).subscribe(
        response => {
          // var path = response.Key;
          // var splittedpath = path.split("/");
          this.yogaProfileImage.profileImagePath = this.globals.CLOUDFRONT_URL + response.Key;
          this.yogaImgUrl = this.yogaProfileImage.profileImagePath;
          // this.profileImage.uploadedOn = new Date().toString();
          this.loaderHide = !this.loaderHide;
          // console.log('Image uploaded successfully.', response);
          this.isImgUploaded = "YES";
          this.toastr.success('', 'Image uploaded successfully.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        },
        error => {
          console.log('There is an error uploading image.', error);
          // console.log('Image uploaded successfully.', response);
          this.loaderHide = !this.loaderHide;
          this.toastr.error('', 'There is an error uploading image.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        })
    }
    else if (filetype == 'yoga_certificate') {
      for (let i = 0; i < this.Yoga_totalCertificatesSelected.length; i++) {
        if (this.Yoga_totalCertificatesSelected[i]["name"] == cer_name) {
          this.Yoga_totalCertificatesSelected[i]["uploadedCertificateName"] = this.selectedFile.name;
        }
      }
      // this.certification.uploadedCertificateName = this.selectedFile.name;
      this.awsService.uploadFileToS3(this.selectedFile, this.profile.trainerId, this.globals.DEST_BUCKET, this.globals.CER_FOLDER, filetype).subscribe(
        response => {
          for (let i = 0; i < this.Yoga_totalCertificatesSelected.length; i++) {
            if (this.Yoga_totalCertificatesSelected[i]["name"] == cer_name) {
              this.Yoga_totalCertificatesSelected[i]["certificateDestinationUploadUrl"] = this.globals.CLOUDFRONT_URL + response.Key;
            }
          }
          //this.certification.certificateDestinationUploadUrl = response.Location;
          this.loaderHide = !this.loaderHide;
          //  console.log('Certificate uploaded successfully.', response);
          this.toastr.success('', 'Certificate uploaded successfully.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        },
        error => {
          console.log('There is an error uploading certificate.', error);
          // console.log('Image uploaded successfully.', response);
          this.loaderHide = !this.loaderHide;
          this.toastr.error('', 'There is an error uploading certificate.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        })
    } else if (filetype == 'yoga_nutrition_certificate') {
      this.isYogaNutritionist.certificateName = this.selectedFile.name;
      this.awsService.uploadFileToS3(this.selectedFile, this.profile.trainerId, this.globals.DEST_BUCKET, this.globals.CER_FOLDER, filetype).subscribe(
        response => {
          this.isYogaNutritionist.certificateDestinationUploadUrl = this.globals.CLOUDFRONT_URL + response.Key;
          this.loaderHide = !this.loaderHide;
          // console.log('Certificate uploaded successfully.', response);
          this.toastr.success('', 'Certificate uploaded successfully.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        },
        error => {
          console.log('There is an error uploading certificate.', error);
          // console.log('Image uploaded successfully.', response);
          this.loaderHide = !this.loaderHide;
          this.toastr.error('', 'There is an error uploading certificate.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        })
    } else {
      this.yogaProfileIntroVideo.profileVideoName = this.selectedFile.name;
      this.yogaProfileIntroVideo.videoDestinationCheck = "false";
      this.awsService.uploadFileToS3(this.selectedFile, this.profile.trainerId, this.globals.SORCE_BUCKET, this.globals.PROFILE_FOLDER, filetype).subscribe(
        response => {
          //this.profile.key = response.Key;
          this.yogaProfileIntroVideo.videosourceUrl = response.sourceUrl;
          this.yogaProfileIntroVideo.videoMp4Destination = response.mp4Destination;
          this.yogaProfileIntroVideo.videoThumbnailPath = response.thumbnailDestination;
          //this.profileIntroVideo.videouploadedOn = new Date().toString();
          this.thumbUrl = response.thumbnailDestination;
          this.yogaVideoUrl = response.sourceUrl;
          //  console.log('Video uploaded Successfully.', response);
          // alert("Video uploaded successfully.")
          this.loaderHide = !this.loaderHide;
          this.toastr.success('', 'Video uploaded successfully.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
          this.yogaVideoPlay.nativeElement.muted = true;
          this.yogaVideoPlay.nativeElement.play();
        },
        error => {
          console.log('There is an error uploading video.', error);
          this.loaderHide = !this.loaderHide;
          this.toastr.error('', 'There is an error uploading video.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        })
    }
  }
  limitInputLength(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.value.length > 3) {
      // Prevent character from being entered if it exceeds limit
      event.preventDefault();
      input.value = input.value.slice(0, 3);
    }
  }


}
