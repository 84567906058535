<section class="mt-100 mb-40">
  <div class="container-fluid">
    <h1 class="heading1 text-center"> <span>Dashboard</span></h1>

    <div class="row justify-content-md-center">

      <div class="category-items">

        <a routerLink="/exercise" *ngIf="currentCategory=='Bodybuilder'">
          <!-- <img src="assets/images/exerciseimg.png" width="200"  /> -->
          <img src="assets/images/dashboard/workouts.png"
            style="border-radius: 89%; border: 7px solid #8bc53f; height: 193px; width: 86%;" />
          <h4>Exercises</h4>
        </a>

        <a routerLink="/asanas" *ngIf="currentCategory=='Yoga'">
          <!-- <img src="assets/images/exerciseimg.png" width="200"  /> -->
          <img src="assets/images/icons/asana-management.png"
            style="border-radius: 89%; border: 7px solid #8bc53f; height: 193px; width: 86%;" />
          <h4>Asanas</h4>
        </a>

        <a routerLink="/workout" *ngIf="currentCategory=='Bodybuilder'">
          <!-- <img src="assets/images/myprogrm.png" width="200"  /> -->
          <img src="assets/images/dashboard/exercis.png"
            style="border-radius: 89%; border: 7px solid #8bc53f; height: 193px; width: 86%;" />
          <h4>Workouts</h4>
        </a>

        <a routerLink="/program" *ngIf="currentCategory=='Bodybuilder'">
          <!-- <img src="assets/images/workoutimg.png" width="200" /> -->
          <img src="assets/images/dashboard/program.png"
            style="border-radius: 89%; border: 7px solid #8bc53f; height: 193px; width: 86%;" />
          <h4>Programs</h4>
        </a>
        <a routerLink="/yogaPrograms" *ngIf="currentCategory=='Yoga'">
          <!-- <img src="assets/images/workoutimg.png" width="200" /> -->
          <img src="assets/images/dashboard/program_yoga.png"
            style="border-radius: 89%; border: 7px solid #8bc53f; height: 193px; width: 86%;" />
          <h4>Programs</h4>
        </a>

        <a routerLink="/nutrition">
          <!-- <img src="assets/images/workoutimg.png" width="200" /> -->
          <img src="assets/images/dashboard/nutrition.png"
          style="border-radius: 89%; border: 7px solid #8bc53f; height: 193px; width: 86%;"  />
          <h4>Nutrition</h4>
        </a>

        <a routerLink="/reports">
          <!-- <img src="assets/images/workoutimg.png" width="200" /> -->
          <img src="assets/images/icons/reports.png"
            style="border-radius: 89%; border: 7px solid #8bc53f; height: 193px; width: 86%;" />
          <h4>Reports</h4>
        </a>

        <!-- <a routerLink="/mealplan" *ngIf="isNutritionist">
          <img src="assets/images/nutrition2.png"
            style="border-radius: 89%; border: 7px solid #8bc53f; height: 193px; width: 86%;" />
          <h4>Nutrition</h4>
        </a> -->
      </div>

    </div>
    <!-- isRejectedProfile -->
    <ng-container *ngIf="isRejectedProfile">
      <div class="program_event-popup__wrapper">
        <div class="program_event-pop zoomIn background-shadow-green" style="width:40% !important;">
          <div class="program_event-header">
            <h4>Your profile has rejected, please update your profile.</h4>
          </div>
          <a (click)="closePopup()" class="close-program_event-pop"><i class="fas fa-times"></i></a>
          <div class="program_event-body d-flex justify-content-center">

            <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
              (click)="closePopup()">Cancel</button>

            <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
              (click)="onUpdateProfile()">Update</button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</section>
