<section>
  <div class="container-fluid">
    <div class="fit-px-mt-5">
      <div class="row">
        <div class="heading1 col-md-8 col-sm-12 mb-4"> <img src="assets/images/dashboard/mealbowl.png" width="50" /> <span><b>Nutrition Management</b></span>
        </div>
        <!-- Exercise Search box -->
        <div class="col-md-4">
          <div class="d-flex justify-content-end">
            <div class="search__box search__box-exercise search__box-exercise-closed d-flex m-0 mx-2" style="cursor: pointer;">
              <input type="text" class="col" autocomplete="off" name="search" placeholder="Search for a Mealplan" [(ngModel)]="searchMeal">
              <a class="search__box__btn search__box__btn-exercise-open"><i class="fas fa-search"></i></a>
              <!-- <a data-toggle="modal" data-target="#exerciseSerachFilters" class="search-filters">
                 <i class="fas fa-filter"></i>
              </a> -->
            </div>
            <div title="Add Nutrition" class="add__exercise__btn" routerLink="/addNutrition"><i class="fa fa-plus"></i></div>
          </div>
        </div> 
        <!-- Exercise Search box /-->
      </div>


      <!-- <div class="row">
          <div class="col-md-2" *ngFor="let workout of workouts">
           <div class="workout_item"> <i class="fas fa-filter"></i></div>
            <h4 *ngIf="workout.workoutName !='Others'" (click)="editWorkout(workout.workoutId)">{{workout.workoutName}}</h4>
            <h4 *ngIf="workout.workoutName =='Others'" (click)="editWorkout(workout.workoutId)">{{workout.customWorkoutName}}</h4>
            <i class="fa fa-trash" (click)="deleteWorkout(workout.workoutId)"></i>
          </div>
        </div> -->
      <div class="d-flex">
        <div class="col-md-12 sub_heading mb-4" style="text-align: end;">Total Nutrition : {{myNutritionLength}}</div>
      </div>
      <div class="row justify-content-center" *ngIf="myNutritionLength == 0">
        <h1>Please click on '+' icon to add Nutrition</h1>
      </div>
      <div class="row">
        <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2 col-6 text-center mb-3 workout_item workoutmanagement-width" *ngFor="let meal of mealplans | filter:searchMeal">
          <img title="Nutrition" src="assets/images/dashboard/mealbowl.png" width="150" routerLink="/addNutrition/{{meal.mealId}}"/>

          <div class="col-md-12 px-1">
          <div class="row">
            <div class="word-ellipsis col-8" style="max-width: 66%; font-size: 600;">{{meal.mealName}}</div>
            <div class="col-4 px-1">
              <div class="row">
                  <a class="mx-1" style="color: red;">
                    <i title="Delete" class="fas fa-trash-alt"  (click)="deleteMeal(meal.mealId)" style="cursor: pointer;"></i>
                  </a>
                  <a placement="bottom" style="color: #8dc63f;" class="mx-1" ><i class="fa fa-clock-o" title="Updated on: {{meal.lastUpdatedOn | date}}"></i></a>
               </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="showMealDeletePopup">
      <div class="program_event-popup__wrapper">
        <div class="program_event-pop zoomIn background-shadow-green">
          <div class="program_event-header text-center">
            <h4>Are you sure</h4>
          </div>
          <a (click)="closePopup()" class="close-program_event-pop"><i class="fas fa-times"></i></a>
          <div class="program_event-body d-flex justify-content-center">
            <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
              (click)="onDeleteMeal()">Yes</button>
            <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
              (click)="closePopup()">No</button>
          </div>
        </div>
      </div>
    </ng-container>

  </div>
</section>
