import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpBackend } from "@angular/common/http";
import { AppGlobals } from "../app.globals";
import { sign } from 'crypto';
//import 'rxjs/add/observable/of';
// import { Observable } from "rxjs/Observable";

@Injectable({
  providedIn: 'root'
})
export class TraineeDBService {
  private httpClient: HttpClient;
  private apiUrl = 'https://api.zoom.us/v2';
  sdkKey = 'DQnY0VjMQ4e2cG035ZhIRw'
  sdkSecret= 'OyWEPlMp4UU7Lo7TA4gOrv1QiSiTTSM1'
  constructor(private globals: AppGlobals, handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
   }
  // headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');

  saveDataToDB(data, index): Observable<any> {
    return this.httpClient.post(this.globals.TRAINEE_SERVER_LINK + index, data);
  }

  getDataFromServer(id, index): Observable<any> {
    return this.httpClient.get(this.globals.TRAINEE_SERVER_LINK + index + id);
  }
  getData(data, index): Observable<any> {
    return this.httpClient.post(this.globals.TRAINEE_SERVER_LINK + index, data);
  }
  getQueriedData(id,index,queryParams):Observable<any>{
    return this.httpClient.get(this.globals.TRAINEE_SERVER_LINK + index + id,{params:queryParams});
  }

  getSignatureData(queryParams):Observable<any>{
    return this.httpClient.get(this.globals.ZOOM_CALL_INIT,{params:queryParams,responseType: 'text'});
  }

  getMeetingData():Observable<any>{
    return this.httpClient.get(this.globals.ZOOM_CALL_INIT);
  }

  createZoomMeeting(topic: string, startTime: string, duration: number): Observable<any> {
    // var jwtToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbkV4cCI6MTcxMjI1NjI1OCwiYXBwS2V5IjoiRFFuWTBWak1RNGUyY0cwMzVaaElSdyIsImV4cCI6MTcxMjI1NjI1OCwiaWF0IjoxNzEyMjQ5MDU4fQ.5mm1PZQDCFIZveV7CR4WJ-L-3UA1sT9I2132y5AVjFY'
    var jwtToken = this.generateToken()
    console.log(jwtToken)
    const body = {
      topic: topic,
      type: 2, // Scheduled meeting
      start_time: startTime,
      duration: duration,
      password:'k5M92G'
      // Add more parameters as needed
    };

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${jwtToken}`,
      'Content-Type': 'application/json'
    });

    return this.httpClient.post<any>(`${this.apiUrl}/users/me/meetings`, body, { headers });
  }

  createJWTtoken(){
    // const jwt = require('jsonwebtoken');

    //   const apiKey = this.sdkKey ;
    //   const apiSecret = this.sdkSecret;

    //   // Payload for JWT token
    //   const payload = {
    //     iss: apiKey, // API Key
    //     exp: Date.now() + (60 * 60 * 1000) // Expiration time in milliseconds (1 hour from now)
    //   };

    //   // Generate the JWT token
    //   const token = jwt.sign(payload, apiSecret);

    //   console.log('JWT Token:', token);
    //   return token
    

  }

  generateToken(): string {
    const header = this.createHeader();
    const payload = this.createPayload();
    const signature = this.createSignature(header, payload, this.sdkSecret);
    console.log(header,payload,signature)
    return `${header}.${payload}.${signature}`;
  }

  private createHeader(): string {
    const header = {
      alg: 'HS256',
      typ: 'JWT'
    };
    return this.base64Encode(JSON.stringify(header));
  }

  private createPayload(): string {
    const now = Math.floor(Date.now() / 1000);
    const exp = now;
    const payload = {
      iss: this.sdkKey,
      exp: exp
    };
    return this.base64Encode(JSON.stringify(payload));
  }

  private createSignature(header: string, payload: string, apiSecret: string): string {
    const signatureInput = `${header}.${payload}`;
    const signature = this.base64EncodeUrlSafe(this.sha256Hmac(signatureInput, apiSecret));
    return signature;
  }

  private base64Encode(input: string): string {
    return btoa(input);
  }

  private base64EncodeUrlSafe(input: string): string {
    return input.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
  }

  private sha256Hmac(input: string, key: string): string {
    // Implement your HMAC-SHA256 algorithm here
    // This is just a placeholder function
    return 'sha256-hmac-result';
  }

}
