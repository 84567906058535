import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { UserLoginService } from "../service/user-login.service";
import { CognitoUtil,LoggedInCallback } from "../service/cognito.service";

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {

  constructor(public router: Router, public cognitoUtil: CognitoUtil, public userService: UserLoginService) { 
    // this.userService.isAuthenticated(this);
  }

  ngOnInit() {
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    // if (!isLoggedIn) {
    //   this.router.navigate(['/privacy']);
    // }else{
    //   this.userService.logout();
    //   this.cognitoUtil.signOut();
    //   localStorage.clear();
    //   localStorage.removeItem("ProfileCategory");
    //   localStorage.removeItem("ProfileGender");
    // }
  }

}
