import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserLoginService } from "../service/user-login.service";
import { ChallengeParameters, CognitoUtil, CognitoCallback, LoggedInCallback, Callback } from "../service/cognito.service";
import { ToastrService } from 'ngx-toastr';
import { DBService } from '../service/db.service';
import { CognitoAuth } from 'amazon-cognito-auth-js';
import { environment } from "../../environments/environment";
import { UserParametersService } from "../service/user-parameters.service";

declare const gapi: any;
declare const AWS: any;
export class profile {
  trainerUserName: string;
  trainerId: string;
  profileStatus: string;
  registraionType: string;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements CognitoCallback, LoggedInCallback, OnInit {

  // Google Var
  public auth2: any;

  email: string;
  password: string;
  errorMessage: string;
  mfaStep = false;
  mfaData = {
    destination: '',
    callback: null
  };
  // cognito auth tokens
  authData: any;
  auth: any;
  session: any;

  show: boolean;
  showEye: boolean;
  showSplash: boolean;
  // @Output() getLoggedInEmail= new EventEmitter<any>();
  title: string;
  closeBtnName: string;
  userId: string;
  userName: string;
  currentUserPic: any;
  logedInType: string;
  // currentID : string;
  profile: profile;
  profileResponse: object;
  profileStatus: string;
  profileCategory: string;
  profileGender: string;
  profileRegistration: string;
  // signInHide: boolean = true;
  // forgetPassPageHide: boolean = false;

  public parameters: Array<Parameters> = [];
  public cognitoId: String;

  cat : any;

  loaderHide: boolean = false;
  constructor(private db_service: DBService,
    public router: Router,
    public userService: UserLoginService,
    private toastr: ToastrService,
    public awsService: CognitoUtil,
    public userParams: UserParametersService,) {
      this.show = false;
      this.showEye = false;
      this.showSplash = true;
    //console.log("LoginComponent constructor");
    if (!this.awsService.isAuthenticated) {
      console.log("Not authenticated")
    } else {
      console.log("Already authenticated")
    }
  }


  ngOnInit() {
    this.profile = new profile();
    this.errorMessage = null;
    // console.log("Checking if the user is already authenticated. If so, then redirect to the secure site");
    this.userService.isAuthenticated(this);

    // Google intialization
    this.auth = this.initCognitoSDK();
    const curUrl = window.location.href;
    this.auth.parseCognitoWebResponse(curUrl);

  }

  onLogin() {
    if (this.email == null || this.email === '' || this.password == null || this.password === '') {
      this.toastr.error('', 'Mandatory fields are required', {
        timeOut: 5000,
        extendedTimeOut: 1000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true
      });
    } else {
      this.loaderHide = !this.loaderHide;
      localStorage.clear();
      this.userService.authenticate(this.email, this.password, this);
      // this.getProfileDataFromServer();
    }
  }
  

  showPassword() {
    this.show = !this.show;
    this.showEye = !this.showEye;
    this.showSplash = !this.showSplash;
  }


  getProfileDataFromServer() {
    var index = '/profile/category/';
    this.db_service.getDataFromServer(this.email, index).subscribe(
      response => {
        // console.log('Data Retrived succesfully.', response);
        var responceSample = response;
        response = response.data[0];
        this.userId = response.trainerId;
        this.userName = response.trainerUserName;
        this.currentUserPic = response.profileImage["profileImagePath"];
        this.logedInType = response.registraionType;
        this.profileResponse = response;
        this.profileStatus = response.profileStatus;
        this.profileRegistration = response.registraionType;
        // var cat = response.category;
        // var cat;
        // for(let categoryVal = 0 ; categoryVal < responceSample.data.length; categoryVal++){
        //    cat = responceSample.data[categoryVal].category
        // }
        // for(let categoryVal = 0 ; categoryVal < responceSample.data.length; categoryVal++){
        //   console.log("category pushed : " +responceSample.data[categoryVal].category)
        //   this.cat.push(responceSample.data[categoryVal].category);
        // }

        this.cat = [];
        for(var i = 0 ; i < responceSample.data.length ; i++){
              this.cat.push(responceSample.data[i]["category"][0]);
        }
        localStorage.setItem("userCategory",JSON.stringify(this.cat));
        for (let i = 0; i < this.cat.length; i++) {
          if (this.cat[i]["primary"] == true) {
            this.profileCategory = this.cat[i]["category_name"];
          }
        }
        // this.profileCategory = response.category;
        this.profileGender = response.gender;
        // this.Status = response.profileStatus;
        if (this.profileStatus == "created") {
          // this.getProfileDataFromServer();
          this.router.navigate(['gender', this.email, this.userId]);
        } else {
          this.userService.broadcastLoginChange(this.userId, this.currentUserPic, this.logedInType);
          this.userService.broadcastUserCategories(JSON.stringify(this.cat));
          localStorage.setItem("ProfileCategory", this.profileCategory);
          localStorage.setItem("ProfileGender", this.profileGender);
          this.router.navigate(['dashboard']);
        }
      },
      error => {
        this.toastr.error('', "An error has occurred while retriving profile data.", {
          timeOut: 5000,
          extendedTimeOut: 1000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true
        });
        // alert();
      }
    )
  }

  cognitoCallback(message: string, result: any) {
    //console.log("login Result" + result);
    if (message != null) { //error
      this.errorMessage = message;
      // let userID = result.accessToken.payload.sub;
      // this.loaderHide = !this.loaderHide;
      this.loaderHide = !this.loaderHide;
      this.toastr.error('', message, {
        timeOut: 5000,
        extendedTimeOut: 1000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true
      });
      if (this.errorMessage === 'User is not confirmed.') {
        // console.log("redirecting");
        this.loaderHide = !this.loaderHide;
        this.router.navigate(['confirm', this.email]);
      } else if (this.errorMessage === 'User needs to set password.') {
        // console.log("redirecting to set new password");
      }
    }
    else {
      this.loaderHide = !this.loaderHide;
      let id = result.accessToken.payload.sub;
      this.userId = result.accessToken.payload.sub;
      var index = '/profile/category/';
      this.db_service.getDataFromServer(this.email, index).subscribe(
        response => {
          //console.log('Data Retrived succesfully.', response);
          var responceSample = response;
          response = response.data[0];

          if (response == undefined || response == null) {
            this.logedInType = "Cognito";
            this.userService.broadcastLoginChange(this.userId, this.currentUserPic, this.logedInType)
            this.userService.broadcastUserCategories(JSON.stringify(this.cat));
            this.router.navigate(['gender', this.email, this.userId]);
          }
          else if (response.profileStatus == undefined) {
            this.logedInType = "Cognito";
            this.userService.broadcastLoginChange(this.userId, this.currentUserPic, this.logedInType)
            this.userService.broadcastUserCategories(JSON.stringify(this.cat));
            this.router.navigate(['gender', this.email, this.userId]);
          }
          else {
            this.userId = response.trainerId;
            this.userName = response.trainerUserName;
            this.currentUserPic = response.profileImage["profileImagePath"];
            this.logedInType = response.registraionType;
            this.profileResponse = response;
            this.profileStatus = response.profileStatus;
            this.profileRegistration = response.registraionType;
            // for(let categoryVal = 0 ; categoryVal < response.data.length; categoryVal++){
            //   var cat = response.data[categoryVal].category;
            // }
            
            // this.profileCategory = response.category;
            // var cat;
            // for(let categoryVal = 0 ; categoryVal < responceSample.data.length; categoryVal++){
            //   cat = responceSample.data[categoryVal].category
            // }
            // for(let categoryVal = 0 ; categoryVal < responceSample.data.length; categoryVal++){
            //   console.log("category pushed : " +responceSample.data[categoryVal].category)
            //   this.cat.push(responceSample.data[categoryVal].category);
            // }
            // localStorage.setItem("userCategory",this.cat);
            this.cat = [];
            for(var i = 0 ; i < responceSample.data.length ; i++){
                  this.cat.push(responceSample.data[i]["category"][0]);
            }
            localStorage.setItem("userCategory",JSON.stringify(this.cat));
            for (let i = 0; i < this.cat.length; i++) {
              if (this.cat[i]["primary"] == true) {
                this.profileCategory = this.cat[i]["category_name"];
              }
            }
            this.profileGender = response.gender;
            this.logedInType = "Cognito";
            this.userService.broadcastLoginChange(this.userId, this.currentUserPic, this.logedInType)
            this.userService.broadcastUserCategories(JSON.stringify(this.cat));
            localStorage.setItem("ProfileCategory", this.profileCategory)
            localStorage.setItem("ProfileGender", this.profileGender)
            this.router.navigate(['dashboard']);
          }

          // this.Status = response.profileStatus;
          // if (this.profileStatus == "created") {
          //   // this.getProfileDataFromServer();
          //   this.router.navigate(['gender', this.email, this.userId]);
          // } else {
          //   this.userService.broadcastLoginChange(this.userId, this.currentUserPic,this.logedInType);
          //   localStorage.setItem("ProfileCategory", this.profileCategory);
          //   localStorage.setItem("ProfileGender", this.profileGender);
          //   this.router.navigate(['dashboard']);
          // }
        },
        error => {
          this.toastr.error('', "An error has occurred while retriving profile data.", {
            timeOut: 5000,
            extendedTimeOut: 1000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true
          });
          // alert();
        }
      )
      // this.getProfile();



    }
  }

  // onConfirmRegistration() {
  //     this.loaderHide = !this.loaderHide;
  //     this.errorMessage = null;
  //     this.userRegistration.confirmRegistration(this.registrationUser.email, this.confirmationCode, this);
  // }

  handleMFAStep(challengeName: string, challengeParameters: ChallengeParameters, callback: (confirmationCode: string) => any): void {
    this.mfaStep = true;
    this.mfaData.destination = challengeParameters.CODE_DELIVERY_DESTINATION;
    this.mfaData.callback = (code: string) => {
      if (code == null || code.length === 0) {
        // this.errorMessage = "Code is required";
        this.toastr.warning('', 'Code is required', {
          timeOut: 3000,
          extendedTimeOut: 1000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true
        });
      }
      this.errorMessage = null;
      callback(code);
    };
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (isLoggedIn) {
      // this.router.navigate(['/securehome']);
      // console.log("is logged in is true");

    }
  }


  cancelMFA(): boolean {
    this.mfaStep = false;
    return false;   //necessary to prevent href navigation
  }
  // loginGoogle() {
  //   this.awsService.login();
  //   this.awsService.parseCognitoWebResponse(this.router.url);
  // }




  initCognitoSDK() {
    const authData = {
      ClientId: environment.clientId,
      AppWebDomain: environment.cognito_domain_name,
      TokenScopesArray: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      // RedirectUriSignIn: 'http://localhost:4200/login',
      // RedirectUriSignIn: 'https://dev.1o1.cloudns.asia/Trainers/login',
      RedirectUriSignIn: 'https://demo.1o1fitness.com/Trainers/login',
      // RedirectUriSignIn: 'https://uat.1o1fitness.com/Trainers/login',
      // RedirectUriSignIn: 'https://1o1fitness.com/Trainers/login',
      UserPoolId: environment.userPoolId,
      // RedirectUriSignOut: 'http://localhost:4200',
      // RedirectUriSignOut: 'https://dev.1o1.cloudns.asia/Trainers/',
      RedirectUriSignOut: 'https://demo.1o1fitness.com/Trainers/',
      // RedirectUriSignOut: 'https://uat.1o1fitness.com/Trainers/',
      // RedirectUriSignOut: 'https://1o1fitness.com/Trainers/',
      AdvancedSecurityDataCollectionFlag: false,
      IdentityProvider : 'Google', // e.g. 'Facebook',
    //   ResponseType: 'code',
    };

    const auth = new CognitoAuth(authData);

    auth.userhandler = {
      onSuccess: (result) => {
        // alert('Sign in success');
        console.log("sucess" +result);
        this.showSignedIn(result);
        this.ngOnInit();
      },
      onFailure: (err) => {
        // alert('Sign in failed');
        console.log("Sign in failed" +err);
      }
    };
    auth.useCodeGrantFlow();
    return auth;
  }

  loginGoogle() {
    this.auth.getSession();
    this.ngOnInit();
  }

  showSignedIn(session) {
    console.log('Session: ', session);
    this.awsService.signedIn(session);
    this.email = session.idToken.payload.email;
    this.userId = session.idToken.payload.sub;
    console.log("google email is " + this.email);
    if (this.email != null || this.email != undefined) {
      var index = '/profile/category/';
      this.db_service.getDataFromServer(this.email, index).subscribe(
        response => {
          console.log('Date Retrived succesfully.', response);
          var responceSample = response;
          response = response.data[0];
          // responceSAmple = respo
          if (response == undefined || response == null) {
            this.logedInType = "Google";
            this.userService.broadcastLoginChange(this.userId, this.currentUserPic, this.logedInType)
            this.userService.broadcastUserCategories(JSON.stringify(this.cat));

            this.router.navigate(['gender', this.email, this.userId]);
          }
          else if (response.registraionType == "Cognito" && response.trainerUserName == this.email) {
            this.toastr.error('', "User already registered", {
              timeOut: 5000,
              extendedTimeOut: 1000,
              positionClass: 'toast-top-right',
              progressBar: true,
              progressAnimation: 'increasing',
              tapToDismiss: true
            });
            this.auth.signOut();
          }
          else {
            this.userId = response.trainerId;
            this.userName = response.trainerUserName;
            this.currentUserPic = response.profileImage["profileImagePath"];
            this.logedInType = response.registraionType;
            this.profileResponse = response;
            this.profileStatus = response.profileStatus;
            this.profileRegistration = response.registraionType;
            // // var cat = response.category;
            
            // // var cat;
            // for(let categoryVal = 0 ; categoryVal < responceSample.data.length; categoryVal++){
            //   var cat = responceSample.data[categoryVal].category
            // }
            // // for(let categoryVal = 0 ; categoryVal < responceSample.data.length; categoryVal++){
            // //   console.log("category pushed : " +responceSample.data[categoryVal].category)
            // //   this.cat.push(responceSample.data[categoryVal].category);
            // // }
            // // localStorage.setItem("userCategory",this.cat);
            // for (let i = 0; i < cat.length; i++) {
            //   if (cat[i]["primary"] == true) {
            //     this.profileCategory = cat[i]["category_name"];
            //   }
            // }
            // var cat = response.category;
            
            // for(let categoryVal = 0 ; categoryVal < responceSample.data.length; categoryVal++){
            //   var cat = responceSample.data[categoryVal].category
            // }
            this.cat = [];
            for(var i = 0 ; i < responceSample.data.length ; i++){
                  this.cat.push(responceSample.data[i]["category"][0]);
            }
            localStorage.setItem("userCategory",JSON.stringify(this.cat));
            // this.profileCategory = response.category;
            this.profileGender = response.gender;
            for (let i = 0; i < this.cat.length; i++) {
              if (this.cat[i]["primary"] == true) {
                this.profileCategory = this.cat[i]["category_name"];
              }
            }
            // this.profileCategory = response.category;
            // this.profileGender = response.gender;
            // for (let i = 0; i < this.cat.length; i++) {
            //   if (this.cat[i]["primary"] == true) {
            //     this.profileCategory = this.cat[i]["category_name"];
            //   }
            // }
            this.logedInType = "Google";
            this.userService.broadcastLoginChange(this.userId, this.currentUserPic, this.logedInType)
            this.userService.broadcastUserCategories(JSON.stringify(this.cat));
            localStorage.setItem("ProfileCategory", this.profileCategory)
            localStorage.setItem("ProfileGender", this.profileGender)
            this.router.navigate(['dashboard']);
          }
        },
        error => {
          this.toastr.error('', "An error has occurred while retriving profile data.", {
            timeOut: 5000,
            extendedTimeOut: 1000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true
          });
          // alert();
        }
      )
    }
  }
  profileCategories :any ;
  getProfileCategories(){
    var index = "/profile/category/"
    this.db_service.getDataFromServer(this.userId,index).subscribe(response =>{
      this.profileCategories = [];
      for(var i = 0 ; i < response.data.length ; i++){
            this.profileCategories.push(response.data[i]["category"][0]);
      }
      console.log(this.profileCategories);
    })
  }
}


export class Parameters {
  name: string;
  value: string;
}

export class GetParametersCallback implements Callback {

  constructor(public me: LoginComponent, public cognitoUtil: CognitoUtil) {

  }

  callback() {

  }

  callbackWithParam(result: any) {

    for (let i = 0; i < result.length; i++) {
      let parameter = new Parameters();
      parameter.name = result[i].getName();
      parameter.value = result[i].getValue();
      this.me.parameters.push(parameter);
    }
    let param = new Parameters()
    param.name = "cognito ID";
    param.value = this.cognitoUtil.getCognitoIdentity();
    this.me.parameters.push(param)
  }
}
