 <div style="position: absolute; z-index: 900;">
  <img class="img-responsive" height="135" src="assets/images/logov1.gif" style="">
</div>

<div class="container-fluid" >
  <div class="row">
    <div class="col-md-10 p-2" #localVideo>

    </div>
    <div class="col-md-2 mt-2 p-0 rightside-video" >
      <div class="col-md-12 p-2 my-2 text-center font-weight" style="color:#82a43e; font-weight: 700; font-size: 21px;"> Trainees</div>
      <div class="col-md-12 p-2 my-3" id="remote" #remoteVideo>

      </div>
    </div>
  </div>
</div>
<div class="btn-group">
  <div class="col-md-2 mx-auto text-center">
    <div class="d-flex">
          <img class="video-image-background p-2" src="assets/images/mute.png" alt="mute" (click)="mute()" *ngIf="twilioService.microphone" style="width: 60px;
          height: 60px;">
          <img class="video-image-background p-2" src="assets/images/unmute.png" alt="unmute" (click)="unmute()" *ngIf="!twilioService.microphone" style="width: 60px;
          height: 60px;">
          <img class="video-image-background p-2" src="assets/images/video-call-dis.png" alt="video-call" (click)="onVideoEnable()" *ngIf="!twilioService.camera" style="width: 60px;
          height: 60px;">
          <img class="video-image-background p-2" src="assets/images/video-call-clr.png" alt="dis-video-call" (click)="onVideoDisable()" *ngIf="twilioService.camera" style="width: 60px;
          height: 60px;">
          <img class="video-image-background p-2" src="assets/images/end.png" alt="end-call" (click)="disconnect()" style="width: 58px;
          height: 58px;">
    </div>
  </div>
</div>
  <!-- <button *ngIf="twilioService.microphone" title="Mute" class="pr-2 btn btn-default save-exercise-btn" style="opacity: 1;cursor: pointer;"
(click)="mute()">Mute</button>
<button *ngIf="!twilioService.microphone" title="Unmute" class="pr-2 btn btn-default save-exercise-btn" style="opacity: 1;cursor: pointer;"
(click)="unmute()">Un Mute</button>
<button title="Disconnect" class="pr-2 btn btn-default save-exercise-btn" style="opacity: 1;cursor: pointer;" (click)="disconnect()"> Close</button> -->

