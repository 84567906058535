import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppGlobals } from "../app.globals";

@Injectable({
  providedIn: 'root'
})
export class TwilioDBService {
  constructor(private HttpClient: HttpClient, private globals: AppGlobals) { }
  // headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');

  createRoom(data, index): Observable<any> {
    return this.HttpClient.post(this.globals.TWILIO_SERVER_LINK + index, data);
  }
  updateDatainDB(data, index): Observable<any> {
    return this.HttpClient.put(this.globals.TWILIO_SERVER_LINK + index, data);
  }
  getData(data, index): Observable<any> {
    return this.HttpClient.post(this.globals.TWILIO_SERVER_LINK + index, data);
  }
  // getToken(id, index): Observable<any> {
  //   return this.HttpClient.get(this.globals.TWILIO_SERVER_LINK + index + id, { responseType: 'text' });
  // }
  getDataFromServer(id, index): Observable<any> {
    return this.HttpClient.get(this.globals.TWILIO_SERVER_LINK + index + id);
  }
  deleteRecordFromDB(id, index): Observable<any> {
    return this.HttpClient.post(this.globals.TWILIO_SERVER_LINK + index, id);
  }
  getToken(id,index,queryParams):Observable<any>{
    return this.HttpClient.get(this.globals.TWILIO_SERVER_LINK + index + id,{params:queryParams});
  }
}
