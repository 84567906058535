
import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Renderer2 } from "@angular/core";
// import { HttpService } from ""../../services/http.service";
import { Router, ActivatedRoute } from "@angular/router";
import { TwilioService } from "../service/twilio.service";
// import { BaCustomPreLoader } from ‘../../services/baCustomPreloader.service’;
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-twilli-video',
  templateUrl: './twilli-video.component.html',
  styleUrls: ['./twilli-video.component.css']
})
export class TwilliVideoComponent implements OnInit, OnDestroy {
  @ViewChild("localVideo", { static: false }) localVideo: ElementRef;
  @ViewChild("remoteVideo", { static: false }) remoteVideo: ElementRef;
  currentCategory: string;
  currentGender: string;
  private sub: any;
  room_name;
  access_tokan;
  app_id;
  constructor(
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    public twilioService: TwilioService) {
    // if (navigator.getUserMedia)
    //   navigator.getUserMedia({ audio: true, video: true }, function (stream) {
    //   }, function (error) {
    //     // console.log(error.name + ": " + error.message);
    //     this.toastr.error('', error.message, {
    //       timeOut: 5000,
    //       extendedTimeOut: 1000,
    //       positionClass: 'toast-top-right',
    //       progressBar: true,
    //       progressAnimation: 'decreasing',
    //       tapToDismiss: true
    //     });
    //   });
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia({ audio: true, video: true })
          .then(function (stream) {
            // Success callback
          })
          .catch(function (error) {
            // console.log(error.name + ": " + error.message);
            this.toastr.error('', error.message, {
              timeOut: 5000,
              extendedTimeOut: 1000,
              positionClass: 'toast-top-right',
              progressBar: true,
              progressAnimation: 'decreasing',
              tapToDismiss: true
            });
          });
      }
    else if (navigator.vendor.match(/[Aa]+pple/g) && navigator.vendor.match(/[Aa]+pple/g).length > 0)
      navigator.mediaDevices.getUserMedia({ audio: true, video: true })
        .then(function () { })
        .catch(function (error) {
          // console.log(error.name + ": " + error.message);
          this.toastr.error('', error.message, {
            timeOut: 5000,
            extendedTimeOut: 1000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'decreasing',
            tapToDismiss: true
          });
        });
    // this.baCustomPreLoader.show();
    this.route.params.subscribe(params => {
      this.app_id = params["id"];
      const body = {
        bookingId: this.app_id
      };
      // this.httpService.getData(‘/twilio/getToken’, body).subscribe(res => {
      // if (res.statusCode === 200) {
      // this.room_name = res.data.roomName;
      // this.access_tokan = res.data.accessToken;
      // this.connect();
      // }}, (error) => {
      // alert(error.message);
      // });
    });
    window.addEventListener("unload", () => {
      this.disconnect();
    })
  }
  ngOnInit() {
    this.currentCategory = localStorage.getItem("ProfileCategory")
    this.currentGender = localStorage.getItem("ProfileGender");
    if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-build-male');
    }
    else if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-build-female');
    }
    else if (this.currentCategory == 'Yoga' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-yoga-male');
    }
    else if (this.currentCategory == 'Yoga' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-yoga-female');
    }
    else if (this.currentCategory == 'Zumba' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-zumba-male');
    }
    else if (this.currentCategory == 'Zumba' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-zumba-female');
    }
    this.sub = this.route.params.subscribe(params => {
      this.room_name = params['roomName'];
      this.access_tokan = params['accessToken'];
    })
    this.connect();
    this.twilioService.localVideo = this.localVideo;
    this.twilioService.remoteVideo = this.remoteVideo;
  }
  disconnect() {
    if (this.twilioService.roomObj && this.twilioService.roomObj !== null) {
      this.twilioService.roomObj.disconnect();
      this.twilioService.roomObj = null;
    } else this.router.navigate(["callscheduler"]);
  }
  connect() {
    let accessToken = this.access_tokan;
    this.twilioService.connectToRoom(accessToken, {
      name: this.room_name,
      audio: true,
      video: { height: 720, frameRate: 24, width: 1280 },
      bandwidthProfile: {
        video: {
          mode: "collaboration",
          renderDimensions: {
            high: { height: 1080, width: 1980 },
            standard: { height: 720, width: 1280 },
            low: { height: 176, width: 144 }
          }
        }
      },
    })
  }
  mute() { this.twilioService.mute(); }
  unmute() { this.twilioService.unmute(); }
  onVideoEnable() { this.twilioService.videoEnable(); }
  onVideoDisable() { this.twilioService.videoDisable(); }

  ngOnDestroy() {
    this.disconnect();
    this.renderer.removeClass(document.body, 'bg-img-build-male');
    this.renderer.removeClass(document.body, 'bg-img-yoga-male');
    this.renderer.removeClass(document.body, 'bg-img-zumba-male');
    this.renderer.removeClass(document.body, 'bg-img-build-female');
    this.renderer.removeClass(document.body, 'bg-img-yoga-female');
    this.renderer.removeClass(document.body, 'bg-img-zumba-female');
    this.renderer.addClass(document.body, 'body-main');
  }
}
