<div id="mydiv" *ngIf="loaderHide">
  <img src="assets/images/landing_img.gif" width="10%" class="ajax-loader" />
</div>
<div class="container">
<div class="row">
  <div class="col-sm-12 col-md-6 col-lg-6 login__wrapper m-auto">
    <h1 class="text-center login-heading-style"><span>Confirmation</span></h1>
    <div class="login__form-section">
      <!-- <form class="" (ngsubmit) ="f.form.valid && onRegister()" #f="ngForm"> -->
      <form class="">
        <div class="confirm__fileds">
          <div class="form-group fit-my-4">
            <!-- <input type="text" id="confirmationCode" class="form-control col-12" style="border-width: 1px;" placeholder="Confirmation Code*"
               [(ngModel)]="confirmationCode" name="confirmationCode" required> -->
               <input type="text" id="confirmationCode" autocomplete="off" class="login-form-control col-12" style="border-width: 1px;"
              [(ngModel)]="confirmationCode" #Code="ngModel" name="confirmationCode" placeholder="Confirmation Code*" pattern="\d{6}">
            <!-- <span class="floating-label"><i class="fas fa-user"></i> Username</span> -->
            <i class="fas fa-check-circle login-fa-icon-style"></i>
            <div *ngIf="Code.invalid && (Code.dirty || Code.touched)" class="alert alert-danger">
              <!-- <div *ngIf="pincode.errors?.required">
                Pincode is mandatory
              </div> -->
              <div *ngIf="Code.errors?.pattern">
                Please enter 6 digit code sent to your email.
              </div>
            </div>
          </div>
        </div>
        <div class="text-center btn-margin">
            <button class="btn btn-default submitBtn" (click)="onConfirmRegistration()" id="confirmRegistration" type="submit">Confirm</button>
            <button class="btn btn-default submitBtn" (click)="resendCode()" id="signupSubmit" type="submit">Resend</button>
        </div>
      </form>
    </div>
  </div>
</div>
</div>
