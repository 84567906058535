import { Inject, Injectable } from "@angular/core";
import { CognitoCallback, CognitoUtil } from "./cognito.service";
import { AuthenticationDetails, CognitoUser, CognitoUserAttribute } from "amazon-cognito-identity-js";
import { RegisterComponent, RegistrationUser } from "../register/register.component";
import { AppGlobals } from "../app.globals"
//import {NewPasswordUser} from "../public/auth/newpassword/newpassword.component";
import * as AWS from "aws-sdk";

@Injectable()
export class UserRegistrationService {

    constructor(@Inject(CognitoUtil) public cognitoUtil: CognitoUtil, private globals:AppGlobals) {

    }

    register(user: RegistrationUser, callback: CognitoCallback): void {
        //  console.log("UserRegistrationService: user is " + user);

        let attributeList = [];

        let dataEmail = {
            Name: 'email',
            Value: user.email
        };
        let dataNickname = {
            Name: 'name',
            Value: user.name
        };
        let dataProfileStatus = {
            Name: 'custom:profileStatus',
            Value: user.profileStatus
        };
        attributeList.push(new CognitoUserAttribute(dataEmail));
        attributeList.push(new CognitoUserAttribute(dataNickname));
        attributeList.push(new CognitoUserAttribute(dataProfileStatus));
        // attributeList.push(new CognitoUserAttribute({
        //     Name: 'phone_number',
        //     Value: user.phone_number
        // }));

        this.cognitoUtil.getUserPool().signUp(user.email, user.password, attributeList, null, function (err, result) {
            if (err) {
                callback.cognitoCallback(err.message, null);
            } else {
                // console.log("UserRegistrationService: registered user is " + result);
                callback.cognitoCallback(null, result);
            }
        });


    }

    confirmRegistration(username: string, confirmationCode: string, callback: CognitoCallback): void {

        let userData = {
            Username: username,
            Pool: this.cognitoUtil.getUserPool()
        };
        console.log(userData,'----userData')
        let cognitoUser = new CognitoUser(userData);

        // // adding username to group
        // let params = {
        //     GroupName: 'trainerJwtTest', /* required */
        //     UserPoolId: 'us-east-1_SX1wuiOvn', /* required */
        //     Username: userData.Username /* required */
        //   };
        //   const cognitoIdentityService = new AWS.CognitoIdentityServiceProvider()

        //   cognitoIdentityService.adminAddUserToGroup(params, function(err, data) {
        //     if (err) console.log(err, err.stack); // an error occurred
        //     else     console.log( 'user added to group : '+data);           // successful response
        //   });


        // AWS.config.credentials
        cognitoUser.confirmRegistration(confirmationCode, true, function (err, result) {
            if (err) {
                console.log('ERRORR',err)
                callback.cognitoCallback(err.message, null);
            } else {
                callback.cognitoCallback(null, result);
                // adding username to group
                let params = {
                    GroupName: 'ADMIN_ROLE', /* required */
                    UserPoolId: 'ap-south-1_ch9xnLPFj', /* required */
                    Username: userData.Username /* required */
                };
                // let cognitoUserGroup = new CognitoUser(params);
                AWS.config.region = this.globals.region; // Region
                AWS.config.update({ accessKeyId: 'AKIAJER6MM4TJH3XZUNA', secretAccessKey: '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' });
                // AWS.config.update({ accessKeyId: this.globals.accessKeyId, secretAccessKey: this.globals.secretAccessKey });
                const cognitoIdentityService = new AWS.CognitoIdentityServiceProvider({ apiVersion: '2016-04-18' });
                cognitoIdentityService.adminAddUserToGroup(params, function (err, data) {
                    if (err) {
                        console.log('in err')
                        callback.cognitoCallback(err.message, null);// an error occurred
                    } else {
                        console.log('else')
                        callback.cognitoCallback(null, result);   // successful response
                        console.log('group' + data);
                    }
                });
            }
        });


    }

    resendCode(username: string, callback: CognitoCallback): void {
        let userData = {
            Username: username,
            Pool: this.cognitoUtil.getUserPool()
        };

        let cognitoUser = new CognitoUser(userData);

        cognitoUser.resendConfirmationCode(function (err, result) {
            if (err) {
                callback.cognitoCallback(err.message, null);
            } else {
                callback.cognitoCallback("sent", result);
            }
        });
    }

    newPassword(newPasswordUser: any, callback: CognitoCallback): void {
        // console.log(newPasswordUser);
        // Get these details and call
        //cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, this);
        let authenticationData = {
            Username: newPasswordUser.username,
            Password: newPasswordUser.existingPassword,
        };
        let authenticationDetails = new AuthenticationDetails(authenticationData);

        let userData = {
            Username: newPasswordUser.username,
            Pool: this.cognitoUtil.getUserPool()
        };

        //console.log("UserLoginService: Params set...Authenticating the user");
        let cognitoUser = new CognitoUser(userData);
        //  console.log("UserLoginService: config is " + AWS.config);
        cognitoUser.authenticateUser(authenticationDetails, {
            newPasswordRequired: function (userAttributes, requiredAttributes) {
                // User was signed up by an admin and must provide new
                // password and required attributes, if any, to complete
                // authentication.

                // the api doesn't accept this field back
                delete userAttributes.email_verified;
                cognitoUser.completeNewPasswordChallenge(newPasswordUser.password, requiredAttributes, {
                    onSuccess: function (result) {
                        callback.cognitoCallback(null, userAttributes);
                    },
                    onFailure: function (err) {
                        callback.cognitoCallback(err, null);
                    }
                });
            },
            onSuccess: function (result) {
                callback.cognitoCallback(null, result);
            },
            onFailure: function (err) {
                callback.cognitoCallback(err, null);
            }
        });
    }
}
