<div id="mydiv" *ngIf="loaderHide">
  <img src="assets/images/landing_img.png" width="10%" class="ajax-loader" />
</div>
<div class="container login-div-style text-center mb-3">
  <!-- <img _ngcontent-c1="" class="img-responsive mb-3" src="assets/images/logov1.gif" style="width:150px;"> -->
<div class="row">
  <div class="col-11 col-sm-12 col-md-6 col-lg-6 login__wrapper m-auto">
    <h1 class="text-center login-heading-style"><span>Resend code</span></h1>
    <div class="login__form-section">
      <!-- <form class="" (ngsubmit) ="f.form.valid && onRegister()" #f="ngForm"> -->
      <form class="">
        <div class="resend__fileds">
          <div class="form-group fit-my-4">
            <!-- <input type="text" id="confirmationCode" class="form-control col-12" style="border-width: 1px;" placeholder="Confirmation Code*"
               [(ngModel)]="confirmationCode" name="confirmationCode" required> -->
               <input type="email" id="inputEmail" autocomplete="off" class="col-12 login-form-control"
              [(ngModel)]="email" #useremail="ngModel" name="inputEmail" placeholder="Email*">
            <!-- <span class="floating-label"><i class="fas fa-user"></i> Username</span> -->
            <i class="fas fa-envelope login-fa-icon-style"></i>
            <div *ngIf="useremail.invalid && (useremail.dirty || useremail.touched)" class="alert alert-danger">
              <!-- <div *ngIf="useremail.errors?.required">
                Email is required
              </div> -->
              <div *ngIf="useremail.errors?.pattern">
                Please include '@' in the Email.
                </div>
          </div>
          </div>
        </div>
        <div class="text-center btn-margin">
            <button class="btn btn-default submitBtn" (click)="resendCode()" id="signupSubmit" type="submit">Resend Code</button>
        </div>
      </form>
    </div>
  </div>
</div>
</div>
