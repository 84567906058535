import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TraineeLoginService } from "../../service/trainee-login.service";
import { ChallengeParameters, CognitoTraineeUtil, CognitoCallback, LoggedInCallback, CallbackTrainee } from "../../service/cognitotrainee.service";
import { ToastrService } from 'ngx-toastr';
import { DBService } from '../../service/db.service';
import { UserParametersService } from "../../service/user-parameters.service";

declare const gapi: any;
declare const AWS: any;

export class profile {
  trainerUserName: string;
  trainerId: string;
  profileStatus: string;
  registraionType: string;
}

@Component({
  selector: 'app-trainee-login',
  templateUrl: './trainee-login.component.html',
  styleUrls: ['./trainee-login.component.css']
})
export class TraineeLoginComponent implements CognitoCallback, LoggedInCallback, OnInit {


  username: string;
  password: string;
  errorMessage: string;
  mfaStep = false;
  mfaData = {
    destination: '',
    callback: null
  };
  // cognito auth tokens
  authData: any;
  auth: any;
  session: any;

  // @Output() getLoggedInEmail= new EventEmitter<any>();
  title: string;
  closeBtnName: string;
  userId: string;
  userName: string;
  currentUserPic: any;
  logedInType: string;
  // currentID : string;
  profile: profile;
  profileResponse: object;
  profileStatus: string;
  profileCategory: string;
  profileGender: string;
  profileRegistration: string;
  // signInHide: boolean = true;
  // forgetPassPageHide: boolean = false;

  public cognitoId: String;

  loaderHide: boolean = false;
  constructor(private db_service: DBService,
    public router: Router,
    public userService: TraineeLoginService,
    private toastr: ToastrService,
    public awsService: CognitoTraineeUtil,
    public userParams: UserParametersService,) {
    //  console.log("TraineeLoginComponent constructor");

  }


  ngOnInit() {
    this.profile = new profile();
    this.errorMessage = null;
    // console.log("Checking if the user is already authenticated. If so, then redirect to the secure site");
    this.userService.isAuthenticated(this);
  }

  onLogin() {
    if (this.username == null || this.password == null) {
      this.toastr.error('', 'Mandatory fields are required', {
        timeOut: 5000,
        extendedTimeOut: 1000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true
      });
    }
    else {
      this.loaderHide = !this.loaderHide;
      localStorage.clear();
      this.userService.authenticate(this.username, this.password, this);
      // this.getProfileDataFromServer();

    }
  }

  getProfileDataFromServer() {
    var index = '/profile/';
    this.db_service.getDataFromServer(this.username, index).subscribe(
      response => {
        // console.log('Data Retrived succesfully.', response);
        response = response.data[0];
        this.userId = response.trainerId;
        this.userName = response.trainerUserName;
        this.currentUserPic = response.profileImage["profileImagePath"];
        this.logedInType = response.registraionType;
        this.profileResponse = response;
        this.profileStatus = response.profileStatus;
        this.profileRegistration = response.registraionType;
        var cat = response.category;
        for (let i = 0; i < cat.length; i++) {
          if (cat[i]["primary"] == true) {
            this.profileCategory = cat[i]["category_name"];
          }
        }
        // this.profileCategory = response.category;
        this.profileGender = response.gender;
        // this.Status = response.profileStatus;
        if (this.profileStatus == "created") {
          // this.getProfileDataFromServer();
          this.router.navigate(['gender', this.username, this.userId]);
        } else {
          // this.userService.broadcastLoginChange(this.userId, this.currentUserPic,this.logedInType);
          localStorage.setItem("ProfileCategory", this.profileCategory);
          localStorage.setItem("ProfileGender", this.profileGender);
          this.router.navigate(['dashboard']);
        }
      },
      error => {
        this.toastr.error('', "An error has occurred while retriving profile data.", {
          timeOut: 5000,
          extendedTimeOut: 1000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true
        });
        // alert();
      }
    )
  }

  cognitoCallback(message: string, result: any) {
    //console.log("login Result" + result);
    if (message != null) { //error
      this.errorMessage = message;
      // let userID = result.accessToken.payload.sub;
      // this.loaderHide = !this.loaderHide;
      this.loaderHide = !this.loaderHide;
      this.toastr.error('', message, {
        timeOut: 5000,
        extendedTimeOut: 1000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true
      });
      if (this.errorMessage === 'User is not confirmed.') {
        // console.log("redirecting");
        this.loaderHide = !this.loaderHide;
        this.router.navigate(['confirm', this.username]);
      } else if (this.errorMessage === 'User needs to set password.') {
        // console.log("redirecting to set new password");
      }
    }
    else {
      this.loaderHide = !this.loaderHide;
      let id = result.accessToken.payload.sub;
      this.userId = result.accessToken.payload.sub;
      localStorage.setItem("ProfileCategory", 'Bodybuilder')
      localStorage.setItem("ProfileGender", "male")
      this.router.navigate(['traineeSlot', this.userId]);
      // var index = '/profile/';
      // this.db_service.getDataFromServer(this.email, index).subscribe(
      //   response => {
      //     console.log('Date Retrived succesfully.', response);
      //     response = response.data[0];

      //     if (response == undefined || response == null) {
      //       this.logedInType = "Cognito";
      //       this.userService.broadcastLoginChange(this.userId, this.currentUserPic, this.logedInType)
      //       this.router.navigate(['gender', this.email, this.userId]);
      //     }
      //     else if (response.profileStatus == undefined) {
      //       this.logedInType = "Cognito";
      //       this.userService.broadcastLoginChange(this.userId, this.currentUserPic, this.logedInType)
      //       this.router.navigate(['gender', this.email, this.userId]);
      //     }
      //     else {
      //       this.userId = response.trainerId;
      //       this.userName = response.trainerUserName;
      //       this.currentUserPic = response.profileImage["profileImagePath"];
      //       this.logedInType = response.registraionType;
      //       this.profileResponse = response;
      //       this.profileStatus = response.profileStatus;
      //       this.profileRegistration = response.registraionType;
      //       var cat = response.category;
      //       this.profileCategory = response.category;
      //       this.profileGender = response.gender;
      //       for (let i = 0; i < cat.length; i++) {
      //         if (cat[i]["primary"] == true) {
      //           this.profileCategory = cat[i]["category_name"];
      //         }
      //       }
      //       this.logedInType = "Cognito";
      //       this.userService.broadcastLoginChange(this.userId, this.currentUserPic, this.logedInType)
      //       localStorage.setItem("ProfileCategory", this.profileCategory)
      //       localStorage.setItem("ProfileGender", this.profileGender)
      //       this.router.navigate(['dashboard']);
      //     }

      //   },
      //   error => {
      //     this.toastr.error('', "An error has occurred while retriving profile data.", {
      //       timeOut: 5000,
      //       extendedTimeOut: 1000,
      //       positionClass: 'toast-top-right',
      //       progressBar: true,
      //       progressAnimation: 'increasing',
      //       tapToDismiss: true
      //     });
      //     // alert();
      //   }
      // )
      // this.getProfile();



    }
  }

  handleMFAStep(challengeName: string, challengeParameters: ChallengeParameters, callback: (confirmationCode: string) => any): void {
    this.mfaStep = true;
    this.mfaData.destination = challengeParameters.CODE_DELIVERY_DESTINATION;
    this.mfaData.callback = (code: string) => {
      if (code == null || code.length === 0) {
        // this.errorMessage = "Code is required";
        this.toastr.warning('', 'Code is required', {
          timeOut: 3000,
          extendedTimeOut: 1000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true
        });
      }
      this.errorMessage = null;
      callback(code);
    };
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (isLoggedIn) {
      // this.router.navigate(['/securehome']);
      // console.log("is logged in is true");

    }
  }


  cancelMFA(): boolean {
    this.mfaStep = false;
    return false;   //necessary to prevent href navigation
  }

}
