import { Component, OnDestroy, OnInit, Injectable, Injector } from '@angular/core';
import { Router } from "@angular/router";
import { UserRegistrationService } from "../service/user-registration.service";
import { CognitoCallback } from "../service/cognito.service";
import { ToastrService } from 'ngx-toastr';
import { DBService } from '../service/db.service';
import {HttpBackend, HttpClient} from "@angular/common/http";

declare const gapi: any;

// @Injectable()
export class RegistrationUser {
  name: string;
  email: string;
  // phone_number: string;
  password: string;
  profileStatus: string;
}
export class profile {
  // firstName: string;
  userName: string;
  trainerId: string;
  profileStatus: string;
  registraionType: string;
  adminStatus: string;
}
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})

export class RegisterComponent implements OnInit, CognitoCallback {

  registrationUser: RegistrationUser;
  router: Router;
  errorMessage: string;
  confirmationCode: string;
  cognitiUser: string;
  userID: string;
  private sub: any;
  show: boolean;
  showEye: boolean;
  showSplash: boolean;

  // signUpPageHide: boolean = true;
  // confirmPageHide: boolean = false;
  loaderHide: boolean = false;
  // userRequired : boolean = false;

  // errorMessage: string;
  profile: profile;
  profileResponse: any;
  profileResponce: string;

  constructor(public userRegistration: UserRegistrationService, router: Router, private toastr: ToastrService, private db_service: DBService,private httpClient: HttpClient,handler: HttpBackend) {
    this.router = router;
    this.httpClient = new HttpClient(handler);
    // this.onInit();
  }

  ngOnInit() {
    this.profile = new profile();
    this.registrationUser = new RegistrationUser();
    this.errorMessage = null;
    this.loadScript('https://apis.google.com/js/platform.js');
    this.show = false;
    this.showEye = false;
    this.showSplash = true;

    // document.body.classList.remove('body-main-build');
    // document.body.classList.remove('body-main-yoga');
    // document.body.classList.remove('body-main-zumba');
    // document.body.classList.add('body-main');
  }

  // ngOnDestroy() {
  //   this.sub.unsubscribe();
  // }
  onRegister() {

    if (this.registrationUser.name == null || this.registrationUser.email == null || this.registrationUser.password == null) {
      // this.errorMessage = "All fields are required";
      // return;
      this.toastr.error('', 'Mandatory fields are required', {
        timeOut: 5000,
        extendedTimeOut: 1000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true
      });
    }
    else {
      this.httpClient.get<any>('https://demo.1o1fitness.com/trainerservice/api/trainer/guest/'+this.registrationUser.email).subscribe({
        next: response => {
          this.profileResponce = response.message;
          this.loaderHide = !this.loaderHide;
          if(this.profileResponce != "User already exist."){
            this.loaderHide = !this.loaderHide;
            this.registrationUser.profileStatus = "registered";
            this.userRegistration.register(this.registrationUser, this);
          }
          else{
            this.loaderHide = !this.loaderHide;
            this.toastr.error('', 'User already exist. Please sign in.', {
              timeOut: 5000,
              extendedTimeOut: 1000,
              positionClass: 'toast-top-right',
              progressBar: true,
              progressAnimation: 'increasing',
              tapToDismiss: true
            });
          }
        },
        error: error => {
          console.log('There is an error while retriving data.');
        }
      })


    }
  }

  showPassword() {
    this.show = !this.show;
    this.showEye = !this.showEye;
    this.showSplash = !this.showSplash;
  }
  // onConfirmRegistration() {
  //   this.loaderHide = !this.loaderHide;
  //   this.errorMessage = null;
  //   this.userRegistration.confirmRegistration(this.registrationUser.email, this.confirmationCode, this);
  // }

  cognitoCallback(message: string, result: any) {
    if (message != null) { //error
      this.loaderHide = !this.loaderHide;
      this.toastr.error('',message, {
        timeOut: 5000,
        extendedTimeOut: 1000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true
      });
      // console.log("result: " + this.errorMessage);
      // } else if(result=="SUCCESS") {
      //   this.toastr.success('','Signed up successfully',{
      //     timeOut: 2000,
      //     extendedTimeOut: 1000,
      //     positionClass:'toast-top-center',
      //     progressBar: true,
      //     progressAnimation: 'decreasing',
      //     tapToDismiss: true
      //   });
      //   this.loaderHide = !this.loaderHide;
      //   this.router.navigate(['gender', this.cognitiUser, this.userID]);

      //   //success
      //   //move to the next step
      //   // console.log("redirecting");
      //   //this.router.navigate(['/home/confirmRegistration', result.user.username]);
      //   // let cognitiUser = result.user.username;
      //   // let userID = result.userSub;
      //   // this.router.navigate(["confirm", cognitiUser, userID]);
      //   //this.dsModalRef.hide();
    }
    else {
      //  console.log("redirecting")
      this.loaderHide = !this.loaderHide;
      // this.signUpPageHide = !this.signUpPageHide;
      // this.confirmPageHide = !this.confirmPageHide;
      this.cognitiUser = result.user.username;
      this.userID = result.userSub;
      this.profile.userName = result.user.username;
      this.profile.trainerId = result.userSub;

      this.toastr.success('', 'Confirmation Code has been sent to your registered Email.', {
        timeOut: 5000,
        extendedTimeOut: 1000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true
      });
      this.router.navigate(["confirm", this.cognitiUser]);
      // this.SaveProfile();
    }
  }
  SaveProfile() {
    this.profile.profileStatus = "initiated";
    this.profile.adminStatus = "New";
    this.profile.registraionType = "Cognito";
    var index = '/profile'
    this.db_service.saveDataToDB(this.profile, index).subscribe(
      response => {
        // this.loaderHide = !this.loaderHide;
        this.profileResponse = Array.of(response);

      },
      error => {
        // alert('there is an error while saving data');
        this.toastr.error('', 'There is an error while saving data', {
          timeOut: 5000,
          extendedTimeOut: 1000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true
        });
      }
    )
  }
  public loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }
}
