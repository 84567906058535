<section>
  <div class="container-fluid">
    <div class="fit-px-mt-5">
      <div class="row">

        <div class="heading1 col-md-8 col-sm-12 mb-4"> <img src="assets/images/icons/asana-management.png"
            style="border: 2px solid #8bc53f;border-radius: 50%;" width="50" />
          <span><b>Asanas Management</b></span>
        </div>

        <!-- Exercise Search box -->
        <div class="col-md-4 fit-mt-3">
          <div class="d-flex justify-content-end">
            <div class="search__box search__box-exercise search__box-exercise-closed d-flex m-0 mx-2" style="cursor: pointer;">
              <input type="text" class="col" autocomplete="off" name="search" placeholder="Search for an Asanas" [(ngModel)]="searchAsana">
              <a class="search__box__btn search__box__btn-exercise-open"><i class="fas fa-search"></i></a>
              <!-- <a data-toggle="modal" data-target="#exerciseSerachFilters" class="search-filters">
                 <i class="fas fa-filter"></i>
              </a> -->
            </div>
            <div routerLink="/addAsanas" style="cursor: pointer;" class="add__exercise__btn"><i class="fa fa-plus"></i></div>
            <!-- <a class="gallery__btn" data-toggle="dropdown"><i class="fa fa-cubes"></i></a>
            <div class="dropdown-menu user_settings_wrapper">
             <div>
            <a style="display:flex;" *ngFor="let pex of publicexercises">
                <label>{{pex.exerciseName}}</label>
                <ui-switch [(ngModel)]="pex.isPublic" class="custom-control" (change)="disablePublic(pex.exerciseId)">
                </ui-switch>
              </a>
            </div>
            </div> -->
          </div>
        </div>
        <!-- Exercise Search box /-->
      </div>
      <!-- Exercise Video -->
      <!-- <div class="videos videos-exercise col-12 row">
              <div class="col-sm-6 col-md-2"*ngFor="let ex of exercises"><a [ngStyle]="{'background-image' : 'url('+ ex.thumbnailDestination +')'}" style="background-position: center;" (click)="openModal(ex)"></a><h1 (click)="editExercise(ex.exerciseId)" style="cursor:pointer;">{{ ex.exerciseName }}<i class="fa fa-eye"></i></h1></div>
            </div> -->

      <div class="d-flex">

        <div class="col-md-6 sub_heading mb-0" style="display: flex;">
          <label class="mt-2" *ngIf="asanaLength !=0 || isPublic == true">
            <h4>Public Videos</h4>
          </label>
          <ui-switch *ngIf="asanaLength !=0 || isPublic == true" defaultBgColor="#8a8585" [(ngModel)]="isPublic"
            class="custom-control" (change)="enablePublic()"></ui-switch>
        </div>
        <div class="col-md-6 sub_heading mb-0 total-exercises-style" style="text-align: end;">Total Asanas :
          {{asanaLength}}</div>
      </div>
      <div class="row justify-content-center" *ngIf="asanaLength == 0 && isPublic == false">
        <h1>Please click on '+' icon to add Asanas</h1>
      </div>
      <div
      class="search-results"
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      (scrolled)="onScroll()"
    >
      <div class="videos videos-exercise row" *ngIf="asanas != undefined">
        <div class="col-sm-6 col-md-4 col-10 fit-mx-auto col-lg-3 col-xl-2 exercise-video-div-grid mb-3" title="{{ex.asanaTitle}}"
          *ngFor="let ex of asanas.slice().reverse() | filter:searchAsana">
          <div class="video_wrapper">
            <div class="video_file">
              <a>
                <video (click)="openModal(ex,template)" onloadedmetadata="this.muted=true" muted="" loop="" playsinline="" data-toggle="modal" data-target="#contentModal"
                  onmouseover="this.play()" onmouseout="this.load();" poster="{{ex.asanaVideo.videoThumbnailPath}}"
                  style="width: 100%;">
                  <source _ngcontent-c3="" src="{{ex.asanaVideo.asanaVideoSource}}">
                </video>
              </a>
            </div>
            <div class="video__title col-md-12">
              <div class="row">
                  <div class="col-sm-9 col-md-9 col-9">
                    <div style="font-size:14px;" class="word-ellipsis">{{ex.asanaTitle}}</div>
                    <!-- <i class="fa fa-trash" (click)="deleteExercise(ex.exerciseId)"></i> -->
                  </div>
                  <!-- <div class="edit_links col-sm-4 col-md-3 text-right">
                    <ng-template #tolTemplate>

                      <div>Updated on: {{ex.lastUpdatedOn | date}}</div>
                    </ng-template>
                    <a placement="bottom" style="margin-left: 10px;"
                      *ngIf="ex.adminStatus == 'Under Review' || ex.adminStatus == 'Rejected'"> <i class="fa fa-comment"
                        style="font-size: 18px; color:red;" (click)="getAdminComments(ex.exerciseId)" title="Comments"></i>
                    </a>
                    <a [tooltip]="tolTemplate" placement="bottom" style="margin-right: 5px;"><i
                        class="fa fa-clock-o"></i></a>
                    <a (click)="editExercise(ex.exerciseId)" title="Edit" style="margin-right: 5px;"><i
                        class="fas fa-pencil-alt"></i></a>
                    <a (click)="deleteCheck(ex.exerciseId)" title="Delete"><i class="fas fa-trash-alt"></i></a>

                  </div> -->


                  <div class="dropdown col-md-3 col-sm-3 col-3 text-right">
                    <i class="fa fa-caret-down" aria-hidden="true" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="color:white; " ></i>
                    <div class="dropdown-menu exercise-edit-droppdown" aria-labelledby="dropdownMenuButton" >
                      <a placement="bottom" class="mx-2" *ngIf="ex.adminStatus == 'Under Review' || ex.adminStatus == 'Rejected'">
                        <i class="fa fa-comment exe-edit-fa-grop" (click)="getAdminComments(ex.asanaId)" title="Comments"></i>
                      </a>
                      <a [tooltip]="tolTemplate" title="Updated on: {{ex.lastUpdatedDate | date}}" class="mx-2" placement="bottom" >
                        <i class="fa fa-clock-o exe-edit-fa-grop"></i>
                      </a>
                      <a (click)="editAsana(ex.asanaId)" title="Edit" class="mx-2">
                        <i class="fas fa-pencil-alt exe-edit-fa-grop"></i>
                      </a>
                      <a (click)="deleteCheck(ex.asanaId)" title="Delete" class="mx-2">
                        <i style="color: red;" class="fas fa-trash-alt exe-edit-fa-grop"></i>
                      </a>
                    </div>

                  </div>





            </div>
            </div>
          </div>

        </div>
      </div>
      </div>
      <!-- Exercise Video /-->

    </div>
    <!-- <ng-container *ngIf="showVideoPopup">
      <div class="modal fade " id="contentModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style="background: #000000ba;">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">

                  <div class="video-pop zoomIn modal-body background-shadow-green" style="border-radius: 12px;">
                    <a (click)="closePopup()" class="close-video-pop float-right" style="cursor: pointer;"><i class="fas fa-times"></i></a>
                    <video autoplay="" loop="" onloadedmetadata="this.muted=true" muted="" playsinline="" controls style="width: 100%;" *ngIf="videoHidden">
                      <source attr.src="{{activeUrl}}" type="video/mp4">
                    </video>
                    <div class="exercise-upload-video" [innerHtml]="embedYoutubeUrl" *ngIf="iframeHidden"></div>

                </div>
                </div>
              </div>
            </div>

    </ng-container> -->

    <ng-template #template>
      <!-- <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content"> -->
          <div class="modal-body">
            <div class="video-pop zoomIn modal-body background-shadow-green" style="border-radius: 12px;">
              <!-- <a (click)="closePopup()" class="close-video-pop float-right" style="cursor: pointer;"><i class="fas fa-times"></i></a> -->
              <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
              </button>
              <video autoplay="" loop="" onloadedmetadata="this.muted=true" muted="" playsinline="" controls style="width: 100%;" *ngIf="videoHidden">
                <source attr.src="{{activeUrl}}" type="video/mp4">
              </video>
              <div class="exercise-upload-video" [innerHtml]="embedYoutubeUrl" *ngIf="iframeHidden"></div>

          </div>
          </div>
        <!-- </div>
      </div> -->
    </ng-template>
    <!-- <ng-container *ngIf="showDeletePopup">
      <div class="program_event-popup__wrapper">
        <div class="program_event-pop zoomIn background-shadow-green">
          <div class="program_event-header">
            <h3>Delete exercise</h3>
          </div>
          <a (click)="closePopup()" class="close-program_event-pop"><i class="fas fa-times"></i></a>
          <div class="program_event-body d-flex justify-content-center">
            <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;">Yes</button>
            <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;">No</button>
          </div>
        </div>
      </div>
    </ng-container> -->
    <!-- showExdeletePopup -->
    <ng-container *ngIf="showExdeletePopup">
      <div class="program_event-popup__wrapper">
        <div class="program_event-pop zoomIn background-shadow-green">
          <div class="program_event-header text-center">
            <h4>Are you sure</h4>
          </div>
          <a (click)="closePopup()" class="close-program_event-pop"><i class="fas fa-times"></i></a>
          <div class="program_event-body d-flex justify-content-center">
            <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
              (click)="deleteAsana()">Yes</button>
            <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
              (click)="closePopup()">No</button>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="showAdminComments">
      <div class="video-popup__wrapper">
        <div class="video-pop zoomIn">

          <div class="program_event-body d-flex justify-content-center">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12">
                  <div class="add_program_duration">
                    <div class="col-12">
                      <div class="row">
                        <div class="col"  style="text-align: center">
                          <b>Comments</b>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                          <div style="display: grid"><b>Created by</b> </div>
                        </div>
                        <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                          <div style="display: grid"><b>Date</b> </div>
                        </div>

                        <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                          <div style="display: grid"><b>Status</b> </div>
                        </div>

                        <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                          <div style="display: grid"><b>Comment</b>
                          </div>
                        </div>

                      </div>

                      <div class="row" *ngFor="let comment of adminComments">
                        <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                          <div style="display: grid"><span>{{comment.updatedBy}}</span>
                          </div>
                        </div>

                        <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                          <div style="display: grid"><span>{{comment.updateDate | date}}</span> </div>
                        </div>

                        <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                          <div style="display: grid"><span>{{comment.action}} </span> </div>
                        </div>

                        <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                          <div style="display: grid"><span>{{ comment.comment }} </span> </div>
                        </div>
                      </div>
                    </div>


                    <div class="d-flex col-12 justify-content-end">
                      <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
                        (click)="closePopup()">
                        Ok
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

  </div>


</section>
