import { Component, OnInit, Input, OnDestroy, Renderer2, EventEmitter, HostListener } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { UserLoginService } from "../service/user-login.service";
import { LoggedInCallback, CognitoUtil } from "../service/cognito.service";
import { AppGlobals } from '../app.globals';
import { DBService } from '../service/db.service';
import { AWSService } from '../service/aws.service';
import { Options } from 'ng5-slider';
import { $ } from 'protractor';
import { EmbedVideoService } from 'ngx-embed-video';
// import { AngularFontAwesomeComponent } from 'angular-font-awesome';
import { integer } from 'aws-sdk/clients/cloudfront';
import { ToastrService } from 'ngx-toastr';
import { YogaDBService } from '../service/YogaDBService';
import { of } from 'rxjs';
//import * as $ from 'jquery';

// export class exercise {
//   exerciseId: string;
//   exerciseName: string;
//   workoutType: Object;
//   otherWorkoutType: String;
//   mechanicalType: Object;
//   otherMechanicalType: string;
//   mainMuscleWorkout: Object;
//   otherMainMuscleWorkout: string;
//   otherMuscleWorkout: Object[];
//   level: Object;
//   sport: Object;
//   equipment: Object;
//   force: Object;
//   sets: Object[];
//   exerciseVideo: Object;
//   instructions: string;
//   trainerId: string;
//   isPublic: boolean;
//   isPublicDate: Date;
//   creationDate: Date;
//   lastUpdatedOn: Date;
//   exerciseWarnings: string;
//   exerciseStatus: string;
//   exercisePercentage: string;
//   exerciseComments: Object[];
//   isChangeAffects: boolean;
// }
export class asanaModel{
  asanaId : string;
  trainerId : string;
  asanaVideo: string;
  asanaTitle : string;
  asanaType:Object;
  sets:Object[];
  adoptedName : string;
  targetMuscle: Object[];
  difficultyLevel:object;
  asanaGoal:Object[];
  therapeutic:object;
  benifitsOfAsana:string;
  instructions:Object[];
  precautions:string;
  isPublic: boolean;
  isPublicDate: Date;
  creationDate: Date;
  lastUpdatedOn: Date;
  asanaStatus: string;
  asanaPercentage: string;
  asanaComments: Object[];
  asanaImgUrl:string;
}


@Component({
  selector: 'app-add-asanas',
  templateUrl: './add-asanas.component.html',
  styleUrls: ['./add-asanas.component.css']
})
export class AddAsanasComponent implements OnInit {
  @Input() videoUrl: string = "";
  @Input() imgUrl: string = "assets/images/uploadimg.PNG";
  @Input() thumbUrl: string = "assets/images/upload-video.jpg";
  videoHidden: boolean = false;
  //isBasic:boolean  = false;
  drgDropHidden: boolean = true;
  iframeHidden: boolean = false;
  loaderHide: boolean = false;
  showasanaGoal:boolean  = false;
  isYoutubeVerified: boolean = false;
  isUpdateAsana: boolean = false;
  editDisabled: boolean = false;
  dropdownSettings: any = {};
  dropdownSettings_single: any = {};
  dropdownSettings_single_1: any = {};
  closeDropdownSelection = false;
  disabled = false;
  //custDrop: string;
  fileExtension: any;
  embedYoutubeUrl: string;
  currentCategory: string;
  currentGender: string;
  iframeURL: any;
  isDragOver: boolean = false;
  // exName:string;

  // WorkoutTypes: object[];
  MainMuscleWorkouts: object[];
  // OtherMuscleWorkouts: object[];
  // Equipments: object[];
  // MechanicalTypes: object[];
  // Levels: object[];
  // Sports: object[];
  // Forces: object[];
  asanaTypes : object[];
  targetMuscles: object[];
  difficultLevel:object[];
  asanaGoals:object[];

  selectedAsanaType : object[];
  // selectedTargetMuscles : object[];
  selectedDifficultLevels:object[];
  selectedasanaGoal:object[];

  // SelectedWorkoutType: Object[];
  SelectedMainMuscleWorkout: Object[];
  // SelectedOtherMuscleWorkouts: Object[];
  // SelectedEquipment: Object[];
  // SelectedMechanicalType: Object[];
  // SelectedLevel: Object[];
  // SelectedSport: Object[];
  // SelectedForce: Object[];

  // isWorkoutType = false;
  // isMechanicalType = false;
  // isMainMuscleWorkout = false;

  set = {
    setNo: 0,
    reputationValue: { "actual": 0, "completed": 0 },
    breathValue: { "actual": 0, "completed": 0 },
    minutesPeriod: { "actual": 0, "completed": 0 },
    secondsPeriod: { "actual": 0, "completed": 0 },
    restPeriodMetric: "",
    // maxWeights: { "actual": 0, "completed": 0 },
    heartrate: ""
  }


  setsVal: number = 0;
  setsOptions: Options = {
    floor: 0,
    ceil: 10
  };

  manualRefreshEnabled: boolean = true;
  manualRefresh: EventEmitter<void> = new EventEmitter<void>();

  repsVal: number = 0;
  repsOptions: Options = {
    floor: 0,
    ceil: 10
  };
  restPeriodVal: number = 0;
  breathsOptions: Options = {
    floor: 0,
    ceil: 10
  };
  breathsVal: number = 0;
  minutesOptions: Options = {
    floor: 0,
    ceil: 60
  };
  minutesVal: number = 0;
  seconsOptions: Options = {
    floor: 0,
    ceil: 60
  };
  secondsVal: number = 0;
  weightsVal = 0

  repsVal1: number = 0;
  breathsVal1: number = 0;
  minutesVal1: number = 0;
  secondsVal1: number = 0;
  weightsVal1 = 0

  repsVal2: number = 0;
  breathsVal2: number = 0;
  minutesVal2: number = 0;
  secondsVal2: number = 0;
  weightsVal2 = 0

  repsVal3: number = 0;
  breathsVal3: number = 0;
  minutesVal3: number = 0;
  secondsVal3: number = 0;
  weightsVal3 = 0

  repsVal4: number = 0;
  breathsVal4: number = 0;
  minutesVal4: number = 0;
  secondsVal4: number = 0;
  weightsVal4 = 0

  repsVal5: number = 0;
  breathsVal5: number = 0;
  minutesVal5: number = 0;
  secondsVal5: number = 0;
  weightsVal5 = 0

  repsVal6: number = 0;
  breathsVal6: number = 0;
  minutesVal6: number = 0;
  secondsVal6: number = 0;
  weightsVal6 = 0

  repsVal7: number = 0;
  breathsVal7: number = 0;
  minutesVal7: number = 0;
  secondsVal7: number = 0;
  weightsVal7 = 0

  repsVal8: number = 0;
  breathsVal8: number = 0;
  minutesVal8: number = 0;
  secondsVal8: number = 0;
  weightsVal8 = 0

  repsVal9: number = 0;
  breathsVal9: number = 0;
  minutesVal9: number = 0;
  secondsVal9: number = 0;
  weightsVal9 = 0

  // exerciseVideo: any = {
  //   exerciseVideoSource: "",
  //   exerciseVideoName: "",
  //   videoThumbnailPath: "",
  //   videoSourceUrl: "",
  //   videoDestinationCheck: false,
  //   videoMp4Destination: "",
  //   VideoVerfied: "",
  //   verifiedOn: Date,
  //   VideoUploadedOn: new Date(),
  //   youtubeUrl: "",
  // }

  asanaVideo: any = {
    asanaVideoSource: "",
    asanaVideoName: "",
    videoThumbnailPath: "",
    videoSourceUrl: "",
    videoDestinationCheck: false,
    videoMp4Destination: "",
    VideoVerfied: "",
    verifiedOn: Date,
    VideoUploadedOn: new Date(),
    youtubeUrl: "",
  }
  


  oneAtATime: boolean = true;
  constructor(private renderer: Renderer2, private HttpClient: HttpClient, public router: Router, public userService: UserLoginService,
    public route: ActivatedRoute, private globals: AppGlobals, private yoga_db_service: YogaDBService, private db_service: DBService, private awsService: AWSService, private toastr: ToastrService,
    private embedService: EmbedVideoService, public cognitoUtil: CognitoUtil) {
    this.userService.isAuthenticated(this);
  }
  
  @HostListener('dragover', ['$event'])
  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = true;
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent,filetype) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = false;

    const files = event.dataTransfer?.files;
    if (files) {
      // Handle dropped files here
      this.selectFile(files, filetype)
    }
  }
  onFileSelected(event: Event,filetype) {
    const inputElement = event.target as HTMLInputElement;
    const files = inputElement.files;
    if (files) {
      // Handle selected files here
      this.selectFile(files,filetype)
    }
  }
  // exercise: exercise;
  asanaModel : asanaModel;
  selectedFile: File;
  // exerciseResponse: any;
  asanaResponse:any;
  private sub: any;
  //exerciseId: string = "";
  currentEmail: string;
 instructions: any[] = [{
      id: 1,
      name: ""
 }];

  // Google Var
  private loggedIn: boolean;

  ngOnInit() {

    this.currentCategory = localStorage.getItem("ProfileCategory")
    this.currentGender = localStorage.getItem("ProfileGender");
    if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-build-male');
    }
    else if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-build-female');
    }
    else if (this.currentCategory == 'Yoga' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-yoga-male');
    }
    else if (this.currentCategory == 'Yoga' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-yoga-female');
    }
    else if (this.currentCategory == 'Zumba' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-zumba-male');
    }
    else if (this.currentCategory == 'Zumba' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-zumba-female');
    }

    // this.exercise = new exercise();
    this.asanaModel = new asanaModel();
    // this.exercise.isPublic = false;
    this.asanaModel.isPublic = false;
    this.userService.currentUserID.subscribe((val) => {
      if (val != '') {
        // this.exercise.trainerId = val;
        this.asanaModel.trainerId = val;
      }
    });
    this.sub = this.route.params.subscribe(params => {
      // this.exercise.exerciseId = params['exerciseID'];
      this.asanaModel.asanaId = params['asanaID'];
    });
    if (this.asanaModel.asanaId != undefined && this.asanaModel.asanaId != "") {
      this.getAsanaData();
      
    }
    this.dropdownSettings = {
      singleSelection: false,
      enableCheckAll: false,
      idField: 'id',
      textField: 'name',
      allowSearchFilter: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      closeDropDownOnSelection: this.closeDropdownSelection
    };
    this.dropdownSettings_single = {
      singleSelection: true,
      enableCheckAll: false,
      idField: 'id',
      textField: 'name',
      allowSearchFilter: true,
      itemsShowLimit: 1,
      closeDropDownOnSelection: this.closeDropdownSelection
    };
    this.dropdownSettings_single_1 = {
      singleSelection: true,
      enableCheckAll: false,
      idField: 'id',
      textField: 'name',
      allowSearchFilter: false,
      itemsShowLimit: 1,
      closeDropDownOnSelection: this.closeDropdownSelection
    };
    this.getAllMasters();
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
    this.renderer.removeClass(document.body, 'bg-img-build-male');
    this.renderer.removeClass(document.body, 'bg-img-yoga-male');
    this.renderer.removeClass(document.body, 'bg-img-zumba-male');
    this.renderer.removeClass(document.body, 'bg-img-build-female');
    this.renderer.removeClass(document.body, 'bg-img-yoga-female');
    this.renderer.removeClass(document.body, 'bg-img-zumba-female');
    this.renderer.addClass(document.body, 'body-main');
  }
  addInstruction(){
      if(this.instructions.length > 15){
        this.toastr.error('', 'Maximum limit for adding instructions reached"', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        return;
      }
      this.instructions.push({
        id :this.instructions.length + 1,
        name:""
        
    })
  }
  removeInstruction(i :number){
      if(this.instructions.length > 1)
      this.instructions.splice(i,1);
      else{
        this.toastr.error('', 'Cannot remove Required atleast one instruction"', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        return;
      }
   

  }
  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/login']);
    } else {
      // if()
      if (this.loggedIn == true) {
        this.currentEmail = localStorage.getItem('lastAuthUser');
        // if(this.currentEmail != undefined)
        this.getProfileDataFromServer();
      }
      else {
        // this.currentEmail = localStorage.getItem('CognitoIdentityServiceProvider.2io8v4r253hugt5ndbjv9shkfp.LastAuthUser');
        this.currentEmail = this.cognitoUtil.getCurrentUser().getUsername();
        if(this.currentEmail == undefined || this.currentEmail == '' || this.currentEmail == null || this.currentEmail.includes("Google") || this.currentEmail.includes("google"))
        {
          this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
          if(this.currentEmail == undefined)
          this.currentEmail = this.cognitoUtil.session.idToken.payload.email;
        }
        // this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
        this.getProfileDataFromServer();
      }
    }
  }
  getProfileDataFromServer() {
    var index = '/profile/';
    this.yoga_db_service.getDataFromServer(this.currentEmail, index).subscribe(
      response => {
        //console.log('Data Retrived succesfully.', response);
        response = response.data[0];
        this.asanaModel.trainerId = response.trainerId;
        // this.geExerciseDataFromServer();
      },
      error => {
        console.log("An error has occurred while retriving profile data.");
      }
    )
  }
  BindData(){
    for(var i = 0; i < this.asanaTypes.length ; i++){
      if(this.asanaTypes[i]["id"] == this.selectedAsanaType[0]["id"]){
          this.asanaGoals = this.asanaTypes[i]['asanaGoal'];
          if(this.asanaModel.asanaId == null || this.asanaModel.asanaId == undefined){
          this.asanaModel.benifitsOfAsana = this.asanaTypes[i]['benefits'];
          this.asanaModel.precautions = this.asanaTypes[i]['precautions'];
          // this.asanaModel.instructions = this.asanaTypes[i]['instructions'];
          }
          this.showasanaGoal = true;
      }
  }
  }
  onItemSelect(event, type) {
        if(type == "workoutType"){
          for(var i = 0; i < this.asanaTypes.length ; i++){
            if(this.asanaTypes[i]["id"] == this.selectedAsanaType[0]["id"]){
                this.asanaGoals = this.asanaTypes[i]['asanaGoal'];
                this.selectedasanaGoal= [];
                if(this.asanaModel.asanaId == null || this.asanaModel.asanaId == undefined){
                this.asanaModel.benifitsOfAsana = this.asanaTypes[i]['benefits'];
                this.asanaModel.precautions = this.asanaTypes[i]['precautions'];
                // this.asanaModel.instructions = this.asanaTypes[i]['instructions'];
                }
                this.showasanaGoal = true;
            }
        }
        }
    // if (event.name == "Others" && type == "workoutType") {
    //   this.isWorkoutType = !this.isWorkoutType;
    // } else {
    //   this.isWorkoutType = false;
    // }
    // if (event.name == "Others" && type == "mechanicalType") {
    //   this.isMechanicalType = !this.isMechanicalType;
    // } else {
    //   this.isMechanicalType = false;
    // }
    // if (event.name == "Others" && type == "mainMuscleWorkout") {
    //   this.isMainMuscleWorkout = !this.isMainMuscleWorkout;
    // } else {
    //   this.isMainMuscleWorkout = false;
    // }
  }
  onItemDeselect(event, type) {
    if(type == "workoutType"){
      this.asanaGoals = [];
      this.asanaModel.benifitsOfAsana = "";
      this.selectedasanaGoal= [];
      this.asanaModel.precautions = "";
      // this.asanaModel.instructions = "";
      this.showasanaGoal = false;
    }
     
    // if (event.name == "Others" && type == "workoutType") {
    //   this.isWorkoutType = !this.isWorkoutType;
    // }
    // if (event.name == "Others" && type == "mechanicalType") {
    //   this.isMechanicalType = !this.isMechanicalType;
    // }
    // if (event.name == "Others" && type == "mainMuscleWorkout") {
    //   this.isMainMuscleWorkout = !this.isMainMuscleWorkout;
    // }
  }
  getAllMasters() {
    var index = '/masters/';
    this.db_service.getDataFromServer('asanas', index).subscribe(
      response => {
        //console.log('Data Retrived succesfully.', response);
        for (let i = 0; i < response.length; i++) {
          if (response[i]['name'] == "yoga_asana"){
            this.asanaTypes = response[i]['values'];
          }
          if (response[i]['name'] == "MainMuscleWorkouts"){
            this.MainMuscleWorkouts = response[i]['values'];
          }
          if (response[i]['name'] == "Levels"){
            this.difficultLevel = response[i]['values'];
          }
           
     
        }

        if (this.asanaModel.asanaId != undefined && this.asanaModel.asanaId != "") {
          this.BindData();
          
        }
        
      },
      error => {
        console.log("An error has occurred while retriving masters data.");
      }
    )
  }
  SaveAsana() {
    //console.log(this.exercise.isPublic);
    var atleastOneAlpha;
    var setVal1: boolean = false;
    var setVal2: boolean = false;
    var setVal3: boolean = false;
    var setVal4: boolean = false;
    var setVal5: boolean = false;

    var setVal6: boolean = false;
    var setVal7: boolean = false;
    var setVal8: boolean = false;
    var setVal9: boolean = false;
    var setVal10: boolean = false;
    var isFilled = this.instructions.filter(x =>{
        if(x['name'] == "" || x['name'] == "" || x['name'] == undefined )
        return true;
    })
    if(isFilled.length == 0 || (isFilled.length != 0 && isFilled[0].name != ""))
    this.asanaModel.instructions = this.instructions;
    else{
      this.toastr.error('', 'Mandatory fields are required in Add Instructions.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return;
    }
    if (this.asanaModel.asanaTitle != undefined) {
      atleastOneAlpha = this.asanaModel.asanaTitle.match(".*[a-zA-Z]+.*");
    }
    if (this.setsVal > 0) {
      for (let i = 1; i <= this.setsVal; i++) {
        if (i == 1) {
          if (this.repsVal <= 0) {
            setVal1 = true;
          }
        }
        if (i == 2) {
          if (this.repsVal1 <= 0) {
            setVal2 = true;
          }
        }
        if (i == 3) {
          if (this.repsVal2 <= 0) {
            setVal3 = true
          }
        }
        if (i == 4) {
          if (this.repsVal3 <= 0) {
            setVal4 = true
          }
        }
        if (i == 5) {
          if (this.repsVal4 <= 0) {
            setVal5 = true
          }
        }
        if (i == 6) {
          if (this.repsVal5 <= 0) {
            setVal6 = true
          }
        }
        if (i == 7) {
          if (this.repsVal6 <= 0) {
            setVal7 = true
          }
        }
        if (i == 8) {
          if (this.repsVal7 <= 0) {
            setVal8 = true
          }
        }
        if (i == 9) {
          if (this.repsVal8 <= 0) {
            setVal9 = true
          }
        }
        if (i == 10) {
          if (this.repsVal9 <= 0) {
            setVal10 = true
          }
        }
      }
    }
    if (this.videoHidden == false && this.asanaVideo.youtubeUrl == "") {
      this.toastr.error('', 'Video is mandatory in "Add Video"', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return;
    }
    if (this.isYoutubeVerified == false && this.asanaVideo.youtubeUrl != "") {
      this.toastr.error('', 'Please click on upload icon to add the YouTube video in "Add Video"', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return;
    }
    else if (this.asanaModel.asanaTitle == null || this.asanaModel.asanaTitle == undefined || this.asanaModel.asanaTitle == '') {
      this.toastr.error('', 'Mandatory fields are required in Add Details', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
 
    else if (this.asanaModel.instructions == null || this.asanaModel.instructions == undefined || this.asanaModel.instructions.length == 0) {
      this.toastr.error('', 'Mandatory fields are required in Add Instructions', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    } else if (atleastOneAlpha == null) {
      this.toastr.error('', 'Please provide valid Asana name.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (this.setsVal == 0) {
      this.toastr.error('', 'Please select minimum set for this asana in Add Duration.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (setVal1 == true) {
      this.toastr.error('', 'Zero cannot be stored as minimum values for reps in SET 1', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (setVal2 == true) {
      this.toastr.error('', 'Zero cannot be stored as minimum values for reps in SET 2', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (setVal3 == true) {
      this.toastr.error('', 'Zero cannot be stored as minimum values for reps in SET 3', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (setVal4 == true) {
      this.toastr.error('', 'Zero cannot be stored as minimum values for reps in SET 4', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (setVal5 == true) {
      this.toastr.error('', 'Zero cannot be stored as minimum values for reps in SET 5', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (setVal6 == true) {
      this.toastr.error('', 'Zero cannot be stored as minimum values for reps in SET 6', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (setVal7 == true) {
      this.toastr.error('', 'Zero cannot be stored as minimum values for reps in SET 7', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (setVal8 == true) {
      this.toastr.error('', 'Zero cannot be stored as minimum values for reps in SET 8', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (setVal9 == true) {
      this.toastr.error('', 'Zero cannot be stored as minimum values for reps in SET 9', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (setVal10 == true) {
      this.toastr.error('', 'Zero cannot be stored as minimum values for reps in SET 10', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else {

      let regexpAlpha = new RegExp('^[A-Za-z ]*[A-Za-z][A-Za-z ]*$');
      if (regexpAlpha.test(this.asanaModel.asanaTitle) === false) {
        this.toastr.error('', 'Please provide valid asana title', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        return;
      }
      if (regexpAlpha.test(this.asanaModel.adoptedName) === false) {
        this.toastr.error('', 'Please provide valid asana adopted name', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        return;
      }



      if (this.selectedAsanaType != undefined) {
        if (this.selectedAsanaType.length != 0) {
          this.asanaModel.asanaType = this.selectedAsanaType[0];
        }
      }
      if (this.SelectedMainMuscleWorkout != undefined) {
        if (this.SelectedMainMuscleWorkout.length != 0) {
          // var selectedTargetMusleWorkoutList = [];
          // for(let i=0; i<this.SelectedMainMuscleWorkout.length; i++){
          //   var selectedTargetMusleWorkout = this.MainMuscleWorkouts.find(x => x["id"] == this.SelectedMainMuscleWorkout[i]["id"]);
          //   selectedTargetMusleWorkoutList.push(JSON.parse(JSON.stringify(selectedTargetMusleWorkout)))
          // }
          this.asanaModel.targetMuscle = this.SelectedMainMuscleWorkout;
        }
      }

     
      if (this.selectedDifficultLevels != undefined) {
        if (this.selectedDifficultLevels.length != 0) {
          this.asanaModel.difficultyLevel = this.selectedDifficultLevels[0];
        }
      }
      if (this.selectedasanaGoal != undefined) {
        if (this.selectedasanaGoal.length != 0) {
          this.asanaModel.asanaGoal = this.selectedasanaGoal;
        }
      }

     


      this.asanaVideo.youtubeUrl = this.asanaVideo.youtubeUrl;
      this.asanaModel.asanaVideo = this.asanaVideo;
      this.asanaModel.instructions = this.instructions;

      this.asanaModel.sets = [];

      for (let i = 1; i <= this.setsVal; i++) {
        if (i == 1) {
          this.set.breathValue.actual = this.breathsVal;
          this.set.minutesPeriod.actual = this.minutesVal;
          this.set.secondsPeriod.actual = this.secondsVal;
          this.set.reputationValue.actual = this.repsVal;
          this.set.setNo = i;
          this.asanaModel.sets.push(JSON.parse(JSON.stringify(this.set)));

        }
        if (i == 2) {
          this.set.breathValue.actual = this.breathsVal1;
          this.set.minutesPeriod.actual = this.minutesVal1;
          this.set.secondsPeriod.actual = this.secondsVal1;
          this.set.reputationValue.actual = this.repsVal1;
          this.set.setNo = i;
          this.asanaModel.sets.push(JSON.parse(JSON.stringify(this.set)));

        }
        if (i == 3) {
          this.set.breathValue.actual = this.breathsVal2;
          this.set.minutesPeriod.actual = this.minutesVal2;
          this.set.secondsPeriod.actual = this.secondsVal2;
          this.set.reputationValue.actual = this.repsVal2;
          this.set.setNo = i;
          this.asanaModel.sets.push(JSON.parse(JSON.stringify(this.set)));

        }
        if (i == 4) {
          this.set.breathValue.actual = this.breathsVal3;
          this.set.minutesPeriod.actual = this.minutesVal3;
          this.set.secondsPeriod.actual = this.secondsVal3;
          this.set.reputationValue.actual = this.repsVal3;
          this.set.setNo = i;
          this.asanaModel.sets.push(JSON.parse(JSON.stringify(this.set)));

        }
        if (i == 5) {
          this.set.breathValue.actual = this.breathsVal4;
          this.set.minutesPeriod.actual = this.minutesVal4;
          this.set.secondsPeriod.actual = this.secondsVal4;
          this.set.reputationValue.actual = this.repsVal4;
          this.set.setNo = i;
          this.asanaModel.sets.push(JSON.parse(JSON.stringify(this.set)));
        }
        if (i == 6) {
          this.set.breathValue.actual = this.breathsVal5;
          this.set.minutesPeriod.actual = this.minutesVal5;
          this.set.secondsPeriod.actual = this.secondsVal5;
          this.set.reputationValue.actual = this.repsVal5;
          this.set.setNo = i;
          this.asanaModel.sets.push(JSON.parse(JSON.stringify(this.set)));
        }
        if (i == 7) {
          this.set.breathValue.actual = this.breathsVal6;
          this.set.minutesPeriod.actual = this.minutesVal6;
          this.set.secondsPeriod.actual = this.secondsVal6;
          this.set.reputationValue.actual = this.repsVal6;
          this.set.setNo = i;
          this.asanaModel.sets.push(JSON.parse(JSON.stringify(this.set)));
        }
        if (i == 8) {
          this.set.breathValue.actual = this.breathsVal7;
          this.set.minutesPeriod.actual = this.minutesVal7;
          this.set.secondsPeriod.actual = this.secondsVal7;
          this.set.reputationValue.actual = this.repsVal7;
          this.set.setNo = i;
          this.asanaModel.sets.push(JSON.parse(JSON.stringify(this.set)));
        }
        if (i == 9) {
          this.set.breathValue.actual = this.breathsVal8;
          this.set.minutesPeriod.actual = this.minutesVal8;
          this.set.secondsPeriod.actual = this.secondsVal8;
          this.set.reputationValue.actual = this.repsVal8;
          this.set.setNo = i;
          this.asanaModel.sets.push(JSON.parse(JSON.stringify(this.set)));
        }
        if (i == 10) {
          this.set.breathValue.actual = this.breathsVal9;
          this.set.minutesPeriod.actual = this.minutesVal9;
          this.set.secondsPeriod.actual = this.secondsVal9;
          this.set.reputationValue.actual = this.repsVal9;
          this.set.setNo = i;
          this.asanaModel.sets.push(JSON.parse(JSON.stringify(this.set)));
        }
      }
      var index = '/asana';
      this.asanaModel.asanaImgUrl = "https://d1ojbumdvbscm0.cloudfront.net/yoga_asanas/yoga-icon.png";
      if (this.asanaModel.asanaId == undefined) {
        this.asanaModel.creationDate = new Date();
        this.asanaModel.lastUpdatedOn = new Date();
        // this.asanaModel.adoptedName = this.asanaModel.asanaTitle
        this.asanaModel.asanaStatus = "new";
        this.asanaModel.asanaPercentage = "0";
        this.asanaModel.asanaComments = [];
        this.yoga_db_service.saveDataToDB(this.asanaModel, index).subscribe(
          response => {
            this.asanaResponse = Array.of(response);
            // alert('Data saved successfully.');
            this.toastr.success('', 'Aasana has been saved successfully', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
              progressBar: true,
              progressAnimation: 'increasing',
              tapToDismiss: true,
            });
            this.router.navigate(['asanas']);
          },
          error => {
            // alert('there is an error while saving data');
            this.toastr.error('', 'There is an error while saving asana', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
              progressBar: true,
              progressAnimation: 'increasing',
              tapToDismiss: true,
            });
          }

        )
      } else {
        // var index = '/exercise/' + this.asanaModel.trainerId + '/' + this.asanaModel.asanaId + '/status';
        // this.db_service.getDataFromServer("", index).subscribe(response => {
        //   if (response.data == true) {
        //     this.isUpdateAsana = true;
        //   } else {
        //     this.onUpdateExercise("No");
        //   }
        // })
        this.onUpdateAsana("No");

      }
    }
  }
  onUpdateAsana(val) {
    // if (val == "Yes")
    //   this.exercise.isChangeAffects = true
    // else
    //   this.exercise.isChangeAffects = false;
    var index = '/asana';
    this.asanaModel.lastUpdatedOn = new Date();
    this.yoga_db_service.updateDatainDB(this.asanaModel, index).subscribe(
      response => {
        this.asanaResponse = Array.of(response);
        this.toastr.success('', 'Asana has been updated successfully', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        this.router.navigate(['asanas']);
        this.isUpdateAsana = false;
      },
      error => {
        // alert('there is an error while saving data');
        this.toastr.error('', 'There is an error while updating asana', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      }

    )
  }
  getAsanaData() {
    this.getAllMasters();
    var index = '/asana/';
    this.yoga_db_service.getDataFromServer(this.asanaModel.asanaId, index).subscribe(
      response => {
        // console.log('Data Retrived succesfully.', response);
        //response = Array.of(response);
        this.asanaModel = response.data;
        this.drgDropHidden = false;
        this.videoHidden = true;
       // this.editDisabled = true;
       this.editDisabled = false;
        this.showasanaGoal = true;
        this.setsVal = this.asanaModel.sets.length;
        this.instructions = this.asanaModel.instructions;

        for (let i = 0; i < this.asanaModel.sets.length; i++) {
          if (i == 0) {
            // this.weightsVal = this.exercise.sets[i]["maxWeights"]["actual"];
            // this.restPeriodVal = this.exercise.sets[i]["restPeriod"]["actual"];
            this.breathsVal = this.asanaModel.sets[i]["breathValue"]["actual"];
            this.minutesVal = this.asanaModel.sets[i]["minutesPeriod"]["actual"];
            this.secondsVal = this.asanaModel.sets[i]["secondsPeriod"]["actual"];
            this.repsVal = this.asanaModel.sets[i]["reputationValue"]["actual"];
          }
          if (i == 1) {
            this.breathsVal1 = this.asanaModel.sets[i]["breathValue"]["actual"];
            this.minutesVal1= this.asanaModel.sets[i]["minutesPeriod"]["actual"];
            this.secondsVal1 = this.asanaModel.sets[i]["secondsPeriod"]["actual"];
            this.repsVal1 = this.asanaModel.sets[i]["reputationValue"]["actual"];
          }
          if (i == 2) {
            this.breathsVal2 = this.asanaModel.sets[i]["breathValue"]["actual"];
            this.minutesVal2 = this.asanaModel.sets[i]["minutesPeriod"]["actual"];
            this.secondsVal2 = this.asanaModel.sets[i]["secondsPeriod"]["actual"];
            this.repsVal2 = this.asanaModel.sets[i]["reputationValue"]["actual"];
          }
          if (i == 3) {
            this.breathsVal3 = this.asanaModel.sets[i]["breathValue"]["actual"];
            this.minutesVal3 = this.asanaModel.sets[i]["minutesPeriod"]["actual"];
            this.secondsVal3 = this.asanaModel.sets[i]["secondsPeriod"]["actual"];
            this.repsVal3 = this.asanaModel.sets[i]["reputationValue"]["actual"];
          }
          if (i == 4) {
            this.breathsVal4 = this.asanaModel.sets[i]["breathValue"]["actual"];
            this.minutesVal4 = this.asanaModel.sets[i]["minutesPeriod"]["actual"];
            this.secondsVal4 = this.asanaModel.sets[i]["secondsPeriod"]["actual"];
            this.repsVal4 = this.asanaModel.sets[i]["reputationValue"]["actual"];
          }
          if (i == 5) {
            this.breathsVal5 = this.asanaModel.sets[i]["breathValue"]["actual"];
            this.minutesVal5 = this.asanaModel.sets[i]["minutesPeriod"]["actual"];
            this.secondsVal5 = this.asanaModel.sets[i]["secondsPeriod"]["actual"];
            this.repsVal5 = this.asanaModel.sets[i]["reputationValue"]["actual"];
          }
          if (i == 6) {
            this.breathsVal6 = this.asanaModel.sets[i]["breathValue"]["actual"];
            this.minutesVal6 = this.asanaModel.sets[i]["minutesPeriod"]["actual"];
            this.secondsVal6 = this.asanaModel.sets[i]["secondsPeriod"]["actual"];
            this.repsVal6 = this.asanaModel.sets[i]["reputationValue"]["actual"];
          }
          if (i == 7) {
            this.breathsVal7 = this.asanaModel.sets[i]["breathValue"]["actual"];
            this.minutesVal7 = this.asanaModel.sets[i]["minutesPeriod"]["actual"];
            this.secondsVal7 = this.asanaModel.sets[i]["secondsPeriod"]["actual"];
            this.repsVal7 = this.asanaModel.sets[i]["reputationValue"]["actual"];
          }
          if (i == 8) {
            this.breathsVal8 = this.asanaModel.sets[i]["breathValue"]["actual"];
            this.minutesVal8 = this.asanaModel.sets[i]["minutesPeriod"]["actual"];
            this.secondsVal8 = this.asanaModel.sets[i]["secondsPeriod"]["actual"];
            this.repsVal8 = this.asanaModel.sets[i]["reputationValue"]["actual"];
          }
          if (i == 9) {
            this.breathsVal9 = this.asanaModel.sets[i]["breathValue"]["actual"];
            this.minutesVal9 = this.asanaModel.sets[i]["minutesPeriod"]["actual"];
            this.secondsVal9 = this.asanaModel.sets[i]["secondsPeriod"]["actual"];
            this.repsVal9 = this.asanaModel.sets[i]["reputationValue"]["actual"];
          }
        }
        if (this.asanaModel.asanaType != null) {
          this.selectedAsanaType = Array.of(this.asanaModel.asanaType).sort();
         
        }
    
        if (this.asanaModel.targetMuscle != null) {
          this.SelectedMainMuscleWorkout = this.asanaModel.targetMuscle;
        }
    
        if (this.asanaModel.difficultyLevel != null) {
          this.selectedDifficultLevels = Array.of(this.asanaModel.difficultyLevel).sort();
        }
        this.asanaVideo = this.asanaModel.asanaVideo;
        if (this.asanaVideo.youtubeUrl == "" && this.asanaVideo.videoDestinationCheck == false) {
          var dest = this.asanaVideo.videoMp4Destination.split("/");
          var FolderPath = dest[3] + "/" + dest[4] + "/" + dest[5] + "/" + dest[6] + "/" + dest[7];
          this.awsService.checkUrl(this.globals.DEST_BUCKET, FolderPath).subscribe(
            response => {

              if (response != "false") {
                this.videoUrl = this.asanaVideo.videoMp4Destination;
                this.asanaVideo.videoDestinationCheck = true;
                this.asanaVideo.exerciseVideoSource = this.asanaVideo.videoMp4Destination;
                this.updateAsana(this.asanaModel);
              }
            },
            error => {
              this.asanaVideo.exerciseVideoSource = this.asanaVideo.videoSourceUrl;
            }
          );
        } else if (this.asanaVideo.youtubeUrl != "") {

          this.changeYoutubeURL();
          this.videoHidden = false;
          this.asanaVideo.exerciseVideoSource = this.asanaVideo.youtubeUrl;

        } else {
          this.asanaVideo.exerciseVideoSource = this.asanaVideo.videoMp4Destination;
        }
    
     
        if (this.asanaModel.asanaGoal != null) {
          this.selectedasanaGoal = this.asanaModel.asanaGoal;
        }
      },
      error => {
        //alert("An error has occurred while retriving exercise data.");
        this.toastr.error('', 'An error has occurred while retriving exercise data.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      })

  }
  isInArray(array, word) {
    return array.indexOf(word.toLowerCase()) > -1;
  }
  changeYoutubeURL() {
    this.isYoutubeVerified = true;

      // Check for empty input before calling embedService methods
    if (!this.asanaVideo.youtubeUrl.trim()) {
       this.toastr.error('', 'Please provide a valid Youtube video URL', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return; // Prevent further execution if empty
    }

    //console.log(event);
    //console.log(this.embedService.embed(this.exercise.youtubeUrl));
    this.iframeURL = this.embedService.embed(this.asanaVideo.youtubeUrl, {
      query: { portrait: 0, color: 'black' },
      attr: { width: "100%", height: 450, frameborder: 1, style: "border: 1px solid black;border-radius: 7px; box-shadow: 0 0px 20px rgba(154, 246, 123, 0.3);" }
    })
    // console.log(this.iframeURL);
    this.asanaVideo.asanaVideoSource = this.asanaVideo.youtubeUrl;
    if (this.iframeURL != undefined) {
      this.embedYoutubeUrl = this.iframeURL;
      this.embedService
        .embed_image(this.asanaVideo.youtubeUrl,
          { image: 'mqdefault' }
        )
        .then(res => {
          this.asanaVideo.videoThumbnailPath = res.link;
        });
      this.drgDropHidden = false;
      this.iframeHidden = true;
    } else {
      this.toastr.error('', 'Please provide a valid Youtube video URL', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    //console.log(this.iframeURL);
  }
  selectFile(file:any,filetype) {
    // this.selectedFile = event.target.files[0];
    // console.log(event);
    this.selectedFile = file;
    //var format = /[!@#$%^&*()_+\=\[\]{};':"\\|,<>\/?]/;
    console.log("the file size : " + this.selectedFile[0].size);
    // var videofileCheck = format.test(this.selectedFile[0].name);
    // if (videofileCheck == true) {
    //   this.toastr.error('', 'Special charecters are not allowed in video file name.', {
    //     timeOut: 3000,
    //     positionClass: 'toast-top-right',
    //     progressBar: true,
    //     progressAnimation: 'increasing',
    //     tapToDismiss: true,
    //   });
    // }
    // else if(this.selectedFile[0].size > 35000000 ){
    //   this.toastr.error('', 'Maximum upload file size is 35 Mb', {
    //     timeOut: 3000,
    //     positionClass: 'toast-top-right',
    //     progressBar: true,
    //     progressAnimation: 'increasing',
    //     tapToDismiss: true,
    //   });
    // }
    //else {
    this.fileExtension = this.selectedFile[0].name.split('.').pop();
    if (this.isInArray(this.globals.VideoallowedExtensions, this.fileExtension)) {
      if (this.asanaVideo.VideoName != undefined && this.asanaVideo.asanaVideoName != "") {
        var videoName = this.asanaVideo.asanaVideoName.replace(/ /g, "_");
        this.awsService.deleteFileFromS3(videoName, this.asanaModel.trainerId, this.globals.SORCE_BUCKET, this.globals.EXERCISE_FOLDER).subscribe(
          response => {
            //this.fileUpload(filetype);
            //console.log('Video deleted successfully.', response);
            var assetId = this.asanaVideo.videoMp4Destination.split("/");
            this.awsService.deleteDestinationFolder(this.asanaModel.trainerId, this.globals.DEST_BUCKET, this.globals.EXERCISE_FOLDER, assetId[6]).subscribe(
              response => {
                // this.exercise.preVideoId = this.exercise.videoId;
                this.fileUpload(filetype);
              },
              error => {
                console.log('There is an error deleting video from destination folder.', error);
              })

          },
          error => {
            console.log('There is an error deleting video from source folder.', error);
          })
      } else {
        this.fileUpload(filetype);
      }
    } else {
      this.toastr.error('', 'Please upload only videos with mp4 format.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    // }

  }
  fileUpload(filetype) {
    this.loaderHide = !this.loaderHide;
    this.asanaVideo.asanaVideoName = this.selectedFile[0].name;
    this.asanaVideo.videoDestinationCheck = false;
    this.awsService.uploadFileToS3(this.selectedFile[0], this.asanaModel.trainerId, this.globals.SORCE_BUCKET, this.globals.EXERCISE_FOLDER, filetype).subscribe(
      response => {
        //this.profile.key = response.Key;
        this.asanaVideo.videoSourceUrl = response.sourceUrl;
        this.asanaVideo.asanaVideoSource = response.sourceUrl;
        this.asanaVideo.videoMp4Destination = response.mp4Destination;
        this.asanaVideo.videoThumbnailPath = response.thumbnailDestination;
        this.thumbUrl = response.thumbnailDestination;
        // this.videoUrl = response.sourceUrl;
        this.loaderHide = !this.loaderHide;
        // console.log('Video uploaded Successfully.', response);
        this.toastr.success('', 'Video uploaded successfully.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        this.drgDropHidden = false;
        this.videoHidden = true;
      },
      error => {
        this.loaderHide = !this.loaderHide;
        console.log('There is an error uploading video.', error);
        this.toastr.error('', 'There is an error while uploading video.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        this.drgDropHidden = true;
        this.videoHidden = false;
      })
  }
  onVideoPopupClose(){
    this.videoHidden = false;
    this.iframeHidden = false;
    this.drgDropHidden = true;
  }
  Cancel() {
    this.router.navigate(['asanas']);
  }
  updateAsana(data) {
    var index = '/asana'
    this.asanaVideo.asanaType = this.selectedAsanaType[0];
    this.asanaModel.targetMuscle = this.SelectedMainMuscleWorkout;
    this.asanaModel.difficultyLevel = this.selectedDifficultLevels[0];
    this.asanaVideo.youtubeUrl = this.asanaVideo.youtubeUrl;
    this.asanaModel.asanaVideo = this.asanaVideo;
    this.asanaModel.sets = [];

    // this.breathsVal = this.exercise.sets[i]["breathValue"]["actual"];
    //         this.minutesVal = this.exercise.sets[i]["minutesPeriod"]["actual"];
    //         this.secondsVal = this.exercise.sets[i]["secondsPeriod"]["actual"];
    for (let i = 1; i <= this.setsVal; i++) {
      if (i == 1) {
        this.set.breathValue.actual = this.breathsVal;
        this.set.minutesPeriod.actual = this.minutesVal;
        this.set.secondsPeriod.actual = this.secondsVal;
        this.set.reputationValue.actual = this.repsVal;
        this.set.setNo = i;
        this.asanaModel.sets.push(JSON.parse(JSON.stringify(this.set)));

      }
      if (i == 2) {
        this.set.breathValue.actual = this.breathsVal1;
        this.set.minutesPeriod.actual = this.minutesVal1;
        this.set.secondsPeriod.actual = this.secondsVal1;
        this.set.reputationValue.actual = this.repsVal1;
        this.set.setNo = i;
        this.asanaModel.sets.push(JSON.parse(JSON.stringify(this.set)));

      }
      if (i == 3) {
        this.set.breathValue.actual = this.breathsVal2;
        this.set.minutesPeriod.actual = this.minutesVal2;
        this.set.secondsPeriod.actual = this.secondsVal2;
        this.set.reputationValue.actual = this.repsVal2;
        this.set.setNo = i;
        this.asanaModel.sets.push(JSON.parse(JSON.stringify(this.set)));

      }
      if (i == 4) {
        this.set.breathValue.actual = this.breathsVal3;
        this.set.minutesPeriod.actual = this.minutesVal3;
        this.set.secondsPeriod.actual = this.secondsVal3;
        this.set.reputationValue.actual = this.repsVal3;
        this.set.setNo = i;
        this.asanaModel.sets.push(JSON.parse(JSON.stringify(this.set)));

      }
      if (i == 5) {
        this.set.breathValue.actual = this.breathsVal4;
        this.set.minutesPeriod.actual = this.minutesVal4;
        this.set.secondsPeriod.actual = this.secondsVal4;
        this.set.reputationValue.actual = this.repsVal4;
        this.set.setNo = i;
        this.asanaModel.sets.push(JSON.parse(JSON.stringify(this.set)));
      }
      if (i == 6) {
        this.set.breathValue.actual = this.breathsVal5;
        this.set.minutesPeriod.actual = this.minutesVal5;
        this.set.secondsPeriod.actual = this.secondsVal5;
        this.set.reputationValue.actual = this.repsVal5;
        this.set.setNo = i;
        this.asanaModel.sets.push(JSON.parse(JSON.stringify(this.set)));
      }
      if (i == 7) {
        this.set.breathValue.actual = this.breathsVal6;
        this.set.minutesPeriod.actual = this.minutesVal6;
        this.set.secondsPeriod.actual = this.secondsVal6;
        this.set.reputationValue.actual = this.repsVal6;
        this.set.setNo = i;
        this.asanaModel.sets.push(JSON.parse(JSON.stringify(this.set)));
      }
      if (i == 8) {
        this.set.breathValue.actual = this.breathsVal7;
        this.set.minutesPeriod.actual = this.minutesVal7;
        this.set.secondsPeriod.actual = this.secondsVal7;
        this.set.reputationValue.actual = this.repsVal7;
        this.set.setNo = i;
        this.asanaModel.sets.push(JSON.parse(JSON.stringify(this.set)));
      }
      if (i == 9) {
        this.set.breathValue.actual = this.breathsVal8;
        this.set.minutesPeriod.actual = this.minutesVal8;
        this.set.secondsPeriod.actual = this.secondsVal8;
        this.set.reputationValue.actual = this.repsVal8;
        this.set.setNo = i;
        this.asanaModel.sets.push(JSON.parse(JSON.stringify(this.set)));
      }
      if (i == 10) {
        this.set.breathValue.actual = this.breathsVal9;
        this.set.minutesPeriod.actual = this.minutesVal9;
        this.set.secondsPeriod.actual = this.secondsVal9;
        this.set.reputationValue.actual = this.repsVal9;
        this.set.setNo = i;
        this.asanaModel.sets.push(JSON.parse(JSON.stringify(this.set)));
      }

    }
    this.asanaModel.lastUpdatedOn = new Date();

    this.yoga_db_service.updateDatainDB(this.asanaModel, index).subscribe(
      response => {
        console.log('destVideoCheck updated succesfully.');
      },
      error => {
        console.log('there is an error while updating destVideoCheck');
      }

    )
  }
  closePopup() {
    this.isUpdateAsana = false;
    // this.exercise.isChangeAffects = false;
    // this.videoHidden = false;
    // this.drgDropHidden = true;
  }

  toggleCollapsed(): void {
    // Bootstrap uses display CSS property to effect the collapse, so we need this to manually trigger a refresh
    this.manualRefresh.emit();
  }

  // onDragOver(event:DragEvent):void {
  //   event.preventDefault();
  // }
}
