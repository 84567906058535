<div id="mydiv" *ngIf="loaderHide">
  <img src="assets/images/landing_img.gif" width="10%" class="ajax-loader" />
</div>
<div class="container login-div-style text-center mb-3">
  <img _ngcontent-c1="" class="img-responsive mb-3" src="assets/images/logov1.gif" style="width:150px;">
  
  <div class="row">
    <div class="col-11 col-sm-12 col-md-6 col-lg-6 login__wrapper m-auto" *ngIf="forgetPassPageShow">
      <h1 class="text-center login-heading-style"><span>Forgot Password</span></h1>
      <div class="login__form-section">
        <form class="" #forgetPassword="ngForm">
          <div class="signUp__fileds">
            <div class="form-group fit-my-4">
              <input type="email" id="inputEmail" autocomplete="off" class="login-form-control col-12" style="border-width: 1px;"
                placeholder="Email*" #useremail="ngModel" [(ngModel)]="forgetEmail" name="useremail"
                pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
              <i class="fas fa-envelope login-fa-icon-style"></i>
              <div *ngIf="useremail.invalid && (useremail.dirty || useremail.touched)" class="alert alert-danger">
                  <!-- <div *ngIf="useremail.errors?.required">
                    Email is required
                  </div> -->
                  <div *ngIf="useremail.errors?.pattern">
                    Please include '@' in the Email.
                    </div>
              </div>
            </div>
          </div>
          <div class="text-center btn-margin">
            <button class="btn btn-circle nextBtn" (click)="onNext()" id="frgtPassSubmit" type="submit"><i class="fas fa-angle-right"></i></button>
          </div>
        </form>
      </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-6 login__wrapper m-auto" *ngIf="forgetPassPageHide">
      <h1 class="heading1 text-center login-heading-style"><span>Forgot Password</span></h1>

      <div class="login__form-section">
        <!-- <form class="" (ngsubmit) ="f.form.valid && onRegister()" #f="ngForm"> -->
        <form class="">
          <div class="forgetPass__fileds">
            <div class="form-group fit-my-4">
              <input type="text" id="confirmationCode" autocomplete="off" class="login-form-control col-12" style="border-width: 1px;"
                placeholder="Confirmation Code*" autocomplete="off" #Code="ngModel" [(ngModel)]="confirmationCode" name="confirmationCode" pattern="\d{6}">
              <i class="fas fa-check-circle login-fa-icon-style"></i>
              <!-- <div *ngIf="Code.invalid && (Code.dirty || Code.touched)" class="alert alert-danger">
                <div *ngIf="Code.errors?.required">
                  Confirmation code is required
                </div>
              </div> -->
              <div *ngIf="Code.invalid && (Code.dirty || Code.touched)" class="alert alert-danger">
                <!-- <div *ngIf="pincode.errors?.required">
                  Pincode is mandatory
                </div> -->
                <div *ngIf="Code.errors?.pattern">
                  Please enter 6 digit code sent to your email.
                </div>
              </div>
            </div>
            <div class="form-group fit-my-4">
              <input type="password" id="newPassword" autocomplete="off" class="login-form-control col-12" style="border-width: 1px;"
                placeholder="New Password*" #newPassword1="ngModel" [(ngModel)]="newPassword" name="newPassword"
                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[a-zA-Z0-9\S]{8,}$">
              <!-- <span class="floating-label"> Password</span> -->
              <i class="fas fa-key login-fa-icon-style"></i>
              <div *ngIf="newPassword1.invalid && (newPassword1.dirty || newPassword1.touched)" class="alert alert-danger">
                <!-- <div class="error_alert_msg error_alet_msg_show">
                All fields are required.
              </div> -->
                <!-- <div *ngIf="newPassword1.errors?.required">
                  Password is required
                </div> -->
                <!-- <div *ngIf="useremail.errors?.minlength && useremail.touched">
                  E-mail ID should have Minimum 1 Character
              </div> -->
                <div *ngIf="newPassword1.errors?.pattern">
                  Password must be at least 8 characters with 1 uppercase, 1 number, and 1 special character.
                  <!-- - Password require uppercase letters
                  - Password require lowercase letters
                  - Password Require numbers
                  - Password require special character -->
                </div>
              </div>
            </div>
          </div>
          <div class="text-center btn-margin">
            <button class="btn btn-default submitBtn" (click)="onConfirmRegistration()" id="confirmRegistration" type="submit">Reset
              Password</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>