<div id="mydiv" *ngIf="loaderHide">
  <img src="assets/images/landing_img.gif" width="10%" class="ajax-loader" />
</div>
<div class="container">
  <div class="row">
    <div class="col-sm-12 col-md-6 col-lg-4 login__wrapper m-auto">
      <h1 class="heading1 text-center"><span>Sign In</span></h1>
      <div class="login__form-section">
        <form autocomplete="off">
          <div class="login__fileds">
            <div class="form-group">
              <input type="email" id="inputEmail"  autocomplete="off" class="form-control col-12  user__name" #useremail="ngModel"
                style="border-width: 1px;" [(ngModel)]="username" name="username" placeholder="Uername*">
              <i class="fas fa-envelope"></i>
            </div>
            <div class="form-group">
              <input type="password" id="inputPassword" autocomplete="nope" class="form-control col-12" style="border-width: 1px;"
                [(ngModel)]="password" name="password" #userPassword="ngModel" placeholder="Password*" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[a-zA-Z0-9\S]{8,}$">
              <!-- <span class="floating-label"><i class="fas fa-key"></i> Password</span> -->
              <i class="fas fa-key"></i>
              <div *ngIf="userPassword.invalid && (userPassword.dirty || userPassword.touched)" class="alert alert-danger">

                <div *ngIf="userPassword.errors?.pattern">
                  Password must be at least 8 characters with 1 uppercase, 1 number, and 1 special character.
                </div>
              </div>
            </div>
          </div>
          <div class="text-center btn-margin">
            <button class="btn btn-circle nextBtn" (click)="onLogin()" id="signupSubmit" type="submit"><i class="fas fa-angle-right"></i></button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
