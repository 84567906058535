import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { UserLoginService } from "../service/user-login.service";
import { LoggedInCallback } from "../service/cognito.service";
import { DataService } from "./../service/data.service";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-gender',
  templateUrl: './gender.component.html',
  styleUrls: ['./gender.component.css']
})
export class GenderComponent implements OnInit {
  private sub: any;
  email: String;
  userID: string;
  genType: string;
  showTickBody: string;
  showTickYoga: string;
  showTickZumba: string;
  categorySelected: any = [];
  showPrimary: boolean = false;
  // dropdown settings
  dropdownSettings_single: any = {};
  closeDropdownSelection = false;
  primaryCategory: any = [];
  selectedPrimary: any = [];
  // event emitter from child to parent for category object

  constructor(public router: Router, public route: ActivatedRoute, public userService: UserLoginService, private toastr: ToastrService, private data: DataService) {
    // this.userService.isAuthenticated(this);
    this.router = router;
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.email = params['username'];
      this.userID = params['userID'];
    });

    // dropdown settings on load
    this.dropdownSettings_single = {
      singleSelection: true,
      enableCheckAll: false,
      idField: 'id',
      textField: 'name',
      allowSearchFilter: false,
      itemsShowLimit: 1,
      closeDropDownOnSelection: this.closeDropdownSelection
    };
    // this.primaryCategory = [{"id":"0","name":"Fitness"},
    // {"id":"1","name":"Yoga"},
    // {"id":"2","name":"Zumba"}]
  }
  genderSelect(type) {
    // this.router.navigate(["category",this.email,this.userID,type]);
    this.genType = type;
  }
  categorySelect(catType) {
    // this.router.navigate(["profile", this.email, this.userID, this.genType, catType]);
    if (catType == "Bodybuilder") {
      // this.showTickBody = true;
      this.showTickBody = this.showTickBody === catType ? undefined : catType;
      if (this.showTickBody == "Bodybuilder") {
        this.categorySelected.push({ category_name: "Bodybuilder", primary: false });
        this.primaryCategory.push({ "id": "0", "name": "Fitness" });
      }
      if (this.showTickBody == undefined) {
        this.categorySelected = this.categorySelected.filter(({ category_name }) => category_name !== "Bodybuilder");
        this.primaryCategory = this.primaryCategory.filter(({ id }) => id !== "0");
      }
    }
    if (catType == "Yoga") {
      // this.showTickYoga = true;
      this.showTickYoga = this.showTickYoga === catType ? undefined : catType;
      if (this.showTickYoga == "Yoga") {
        this.categorySelected.push({ category_name: "Yoga", primary: false });
        this.primaryCategory.push({ "id": "1", "name": "Yoga" });
      }
      if (this.showTickYoga == undefined) {
        this.categorySelected = this.categorySelected.filter(({ category_name }) => category_name !== "Yoga");
        this.primaryCategory = this.primaryCategory.filter(({ id }) => id !== "1");
      }
    }
    if (catType == "Zumba") {
      // this.showTickZumba = true;
      this.showTickZumba = this.showTickZumba === catType ? undefined : catType;
      if (this.showTickZumba == "Zumba") {
        this.categorySelected.push({ category_name: "Zumba", primary: false });
        this.primaryCategory.push({ "id": "2", "name": "Zumba" });
      }
      if (this.showTickZumba == undefined) {
        this.categorySelected = this.categorySelected.filter(({ category_name }) => category_name !== "Zumba");
        this.primaryCategory = this.primaryCategory.filter(({ id }) => id !== "2");

      }
    }
  }
  onInfoSubmit() {
    //console.log(this.categorySelected);
    if (this.primaryCategory.length == 0) {
      this.toastr.error('', ' Please select any category', {
        timeOut: 5000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (this.primaryCategory.length == 1) {
      this.categorySelected[0].primary = true;
      this.data.changeMessage(this.categorySelected);
      localStorage.setItem("CategoryItems",JSON.stringify(this.categorySelected));
      this.router.navigate(["profile", this.email, this.userID, this.genType]);
    }
    else {
      this.showPrimary = true;
    }
  }
  closePopup() {
    this.showPrimary = false;
  }
  onCategory() {
    if (this.selectedPrimary.length == 0) {
      this.toastr.error('', 'Please select primary category.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    } else {
      var cat_name = this.selectedPrimary[0].name;
      // Assuming updateWith is the object that you want to update with
      if (cat_name == 'Fitness') {
        const targetIdx = this.categorySelected.findIndex(item => item.category_name == "Bodybuilder");
        this.categorySelected[targetIdx].primary = true;
      }
      else {
        const targetIdx = this.categorySelected.findIndex(item => item.category_name == cat_name);
        this.categorySelected[targetIdx].primary = true;
      }
      // console.log(this.categorySelected);
      // this.messageFromGender.emit(this.categorySelected);

      this.data.changeMessage(this.categorySelected);
      localStorage.setItem("CategoryItems",JSON.stringify(this.categorySelected));
      this.router.navigate(["profile", this.email, this.userID, this.genType]);
    }
  }
}
