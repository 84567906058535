import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { ChallengeParameters, CognitoCallback, LoggedInCallback } from "../service/cognito.service";
import { UserLoginService } from "../service/user-login.service";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgetpass',
  templateUrl: './forgetpass.component.html',
  styleUrls: ['./forgetpass.component.css']
})
export class ForgetpassComponent implements CognitoCallback, OnInit, OnDestroy {
  confirmationCode: string;
  newPassword: string;
  email: string;
  private sub: any;
  forgetEmail: string;
  forgetPassPageShow: boolean = true;
  forgetPassPageHide: boolean = false;
  loaderHide: boolean = false;
  constructor(public router: Router, public route: ActivatedRoute, public userService: UserLoginService, private toastr: ToastrService) {
    // console.log("email from the url: " + this.email);
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.email = params['username'];
    });
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  onNext() {
    if (this.forgetEmail == null || this.forgetEmail == '') {
      this.toastr.error('', 'Mandatory fields are required', {
        timeOut: 5000,
        extendedTimeOut: 1000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true
      });
    }
    else {
      this.loaderHide = !this.loaderHide;
      this.userService.forgotPassword(this.forgetEmail, this);

    }
  }
  // cognitoCallback(message: string, result: any) {
  //   if (message == null && result == null) { //error
  //       this.router.navigate(['/home/forgotPassword', this.email]);
  //   } else { //success
  //       this.errorMessage = message;
  //   }
  // }
  onConfirmRegistration() {
    // this.errorMessage = null;
    if (this.confirmationCode == null || this.newPassword == null) {
      this.toastr.error('', 'Mandatory fields are required', {
        timeOut: 5000,
        extendedTimeOut: 1000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true
      });
    }
    else {
      this.loaderHide = !this.loaderHide;
      this.userService.confirmNewPassword(this.forgetEmail, this.confirmationCode, this.newPassword, this);
    }
  }
  cognitoCallback(message: string, result: any) {
    // console.log(message)

    if (message == null && result == null) { //error

      this.loaderHide = !this.loaderHide;
      // this.router.navigate(['/home/forgotPassword', this.email]);
      this.forgetPassPageHide = !this.forgetPassPageHide;
      this.forgetPassPageShow = !this.forgetPassPageShow;
    }
    else if (message == "SUCCESS") { //success

      this.loaderHide = !this.loaderHide;
      this.toastr.success('', 'Password updated successfully', {
        timeOut: 5000,
        extendedTimeOut: 1000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true
      });

      this.router.navigate(['/login']);
    }
    else if (message == "Attempt limit exceeded, please try after some time.") {

      this.loaderHide = !this.loaderHide;
      this.toastr.error('', 'Attempt limit exceeded, please try after some time.', {
        timeOut: 5000,
        extendedTimeOut: 1000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true
      });
    }
    else if (message == "Invalid code provided, please request a code again.") {

      this.loaderHide = !this.loaderHide;
      this.toastr.error('', 'Invalid code provided', {
        timeOut: 5000,
        extendedTimeOut: 1000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true
      });
    }
    else if (message != null) { //error
      //  this.errorMessage = message;

      this.loaderHide = !this.loaderHide;
      //  console.log("result: " + message);
      this.toastr.error('', message, {
        timeOut: 5000,
        extendedTimeOut: 1000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true
      });
    }

    else {

    }
  }

}
