import { Injectable } from '@angular/core';
import { Observable, of } from "rxjs";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Nutrition } from '../common/nutrition';
import { AppGlobals } from "../app.globals";


@Injectable()
export class NutritionService {
    private _reqOptionsArgs = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    constructor(private http: HttpClient, private globals: AppGlobals) { }

    public findNutrition(description: string): Observable<Nutrition[]> {
        return this.http.get<Nutrition[]>(this.globals.NUTRITION_SERVER_LINK + '/NIN/Nutrition/search/trainer/' + description + "/TRUE", this._reqOptionsArgs).pipe(catchError(error => {
            //  console.error( JSON.stringify( error ) );
            return Observable.throw(error);
        }));
    }
    getDataFromServer(id, index): Observable<any> {
        return this.http.get(this.globals.NUTRITION_SERVER_LINK + index + id);
    }

}