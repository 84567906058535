<section>
  <div class="container-fluid">
    <div class="px-5 mt-5">
      <div class="row">
        <div class="col">
          <div class="row">
            <div class="container-fluid">
              <div>
                <div class="heading1 mb-4" style="display: flex; float: left;">
                  <!-- <img width="90%" src="assets/images/icons/scheduler.png"/> -->
                  <img
                    src="assets/images/icons/scheduler.png"
                    style="border: 2px solid #8bc53f; border-radius: 50%;"
                  />
                  <h1><b>Scheduler</b></h1>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div>
                <button
                  type="button"
                  class="btn btn-circle prevBtn"
                  (click)="decreaseMonth()"
                >
                  <i class="fas fa-angle-left"></i>
                </button>
                <span
                  style="font-size: 22px; font-weight: bold; padding: 10px;"
                >
                  {{ displayMonth }} {{ displayYear }}
                </span>
                <button
                  type="button"
                  class="btn btn-circle nextBtn"
                  id="nextBtn"
                  (click)="increaseMonth()"
                >
                  <i class="fas fa-angle-right"></i>
                </button>
                <!-- <button
                  type="button"
                  class="btn btn-default save-exercise-btn"
                  style="margin-top: 10px; float: right;"
                  routerLink="/program"
                >
                  BACK
                </button> -->
              </div>
            </div>
            <div class="calendar__table">
              <div class="head_layout">
                <div
                  class="head_row"
                  fxLayout="row wrap"
                  fxLayoutAlign="flex-start"
                  style="width: 100%;"
                >
                  <!-- <div class="head_content" style="width: 10.5%;"></div> -->
                  <ng-container *ngFor="let dayName of calendarDayNames">
                    <div
                      class="head_content"
                      fxFlex="0 1 calc(14.2%)"
                      style="color: rgb(255, 255, 255); font-weight: 700;"
                    >
                      {{ dayName }}
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="body_layout d-flex" style="width: 100%;">
                <div
                  class="body_row"
                  fxLayout="row wrap"
                  fxLayoutAlign="flex-start"
                  style="width: 100%;"
                >
                  <ng-container *ngFor="let day of calendar; let i = index">
                    <div
                      [ngClass]="{
                        'past-month': day.isPastMonth,
                        today: day.isToday
                      }"
                      class="body_content"
                      fxFlex="0 1 calc(14.21%)"
                      style="padding: 5px;"
                    >
                      <div class="cal-day">
                        <strong
                          [ngClass]="{
                            'past-month': day.isPastMonth,
                            today: day.isToday
                          }"
                          >{{ day.date.getDate() }}
                        </strong>
                      </div>
                      <div
                        class="edit-day"
                        (click)="onAddSlot(day.date)"
                      >
                        <i class="fa fa-pencil-square-o"></i>
                        <strong> Edit</strong>
                      </div>
                      <div class="time-slot-day">
                        <div class="time-slot-name col-sm-8 col-md-7 p-0">
                          1:00 to 2:00
                        </div>
                        <div class="col-sm-4 col-md-5 text-right p-0">
                          <a>
                            <img class="time-slot-img" src="assets/images/icons/user-do.png"/>
                          </a>
                          <a>
                            <img class="time-slot-img" src="assets/images/icons/user-no.png"/>
                          </a>
                          <a>
                            <img class="time-slot-img" src="assets/images/icons/group.png"/>
                          </a>
                          <a>
                            <img class="time-slot-img" src="assets/images/icons/edit-pencil.png"/>
                          </a>
                          <a>
                            <img class="time-slot-img" src="assets/images/icons/edit-do.png"/>
                          </a>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="showAddSlot">
      <div class="workout-popup__wrapper">
      <div class="calendar-sched-pop p-0 zoomIn exercise-vid-popup background-shadow-green" style="top: 10% !important;">

      <a (click)="closePopup()" class="close-program_event-pop" style="position: absolute; left: 94%; top: 1%;"><i class="fas fa-times" ></i></a>
      <div class="workout-body">
      <div class="container-fluid" style="font-size: 15px;">
      <div class="row">
      <div class="col-md-12 py-2 d-flex" style="border-bottom: 1px dashed #919191;">
      <div class="col-md-6 p-0 text-left font-weight-bolder" style="font-size: 18px;">Add Slot
      </div>
      <div class="col-md-6 p-0 text-right">
      </div>
      </div>
      <div class="col-md-10 mx-auto mt-2">
      <div class="row">
      <div class="col-md-5 py-1 p-0 d-flex text-right">
      <input id="radio-1" name="radio" type="radio" checked style="margin: 5px; width: 15px;">
      <label for="radio-1" class="radio-label">1o1 Call</label>
      </div>
      <div class="col-md-6 py-1 p-0 d-flex text-left">
      <input id="radio-1" name="radio" type="radio" style="margin: 5px; width: 15px;">
      <label for="radio-1" class="radio-label">Group Call</label>
      </div>
      </div>

      </div>

      <div class="col-md-12 mt-2">
      <div class="row">
      <div class="form-group col-md-6 ">
      <input class="form-control col-12" type=time style="border: 1px solid #9acd32 !important; border-radius: 5px !important;">
      <span class="floating-label" style="top: -14px !important; left: 21px !important; background: #000 !important;">Start time</span>
      <i class="fa fa-clock-o" aria-hidden="true" style="position: absolute; left: 160px; top: 11px;"></i>
      </div>
      <div class="form-group col-md-6 ">
      <input class="form-control col-12" type=time style="border: 1px solid #9acd32 !important; border-radius: 5px !important;">
      <span class="floating-label" style="top: -14px !important; left: 21px !important; background: #000 !important;">End time</span>
      <i class="fa fa-clock-o" aria-hidden="true" style="position: absolute; left: 160px; top: 11px;"></i>
      </div>
      </div>
      </div>
      <div class="col-md-12">
      <div class="row">
      <div class="form-group col-md-4 p-1">
      <input class="form-control col-12" style="border: 1px solid #9acd32 !important; border-radius: 5px !important;">
      <span class="floating-label" style="top: -11px !important;left: 9px !important;background: #000 !important;">Number of slots</span>
      </div>
      <div class="form-group col-md-4 p-1">
      <input class="form-control col-12" style="border: 1px solid #9acd32 !important; border-radius: 5px !important;">
      <span class="floating-label" style="top: -11px !important; left: 19px !important; background: #000 !important;">Duration slot</span>

      </div>
      <div class="form-group col-md-4 p-1">
      <input class="form-control col-12" style="border: 1px solid #9acd32 !important; border-radius: 5px !important;">
      <span class="floating-label" style="top: -11px !important; left: 19px !important; background: #000 !important;">Delayed slots</span>
      </div>
      </div>
      </div>




      <div class="col-md-9 p-0 text-center mx-auto">
      <div class="btn mx-4" style="padding: 4px 29px;">Cancel
      </div>
      <div
      class="btn mx-3"
      style="padding: 4px 29px;"
      >
      Save
      </div>
      </div>
      </div>
      </div>

      </div>
      <div class="workout-footer d-flex col-12 justify-content-end">
      </div>
      </div>
      </div>
      </ng-container>
  </div>
</section>
