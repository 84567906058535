<section>
  <div class="container-fluid">
    <div class="fit-px-mt-5">
      <div class="row">
        <div class="heading1 mb-0 col-md-8 col-sm-12">
          <img src="assets/images/dashboard/program.png" style="border: 2px solid #8bc53f;border-radius: 50%;"
            width="50" /> <span><b>Programs</b></span>
        </div>

        <!-- Exercise Search box -->
        <div class="col-md-4 fit-mt-3">
          <div class="d-flex justify-content-end">
            <div class="search__box search__box-exercise search__box-exercise-closed d-flex m-0 mx-2">
              <input type="text" class="col" name="search" autocomplete="off" placeholder="Search for a Program" [(ngModel)]="searchProgram">
              <a class="search__box__btn search__box__btn-exercise-open"><i class="fas fa-search"></i></a>
              <!-- <a data-toggle="modal" data-target="#exerciseSerachFilters" class="search-filters"> </a> -->
                <!-- <i class="fas fa-filter"></i> -->

            </div>
            <div class="add__exercise__btn" title="Add Program" routerLink="/addprogram"><i class="fa fa-plus"></i></div>
          </div>
        </div>
        <!-- Exercise Search box /-->
      </div>
      <div class="row justify-content-center" *ngIf="myprogramsLength == 0">
        <h1>Please click on '+' icon to add Programs</h1>
      </div>
      <div
      class="search-results"
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      (scrolled)="onScroll()"
    >
      <div class="row px-3 mt-3" *ngIf="myprograms!= undefined">

        <div class="col-sm-6 col-md-4 col-10 fit-mx-auto col-lg-3 col-xl-2 mt-3 exercise-video-div-grid my__programs"
          *ngFor="let program of myprograms | filter:searchProgram">

          <div class="p-1 pt-1" style=" background: black; border-radius: 10px;">
            <div class="my__programs_header x-my__programs_header col-md-12 px-1 mt-2" style="cursor: pointer" routerLink="/addprogram/{{program.programId}}">

              <img src="{{program.programImage.imgPath}}" />

            </div>
            <div class="my__programs_body mb-0 col-md-12">
              <div class="row">
                <div class="col-sm-9 col-md-9 col-9">
                  <div class="program-text-style word-ellipsis" style="cursor: pointer"
                    routerLink="/addprogram/{{program.programId}}" title="{{program.programName}}" style="font-size: 14px;">{{program.programName}}</div>
                </div>

                <div class="dropdown col-md-3 col-sm-3 col-3 text-right">
                  <i class="fa fa-caret-down" aria-hidden="true" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="color:white; " >
                  </i>
                  <div class="dropdown-menu exercise-edit-droppdown" aria-labelledby="dropdownMenuButton" >

                    <a class="mx-2" style="cursor: pointer;"  *ngIf="program.adminStatus == 'Rejected' || program.adminStatus == 'Onhold'" title="Comments">
                      <i aria-hidden="true" class="fa fa-comment exe-edit-fa-grop" (click)="getAdminComments(program.programId)">
                      </i>
                    </a>

                    <a class="mx-2" style="cursor: pointer;" routerLink="/calendar/{{program.programId}}" title="Calendar">
                      <i class="fas fa-calendar-alt exe-edit-fa-grop" ></i>
                    </a>

                    <a class="mx-2" style="cursor: pointer;" routerLink="/trainee/{{program.programId}}" title="Trainees">
                      <i class="fas fa-users exe-edit-fa-grop"></i>
                    </a>

                    <!-- <a class="mx-2" (click)="deleteCheck(ex.exerciseId)" title="Delete">
                      <i class="fas fa-trash-alt exe-edit-fa-grop "></i>
                    </a> -->

                    <a class="mx-2" style="cursor: pointer;" *ngIf="program.status == 'New' && (program.adminStatus == undefined || program.adminStatus == null)" title="Publish" (click)="checkCalendarData('Registered',program.programId)">
                    <img class="exe-edit-fa-grop-img" src="assets/images/statusIcons/grey.gif" />
                    </a>

                    <a class="mx-2" style="cursor: pointer;" *ngIf="program.status == 'New'&& program.adminStatus == 'New'" title=" Re Publish" (click)="onRepublish(program.programId)">
                    <img class="exe-edit-fa-grop-img"  src="assets/images/statusIcons/grey.gif" />
                  </a>

                  <a class="mx-2" style="cursor: pointer;" *ngIf="(program.adminStatus == 'Under Review' || program.status == 'Registered' || program.adminStatus == 'Onhold') && program.adminStatus != 'Rejected' && program.adminStatus != 'Approved'" title="Publishing inprogress">
                    <img class="exe-edit-fa-grop-img"  src="assets/images/statusIcons/orange.gif" />
                  </a>

                  <a class="mx-2" style="cursor: pointer;" *ngIf="program.status == 'Approved' && program.adminStatus == 'Approved'" title="Un publish" (click)="saveProgram('New',program.programId)">
                    <img class="exe-edit-fa-grop-img"  src="assets/images/statusIcons/green.gif" />
                  </a>

                  <a class="mx-2" style="cursor: pointer;" *ngIf="program.adminStatus == 'Rejected'" (click)="checkCalendarData('Under Review',program.programId)" title="Re publish" >
                  <img class="exe-edit-fa-grop-img"  src="assets/images/statusIcons/red.gif" />
                  </a>

                  </div>

                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
      </div>
    </div>






                <!-- <div class="text-right col col-md-7"> -->








                  <!-- <a class="logout_link mx-1" style="cursor: pointer;" *ngIf="program.status == 'New'"
                    (click)="deleteProgram(program.programId)"><img src="assets/images/delete.png" width="15"
                      title="Delete" />
                  </a> -->



                  <!-- <a class="logout_link mx-1" style="cursor: pointer;"
                    *ngIf="program.adminStatus == 'Approved' && program.status == 'New'"
                    (click)="saveProgram('New',program.programId)"><img src="assets/images/publish.png" width="15"
                      title="Un publish" />
                  </a> -->

                <!-- </div> -->

              <!-- <a class="program__links float-right">
                <i class="fas fa-ellipsis-h w3-dropdown-hover menu_icon">
                  <div class="dropdown-menu user_settings_wrapper w3-dropdown-content" style="cursor: pointer">
                    <a style="cursor: pointer;" routerLink="/calendar/{{program.programId}}"><i
                        class="fas fa-calendar-alt" style="color:green;"></i> Calendar</a>

                    <a class="logout_link" style="cursor: pointer;" routerLink="/trainee/{{program.programId}}"><i
                        style="color:green;" class="fas fa-users"></i> Trainee</a>

                    <a class="logout_link" style="cursor: pointer;" *ngIf="program.status == 'New'"
                      (click)="checkCalendarData('Registered',program.programId)"><img src="assets/images/publish.png"
                        width="15" /> Publish</a>
                    <a class="logout_link" style="cursor: pointer;" *ngIf="program.status == 'Registered'"> Publishing
                      inprogress..</a>
                    <a class="logout_link" style="cursor: pointer;" *ngIf="program.status == 'Approved'"
                      (click)="saveProgram('',program.programId)"><img src="assets/images/unpublish.png" width="20" />
                      Unpublish</a>
                    <a class="logout_link" style="cursor: pointer;" *ngIf="program.status == 'New'"
                      (click)="deleteProgram(program.programId)"><img src="assets/images/delete.png" width="20" />
                      Delete</a>
                  </div>
                </i>
              </a> -->







      <!-- <div class="videos videos-exercise row" *ngIf="myprograms!= undefined">
        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3" style="margin-bottom: 30px;"
          *ngFor="let program of myprograms | filter:searchProgram">
          <div class="video_wrapper">
            <div class="video_file" routerLink="/addprogram/{{program.programId}}">
              <a style="display: flex;
              justify-content: center;
              align-items: center;
              overflow: hidden">
                <img src="{{program.programImage.imgPath}}" />
              </a>
            </div>
            <div class="video__title col-md-12" style="display:flex;">
              <div style="float:left;" class="col-sm-8 col-md-7 p-0">
                <h3 routerLink="/addprogram/{{program.programId}}">{{program.programName}}</h3>
              </div>
              <div class="edit_links col-sm-4 col-md-5 text-right p-0">


                <a style="cursor: pointer;" title="Calendar" routerLink="/calendar/{{program.programId}}"><i
                    class="fas fa-calendar-alt" style="margin-right: 5px; color:green;"></i></a>

                <a class="logout_link" title="Trainees" style="cursor: pointer;"
                  routerLink="/trainee/{{program.programId}}"><i style="margin-right: 5px;color:green;"
                    class="fas fa-users"></i></a>

                <a class="logout_link" style="cursor: pointer;" title="Publish" *ngIf="program.status == 'New'"
                  (click)="checkCalendarData('Registered',program.programId)"><img src="assets/images/publish.png"
                    width="15" /></a>

                <a class="logout_link" style="cursor: pointer;" title="Unpublish" *ngIf="program.status == 'Approved'"
                  (click)="saveProgram('',program.programId)"><img src="assets/images/unpublish.png" width="20" />
                </a>
                <a class="logout_link" style="cursor: pointer;" *ngIf="program.status == 'New'" title="Delete"
                  (click)="deleteProgram(program.programId)"><img src="assets/images/delete.png" width="20" />

              </div>
            </div>
          </div>

        </div>
      </div> -->

    <!-- showDeletePopup -->
    <ng-container *ngIf="showDeletePopup">
      <div class="program_event-popup__wrapper">
        <div class="program_event-pop zoomIn background-shadow-green">
          <div class="program_event-header text-center">
            <h4>Are you sure</h4>
          </div>
          <a (click)="closePopup()" class="close-program_event-pop"><i class="fas fa-times"></i></a>
          <div class="program_event-body d-flex justify-content-center">
            <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
              (click)="onConfirmDelete()">Yes</button>
            <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
              (click)="closePopup()">No</button>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- isBusinessDetails -->
    <ng-container *ngIf="isBusinessDetails">
      <div class="program_event-popup__wrapper">
        <div class="program_event-pop zoomIn background-shadow-green" style="width:33% !important;">
          <div class="program_event-header">
            <h4>Please provide the bank information</h4>
          </div>
          <a (click)="closePopup()" class="close-program_event-pop"><i class="fas fa-times"></i></a>
          <div class="program_event-body d-flex justify-content-center">

            <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
              (click)="closePopup()">Cancel</button>

            <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
              (click)="onContinue()">Continue</button>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- isNewProfile -->
    <ng-container *ngIf="isNewProfile">
      <div class="program_event-popup__wrapper">
        <div class="program_event-pop zoomIn background-shadow-green" style="width:40% !important;">
          <div class="program_event-header" style="text-align: center;"
            *ngIf="profileStatus == 'Under Review' || profileStatus == 'New'">
            <h4>Your profile is in under review</h4>
          </div>

          <div class="program_event-header" style="text-align: center;" *ngIf="profileStatus == 'Onhold'">
            <h4>Your profile is onhold</h4>
          </div>
          <a (click)="closePopup()" class="close-program_event-pop"><i class="fas fa-times"></i></a>
          <div class="program_event-body d-flex justify-content-center">

            <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
              (click)="closePopup()">Ok</button>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- isRejectedProfile -->
    <ng-container *ngIf="isRejectedProfile">
      <div class="program_event-popup__wrapper">
        <div class="program_event-pop zoomIn background-shadow-green" style="width:40% !important;">
          <div class="program_event-header">
            <h4>Your profile has rejected, please update your profile.</h4>
          </div>
          <a (click)="closePopup()" class="close-program_event-pop"><i class="fas fa-times"></i></a>
          <div class="program_event-body d-flex justify-content-center">

            <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
              (click)="closePopup()">Cancel</button>

            <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
              (click)="onUpdateProfile()">Update</button>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="showAdminComments">
      <div class="video-popup__wrapper">
        <div class="video-pop zoomIn">

          <div class="program_event-body d-flex justify-content-center">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12">
                  <div class="add_program_duration">
                    <div class="col-12">
                      <div class="row">
                        <div class="col"  style="text-align: center">
                          <b>Comments</b>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                          <div style="display: grid"><b>Created by</b> </div>
                        </div>
                        <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                          <div style="display: grid"><b>Date</b> </div>
                        </div>

                        <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                          <div style="display: grid"><b>Status</b> </div>
                        </div>

                        <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                          <div style="display: grid"><b>Comment</b>
                          </div>
                        </div>

                      </div>

                      <div class="row" *ngFor="let comment of adminComments">
                        <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                          <div style="display: grid"><span>{{comment.updatedBy}}</span>
                          </div>
                        </div>

                        <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                          <div style="display: grid"><span>{{comment.updateDate | date}}</span> </div>
                        </div>

                        <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                          <div style="display: grid"><span>{{comment.action}} </span> </div>
                        </div>

                        <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                          <div style="display: grid"><span>{{ comment.comment }}</span>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="d-flex col-12 justify-content-end">
                      <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
                        (click)="closePopup()">
                        Ok
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- showWorkoutrejectPopUp -->
    <ng-container *ngIf="showWorkoutrejectPopUp">
      <div class="program_event-popup__wrapper">
        <div class="program_event-pop zoomIn background-shadow-green" style="width:40% !important;">
          <div class="program_event-header">
            <h4>Please update workouts which are rejected by admin
            </h4>
          </div>

          <div class="program_event-body d-flex justify-content-center">

            <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
              (click)="closePopup()">Ok</button>
          </div>
        </div>
      </div>
    </ng-container>


    <!-- showRepublishPopup -->
    <ng-container *ngIf="showRepublishPopup">
      <div class="program_event-popup__wrapper">
        <div class="program_event-pop zoomIn background-shadow-green">
          <div class="program_event-header text-center">
            <h4>Are you sure</h4>
          </div>
          <a (click)="closePopup()" class="close-program_event-pop"><i class="fas fa-times"></i></a>
          <div class="program_event-body d-flex justify-content-center">
            <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
              (click)="checkCalendarData('Under Review',program.programId)">Yes</button>
            <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
              (click)="closePopup()">No</button>
          </div>
        </div>
      </div>
    </ng-container>

  </div>
</section>
