<section>
  <div class="container-fluid">
    <div class="fit-px-mt-5">
      <div class="row">
        <div class="heading1 col-md-8 col-sm-12 mb-4"> <img src="assets/images/dashboard/exercis.png"
            style="border: 2px solid #8bc53f;border-radius: 50%;" width="50" /> <span><b>Workout Management</b></span>
        </div>
        <!-- Exercise Search box -->
        <div class="col-md-4">
          <div class="d-flex justify-content-end">
            <div class="search__box search__box-exercise search__box-exercise-closed d-flex m-0 mx-2" style="cursor: pointer;">
              <input type="text" class="col" autocomplete="off" name="search" placeholder="Search for a Workout" [(ngModel)]="searchWorkout">
              <a class="search__box__btn search__box__btn-exercise-open"><i class="fas fa-search"></i></a>
              <!-- <a data-toggle="modal" data-target="#exerciseSerachFilters" class="search-filters">
                 <i class="fas fa-filter"></i>
              </a> -->
            </div>
            <div title="Add Workout" class="add__exercise__btn"><i class="fa fa-plus" (click)="onAddWorkout()"></i></div>
          </div>
        </div>
        <!-- Exercise Search box /-->
      </div>


      <!-- <div class="row">
          <div class="col-md-2" *ngFor="let workout of workouts">
           <div class="workout_item"> <i class="fas fa-filter"></i></div>
            <h4 *ngIf="workout.workoutName !='Others'" (click)="editWorkout(workout.workoutId)">{{workout.workoutName}}</h4>
            <h4 *ngIf="workout.workoutName =='Others'" (click)="editWorkout(workout.workoutId)">{{workout.customWorkoutName}}</h4>
            <i class="fa fa-trash" (click)="deleteWorkout(workout.workoutId)"></i>
          </div>
        </div> -->
      <div class="d-flex">
        <div class="col-md-12 sub_heading mb-4" style="text-align: end;">Total Workouts : {{workoutsLength}}</div>
      </div>
      <div class="row justify-content-center" *ngIf="workoutsLength == 0">
        <h1>Please click on '+' icon to add Workouts</h1>
      </div>
      <div
      class="search-results"
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      (scrolled)="onScroll()"
    >
      <div class="row" *ngIf="workouts != undefined">
        <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2 col-6 text-center mb-3 workout_item workoutmanagement-width"
          *ngFor="let workout of workouts.slice().reverse() | filter:searchWorkout">
          <img title="{{workout.customWorkoutName}}" src="{{workout.workoutName.imgUrl}}" (click)="editWorkout(workout.workoutId)" width="150" />

          <div class="col-md-12 px-1">
          <div class="row" *ngIf="workout.name != 'Others'">
            <div class="word-ellipsis col-8" style="max-width: 66%; font-size: 600;">{{workout.customWorkoutName}}</div>
            <div class="col-4 px-1">
              <div class="row">
                  <a placement="bottom" class="mx-1"
                  *ngIf="workout.adminStatus == 'Under Review' || workout.adminStatus == 'Rejected'">
                    <i class="fa fa-comment" style="color:red;"
                      (click)="getAdminComments(workout.workoutId)" title="Comments"></i>
                  </a>
                  <a class="mx-1">
                    <i title="Delete" style="color: red;" (click)="deleteWorkout(workout.workoutId)" class="fas fa-trash-alt"></i>
                  </a>
                  <a [tooltip]="tolTemplate" placement="bottom" class="mx-1" >
                    <i style="color: #8dc63f;" class="fa fa-clock-o" title="{{workout.lastUpdatedOn | date}}"></i>
                  </a>
               </div>
            </div>
          </div>
          </div>
          <h4 *ngIf="workout.name == 'Others'">
            <b>{{workout.customWorkoutName}}</b>
            <span>
              <a (click)="deleteWorkout(workout.workoutId)" style="margin-left: 10px;" title="Delete"><i
                  class="fas fa-trash-alt"></i></a>
              <ng-template #tolTemplate>
                <!-- <div>Created on: {{ex.creationDate | date}}</div> -->
                <div>Updated on: {{workout.lastUpdatedOn | date}}</div>
              </ng-template>
              <a [tooltip]="tolTemplate" placement="bottom" style="margin-left: 10px;"><i class="fa fa-clock-o"></i></a>
              <a placement="bottom" style="margin-left: 10px;"
                *ngIf="workout.adminStatus == 'Under Review' || workout.adminStatus == 'Rejected'"> <i
                  class="fa fa-comment" style="font-size: 18px;" title="Comments"
                  (click)="getAdminComments(workout.workoutId)"></i>
              </a>
            </span>
          </h4>
          <!-- <i class="fa fa-trash" (click)="deleteWorkout(workout.workoutId)"></i> -->
        </div>
      </div>
     </div>

    </div>
    <!-- showDeletePopup -->
    <ng-container *ngIf="showDeletePopup">
      <div class="program_event-popup__wrapper">
        <div class="program_event-pop zoomIn background-shadow-green">
          <div class="program_event-header text-center">
            <h4>Are you sure</h4>
          </div>
          <a (click)="closePopup()" class="close-program_event-pop"><i class="fas fa-times"></i></a>
          <div class="program_event-body d-flex justify-content-center">
            <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
              (click)="onConfirmDelete()">Yes</button>
            <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
              (click)="closePopup()">No</button>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="showAdminComments">
      <div class="video-popup__wrapper">
        <div class="video-pop zoomIn">

          <div class="program_event-body d-flex justify-content-center">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12">
                  <div class="add_program_duration">
                    <div class="col-12">
                      <div class="row">
                        <div class="col"  style="text-align: center">
                          <b>Comments</b>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                          <div style="display: grid"><b>Created by</b> </div>
                        </div>
                        <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                          <div style="display: grid"><b>Date</b> </div>
                        </div>

                        <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                          <div style="display: grid"><b>Status</b> </div>
                        </div>

                        <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                          <div style="display: grid"><b>Comment</b>
                          </div>
                        </div>

                      </div>

                      <div class="row" *ngFor="let comment of adminComments">
                        <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                          <div style="display: grid"><span>{{ comment.updatedBy }}</span>
                          </div>
                        </div>

                        <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                          <div style="display: grid"><span>{{ comment.updateDate | date }}</span> </div>
                        </div>

                        <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                          <div style="display: grid"><span>{{ comment.action }} </span> </div>
                        </div>

                        <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                          <div style="display: grid"><span>{{ comment.comment }} </span> </div>
                        </div>
                      </div>
                    </div>


                    <div class="d-flex col-12 justify-content-end">
                      <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
                        (click)="closePopup()">
                        Ok
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</section>
