import { environment } from "../../environments/environment";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from 'rxjs';
// import { DynamoDBService } from "./ddb.service";
import { CognitoCallback, CognitoUtil, LoggedInCallback } from "./cognito.service";
import { AuthenticationDetails, CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";
import { CognitoAuth } from 'amazon-cognito-auth-js'
import * as AWS from "aws-sdk/global";
import STS from "aws-sdk/clients/sts";

import { Observable } from 'rxjs/Rx';
// import { Router, ActivatedRoute } from "@angular/router";

@Injectable()
export class UserLoginService {
    // @Output() getLoggedInName: EventEmitter<any> = new EventEmitter();
    currentUserID = new BehaviorSubject('');   // This is to observe current userID on login (code : santosh)
    currentUserPic = new BehaviorSubject('');   // This is to observe current user profile pic on login (code : santosh)
    logedInType = new BehaviorSubject('');  // checcking for cognito or google signin
    currentUserDetails = new BehaviorSubject('');  //To checking for user categories.

    broadcastLoginChange(userID: string, userPic: string, signinType: string) {
        this.currentUserID.next(userID);        // this method is used in login component to change user ID on login (code : santosh)
        this.currentUserPic.next(userPic);      // this method is used in login component to change user Pic on login (code : santosh)
        this.logedInType.next(signinType);
    }
    broadcastUserCategories(userCategory : string){
        this.currentUserDetails.next(userCategory);

    }
    private onLoginSuccess = (callback: CognitoCallback, session: CognitoUserSession) => {

        //    console.log("In authenticateUser onSuccess callback");

        AWS.config.credentials = this.cognitoUtil.buildCognitoCreds(session.getIdToken().getJwtToken());

        // So, when CognitoIdentity authenticates a user, it doesn't actually hand us the IdentityID,
        // used by many of our other handlers. This is handled by some sly underhanded calls to AWS Cognito
        // API's by the SDK itself, automatically when the first AWS SDK request is made that requires our
        // security credentials. The identity is then injected directly into the credentials object.
        // If the first SDK call we make wants to use our IdentityID, we have a
        // chicken and egg problem on our hands. We resolve this problem by "priming" the AWS SDK by calling a
        // very innocuous API call that forces this behavior.
        let clientParams: any = {};
        if (environment.sts_endpoint) {
            clientParams.endpoint = environment.sts_endpoint;
        }
        let sts = new STS(clientParams);
        sts.getCallerIdentity(function (err, data) {
            // console.log("UserLoginService: Successfully set the AWS credentials");
            callback.cognitoCallback(null, session);
        });
    }

    private onLoginError = (callback: CognitoCallback, err) => {
        callback.cognitoCallback(err.message, null);
    }

    constructor(public cognitoUtil: CognitoUtil) {
    }

    authenticate(username: string, password: string, callback: CognitoCallback) {
        //  console.log("UserLoginService: starting the authentication");

        let authenticationData = {
            Username: username,
            Password: password,
        };
        let authenticationDetails = new AuthenticationDetails(authenticationData);

        let userData = {
            Username: username,
            Pool: this.cognitoUtil.getUserPool()
        };
        // this.getLoggedInName.emit(username);

        //  console.log("UserLoginService: Params set...Authenticating the user");
        let cognitoUser = new CognitoUser(userData);

        // console.log("UserLoginService: config is " + AWS.config);
        cognitoUser.authenticateUser(authenticationDetails, {
            newPasswordRequired: (userAttributes, requiredAttributes) => callback.cognitoCallback(`User needs to set password.`, null),
            onSuccess: result => this.onLoginSuccess(callback, result),
            onFailure: err => this.onLoginError(callback, err),
            mfaRequired: (challengeName, challengeParameters) => {
                callback.handleMFAStep(challengeName, challengeParameters, (confirmationCode: string) => {
                    cognitoUser.sendMFACode(confirmationCode, {
                        onSuccess: result => this.onLoginSuccess(callback, result),
                        onFailure: err => this.onLoginError(callback, err)
                    });
                });
            }
        });
        //     if(result != null){
        //     this.authenticate.getLoggedInName.emit(result);
        // }
    }


    forgotPassword(username: string, callback: CognitoCallback) {
        let userData = {
            Username: username,
            Pool: this.cognitoUtil.getUserPool()
        };

        let cognitoUser = new CognitoUser(userData);

        cognitoUser.forgotPassword({
            onSuccess: function () {

            },
            onFailure: function (err) {
                callback.cognitoCallback(err.message, null);
            },
            inputVerificationCode() {
                callback.cognitoCallback(null, null);
            }
        });
    }

    confirmNewPassword(email: string, verificationCode: string, password: string, callback: CognitoCallback) {
        let userData = {
            Username: email,
            Pool: this.cognitoUtil.getUserPool()
        };

        let cognitoUser = new CognitoUser(userData);

        cognitoUser.confirmPassword(verificationCode, password, {
            onSuccess: function () {
                callback.cognitoCallback("SUCCESS", null);
            },
            onFailure: function (err) {
                callback.cognitoCallback(err.message, null);
            }
        });
    }

    logout() {
        //  console.log("UserLoginService: Logging out");
        //this.ddb.writeLogEntry("logout");
        this.cognitoUtil.getCurrentUser().signOut();
        // this.router.navigate(['/login']);

    }

    isAuthenticated(callback: LoggedInCallback) {
        if (callback == null)
            throw ("UserLoginService: Callback in isAuthenticated() cannot be null");

        let cognitoUser = this.cognitoUtil.getCurrentUser();
        // this.getLoggedInName.emit(cognitoUser);
        if (cognitoUser != null) {
            cognitoUser.getSession(function (err, session) {
                if (err) {
                    //  console.log("UserLoginService: Couldn't get the session: " + err, err.stack);
                    callback.isLoggedIn(err, false);
                }
                else {
                    // console.log("UserLoginService: Session is " + session.isValid());
                    callback.isLoggedIn(err, session.isValid());
                }
            });
        } else {
            // console.log("UserLoginService: can't retrieve the current user");
            callback.isLoggedIn("Can't retrieve the CurrentUser", false);
        }
    }
}
