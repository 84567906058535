import { Component, OnInit, Input, OnDestroy, Renderer2, EventEmitter,HostListener } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { UserLoginService } from "../service/user-login.service";
import { LoggedInCallback, CognitoUtil } from "../service/cognito.service";
import { AppGlobals } from '../app.globals';
import { DBService } from '../service/db.service';
import { AWSService } from '../service/aws.service';
import { Options } from 'ng5-slider';
import { $ } from 'protractor';
import { EmbedVideoService } from 'ngx-embed-video';
// import { AngularFontAwesomeComponent } from 'angular-font-awesome';
import { integer } from 'aws-sdk/clients/cloudfront';
import { ToastrService } from 'ngx-toastr';
//import * as $ from 'jquery';

export class exercise {
  exerciseId: string;
  exerciseName: string;
  workoutType: Object;
  otherWorkoutType: String;
  mechanicalType: Object;
  otherMechanicalType: string;
  mainMuscleWorkout: Object;
  otherMainMuscleWorkout: string;
  otherMuscleWorkout: Object[];
  level: Object;
  sport: Object;
  equipment: Object;
  force: Object;
  sets: Object[];
  exerciseVideo: Object;
  instructions: string;
  trainerId: string;
  isPublic: boolean;
  isPublicDate: Date;
  creationDate: Date;
  lastUpdatedOn: Date;
  exerciseWarnings: string;
  exerciseStatus: string;
  exercisePercentage: string;
  exerciseComments: Object[];
  isChangeAffects: boolean;
}
@Component({
  selector: 'app-addexercise',
  templateUrl: './addexercise.component.html',
  styleUrls: ['./addexercise.component.css']
})
export class AddexerciseComponent implements OnInit, OnDestroy, LoggedInCallback {
  @Input() videoUrl: string = "";
  @Input() imgUrl: string = "assets/images/uploadimg.PNG";
  @Input() thumbUrl: string = "assets/images/upload-video.jpg";
  videoHidden: boolean = false;
  //isBasic:boolean  = false;
  drgDropHidden: boolean = true;
  iframeHidden: boolean = false;
  loaderHide: boolean = false;
  isYoutubeVerified: boolean = false;
  isUpdateExercise: boolean = false;
  editDisabled: boolean = false;
  dropdownSettings: any = {};
  dropdownSettings_single: any = {};
  dropdownSettings_single_1: any = {};
  closeDropdownSelection = false;
  disabled = false;
  //custDrop: string;
  fileExtension: any;
  embedYoutubeUrl: string;
  currentCategory: string;
  currentGender: string;
  iframeURL: any;
  // exName:string;

  WorkoutTypes: object[];
  MainMuscleWorkouts: object[];
  OtherMuscleWorkouts: object[];
  Equipments: object[];
  MechanicalTypes: object[];
  Levels: object[];
  Sports: object[];
  Forces: object[];

  SelectedWorkoutType: Object[];
  SelectedMainMuscleWorkout: Object[];
  SelectedOtherMuscleWorkouts: Object[];
  SelectedEquipment: Object[];
  SelectedMechanicalType: Object[];
  SelectedLevel: Object[];
  SelectedSport: Object[];
  SelectedForce: Object[];

  isWorkoutType = false;
  isMechanicalType = false;
  isMainMuscleWorkout = false;

  set = {
    setNo: 0,
    reputationValue: { "actual": 0, "completed": 0 },
    restPeriod: { "actual": 0, "completed": 0 },
    restPeriodMetric: "",
    maxWeights: { "actual": 0, "completed": 0 },
    heartrate: ""
  }


  setsVal: number = 0;
  setsOptions: Options = {
    floor: 0,
    ceil: 10
  };

  manualRefreshEnabled: boolean = true;
  manualRefresh: EventEmitter<void> = new EventEmitter<void>();

  repsVal: number = 0;
  repsOptions: Options = {
    floor: 0,
    ceil: 50
  };
  restPeriodVal: number = 0;
  restPeriodOptions: Options = {
    floor: 0,
    ceil: 180
  };
  weightsVal = 0

  repsVal1: number = 0;
  restPeriodVal1: number = 0;
  weightsVal1 = 0

  repsVal2: number = 0;
  restPeriodVal2: number = 0;
  weightsVal2 = 0

  repsVal3: number = 0;
  restPeriodVal3: number = 0;
  weightsVal3 = 0


  repsVal4: number = 0;
  restPeriodVal4: number = 0;
  weightsVal4 = 0

  repsVal5: number = 0;
  restPeriodVal5: number = 0;
  weightsVal5 = 0

  repsVal6: number = 0;
  restPeriodVal6: number = 0;
  weightsVal6 = 0

  repsVal7: number = 0;
  restPeriodVal7: number = 0;
  weightsVal7 = 0

  repsVal8: number = 0;
  restPeriodVal8: number = 0;
  weightsVal8 = 0

  repsVal9: number = 0;
  restPeriodVal9: number = 0;
  weightsVal9 = 0


  isDragOver: boolean = false;

  exerciseVideo: any = {
    exerciseVideoSource: "",
    exerciseVideoName: "",
    videoThumbnailPath: "",
    videoSourceUrl: "",
    videoDestinationCheck: false,
    videoMp4Destination: "",
    VideoVerfied: "",
    verifiedOn: Date,
    VideoUploadedOn: new Date(),
    youtubeUrl: "",
  }
  @HostListener('dragover', ['$event'])
  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = true;
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent, filetype) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = false;

    const files = event.dataTransfer?.files;
    if (files) {
      // Handle dropped files here
      this.selectFile(files, filetype)
    }
  }
  onFileSelected(event: Event,filetype) {
    const inputElement = event.target as HTMLInputElement;
    const files = inputElement.files;
    if (files) {
      // Handle selected files here
      this.selectFile(files,filetype)
    }
  }


  oneAtATime: boolean = true;
  constructor(private renderer: Renderer2, private HttpClient: HttpClient, public router: Router, public userService: UserLoginService,
    public route: ActivatedRoute, private globals: AppGlobals, private db_service: DBService, private awsService: AWSService, private toastr: ToastrService,
    private embedService: EmbedVideoService, public cognitoUtil: CognitoUtil) {
    this.userService.isAuthenticated(this);
  }
  exercise: exercise;
  selectedFile: File;
  exerciseResponse: any;
  private sub: any;
  //exerciseId: string = "";
  currentEmail: string;

  // Google Var
  private loggedIn: boolean;

  ngOnInit() {

    this.currentCategory = localStorage.getItem("ProfileCategory")
    this.currentGender = localStorage.getItem("ProfileGender");
    if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-build-male');
    }
    else if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-build-female');
    }
    else if (this.currentCategory == 'Yoga' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-yoga-male');
    }
    else if (this.currentCategory == 'Yoga' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-yoga-female');
    }
    else if (this.currentCategory == 'Zumba' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-zumba-male');
    }
    else if (this.currentCategory == 'Zumba' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-zumba-female');
    }

    this.exercise = new exercise();
    this.exercise.isPublic = false;
    this.userService.currentUserID.subscribe((val) => {
      if (val != '') {
        this.exercise.trainerId = val;
      }
    });
    this.sub = this.route.params.subscribe(params => {
      this.exercise.exerciseId = params['exerciseID'];
    });
    if (this.exercise.exerciseId != undefined && this.exercise.exerciseId != "") {
      this.getExerciseData();
    }
    this.dropdownSettings = {
      singleSelection: false,
      enableCheckAll: false,
      idField: 'id',
      textField: 'name',
      allowSearchFilter: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      closeDropDownOnSelection: this.closeDropdownSelection
    };
    this.dropdownSettings_single = {
      singleSelection: true,
      enableCheckAll: false,
      idField: 'id',
      textField: 'name',
      allowSearchFilter: true,
      itemsShowLimit: 1,
      closeDropDownOnSelection: this.closeDropdownSelection
    };
    this.dropdownSettings_single_1 = {
      singleSelection: true,
      enableCheckAll: false,
      idField: 'id',
      textField: 'name',
      allowSearchFilter: false,
      itemsShowLimit: 1,
      closeDropDownOnSelection: this.closeDropdownSelection
    };
    this.getAllMasters();
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
    this.renderer.removeClass(document.body, 'bg-img-build-male');
    this.renderer.removeClass(document.body, 'bg-img-yoga-male');
    this.renderer.removeClass(document.body, 'bg-img-zumba-male');
    this.renderer.removeClass(document.body, 'bg-img-build-female');
    this.renderer.removeClass(document.body, 'bg-img-yoga-female');
    this.renderer.removeClass(document.body, 'bg-img-zumba-female');
    this.renderer.addClass(document.body, 'body-main');
  }
  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/login']);
    } else {
      // if()
      if (this.loggedIn == true) {
        this.currentEmail = localStorage.getItem('lastAuthUser');
        // if(this.currentEmail != undefined)
        this.getProfileDataFromServer();
      }
      else {
        // this.currentEmail = localStorage.getItem('CognitoIdentityServiceProvider.2io8v4r253hugt5ndbjv9shkfp.LastAuthUser');
        this.currentEmail = this.cognitoUtil.getCurrentUser().getUsername();
        if(this.currentEmail == undefined || this.currentEmail == '' || this.currentEmail == null || this.currentEmail.includes("Google") || this.currentEmail.includes("google"))
        {
          this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
          if(this.currentEmail == undefined)
          this.currentEmail = this.cognitoUtil.session.idToken.payload.email;
        }
        // this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
        this.getProfileDataFromServer();
      }
    }
  }
  getProfileDataFromServer() {
    var index = '/profile/';
    this.db_service.getDataFromServer(this.currentEmail, index).subscribe(
      response => {
        //console.log('Data Retrived succesfully.', response);
        response = response.data[0];
        this.exercise.trainerId = response.trainerId;
        // this.geExerciseDataFromServer();
      },
      error => {
        console.log("An error has occurred while retriving profile data.");
      }
    )
  }
  onItemSelect(event, type) {
    if (event.name == "Others" && type == "workoutType") {
      this.isWorkoutType = !this.isWorkoutType;
    } else {
      this.isWorkoutType = false;
    }
    if (event.name == "Others" && type == "mechanicalType") {
      this.isMechanicalType = !this.isMechanicalType;
    } else {
      this.isMechanicalType = false;
    }
    if (event.name == "Others" && type == "mainMuscleWorkout") {
      this.isMainMuscleWorkout = !this.isMainMuscleWorkout;
    } else {
      this.isMainMuscleWorkout = false;
    }
  }
  onItemDeselect(event, type) {
    if (event.name == "Others" && type == "workoutType") {
      this.isWorkoutType = !this.isWorkoutType;
    }
    if (event.name == "Others" && type == "mechanicalType") {
      this.isMechanicalType = !this.isMechanicalType;
    }
    if (event.name == "Others" && type == "mainMuscleWorkout") {
      this.isMainMuscleWorkout = !this.isMainMuscleWorkout;
    }
  }
  getAllMasters() {
    var index = '/masters/';
    this.db_service.getDataFromServer('exercise', index).subscribe(
      response => {
        //console.log('Data Retrived succesfully.', response);
        for (let i = 0; i < response.length; i++) {
          if (response[i]['name'] == "WorkoutTypes")
            this.WorkoutTypes = response[i]['values'];
          if (response[i]['name'] == "Main_Other_MuscleWorkouts")
            this.MainMuscleWorkouts = response[i]['values'];
          if (response[i]['name'] == "Main_Other_MuscleWorkouts")
            this.OtherMuscleWorkouts = response[i]['values'];

          if (response[i]['name'] == "Equipments")
            this.Equipments = response[i]['values'];
          if (response[i]['name'] == "MechanicalTypes")
            this.MechanicalTypes = response[i]['values'];
          if (response[i]['name'] == "Levels")
            this.Levels = response[i]['values'];
          if (response[i]['name'] == "Sports")
            this.Sports = response[i]['values'];
          if (response[i]['name'] == "Forces")
            this.Forces = response[i]['values'];
        }
      },
      error => {
        console.log("An error has occurred while retriving masters data.");
      }
    )
  }
  SaveExercise() {
    //console.log(this.exercise.isPublic);
    var atleastOneAlpha;
    var setVal1: boolean = false;
    var setVal2: boolean = false;
    var setVal3: boolean = false;
    var setVal4: boolean = false;
    var setVal5: boolean = false;

    var setVal6: boolean = false;
    var setVal7: boolean = false;
    var setVal8: boolean = false;
    var setVal9: boolean = false;
    var setVal10: boolean = false;
    if (this.exercise.exerciseName != undefined) {
      atleastOneAlpha = this.exercise.exerciseName.match(".*[a-zA-Z]+.*");
    }
    if (this.setsVal > 0) {
      for (let i = 1; i <= this.setsVal; i++) {
        if (i == 1) {
          if (this.repsVal <= 0) {
            setVal1 = true;
          }
        }
        if (i == 2) {
          if (this.repsVal1 <= 0) {
            setVal2 = true;
          }
        }
        if (i == 3) {
          if (this.repsVal2 <= 0) {
            setVal3 = true
          }
        }
        if (i == 4) {
          if (this.repsVal3 <= 0) {
            setVal4 = true
          }
        }
        if (i == 5) {
          if (this.repsVal4 <= 0) {
            setVal5 = true
          }
        }
        if (i == 6) {
          if (this.repsVal5 <= 0) {
            setVal6 = true
          }
        }
        if (i == 7) {
          if (this.repsVal6 <= 0) {
            setVal7 = true
          }
        }
        if (i == 8) {
          if (this.repsVal7 <= 0) {
            setVal8 = true
          }
        }
        if (i == 9) {
          if (this.repsVal8 <= 0) {
            setVal9 = true
          }
        }
        if (i == 10) {
          if (this.repsVal9 <= 0) {
            setVal10 = true
          }
        }
      }
    }
    if (this.videoHidden == false && this.exerciseVideo.youtubeUrl == "") {
      this.toastr.error('', 'Video is mandatory in "Add Video"', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return;
    }
    if (this.isYoutubeVerified == false && this.exerciseVideo.youtubeUrl != "") {
      this.toastr.error('', 'Please click on upload icon to add the YouTube video in "Add Video"', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return;
    }
    else if (this.exercise.exerciseName == null || this.exercise.exerciseName == undefined || this.exercise.exerciseName == '' || this.SelectedMainMuscleWorkout == null || this.SelectedMainMuscleWorkout == undefined || this.SelectedMainMuscleWorkout.length == 0) {
      this.toastr.error('', 'Mandatory fields are required in Add Details', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (this.exercise.instructions == null || this.exercise.instructions == undefined || this.exercise.instructions == '') {
      this.toastr.error('', 'Mandatory fields are required in Add Instructions', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    } else if (atleastOneAlpha == null) {
      this.toastr.error('', 'Please give valid exercise name.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (this.setsVal == 0) {
      this.toastr.error('', 'Please select minimum set for this excercise in Basic Workout.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (setVal1 == true) {
      this.toastr.error('', 'Zero cannot be stored as minimum values for reps or rest in SET 1', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (setVal2 == true) {
      this.toastr.error('', 'Zero cannot be stored as minimum values for reps or rest in SET 2', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (setVal3 == true) {
      this.toastr.error('', 'Zero cannot be stored as minimum values for reps or rest in SET 3', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (setVal4 == true) {
      this.toastr.error('', 'Zero cannot be stored as minimum values for reps or rest in SET 4', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (setVal5 == true) {
      this.toastr.error('', 'Zero cannot be stored as minimum values for reps or rest in SET 5', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (setVal6 == true) {
      this.toastr.error('', 'Zero cannot be stored as minimum values for reps or rest in SET 6', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (setVal7 == true) {
      this.toastr.error('', 'Zero cannot be stored as minimum values for reps or rest in SET 7', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (setVal8 == true) {
      this.toastr.error('', 'Zero cannot be stored as minimum values for reps or rest in SET 8', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (setVal9 == true) {
      this.toastr.error('', 'Zero cannot be stored as minimum values for reps or rest in SET 9', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (setVal10 == true) {
      this.toastr.error('', 'Zero cannot be stored as minimum values for reps or rest in SET 10', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else {

      let regexpAlpha = new RegExp('^[A-Za-z ]*[A-Za-z][A-Za-z ]*$');
      if (regexpAlpha.test(this.exercise.exerciseName) === false) {
        this.toastr.error('', 'Please provide valid exercise name', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        return;
      }
      // if (this.exercise.exerciseId == undefined) {
      // this.exercise.exerciseId = JSON.stringify(Math.floor(100000 + Math.random() * 900000));
      // this.exercise.exerciseId = this.exercise.trainerId.split("-")[0] + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 12);
      //}

      if (this.SelectedWorkoutType != undefined) {
        if (this.SelectedWorkoutType.length != 0) {
          this.exercise.workoutType = this.SelectedWorkoutType[0];
        }
      }
      if (this.SelectedMainMuscleWorkout != undefined) {
        if (this.SelectedMainMuscleWorkout.length != 0) {
          this.exercise.mainMuscleWorkout = this.MainMuscleWorkouts.find(x => x["id"] == this.SelectedMainMuscleWorkout[0]["id"]);
        }
      }
      if (this.SelectedOtherMuscleWorkouts != undefined) {
        if (this.SelectedOtherMuscleWorkouts.length != 0) {
          this.exercise.otherMuscleWorkout = [];
          for (let i = 0; i < this.SelectedOtherMuscleWorkouts.length; i++) {
            var othermucleWorkout = this.OtherMuscleWorkouts.find(y => y["id"] == this.SelectedOtherMuscleWorkouts[i]["id"]);
            if (this.exercise.otherMuscleWorkout == undefined) {
              this.exercise.otherMuscleWorkout = Array.of(othermucleWorkout);
            } else {
              this.exercise.otherMuscleWorkout.push(othermucleWorkout);
            }
          }
        }
      }

      // this.exercise.otherMuscleWorkout = this.OtherMuscleWorkouts.find(y=> y["id"] == this.SelectedOtherMuscleWorkouts[0]["id"]);

      if (this.SelectedEquipment != undefined) {
        if (this.SelectedEquipment.length != 0) {
          this.exercise.equipment = this.SelectedEquipment[0];
        }
      }

      if (this.SelectedMechanicalType != undefined) {
        if (this.SelectedMechanicalType.length != 0) {
          this.exercise.mechanicalType = this.SelectedMechanicalType[0];
        }
      }

      if (this.SelectedLevel != undefined) {
        if (this.SelectedLevel.length != 0) {
          this.exercise.level = this.SelectedLevel[0];
        }
      }

      if (this.SelectedSport != undefined) {
        if (this.SelectedSport.length != 0) {
          this.exercise.sport = this.SelectedSport[0];
        }
      }

      if (this.SelectedForce != undefined) {
        if (this.SelectedForce.length != 0) {
          this.exercise.force = this.SelectedForce[0];
        }
      }


      this.exerciseVideo.youtubeUrl = this.exerciseVideo.youtubeUrl;
      this.exercise.exerciseVideo = this.exerciseVideo;

      this.exercise.sets = [];

      for (let i = 1; i <= this.setsVal; i++) {
        if (i == 1) {
          this.set.maxWeights.actual = this.weightsVal;
          this.set.restPeriod.actual = this.restPeriodVal;
          this.set.reputationValue.actual = this.repsVal;
          this.set.setNo = i;
          this.exercise.sets.push(JSON.parse(JSON.stringify(this.set)));

        }
        if (i == 2) {
          this.set.maxWeights.actual = this.weightsVal1;
          this.set.restPeriod.actual = this.restPeriodVal1;
          this.set.reputationValue.actual = this.repsVal1;
          this.set.setNo = i;
          this.exercise.sets.push(JSON.parse(JSON.stringify(this.set)));

        }
        if (i == 3) {
          this.set.maxWeights.actual = this.weightsVal2;
          this.set.restPeriod.actual = this.restPeriodVal2;
          this.set.reputationValue.actual = this.repsVal2;
          this.set.setNo = i;
          this.exercise.sets.push(JSON.parse(JSON.stringify(this.set)));

        }
        if (i == 4) {
          this.set.maxWeights.actual = this.weightsVal3;
          this.set.restPeriod.actual = this.restPeriodVal3;
          this.set.reputationValue.actual = this.repsVal3;
          this.set.setNo = i;
          this.exercise.sets.push(JSON.parse(JSON.stringify(this.set)));

        }
        if (i == 5) {
          this.set.maxWeights.actual = this.weightsVal4;
          this.set.restPeriod.actual = this.restPeriodVal4;
          this.set.reputationValue.actual = this.repsVal4;
          this.set.setNo = i;
          this.exercise.sets.push(JSON.parse(JSON.stringify(this.set)));
        }
        if (i == 6) {
          this.set.maxWeights.actual = this.weightsVal5;
          this.set.restPeriod.actual = this.restPeriodVal5;
          this.set.reputationValue.actual = this.repsVal5;
          this.set.setNo = i;
          this.exercise.sets.push(JSON.parse(JSON.stringify(this.set)));
        }
        if (i == 7) {
          this.set.maxWeights.actual = this.weightsVal6;
          this.set.restPeriod.actual = this.restPeriodVal6;
          this.set.reputationValue.actual = this.repsVal6;
          this.set.setNo = i;
          this.exercise.sets.push(JSON.parse(JSON.stringify(this.set)));
        }
        if (i == 8) {
          this.set.maxWeights.actual = this.weightsVal7;
          this.set.restPeriod.actual = this.restPeriodVal7;
          this.set.reputationValue.actual = this.repsVal7;
          this.set.setNo = i;
          this.exercise.sets.push(JSON.parse(JSON.stringify(this.set)));
        }
        if (i == 9) {
          this.set.maxWeights.actual = this.weightsVal8;
          this.set.restPeriod.actual = this.restPeriodVal8;
          this.set.reputationValue.actual = this.repsVal8;
          this.set.setNo = i;
          this.exercise.sets.push(JSON.parse(JSON.stringify(this.set)));
        }
        if (i == 10) {
          this.set.maxWeights.actual = this.weightsVal9;
          this.set.restPeriod.actual = this.restPeriodVal9;
          this.set.reputationValue.actual = this.repsVal9;
          this.set.setNo = i;
          this.exercise.sets.push(JSON.parse(JSON.stringify(this.set)));
        }
      }
      var index = '/exercise';
      if (this.exercise.exerciseId == undefined) {
        this.exercise.creationDate = new Date();
        this.exercise.lastUpdatedOn = new Date();
        this.exercise.exerciseStatus = "new";
        this.exercise.exercisePercentage = "0";
        this.exercise.exerciseComments = [];
        this.db_service.saveDataToDB(this.exercise, index).subscribe(
          response => {
            this.exerciseResponse = Array.of(response);
            // alert('Data saved successfully.');
            this.toastr.success('', 'Exercise has been saved successfully', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
              progressBar: true,
              progressAnimation: 'increasing',
              tapToDismiss: true,
            });
            this.router.navigate(['exercise']);
          },
          error => {
            // alert('there is an error while saving data');
            this.toastr.error('', 'There is an error while saving Exercise', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
              progressBar: true,
              progressAnimation: 'increasing',
              tapToDismiss: true,
            });
          }

        )
      } else {
        var index = '/exercise/' + this.exercise.trainerId + '/' + this.exercise.exerciseId + '/status';
        this.db_service.getDataFromServer("", index).subscribe(response => {
          if (response.data == true) {
            this.isUpdateExercise = true;
          } else {
            this.onUpdateExercise("No");
          }
        })

      }
    }
  }
  onUpdateExercise(val) {
    if (val == "Yes")
      this.exercise.isChangeAffects = true
    else
      this.exercise.isChangeAffects = false;
    var index = '/exercise';
    this.exercise.lastUpdatedOn = new Date();
    this.db_service.updateDatainDB(this.exercise, index).subscribe(
      response => {
        this.exerciseResponse = Array.of(response);
        this.toastr.success('', 'Exercise has been updated successfully', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        this.router.navigate(['exercise']);
        this.isUpdateExercise = false;
      },
      error => {
        // alert('there is an error while saving data');
        this.toastr.error('', 'There is an error while updating exercise', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      }

    )
  }
  getExerciseData() {
    var index = '/exercise/';
    this.db_service.getDataFromServer(this.exercise.exerciseId, index).subscribe(
      response => {
        // console.log('Data Retrived succesfully.', response);
        //response = Array.of(response);
        this.exercise = response.data;
        this.drgDropHidden = false;
        this.videoHidden = true;
        this.editDisabled = true;;
        this.setsVal = this.exercise.sets.length;
        for (let i = 0; i < this.exercise.sets.length; i++) {
          if (i == 0) {
            this.weightsVal = this.exercise.sets[i]["maxWeights"]["actual"];
            this.restPeriodVal = this.exercise.sets[i]["restPeriod"]["actual"];
            this.repsVal = this.exercise.sets[i]["reputationValue"]["actual"];
          }
          if (i == 1) {
            this.weightsVal1 = this.exercise.sets[i]["maxWeights"]["actual"];
            this.restPeriodVal1 = this.exercise.sets[i]["restPeriod"]["actual"];
            this.repsVal1 = this.exercise.sets[i]["reputationValue"]["actual"];
          }
          if (i == 2) {
            this.weightsVal2 = this.exercise.sets[i]["maxWeights"]["actual"];
            this.restPeriodVal2 = this.exercise.sets[i]["restPeriod"]["actual"];
            this.repsVal2 = this.exercise.sets[i]["reputationValue"]["actual"];
          }
          if (i == 3) {
            this.weightsVal3 = this.exercise.sets[i]["maxWeights"]["actual"];
            this.restPeriodVal3 = this.exercise.sets[i]["restPeriod"]["actual"];
            this.repsVal3 = this.exercise.sets[i]["reputationValue"]["actual"];
          }
          if (i == 4) {
            this.weightsVal4 = this.exercise.sets[i]["maxWeights"]["actual"];
            this.restPeriodVal4 = this.exercise.sets[i]["restPeriod"]["actual"];
            this.repsVal4 = this.exercise.sets[i]["reputationValue"]["actual"];
          }
          if (i == 5) {
            this.weightsVal5 = this.exercise.sets[i]["maxWeights"]["actual"];
            this.restPeriodVal5 = this.exercise.sets[i]["restPeriod"]["actual"];
            this.repsVal5 = this.exercise.sets[i]["reputationValue"]["actual"];
          }
          if (i == 6) {
            this.weightsVal6 = this.exercise.sets[i]["maxWeights"]["actual"];
            this.restPeriodVal6 = this.exercise.sets[i]["restPeriod"]["actual"];
            this.repsVal6 = this.exercise.sets[i]["reputationValue"]["actual"];
          }
          if (i == 7) {
            this.weightsVal7 = this.exercise.sets[i]["maxWeights"]["actual"];
            this.restPeriodVal7 = this.exercise.sets[i]["restPeriod"]["actual"];
            this.repsVal7 = this.exercise.sets[i]["reputationValue"]["actual"];
          }
          if (i == 8) {
            this.weightsVal8 = this.exercise.sets[i]["maxWeights"]["actual"];
            this.restPeriodVal8 = this.exercise.sets[i]["restPeriod"]["actual"];
            this.repsVal8 = this.exercise.sets[i]["reputationValue"]["actual"];
          }
          if (i == 9) {
            this.weightsVal9 = this.exercise.sets[i]["maxWeights"]["actual"];
            this.restPeriodVal9 = this.exercise.sets[i]["restPeriod"]["actual"];
            this.repsVal9 = this.exercise.sets[i]["reputationValue"]["actual"];
          }
        }
        if (this.exercise.workoutType != null) {
          this.SelectedWorkoutType = Array.of(this.exercise.workoutType).sort();
        }
        if (this.exercise.mainMuscleWorkout != null) {
          this.SelectedMainMuscleWorkout = Array.of(this.exercise.mainMuscleWorkout).sort();
        }
        if (this.exercise.otherMuscleWorkout != null) {
          this.SelectedOtherMuscleWorkouts = this.exercise.otherMuscleWorkout;
        }
        if (this.exercise.equipment != null) {
          this.SelectedEquipment = Array.of(this.exercise.equipment).sort();
        }
        if (this.exercise.mechanicalType != null) {
          this.SelectedMechanicalType = Array.of(this.exercise.mechanicalType).sort();
        }
        if (this.exercise.level != null) {
          this.SelectedLevel = Array.of(this.exercise.level).sort();
        }
        if (this.exercise.sport != null) {
          this.SelectedSport = Array.of(this.exercise.sport).sort();
        }
        if (this.exercise.force != null) {
          this.SelectedForce = Array.of(this.exercise.force).sort();
        }



        this.exerciseVideo = this.exercise.exerciseVideo;
        if (this.exerciseVideo.youtubeUrl == "" && this.exerciseVideo.videoDestinationCheck == false) {
          var dest = this.exerciseVideo.videoMp4Destination.split("/");
          var FolderPath = dest[3] + "/" + dest[4] + "/" + dest[5] + "/" + dest[6] + "/" + dest[7];
          this.awsService.checkUrl(this.globals.DEST_BUCKET, FolderPath).subscribe(
            response => {

              if (response != "false") {
                this.videoUrl = this.exerciseVideo.videoMp4Destination;
                this.exerciseVideo.videoDestinationCheck = true;
                this.exerciseVideo.exerciseVideoSource = this.exerciseVideo.videoMp4Destination;
                this.updateExercise(this.exercise);
              }
            },
            error => {
              this.exerciseVideo.exerciseVideoSource = this.exerciseVideo.videoSourceUrl;
            }
          );
        } else if (this.exerciseVideo.youtubeUrl != "") {

          this.changeYoutubeURL();
          this.videoHidden = false;
          this.exerciseVideo.exerciseVideoSource = this.exerciseVideo.youtubeUrl;

        } else {
          this.exerciseVideo.exerciseVideoSource = this.exerciseVideo.videoMp4Destination;
        }
      },
      error => {
        //alert("An error has occurred while retriving exercise data.");
        this.toastr.error('', 'An error has occurred while retriving exercise data.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      })

  }
  isInArray(array, word) {
    return array.indexOf(word.toLowerCase()) > -1;
  }
  changeYoutubeURL() {
    this.isYoutubeVerified = true;


    // Check for empty input before calling embedService methods
    if (!this.exerciseVideo.youtubeUrl.trim()) {
      this.toastr.error('', 'Please provide a valid Youtube video URL', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return; // Prevent further execution if empty
    }

    //console.log(event);
    //console.log(this.embedService.embed(this.exercise.youtubeUrl));
    this.iframeURL = this.embedService.embed(this.exerciseVideo.youtubeUrl, {
      query: { portrait: 0, color: 'black' },
      attr: { width: "100%", height: 450, frameborder: 1, style: "border: 1px solid black;border-radius: 7px; box-shadow: 0 0px 20px rgba(154, 246, 123, 0.3);" }
    })
    // console.log(this.iframeURL);
    this.exerciseVideo.exerciseVideoSource = this.exerciseVideo.youtubeUrl;
    if (this.iframeURL != undefined) {
      this.embedYoutubeUrl = this.iframeURL;
      this.embedService
        .embed_image(this.exerciseVideo.youtubeUrl,
          { image: 'mqdefault' }
        )
        .then(res => {
          this.exerciseVideo.videoThumbnailPath = res.link;
        });
      this.drgDropHidden = false;
      this.iframeHidden = true;
    } else {
      this.toastr.error('', 'Please provide a valid Youtube video URL', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    //console.log(this.iframeURL);
  }
  selectFile(event, filetype) {
    // this.selectedFile = event.target.files[0];
    // console.log(event);
    this.selectedFile = event;
    //var format = /[!@#$%^&*()_+\=\[\]{};':"\\|,<>\/?]/;
    console.log("the file size : " + this.selectedFile[0].size);
    // var videofileCheck = format.test(this.selectedFile[0].name);
    // if (videofileCheck == true) {
    //   this.toastr.error('', 'Special charecters are not allowed in video file name.', {
    //     timeOut: 3000,
    //     positionClass: 'toast-top-right',
    //     progressBar: true,
    //     progressAnimation: 'increasing',
    //     tapToDismiss: true,
    //   });
    // }
    // else if(this.selectedFile[0].size > 35000000 ){
    //   this.toastr.error('', 'Maximum upload file size is 35 Mb', {
    //     timeOut: 3000,
    //     positionClass: 'toast-top-right',
    //     progressBar: true,
    //     progressAnimation: 'increasing',
    //     tapToDismiss: true,
    //   });
    // }
    //else {
    this.fileExtension = this.selectedFile[0].name.split('.').pop();
    if (this.isInArray(this.globals.VideoallowedExtensions, this.fileExtension)) {
      if (this.exerciseVideo.exerciseVideoName != "") {
        var videoName = this.exerciseVideo.exerciseVideoName.replace(/ /g, "_");
        this.awsService.deleteFileFromS3(videoName, this.exercise.trainerId, this.globals.SORCE_BUCKET, this.globals.EXERCISE_FOLDER).subscribe(
          response => {
            //this.fileUpload(filetype);
            //console.log('Video deleted successfully.', response);
            var assetId = this.exerciseVideo.videoMp4Destination.split("/");
            this.awsService.deleteDestinationFolder(this.exercise.trainerId, this.globals.DEST_BUCKET, this.globals.EXERCISE_FOLDER, assetId[6]).subscribe(
              response => {
                // this.exercise.preVideoId = this.exercise.videoId;
                this.fileUpload(filetype);
              },
              error => {
                console.log('There is an error deleting video from destination folder.', error);
              })

          },
          error => {
            console.log('There is an error deleting video from source folder.', error);
          })
      } else {
        this.fileUpload(filetype);
      }
    } else {
      this.toastr.error('', 'Please upload only videos with mp4 format.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    // }

  }
  fileUpload(filetype) {
    this.loaderHide = !this.loaderHide;
    this.exerciseVideo.exerciseVideoName = this.selectedFile[0].name;
    this.exerciseVideo.videoDestinationCheck = false;
    this.awsService.uploadFileToS3(this.selectedFile[0], this.exercise.trainerId, this.globals.SORCE_BUCKET, this.globals.EXERCISE_FOLDER, filetype).subscribe(
      response => {
        //this.profile.key = response.Key;
        this.exerciseVideo.videoSourceUrl = response.sourceUrl;
        this.exerciseVideo.exerciseVideoSource = response.sourceUrl;
        this.exerciseVideo.videoMp4Destination = response.mp4Destination;
        this.exerciseVideo.videoThumbnailPath = response.thumbnailDestination;
        this.thumbUrl = response.thumbnailDestination;
        // this.videoUrl = response.sourceUrl;
        this.loaderHide = !this.loaderHide;
        // console.log('Video uploaded Successfully.', response);
        this.toastr.success('', 'Video uploaded successfully.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        this.drgDropHidden = false;
        this.videoHidden = true;
      },
      error => {
        this.loaderHide = !this.loaderHide;
        // console.log('There is an error uploading video.', error);
        this.toastr.error('', 'There is an error while uploading video.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        this.drgDropHidden = true;
        this.videoHidden = false;
      })
  }
  onVideoPopupClose(){
    this.videoHidden = false;
    this.iframeHidden = false;
    this.drgDropHidden = true;
  }
  Cancel() {
    this.router.navigate(['exercise']);
  }
  updateExercise(data) {
    var index = '/exercise'
    this.exercise.workoutType = this.SelectedWorkoutType[0];
    this.exercise.mainMuscleWorkout = this.SelectedMainMuscleWorkout[0];
    this.exercise.otherMuscleWorkout = [];
    for (let i = 0; i < this.SelectedOtherMuscleWorkouts.length; i++) {
      var othermucleWorkout = this.OtherMuscleWorkouts.find(y => y["id"] == this.SelectedOtherMuscleWorkouts[i]["id"]);
      if (this.exercise.otherMuscleWorkout == undefined) {
        this.exercise.otherMuscleWorkout = Array.of(othermucleWorkout);
      } else {
        this.exercise.otherMuscleWorkout.push(othermucleWorkout);
      }
    }
    this.exercise.equipment = this.SelectedEquipment[0];
    this.exercise.mechanicalType = this.SelectedMechanicalType[0];
    this.exercise.level = this.SelectedLevel[0];
    this.exercise.sport = this.SelectedSport[0];
    this.exercise.force = this.SelectedForce[0];
    this.exerciseVideo.youtubeUrl = this.exerciseVideo.youtubeUrl;
    this.exercise.exerciseVideo = this.exerciseVideo;
    this.exercise.sets = [];

    for (let i = 1; i <= this.setsVal; i++) {
      if (i == 1) {
        this.set.maxWeights.actual = this.weightsVal;
        this.set.restPeriod.actual = this.restPeriodVal;
        this.set.reputationValue.actual = this.repsVal;
        this.set.setNo = i;
        this.exercise.sets.push(JSON.parse(JSON.stringify(this.set)));

      }
      if (i == 2) {
        this.set.maxWeights.actual = this.weightsVal1;
        this.set.restPeriod.actual = this.restPeriodVal1;
        this.set.reputationValue.actual = this.repsVal1;
        this.set.setNo = i;
        this.exercise.sets.push(JSON.parse(JSON.stringify(this.set)));

      }
      if (i == 3) {
        this.set.maxWeights.actual = this.weightsVal2;
        this.set.restPeriod.actual = this.restPeriodVal2;
        this.set.reputationValue.actual = this.repsVal2;
        this.set.setNo = i;
        this.exercise.sets.push(JSON.parse(JSON.stringify(this.set)));

      }
      if (i == 4) {
        this.set.maxWeights.actual = this.weightsVal3;
        this.set.restPeriod.actual = this.restPeriodVal3;
        this.set.reputationValue.actual = this.repsVal3;
        this.set.setNo = i;
        this.exercise.sets.push(JSON.parse(JSON.stringify(this.set)));

      }
      if (i == 5) {
        this.set.maxWeights.actual = this.weightsVal4;
        this.set.restPeriod.actual = this.restPeriodVal4;
        this.set.reputationValue.actual = this.repsVal4;
        this.set.setNo = i;
        this.exercise.sets.push(JSON.parse(JSON.stringify(this.set)));
      }
      if (i == 6) {
        this.set.maxWeights.actual = this.weightsVal5;
        this.set.restPeriod.actual = this.restPeriodVal5;
        this.set.reputationValue.actual = this.repsVal5;
        this.set.setNo = i;
        this.exercise.sets.push(JSON.parse(JSON.stringify(this.set)));
      }
      if (i == 7) {
        this.set.maxWeights.actual = this.weightsVal6;
        this.set.restPeriod.actual = this.restPeriodVal6;
        this.set.reputationValue.actual = this.repsVal6;
        this.set.setNo = i;
        this.exercise.sets.push(JSON.parse(JSON.stringify(this.set)));
      }
      if (i == 8) {
        this.set.maxWeights.actual = this.weightsVal7;
        this.set.restPeriod.actual = this.restPeriodVal7;
        this.set.reputationValue.actual = this.repsVal7;
        this.set.setNo = i;
        this.exercise.sets.push(JSON.parse(JSON.stringify(this.set)));
      }
      if (i == 9) {
        this.set.maxWeights.actual = this.weightsVal8;
        this.set.restPeriod.actual = this.restPeriodVal8;
        this.set.reputationValue.actual = this.repsVal8;
        this.set.setNo = i;
        this.exercise.sets.push(JSON.parse(JSON.stringify(this.set)));
      }
      if (i == 10) {
        this.set.maxWeights.actual = this.weightsVal9;
        this.set.restPeriod.actual = this.restPeriodVal9;
        this.set.reputationValue.actual = this.repsVal9;
        this.set.setNo = i;
        this.exercise.sets.push(JSON.parse(JSON.stringify(this.set)));
      }

    }
    this.exercise.lastUpdatedOn = new Date();

    this.db_service.updateDatainDB(this.exercise, index).subscribe(
      response => {
        console.log('destVideoCheck updated succesfully.');
      },
      error => {
        console.log('there is an error while updating destVideoCheck');
      }

    )
  }
  closePopup() {
    this.isUpdateExercise = false;
    this.exercise.isChangeAffects = false;
    // this.videoHidden = false;
    // this.drgDropHidden = true;
  }

  toggleCollapsed(): void {
    // Bootstrap uses display CSS property to effect the collapse, so we need this to manually trigger a refresh
    this.manualRefresh.emit();
  }
}
