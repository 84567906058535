import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from "@angular/core";

// import { HeaderComponent } from './header/header.component';
import { LandingComponent } from './landing/landing.component';
import { ContentComponent } from './content/content.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { ForgetpassComponent } from './forgetpass/forgetpass.component';
import { ProfilrComponent } from './profilr/profilr.component';
import { GenderComponent } from './gender/gender.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { ExerciseComponent } from './exercise/exercise.component';
// import { CategoryComponent } from './category/category.component';
import { MyprofileComponent } from './myprofile/myprofile.component';
import { AddexerciseComponent } from './addexercise/addexercise.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { WorkoutComponent } from './workout/workout.component';
import { AddworkoutComponent } from './addworkout/addworkout.component';
import { ViewworkoutComponent } from './viewworkout/viewworkout.component';
import { ResendComponent } from './resend/resend.component';
import { ProgramComponent } from './program/program.component';
import { AddprogramComponent } from './addprogram/addprogram.component'
import { CalendarComponent } from './calendar/calendar.component';
import { TraineeComponent } from './trainee/trainee.component';
import { TraineeprofileComponent } from './traineeprofile/traineeprofile.component';
import { TraineeMealplanComponent } from './trainee-mealplan/trainee-mealplan.component';
import { MealplanCalendarComponent } from './trainee-mealplan/mealplan-calendar/mealplan-calendar.component';
import { MealplanGenerationComponent } from './trainee-mealplan/mealplan-generation/mealplan-generation.component';

// Communication POC

import { TraineeLoginComponent } from './trainee-video/trainee-login/trainee-login.component';
import { TraineeBookslotComponent } from './trainee-video/trainee-bookslot/trainee-bookslot.component';
import { TwilliVideoComponent } from './twilli-video/twilli-video.component';
import { TwilioGroupCallComponent } from './twilio-group-call/twilio-group-call.component';
import { CalendarSampleComponent } from './calendar-sample/calendar-sample.component';
import { CallSchedulerComponent } from './call-scheduler/call-scheduler.component';
import { TraineeGalleryComponent } from './trainee-gallery/trainee-gallery.component';
import { BussinessDetailsComponent } from './bussiness-details/bussiness-details.component';
import { TraineeDetailsComponent } from './trainee-details/trainee-details.component';
import { ReportsComponent } from './reports/reports.component';
import { NutritionComponent } from './nutrition/nutrition.component';
import { AddNutritionComponent } from './add-nutrition/add-nutrition.component';
import { TraineeReportsComponent } from './trainee-reports/trainee-reports.component';
import { AddAsanasComponent } from './add-asanas/add-asanas.component';
import { AsanasComponent } from './asanas/asanas.component';
import { AddprogramYogaComponent } from './addprogram-yoga/addprogram-yoga.component';
import { ProgramYogaComponent } from './program-yoga/program-yoga.component';
import { CalendarYogaComponent } from './calendar-yoga/calendar-yoga.component';
import { YogaMealplanCalendarComponent } from './trainee-mealplan/yoga-mealplan-calendar/yoga-mealplan-calendar.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ZoomViewComponent } from './zoom-view/zoom-view.component';
import { SampleComponent } from './sample/sample.component';


const routes: Routes = [
  { path: 'content', component: ContentComponent },
  { path: 'login', component: LoginComponent },
  { path: 'forgetPassword', component: ForgetpassComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'resend', component: ResendComponent },
  // { path: 'category/:username/:userID/:type', component:CategoryComponent},
  { path: 'gender/:username/:userID', component: GenderComponent },
  { path: 'profile/:username/:userID/:genType', component: ProfilrComponent },
  { path: 'mydata/:userID', component: MyprofileComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'confirm/:username', component: ConfirmComponent },
  { path: 'exercise', component: ExerciseComponent },
  { path: 'addexercise', component: AddexerciseComponent },
  { path: 'reports', component: ReportsComponent },
  { path: 'editexercise/:exerciseID', component: AddexerciseComponent },
  { path: 'workout', component: WorkoutComponent },
  { path: 'addworkout', component: AddworkoutComponent },
  { path: 'viewworkout/:workoutID', component: ViewworkoutComponent },
  { path: 'program', component: ProgramComponent },
  { path: 'addprogram', component: AddprogramComponent },
  { path: 'addprogram/:programID', component: AddprogramComponent },
  { path: 'calendar/:programID', component: CalendarComponent },
  { path: 'trainee', component: TraineeComponent },
  { path: 'trainee/:programID', component: TraineeComponent },
  { path: 'tprofile/:programID/:traineeID', component: TraineeprofileComponent },
  { path: 'mealplanCalendar', component: MealplanCalendarComponent },
  { path: 'mealplanGen/:programID/:traineeID', component: MealplanGenerationComponent },
  { path: 'traineeMeal/:programID/:traineeID', component: TraineeMealplanComponent },
  { path: 'traineeLogin', component: TraineeLoginComponent },
  { path: 'traineeSlot/:traineeId', component: TraineeBookslotComponent },
  { path: 'traineeGallery/:programID/:traineeId', component: TraineeGalleryComponent },
  { path: 'twilioVideo/:accessToken/:roomName', component: TwilliVideoComponent },
  { path: 'twilioGroup/:accessToken/:roomName', component: TwilioGroupCallComponent },
  { path: 'calSamp', component: CalendarSampleComponent },
  { path: 'callscheduler', component: CallSchedulerComponent },
  { path: 'businessdetails/:trainerId', component: BussinessDetailsComponent },
  { path: 'traineedetails/:programID/:traineeId', component: TraineeDetailsComponent },
  { path: 'nutrition', component: NutritionComponent},
  { path: 'addNutrition/:mealId', component: AddNutritionComponent},
  { path: 'addNutrition', component: AddNutritionComponent},
  { path: 'traineeReports/:programID/:traineeId', component: TraineeReportsComponent },
  { path: 'asanas', component: AsanasComponent},
  { path: 'addAsanas', component: AddAsanasComponent},
  { path: 'editAsanas/:asanaID', component: AddAsanasComponent},
  { path: 'yogaPrograms', component: ProgramYogaComponent},
  { path: 'addYogaProgram', component: AddprogramYogaComponent},
  { path: 'addYogaProgram/:programID', component: AddprogramYogaComponent},
  { path: 'yogaCalendar/:programID', component: CalendarYogaComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'yogaMealplanCalendar/:programID/:traineeID', component: YogaMealplanCalendarComponent },
  { path: '', component: ContentComponent },
  { path: 'meet/:slotID', component: ZoomViewComponent }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  // imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
// export const appRoutingProviders: any[] = [];

// export const routing: ModuleWithProviders = RouterModule.forRoot(routes);
