<section class="p-3">

  <div class="container">
    <div class="row">
      <div class="heading1 col-md-6 col-7 mb-5"><img src="assets/images/mealplan-v3.png" width="25"><span><b>Meal
            Plan</b></span>
      </div>
      <div class="col-md-6 col-5">
        <button class="btn btn-default save-exercise-btn mr-0 float-right" style="cursor: pointer;"
         routerLink="/nutrition" type="button"
          tabindex="0">BACK</button>
      </div>
    </div>
  </div>
  <div class="container-fluid main-container-size">
    <div class="row">

  <div class="col-md-8 mealplan-left-div order-last order-md-1">
    <div class="mealplan-div-bg mealplan-title-div mb-4 order-2 p-3">
      <div class="row">
        <div class="col-md-7 col-lg-7">
          <div class="meal-plan-title-div-sm-text">
          </div>
          <div class="meal-plan-main-headings mpt-orange font-weight-bold">
            <input type="text" class="form-control col-12" id="mealName" name="mealName" autocomplete="off"
              style="border-bottom: 1px solid rgba(243, 243, 243, 0.767);"
              [(ngModel)]="day.mealName" minlength="1" pattern="^[a-zA-Z ]+$" placeholder="Meal name" />
          </div>
        </div>
        <div class="col-md-5 col-lg-5 text-right">
          <div *ngIf="isDataEditable">
            <img src="assets/images/savemeal.png" (click)="saveDayMeal()"
              style="cursor: pointer;width: 25px;height: 25px; margin-top: 20px;" width="50" alt="alternate text" />
          </div>

        </div>


      </div>
    </div>

    <!-- div meal plan starts here -->
    <div class="mealplan-div-bg mealplan-breakfast-div mb-4 p-3">
      <div class="row">
        <div class="col breakfast-header pb-2 p-0">
          <div class="d-flex">
            <div class="col-md-8  mpt-orange meal-plan-main-headings font-weight-bold "> Breakfast</div>
            <div class="col-md-4 text-right" (click)="onSearch('breakfast')" *ngIf="isDataEditable">
              <i class="fa fa-plus text-success" aria-hidden="true "></i></div>
          </div>
        </div>
        <div class="meal-plan-breakfast-details col-md-12">
          <div class="row" style="border-bottom: 1px solid rgba(255, 255, 255, 0.11);
                        margin-bottom: 6px;" *ngFor="let bfast of day.breakfast.foodItems">
            <div class="meal-plan-item-image col-sm-6 col-md-2 text-center">
              <!-- <img src="https://i.picsum.photos/id/866/120/80.jpg"> -->
              <img class="" src="{{ bfast.photo.thumb }}" style="cursor: pointer;" width="50" alt="alternate text" />
            </div>
            <div class="meal-plan-details mp-detail-text-header-font col-sm-6 col-md-10">
              <div class="col-md-12 p-1">
                <div class="d-flex">
                  <div class="col-md-6 mpt-orange font-weight-bold" style="font-size: 15px;">{{ bfast.food_name }}
                  </div>

                  <div class="col-md-6 text-right">
                    <i class="fa fa-pencil-square-o mx-1" *ngIf="isDataEditable" (click)="editMeal('breakfast', bfast.refId)"></i>
                    <i class="fa fa-trash text-danger mx-1" aria-hidden="true" *ngIf="isDataEditable" (click)="deleteMeal('breakfast', bfast.refId)"></i>
                  </div>
                </div>
              </div>
            
              <div class="col-md-12 p-1">
                <div class="d-flex">
                  <div class="col-md-4">
                    <span class="font-weight-bold"> Engery: </span>
                    <span> {{ bfast.nf_calories.recommended }} </span>
                    <span>kcal</span>
                  </div>
                  <div class="col-md-4">
                    <span class="font-weight-bold"> Recommended Qty: </span>
                    <span><i class="fa fa-minus text-primary ml-1" style="font-size: 0.8rem;" aria-hidden="true "
                      (click)="CalculateNutrients('breakfast', bfast,'substraction')"></i></span>
                    <span>{{ bfast.serving_qty.recommended }}</span>
                    <span><i class=" fa fa-plus text-primary mr-1" style="font-size: 0.8rem;" aria-hidden="true "
                      (click)="CalculateNutrients('breakfast', bfast,'addition')"></i></span>
                    
  
                  </div>
                  <div class="col-md-4 d-flex">
                    <div class="text-primary"><img
                        src="https://img.icons8.com/officexs/16/000000/time-machine--v1.png" /></div>
                    <input atp-time-picker type="time" id="time" class="col" name="time"
                      style="margin-top: 2px; font-size:10px;" [(ngModel)]="bfast.time" required>
                    <!-- <div class="form-group col-6"> </div> -->

                  </div>
                </div>
              </div>
              <div class="col-md-12 p-1 px-3">
                <div class="d-flex">
                  <div class="col p-1">
                    <span>Protein: <span class="mpt-orange">{{ bfast.nf_protein.recommended }} g</span></span>
                  </div>

                  <div class="col p-1">
                    <span>Carbs: <span class="mpt-orange">{{ bfast.nf_total_carbohydrate.recommended }} g</span></span>
                  </div>
                  <div class="col p-1">
                    <span>Fat: <span class="mpt-orange">{{ bfast.nf_total_fat.recommended }} g</span></span>
                  </div>
                  <div class="col p-1">
                    <span>Fiber: <span class="mpt-orange">{{ bfast.nf_dietary_fiber.recommended }} g</span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="mealplan-div-bg mealplan-breakfast-div order-1">
      <div class="col-md-12 p-3">
        <div class="row ">
          <div class="col-md-1">
            <div class="p-2 text-center">Total</div>
          </div>
          <div class="col-md-11">
            <div class="d-flex">
              <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                <div style="display: grid"><span>Calories</span> <span
                    class=" mp-protine-value-font">{{ day.breakfast.overall_calories_recommended }} kcal</span></div>
              </div>
              <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                <div style="display: grid"><span>Protein</span> <span
                    class="mpt-orange mp-protine-value-font">{{ day.breakfast.overall_protein_recommended }}
                    g</span></div>
              </div>

              <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                <div style="display: grid"><span>Carbs</span> <span
                    class="mpt-orange mp-protine-value-font">{{ day.breakfast.overall_carboHydrate_recommended }}
                    g</span></div>
              </div>
              <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                <div style="display: grid"><span>Fat</span> <span
                    class="mpt-orange mp-protine-value-font">{{ day.breakfast.overall_fat_recommended }} g</span>
                </div>
              </div>
              <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                <div style="display: grid"><span>Fiber</span> <span
                    class="mpt-orange mp-protine-value-font">{{ day.breakfast.overall_fibre_recommended }} g</span>
                </div>
              </div>
            </div>
          </div>



          <!-- <div class="col-md-1 text-right mt-1 text-center">
                           <img src="assets/images/info-graph.png" width="30" height="auto">
                      </div> -->
        </div>
      </div>
    </div>
    <div class="mealplan-div-bg mealplan-breakfast-div mb-4 mt-4 p-3">
      <div class="row">
        <div class="col breakfast-header pb-2 p-0">
          <div class="d-flex">
            <div class="col-md-8  mpt-orange meal-plan-main-headings font-weight-bold "> Lunch</div>
            <div class="col-md-4 text-right" (click)="onSearch('lunch')" *ngIf="isDataEditable">
              <i class="fa fa-plus text-success" aria-hidden="true "></i></div>
          </div>
        </div>
        <div class="meal-plan-breakfast-details col-md-12">
          <div class="row" style="border-bottom: 1px solid rgba(255, 255, 255, 0.11);
                      margin-bottom: 6px;" *ngFor="let lunch of day.lunch.foodItems">
            <div class="meal-plan-item-image col-sm-6 col-md-2 text-center">
              <img class="" (click)="getNutrients(lunch.food_name, 'lunch')" src="{{ lunch.photo.thumb }}"
                style="cursor: pointer;" width="50" alt="alternate text" />
            </div>
            <div class="meal-plan-details mp-detail-text-header-font col-sm-6 col-md-10">

              <div class="col-md-12 p-1">
                <div class="d-flex">
                  <div class="col-md-6 mpt-orange font-weight-bold" style="font-size: 15px;">{{ lunch.food_name }}
                  </div>

                  <div class="col-md-6 text-right">
                    <i class="fa fa-pencil-square-o mx-1" *ngIf="isDataEditable" (click)="editMeal('lunch', lunch.refId)"></i>
                    <i class="fa fa-trash text-danger mx-1" aria-hidden="true" *ngIf="isDataEditable" (click)="deleteMeal('lunch', lunch.refId)"></i></div>
                </div>
              </div>
              
             <div class="col-md-12 p-1">
              <div class="d-flex">
                <div class="col-md-4">
                  <span class="font-weight-bold"> Engery: </span>
                  <span>{{ lunch.nf_calories.recommended }} </span>
                  <span>kcal</span>
                </div>
                <div class="col-md-4">
                  <span class="font-weight-bold"> Recommended Qty: </span>
                  <span><i class="fa fa-minus text-primary ml-1" style="font-size: 0.8rem;" aria-hidden="true "
                    (click)="CalculateNutrients('lunch', lunch,'substraction')"></i></span>
                  <span>{{ lunch.serving_qty.recommended }}</span>
                  <span><i class=" fa fa-plus text-primary mr-1" style="font-size: 0.8rem;" aria-hidden="true "
                    (click)="CalculateNutrients('lunch', lunch,'addition')"></i></span>
                  
                </div>
                <div class="col-md-4 d-flex">
                  <span class="text-primary"><img
                      src="https://img.icons8.com/officexs/16/000000/time-machine--v1.png" /></span>
                  <input atp-time-picker type="time" id="time" name="time"
                    style="margin-top: 2px; font-size:10px;" [(ngModel)]="lunch.time" required>
                </div>

              </div>
            </div>

              <div class="col-md-12 p-1 px-3">
                <div class="d-flex">
                  <div class="col p-1">
                    <span>Protein: <span class="mpt-orange">{{ lunch.nf_protein.recommended }} g</span></span>
                  </div>

                  <div class="col p-1">
                    <span>Carbs: <span class="mpt-orange">{{ lunch.nf_total_carbohydrate.recommended }} g</span></span>
                  </div>
                  <div class="col p-1">
                    <span>Fat: <span class="mpt-orange">{{ lunch.nf_total_fat.recommended }} g</span></span>
                  </div>
                  <div class="col p-1">
                    <span>Fiber: <span class="mpt-orange">{{ lunch.nf_dietary_fiber.recommended }} g</span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mealplan-div-bg mealplan-breakfast-div order-1">
      <div class="col-md-12 p-3">
        <div class="row">
          <div class="col-md-1">
            <div class="p-2 text-center">Total</div>
          </div>
          <div class="col-md-11">
            <div class="d-flex">
              <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                <div style="display: grid"><span>Calories</span> <span
                    class=" mp-protine-value-font">{{ day.lunch.overall_calories_recommended }} kcal</span></div>
              </div>
              <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                <div style="display: grid"><span>Protein</span> <span
                    class="mpt-orange mp-protine-value-font">{{ day.lunch.overall_protein_recommended }} g</span>
                </div>
              </div>

              <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                <div style="display: grid"><span>Carbs</span> <span
                    class="mpt-orange mp-protine-value-font">{{ day.lunch.overall_carboHydrate_recommended }}
                    g</span></div>
              </div>
              <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                <div style="display: grid"><span>Fat</span> <span
                    class="mpt-orange mp-protine-value-font">{{ day.lunch.overall_fat_recommended }} g</span></div>
              </div>
              <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                <div style="display: grid"><span>Fiber</span> <span
                    class="mpt-orange mp-protine-value-font">{{ day.lunch.overall_fibre_recommended }} g</span>
                </div>
              </div>
            </div>
          </div>



          <!-- <div class="col-md-1 text-right mt-1 text-center">
                         <img src="assets/images/info-graph.png" width="30" height="auto">
                    </div> -->
        </div>
      </div>
    </div>
    <div class="mealplan-div-bg mealplan-breakfast-div mb-4 mt-4 p-3">
      <div class="row">
        <div class="col breakfast-header pb-2 p-0">
          <div class="d-flex">
            <div class="col-md-8  mpt-orange meal-plan-main-headings font-weight-bold "> Snacks</div>
            <div class="col-md-4 text-right" (click)="onSearch('snacks')" *ngIf="isDataEditable">
              <i class="fa fa-plus text-success" aria-hidden="true "></i></div>
          </div>
        </div>
        <div class="meal-plan-breakfast-details col-md-12">
          <div class="row" style="border-bottom: 1px solid rgba(255, 255, 255, 0.11);
                    margin-bottom: 6px;" *ngFor="let snack of day.snacks.foodItems">
            <div class="meal-plan-item-image col-sm-6 col-md-2 text-center">
              <img class="" src="{{ snack.photo.thumb }}" style="cursor: pointer;" width="50" alt="alternate text" />
            </div>
            <div class="meal-plan-details mp-detail-text-header-font col-sm-6 col-md-10">

              <div class="col-md-12 p-1">
                <div class="d-flex">
                  <div class="col-md-6 mpt-orange font-weight-bold" style="font-size: 15px;">{{ snack.food_name }}
                  </div>
                
                  <div class="col-md-6 text-right">
                    <i class="fa fa-pencil-square-o mx-1" *ngIf="isDataEditable" (click)="editMeal('snacks', snack.refId)"></i>
                    <i class="fa fa-trash text-danger mx-1" aria-hidden="true" *ngIf="isDataEditable" (click)="deleteMeal('snacks', snack.refId)"></i></div>
                </div>
              </div>
            

              <div class="col-md-12 p-1">
                <div class="d-flex">
                  <div class="col-md-4">
                    <span class="font-weight-bold"> Engery: </span>
                    <span>{{ snack.nf_calories.recommended }} </span>
                    <span>kcal</span>
                  </div>
                  <div class="col-md-4">
                    <span class="font-weight-bold"> Recommended Qty: </span>
                    <span><i class="fa fa-minus text-primary ml-1" style="font-size: 0.8rem;" aria-hidden="true "
                      (click)="CalculateNutrients('snacks', snack,'substraction')"></i></span>
                    <span>{{ snack.serving_qty.recommended }}</span>
                    <span><i class=" fa fa-plus text-primary mr-1" style="font-size: 0.8rem;" aria-hidden="true "
                      (click)="CalculateNutrients('snacks', snack,'addition')"></i></span>
                    
                  </div>

                  <div class="col-md-4 d-flex">
                    <span class="text-primary"><img
                        src="https://img.icons8.com/officexs/16/000000/time-machine--v1.png" /> </span>
                    <input atp-time-picker type="time" class="col" id="time" name="time"
                      style="margin-top: 2px; font-size:10px;" [(ngModel)]="snack.time" required>
                  </div>


                </div>
              </div>

              <div class="col-md-12 p-1 px-3">
                <div class="d-flex">
                  <div class="col p-1">
                    <span>Protein: <span class="mpt-orange">{{ snack.nf_protein.recommended }} g</span></span>
                  </div>

                  <div class="col p-1">
                    <span>Carbs: <span class="mpt-orange">{{ snack.nf_total_carbohydrate.recommended }} g</span></span>
                  </div>
                  <div class="col p-1">
                    <span>Fat: <span class="mpt-orange">{{ snack.nf_total_fat.recommended }} g</span></span>
                  </div>
                  <div class="col p-1">
                    <span>Fiber: <span class="mpt-orange">{{ snack.nf_dietary_fiber.recommended }} g</span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="mealplan-div-bg mealplan-breakfast-div order-1">
      <div class="col-md-12 p-3">
        <div class="row">
          <div class="col-md-1">
            <div class="p-2 text-center">Total</div>
          </div>
          <div class="col-md-11">
            <div class="d-flex">
              <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                <div style="display: grid"><span>Calories</span> <span
                    class=" mp-protine-value-font">{{ day.snacks.overall_calories_recommended }} kcal</span></div>
              </div>
              <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                <div style="display: grid"><span>Protein</span> <span
                    class="mpt-orange mp-protine-value-font">{{ day.snacks.overall_protein_recommended }} g</span>
                </div>
              </div>

              <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                <div style="display: grid"><span>Carbs</span> <span
                    class="mpt-orange mp-protine-value-font">{{ day.snacks.overall_carboHydrate_recommended }}
                    g</span></div>
              </div>
              <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                <div style="display: grid"><span>Fat</span> <span
                    class="mpt-orange mp-protine-value-font">{{ day.snacks.overall_fat_recommended }} g</span></div>
              </div>
              <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                <div style="display: grid"><span>Fiber</span> <span
                    class="mpt-orange mp-protine-value-font">{{ day.snacks.overall_fibre_recommended }} g</span>
                </div>
              </div>
            </div>
          </div>



          <!-- <div class="col-md-1 text-right mt-1 text-center">
                      <img src="assets/images/info-graph.png" width="30" height="auto">
                  </div> -->
        </div>
      </div>
    </div>
    <div class="mealplan-div-bg mealplan-breakfast-div mb-4 mt-4 p-3">
      <div class="row">
        <div class="col breakfast-header pb-2 p-0">
          <div class="d-flex">
            <div class="col-md-8  mpt-orange meal-plan-main-headings font-weight-bold "> Dinner</div>
            <div class="col-md-4 text-right" (click)="onSearch('dinner')" *ngIf="isDataEditable">
              <i class="fa fa-plus text-success" aria-hidden="true "></i></div>
          </div>
        </div>
        <div class="meal-plan-breakfast-details col-md-12">
          <div class="row" style="border-bottom: 1px solid rgba(255, 255, 255, 0.11);
                margin-bottom: 6px;" *ngFor="let dinner of day.dinner.foodItems">
            <div class="meal-plan-item-image col-sm-6 col-md-2 text-center">
              <img class="" src="{{ dinner.photo.thumb }}" style="cursor: pointer;" width="50" alt="alternate text" />
            </div>
            <div class="meal-plan-details mp-detail-text-header-font col-sm-6 col-md-10">

              <div class="col-md-12 p-1">
                <div class="d-flex">
                  <div class="col-md-6 mpt-orange font-weight-bold" style="font-size:15px">{{ dinner.food_name }}
                  </div>

                  <div class="col-md-6 text-right">
                    <i class="fa fa-pencil-square-o mx-1" *ngIf="isDataEditable" (click)="editMeal('dinner', dinner.refId)"></i>
                    <i class="fa fa-trash text-danger mx-1" aria-hidden="true" *ngIf="isDataEditable" (click)="deleteMeal('dinner', dinner.refId)"></i></div>
                </div>
              </div>
             

              <div class="col-md-12 p-1">
                <div class="d-flex">
                  <div class="col-md-4">
                    <span class="font-weight-bold"> Engery: </span>
                    <span>{{ dinner.nf_calories.recommended }} </span>
                    <span>kcal</span>
                  </div>
                  <div class="col-md-4">
                    <span class="font-weight-bold"> Recommended Qty: </span>
                    <span><i class="fa fa-minus text-primary ml-1" style="font-size: 0.8rem;" aria-hidden="true "
                      (click)="CalculateNutrients('dinner', dinner,'substraction')"></i></span>
                    <span>{{ dinner.serving_qty.recommended }}</span>
                    <span><i class=" fa fa-plus text-primary mr-1" style="font-size: 0.8rem;" aria-hidden="true "
                      (click)="CalculateNutrients('dinner', dinner,'addition')"></i></span>
                    
                  </div>
                  <div class="col-md-4 d-flex">
                    <span class="text-primary"><img
                        src="https://img.icons8.com/officexs/16/000000/time-machine--v1.png" /></span>
                    <input atp-time-picker type="time" class="col" id="time" name="time"
                      style="margin-top: 2px; font-size:10px;" [(ngModel)]="dinner.time" required></div>

                </div>
              </div>

              <div class="col-md-12 p-1 px-3">
                <div class="d-flex">
                  <div class="col p-1">
                    <span>Protein: <span class="mpt-orange">{{ dinner.nf_protein.recommended }} g</span></span>
                  </div>

                  <div class="col p-1">
                    <span>Carbs: <span class="mpt-orange">{{ dinner.nf_total_carbohydrate.recommended }} g</span></span>
                  </div>
                  <div class="col p-1">
                    <span>Fat: <span class="mpt-orange">{{ dinner.nf_total_fat.recommended }} g</span></span>
                  </div>
                  <div class="col p-1">
                    <span>Fiber: <span class="mpt-orange">{{ dinner.nf_dietary_fiber.recommended }} g</span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="mealplan-div-bg mealplan-breakfast-div order-1">
      <div class="col-md-12 p-3">
        <div class="row">
          <div class="col-md-1">
            <div class="p-2 text-center">Total</div>
          </div>
          <div class="col-md-11">
            <div class="d-flex">
              <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                <div style="display: grid"><span>Calories</span> <span
                    class=" mp-protine-value-font">{{ day.dinner.overall_calories_recommended }} kcal</span></div>
              </div>
              <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                <div style="display: grid"><span>Protein</span> <span
                    class="mpt-orange mp-protine-value-font">{{ day.dinner.overall_protein_recommended }} g</span>
                </div>
              </div>

              <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                <div style="display: grid"><span>Carbs</span> <span
                    class="mpt-orange mp-protine-value-font">{{ day.dinner.overall_carboHydrate_recommended }}
                    g</span></div>
              </div>
              <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                <div style="display: grid"><span>Fat</span> <span
                    class="mpt-orange mp-protine-value-font">{{ day.dinner.overall_fat_recommended }} g</span></div>
              </div>
              <div class="col meal-plan-title-div-sm-text" style="text-align: center">
                <div style="display: grid"><span>Fiber</span> <span
                    class="mpt-orange mp-protine-value-font">{{ day.dinner.overall_fibre_recommended }} g</span>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
  <div class="col-md-4 mealplan-right-div order-first order-md-2">
    <div class="mealplan-div-bg mt-3 mealplan-infographic-div p-2">
      <div class="row">
        <div class="col-md-12 text-center"><b class="mpt-orange">Total Calories: </b> {{day.overall_calories_recommended}} kcal</div>
        <div class="col-md-7 meal-plan-title-div-sm-text text-center">
          <div class="col mp-bg-dark mp-div-rounded "> Protein: {{day.overall_protein_recommended}} g
          </div>
          <div class="col mp-bg-dark mp-div-rounded mt-2"> Carbs: {{day.overall_carboHydrate_recommended}} g
          </div>
          <div class="col mp-bg-dark mp-div-rounded mt-2"> Fat: {{day.overall_fat_recommended}} g
          </div>
        </div>
        <div class="col-md-5 text-center">
          <div>
            <div>
              <div class="chart">
                <canvas baseChart [data]="pieChartData" [labels]="pieChartLabels" [chartType]="pieChartType"
                  [options]="pieChartOptions" [plugins]="pieChartPlugins" [colors]="pieChartColors"
                  [legend]="pieChartLegend">
                </canvas>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
<ng-container *ngIf="showCalendarPopup">

  <div class="video-popup__wrapper p-0 ">
    <div class="video-pop zoomIn fit-searchpopup-insan">
      
      <a (click)="closeSearchPopup()" class="close-program_event-pop"><i class="fas fa-times"></i></a>
      <div class="program_event-body d-flex justify-content-center">

        <div class="container-fluid">
          <div class="row">
            <div class="col-12" *ngIf="showNutrionSearch">
              <div class="row">

                <div class="input-group-prependd col-md-12">
                  <div class="row">
                        <div class="input-group-text col-auto" id="searchMovieTitle">
                          <b>Search by food: </b>
                        </div>
                        <div class="col-md-8 col-7">
                          <input type="text" class="form-control" placeholder="Food name" aria-describedby="searchMovieTitle"
                          [formControl]="movieTitle" style="border-bottom: 1px solid white;" />
                        </div>
                   </div>
                </div>

              </div>
              <div class="col-12 py-3 px-0">
                <tabset type="pills" class="search-tabset ">
                  <tab heading="Common">
                    <div *ngIf="movieTitle.value">
                      <span *ngIf="moviesLoading">Loading</span>
                      <div class="dropdown-item" *ngFor="let nutrition of (nutritions | async)?.common" style="cursor: pointer">
                        <span (click)="getNutrientData(nutrition, false)"
                          style="cursor: pointer; color: white;">{{ nutrition.food_name }}</span><br/>
                      </div>


                    </div>



                  </tab>
                  <tab heading="Branded">
                    <div *ngIf="movieTitle.value">
                      <span *ngIf="moviesLoading">Loading</span>
                      <div class="dropdown-item" *ngFor="let nutrition of (nutritions | async)?.branded" style="cursor: pointer">
                        <span (click)="getNutrientData(nutrition, true)"
                          style="cursor: pointer; color: white;">{{ nutrition.food_name }}</span><br/>
                      </div>
                    </div>

                  </tab>
                </tabset>
              </div>
            </div>

            <div class="col-12" *ngIf="showNutrionData">
              <div class="add_program_duration">
                <div class="col-md-12 py-2">
                    <div class="d-flex">
                      <div class="col-md-6 pl-0">
                        <span class="mpt-orange"> Meal type :</span> <span> {{ currentMealType }}</span>
                      </div>
                      <div class="col-md-6">
                        <span class="mpt-orange"> Food name :</span> <span> {{ meal_object.food_name }} </span>
                      </div>
                    </div>
                </div>
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-6 py-2 pr-0 d-flex" *ngIf="meal_object.serving_weight_grams != null">
                      <span class="mpt-orange">Serving size :</span>
                      <span><input type="number" readonly OnlyPositiveNumbers numericType="number"
                        [(ngModel)]="meal_object.serving_weight_grams"
                        style="color:white;width: 50px; background: rgba(255, 255, 255, 0); border-bottom: 1px solid rgb(255, 255, 255); "
                         /></span><span>grms</span>
                    </div>
                    <div class="col-md-6 py-2 pr-0 d-flex">
                      <span class="mpt-orange">Serving quantity :</span>
                      <span><input type="number" OnlyPositiveNumbers numericType="number"
                        [(ngModel)]="meal_object.serving_qty"  (ngModelChange)="onItemSelect('number')"
                        style="color:white; width: 50px; background: rgba(255, 255, 255, 0); border-bottom: 1px solid rgb(255, 255, 255); "
                         /> </span>
                    </div>

                  </div>
                </div>
                <div class="col-md-12 d-flex py-3">
                  <span class="mpt-orange"> Serving units :</span>
                  <span class="mt-2 col-md-4">
                   
                    <ng-multiselect-dropdown  name="city"
                    [placeholder]="'Select'"
                    [settings]="dropdownSettings"  [disabled]="disabled"
                    [data]="types" 
                    [(ngModel)]="selectedUnits"
                    (onSelect)="onItemSelect('dropdown')" (onDeSelect)="onItemDeselect()"
                  >
                  </ng-multiselect-dropdown>
                  </span>
                  </div>

                <!-- <div class="col-md-12 p-0" style="margin: 0px;">
                  <div class="row">
                    <div class="col-md-6" style="padding: 10px;">
                      <div class="row">

                        <div class="col-md-6 p-0">

                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4 p-0 text-right mr-1"> Quantity :</div>
                        <div class="col-md-6 p-0">
                          <input type="number" OnlyPositiveNumbers numericType="number"
                            [(ngModel)]="meal_object.serving_qty"  (ngModelChange)="onItemSelect('number')"
                            style="margin-right: 20px; margin-right: 20px; background: rgba(255, 255, 255, 0); border-bottom: 1px solid rgb(255, 255, 255); "
                            class="col-md-5" />
                        </div>
                      </div>
                    </div>

                  </div>
                </div> -->


                <div class="d-flex col-12 mt-4 justify-content-end">
                  <button *ngIf="isMealEdit == false" type="button" class="btn btn-circle prevBtn" style="position: absolute; left: 10px;"
                    (click)="onBack()">
                    <i class="fas fa-angle-left"></i>
                  </button>
                  <button *ngIf="isMealEdit == false" type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
                  (click)="saveMeal('save')">
                  Add
                </button>
                <button  *ngIf="isMealEdit" type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
                (click)="updateMeal()">
                Update
              </button>
                </div>
              </div>
            </div>
            <!-- <pagination [totalItems]="nutritions.length" [itemsPerPage]="5"></pagination> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
</div>
</section>
