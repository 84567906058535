import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { UserLoginService } from "../service/user-login.service";
import { LoggedInCallback, CognitoUtil } from "../service/cognito.service";
import { DBService } from '../service/db.service';
import { AWSService } from '../service/aws.service';
import { ToastrService } from 'ngx-toastr';
import { Integer } from 'aws-sdk/clients/athena';
import { YogaDBService } from '../service/YogaDBService';

export class trainee {
  traineeId: string;
  first_name: string;
  last_name: string;
  mobile_no: string;
  gender: string;
  age: Integer;
  currrent_weight: Object;
  trainee_height: Object;
  bmi_bmr: Object;
}

@Component({
  selector: 'app-trainee-details',
  templateUrl: './trainee-details.component.html',
  styleUrls: ['./trainee-details.component.css']
})
export class TraineeDetailsComponent implements OnInit, OnDestroy, LoggedInCallback {

  currentCategory: string;
  currentGender: string;
  private sub: any;

  currentLogged: string;
  currentEmail: string;
  private loggedIn: boolean;

  // Vars
    userID: string;
    trainee_id: string;
    program_id: string;
    alltrainees: any;
    Trainees: any;
    trainee: any;

    traineeImage: string
    traineeDiet: string;

    constructor(private renderer: Renderer2, public router: Router, public route: ActivatedRoute, public userService: UserLoginService
        , private db_service: DBService, private awsService: AWSService, private yoga_db_service: YogaDBService, private toastr: ToastrService, public cognitoUtil: CognitoUtil) {
        this.userService.isAuthenticated(this);
    }

  ngOnInit() {
     //   this.trainee = new trainee();
     this.sub = this.route.params.subscribe(params => {
     this.trainee_id = params['traineeId'];
     this.program_id = params['programID'];
  });
 
        this.currentCategory = localStorage.getItem("ProfileCategory")
        this.currentGender = localStorage.getItem("ProfileGender");
        this.getTraineeData();
        if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'male') {
            this.renderer.removeClass(document.body, 'body-main');
            this.renderer.addClass(document.body, 'bg-img-build-male');
        }
        else if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'female') {
            this.renderer.removeClass(document.body, 'body-main');
            this.renderer.addClass(document.body, 'bg-img-build-female');
        }
        else if (this.currentCategory == 'Yoga' && this.currentGender == 'male') {
            this.renderer.removeClass(document.body, 'body-main');
            this.renderer.addClass(document.body, 'bg-img-yoga-male');
        }
        else if (this.currentCategory == 'Yoga' && this.currentGender == 'female') {
            this.renderer.removeClass(document.body, 'body-main');
            this.renderer.addClass(document.body, 'bg-img-yoga-female');
        }
        else if (this.currentCategory == 'Zumba' && this.currentGender == 'male') {
            this.renderer.removeClass(document.body, 'body-main');
            this.renderer.addClass(document.body, 'bg-img-zumba-male');
        }
        else if (this.currentCategory == 'Zumba' && this.currentGender == 'female') {
            this.renderer.removeClass(document.body, 'body-main');
            this.renderer.addClass(document.body, 'bg-img-zumba-female');
        }
        this.userService.currentUserID.subscribe((val) => {
            if (val != '') {
                this.userID = val;
            }
        });
  }
  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'bg-img-build-male');
    this.renderer.removeClass(document.body, 'bg-img-yoga-male');
    this.renderer.removeClass(document.body, 'bg-img-zumba-male');
    this.renderer.removeClass(document.body, 'bg-img-build-female');
    this.renderer.removeClass(document.body, 'bg-img-yoga-female');
    this.renderer.removeClass(document.body, 'bg-img-zumba-female');
    this.renderer.addClass(document.body, 'body-main');
}
getTraineeData() {
  if(this.currentCategory == "Bodybuilder"){
  var index = '/program/trainee/';
  var queryparams = {
    "program_id":this.program_id,
    "trainee_id":this.trainee_id
  }
  this.db_service.getQueriedData("", index, queryparams).subscribe(
      response => {
          // console.log('Data retrived succesfully.', response);
          // this.program_id = response.data[0]["program_id"];
          this.trainee = response.data;
          if (this.trainee.traineeProfileImg != null)
              this.traineeImage = this.trainee.traineeProfileImg.profileImagePath;
          this.traineeDiet = this.trainee.food_preference.food_type[0];
      },
      error => {
          // alert("An error has occurred while retriving profile data.");
          this.toastr.error('', 'An error has occurred while retriving trainee profile data', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
              progressBar: true,
              progressAnimation: 'increasing',
              tapToDismiss: true,
          });
      }
  )
    }else{
      
  var index = '/program/trainee/';
  var queryparams = {
    "program_id":this.program_id,
    "trainee_id":this.trainee_id
  }
  this.yoga_db_service.getQueriedData("", index, queryparams).subscribe(
      response => {
          // console.log('Data retrived succesfully.', response);
          // this.program_id = response.data[0]["program_id"];
          this.trainee = response.data;
          if (this.trainee.traineeProfileImg != null)
              this.traineeImage = this.trainee.traineeProfileImg.profileImagePath;
          this.traineeDiet = this.trainee.food_preference.food_type[0];
      },
      error => {
          // alert("An error has occurred while retriving profile data.");
          this.toastr.error('', 'An error has occurred while retriving trainee profile data', {
              timeOut: 3000,
              positionClass: 'toast-top-right',
              progressBar: true,
              progressAnimation: 'increasing',
              tapToDismiss: true,
          });
      }
  )
    
    }
  }
  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/login']);
    } else {
      // if()
      if (this.loggedIn == true) {
        this.currentEmail = localStorage.getItem('lastAuthUser');
        // if(this.currentEmail != undefined)
        this.getProfileDataFromServer();
      }
      else {
        // this.currentEmail = localStorage.getItem('CognitoIdentityServiceProvider.2io8v4r253hugt5ndbjv9shkfp.LastAuthUser');
        this.currentEmail = this.cognitoUtil.getCurrentUser().getUsername();
        if(this.currentEmail == undefined || this.currentEmail == '' || this.currentEmail == null || this.currentEmail.includes("Google") || this.currentEmail.includes("google"))
        {
          this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
          if(this.currentEmail == undefined)
          this.currentEmail = this.cognitoUtil.session.idToken.payload.email;
        }
        // this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
        this.getProfileDataFromServer();
      }
    }
}
getProfileDataFromServer() {
    var index = '/profile/';
    this.db_service.getDataFromServer(this.currentEmail, index).subscribe(
        response => {
            response = response.data[0];
            this.userID = response.trainerId;
        },
        error => {
            console.log("An error has occurred while retriving profile data.");
        }
    )
}

}
