import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppGlobals } from "../app.globals";
//import 'rxjs/add/observable/of'; 
// import { Observable } from "rxjs/Observable";

@Injectable()
export class YogaDBService {
  constructor(private HttpClient: HttpClient, private globals: AppGlobals) { }
  // headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');

  saveDataToDB(data, index): Observable<any> {
    return this.HttpClient.post(this.globals.YOGA_SERVER_LINK + index, data);
  }
  updateDatainDB(data, index): Observable<any> {
    return this.HttpClient.put(this.globals.YOGA_SERVER_LINK + index, data);
  }
  getData(data, index): Observable<any> {
    return this.HttpClient.post(this.globals.YOGA_SERVER_LINK + index, data);
  }
  getToken(id, index): Observable<any> {
    return this.HttpClient.get(this.globals.YOGA_SERVER_LINK + index + id, { responseType: 'text' });
  }
  getDataFromServer(id, index): Observable<any> {
    return this.HttpClient.get(this.globals.YOGA_SERVER_LINK + index + id);
  }
  deleteRecordFromDB(id, index): Observable<any> {
    return this.HttpClient.post(this.globals.YOGA_SERVER_LINK + index, id);
  }
  getNutritionDataFromServer(id, index): Observable<any> {
    return this.HttpClient.get(this.globals.NUTRITION_SERVER_LINK + index + id);
  }
  saveNutritionDataToDB(data, index): Observable<any> {
    return this.HttpClient.post(this.globals.NUTRITION_SERVER_LINK + index, data);
  }
  deleteNutritionRecordFromDB(id, index): Observable<any> {
    return this.HttpClient.post(this.globals.NUTRITION_SERVER_LINK + index, id);
  }
  deleteRecordInDB(id, index): Observable<any> {
    return this.HttpClient.delete(this.globals.YOGA_SERVER_LINK + index+id);
  }
  getQueriedData(id,index,queryParams):Observable<any>{
    return this.HttpClient.get(this.globals.YOGA_SERVER_LINK + index + id,{params:queryParams});
  }
  sendEmail(emailModel):Observable<any>{
    return this.HttpClient.post(this.globals.NOTIFICATION_SERVER_LINK+"/email",emailModel);
  }
  savenotification(notificationModel):Observable<any>{
    return this.HttpClient.post(this.globals.NOTIFICATION_SERVER_LINK+"/notification",notificationModel);
  }
  getNotifications(userId):Observable<any>{
      return this.HttpClient.get(this.globals.NOTIFICATION_SERVER_LINK+"/notification/"+userId);
  }
  getNewNotifications(userId):Observable<any>{
    return this.HttpClient.get(this.globals.NOTIFICATION_SERVER_LINK+"/notification/size/"+userId);
  }
  updateNotification(notificationModel):Observable<any>{
    return this.HttpClient.put(this.globals.NOTIFICATION_SERVER_LINK+"/notification",notificationModel);
  }
  sendPushNotification(data,index):Observable<any>{
    return this.HttpClient.post(this.globals.NOTIFICATION_SERVER_LINK+ index,data);
  }
  generateReports(trainerId,queryParams):Observable<any>{
    return this.HttpClient.get(this.globals.YOGA_SERVER_LINK+"/reports/"+trainerId,{params:queryParams});
  }
}
