<section>
  <div class="container">
    <div class="row">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <h3 class="trainee-list-page-main-heading1">Trainee Management</h3>
          </div>
          <div class="col-md-6 mb-2">
            <button type="button" class="btn btn-default save-exercise-btn" style="float: right;" (click)="onBack()"
              >BACK</button>
          </div>
        </div>
      </div>

      <!-- <div class="container trainee-list-page-program-div">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6 col-lg-2">
              <img src="{{programImagePath}}" style="width: 265px;border-radius: 5%;border: 4px solid black;">
            </div>
            <div class="container col-lg-5 flex-column" id="testabsolute" style="display: inline-flex; ">
              <p><span><b> Program Name: &nbsp; </b></span> <span> {{program.programName}}</span> </p>
              <p><span><b> Nutrition: &nbsp; </b></span> <span> {{nutrition}}</span> </p>
              <p><span><b> Program Duration: &nbsp; </b></span> <span> {{program.programDuration}}
                  Weeks</span>
              </p>
              <p><span><b> Total Trainees: &nbsp; </b></span> <span> {{program.noOfSubscriptions}}</span> </p>
            </div>
          </div>
        </div>
      </div> -->
      <div class="col-md-10 mx-auto">
        <div class="col-md-6 mx-auto" style="border: 1px solid yellowgreen;
        border-radius: 10px;
        padding: 8px;">
          <div class="row">
            <div class="col-12 col-md-5 text-center align-self-center">
              <img src="{{programImagePath}}"
                style="width:100%; border-radius: 5%;border: 4px solid black;height: 128px;">
            </div>
            <div class="col-md-7 flex-column program-details-div" id="testabsolute" style="display: inline-flex; ">
              <p><span><b>Program Name:</b> &nbsp;</span> <span> {{program.programName}}</span> </p>
              <p><span><b>Nutrition:</b> &nbsp; </span> <span> {{nutrition}}</span> </p>
              <p><span><b>Program Duration:</b> &nbsp;</span> <span> {{program.programDuration}}
                  Weeks</span>
              </p>
              <p><span><b>Total Trainees:</b> &nbsp;</span> <span> {{program.noOfSubscriptions}}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="col-md-12 col-lg-12" style="margin-top: 10px;">
          <div class="numbers box">
            <div class="row noresponsive auto">

              <a class="col number trainee-list-page-clientscount" (click)="onActiveClients()" style="cursor:pointer;">
                <span class="figure trainee-list-page-clientscount-numb">{{ activeTraineesLength }}</span>
                <span class="heading trainee-list-page-clientscount-text"  style="color:yellowgreen; font-weight:600">Active
                  Clients</span>
              </a>

              <a class="col number trainee-list-page-clientscount" (click)="onPastClients()" style="cursor:pointer;">
                <span class="figure trainee-list-page-clientscount-numb">{{pastTraineesLength}}</span>
                <span class="heading trainee-list-page-clientscount-text"  style="color:yellowgreen; font-weight:600">Inactive Clients</span>
              </a>
            </div>
          </div>
        </div>

      </div>


      <br>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="offset-3 d-flex justify-content-end">
              <div class="search__box search__box-exercise search__box-exercise-closed d-flex" style="cursor: pointer;">
                <input type="text" name="search" placeholder="Search by name" [(ngModel)]="searchTrainee">
                <a class="search__box__btn search__box__btn-exercise-open"><i class="fas fa-search"></i></a>
                <!-- <a data-toggle="modal" data-target="#exerciseSerachFilters" class="search-filters">
                   <i class="fas fa-filter"></i>
                </a> -->
              </div>
              <!-- <a routerLink="/addexercise" class="add__exercise__btn"><i class="fa fa-plus"></i></a> -->
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="container">
              <img src="assets/images/save.png" class="float-right" style=" width: 50px;height: 50px;">
          </div> -->
      <div class="fit-overflow col-md-12">
        <table class="table">
          <thead>
            <tr style="font-size: bold;">
              <!-- <th scope="col"><input type="checkbox"> </th> -->
              <th scope="col">Name</th>
              <th scope="col">User name</th>
              <th scope="col">Age</th>
              <th scope="col">Gender</th>
              <th scope="col">Country</th>
              <th scope="col">Subscription date</th>
              <!-- <th scope="col">Status</th> -->
              <th scope="col">View Profile</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let trainee of trainees | filter:searchTrainee| paginate: { itemsPerPage: 5, currentPage: page }">
              <!-- <td scope="col"><input type="checkbox"></td> -->
              <td scope="col"><span>{{trainee.first_name}} {{trainee.last_name}}</span></td>
              <td scope="col">{{trainee.username}}</td>
              <td scope="col">{{trainee.age}}</td>
              <td scope="col">{{trainee.gender}}</td>
              <td scope="col">{{trainee.trainee_address[0].country}}</td>
              <td scope="col">{{ trainee.program_startdate | date }}</td>
              <!-- <td scope="col"> {{trainee.isProgramActive}}</td> -->
              <td scope="col"><i class="fa fa-eye mealplan-png-cursor" style="font-size: 18px; color:yellowgreen;" (click)="onClickOnView(trainee.trainee_id)"
                  aria-hidden="true"></i></td>

            </tr>
          </tbody>
        </table>
      </div>

      <div class="col-md-12 my-3">
        <pagination-controls class="my-pagination" (pageChange)="page = $event"></pagination-controls>
      </div>

    </div>
  </div>

</section>
