import { Component, OnInit, Input, OnDestroy, Renderer2, HostListener, ViewChild, ElementRef, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { DBService } from '../../service/db.service';
import { Router, ActivatedRoute } from "@angular/router";
import { Integer } from 'aws-sdk/clients/comprehend';
import { ToastrService } from 'ngx-toastr';
import { UserLoginService } from "../../service/user-login.service";
import { LoggedInCallback, CognitoUtil } from "../../service/cognito.service";
import { AppGlobals } from "../../app.globals";
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Label, MultiDataSet } from 'ng2-charts';
import { DataService } from '../../service/data.service';
import { DatePipe } from '@angular/common'

import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from 'date-fns';
import moment from 'moment';

//  SEARCH Dependencys
import { Nutrition } from '../../common/nutrition';
import { NutritionService } from '../../service/nutritionservice';
import { NutritionixService } from '../../service/nutrionixservice';
import { Observable } from 'rxjs';
import { FormControl } from "@angular/forms";
import { map, tap, debounceTime, distinctUntilChanged, switchMap, flatMap } from 'rxjs/operators';
import { IfStmt, preserveWhitespacesDefault } from '@angular/compiler';
// import { NULL_INJECTOR } from '@angular/core/src/render3/component';
import {IMyDpOptions} from 'mydatepicker';
import { YogaDBService } from 'src/app/service/YogaDBService';
export class mealplan {
  trainer_id: string;
  creationDate: Date;
  trainee_id: string;
  mealplan: any;
  program_id: string
  date: string;
}
export class trainee {
  traineeId: string;
  first_name: string;
  last_name: string;
  mobile_no: string;
  gender: string;
  age: Integer;
  currrent_weight: Object;
  trainee_height: Object;
  bmi_bmr: Object;
}
export class IMyDateModel {
  date: IMyDate;
  jsdate: Date;
  formatted: string;
  epoc: number;
}

export class IMyDate {
  year: number;
  month: number;
  day: number;
}

@Component({
  selector: 'app-mealplan-generation',
  templateUrl: './mealplan-generation.component.html',
  styleUrls: ['./mealplan-generation.component.css']
})
export class MealplanGenerationComponent implements OnInit, OnDestroy, LoggedInCallback {


  monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  labelNutrients: Object[];
  currentMealType: string;
  currentFoodRefId:number=0;
  mealplan: mealplan;
  currentCategory: string;
  currentGender: string;
  private sub: any;
  currentDate: IMyDateModel;
  trainee_id: string = "";
  program_id: string = "";
  selectedUnits: Array<any> = [];

  traineeImage: string;
  traineeDiet: string;
  isBranded: boolean = false;
  isDataEditable: boolean = true;
  actualQuantity = 0;
  current_meal:object={};
  meal = {
    "overall_protein_recommended": 0,
    "overall_carboHydrate_recommended": 0,
    "overall_fibre_recommended": 0,
    "overall_fat_recommended": 0,
    "overall_calories_recommended": 0,
    "overall_protein_consumed": 0,
    "overall_carboHydrate_consumed": 0,
    "overall_fibre_consumed": 0,
    "overall_fat_consumed": 0,
    "overall_calories_consumed": 0,
    "foodItems": [],
    "macros": {}
  }

  day = {
    mealName: "",
    "overall_protein_recommended": 0,
    "overall_carboHydrate_recommended": 0,
    "overall_fibre_recommended": 0,
    "overall_fat_recommended": 0,
    "overall_calories_recommended": 0,
    "overall_protein_consumed": 0,
    "overall_carboHydrate_consumed": 0,
    "overall_fibre_consumed": 0,
    "overall_fat_consumed": 0,
    "overall_calories_consumed": 0,

    "breakfast": {
      "foodItems": [],
      "macros": {
        "protein_consumed": 0,
        "carboHydrate_consumed": 0,
        "fat_consumed": 0
      },
      "overall_protein_recommended": 0,
      "overall_carboHydrate_recommended": 0,
      "overall_fibre_recommended": 0,
      "overall_fat_recommended": 0,
      "overall_calories_recommended": 0,
      "overall_protein_consumed": 0,
      "overall_carboHydrate_consumed": 0,
      "overall_fibre_consumed": 0,
      "overall_fat_consumed": 0,
      "overall_calories_consumed": 0
    },
    "lunch": {
      "foodItems": [],
      "macros": {
        "protein_consumed": 0,
        "carboHydrate_consumed": 0,
        "fat_consumed": 0
      },
      "overall_protein_recommended": 0,
      "overall_carboHydrate_recommended": 0,
      "overall_fibre_recommended": 0,
      "overall_fat_recommended": 0,
      "overall_calories_recommended": 0,
      "overall_protein_consumed": 0,
      "overall_carboHydrate_consumed": 0,
      "overall_fibre_consumed": 0,
      "overall_fat_consumed": 0,
      "overall_calories_consumed": 0
    },
    "snacks": {
      "foodItems": [],
      "macros": {
        "protein_consumed": 0,
        "carboHydrate_consumed": 0,
        "fat_consumed": 0
      },
      "overall_protein_recommended": 0,
      "overall_carboHydrate_recommended": 0,
      "overall_fibre_recommended": 0,
      "overall_fat_recommended": 0,
      "overall_calories_recommended": 0,
      "overall_protein_consumed": 0,
      "overall_carboHydrate_consumed": 0,
      "overall_fibre_consumed": 0,
      "overall_fat_consumed": 0,
      "overall_calories_consumed": 0
    },
    "dinner": {
      "foodItems": [],
      "macros": {
        "protein_consumed": 0,
        "carboHydrate_consumed": 0,
        "fat_consumed": 0
      },
      "overall_protein_recommended": 0,
      "overall_carboHydrate_recommended": 0,
      "overall_fibre_recommended": 0,
      "overall_fat_recommended": 0,
      "overall_calories_recommended": 0,
      "overall_protein_consumed": 0,
      "overall_carboHydrate_consumed": 0,
      "overall_fibre_consumed": 0,
      "overall_fat_consumed": 0,
      "overall_calories_consumed": 0
    },
    "mealPlanStatus": "new",
    "macros": {}
  }
  final_meal_object = {
    "food_name": "",
    "brand_name": "",
    "serving_qty": {
      "recommended": 0,
      "consumed": 0
    },
    "serving_unit": "",
    "serving_weight_grams": {
      "recommended": 0,
      "consumed": 0
    },
    "nf_calories": {
      "recommended": 0,
      "consumed": 0
    },
    "nf_total_fat": {
      "recommended": 0,
      "consumed": 0
    },
    "nf_saturated_fat": 0,
    "nf_cholesterol": 0,
    "nf_sodium": 0,
    "nf_total_carbohydrate": {
      "recommended": 0,
      "consumed": 0
    },
    "nf_dietary_fiber": {
      "recommended": 0,
      "consumed": 0
    },
    "nf_sugars": 0,
    "nf_protein": {
      "recommended": 0,
      "consumed": 0
    },
    "nf_potassium": 0,
    "nf_p": 0,
    "nix_brand_name": "",
    "nix_brand_id": "",
    "nix_item_name": "",
    "nix_item_id": "",
    "upc": "",
    "consumed_at": new Date(),
    "metadata": {},
    "source": 0,
    "ndb_no": 0,
    "tags": {},
    "alt_measures": [],
    "lat": "",
    "lng": "",
    "meal_type": 0,
    "photo": {},
    "sub_recipe": "",
    "macros": {},
    "dailyValues": {},
    "foodStatus": "",
    "createdBy": "",
    "refId": 0,
    "time": ""
  }

  meal_object = {
    "food_name": "",
    "brand_name": "",
    "serving_qty": 0,
    "serving_unit": "",
    "serving_weight_grams": 0,
    "nf_calories": 0,
    "nf_total_fat": 0,
    "nf_saturated_fat": 0,
    "nf_cholesterol": 0,
    "nf_sodium": 0,
    "nf_total_carbohydrate": 0,
    "nf_dietary_fiber": 0,
    "nf_sugars": 0,
    "nf_protein": 0,
    "nf_potassium": 0,
    "nf_p": 0,
    "full_nutrients": [],
    "nix_brand_name": "",
    "nix_brand_id": "",
    "nix_item_name": "",
    "nix_item_id": "",
    "upc": "",
    "consumed_at": new Date(),
    "metadata": {},
    "source": 0,
    "ndb_no": 0,
    "tags": {},
    "alt_measures": [],
    "lat": "",
    "lng": "",
    "meal_type": 0,
    "photo": {},
    "sub_recipe": ""
  }

  empTy = {
    "foodItems": [],
    "overall_protein_recommended": 0,
    "overall_carboHydrate_recommended": 0,
    "overall_fibre_recommended": 0,
    "overall_fat_recommended": 0,
    "overall_calories_recommended": 0,
    "overall_protein_consumed": 0,
    "overall_carboHydrate_consumed": 0,
    "overall_fibre_consumed": 0,
    "overall_fat_consumed": 0,
    "overall_calories_consumed": 0,
    "macros": {}
  }

  selectedFood: object
  googleUserEmail: string;
  private loggedIn: boolean;
  private subcrptn_1: any;
  currentLogged: string;
  currentEmail: string;
  isNutritionist: boolean = false;
  showVideoPopup: boolean = false;
  showCalendarPopup: boolean = false;
  showNutrionSearch: boolean = true;
  showNutrionData: boolean = false;
  isMealEdit: boolean = false;
  // Vars
  userID: string;

  // Search Variables
  showNutritionPopUp: boolean = false;
  description: string;
  Items: Object[];

  // dropdown selectionF
  disabled = false;
  ShowFilter = false;
  limitSelection = false;
  types: Array<any>;
  selectedItems: Array<any> = [];
  dropdownSettings: any = {};
  closeDropdownSelection = false;
  trainee: any;

  showMealGrid: boolean = false;
  movieTitle = new FormControl();
  nutritions: Observable<Nutrition | Observable<Nutrition>>;
  importNutritions: Nutrition[] = [];
  moviesLoading = false;
  moviesByGenLoading = false;
  scrollMovies: Nutrition[] = [];
  scMoviesPageEnd = 1;
  @ViewChild('movies') moviesRef: ElementRef;
  loading = false;
  allMoviesLoaded = false;
  // Charts variables
  totalCalories: number = 0;
  totalProtiens: number = 0;
  totalFat: number = 0;
  totalCarbs: number = 0;
  totalCalsium: number = 0;
  totalFibre: number = 0;
  imgPath: string = "assets/images/nutrition/Eggraw.jpg"
  previusUnitSelected: string = "grams";

  myDatePickerOptions: IMyDpOptions = {};

  constructor(private nutritionService: NutritionService, private renderer: Renderer2,
    private db_service: DBService, private nutritionixservice: NutritionixService, public router: Router, public route: ActivatedRoute, private toastr: ToastrService,
    private globals: AppGlobals, public cognitoUtil: CognitoUtil, private yoga_db_service: YogaDBService,
    public userService: UserLoginService, public dataService: DataService, public datepipe: DatePipe) {
    this.userService.isAuthenticated(this);
  }
  selectedDate: string = "";
  currentTraniee: string;
  timezone: string;
  dateFormat:string;

  ngOnInit() {
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    //  this.trainee = new trainee();
    this.userService.currentUserID.subscribe((val) => {
      if (val != '') {
        this.userID = val;
      }
    });
    this.dataService.currentDay.subscribe((value) => {
      if (value != '') {
        this.selectedDate = value;
      }
    }
    );
    this.mealplan = new mealplan();
    this.currentCategory = localStorage.getItem("ProfileCategory")
    this.currentGender = localStorage.getItem("ProfileGender");
    if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-nutrition');
    }
    else if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-nutrition');
    }
    else if (this.currentCategory == 'Yoga' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-nutrition');
    }
    else if (this.currentCategory == 'Yoga' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-nutrition');
    }
    else if (this.currentCategory == 'Zumba' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-nutrition');
    }
    else if (this.currentCategory == 'Zumba' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-nutrition');
    }
    if (this.selectedDate != "") {
      var s_date = this.selectedDate.split('/');
      var date = new Date(s_date[2] + "-" + s_date[1] + "-" + s_date[0]);
      this.currentDate = this.getConvertedDate(date);
    }
    // else{
    //   this.currentDate = this.datepipe.transform(new Date(), 'MM-dd-yyyy');
    // }
    this.dropdownSettings = {
      singleSelection: true,
      enableCheckAll: false,
      idField: 'id',
      textField: 'measure',
      allowSearchFilter: true,
      itemsShowLimit: 1,
      closeDropDownOnSelection: this.closeDropdownSelection
    };
    // this.workout.userId = "naresh_1e25dff44asds4654sdsd4";

    this.sub = this.route.params.subscribe(params => {
      this.trainee_id = params['traineeID'];
      this.program_id = params['programID'];
    });
    // Search Category
    this.nutritions = this.movieTitle.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      tap(() => this.moviesLoading = true),
      switchMap(title => this.nutritionixservice.findMeal(title)),
      tap(() => this.moviesLoading = false));
    // this.currentDate = new Date();
    // this.onProfileView();
    this.getTraineedetails();

    if(this.timezone.includes("asia") || this.timezone.includes("Asia")){
      this.myDatePickerOptions = {
        todayBtnTxt: 'Today',
        dateFormat: 'dd-mm-yyyy',
        firstDayOfWeek: 'mo',
        sunHighlight: true,
        openSelectorOnInputClick: true,
        editableDateField: false,
        inline: false
      }
      this.dateFormat = "dd-MM-yyyy";
    }else{
      this.myDatePickerOptions = {
        todayBtnTxt: 'Today',
        dateFormat: 'mm-dd-yyyy',
        firstDayOfWeek: 'mo',
        sunHighlight: true,
        openSelectorOnInputClick: true,
        editableDateField: false,
        inline: false
      }
      this.dateFormat = "MM-dd-yyyy";
    }
  }
  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'bg-img-nutrition');
    this.renderer.addClass(document.body, 'body-main');
  }
  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/login']);
    } else {
      // if()
      if (this.loggedIn == true) {
        this.currentEmail = localStorage.getItem('lastAuthUser');
        // if(this.currentEmail != undefined)
        this.getProfileDataFromServer();
      }
      else {
        // this.currentEmail = localStorage.getItem('CognitoIdentityServiceProvider.2io8v4r253hugt5ndbjv9shkfp.LastAuthUser');
        this.currentEmail = this.cognitoUtil.getCurrentUser().getUsername();
        if(this.currentEmail == undefined || this.currentEmail == '' || this.currentEmail == null || this.currentEmail.includes("Google") || this.currentEmail.includes("google"))
        {
          this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
          if(this.currentEmail == undefined)
          this.currentEmail = this.cognitoUtil.session.idToken.payload.email;
        }
        // this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
        this.getProfileDataFromServer();
      }
    }
  }
  getProfileDataFromServer() {
    var index = '/profile/';
    if(this.currentCategory == "Bodybuilder"){
    this.db_service.getDataFromServer(this.currentEmail, index).subscribe(
      response => {
        console.log('Date Retrived succesfully.', response);
        this.userID = response.data[0].trainerId;
        this.mealplan.trainer_id = response.data[0].trainerId;
        var nutri = response.data[0]["isNutritionist"];
        if (nutri["status"] == "Yes")
          this.isNutritionist = true;
      },
      error => {
        console.log("An error has occurred while retriving profile data.");
      }
    )
    }else{
      this.yoga_db_service.getDataFromServer(this.currentEmail, index).subscribe(
        response => {
          console.log('Date Retrived succesfully.', response);
          this.userID = response.data[0].trainerId;
          this.mealplan.trainer_id = response.data[0].trainerId;
          var nutri = response.data[0]["isNutritionist"];
          if (nutri["status"] == "Yes")
            this.isNutritionist = true;
        },
        error => {
          console.log("An error has occurred while retriving profile data.");
        }
      )
    }

  }

  date: string;

  receiveMessage($event) {
    this.date = $event
    console.log("The date from event" + this.date);
  }

  deleteMeal(item_type, refId) {
    var index;
    var current_meal = {};
    if (item_type == "lunch") {
      current_meal = this.day.lunch["foodItems"].find(x => x.refId == refId);
      this.day.lunch["overall_protein_recommended"] = parseFloat((this.day.lunch["overall_protein_recommended"] - current_meal["nf_protein"]["recommended"]).toFixed(2));
      this.day.lunch["overall_carboHydrate_recommended"] = parseFloat((this.day.lunch["overall_carboHydrate_recommended"] - current_meal["nf_total_carbohydrate"]["recommended"]).toFixed(2));
      this.day.lunch["overall_fibre_recommended"] = parseFloat((this.day.lunch["overall_fibre_recommended"] - current_meal["nf_dietary_fiber"]["recommended"]).toFixed(2));
      this.day.lunch["overall_fat_recommended"] = parseFloat((this.day.lunch["overall_fat_recommended"] - current_meal["nf_total_fat"]["recommended"]).toFixed(2));
      this.day.lunch["overall_calories_recommended"] = parseFloat((this.day.lunch["overall_calories_recommended"] - current_meal["nf_calories"]["recommended"]).toFixed(2));
      index = this.day.lunch["foodItems"].findIndex(x => x.refId == refId);
      this.day.lunch["foodItems"].splice(index, 1);

    }
    if (item_type == "breakfast") {
      current_meal = this.day.breakfast["foodItems"].find(x => x.refId == refId);
      this.day.breakfast["overall_protein_recommended"] = parseFloat((this.day.breakfast["overall_protein_recommended"] - current_meal["nf_protein"]["recommended"]).toFixed(2));
      this.day.breakfast["overall_carboHydrate_recommended"] = parseFloat((this.day.breakfast["overall_carboHydrate_recommended"] - current_meal["nf_total_carbohydrate"]["recommended"]).toFixed(2));
      this.day.breakfast["overall_fibre_recommended"] = parseFloat((this.day.breakfast["overall_fibre_recommended"] - current_meal["nf_dietary_fiber"]["recommended"]).toFixed(2));
      this.day.breakfast["overall_fat_recommended"] = parseFloat((this.day.breakfast["overall_fat_recommended"] - current_meal["nf_total_fat"]["recommended"]).toFixed(2));
      this.day.breakfast["overall_calories_recommended"] = parseFloat((this.day.breakfast["overall_calories_recommended"] - current_meal["nf_calories"]["recommended"]).toFixed(2));

      index = this.day.breakfast["foodItems"].findIndex(x => x.refId == refId);
      this.day.breakfast["foodItems"].splice(index, 1);
    }
    if (item_type == "snacks") {
      current_meal = this.day.snacks["foodItems"].find(x => x.refId == refId);

      this.day.snacks["overall_protein_recommended"] = parseFloat((this.day.snacks["overall_protein_recommended"] - current_meal["nf_protein"]["recommended"]).toFixed(2));
      this.day.snacks["overall_carboHydrate_recommended"] = parseFloat((this.day.snacks["overall_carboHydrate_recommended"] - current_meal["nf_total_carbohydrate"]["recommended"]).toFixed(2));
      this.day.snacks["overall_fibre_recommended"] = parseFloat((this.day.snacks["overall_fibre_recommended"] - current_meal["nf_dietary_fiber"]["recommended"]).toFixed(2));
      this.day.snacks["overall_fat_recommended"] = parseFloat((this.day.snacks["overall_fat_recommended"] - current_meal["nf_total_fat"]["recommended"]).toFixed(2));
      this.day.snacks["overall_calories_recommended"] = parseFloat((this.day.snacks["overall_calories_recommended"] - current_meal["nf_calories"]["recommended"]).toFixed(2));

      index = this.day.snacks["foodItems"].findIndex(x => x.refId == refId);
      this.day.snacks["foodItems"].splice(index, 1);
    }
    if (item_type == "dinner") {
      current_meal = this.day.dinner["foodItems"].find(x => x.refId == refId);

      this.day.dinner["overall_protein_recommended"] = parseFloat((this.day.dinner["overall_protein_recommended"] - current_meal["nf_protein"]["recommended"]).toFixed(2));
      this.day.dinner["overall_carboHydrate_recommended"] = parseFloat((this.day.dinner["overall_carboHydrate_recommended"] - current_meal["nf_total_carbohydrate"]["recommended"]).toFixed(2));
      this.day.dinner["overall_fibre_recommended"] = parseFloat((this.day.dinner["overall_fibre_recommended"] - current_meal["nf_dietary_fiber"]["recommended"]).toFixed(2));
      this.day.dinner["overall_fat_recommended"] = parseFloat((this.day.dinner["overall_fat_recommended"] - current_meal["nf_total_fat"]["recommended"]).toFixed(2));
      this.day.dinner["overall_calories_recommended"] = parseFloat((this.day.dinner["overall_calories_recommended"] - current_meal["nf_calories"]["recommended"]).toFixed(2));

      index = this.day.dinner["foodItems"].findIndex(x => x.refId == refId);
      this.day.dinner["foodItems"].splice(index, 1);
    }

    this.day.overall_carboHydrate_recommended = parseFloat((this.day.overall_carboHydrate_recommended - current_meal["nf_total_carbohydrate"]["recommended"]).toFixed(2));
    this.day.overall_protein_recommended = parseFloat((this.day.overall_protein_recommended - current_meal["nf_protein"]["recommended"]).toFixed(2));
    this.day.overall_fibre_recommended = parseFloat((this.day.overall_fibre_recommended - current_meal["nf_dietary_fiber"]["recommended"]).toFixed(2));
    this.day.overall_fat_recommended = parseFloat((this.day.overall_fat_recommended - current_meal["nf_total_fat"]["recommended"]).toFixed(2));
    this.day.overall_calories_recommended = parseFloat((this.day.overall_calories_recommended - current_meal["nf_calories"]["recommended"]).toFixed(2));

    this.toastr.error('', 'Food item deleted succesfully.', {
      timeOut: 5000,
      positionClass: 'toast-top-right',
      progressBar: true,
      progressAnimation: 'increasing',
      tapToDismiss: true,
    });

  }
  editMeal(item_type, refId){
    this.showCalendarPopup = true;
    this.showNutrionData = true;
    this.showNutrionSearch = false;
    this.isMealEdit = true;
    this.types = [];
    this.current_meal = {};
    this.currentMealType=item_type;
    this.currentFoodRefId = refId;
    if (item_type == "lunch") {
      this.current_meal = this.day.lunch["foodItems"].find(x => x.refId == refId); 
    }
    if (item_type == "breakfast") {
      this.current_meal = this.day.breakfast["foodItems"].find(x => x.refId == refId);
    }
    if (item_type == "snacks") {
      this.current_meal = this.day.snacks["foodItems"].find(x => x.refId == refId);
    }
    if (item_type == "dinner") {
      this.current_meal = this.day.dinner["foodItems"].find(x => x.refId == refId);
    }
    this.meal_object.food_name = this.current_meal["food_name"];
    this.meal_object.serving_qty = this.current_meal["serving_qty"]["recommended"];
    this.meal_object.serving_weight_grams = this.current_meal["serving_weight_grams"]["recommended"];
    this.actualQuantity = this.meal_object.serving_qty;

    this.meal_object.nf_calories = this.current_meal["nf_calories"]["recommended"];
    this.meal_object.nf_total_fat = this.current_meal["nf_total_fat"]["recommended"];
    this.meal_object.nf_total_carbohydrate = this.current_meal["nf_total_carbohydrate"]["recommended"];
    this.meal_object.nf_dietary_fiber = this.current_meal["nf_dietary_fiber"]["recommended"];
    this.meal_object.nf_protein = this.current_meal["nf_protein"]["recommended"];

    this.meal_object.nf_saturated_fat = this.current_meal["nf_saturated_fat"];
    this.meal_object.nf_cholesterol = this.current_meal["nf_cholesterol"];
    this.meal_object.nf_sodium = this.current_meal["nf_sodium"];
    this.meal_object.nf_sugars = this.current_meal["nf_sugars"];
    this.meal_object.nf_potassium = this.current_meal["nf_potassium"];
  
    if (this.current_meal["alt_measures"] != null && this.current_meal["alt_measures"] != undefined){
            this.types = this.current_meal["alt_measures"];
            this.meal_object.alt_measures = this.current_meal["alt_measures"];
    }
          else
            this.types = [];

          for (let i = 0; i < this.types.length; i++) {
            this.types[i]["id"] = i + 1;
          }
          if (this.types.length != 0)
            this.selectedUnits = Array.of(this.types.find(x => x["measure"] == this.current_meal["serving_unit"]));
          else {
            this.selectedUnits = Array.of({ id: 1, measure: this.current_meal["serving_unit"]});
            this.types = Array.of({ id: 1, measure: this.current_meal["serving_unit"]});
          }

  }
  updateMeal(){
    if (this.meal_object.serving_qty <= 0 || this.meal_object.serving_qty == undefined) {
      this.toastr.error('', 'Meal quantity should be greater than zero.', {
        timeOut: 5000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return false;
    }
    if (this.selectedUnits == undefined) {
      this.toastr.error('', 'Please select units.', {
        timeOut: 5000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return false;
    }
    if (this.selectedUnits.length == 0) {
      this.toastr.error('', 'Please select units.', {
        timeOut: 5000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return false;
    }

    if (this.meal_object.serving_weight_grams == null) {
      this.toastr.error('', 'Serving size should not be null or zero.', {
        timeOut: 5000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return false;
    }

    this.showCalendarPopup = false;
    this.showNutrionData = false;
    this.showNutrionSearch = false;
    this.isMealEdit = false;

    var meal=JSON.parse(JSON.stringify(this.current_meal));

    meal["serving_qty"]["recommended"] = this.meal_object.serving_qty;
    meal["serving_weight_grams"]["recommended"]  = this.meal_object.serving_weight_grams;
    meal["nf_calories"]["recommended"] = this.meal_object.nf_calories;
    meal["nf_total_fat"]["recommended"] = this.meal_object.nf_total_fat;
    meal["nf_total_carbohydrate"]["recommended"] = this.meal_object.nf_total_carbohydrate;
    meal["nf_dietary_fiber"]["recommended"]= this.meal_object.nf_dietary_fiber;
    meal["nf_protein"]["recommended"] = this.meal_object.nf_protein;
    meal["nf_saturated_fat"] = this.meal_object.nf_saturated_fat;
    meal["nf_cholesterol"] = this.meal_object.nf_cholesterol;
    meal["nf_sodium"] = this.meal_object.nf_sodium;
    meal["nf_sugars"] = this.meal_object.nf_sugars;
    meal["nf_potassium"] = this.meal_object.nf_potassium;
    meal["serving_unit"] = this.selectedUnits[0]["measure"];

    this.day.overall_carboHydrate_recommended = parseFloat((this.day.overall_carboHydrate_recommended - this.current_meal["nf_total_carbohydrate"]["recommended"] + this.meal_object.nf_total_carbohydrate).toFixed(2));
    this.day.overall_protein_recommended = parseFloat((this.day.overall_protein_recommended - this.current_meal["nf_protein"]["recommended"] +  this.meal_object.nf_protein).toFixed(2));
    this.day.overall_fibre_recommended = parseFloat((this.day.overall_fibre_recommended - this.current_meal["nf_dietary_fiber"]["recommended"] + this.meal_object.nf_dietary_fiber).toFixed(2));
    this.day.overall_fat_recommended = parseFloat((this.day.overall_fat_recommended - this.current_meal["nf_total_fat"]["recommended"] + this.meal_object.nf_total_fat).toFixed(2));
    this.day.overall_calories_recommended = parseFloat((this.day.overall_calories_recommended - this.current_meal["nf_calories"]["recommended"] + this.meal_object.nf_calories).toFixed(2));

    this.day.macros = {};
    var totalDayMacros = (this.day.overall_fat_recommended * this.globals.gramFat) + (this.day.overall_protein_recommended * this.globals.gramProtein) + (this.day.overall_carboHydrate_recommended * this.globals.gramCarbs);

    this.day.macros["fat_recommended"] = this.calculateMacros(totalDayMacros, this.day.overall_fat_recommended, "fat");
    this.day.macros["protein_recommended"] = this.calculateMacros(totalDayMacros, this.day.overall_protein_recommended, "nf_protein");
    this.day.macros["carboHydrate_recommended"] = this.calculateMacros(totalDayMacros, this.day.overall_carboHydrate_recommended, "carboHydrate");


    var totalMeal_objectMacros = (meal["nf_total_fat"]["recommended"] * this.globals.gramFat) + (meal["nf_protein"]["recommended"] * this.globals.gramProtein) + (meal["nf_total_carbohydrate"]["recommended"] * this.globals.gramCarbs);
    meal.macros = {}
    meal.macros["fat_recommended"] = this.calculateMacros(totalMeal_objectMacros, meal["nf_total_fat"]["recommended"], "fat");
    meal.macros["protein_recommended"] = this.calculateMacros(totalMeal_objectMacros, meal["nf_protein"]["recommended"], "nf_protein");
    meal.macros["carboHydrate_recommended"] = this.calculateMacros(totalMeal_objectMacros, meal["nf_total_carbohydrate"]["recommended"], "carboHydrate");



    meal.dailyValues = {}
    meal.dailyValues["fat_recommended"] = parseFloat(((meal["nf_total_fat"]["recommended"] / this.globals.dailyValueOfFat) * 100).toFixed(2));
    meal.dailyValues["fibre_recommended"] = parseFloat(((meal["nf_dietary_fiber"]["recommended"] / this.globals.dailyValueOfFibre) * 100).toFixed(2));
    meal.dailyValues["carboHydrate_recommended"] = parseFloat(((meal["nf_total_carbohydrate"]["recommended"] / this.globals.dailyValueOfCarbohydrate) * 100).toFixed(2));
    meal.dailyValues["protein_recommended"] = parseFloat(((meal["nf_protein"]["recommended"] / this.globals.dailyValueOfProtein) * 100).toFixed(2));

    var bdata = JSON.parse(JSON.stringify(meal));
   
    if (this.currentMealType == "breakfast") {
      
      this.day.breakfast["overall_protein_recommended"] = parseFloat((this.day.breakfast["overall_protein_recommended"] + meal.nf_protein.recommended - this.current_meal["nf_protein"]["recommended"]).toFixed(2));
      this.day.breakfast["overall_carboHydrate_recommended"] = parseFloat((this.day.breakfast["overall_carboHydrate_recommended"] + meal.nf_total_carbohydrate.recommended - this.current_meal["nf_total_carbohydrate"]["recommended"]).toFixed(2));
      this.day.breakfast["overall_fibre_recommended"] = parseFloat((this.day.breakfast["overall_fibre_recommended"] + meal.nf_dietary_fiber.recommended - this.current_meal["nf_dietary_fiber"]["recommended"]).toFixed(2));
      this.day.breakfast["overall_fat_recommended"] = parseFloat((this.day.breakfast["overall_fat_recommended"] + meal.nf_total_fat.recommended - this.current_meal["nf_total_fat"]["recommended"]).toFixed(2));
      this.day.breakfast["overall_calories_recommended"] = parseFloat((this.day.breakfast["overall_calories_recommended"] + meal.nf_calories.recommended - this.current_meal["nf_calories"]["recommended"]).toFixed(2));


      var totalBreakfastMacros = (this.day.breakfast["overall_fat_recommended"] * this.globals.gramFat) + (this.day.breakfast["overall_protein_recommended"] * this.globals.gramProtein) + (this.day.breakfast["overall_carboHydrate_recommended"] * this.globals.gramCarbs);

      this.day.breakfast["macros"]["fat_recommended"] = this.calculateMacros(totalBreakfastMacros, this.day.breakfast["overall_fat_recommended"], "fat");
      this.day.breakfast["macros"]["protein_recommended"] = this.calculateMacros(totalBreakfastMacros, this.day.breakfast["overall_protein_recommended"], "nf_protein");
      this.day.breakfast["macros"]["carboHydrate_recommended"] = this.calculateMacros(totalBreakfastMacros, this.day.breakfast["overall_carboHydrate_recommended"], "carboHydrate");

        var index = this.day.breakfast["foodItems"].findIndex(food =>food["refId"] == this.currentFoodRefId);
        this.day.breakfast["foodItems"][index] = meal;

    }
    else if (this.currentMealType == "lunch") {
      this.day.lunch["overall_protein_recommended"] = parseFloat((this.day.lunch["overall_protein_recommended"] + meal.nf_protein.recommended - this.current_meal["nf_protein"]["recommended"]).toFixed(2));
      this.day.lunch["overall_carboHydrate_recommended"] = parseFloat((this.day.lunch["overall_carboHydrate_recommended"] + meal.nf_total_carbohydrate.recommended - this.current_meal["nf_total_carbohydrate"]["recommended"]).toFixed(2));
      this.day.lunch["overall_fibre_recommended"] = parseFloat((this.day.lunch["overall_fibre_recommended"] + meal.nf_dietary_fiber.recommended - this.current_meal["nf_dietary_fiber"]["recommended"]).toFixed(2));
      this.day.lunch["overall_fat_recommended"] = parseFloat((this.day.lunch["overall_fat_recommended"] + meal.nf_total_fat.recommended - this.current_meal["nf_total_fat"]["recommended"]).toFixed(2));
      this.day.lunch["overall_calories_recommended"] = parseFloat((this.day.lunch["overall_calories_recommended"] + meal.nf_calories.recommended - this.current_meal["nf_calories"]["recommended"]).toFixed(2));
     
      var totalLunchMacros = (this.day.lunch["overall_fat_recommended"] * this.globals.gramFat) + (this.day.lunch["overall_protein_recommended"] * this.globals.gramProtein) + (this.day.lunch["overall_carboHydrate_recommended"] * this.globals.gramCarbs);

      this.day.lunch["macros"]["fat_recommended"] = this.calculateMacros(totalLunchMacros, this.day.lunch["overall_fat_recommended"], "fat");
      this.day.lunch["macros"]["protein_recommended"] = this.calculateMacros(totalLunchMacros, this.day.lunch["overall_protein_recommended"], "nf_protein");
      this.day.lunch["macros"]["carboHydrate_recommended"] = this.calculateMacros(totalLunchMacros, this.day.lunch["overall_carboHydrate_recommended"], "carboHydrate");

      var index = this.day.lunch["foodItems"].findIndex(food =>food["refId"] == this.currentFoodRefId);
      this.day.lunch["foodItems"][index] = meal;


    }
    else if (this.currentMealType == "snacks") {
     
      this.day.snacks["overall_protein_recommended"] = parseFloat((this.day.snacks["overall_protein_recommended"] + meal.nf_protein.recommended - this.current_meal["nf_protein"]["recommended"]).toFixed(2));
      this.day.snacks["overall_carboHydrate_recommended"] = parseFloat((this.day.snacks["overall_carboHydrate_recommended"] + meal.nf_total_carbohydrate.recommended - this.current_meal["nf_total_carbohydrate"]["recommended"]).toFixed(2));
      this.day.snacks["overall_fibre_recommended"] = parseFloat((this.day.snacks["overall_fibre_recommended"] + meal.nf_dietary_fiber.recommended - this.current_meal["nf_dietary_fiber"]["recommended"]).toFixed(2));
      this.day.snacks["overall_fat_recommended"] = parseFloat((this.day.snacks["overall_fat_recommended"] + meal.nf_total_fat.recommended - this.current_meal["nf_total_fat"]["recommended"]).toFixed(2));
      this.day.snacks["overall_calories_recommended"] = parseFloat((this.day.snacks["overall_calories_recommended"] + meal.nf_calories.recommended - this.current_meal["nf_calories"]["recommended"]).toFixed(2));

      
      var totalSnacksMacros = (this.day.snacks["overall_fat_recommended"] * this.globals.gramFat) + (this.day.snacks["overall_protein_recommended"] * this.globals.gramProtein) + (this.day.snacks["overall_carboHydrate_recommended"] * this.globals.gramCarbs);

      this.day.snacks["macros"]["fat_recommended"] = this.calculateMacros(totalSnacksMacros, this.day.snacks["overall_fat_recommended"], "fat");
      this.day.snacks["macros"]["protein_recommended"] = this.calculateMacros(totalSnacksMacros, this.day.snacks["overall_protein_recommended"], "nf_protein");
      this.day.snacks["macros"]["carboHydrate_recommended"] = this.calculateMacros(totalSnacksMacros, this.day.snacks["overall_carboHydrate_recommended"], "carboHydrate");


      var index = this.day.snacks["foodItems"].findIndex(food =>food["refId"] == this.currentFoodRefId);
      this.day.snacks["foodItems"][index] = meal;

    }
    else {
      
      this.day.dinner["overall_protein_recommended"] = parseFloat((this.day.dinner["overall_protein_recommended"] + meal.nf_protein.recommended - this.current_meal["nf_protein"]["recommended"]).toFixed(2));
      this.day.dinner["overall_carboHydrate_recommended"] = parseFloat((this.day.dinner["overall_carboHydrate_recommended"] + meal.nf_total_carbohydrate.recommended - this.current_meal["nf_total_carbohydrate"]["recommended"]).toFixed(2));
      this.day.dinner["overall_fibre_recommended"] = parseFloat((this.day.dinner["overall_fibre_recommended"] + meal.nf_dietary_fiber.recommended - this.current_meal["nf_dietary_fiber"]["recommended"]).toFixed(2));
      this.day.dinner["overall_fat_recommended"] = parseFloat((this.day.dinner["overall_fat_recommended"] + meal.nf_total_fat.recommended - this.current_meal["nf_total_fat"]["recommended"]).toFixed(2));
      this.day.dinner["overall_calories_recommended"] = parseFloat((this.day.dinner["overall_calories_recommended"] + meal.nf_calories.recommended - this.current_meal["nf_calories"]["recommended"]).toFixed(2));

      var totalDinnerMacros = (this.day.dinner["overall_fat_recommended"] * this.globals.gramFat) + (this.day.dinner["overall_protein_recommended"] * this.globals.gramProtein) + (this.day.dinner["overall_carboHydrate_recommended"] * this.globals.gramCarbs);

      this.day.dinner["macros"]["fat_recommended"] = this.calculateMacros(totalDinnerMacros, this.day.dinner["overall_fat_recommended"], "fat");
      this.day.dinner["macros"]["protein_recommended"] = this.calculateMacros(totalDinnerMacros, this.day.dinner["overall_protein_recommended"], "nf_protein");
      this.day.dinner["macros"]["carboHydrate_recommended"] = this.calculateMacros(totalDinnerMacros, this.day.dinner["overall_carboHydrate_recommended"], "carboHydrate");

      var index = this.day.dinner["foodItems"].findIndex(food =>food["refId"] == this.currentFoodRefId);
      this.day.dinner["foodItems"][index] = meal;
    }

    this.mealplan.mealplan = JSON.parse(JSON.stringify(this.day));

    this.mealplan.program_id = this.program_id;
    this.showCalendarPopup = false;
    this.showNutrionSearch = false;
    this.pieChartData = [Math.round(this.day.overall_protein_recommended), Math.round(this.day.overall_carboHydrate_recommended), Math.round(this.day.overall_fat_recommended)];

  }
  saveMeal() {
    this.isBranded = false;
    this.mealplan.date = this.getFormatedDate(this.currentDate.jsdate);
    if (this.meal_object.serving_qty <= 0 || this.meal_object.serving_qty == undefined) {
      this.toastr.error('', 'Meal quantity should be greater than zero.', {
        timeOut: 5000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return false;
    }
    if (this.selectedUnits == undefined) {
      this.toastr.error('', 'Please select units.', {
        timeOut: 5000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return false;
    }
    if (this.selectedUnits.length == 0) {
      this.toastr.error('', 'Please select units.', {
        timeOut: 5000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return false;
    }

    if (this.meal_object.serving_weight_grams == null) {
      this.toastr.error('', 'Serving size should not be null or zero.', {
        timeOut: 5000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return false;
    }

    this.final_meal_object = this.getFinalMealObject(this.meal_object);

    this.day.overall_carboHydrate_recommended = parseFloat((this.day.overall_carboHydrate_recommended + this.final_meal_object.nf_total_carbohydrate.recommended).toFixed(2));
    this.day.overall_protein_recommended = parseFloat((this.day.overall_protein_recommended + this.final_meal_object.nf_protein.recommended).toFixed(2));
    this.day.overall_fibre_recommended = parseFloat((this.day.overall_fibre_recommended + this.final_meal_object.nf_dietary_fiber.recommended).toFixed(2));
    this.day.overall_fat_recommended = parseFloat((this.day.overall_fat_recommended + this.final_meal_object.nf_total_fat.recommended).toFixed(2));
    this.day.overall_calories_recommended = parseFloat((this.day.overall_calories_recommended + this.final_meal_object.nf_calories.recommended).toFixed(2));

    this.day.macros = {};
    var totalDayMacros = (this.day.overall_fat_recommended * this.globals.gramFat) + (this.day.overall_protein_recommended * this.globals.gramProtein) + (this.day.overall_carboHydrate_recommended * this.globals.gramCarbs);

    this.day.macros["fat_recommended"] = this.calculateMacros(totalDayMacros, this.day.overall_fat_recommended, "fat");
    this.day.macros["protein_recommended"] = this.calculateMacros(totalDayMacros, this.day.overall_protein_recommended, "nf_protein");
    this.day.macros["carboHydrate_recommended"] = this.calculateMacros(totalDayMacros, this.day.overall_carboHydrate_recommended, "carboHydrate");

    var totalMeal_objectMacros = (this.final_meal_object.nf_total_fat.recommended * this.globals.gramFat) + (this.final_meal_object.nf_protein.recommended * this.globals.gramProtein) + (this.final_meal_object.nf_total_carbohydrate.recommended * this.globals.gramCarbs);
    this.final_meal_object.macros = {}
    this.final_meal_object.macros["fat_recommended"] = this.calculateMacros(totalMeal_objectMacros, this.final_meal_object.nf_total_fat.recommended, "fat");
    this.final_meal_object.macros["protein_recommended"] = this.calculateMacros(totalMeal_objectMacros, this.final_meal_object.nf_protein.recommended, "nf_protein");
    this.final_meal_object.macros["carboHydrate_recommended"] = this.calculateMacros(totalMeal_objectMacros, this.final_meal_object.nf_total_carbohydrate.recommended, "carboHydrate");



    this.final_meal_object.dailyValues = {}
    this.final_meal_object.dailyValues["fat_recommended"] = parseFloat(((this.final_meal_object.nf_total_fat.recommended / this.globals.dailyValueOfFat) * 100).toFixed(2));
    this.final_meal_object.dailyValues["fibre_recommended"] = parseFloat(((this.final_meal_object.nf_dietary_fiber.recommended / this.globals.dailyValueOfFibre) * 100).toFixed(2));
    this.final_meal_object.dailyValues["carboHydrate_recommended"] = parseFloat(((this.final_meal_object.nf_total_carbohydrate.recommended / this.globals.dailyValueOfCarbohydrate) * 100).toFixed(2));
    this.final_meal_object.dailyValues["protein_recommended"] = parseFloat(((this.final_meal_object.nf_protein.recommended / this.globals.dailyValueOfProtein) * 100).toFixed(2));

    this.final_meal_object.foodStatus = "new";
    this.final_meal_object.createdBy = "trainer"
    var bdata = JSON.parse(JSON.stringify(this.final_meal_object));
    bdata.refId = Math.floor(1000 + Math.random() * 9000);
    //const data = [Math.round(this.totalCalories), Math.round(this.totalProtiens), Math.round(this.totalFat), Math.round(this.totalCarbs)]
    //this.doughnutChartData = data;
    // this.pieChartData = data;
    if (this.currentMealType == "breakfast") {
      if (this.day.breakfast["overall_protein_recommended"] == undefined && this.day.breakfast["overall_carboHydrate_recommended"] == undefined && this.day.breakfast["overall_fibre_recommended"] == undefined && this.day.breakfast["overall_fat_recommended"] == undefined && this.day.breakfast["overall_calories_recommended"] == undefined) {
        this.day.breakfast["overall_protein_recommended"] = 0;
        this.day.breakfast["overall_carboHydrate_recommended"] = 0;
        this.day.breakfast["overall_fibre_recommended"] = 0;
        this.day.breakfast["overall_fat_recommended"] = 0;
        this.day.breakfast["overall_calories_recommended"] = 0;
      }

      this.day.breakfast["overall_protein_recommended"] = parseFloat((this.day.breakfast["overall_protein_recommended"] + this.final_meal_object.nf_protein.recommended).toFixed(2));
      this.day.breakfast["overall_carboHydrate_recommended"] = parseFloat((this.day.breakfast["overall_carboHydrate_recommended"] + this.final_meal_object.nf_total_carbohydrate.recommended).toFixed(2));
      this.day.breakfast["overall_fibre_recommended"] = parseFloat((this.day.breakfast["overall_fibre_recommended"] + this.final_meal_object.nf_dietary_fiber.recommended).toFixed(2));
      this.day.breakfast["overall_fat_recommended"] = parseFloat((this.day.breakfast["overall_fat_recommended"] + this.final_meal_object.nf_total_fat.recommended).toFixed(2));
      this.day.breakfast["overall_calories_recommended"] = parseFloat((this.day.breakfast["overall_calories_recommended"] + this.final_meal_object.nf_calories.recommended).toFixed(2));

      // this.day.breakfast["macros"] = {};

      var totalBreakfastMacros = (this.day.breakfast["overall_fat_recommended"] * this.globals.gramFat) + (this.day.breakfast["overall_protein_recommended"] * this.globals.gramProtein) + (this.day.breakfast["overall_carboHydrate_recommended"] * this.globals.gramCarbs);

      this.day.breakfast["macros"]["fat_recommended"] = this.calculateMacros(totalBreakfastMacros, this.day.breakfast["overall_fat_recommended"], "fat");
      this.day.breakfast["macros"]["protein_recommended"] = this.calculateMacros(totalBreakfastMacros, this.day.breakfast["overall_protein_recommended"], "nf_protein");
      this.day.breakfast["macros"]["carboHydrate_recommended"] = this.calculateMacros(totalBreakfastMacros, this.day.breakfast["overall_carboHydrate_recommended"], "carboHydrate");


      if (this.day.breakfast["foodItems"] == undefined) {
        this.day.breakfast["foodItems"] = Array.of(JSON.parse(JSON.stringify(bdata)));
      } else {
        if (this.day.breakfast["foodItems"].length != 0) {
          bdata.time = this.day.breakfast["foodItems"][this.day.breakfast["foodItems"].length - 1].time;
        }
        this.day.breakfast["foodItems"].push(JSON.parse(JSON.stringify(bdata)));
      }
    }
    else if (this.currentMealType == "lunch") {
      if (this.day.lunch["overall_protein_recommended"] == undefined && this.day.lunch["overall_carboHydrate_recommended"] == undefined && this.day.lunch["overall_fibre_recommended"] == undefined && this.day.lunch["overall_fat_recommended"] == undefined && this.day.lunch["overall_calories_recommended"] == undefined) {
        this.day.lunch["overall_protein_recommended"] = 0;
        this.day.lunch["overall_carboHydrate_recommended"] = 0;
        this.day.lunch["overall_fibre_recommended"] = 0;
        this.day.lunch["overall_fat_recommended"] = 0;
        this.day.lunch["overall_calories_recommended"] = 0;
      }
      this.day.lunch["overall_protein_recommended"] = parseFloat((this.day.lunch["overall_protein_recommended"] + this.final_meal_object.nf_protein.recommended).toFixed(2));
      this.day.lunch["overall_carboHydrate_recommended"] = parseFloat((this.day.lunch["overall_carboHydrate_recommended"] + this.final_meal_object.nf_total_carbohydrate.recommended).toFixed(2));
      this.day.lunch["overall_fibre_recommended"] = parseFloat((this.day.lunch["overall_fibre_recommended"] + this.final_meal_object.nf_dietary_fiber.recommended).toFixed(2));
      this.day.lunch["overall_fat_recommended"] = parseFloat((this.day.lunch["overall_fat_recommended"] + this.final_meal_object.nf_total_fat.recommended).toFixed(2));
      this.day.lunch["overall_calories_recommended"] = parseFloat((this.day.lunch["overall_calories_recommended"] + this.final_meal_object.nf_calories.recommended).toFixed(2));

      // this.day.lunch["macros"] = {};

      var totalLunchMacros = (this.day.lunch["overall_fat_recommended"] * this.globals.gramFat) + (this.day.lunch["overall_protein_recommended"] * this.globals.gramProtein) + (this.day.lunch["overall_carboHydrate_recommended"] * this.globals.gramCarbs);

      this.day.lunch["macros"]["fat_recommended"] = this.calculateMacros(totalLunchMacros, this.day.lunch["overall_fat_recommended"], "fat");
      this.day.lunch["macros"]["protein_recommended"] = this.calculateMacros(totalLunchMacros, this.day.lunch["overall_protein_recommended"], "nf_protein");
      this.day.lunch["macros"]["carboHydrate_recommended"] = this.calculateMacros(totalLunchMacros, this.day.lunch["overall_carboHydrate_recommended"], "carboHydrate");



      if (this.day.lunch["foodItems"] == undefined) {
        this.day.lunch["foodItems"] = Array.of(JSON.parse(JSON.stringify(bdata)));
      } else {
        if (this.day.lunch["foodItems"].length != 0) {
          bdata.time = this.day.lunch["foodItems"][this.day.lunch["foodItems"].length - 1].time;
        }
        this.day.lunch["foodItems"].push(JSON.parse(JSON.stringify(bdata)));
      }
    }
    else if (this.currentMealType == "snacks") {
      if (this.day.snacks["overall_protein_recommended"] == undefined && this.day.snacks["overall_carboHydrate_recommended"] == undefined && this.day.snacks["overall_fibre_recommended"] == undefined && this.day.snacks["overall_fat_recommended"] == undefined && this.day.snacks["overall_calories_recommended"] == undefined) {
        this.day.snacks["overall_protein_recommended"] = 0;
        this.day.snacks["overall_carboHydrate_recommended"] = 0;
        this.day.snacks["overall_fibre_recommended"] = 0;
        this.day.snacks["overall_fat_recommended"] = 0;
        this.day.snacks["overall_calories_recommended"] = 0;
      }

      this.day.snacks["overall_protein_recommended"] = parseFloat((this.day.snacks["overall_protein_recommended"] + this.final_meal_object.nf_protein.recommended).toFixed(2));
      this.day.snacks["overall_carboHydrate_recommended"] = parseFloat((this.day.snacks["overall_carboHydrate_recommended"] + this.final_meal_object.nf_total_carbohydrate.recommended).toFixed(2));
      this.day.snacks["overall_fibre_recommended"] = parseFloat((this.day.snacks["overall_fibre_recommended"] + this.final_meal_object.nf_dietary_fiber.recommended).toFixed(2));
      this.day.snacks["overall_fat_recommended"] = parseFloat((this.day.snacks["overall_fat_recommended"] + this.final_meal_object.nf_total_fat.recommended).toFixed(2));
      this.day.snacks["overall_calories_recommended"] = parseFloat((this.day.snacks["overall_calories_recommended"] + this.final_meal_object.nf_calories.recommended).toFixed(2));

      // this.day.snacks["macros"] = {};
      var totalSnacksMacros = (this.day.snacks["overall_fat_recommended"] * this.globals.gramFat) + (this.day.snacks["overall_protein_recommended"] * this.globals.gramProtein) + (this.day.snacks["overall_carboHydrate_recommended"] * this.globals.gramCarbs);

      this.day.snacks["macros"]["fat_recommended"] = this.calculateMacros(totalSnacksMacros, this.day.snacks["overall_fat_recommended"], "fat");
      this.day.snacks["macros"]["protein_recommended"] = this.calculateMacros(totalSnacksMacros, this.day.snacks["overall_protein_recommended"], "nf_protein");
      this.day.snacks["macros"]["carboHydrate_recommended"] = this.calculateMacros(totalSnacksMacros, this.day.snacks["overall_carboHydrate_recommended"], "carboHydrate");



      if (this.day.snacks["foodItems"] == undefined) {
        this.day.snacks["foodItems"] = Array.of(JSON.parse(JSON.stringify(bdata)));
      } else {
        if (this.day.snacks["foodItems"].length != 0) {
          bdata.time = this.day.snacks["foodItems"][this.day.snacks["foodItems"].length - 1].time;
        }
        this.day.snacks["foodItems"].push(JSON.parse(JSON.stringify(bdata)));
      }
    }
    else {
      if (this.day.dinner["overall_protein_recommended"] == undefined && this.day.dinner["overall_carboHydrate_recommended"] == undefined && this.day.dinner["overall_fibre_recommended"] == undefined && this.day.dinner["overall_fat_recommended"] == undefined && this.day.dinner["overall_calories_recommended"] == undefined) {
        this.day.dinner["overall_protein_recommended"] = 0;
        this.day.dinner["overall_carboHydrate_recommended"] = 0;
        this.day.dinner["overall_fibre_recommended"] = 0;
        this.day.dinner["overall_fat_recommended"] = 0;
        this.day.dinner["overall_calories_recommended"] = 0;
      }

      this.day.dinner["overall_protein_recommended"] = parseFloat((this.day.dinner["overall_protein_recommended"] + this.final_meal_object.nf_protein.recommended).toFixed(2));
      this.day.dinner["overall_carboHydrate_recommended"] = parseFloat((this.day.dinner["overall_carboHydrate_recommended"] + this.final_meal_object.nf_total_carbohydrate.recommended).toFixed(2));
      this.day.dinner["overall_fibre_recommended"] = parseFloat((this.day.dinner["overall_fibre_recommended"] + this.final_meal_object.nf_dietary_fiber.recommended).toFixed(2));
      this.day.dinner["overall_fat_recommended"] = parseFloat((this.day.dinner["overall_fat_recommended"] + this.final_meal_object.nf_total_fat.recommended).toFixed(2));
      this.day.dinner["overall_calories_recommended"] = parseFloat((this.day.dinner["overall_calories_recommended"] + this.final_meal_object.nf_calories.recommended).toFixed(2));

      // this.day.dinner["macros"] = {};
      var totalDinnerMacros = (this.day.dinner["overall_fat_recommended"] * this.globals.gramFat) + (this.day.dinner["overall_protein_recommended"] * this.globals.gramProtein) + (this.day.dinner["overall_carboHydrate_recommended"] * this.globals.gramCarbs);

      this.day.dinner["macros"]["fat_recommended"] = this.calculateMacros(totalDinnerMacros, this.day.dinner["overall_fat_recommended"], "fat");
      this.day.dinner["macros"]["protein_recommended"] = this.calculateMacros(totalDinnerMacros, this.day.dinner["overall_protein_recommended"], "nf_protein");
      this.day.dinner["macros"]["carboHydrate_recommended"] = this.calculateMacros(totalDinnerMacros, this.day.dinner["overall_carboHydrate_recommended"], "carboHydrate");


      if (this.day.dinner["foodItems"] == undefined) {
        this.day.dinner["foodItems"] = Array.of(JSON.parse(JSON.stringify(bdata)));
      } else {
        if (this.day.dinner["foodItems"].length != 0) {
          bdata.time = this.day.dinner["foodItems"][this.day.dinner["foodItems"].length - 1].time;
        }
        this.day.dinner["foodItems"].push(JSON.parse(JSON.stringify(bdata)));
      }
    }
    this.day.mealPlanStatus = "new";
    this.mealplan.trainee_id = this.trainee_id;
    this.mealplan.creationDate = new Date();
    this.mealplan.trainer_id = this.userID;
    this.mealplan.mealplan = JSON.parse(JSON.stringify(this.day));

    this.mealplan.program_id = this.program_id;
    this.showCalendarPopup = false;
    this.showNutrionSearch = false;
    this.pieChartData = [Math.round(this.day.overall_protein_recommended), Math.round(this.day.overall_carboHydrate_recommended), Math.round(this.day.overall_fat_recommended)];
  }
  calculateMacros(calories, value, type) {
    if (type == "fat") {
      var covertedKcal = value * this.globals.gramFat;
      return Math.round((covertedKcal / calories) * 100);
    } else {
      var covertedKcal = value * this.globals.gramCarbs;
      return Math.round((covertedKcal / calories) * 100);
    }
  }
  saveDayMeal() {
    if (this.day.mealName == null || this.day.mealName == "") {
      this.toastr.error('', 'Please enter meal name.', {
        timeOut: 5000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return false;
    }
    if (this.day.breakfast.foodItems.length == 0 && this.day.lunch.foodItems.length == 0 && this.day.dinner.foodItems.length == 0 && this.day.snacks.foodItems.length == 0) {
      this.toastr.error('', 'Please add atleast one food item.', {
        timeOut: 5000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return false;
    }

    var bareakfast_response = this.day.breakfast.foodItems.find(x => x["time"] == "");
    var lunch_response = this.day.lunch.foodItems.find(x => x["time"] == "");
    var snacks_response = this.day.snacks.foodItems.find(x => x["time"] == "");
    var dinner_response = this.day.dinner.foodItems.find(x => x["time"] == "");
    if (bareakfast_response != undefined || lunch_response != undefined || snacks_response != undefined || dinner_response != undefined) {
      this.toastr.error('', 'Please add time to all food items.', {
        timeOut: 5000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return false;
    }


    this.mealplan.mealplan = JSON.parse(JSON.stringify(this.day));
    if (this.mealplan.mealplan["breakfast"]["foodItems"].length == 0) {
      this.mealplan.mealplan["breakfast"] = undefined;
    }
    if (this.mealplan.mealplan["lunch"]["foodItems"].length == 0) {
      this.mealplan.mealplan["lunch"] = undefined;
    }
    if (this.mealplan.mealplan["snacks"]["foodItems"].length == 0) {
      this.mealplan.mealplan["snacks"] = undefined;
    }
    if (this.mealplan.mealplan["dinner"]["foodItems"].length == 0) {
      this.mealplan.mealplan["dinner"] = undefined;
    }

    var index = '/mealplan/day'
    console.log("data", this.mealplan);
    if(this.currentCategory == "Bodybuilder"){
    this.db_service.updateDatainDB(this.mealplan, index).subscribe(
      response => {
        this.showCalendarPopup = false;
        this.showNutrionSearch = false;
        this.router.navigate(['/traineeMeal/' + this.program_id + "/" + this.trainee_id]);
        this.dataService.mealPlanRoute("true");
        this.renderer.removeClass(document.body, 'modal-open');
        this.toastr.success('', 'Saved successfully', {
          timeOut: 5000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      },
      error => {
        this.toastr.error('', 'There is an error while saving details.', {
          timeOut: 5000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      });

    }else{
      this.yoga_db_service.updateDatainDB(this.mealplan, index).subscribe(
        response => {
          this.showCalendarPopup = false;
          this.showNutrionSearch = false;
          this.router.navigate(['/yogaMealplanCalendar/' + this.program_id + "/" + this.trainee_id]);
          this.dataService.mealPlanRoute("true");
          this.renderer.removeClass(document.body, 'modal-open');
          this.toastr.success('', 'Saved successfully', {
            timeOut: 5000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        },
        error => {
          this.toastr.error('', 'There is an error while saving details.', {
            timeOut: 5000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        });
    }
  }
  onBackToCal(){
    if(this.currentCategory == "Bodybuilder")
    this.router.navigate(['/traineeMeal/' + this.program_id + "/" + this.trainee_id]);
    else
    this.router.navigate(['/yogaMealplanCalendar/' + this.program_id + "/" + this.trainee_id]);

    this.dataService.mealPlanRoute("true");
  }
  CalculateNutrients(item_type, meal_obj, cal_type) {
    if(this.isDataEditable){
    this.currentMealType = item_type;

    this.final_meal_object = meal_obj;
    this.actualQuantity = this.final_meal_object.serving_qty.recommended;

    if (cal_type == "addition")
      this.final_meal_object.serving_qty.recommended = this.final_meal_object.serving_qty.recommended + 1;
    else
      this.final_meal_object.serving_qty.recommended = this.final_meal_object.serving_qty.recommended - 1;

    if (this.final_meal_object.serving_qty.recommended == 0) {
      this.final_meal_object.serving_qty.recommended = this.final_meal_object.serving_qty.recommended + 1;
      this.toastr.error('', 'Minimun quantity should be greater than zero.', {
        timeOut: 5000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return;
    }
    //if (this.final_meal_object.nix_item_id != null && this.final_meal_object.nix_item_id != "") {
    this.final_meal_object.serving_weight_grams.recommended = parseFloat(((this.final_meal_object.serving_qty.recommended * this.final_meal_object.serving_weight_grams.recommended) / this.actualQuantity).toFixed(2));
    this.final_meal_object.nf_calories.recommended = parseFloat(((this.final_meal_object.serving_qty.recommended * this.final_meal_object.nf_calories.recommended) / this.actualQuantity).toFixed(2));
    this.final_meal_object.nf_total_fat.recommended = parseFloat(((this.final_meal_object.serving_qty.recommended * this.final_meal_object.nf_total_fat.recommended) / this.actualQuantity).toFixed(2));
    this.final_meal_object.nf_saturated_fat = parseFloat(((this.final_meal_object.serving_qty.recommended * this.final_meal_object.nf_saturated_fat) / this.actualQuantity).toFixed(2));
    this.final_meal_object.nf_cholesterol = parseFloat(((this.final_meal_object.serving_qty.recommended * this.final_meal_object.nf_cholesterol) / this.actualQuantity).toFixed(2));
    this.final_meal_object.nf_sodium = parseFloat(((this.final_meal_object.serving_qty.recommended * this.final_meal_object.nf_sodium) / this.actualQuantity).toFixed(2));
    this.final_meal_object.nf_total_carbohydrate.recommended = parseFloat(((this.final_meal_object.serving_qty.recommended * this.final_meal_object.nf_total_carbohydrate.recommended) / this.actualQuantity).toFixed(2));
    this.final_meal_object.nf_dietary_fiber.recommended = parseFloat(((this.final_meal_object.serving_qty.recommended * this.final_meal_object.nf_dietary_fiber.recommended) / this.actualQuantity).toFixed(2));
    this.final_meal_object.nf_sugars = parseFloat(((this.final_meal_object.serving_qty.recommended * this.final_meal_object.nf_sugars) / this.actualQuantity).toFixed(2));
    this.final_meal_object.nf_protein.recommended = parseFloat(((this.final_meal_object.serving_qty.recommended * this.final_meal_object.nf_protein.recommended) / this.actualQuantity).toFixed(2));
    this.final_meal_object.nf_potassium = parseFloat(((this.final_meal_object.serving_qty.recommended * this.final_meal_object.nf_potassium) / this.actualQuantity).toFixed(2));
    this.calculateOverAllData();
    // } else {
    //   var query = {
    //     "query": this.final_meal_object.serving_qty.recommended + " " + this.final_meal_object.serving_unit + " " + this.meal_object.food_name
    //   }

    //   this.nutritionixservice.getNutritionDataFromServer(query).subscribe(
    //     response => {
    //       this.meal_object = response.foods[0];
    //       this.final_meal_object = this.getFinalMealObject(this.meal_object);
    //       this.calculateOverAllData();
    //     })
    // }
  }
  }
  calculateOverAllData() {
    if (this.currentMealType == "breakfast") {
      var b_fast_index = this.day.breakfast["foodItems"].findIndex(x => x.refId == this.final_meal_object.refId)
      this.day.breakfast["foodItems"][b_fast_index] = JSON.parse(JSON.stringify(this.final_meal_object));

      this.day.breakfast["overall_protein_recommended"] = 0;
      this.day.breakfast["overall_carboHydrate_recommended"] = 0;
      this.day.breakfast["overall_fibre_recommended"] = 0;
      this.day.breakfast["overall_fat_recommended"] = 0;
      this.day.breakfast["overall_calories_recommended"] = 0;

      for (let i = 0; i < this.day.breakfast.foodItems.length; i++) {
        this.day.breakfast["overall_protein_recommended"] = this.day.breakfast["overall_protein_recommended"] + this.day.breakfast.foodItems[i].nf_protein.recommended;
        this.day.breakfast["overall_carboHydrate_recommended"] = this.day.breakfast["overall_carboHydrate_recommended"] + this.day.breakfast.foodItems[i].nf_total_carbohydrate.recommended;
        this.day.breakfast["overall_fibre_recommended"] = this.day.breakfast["overall_fibre_recommended"] + this.day.breakfast.foodItems[i].nf_dietary_fiber.recommended;
        this.day.breakfast["overall_fat_recommended"] = this.day.breakfast["overall_fat_recommended"] + this.day.breakfast.foodItems[i].nf_total_fat.recommended;
        this.day.breakfast["overall_calories_recommended"] = this.day.breakfast["overall_calories_recommended"] + this.day.breakfast.foodItems[i].nf_calories.recommended;
      }

      this.day.breakfast["overall_protein_recommended"] = parseFloat((this.day.breakfast["overall_protein_recommended"]).toFixed(2));
      this.day.breakfast["overall_carboHydrate_recommended"] = parseFloat((this.day.breakfast["overall_carboHydrate_recommended"]).toFixed(2));
      this.day.breakfast["overall_fibre_recommended"] = parseFloat((this.day.breakfast["overall_fibre_recommended"]).toFixed(2));
      this.day.breakfast["overall_fat_recommended"] = parseFloat((this.day.breakfast["overall_fat_recommended"]).toFixed(2));
      this.day.breakfast["overall_calories_recommended"] = parseFloat((this.day.breakfast["overall_calories_recommended"]).toFixed(2));

    }
    else if (this.currentMealType == "lunch") {
      var lunch_index = this.day.lunch["foodItems"].findIndex(x => x.refId == this.final_meal_object.refId)
      this.day.lunch["foodItems"][lunch_index] = JSON.parse(JSON.stringify(this.final_meal_object));

      this.day.lunch["overall_protein_recommended"] = 0;
      this.day.lunch["overall_carboHydrate_recommended"] = 0;
      this.day.lunch["overall_fibre_recommended"] = 0;
      this.day.lunch["overall_fat_recommended"] = 0;
      this.day.lunch["overall_calories_recommended"] = 0;

      for (let i = 0; i < this.day.lunch.foodItems.length; i++) {
        this.day.lunch["overall_protein_recommended"] = this.day.lunch["overall_protein_recommended"] + this.day.lunch.foodItems[i].nf_protein.recommended;
        this.day.lunch["overall_carboHydrate_recommended"] = this.day.lunch["overall_carboHydrate_recommended"] + this.day.lunch.foodItems[i].nf_total_carbohydrate.recommended;
        this.day.lunch["overall_fibre_recommended"] = this.day.lunch["overall_fibre_recommended"] + this.day.lunch.foodItems[i].nf_dietary_fiber.recommended;
        this.day.lunch["overall_fat_recommended"] = this.day.lunch["overall_fat_recommended"] + this.day.lunch.foodItems[i].nf_total_fat.recommended;
        this.day.lunch["overall_calories_recommended"] = this.day.lunch["overall_calories_recommended"] + this.day.lunch.foodItems[i].nf_calories.recommended;
      }

      this.day.lunch["overall_protein_recommended"] = parseFloat((this.day.lunch["overall_protein_recommended"]).toFixed(2));
      this.day.lunch["overall_carboHydrate_recommended"] = parseFloat((this.day.lunch["overall_carboHydrate_recommended"]).toFixed(2));
      this.day.lunch["overall_fibre_recommended"] = parseFloat((this.day.lunch["overall_fibre_recommended"]).toFixed(2));
      this.day.lunch["overall_fat_recommended"] = parseFloat((this.day.lunch["overall_fat_recommended"]).toFixed(2));
      this.day.lunch["overall_calories_recommended"] = parseFloat((this.day.lunch["overall_calories_recommended"]).toFixed(2));
    }
    else if (this.currentMealType == "snacks") {
      var snacks_index = this.day.snacks["foodItems"].findIndex(x => x.refId == this.final_meal_object.refId)
      this.day.snacks["foodItems"][snacks_index] = JSON.parse(JSON.stringify(this.final_meal_object));

      this.day.snacks["overall_protein_recommended"] = 0;
      this.day.snacks["overall_carboHydrate_recommended"] = 0;
      this.day.snacks["overall_fibre_recommended"] = 0;
      this.day.snacks["overall_fat_recommended"] = 0;
      this.day.snacks["overall_calories_recommended"] = 0;

      for (let i = 0; i < this.day.snacks.foodItems.length; i++) {
        this.day.snacks["overall_protein_recommended"] = this.day.snacks["overall_protein_recommended"] + this.day.snacks.foodItems[i].nf_protein.recommended;
        this.day.snacks["overall_carboHydrate_recommended"] = this.day.snacks["overall_carboHydrate_recommended"] + this.day.snacks.foodItems[i].nf_total_carbohydrate.recommended;
        this.day.snacks["overall_fibre_recommended"] = this.day.snacks["overall_fibre_recommended"] + this.day.snacks.foodItems[i].nf_dietary_fiber.recommended;
        this.day.snacks["overall_fat_recommended"] = this.day.snacks["overall_fat_recommended"] + this.day.snacks.foodItems[i].nf_total_fat.recommended;
        this.day.snacks["overall_calories_recommended"] = this.day.snacks["overall_calories_recommended"] + this.day.snacks.foodItems[i].nf_calories.recommended;
      }

      this.day.snacks["overall_protein_recommended"] = parseFloat((this.day.snacks["overall_protein_recommended"]).toFixed(2));
      this.day.snacks["overall_carboHydrate_recommended"] = parseFloat((this.day.snacks["overall_carboHydrate_recommended"]).toFixed(2));
      this.day.snacks["overall_fibre_recommended"] = parseFloat((this.day.snacks["overall_fibre_recommended"]).toFixed(2));
      this.day.snacks["overall_fat_recommended"] = parseFloat((this.day.snacks["overall_fat_recommended"]).toFixed(2));
      this.day.snacks["overall_calories_recommended"] = parseFloat((this.day.snacks["overall_calories_recommended"]).toFixed(2));
    }
    else {
      var dinner_index = this.day.dinner["foodItems"].findIndex(x => x.refId == this.final_meal_object.refId)
      this.day.dinner["foodItems"][dinner_index] = JSON.parse(JSON.stringify(this.final_meal_object));

      this.day.dinner["overall_protein_recommended"] = 0;
      this.day.dinner["overall_carboHydrate_recommended"] = 0;
      this.day.dinner["overall_fibre_recommended"] = 0;
      this.day.dinner["overall_fat_recommended"] = 0;
      this.day.dinner["overall_calories_recommended"] = 0;

      for (let i = 0; i < this.day.dinner.foodItems.length; i++) {
        this.day.dinner["overall_protein_recommended"] = this.day.dinner["overall_protein_recommended"] + this.day.dinner.foodItems[i].nf_protein.recommended;
        this.day.dinner["overall_carboHydrate_recommended"] = this.day.dinner["overall_carboHydrate_recommended"] + this.day.dinner.foodItems[i].nf_total_carbohydrate.recommended;
        this.day.dinner["overall_fibre_recommended"] = this.day.dinner["overall_fibre_recommended"] + this.day.dinner.foodItems[i].nf_dietary_fiber.recommended;
        this.day.dinner["overall_fat_recommended"] = this.day.dinner["overall_fat_recommended"] + this.day.dinner.foodItems[i].nf_total_fat.recommended;
        this.day.dinner["overall_calories_recommended"] = this.day.dinner["overall_calories_recommended"] + this.day.dinner.foodItems[i].nf_calories.recommended;
      }

      this.day.dinner["overall_protein_recommended"] = parseFloat((this.day.dinner["overall_protein_recommended"]).toFixed(2));
      this.day.dinner["overall_carboHydrate_recommended"] = parseFloat((this.day.dinner["overall_carboHydrate_recommended"]).toFixed(2));
      this.day.dinner["overall_fibre_recommended"] = parseFloat((this.day.dinner["overall_fibre_recommended"]).toFixed(2));
      this.day.dinner["overall_fat_recommended"] = parseFloat((this.day.dinner["overall_fat_recommended"]).toFixed(2));
      this.day.dinner["overall_calories_recommended"] = parseFloat((this.day.dinner["overall_calories_recommended"]).toFixed(2));
    }


    this.day.overall_carboHydrate_recommended = parseFloat((this.day.breakfast["overall_carboHydrate_recommended"] + this.day.lunch["overall_carboHydrate_recommended"] + this.day.snacks["overall_carboHydrate_recommended"] + this.day.dinner["overall_carboHydrate_recommended"]).toFixed(2));
    this.day.overall_protein_recommended = parseFloat((this.day.breakfast["overall_protein_recommended"] + this.day.lunch["overall_protein_recommended"] + this.day.snacks["overall_protein_recommended"] + this.day.dinner["overall_protein_recommended"]).toFixed(2));
    this.day.overall_fibre_recommended = parseFloat((this.day.breakfast["overall_fibre_recommended"] + this.day.lunch["overall_fibre_recommended"] + this.day.snacks["overall_fibre_recommended"] + this.day.dinner["overall_fibre_recommended"]).toFixed(2));
    this.day.overall_fat_recommended = parseFloat((this.day.breakfast["overall_fat_recommended"] + this.day.lunch["overall_fat_recommended"] + this.day.snacks["overall_fat_recommended"] + this.day.dinner["overall_fat_recommended"]).toFixed(2));
    this.day.overall_calories_recommended = parseFloat((this.day.breakfast["overall_calories_recommended"] + this.day.lunch["overall_calories_recommended"] + this.day.snacks["overall_calories_recommended"] + this.day.dinner["overall_calories_recommended"]).toFixed(2));

    this.pieChartData = [Math.round(this.day.overall_protein_recommended), Math.round(this.day.overall_carboHydrate_recommended), Math.round(this.day.overall_fat_recommended)];
  }
  getFinalMealObject(mealObject) {
    this.meal_object = mealObject;

    if(this.meal_object.nf_protein == null)
    this.final_meal_object.nf_protein.recommended = 0;
    else
    this.final_meal_object.nf_protein.recommended = this.meal_object.nf_protein;

    if(this.meal_object.nf_total_carbohydrate == null)
    this.final_meal_object.nf_total_carbohydrate.recommended = 0;
    else
    this.final_meal_object.nf_total_carbohydrate.recommended = this.meal_object.nf_total_carbohydrate;

    if(this.meal_object.nf_dietary_fiber == null)
    this.final_meal_object.nf_dietary_fiber.recommended = 0;
    else
    this.final_meal_object.nf_dietary_fiber.recommended = this.meal_object.nf_dietary_fiber;

    if(this.meal_object.nf_total_fat == null)
    this.final_meal_object.nf_total_fat.recommended = 0;
    else
    this.final_meal_object.nf_total_fat.recommended = this.meal_object.nf_total_fat;

    if(this.meal_object.nf_calories == null)
    this.final_meal_object.nf_calories.recommended = 0;
    else
    this.final_meal_object.nf_calories.recommended = this.meal_object.nf_calories;

    if(this.meal_object.serving_qty == null)
    this.final_meal_object.serving_qty.recommended = 0;
    else
    this.final_meal_object.serving_qty.recommended = this.meal_object.serving_qty;

    if(this.meal_object.serving_weight_grams == null)
    this.final_meal_object.serving_weight_grams.recommended = 0;
    else
    this.final_meal_object.serving_weight_grams.recommended = this.meal_object.serving_weight_grams;

    this.final_meal_object.food_name = this.meal_object.food_name;
    this.final_meal_object.brand_name = this.meal_object.brand_name;
    this.final_meal_object.serving_unit = this.meal_object.serving_unit;
    if (this.meal_object.nf_saturated_fat != null)
      this.final_meal_object.nf_saturated_fat = this.meal_object.nf_saturated_fat;
    else
      this.final_meal_object.nf_saturated_fat = 0;
    if (this.meal_object.nf_cholesterol != null)
      this.final_meal_object.nf_cholesterol = this.meal_object.nf_cholesterol;
    else
      this.final_meal_object.nf_cholesterol = 0;
    if (this.meal_object.nf_sodium != null)
      this.final_meal_object.nf_sodium = this.meal_object.nf_sodium;
    else
      this.final_meal_object.nf_sodium = 0;
    if (this.meal_object.nf_sugars != null)
      this.final_meal_object.nf_sugars = this.meal_object.nf_sugars;
    else
      this.final_meal_object.nf_sugars = 0;
    if (this.meal_object.nf_potassium != null)
      this.final_meal_object.nf_potassium = this.meal_object.nf_potassium;
    else
      this.final_meal_object.nf_potassium = 0;
    if (this.meal_object.nf_p != null)
      this.final_meal_object.nf_p = this.meal_object.nf_p;
    else
      this.final_meal_object.nf_p = 0;

    this.final_meal_object.nix_brand_name = this.meal_object.nix_brand_name;
    this.final_meal_object.nix_brand_id = this.meal_object.nix_brand_id;
    this.final_meal_object.nix_item_name = this.meal_object.nix_item_name;
    this.final_meal_object.nix_item_id = this.meal_object.nix_item_id;
    this.final_meal_object.upc = this.meal_object.upc;
    this.final_meal_object.consumed_at = this.meal_object.consumed_at;
    this.final_meal_object.metadata = this.meal_object.metadata;
    this.final_meal_object.source = this.meal_object.source;
    this.final_meal_object.ndb_no = this.meal_object.ndb_no;
    this.final_meal_object.tags = this.meal_object.tags;
    this.final_meal_object.alt_measures = this.meal_object.alt_measures;
    this.final_meal_object.lat = this.meal_object.lat;
    this.final_meal_object.lng = this.meal_object.lng;
    this.final_meal_object.meal_type = this.meal_object.meal_type;
    this.final_meal_object.photo = this.meal_object.photo;
    this.final_meal_object.sub_recipe = this.meal_object.sub_recipe;
    return this.final_meal_object;
  }
  getMealPlanDataFromServer() {
    this.showMealGrid = true;
    var date2 = new Date();
    this.isDataEditable = this.compareDates(this.currentDate.jsdate, date2);

    var d = this.getFormatedDate(this.currentDate.jsdate);
    var index = '/mealplan/day';
    var queryParams = {
      "program_id": this.program_id,
       date: d,
      "trainee_id": this.trainee_id
    }
    if(this.currentCategory == "Bodybuilder"){
    this.db_service.getQueriedData("", index, queryParams).subscribe(
      response => {
        console.log('Data Retrived succesfully.', response);
        this.renderer.removeClass(document.body, 'modal-open');
        if (response.data != null) {
           if(response.data.trainer_status != 'published')
           this.isDataEditable = true;
          this.mealplan = response.data;
          if (this.mealplan.mealplan != null || this.mealplan.mealplan != undefined) {

            if (this.mealplan.mealplan.breakfast == undefined)
              this.mealplan.mealplan.breakfast = JSON.parse(JSON.stringify(this.empTy));
            if (this.mealplan.mealplan.lunch == undefined)
              this.mealplan.mealplan.lunch = JSON.parse(JSON.stringify(this.empTy));
            if (this.mealplan.mealplan.snacks == undefined)
              this.mealplan.mealplan.snacks = JSON.parse(JSON.stringify(this.empTy));
            if (this.mealplan.mealplan.dinner == undefined)
              this.mealplan.mealplan.dinner = JSON.parse(JSON.stringify(this.empTy));

            this.day = this.mealplan.mealplan;
            if (this.mealplan.mealplan.dinner == undefined && this.mealplan.mealplan.lunch == undefined && this.mealplan.mealplan.snacks == undefined && this.mealplan.mealplan.breakfast == undefined) {
              this.day.macros = {};
              this.day.overall_carboHydrate_recommended = 0;
              this.day.overall_calories_recommended = 0;
              this.day.overall_fat_recommended = 0;
              this.day.overall_fibre_recommended = 0;
              this.day.overall_protein_recommended = 0;
              const data = [0, 0, 0];
              this.pieChartData = data;
            } else {
              const data = [Math.round(this.day.overall_protein_recommended), Math.round(this.day.overall_carboHydrate_recommended), Math.round(this.day.overall_fat_recommended)];
              this.pieChartData = data;
            }

          } else {
            this.mealplan.mealplan = {};
            this.mealplan.mealplan.breakfast = JSON.parse(JSON.stringify(this.empTy));
            this.mealplan.mealplan.lunch = JSON.parse(JSON.stringify(this.empTy));
            this.mealplan.mealplan.snacks = JSON.parse(JSON.stringify(this.empTy));
            this.mealplan.mealplan.dinner = JSON.parse(JSON.stringify(this.empTy));
            this.day = this.mealplan.mealplan;
            this.day.macros = {};
            this.day.overall_carboHydrate_recommended = 0;
            this.day.overall_calories_recommended = 0;
            this.day.overall_fat_recommended = 0;
            this.day.overall_fibre_recommended = 0;
            this.day.overall_protein_recommended = 0;
            const data = [0, 0, 0];
            this.pieChartData = data;

          }
        } else {
          this.mealplan.mealplan = {};
          this.mealplan.mealplan.breakfast = JSON.parse(JSON.stringify(this.empTy));
          this.mealplan.mealplan.lunch = JSON.parse(JSON.stringify(this.empTy));
          this.mealplan.mealplan.snacks = JSON.parse(JSON.stringify(this.empTy));
          this.mealplan.mealplan.dinner = JSON.parse(JSON.stringify(this.empTy));
          this.day = this.mealplan.mealplan;
          this.day.macros = {};
          this.day.overall_carboHydrate_recommended = 0;
          this.day.overall_calories_recommended = 0;
          this.day.overall_fat_recommended = 0;
          this.day.overall_fibre_recommended = 0;
          this.day.overall_protein_recommended = 0;
          const data = [0, 0, 0];
          this.pieChartData = data;
        }
      },
      error => {
        console.log("An error has occurred while retriving profile data.");
        this.mealplan.mealplan.breakfast = JSON.parse(JSON.stringify(this.empTy));
        this.mealplan.mealplan.lunch = JSON.parse(JSON.stringify(this.empTy));
        this.mealplan.mealplan.snacks = JSON.parse(JSON.stringify(this.empTy));
        this.mealplan.mealplan.dinner = JSON.parse(JSON.stringify(this.empTy));
        this.day = this.mealplan.mealplan;
        this.day.macros = {};
        this.day.overall_carboHydrate_recommended = 0;
        this.day.overall_calories_recommended = 0;
        this.day.overall_fat_recommended = 0;
        this.day.overall_fibre_recommended = 0;
        this.day.overall_protein_recommended = 0;
        const data = [0, 0, 0];
        this.pieChartData = data;
      }
    )
    }else{
      
    this.yoga_db_service.getQueriedData("", index, queryParams).subscribe(
      response => {
        console.log('Data Retrived succesfully.', response);
        this.renderer.removeClass(document.body, 'modal-open');
        if (response.data != null) {
           if(response.data.trainer_status != 'published')
           this.isDataEditable = true;
          this.mealplan = response.data;
          if (this.mealplan.mealplan != null || this.mealplan.mealplan != undefined) {

            if (this.mealplan.mealplan.breakfast == undefined)
              this.mealplan.mealplan.breakfast = JSON.parse(JSON.stringify(this.empTy));
            if (this.mealplan.mealplan.lunch == undefined)
              this.mealplan.mealplan.lunch = JSON.parse(JSON.stringify(this.empTy));
            if (this.mealplan.mealplan.snacks == undefined)
              this.mealplan.mealplan.snacks = JSON.parse(JSON.stringify(this.empTy));
            if (this.mealplan.mealplan.dinner == undefined)
              this.mealplan.mealplan.dinner = JSON.parse(JSON.stringify(this.empTy));

            this.day = this.mealplan.mealplan;
            if (this.mealplan.mealplan.dinner == undefined && this.mealplan.mealplan.lunch == undefined && this.mealplan.mealplan.snacks == undefined && this.mealplan.mealplan.breakfast == undefined) {
              this.day.macros = {};
              this.day.overall_carboHydrate_recommended = 0;
              this.day.overall_calories_recommended = 0;
              this.day.overall_fat_recommended = 0;
              this.day.overall_fibre_recommended = 0;
              this.day.overall_protein_recommended = 0;
              const data = [0, 0, 0];
              this.pieChartData = data;
            } else {
              const data = [Math.round(this.day.overall_protein_recommended), Math.round(this.day.overall_carboHydrate_recommended), Math.round(this.day.overall_fat_recommended)];
              this.pieChartData = data;
            }

          } else {
            this.mealplan.mealplan = {};
            this.mealplan.mealplan.breakfast = JSON.parse(JSON.stringify(this.empTy));
            this.mealplan.mealplan.lunch = JSON.parse(JSON.stringify(this.empTy));
            this.mealplan.mealplan.snacks = JSON.parse(JSON.stringify(this.empTy));
            this.mealplan.mealplan.dinner = JSON.parse(JSON.stringify(this.empTy));
            this.day = this.mealplan.mealplan;
            this.day.macros = {};
            this.day.overall_carboHydrate_recommended = 0;
            this.day.overall_calories_recommended = 0;
            this.day.overall_fat_recommended = 0;
            this.day.overall_fibre_recommended = 0;
            this.day.overall_protein_recommended = 0;
            const data = [0, 0, 0];
            this.pieChartData = data;

          }
        } else {
          this.mealplan.mealplan = {};
          this.mealplan.mealplan.breakfast = JSON.parse(JSON.stringify(this.empTy));
          this.mealplan.mealplan.lunch = JSON.parse(JSON.stringify(this.empTy));
          this.mealplan.mealplan.snacks = JSON.parse(JSON.stringify(this.empTy));
          this.mealplan.mealplan.dinner = JSON.parse(JSON.stringify(this.empTy));
          this.day = this.mealplan.mealplan;
          this.day.macros = {};
          this.day.overall_carboHydrate_recommended = 0;
          this.day.overall_calories_recommended = 0;
          this.day.overall_fat_recommended = 0;
          this.day.overall_fibre_recommended = 0;
          this.day.overall_protein_recommended = 0;
          const data = [0, 0, 0];
          this.pieChartData = data;
        }
      },
      error => {
        console.log("An error has occurred while retriving profile data.");
        this.mealplan.mealplan.breakfast = JSON.parse(JSON.stringify(this.empTy));
        this.mealplan.mealplan.lunch = JSON.parse(JSON.stringify(this.empTy));
        this.mealplan.mealplan.snacks = JSON.parse(JSON.stringify(this.empTy));
        this.mealplan.mealplan.dinner = JSON.parse(JSON.stringify(this.empTy));
        this.day = this.mealplan.mealplan;
        this.day.macros = {};
        this.day.overall_carboHydrate_recommended = 0;
        this.day.overall_calories_recommended = 0;
        this.day.overall_fat_recommended = 0;
        this.day.overall_fibre_recommended = 0;
        this.day.overall_protein_recommended = 0;
        const data = [0, 0, 0];
        this.pieChartData = data;
      }
    )
    }
  }

  // getFormatedDate(cDate) {
  //   var d = "";
  //   if (cDate.getDate() < 10 || cDate.getMonth() < 10) {
  //     var date = "";
  //     var mon = "";
  //     if (cDate.getDate() < 10) {
  //       date = "0" + cDate.getDate();
  //     } else {
  //       date = "" + cDate.getDate();
  //     }
  //     console.log(cDate.getMonth());
  //     if (cDate.getMonth() < 9) {
  //       mon = "0" + (cDate.getMonth() + 1);
  //     } else {
  //       mon = "" + (cDate.getMonth() + 1);
  //     }
  //     d = date + "/" + mon + "/" + cDate.getFullYear();
  //   }

  //   return d;
  // }
  getFormatedDate(cDate) {
    var d = "";
    if (cDate.getDate() < 10 || cDate.getMonth() < 10) {
    var date = "";
    var mon = "";
    if (cDate.getDate() < 10) {
    date = "0" + cDate.getDate();
    } else {
    date = "" + cDate.getDate();
    }
    console.log(cDate.getMonth());
    if (cDate.getMonth() < 9) {
    mon = "0" + (cDate.getMonth() + 1);
    } else {
    mon = "" + (cDate.getMonth() + 1);
    }
    d = date + "/" + mon + "/" + cDate.getFullYear();
    }else{
      d = cDate.getDate() + "/" + (cDate.getMonth() + 1) + "/" + cDate.getFullYear();
    }

    return d;
    }


    // getNewFormatedDate(cDate) {
    //   var d = "";
    //   if (cDate.getDate() < 10 || cDate.getMonth() < 10) {
    //   var date = "";
    //   var mon = "";
    //   if (cDate.getDate() < 10) {
    //   date = "0" + cDate.getDate();
    //   } else {
    //   date = "" + cDate.getDate();
    //   }
    //   console.log(cDate.getMonth());
    //   if (cDate.getMonth() < 9) {
    //   mon = "0" + (cDate.getMonth() + 1);
    //   } else {
    //   mon = "" + (cDate.getMonth() + 1);
    //   }
    //   d =   cDate.getFullYear()+ "-" + mon + "-" + date;
    //   }else{
    //     d = cDate.getFullYear() + "-" + (cDate.getMonth() + 1) + "-" + cDate.getDate();
    //   }

    //   return d;
    //   }




  getNutrients(foodName, type) {
    this.showNutritionPopUp = true;
    var response = this.day[type]["foodItems"].find(y => y["foodName"] == foodName);
    this.selectedFood = response;
    this.selectedFood["units"] = this.selectedFood["units"][0]["name"];
    var d = Object.keys(response);
    this.labelNutrients = [];
    for (var i = 0; i < d.length; i++) {
      if (response[d[i]] != null && d[i] != "foodName" && d[i] != "fileId" && d[i] != "quantity" && d[i] != "id" && d[i] != "catagory1" && d[i] != "catagory2" && d[i] != "units") {
        var Obj = { "key": "", "value": "" };
        if (d[i] == "protein" || d[i] == "carboHydrate" || d[i] == "totalFibre" || d[i] == "totalFat" || d[i] == "calcium" || d[i] == "calories") {
          Obj.key = d[i];
          Obj.value = response[d[i]]["recommended"];
          this.labelNutrients.push(Obj);
        } else {
          Obj.key = d[i];
          Obj.value = response[d[i]];
          this.labelNutrients.push(Obj);
        }
      }
    }
  }


  getTraineedetails() {
    if(this.currentCategory == "Bodybuilder"){
    var index = '/program/trainee/';

    this.trainee = {};
    this.trainee["bmi_bmr"] = {};

    var queryparams = {
      "program_id":this.program_id,
      "trainee_id":this.trainee_id
    }
    this.yoga_db_service.getQueriedData("", index, queryparams).subscribe(
      response => {
        console.log('Data Retrived succesfully.', response);
        //this.program_id = response.data[0].program_id;
        this.trainee = response.data;
        if (this.trainee.traineeProfileImg != null)
          this.traineeImage = this.trainee.traineeProfileImg.profileImagePath;
        this.traineeDiet = this.trainee.food_preference.food_type[0];
        if (this.currentDate != undefined) {
          this.getMealPlanDataFromServer();
        }
        if (this.trainee["bmi_bmr"] == null) {
          this.trainee["bmi_bmr"] = {};
        }
      },
      error => {
        // alert("An error has occurred while retriving profile data.");
        this.toastr.error('', 'An error has occurred while retriving profile data', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      }
    )
    }else{
      var index = '/program/trainee/';
      var queryparams = {
          "program_id": this.program_id,
          "trainee_id": this.trainee_id
      }
      this.yoga_db_service.getQueriedData("", index, queryparams).subscribe(
          response => {
            console.log('Data Retrived succesfully.', response);
            //this.program_id = response.data[0].program_id;
            this.trainee = response.data;
            if (this.trainee.traineeProfileImg != null)
              this.traineeImage = this.trainee.traineeProfileImg.profileImagePath;
            this.traineeDiet = this.trainee.food_preference.food_type[0];
            if (this.currentDate != undefined) {
              this.getMealPlanDataFromServer();
            }
            if (this.trainee["bmi_bmr"] == null) {
              this.trainee["bmi_bmr"] = {};
            }
          },
          error => {
              // alert("An error has occurred while retriving profile data.");
              this.toastr.error('', 'An error has occurred while retriving trainee profile data', {
                  timeOut: 3000,
                  positionClass: 'toast-top-right', 
                  progressBar: true,
                  progressAnimation: 'increasing',
                  tapToDismiss: true,
              });
          }
      );
    }
  }
  onProfileView() {
    this.showVideoPopup = !this.showVideoPopup;
    this.getTraineedetails();
  }

  onSearch(type) {
    if (this.currentDate == undefined) {
      this.toastr.error('', 'Please select date to add meal.', {
        timeOut: 5000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return false;
    }
    //this.movieTitle = new FormControl();
    this.movieTitle.setValue('');
    this.currentMealType = type;
    this.showCalendarPopup = true;
    this.showNutrionSearch = true;
    this.showNutrionData = false;
    // this.meal_object.units = [];
    // this.meal_object.quantity = 0;
    this.previusUnitSelected = "grams";
    //this.meal_object.calories = 0;
  }
  getNutrientData(data, isBranded) {
    this.showCalendarPopup = true;
    this.showNutrionData = true;
    this.showNutrionSearch = false;
    this.isBranded = isBranded;
    if (isBranded) {
      this.nutritionixservice.getBrandedFromServer(data.nix_item_id).subscribe(
        response => {

          this.meal_object = response.foods[0];
          this.actualQuantity = this.meal_object.serving_qty;
          if (this.meal_object.alt_measures != null && this.meal_object.alt_measures != undefined)
            this.types = this.meal_object.alt_measures;
          else
            this.types = [];

          for (let i = 0; i < this.types.length; i++) {
            this.types[i]["id"] = i + 1;
          }
          if (this.types.length != 0)
            this.selectedUnits = Array.of(this.types.find(x => x["measure"] == this.meal_object.serving_unit));
          else {
            this.selectedUnits = Array.of({ id: 1, measure: this.meal_object.serving_unit });
            this.types = Array.of({ id: 1, measure: this.meal_object.serving_unit });
          }
        })
    } else {
      var query = {
        "query": data.food_name
      }
      this.nutritionixservice.getNutritionDataFromServer(query).subscribe(
        response => {
          this.meal_object = response.foods[0];
          if (this.meal_object.alt_measures != null && this.meal_object.alt_measures != undefined)
            this.types = this.meal_object.alt_measures;
          else
            this.types = [];

          for (let i = 0; i < this.types.length; i++) {
            this.types[i]["id"] = i + 1;
          }
          if (this.types.length != 0)
            this.selectedUnits = Array.of(this.types.find(x => x["measure"] == this.meal_object.serving_unit));
          else {
            this.selectedUnits = Array.of({ measure: this.meal_object.serving_unit });
            this.types = Array.of({ id: 1, measure: this.meal_object.serving_unit });
          }
        })
    }
  }
  getConvertedDate(dateToConvert: Date){
    var iMyDate = new IMyDate();
    iMyDate.day = dateToConvert.getDate();
    iMyDate.month = dateToConvert.getMonth() + 1;
    iMyDate.year = dateToConvert.getFullYear();
    var displayDate = new IMyDateModel();
    displayDate.jsdate = dateToConvert;
    displayDate.date = iMyDate;
    displayDate.formatted = this.datepipe.transform(dateToConvert, this.dateFormat);
    displayDate.epoc = dateToConvert.getTime() / 1000;
    return displayDate;
   }

   onDateChanged(event: IMyDateModel){
    this.currentDate = event;
    this.getMealPlanDataFromServer();
 }


  compareDates(date1, date2) {
    var d1 = this.datepipe.transform(date1, 'yyyy-MM-dd');
    var d2 = this.datepipe.transform(date2, 'yyyy-MM-dd')
    return d1 >= d2;
  }
  onItemSelect(type) {
    if (this.meal_object.serving_qty != undefined || this.meal_object.serving_qty != null) {
      var query = {};
      if (type == 'dropdown') {
        if (this.meal_object.alt_measures.length > 0) {
          var selectedUnit = this.meal_object.alt_measures.find(x => x["measure"] == this.selectedUnits[0]["measure"]);
        }
        query = {
          "query": selectedUnit["qty"] + " " + selectedUnit["measure"] + " " + this.meal_object.food_name
        }
      } else {

        query = {
          "query": this.meal_object.serving_qty + " " + this.selectedUnits[0]["measure"] + " " + this.meal_object.food_name
        }
      }
      if (this.isBranded == false) {
        this.nutritionixservice.getNutritionDataFromServer(query).subscribe(
          response => {
            this.meal_object = response.foods[0];
            if (this.meal_object.alt_measures != null && this.meal_object.alt_measures != undefined)
              this.types = this.meal_object.alt_measures;
            else
              this.types = [];

            for (let i = 0; i < this.types.length; i++) {
              this.types[i]["id"] = i + 1;
            }
            if (this.types.length != 0)
            if(this.selectedUnits != undefined){
              if(this.selectedUnits.length == 0)
              this.selectedUnits = Array.of(this.types.find(x => x["measure"].toLowerCase() == this.meal_object.serving_unit.toLowerCase()));
              else
                 this.meal_object.serving_unit = this.selectedUnits[0]["measure"];
            }
            else {
              this.selectedUnits = Array.of({ measure: this.meal_object.serving_unit });
              this.types = Array.of({ measure: this.meal_object.serving_unit });
            }
          }, error => {
            this.types = this.meal_object.alt_measures;
            for (let i = 0; i < this.types.length; i++) {
              this.types[i]["id"] = i + 1;
            }
            if (this.types.length != 0){
              if(this.selectedUnits != undefined){
                if(this.selectedUnits.length == 0)
                this.selectedUnits = Array.of(this.types.find(x => x["measure"].toLowerCase() == this.meal_object.serving_unit.toLowerCase()));
                 else
                 this.meal_object.serving_unit = this.selectedUnits[0]["measure"];
              }
            }
            else {
              this.selectedUnits = Array.of({ measure: this.meal_object.serving_unit });
              this.types = Array.of({ measure: this.meal_object.serving_unit });
            }
          })
      } else {
        if (type == "number") {
          this.meal_object.serving_weight_grams = parseFloat(((this.meal_object.serving_qty * this.meal_object.serving_weight_grams) / this.actualQuantity).toFixed(2));
          this.meal_object.nf_calories = parseFloat(((this.meal_object.serving_qty * this.meal_object.nf_calories) / this.actualQuantity).toFixed(2));
          this.meal_object.nf_total_fat = parseFloat(((this.meal_object.serving_qty * this.meal_object.nf_total_fat) / this.actualQuantity).toFixed(2));
          this.meal_object.nf_saturated_fat = parseFloat(((this.meal_object.serving_qty * this.meal_object.nf_saturated_fat) / this.actualQuantity).toFixed(2));
          this.meal_object.nf_cholesterol = parseFloat(((this.meal_object.serving_qty * this.meal_object.nf_cholesterol) / this.actualQuantity).toFixed(2));
          this.meal_object.nf_sodium = parseFloat(((this.meal_object.serving_qty * this.meal_object.nf_sodium) / this.actualQuantity).toFixed(2));
          this.meal_object.nf_total_carbohydrate = parseFloat(((this.meal_object.serving_qty * this.meal_object.nf_total_carbohydrate) / this.actualQuantity).toFixed(2));
          this.meal_object.nf_dietary_fiber = parseFloat(((this.meal_object.serving_qty * this.meal_object.nf_dietary_fiber) / this.actualQuantity).toFixed(2));
          this.meal_object.nf_sugars = parseFloat(((this.meal_object.serving_qty * this.meal_object.nf_sugars) / this.actualQuantity).toFixed(2));
          this.meal_object.nf_protein = parseFloat(((this.meal_object.serving_qty * this.meal_object.nf_protein) / this.actualQuantity).toFixed(2));
          this.meal_object.nf_potassium = parseFloat(((this.meal_object.serving_qty * this.meal_object.nf_potassium) / this.actualQuantity).toFixed(2));
        }
      }
    }
  }
  // nutriCal() {
  //   var currentUnit = this.meal_object.units[0].name;
  //   console.log(currentUnit)
  //   if (currentUnit != this.previusUnitSelected) {
  //     if (currentUnit == "oz" && this.meal_object.quantity > 0) {
  //       this.meal_object.quantity = this.meal_object.quantity * this.globals.oz;
  //       this.previusUnitSelected = currentUnit;
  //     }
  //     if (currentUnit == "grams" && this.meal_object.quantity > 0) {
  //       this.meal_object.quantity = this.meal_object.quantity / this.globals.oz;
  //       this.previusUnitSelected = currentUnit;
  //     }

  //   }
  // }
  onBack() {
    this.movieTitle.setValue('');
    this.showNutrionSearch = true;
    this.showNutrionData = false;
  }
  closePopup() {
    this.showVideoPopup = false;
    this.showNutritionPopUp = false;
    this.renderer.removeClass(document.body, 'modal-open');
  }
  closeSearchPopup() {
    this.showCalendarPopup = false;
    this.showNutrionSearch = false;
    this.showNutrionData = false;
    this.renderer.removeClass(document.body, 'modal-open');
  }
  public pieChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      position: 'top',
    },
    plugins: {
      // datalabels: {
      //   formatter: (value, ctx) => {
      //     const label = ctx.chart.data.labels[ctx.dataIndex];
      //     return label;
      //   },
      // },
      display: false,
    }
  };

  public pieChartLabels: Label[] = ['Protien', 'Carbs', 'Fat', 'Fiber'];
  public pieChartData: number[] = [Math.round(this.day.overall_protein_recommended), Math.round(this.day.overall_carboHydrate_recommended), Math.round(this.day.overall_fat_recommended)];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = false;

  public pieChartPlugins = [pluginDataLabels];
  public pieChartColors = [
    {
      backgroundColor: ['rgba(139,197,63,1)', 'rgba(255,179,0,1)', 'rgba(52,152,219,1)', 'rgba(129,86,0,1)'],
      color: "white",
    },
  ];

  // public doughnutChartLabels: Label[] = ['calories', 'Protien', 'Fat', 'Carbohydratees'];
  // public doughnutChartData: number[] = [Math.round(this.totalCalories), Math.round(this.totalProtiens), Math.round(this.totalFat), Math.round(this.totalCarbs)];
  // public doughnutChartType: ChartType = 'doughnut';
}
