import { Component, OnInit, Input, OnDestroy, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { UserLoginService } from "../service/user-login.service";
import { LoggedInCallback, CognitoUtil } from "../service/cognito.service";
import { AppGlobals } from '../app.globals';
import { DBService } from '../service/db.service';
import { ToastrService } from 'ngx-toastr';
import { Integer } from 'aws-sdk/clients/lambda';
import { AWSService } from '../service/aws.service';
// import { provideForRootGuard } from '@angular/router/src/router_module';
import { DatePipe } from '@angular/common';
import {IMyDpOptions} from 'mydatepicker';
import moment from 'moment';

import { error } from 'console';

export class program {
  trainerId: string;
  programId: string;
  programName: string;
  plans: Integer;
  weeks: Integer;
  programDuration: Integer;
  priceInRupees: number;
  currency: Object[];
  description: string;
  programImage: Object;
  status: string;
  adminStatus: string;
  startDate: string;
  packageType: Object;
  enrollmentDate: string;
  nutrition: Object;
  ProgramAddons: Object[];
  priceInDollars: number;
  endDate: string; sa
  enrollmentEndDate: string;
  programType: Object;
  createdDate: Date;
  lastUpdateOn: Date;
}

export class IMyDateModel {
  date: IMyDate;
  jsdate: Date;
  formatted: string;
  epoc: number;
}

export class IMyDate {
  year: number;
  month: number;
  day: number;
}


@Component({
  selector: 'app-addprogram',
  templateUrl: './addprogram.component.html',
  styleUrls: ['./addprogram.component.css']
})
export class AddprogramComponent implements OnInit, OnDestroy, LoggedInCallback {
  @Input() imgUrl: string = "assets/images/uploadimg.svg";
  currentCategory: string;
  currentGender: string;
  program: program;
  dropdownSettings_single: any = {};
  closeDropdownSelection = false;
  Nutritions: Object[];
  currency: Object[];
  packagetypes: Object[];
  programTypes: Object[];
  selectedPackage: Object[];
  SelectedNutrition: Object[];
  SelectedProgramType: Object[];
  SelectedCurrency: Object[];
  programEdit: boolean = false;
  showCalendarPopup: boolean = false;
  isCurrencyDisabled: boolean = false;
  isPremiumProgram: boolean = false;
  isNutritionShow:boolean = true;
  isIndian: boolean = false;
  country: string;
  timezone: string;
  dateFormat:string;

  // tax pop up
  taxpopup:boolean = false;
  programPrice:number = 0;
  servicetax_1o1:string = "";
  gst_tax:string="";
  grand_total:string="";
  grand_tax_total: number;
  display_currency:string="";
  tax_details = {
   "service_tax":0,
   gst_tax:0,
   min_price_in_rupees:0,
   min_price_in_dollers:0
  }


  // Google Var
  googleUserEmail: string;
  private loggedIn: boolean;
  private subcrptn_1: any;
  currentLogged: string;
  currentEmail: string;
  selectedFile: File;
  fileExtension: any;
  loaderHide: boolean = false;
  isImgUploaded: string = "NO";
  private sub: any;
  editTrue: boolean = true;
  isDisabled: boolean = false;
  isDurationDisabled: boolean = false;
  startDate: IMyDateModel;
  endDate: IMyDateModel
  enrollmentEndDate: IMyDateModel;
  enrollmentDate: IMyDateModel;

  programImage: any = {
    programImg: "",
    imgPath: "",
    imageVerfied: false,
    verifiedOn: Date,
    uploadedOn: Date
  }

  myDatePickerOptions: IMyDpOptions = {};

  constructor(private renderer: Renderer2, private awsService: AWSService, public router: Router, public route: ActivatedRoute,
    public userService: UserLoginService, private globals: AppGlobals, private db_service: DBService, private toastr: ToastrService
    , public cognitoUtil: CognitoUtil, public datepipe: DatePipe) {
    this.userService.isAuthenticated(this);
  }

  ngOnInit() {
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.program = new program();
    this.program.plans = 1;
    this.program.weeks = 1;
    this.program.programDuration = 1;
    this.sub = this.route.params.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.program.programId = params['programID'];
        this.programEdit = true;
        this.getProgramDataFromerver();
        //this.getProfileDataById();
      }
    });
    this.currentCategory = localStorage.getItem("ProfileCategory")
    this.currentGender = localStorage.getItem("ProfileGender");
    if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-build-male');
    }
    else if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-build-female');
    }
    else if (this.currentCategory == 'Yoga' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-yoga-male');
    }
    else if (this.currentCategory == 'Yoga' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-yoga-female');
    }
    else if (this.currentCategory == 'Zumba' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-zumba-male');
    }
    else if (this.currentCategory == 'Zumba' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-zumba-female');
    }

    this.userService.currentUserID.subscribe((val) => {
      if (val != '') {
        this.program.trainerId = val;
      }
    });
    this.dropdownSettings_single = {
      singleSelection: true,
      enableCheckAll: false,
      idField: 'id',
      textField: 'name',
      allowSearchFilter: false,
      itemsShowLimit: 1,
      closeDropDownOnSelection: this.closeDropdownSelection
    };
    this.Nutritions = [{ "id": "0", "name": "Yes" },
    { "id": "1", "name": "No" }]
    this.currency = [{ "id": "0", "name": "Dollar" },
    { "id": "1", "name": "Rupees" }]
    this.packagetypes = [{ "id": "0", "name": "Beginner" },
    { "id": "1", "name": "Intermediate" },
    { "id": "2", "name": "Advanced" }]
    this.programTypes = [{ "id": "1", "name": "Regular program" },
    { "id": "2", "name": "Premium program" }]
    if(this.timezone.includes("asia") || this.timezone.includes("Asia")){
      this.myDatePickerOptions = {
        todayBtnTxt: 'Today',
        dateFormat: 'dd-mm-yyyy',
        firstDayOfWeek: 'mo',
        sunHighlight: true,
        openSelectorOnInputClick: true,
        editableDateField: false,
        inline: false
      }
      this.dateFormat = "dd-MM-yyyy";
    }else{
      this.myDatePickerOptions = {
        todayBtnTxt: 'Today',
        dateFormat: 'mm-dd-yyyy',
        firstDayOfWeek: 'mo',
        sunHighlight: true,
        openSelectorOnInputClick: true,
        editableDateField: false,
        inline: false
      }
      this.dateFormat = "MM-dd-yyyy";
    }
    this.getMasterConfigurations();
  }
  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'bg-img-build-male');
    this.renderer.removeClass(document.body, 'bg-img-yoga-male');
    this.renderer.removeClass(document.body, 'bg-img-zumba-male');
    this.renderer.removeClass(document.body, 'bg-img-build-female');
    this.renderer.removeClass(document.body, 'bg-img-yoga-female');
    this.renderer.removeClass(document.body, 'bg-img-zumba-female');
    this.renderer.addClass(document.body, 'body-main');
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/login']);
    } else {
      // if()
      if (this.loggedIn == true) {
        this.currentEmail = localStorage.getItem('lastAuthUser');
        // if(this.currentEmail != undefined)
        this.getProfileDataFromServer();
      }
      else {
        // this.currentEmail = localStorage.getItem('CognitoIdentityServiceProvider.2io8v4r253hugt5ndbjv9shkfp.LastAuthUser');
        this.currentEmail = this.cognitoUtil.getCurrentUser().getUsername();
        if(this.currentEmail == undefined || this.currentEmail == '' || this.currentEmail == null || this.currentEmail.includes("Google") || this.currentEmail.includes("google"))
        {
          this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
          if(this.currentEmail == undefined)
          this.currentEmail = this.cognitoUtil.session.idToken.payload.email;
        }
        // this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
        this.getProfileDataFromServer();
      }
    }
  }
  getProgramDataFromerver() {
    var index = '/program/';
    this.db_service.getDataFromServer(this.program.programId, index).subscribe(
      response => {
        this.getProfileDataById();
        this.program = response.data;
        //  this.SelectedCurrency = this.program.currency;
        this.selectedPackage = Array.of(this.program.packageType);
        this.SelectedNutrition = Array.of(this.program.nutrition);
        this.programImage = this.program.programImage;
        this.imgUrl = this.programImage.imgPath;
        this.SelectedProgramType = Array.of(this.program.programType);



        var d = new Date(this.program.startDate);
        // this.startDate = d;
       // this.startDate = this.datepipe.transform(d, 'yyyy-mm-dd');
       this.startDate = this.getConvertedDate(d);
        //console.log(this.startDate);

        var d2 = new Date(this.program.enrollmentDate);
        //this.enrollmentDate = this.datepipe.transform(d2, 'yyyy-mm-dd');
        this.enrollmentDate = this.getConvertedDate(d2);

        if (this.SelectedProgramType[0]["name"] == "Premium program") {
          this.isPremiumProgram = true;

          var d3 = new Date(this.program.endDate);
          //this.endDate = this.datepipe.transform(d3, 'yyyy-mm-dd');
          this.endDate = this.getConvertedDate(d3);

          if (this.program.enrollmentEndDate != null) {
            var d4 = new Date(this.program.enrollmentEndDate);
            //this.enrollmentEndDate = this.datepipe.transform(d4, 'yyyy-mm-dd');
            this.enrollmentEndDate =  this.getConvertedDate(d4);
          }
        }

        if (this.program.status == "New") {
          var index = '/calendar/';
          this.db_service.getDataFromServer(this.program.programId, index).subscribe(
            response => {
              var dayCount = 0;
              if (response.data != "") {
                for (let i = 0; i < response.data.weeks.length; i++) {
                  dayCount = dayCount + response.data.weeks[i]["days"].length;
                }
              }
              if (dayCount > 0) {
                this.isDurationDisabled = true;
              } else {
                this.isDurationDisabled = false;
              }

            },
            error => {
              this.toastr.error('', 'An error has occurred getting program data.', {
                timeOut: 5000,
                extendedTimeOut: 1000,
                positionClass: 'toast-top-right',
                progressBar: true,
                progressAnimation: 'increasing',
                tapToDismiss: true
              });
            }
          )
        }


        if (this.program.status != '' && this.program.status != null && this.program.status != "New" && this.program.status != "Rejected") {
          this.editTrue = false;
          this.isDisabled = true;
          this.isDurationDisabled = true;
        } else {
          this.editTrue = true;
          this.isDisabled = false;

        }

      },
      error => {
        // alert("An error has occurred while saving data.");
        this.toastr.error('', 'An error has occurred getting my program data.', {
          timeOut: 5000,
          extendedTimeOut: 1000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true
        });
      }
    )

  }

  getMasterConfigurations(){
   var index = "/masters/configuration/";
   this.db_service.getDataFromServer('1o1_service_tax', index).subscribe(
     response=>{
        this.tax_details = response.values;
     },
    error=> {
        console.log("error " + error);
    }
   )
  }

  getConvertedDate(dateToConvert: Date){
   var iMyDate = new IMyDate();
   iMyDate.day = dateToConvert.getDate();
   iMyDate.month = dateToConvert.getMonth() + 1;
   iMyDate.year = dateToConvert.getFullYear();
   var displayDate = new IMyDateModel();
   displayDate.jsdate = dateToConvert;
   displayDate.date = iMyDate;
   displayDate.formatted = this.datepipe.transform(dateToConvert, this.dateFormat);
   displayDate.epoc = dateToConvert.getTime() / 1000;
   return displayDate;
  }
  getProfileDataById() {
    var index = '/profile/';
    this.db_service.getDataFromServer(this.program.trainerId, index).subscribe(
      response => {
        response = response.data[0];
        this.country = response.address[0].country;
        // this.country = "USA";
        if (this.country.toLowerCase().includes("india") || this.country.toLowerCase().includes("in") ) {
          // this.isCurrencyDisabled = true;
          this.isIndian = true;
        }
        else {
          // this.isCurrencyDisabled = false;
          this.isIndian = false;
        }

        if(response.isNutritionist != undefined){
          if(response.isNutritionist.status == "Yes"){
            this.isNutritionShow = true;
          }else{
            this.isNutritionShow = false;
          }
        }else{
          this.isNutritionShow = false;
        }
      },
      error => {
        console.log("An error has occurred while retriving profile data.");
      }
    )
  }

  onDateChanged(event: IMyDateModel){
     this.onDateChange(event.jsdate);
  }
  onDateChange(date) {
      var new_date = moment(date, "DD-MM-YYYY").add('days', (this.program.programDuration * 7) - 1);
      var r_date = new_date.format('YYYY') + "-" + new_date.format('MM') + "-" + new_date.format('DD');
      this.endDate = this.getConvertedDate(new Date(r_date));
  }

calculate1o1Percentage(currency){

  // let priceRegex = new RegExp('^\d{1,6}(\.\d{1,2})?$');
  if(this.program.priceInRupees != undefined)
  var rupeeString = this.program.priceInRupees.toString();
  if(this.program.priceInDollars != undefined)
  var dollarString = this.program.priceInDollars.toString();
  var priceRegex = new RegExp('^[0-9]{1,6}(?:[.][0-9]{0,2})?$')

    if(currency == 'Rupees'){
      if(priceRegex.test(rupeeString) === false){
        this.toastr.error('', 'Program Price in rupees cannot exceeed 6 digits with 2 decimals.', {
          timeOut: 5000,
          extendedTimeOut: 1000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true
        });
      }
      else{
        this.taxpopup = true;
        this.programPrice = this.program.priceInRupees;
        this.display_currency = '₹';
        this.gst_tax = ((this.programPrice * this.tax_details.gst_tax)/100).toFixed(2);
        this.grand_total = (this.programPrice + ((this.programPrice * this.tax_details.gst_tax)/100) - ((this.programPrice * this.tax_details.service_tax)/100)).toFixed(2);
        this.grand_tax_total = this.programPrice + Number(this.gst_tax);
      }
    }
    else{
      if(priceRegex.test(dollarString) === false){
        this.toastr.error('', 'Program Price in dollars cannot exceeed 6 digits with 2 decimals.', {
          timeOut: 5000,
          extendedTimeOut: 1000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true
        });
      }
      else{
        this.taxpopup = true;
        this.programPrice = this.program.priceInDollars;
        this.display_currency = '$';
        this.grand_total = (this.programPrice - ((this.programPrice * this.tax_details.service_tax)/100)).toFixed(2);
        this.grand_tax_total = this.programPrice;
      }
    }
    this.servicetax_1o1 = ((this.programPrice * this.tax_details.service_tax)/100).toFixed(2);
  // }
}


  getNewFormatedDate(cDate) {
    var d = "";
    if (cDate.getDate() < 10 || cDate.getMonth() < 10) {
    var date = "";
    var mon = "";
    if (cDate.getDate() < 10) {
    date = "0" + cDate.getDate();
    } else {
    date = "" + cDate.getDate();
    }
    console.log(cDate.getMonth());
    if (cDate.getMonth() < 9) {
    mon = "0" + (cDate.getMonth() + 1);
    } else {
    mon = "" + (cDate.getMonth() + 1);
    }
    d =   cDate.getFullYear()+ "-" + mon + "-" + date;
    }else{
      d = cDate.getFullYear() + "-" + (cDate.getMonth() + 1) + "-" + cDate.getDate();
    }

    return d;
    }
  getProfileDataFromServer() {
    var index = '/profile/';
    this.db_service.getDataFromServer(this.currentEmail, index).subscribe(
      response => {
        //console.log('Data Retrived succesfully.', response);
        response = response.data[0];
        this.program.trainerId = response.trainerId;
        this.country = response.address[0].country;
        // this.country = "USA";
        if (this.country.toLowerCase().includes("india")) {
          //  this.isCurrencyDisabled = true;
          this.isIndian = true;
        }
        else {
          // this.isCurrencyDisabled = false;
          this.isIndian = false;
        }
        if(response.isNutritionist != undefined){
          if(response.isNutritionist.status == "Yes"){
            this.isNutritionShow = true;
          }else{
            this.isNutritionShow = false;
          }
        }else{
          this.isNutritionShow = false;
        }
      },
      error => {
        console.log("An error has occurred while retriving profile data.");
      }
    )
  }
  calculate() {
    if (this.program.plans != undefined && this.program.plans != null && this.program.weeks != undefined && this.program.weeks != null) {
      if (this.program.programId != undefined) {
        var index = '/calendar/';
        this.db_service.getDataFromServer(this.program.programId, index).subscribe(
          response => {
            var dayCount = 0;
            if (response.data != "") {
              for (let i = 0; i < response.data.weeks.length; i++) {
                dayCount = dayCount + response.data.weeks[i]["days"].length;
              }
            }
            if (dayCount > 0) {
              this.toastr.error('', 'Calender dependency exist you cannot edit duration.', {
                timeOut: 5000,
                extendedTimeOut: 1000,
                positionClass: 'toast-top-right',
                progressBar: true,
                progressAnimation: 'increasing',
                tapToDismiss: true
              });
            } else {
              this.program.programDuration = this.program.plans * this.program.weeks;
            }
          },
          error => {
            this.toastr.error('', 'An error has occurred getting program data.', {
              timeOut: 5000,
              extendedTimeOut: 1000,
              positionClass: 'toast-top-right',
              progressBar: true,
              progressAnimation: 'increasing',
              tapToDismiss: true
            });
          }
        )

      } else
        this.program.programDuration = this.program.plans * this.program.weeks;
      if (this.startDate != undefined) {
         this.onDateChange(this.startDate.jsdate);
      }
    }
  }
  onItemSelect() {
    if (this.SelectedProgramType[0]["name"] != "Regular program") {
      this.isPremiumProgram = true;
    } else {
      this.isPremiumProgram = false;
    }
  }
  onItemDeselect() {
    if (this.SelectedProgramType.length == 0) {
      this.isPremiumProgram = false;
    }
  }
  saveProgram() {
    var atleastOneAlphaPName;
    var atleastOneAlphaPDesc;
    var testStartDate;
    var testEnrollDate;
    var testEnrollEndDate;
    var Difference_In_Days;
    var checkDate = moment(this.datepipe.transform(new Date(), 'yyyy-MM-dd')).format('YYYY-MM-DD');
    var priceRegex = new RegExp('^[0-9]{1,6}(?:[.][0-9]{0,2})?$')

    if (this.program.programName != undefined) {
      atleastOneAlphaPName = this.program.programName.match(".*[a-zA-Z]+.*");
    }
    if (this.program.description != undefined) {
      atleastOneAlphaPDesc = this.program.description.match(".*[a-zA-Z]+.*");
    }
    if (this.startDate != undefined && this.enrollmentDate != undefined) {

       if(checkDate >  moment(this.datepipe.transform(this.startDate.jsdate, 'yyyy-MM-dd')).format('YYYY-MM-DD')){
        this.toastr.error('', 'Program start date sholud be future date.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        return;
       }
       if(checkDate >  moment(this.datepipe.transform(this.enrollmentDate.jsdate, 'yyyy-MM-dd')).format('YYYY-MM-DD')){
        this.toastr.error('', 'Program enrollment start date should be current or future date.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        return;
      }


      testStartDate = this.startDate.jsdate;
      testEnrollDate = this.enrollmentDate.jsdate;
      if (this.SelectedProgramType[0]["name"] == "Premium program" && this.enrollmentEndDate != undefined){
        testEnrollEndDate = this.enrollmentEndDate.jsdate;
        if(checkDate >  moment(this.datepipe.transform(testEnrollEndDate, 'yyyy-MM-dd')).format('YYYY-MM-DD')){
          this.toastr.error('', 'Program enrollment end date sholud be future date.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
          return;
        }
      }
      var Difference_In_Time = testStartDate.getTime() - testEnrollDate.getTime();

      // To calculate the no. of days between two dates
      Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      this.onDateChange(testStartDate);
    }

    if (this.program.programName == null || this.program.programName == '' || this.program.plans == null || this.program.plans == 0 || this.program.weeks == null || this.program.weeks == 0) {
      this.toastr.error('', 'Mandatory fields are required.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    } else if (atleastOneAlphaPName == null) {
      this.toastr.error('', 'Program name should contain atleast one character.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    } else if (atleastOneAlphaPDesc == null) {
      this.toastr.error('', 'Please enter valid description.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (this.SelectedProgramType == undefined) {
      this.toastr.error('', 'Please select program type.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (this.SelectedNutrition == undefined && this.isNutritionShow) {
      this.toastr.error('', 'Please select nutrition.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    } else if (this.selectedPackage == undefined) {
      this.toastr.error('', 'Please select package type.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });

    }

    else if (this.startDate == undefined) {
      this.toastr.error('', 'Please select program start date.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (this.enrollmentDate == undefined) {
      this.toastr.error('', 'Please select enrollment start date.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    } else if (this.program.plans < 1) {
      this.toastr.error('', 'Minimum number of plans should be one.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (this.program.plans > 4) {
      this.toastr.error('', 'Maximum number of plans should be four.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    } else if (this.program.weeks < 1) {
      this.toastr.error('', 'Minimum number of weeks should be one.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (this.program.weeks > 4) {
      this.toastr.error('', 'Maximum number of weeks should be four.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    } else if (this.program.programDuration > 16) {
      this.toastr.error('', 'Program duration should not exceed 16 weeks.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (testEnrollDate >= testStartDate) {
      this.toastr.error('', 'Start date should be future date of enrollment date.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }  else if (this.SelectedProgramType[0]["name"] != "Premium program" && (Difference_In_Days < 6 || Difference_In_Days > 34)) {
      this.toastr.error('', 'Program start date and enrollment start date should have minimum gap of 1 week and maximum one month.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    } else if (this.country.toLowerCase().includes("india") && this.program.priceInDollars == undefined) {
      this.toastr.error('', 'Please enter price in dollars.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    } else if (this.country.toLowerCase().includes("india") &&  this.program.priceInRupees == undefined) {
      this.toastr.error('', 'Please enter price in rupees.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    else if (this.program.priceInDollars == undefined) {
      this.toastr.error('', 'Please enter price in dollars.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
   else if (this.SelectedProgramType[0]["name"] == "Premium program" && this.enrollmentEndDate == undefined) {

      this.toastr.error('', 'Please select enrollment end date.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return;

  }
    else {

      if(this.country.toLowerCase().includes("india") && this.program.priceInRupees < this.tax_details.min_price_in_rupees){
        this.toastr.error('', 'Program price should be minimum ' + this.tax_details.min_price_in_rupees + ' rupees.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        return;
      }

      if(this.program.priceInRupees != null && priceRegex.test(this.program.priceInRupees.toString()) === false){
        this.toastr.error('', 'Program Price in rupees cannot exceeed 6 digits with 2 decimals.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        return;
      }

      if(priceRegex.test(this.program.priceInDollars.toString()) === false){
        this.toastr.error('', 'Program Price in dollars cannot exceeed 6 digits with 2 decimals.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        return;
      }

      if(this.program.priceInDollars < this.tax_details.min_price_in_dollers){
        this.toastr.error('', 'Program price should be minimum ' + this.tax_details.min_price_in_dollers + ' dollars.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        return;
      }


      if(this.SelectedProgramType[0]["name"] == "Premium program" && testEnrollEndDate != undefined){

      if(testEnrollEndDate <= testEnrollDate){
        this.toastr.error('', 'Enrollment end date should be future date of enrollment date.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        return;
      }

      var Difference_In_Time = 0;
      var Diff_In_Days = 0;
      Difference_In_Time =  testEnrollEndDate.getTime() - testEnrollDate.getTime();
      Diff_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      if(Diff_In_Days < 7 || Diff_In_Days > 27){
      this.toastr.error('', 'Enrollment end date and enrollment start date should have minimum gap of 1 week and maximum 4 weeks.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return;
    }
    Difference_In_Time =  testStartDate.getTime() - testEnrollEndDate.getTime();
    Diff_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    if(Diff_In_Days != 7){
      this.toastr.error('', 'Enrollment end date and program start date should have gap of 1 week.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return;
    }
  }
      // if (this.SelectedCurrency.length == 0) {
      //   this.toastr.error('', 'Please select currency type.', {
      //     timeOut: 3000,
      //     positionClass: 'toast-top-right',
      //     progressBar: true,
      //     progressAnimation: 'increasing',
      //     tapToDismiss: true,
      //   });
      // }
      if(this.SelectedNutrition != undefined && this.isNutritionShow){
      if (this.SelectedNutrition.length == 0) {
        this.toastr.error('', 'Please select nutrition.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      }
    }

      if (this.selectedPackage.length == 0) {
        this.toastr.error('', 'Please select package type.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      }
      if (this.SelectedProgramType.length == 0 && this.isNutritionShow) {
        this.toastr.error('', 'Please select nutrition.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      }



      if (this.programImage.imgPath != "") {
        this.program.createdDate = new Date();
        this.program.lastUpdateOn = new Date();
        if(this.SelectedNutrition != undefined && this.isNutritionShow)
        this.program.nutrition = this.SelectedNutrition[0];
        //  this.program.currency = this.SelectedCurrency;
        this.program.packageType = this.selectedPackage[0];
        this.program.programImage = this.programImage;
        this.program.startDate = this.datepipe.transform(testStartDate, 'yyyy-MM-dd');
        this.program.enrollmentDate = this.datepipe.transform(testEnrollDate, 'yyyy-MM-dd');
        this.program.endDate = this.datepipe.transform(this.endDate.jsdate, 'yyyy-MM-dd');;
        this.program.programType = this.SelectedProgramType[0];
        this.program.enrollmentEndDate = this.datepipe.transform(testEnrollEndDate, 'yyyy-MM-dd');
        this.program.currency = [];
        if (this.program.priceInDollars != undefined) {
          this.program.currency.push(this.currency[0]);
        }
        if (this.program.priceInRupees != undefined) {
          this.program.currency.push(this.currency[1]);
        }
        this.program.status = 'New';
        // this.program.status = 'new';
        if (this.program.programId == undefined) {
          var index = '/program';
          this.db_service.saveDataToDB(this.program, index).subscribe(
            response => {
              this.program.programId = response.data;
              if (this.programEdit == false) {
                this.showCalendarPopup = true;
              }
              this.toastr.success('', 'Program has been saved successfully', {
                timeOut: 3000,
                positionClass: 'toast-top-right',
                progressBar: true,
                progressAnimation: 'increasing',
                tapToDismiss: true,
              });
              // this.router.navigate(['/program']);
            },
            error => {
              // alert('there is an error while saving data');
              this.toastr.error('', 'There is an error while saving Program', {
                timeOut: 3000,
                positionClass: 'toast-top-right',
                progressBar: true,
                progressAnimation: 'increasing',
                tapToDismiss: true,
              });
            }
          )
        } else {
          var index = '/program';
          this.db_service.updateDatainDB(this.program, index).subscribe(
            response => {
              if (this.programEdit == false) {
                this.showCalendarPopup = true;
              }
              this.toastr.success('', 'Program has been saved successfully', {
                timeOut: 3000,
                positionClass: 'toast-top-right',
                progressBar: true,
                progressAnimation: 'increasing',
                tapToDismiss: true,
              });
              this.router.navigate(['/program']);
            },
            error => {
              // alert('there is an error while saving data');
              this.toastr.error('', 'There is an error while saving Program', {
                timeOut: 3000,
                positionClass: 'toast-top-right',
                progressBar: true,
                progressAnimation: 'increasing',
                tapToDismiss: true,
              });
            }
          )
        }
      } else {
        this.toastr.error('', 'Please upload image for program', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      }
    }

  }
  isInArray(array, word) {
    return array.indexOf(word.toLowerCase()) > -1;
  }
  selectFile(event, filetype) {
    this.selectedFile = event.target.files[0];

    this.selectedFile = event.target.files[0];
    if(this.selectedFile.name.includes("+")){
      this.toastr.error('', "No special characters allowed like '+'.", {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return;
    }
    //var format = /[ !@#$%^&*()_+\=\[\]{};':"\\|,<>\/?]/;
    // var fileCheck = format.test(this.selectedFile.name);
    this.fileExtension = this.selectedFile.name.split('.').pop();
    // var allowedExtensions = ["jpg","jpeg","png","JPG","JPEG","JFIF","BMP","SVG"];
    // if (fileCheck == true) {
    //   this.toastr.error('', 'Special charecters are not allowed in image file name.', {
    //     timeOut: 3000,
    //     positionClass: 'toast-top-right',
    //     progressBar: true,
    //     progressAnimation: 'increasing',
    //     tapToDismiss: true,
    //   });
    // } else {
    if (this.isInArray(this.globals.ImgallowedExtensions, this.fileExtension)) {
      if (this.program.programImage != undefined) {
        var imageName = this.program.programImage["programImg"].replace(/ /g, "_");
        this.awsService.deleteFileFromS3(imageName, this.program.trainerId, this.globals.DEST_BUCKET, this.globals.PROGIMGFOLDER).subscribe(
          response => {
            this.fileUpload(filetype);
            //console.log('Image deleted successfully.', response);

          },
          error => {
            console.log('There is an error while deleting image.', error);

          })
      } else {
        this.fileUpload(filetype);
      }
    } else {
      this.toastr.error('', 'Please upload only images.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
    }
    // }
  }
  fileUpload(filetype) {
    this.loaderHide = !this.loaderHide;

    if (filetype == 'image') {
      this.programImage.programImg = this.selectedFile.name;
      this.awsService.uploadFileToS3(this.selectedFile, this.program.trainerId, this.globals.DEST_BUCKET, this.globals.PROGIMGFOLDER, filetype).subscribe(
        response => {
          this.programImage.imgPath = this.globals.CLOUDFRONT_URL + response.Key;
          this.imgUrl = this.programImage.imgPath;
          this.loaderHide = !this.loaderHide;
          //console.log('Image uploaded successfully.', response);
          this.isImgUploaded = "YES";
          this.toastr.success('', 'Image uploaded successfully.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        },
        error => {
          console.log('There is an error uploading image.', error);
          // console.log('Image uploaded successfully.', response);
          this.loaderHide = !this.loaderHide;
          this.toastr.error('', 'There is an error uploading image.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        })
    }
  }
  Cancel() {
    this.router.navigate(['/program']);
  }
  onCalendarYes() {
    this.router.navigate(['/calendar/' + this.program.programId]);
    //this.router.navigate(['/program']);
    this.showCalendarPopup = false;
  }
  onCalendarNo() {
    this.router.navigate(['/program']);
    this.showCalendarPopup = false;
  }
  closePopup() {
    this.showCalendarPopup = false;
    this.taxpopup = false;
  }
}
