import { Injectable } from '@angular/core';
import { Observable, of } from "rxjs";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Nutrition } from '../common/nutrition';
import { AppGlobals } from "../app.globals";


@Injectable()
export class NutritionixService {
    private _reqOptionsArgs = { headers: new HttpHeaders().set('x-app-id', this.globals.XAPPID).set('x-app-key', this.globals.XAPPKEY).set('Content-Type', 'application/json') };
    constructor(private http: HttpClient, private globals: AppGlobals) { }

    public findMeal(serachFood: string): Observable<Nutrition> {
        return this.http.get<Nutrition>(this.globals.SEARCHURL + '?query=' + serachFood, this._reqOptionsArgs).pipe(catchError(error => {
            //  console.error( JSON.stringify( error ) );
            return Observable.throw(error);
        }));
    }
    getNutritionDataFromServer(data): Observable<any> {
        return this.http.post(this.globals.GETNUTRITIONURL, data, this._reqOptionsArgs);
    }
    getBrandedFromServer(index): Observable<any> {
        return this.http.get(this.globals.SEARCHBRANDEDURL + index, this._reqOptionsArgs);
    }

}