<!-- <div id="mydiv" *ngIf="loaderHide">
  <img src="assets/images/landing_img.gif" width="10%" class="ajax-loader" />
</div> -->
<div id="mydiv" *ngIf="loaderHide && this.currentCategory == 'Bodybuilder'">
  <img src="assets/images/loaders/loaderexe.gif" width="10%" class="ajax-loader" />
</div>
<div id="mydiv" *ngIf="loaderHide && this.currentCategory == 'Yoga'">
  <img src="assets/images/loaders/loaderyoga.gif" width="10%" class="ajax-loader" />
</div>
<div id="mydiv" *ngIf="loaderHide && this.currentCategory == 'Zumba'">
  <img src="assets/images/loaders/loaderjumba.gif" width="10%" class="ajax-loader" />
</div>
<section>
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-lg-9 order-last order-md-1 exercise-left-div">
        <div class="exercise-content add-video">
          <div class="main-heading text-content py-2"><i class="fas fa-play"></i>
            <span *ngIf="exercise.exerciseId == undefined">Add Video</span>
            <div *ngIf="exercise.exerciseId != undefined">{{exercise.exerciseName}}</div>
          </div>

          <div class="exercise-upload-video">

            <div *ngIf="drgDropHidden" (drop)="onDrop($event,'video')" (dragover)="onDragOver($event)" 
              style="height: 350px; position: relative;">
              <input #fileInput type="file"
                style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; opacity: 0; cursor: pointer;"
                (change)="onFileSelected($event,'video')">
              <ng-container *ngTemplateOutlet="customDropzone" (click)="fileInput.click()"></ng-container>
            
            
              <ng-template #customDropzone>
                <div class="text-center border" style="background: rgba(0, 0, 0, 0.42); border-radius: 20px; height: 350px;">
                  <br /><br />
                  <h4><b style="color: rgb(173, 255, 47);"><span class="exer-word-style-a">Drag and drop</span><span
                        class="exer-word-style-b">Click here to add</span></b> a media file to begin the upload </h4>
                  <h4 style="color: rgb(255, 140, 0);">Maximum file size is 35 Mb</h4>
                  <br /><br /><br /><br /><br /><br /><br /><br />
                </div>
              </ng-template>
            </div>

            
            <!-- <progress-bar [progress]="100" [color]="'#488aff'">
              </progress-bar> -->

            <div (click)="closePopup()" class="close-video-pop" *ngIf="videoHidden">
              <!-- <i class="fas fa-times" style="position: absolute;margin-left: 752px;"></i> -->
              <video playsinline="" loop="" controls muted="" class="videoInsert">
                <source src="{{ exerciseVideo.exerciseVideoSource }}" type="video/mp4">
                Your browser does not support the video tag.
              </video>
            </div>
            <div class="exercise-upload-video mt-3" style="position: relative;" [innerHtml]="embedYoutubeUrl" *ngIf="iframeHidden">
            </div>
            <div class="dummy fas fa-close" style="position: absolute; top: 10px; left: 96%; transform: translate(-10px,50px); color: red;font-size: 20px; cursor: pointer;" title="close" (click)="onVideoPopupClose()" *ngIf="videoHidden == true || iframeHidden == true">
            </div>
           
          </div>
          <form method="post" *ngIf="drgDropHidden">
            <div class="exercise_url-block d-flex align-items-center">
              <div style="color: black;font-weight: 700;"><i class="fab fa-youtube" style="color:red; font-size: 30px;"></i> <span style="display:none"> &nbsp;
                Youtube URL &nbsp;</span></div>
              <div style="width: 80%;"><input type="text" id="YoutubeUrl" name="YoutubeUrl" placeholder="Enter Youtube URL"  autocomplete="nope"
                  [(ngModel)]="exerciseVideo.youtubeUrl">
                <i (click)="changeYoutubeURL()" style="color:yellowgreen" class="fas fa-upload youtube-play-btn" title="Click to fetch the YouTube video"></i>
              </div>
              <!-- <div></div> -->
              <!--input type="file" id="fileInput" class="box__file"-->
            </div>
          </form>
        </div>

        <div class="exercise-content add-details">
          <h1 class="main-heading"><i class="far fa-address-card"></i> <span>Add Details</span></h1>

          <form class="exercise-add-details trainer-profile-form">
            <div class="form-group col-12 col-md-6">
              <input type="text" class="form-input-style col-12" id="exerciseName" name="exerciseName" autocomplete="nope"
                [(ngModel)]="exercise.exerciseName" #exerciseName="ngModel" minlength="1" maxlength="40"
                pattern="^[a-zA-Z -]+$" placeholder="Exercise Name*" style="height: calc(2.25rem + -6px);">
              <!-- <span class="floating-label">Exercise Name</span> -->
              <div *ngIf="exerciseName.invalid && (exerciseName.dirty || exerciseName.touched)"
                class="alert alert-danger">
                <!-- <div class="error_alert_msg error_alet_msg_show">
                  All fields are required.
                </div> -->
                <!-- <div *ngIf="exerciseName.errors?.required">
                  Exercise Name can't be blank
                </div> -->
                <div *ngIf="exerciseName.errors?.minlength">
                  Exercise Name should have Minimum 2 Character
                </div>
                <div *ngIf="exerciseName.errors?.maxlength">
                  Exercise Name should have Maximun 40 Character
                </div>
                <div *ngIf="exerciseName.errors?.pattern">
                  Exercise Name should only contain alphabets
                </div>
              </div>
            </div>
            <div class="form-group col-12 col-md-6">
         
              <ng-multiselect-dropdown class="form-dropdown-style col-12"
              [placeholder]="'Workout Type'" name="SelectedWorkoutType"
              [settings]="dropdownSettings_single"  [disabled]="disabled"
              [data]="WorkoutTypes"
              [(ngModel)]="SelectedWorkoutType"
              (onSelect)="onItemSelect($event,'workoutType')" (onDeSelect)="onItemDeselect($event,'workoutType')"
            >
            </ng-multiselect-dropdown>
            </div>
            <!-- <div class="form-group col-12 col-md-6" *ngIf="isWorkoutType">
              <input type="text" class="form-input-style col-12" id="otherWorkoutType" name="otherWorkoutType" [(ngModel)]="exercise.otherWorkoutType"
                required>
              <span class="floating-label">Please enter other workout type</span>

            </div> -->
            <div class="form-group col-12 col-md-6">
             
              <ng-multiselect-dropdown class="form-dropdown-style col-12"
              [placeholder]="'Mechanical Type'"  name="MechanicalTypes"
              [settings]="dropdownSettings_single"  [disabled]="disabled"
              [data]="MechanicalTypes"
              [(ngModel)]="SelectedMechanicalType"
              (onSelect)="onItemSelect($event,'mechanicalType')" (onDeSelect)="onItemDeselect($event,'workoutType')"
            >
            </ng-multiselect-dropdown>
            </div>
            <div class="form-group col-12 col-md-6" *ngIf="isMechanicalType">
              <input type="text" class="form-input-style col-12" id="otherMechanicalType" name="otherMechanicalType"
                [(ngModel)]="exercise.otherMechanicalType" required placeholder="Please enter other mechanical type" style="height: calc(2.25rem + -6px);" >
            </div>
            <div class="form-group col-12 col-md-6">
             
              <ng-multiselect-dropdown  class="form-dropdown-style col-12"
              [placeholder]="'Main Muscle Workout*'"  name="MainMuscleWorkouts" 
              [settings]="dropdownSettings_single"
              [data]="MainMuscleWorkouts"
              [(ngModel)]="SelectedMainMuscleWorkout"
              (onSelect)="onItemSelect($event,'mainMuscleWorkout')"
              (onDeSelect)="onItemDeselect($event,'workoutType')" [placeholder]="'Main Muscle Workout*'"  [disabled]="editDisabled"
            >
            </ng-multiselect-dropdown>
            </div>
            <div class="form-group col-12 col-md-6" *ngIf="isMainMuscleWorkout">
              <input type="text" class="form-dropdown-style col-12" id="otherMainMuscleWokout" name="otherMainMuscleWokout"
                [(ngModel)]="exercise.otherMainMuscleWokout" required>
              <span class="floating-label">Please enter other main muscle workout</span>
            </div>
            <div class="form-group col-12 col-md-6">
              
              <ng-multiselect-dropdown class="form-dropdown-style col-12"
              [placeholder]="'Other Muscle Workouts'" name="OtherMuscleWorkouts"
              [settings]="dropdownSettings"
              [data]="OtherMuscleWorkouts"
              [(ngModel)]="SelectedOtherMuscleWorkouts"
              [disabled]="disabled"
            >
            </ng-multiselect-dropdown>
            </div>
            <div class="form-group col-12 col-md-6">
             
              <ng-multiselect-dropdown class="form-dropdown-style col-12" 
              [placeholder]="'Level'" name="Levels"
              [settings]="dropdownSettings_single"
              [data]="Levels"
              [(ngModel)]="SelectedLevel"
              [disabled]="disabled"
            >
            </ng-multiselect-dropdown>
            </div>
            <div class="form-group col-12 col-md-6">
              
              <ng-multiselect-dropdown  class="form-dropdown-style col-12"
              [placeholder]="'Sport'"
              [settings]="dropdownSettings_single_1" name="Sports"
              [data]="Sports"
              [(ngModel)]="SelectedSport"
              [disabled]="disabled"
            >
            </ng-multiselect-dropdown>
            </div>
            <div class="form-group col-12 col-md-6">
            
              <ng-multiselect-dropdown class="form-dropdown-style col-12"
              [placeholder]="'Equipment'"
              [settings]="dropdownSettings_single" name="Equipments" 
              [data]="Equipments"
              [(ngModel)]="SelectedEquipment"
              [disabled]="disabled"
            >
            </ng-multiselect-dropdown>
            </div>
            <div class="form-group col-12 col-md-6">
              
              <ng-multiselect-dropdown  class="form-dropdown-style col-12"
              [placeholder]="'Force'"  name="Forces"
              [settings]="dropdownSettings_single"
              [data]="Forces"
              [(ngModel)]="SelectedForce"
              [disabled]="disabled"
            >
            </ng-multiselect-dropdown>
            </div>

          </form>

        </div>
        <div class="exercise-content basic-workout">
          <h1 class="main-heading"><i class="fas fa-dumbbell"></i> <span>Make a Basic Workout</span></h1>

          <div class="d-flex align-items-baseline exercise_slide_range">
            <div class="col-md-2" style="font-size: 16px;">Sets</div>
            <ng5-slider class="col-md-8" [(value)]="setsVal" [options]="setsOptions"></ng5-slider>
            <div class="col-md-2 text-center">
              <input type="number" OnlyPositiveNumbers numericType="number" name="setsVal" class="slider-range-value"
                [(ngModel)]="setsVal"></div>
          </div>
          <accordion [closeOthers]="oneAtATime">
            <accordion-group heading="SET 1" (click)="toggleCollapsed()" *ngIf="setsVal > 0">
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Reps</div>
                <ng5-slider class="col-md-8" [(value)]="repsVal" [options]="repsOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <!-- <div class="col-md-8" id="reps"></div> -->
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="repsVal"
                    class="slider-range-value" [(ngModel)]="repsVal">
                </div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Weights</div>
                <div class="col-md-8"> </div>
                <div class="col-md-2  text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="weightsVal"
                    class="slider-range-value" [(ngModel)]="weightsVal"></div>
                <!-- <p>{{  weightsVal | weightConverter : 'Pounds'  }} Pounds </p> -->
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Rest Period</div>
                <ng5-slider class="col-md-8" [(value)]="restPeriodVal" [options]="restPeriodOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <!-- <div class="col-md-8" id="restPeriod"></div> -->
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="restPeriodVal"
                    class="slider-range-value" [(ngModel)]="restPeriodVal" ></div>
              </div>
            </accordion-group>
            <accordion-group heading="SET 2" (click)="toggleCollapsed()" *ngIf="setsVal > 1">
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Reps</div>
                <ng5-slider class="col-md-8" [(value)]="repsVal1" [options]="repsOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="repsVal1"
                    class="slider-range-value" [(ngModel)]="repsVal1">
                </div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Weights</div>
                <div class="col-md-8"> </div>
                <div class="col-md-2  text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="weightsVal1"
                    class="slider-range-value" [(ngModel)]="weightsVal1"></div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Rest Period</div>
                <ng5-slider class="col-md-8" [(value)]="restPeriodVal1" [options]="restPeriodOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="restPeriodVal1"
                    class="slider-range-value" [(ngModel)]="restPeriodVal1"></div>
              </div>
            </accordion-group>
            <accordion-group heading="SET 3" (click)="toggleCollapsed()" *ngIf="setsVal > 2">
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Reps</div>
                <ng5-slider class="col-md-8" [(value)]="repsVal2" [options]="repsOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="repsVal2"
                    class="slider-range-value" [(ngModel)]="repsVal2">
                </div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Weights</div>
                <div class="col-md-8"> </div>
                <div class="col-md-2  text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="weightsVal2"
                    class="slider-range-value" [(ngModel)]="weightsVal2"></div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Rest Period</div>
                <ng5-slider class="col-md-8" [(value)]="restPeriodVal2" [options]="restPeriodOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="restPeriodVal2"
                    class="slider-range-value" [(ngModel)]="restPeriodVal2"></div>
              </div>
            </accordion-group>
            <accordion-group heading="SET 4" (click)="toggleCollapsed()" *ngIf="setsVal > 3">
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Reps</div>
                <ng5-slider class="col-md-8" [(value)]="repsVal3" [options]="repsOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="repsVal3"
                    class="slider-range-value" [(ngModel)]="repsVal3">
                </div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Weights</div>
                <div class="col-md-8"> </div>
                <div class="col-md-2  text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="weightsVal3"
                    class="slider-range-value" [(ngModel)]="weightsVal3"></div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Rest Period</div>
                <ng5-slider class="col-md-8" [(value)]="restPeriodVal3" [options]="restPeriodOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="restPeriodVal3"
                    class="slider-range-value" [(ngModel)]="restPeriodVal3"></div>
              </div>
            </accordion-group>
            <accordion-group heading="SET 5" (click)="toggleCollapsed()" *ngIf="setsVal > 4">
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Reps</div>
                <ng5-slider class="col-md-8" [(value)]="repsVal4" [options]="repsOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="repsVal4"
                    class="slider-range-value" [(ngModel)]="repsVal4">
                </div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Weights</div>
                <div class="col-md-8"> </div>
                <div class="col-md-2  text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="weightsVal4"
                    class="slider-range-value" [(ngModel)]="weightsVal4"></div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Rest Period</div>
                <ng5-slider class="col-md-8" [(value)]="restPeriodVal4" [options]="restPeriodOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="restPeriodVal4"
                    class="slider-range-value" [(ngModel)]="restPeriodVal4"></div>
              </div>
            </accordion-group>
            <accordion-group heading="SET 6" (click)="toggleCollapsed()" *ngIf="setsVal > 5">
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Reps</div>
                <ng5-slider class="col-md-8" [(value)]="repsVal5" [options]="repsOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="repsVal5"
                    class="slider-range-value" [(ngModel)]="repsVal5">
                </div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Weights</div>
                <div class="col-md-8"> </div>
                <div class="col-md-2  text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="weightsVal5"
                    class="slider-range-value" [(ngModel)]="weightsVal5"></div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Rest Period</div>
                <ng5-slider class="col-md-8" [(value)]="restPeriodVal5" [options]="restPeriodOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="restPeriodVal5"
                    class="slider-range-value" [(ngModel)]="restPeriodVal5"></div>
              </div>
            </accordion-group>
            <accordion-group heading="SET 7" (click)="toggleCollapsed()" *ngIf="setsVal > 6">
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Reps</div>
                <ng5-slider class="col-md-8" [(value)]="repsVal6" [options]="repsOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="repsVal6"
                    class="slider-range-value" [(ngModel)]="repsVal6">
                </div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Weights</div>
                <div class="col-md-8"> </div>
                <div class="col-md-2  text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="weightsVal6"
                    class="slider-range-value" [(ngModel)]="weightsVal6"></div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Rest Period</div>
                <ng5-slider class="col-md-8" [(value)]="restPeriodVal6" [options]="restPeriodOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="restPeriodVal6"
                    class="slider-range-value" [(ngModel)]="restPeriodVal6"></div>
              </div>
            </accordion-group>
            <accordion-group heading="SET 8" (click)="toggleCollapsed()" *ngIf="setsVal > 7">
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Reps</div>
                <ng5-slider class="col-md-8" [(value)]="repsVal7" [options]="repsOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="repsVal7"
                    class="slider-range-value" [(ngModel)]="repsVal7">
                </div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Weights</div>
                <div class="col-md-8"> </div>
                <div class="col-md-2  text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="weightsVal7"
                    class="slider-range-value" [(ngModel)]="weightsVal7"></div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Rest Period</div>
                <ng5-slider class="col-md-8" [(value)]="restPeriodVal7" [options]="restPeriodOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="restPeriodVal7"
                    class="slider-range-value" [(ngModel)]="restPeriodVal7"></div>
              </div>
            </accordion-group>
            <accordion-group heading="SET 9" (click)="toggleCollapsed()" *ngIf="setsVal > 8">
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Reps</div>
                <ng5-slider class="col-md-8" [(value)]="repsVal8" [options]="repsOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="repsVal8"
                    class="slider-range-value" [(ngModel)]="repsVal8">
                </div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Weights</div>
                <div class="col-md-8"> </div>
                <div class="col-md-2  text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="weightsVal8"
                    class="slider-range-value" [(ngModel)]="weightsVal8"></div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Rest Period</div>
                <ng5-slider class="col-md-8" [(value)]="restPeriodVal8" [options]="restPeriodOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="restPeriodVal8"
                    class="slider-range-value" [(ngModel)]="restPeriodVal8"></div>
              </div>
            </accordion-group>
            <accordion-group heading="SET 10" (click)="toggleCollapsed()" *ngIf="setsVal > 9">
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Reps</div>
                <ng5-slider class="col-md-8" [(value)]="repsVal9" [options]="repsOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="repsVal9"
                    class="slider-range-value" [(ngModel)]="repsVal9">
                </div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Weights</div>
                <div class="col-md-8"> </div>
                <div class="col-md-2  text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="weightsVal9"
                    class="slider-range-value" [(ngModel)]="weightsVal9"></div>
              </div>
              <div class="d-flex align-items-baseline exercise_slide_range">
                <div class="col-md-2" style="font-size: 16px;">Rest Period</div>
                <ng5-slider class="col-md-8" [(value)]="restPeriodVal9" [options]="restPeriodOptions"
                  [manualRefresh]="manualRefresh"></ng5-slider>
                <div class="col-md-2 text-center">
                  <input type="number" OnlyPositiveNumbers numericType="number" name="restPeriodVal9"
                    class="slider-range-value" [(ngModel)]="restPeriodVal9"></div>
              </div>
            </accordion-group>
          </accordion>
        </div>
        <div class="exercise-content add-instruction">
          <h1 class="main-heading"><i class="fas fa-info"></i> <span>Add Instruction</span></h1>
          <textarea rows="10" class="add-instruction-text border" id="instructions" name="instructions"
            placeholder="Add your instructions*" [(ngModel)]="exercise.instructions" #aboutU="ngModel" minlength="20"
            maxlength="2000"></textarea>
          <!-- <span class="floating-label" style="margin-top: -7px;">About</span> -->
          <div *ngIf="aboutU.invalid && (aboutU.dirty || aboutU.touched)" class="alert alert-danger-textarea">
            <!-- <div *ngIf="aboutU.errors?.required">
                About is mandatory
              </div> -->
            <div *ngIf="aboutU.errors?.minlength">
              Instructions should have minimum 20 characters
            </div>
            <div *ngIf="aboutU.errors?.maxlength">
              Instructions should have maximum 2000 characters
            </div>
          </div>
          <div class="d-flex p-3">
            <ui-switch defaultBgColor="#8a8585" [(ngModel)]="exercise.isPublic"></ui-switch>
            <div class="ml-2 p-1 add-instruction-toggle-text" >Add this to Basic profile video</div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-12 col-lg-3 text-center exercise__add order-first order-md-2 exercise-right-tabs">
        <a class="exercise__add-items active" onclick="exerciseDetails('addVideo', this)">
          <i class="fas fa-play"></i>
          <h3 class="exe-tab-sty">Add Video</h3>
        </a>
        <a class="exercise__add-items" onclick="exerciseDetails('addDetails', this)">
          <i class="far fa-address-card"></i>
          <h3 class="exe-tab-sty">Add Details</h3>
        </a>
        <a class="exercise__add-items " onclick="exerciseDetails('basicWorkout', this)">
          <i class="fas fa-dumbbell"></i>
          <h3 class="exe-tab-sty">Basic Workout</h3>
        </a>
        <a class="exercise__add-items " onclick="exerciseDetails('addInstructions', this)">
          <i class="fas fa-info"></i>
          <h3 class="exe-tab-sty">Add Instructions</h3>
        </a>
        <div class="col-12 exercise-save-details-div">
          <button type="button" class="btn btn-default save-exercise-btn mx-2"
            (click)="SaveExercise()">Save</button>
          <!-- <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;" *ngIf = "exercise.exerciseId != undefined" (click)="EditExercise()">Edit</button> -->
          <button type="button" class="btn btn-default save-exercise-btn mx-2"
            (click)="Cancel()">Cancel</button>
        </div>
      </div>

    </div>

    <div class="col-12 exercise-save-details-div-btm my-3 text-center">
      <button type="button" class="btn btn-default save-exercise-btn mx-2"
        (click)="SaveExercise()">Save</button>
      <!-- <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;" *ngIf = "exercise.exerciseId != undefined" (click)="EditExercise()">Edit</button> -->
      <button type="button" class="btn btn-default save-exercise-btn mx-2"
        (click)="Cancel()">Cancel</button>
    </div>
    <!-- isUpdateExercise -->
    <ng-container *ngIf="isUpdateExercise">
      <div class="program_event-popup__wrapper">
        <div class="program_event-pop zoomIn background-shadow-green" style="width:38% !important;">
          <div class="program_event-header">
            <h4 style=" line-height: 1.6;">Do you want to apply these changes in dependent workouts and programs
            </h4>
          </div>

          <div class="program_event-body d-flex justify-content-center">

            <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
              (click)="onUpdateExercise('No')">No</button>

            <button type="button" class="btn btn-default save-exercise-btn" style="margin-top: 10px;"
              (click)="onUpdateExercise('Yes')">Yes</button>
          </div>
        </div>
      </div>
    </ng-container>

  </div>
</section>
<script type="application/javascript" src="../../assets/js/custom.js"></script>
