<div id="mydiv" *ngIf="loaderHide && this.currentCategory == 'Bodybuilder'">
  <img src="assets/images/loaders/loaderexe.gif" width="10%" class="ajax-loader" />
</div>
<div id="mydiv" *ngIf="loaderHide && this.currentCategory == 'Yoga'">
  <img src="assets/images/loaders/loaderyoga.gif" width="10%" class="ajax-loader" />
</div>
<div id="mydiv" *ngIf="loaderHide && this.currentCategory == 'Zumba'">
  <img src="assets/images/loaders/loaderjumba.gif" width="10%" class="ajax-loader" />
</div>

<section>
    <div class="container myprofile-transparent-bg">
        <div class="container form-bg-transparent py-2" *ngIf="showIndianform">
            <div
                class="p-3">
                <!-- <div class="header-bg mx-auto "> Business Details
                      </div> -->
                <div class="text-left">
                    <h1 class="heading1 col-md-6 col-sm-12">
                        <img width="44" src="assets/images/businessdetailsicon.png" alt="businessdetailsicon"/>
                        <span _ngcontent-c6="">Business Details</span>
                    </h1>
                </div>
            </div>
            <!-- <div class="col-md-12">
                    <div class="d-flex">
                      <div class="btn" style="border-radius: 5px !important" [ngClass]="{'onBackground': showuploaddocumentslist}"
                        (click)="uploaddetails()">
                        Upload Bank Details
                      </div>

                    </div>
                  </div> -->

            <!-- <tabset type="pills" class="col-12">
                    <tab heading="Services Offered"> -->
            <div class="container" *ngIf="showuploaddocumentslist">
                <div class="row mt-3 mb-5">
                    <div class="col-md-4">
                        <div class="d-flex">
                            <div class="file_upload fit-bd-btn form-button-1 mt-3">
                                <div style="padding-left:24px;padding-right:24px">
                                    <input type="file" value="" title="" (change)="selectFile($event,'certificate','GSTIN')" [(ngModel)]="GSTINURL" accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"/>
                                    GSTIN Upload

                                <!-- <i class="fas fa-file-upload" style="font-size: 27px;"></i> -->
                                    <!-- <span>GSTIN Upload</span> -->
                                    <!-- <span *ngIf="certification.uploadedCertificateName != ''">Certificate uploaded successfully</span> -->
                                </div>
                                <div style="font-size: 16px; margin-left: 170px; position: absolute; margin-top: -24px;" (click)="openSampleDocument('GSTIN')" title="View sample">
                                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                                </div>
                            </div>

                            <div
                                *ngIf="GSTINURL != ''" (click)="preview('GSTIN')" style="margin-top: 29px">
                                <!-- <i class = "fas fa-eye"    style ="font-size: 27px;"></i> -->
                                <img class="mx-auto d-block eyeicon" *ngIf="GSTINpreview == 'ON'" src="assets/images/imageshown.png" alt="Eye view" style="cursor: pointer"/>
                                <img class="mx-auto d-block eyeicon" *ngIf="GSTINpreview == 'OFF'" src="assets/images/imagebw.png" alt="Eye view" style="cursor: pointer"/>
                            </div>


                        </div>
                        <div class="d-flex">
                            <div class="file_upload fit-bd-btn form-button-1 mt-3">
                                <div style="padding-left:31px;padding-right:31px">
                                    <input type="file" value="" title="" (change)="selectFile($event,'certificate','PAN')" [(ngModel)]="PANURL" accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"/>
                                    PAN Upload
                                <!-- <i class="fas fa-file-upload" style="font-size: 27px;"></i> -->
                                    <!-- <span>GSTIN Upload</span> -->
                                    <!-- <span *ngIf="certification.uploadedCertificateName != ''">Certificate uploaded successfully</span> -->
                                </div>
                                <div style="font-size: 16px; margin-left: 170px; position: absolute; margin-top: -24px;" (click)="openSampleDocument('PAN')" title="View sample">
                                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div
                                *ngIf="PANURL != ''" (click)="preview('PAN')" style="margin-top: 30px">
                                <!-- <i class = "fas fa-eye" style ="font-size: 27px;"></i> -->
                                <img class="mx-auto d-block eyeicon" *ngIf="PANpreview == 'ON'" src="assets/images/imageshown.png" alt="Eye view" style="cursor: pointer"/>
                                <img class="mx-auto d-block eyeicon" *ngIf="PANpreview == 'OFF'" src="assets/images/imagebw.png" alt="Eye view" style="cursor: pointer"/>
                            </div>

                        </div>

                        <div class="d-flex">
                            <div class="file_upload fit-bd-btn form-button-1 mt-3">
                                <div>
                                    <input type="file" value="" title="" (change)="selectFile($event,'certificate','VOID Cheque')" [(ngModel)]="VOIDChequeURL" accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"/>
                                    VOID Cheque Upload
                                <!-- <i class="fas fa-file-upload" style="font-size: 27px;"></i> -->
                                    <!-- <span>GSTIN Upload</span> -->
                                    <!-- <span *ngIf="certification.uploadedCertificateName != ''">Certificate uploaded successfully</span> -->
                                </div>
                                <div style="font-size: 16px; margin-left: 170px; position: absolute; margin-top: -24px;" title="View sample" (click)="openSampleDocument('VOID Cheque')">
                                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div
                                *ngIf="VOIDChequeURL != ''" (click)="preview('VOID Cheque')" style="margin-top: 30px">
                                <!-- <i class = "fas fa-eye" style ="font-size: 27px;"></i> -->
                                <img class="mx-auto d-block eyeicon" *ngIf="VOIDpreview == 'ON'" src="assets/images/imageshown.png" alt="Eye view" style="cursor: pointer"/>
                                <img class="mx-auto d-block eyeicon" *ngIf="VOIDpreview == 'OFF'" src="assets/images/imagebw.png" alt="Eye view" style="cursor: pointer"/>
                            </div>

                        </div>

                    </div>
                    <div class="col-md-8" *ngIf="showselectedpreview">
                        <div style="overflow: auto; height: 400px; position: relative;" *ngIf="showimagepreview">
                            <img class="mx-auto d-block fill-image" style="height:100%; vertical-align: bottom;width: 50%;" attr.src="{{showpreivewuploaddocument}}" alt="Smiley face"/>
                        </div>
                        <div class="pdf-container" *ngIf="showpdfviewer">
                            <pdf-viewer class="pdf-size-changer" src="{{showpreivewuploaddocument}}" [original-size]="true" [fit-to-page]="true" [autoresize]="true"></pdf-viewer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container form-bg-transparent py-2" *ngIf="showOtherCountryform">
            <div class="p-3">
                <div class="text-left">
                    <h1 class="heading1 col-md-6 col-sm-12">
                        <img width="44" src="assets/images/businessdetailsicon.png" alt="businessdetailsicon" style=""/>
                        <span _ngcontent-c6="">Business Details</span>
                    </h1>
                </div>
            </div>
            <!-- <div class="col-md-12">
                    <div class="d-flex">
                      <div class="btn" style="border-radius: 5px !important" [ngClass]="{'onBackground': showuploaddocumentslist}"
                        (click)="uploaddetails()">
                        Upload Bank Details
                      </div>
                    </div>
                  </div> -->

            <div class="container" *ngIf="showuploaddocumentslist">
                <div class="row mt-3 mb-5">
                    <div class="col-md-4">
                        <div class="mt-3 d-flex">
                            <div class="file_upload fit-bd-btn" >
                                <div style="transform: translate(0px, 2px);">
                                    <input type="file" (change)="selectFile($event,'certificate','W9 Form')" [(ngModel)]="W9FormURL" accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"/>
                                    W9 Form Upload
                                </div>
                                <div style="font-size: 16px; margin-left: 170px; position: absolute; margin-top: -24px;" (click)="openSampleDocument('W9FORM')" title="View sample">
                                    <i class="fa fa-info-circle" aria-hidden="true" style="font-size: 16px;"></i>
                                </div>

                            </div>
                            <div
                                *ngIf="W9FormURL != ''" (click)="preview('W9 Form')" style="margin-top: 10px">
                                <!-- <i class = "fas fa-eye" style ="font-size: 27px;"></i> -->
                                <img class="mx-auto mt-2 d-block eyeicon" *ngIf="W9preview == 'ON'" src="assets/images/imageshown.png" alt="Eye view" style="cursor: pointer"/>
                                <img class="mx-auto mt-2 d-block eyeicon" *ngIf="W9preview == 'OFF'" src="assets/images/imagebw.png" alt="Eye view" style="cursor: pointer"/>
                            </div>
                            <div class="ml-2" (click)="download()" *ngIf="W9FORMTYPE == 'application/pdf'">
                                <img class="mx-auto d-block" src="assets/images/download.png" alt="download" title="Download" style=" margin-top: 16px; font-size: 15px; margin-left: 5px; width: 22px;"/>
                            </div>
                        </div>

                        <div class="d-flex">
                            <div class="file_upload fit-bd-btn form-button-1 mt-3">
                                <div>
                                    <input type="file" value="" title="" (change)="selectFile($event,'certificate','VOID Cheque')" [(ngModel)]="VOIDChequeURL" accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"/>
                                    VOID Check Upload
                                <!-- <i class="fas fa-file-upload" style="font-size: 27px;"></i> -->
                                    <!-- <span>GSTIN Upload</span> -->
                                    <!-- <span *ngIf="certification.uploadedCertificateName != ''">Certificate uploaded successfully</span> -->
                                </div>
                                <div style="font-size: 16px; margin-left: 170px; position: absolute; margin-top: -24px;" title="View sample" (click)="openSampleDocument('VOID Cheque')">
                                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div
                                *ngIf="VOIDChequeURL != ''" (click)="preview('VOID Cheque')" style="margin-top: 30px">
                                <!-- <i class = "fas fa-eye" style ="font-size: 27px;"></i> -->
                                <img class="mx-auto d-block eyeicon" *ngIf="VOIDpreview == 'ON'" src="assets/images/imageshown.png" alt="Eye view" style="cursor: pointer"/>
                                <img class="mx-auto d-block eyeicon" *ngIf="VOIDpreview == 'OFF'" src="assets/images/imagebw.png" alt="Eye view" style="cursor: pointer"/>
                            </div>

                        </div>

                        <div class="d-flex">
                            <div class="file_upload fit-bd-btn form-button-1 mt-3" >
                                <div>
                                    <input type="file" value="" title="" (change)="selectFile($event,'certificate','USID')" [(ngModel)]="USIDUrl" accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"/>
                                    Driver License / State Id
                                </div>
                                <div style="font-size: 16px; margin-left: 170px; position: absolute; margin-top: -24px;" title="View sample" (click)="openSampleDocument('USID')">
                                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div
                                *ngIf="USIDUrl != ''" (click)="preview('USID')" style="margin-top: 30px">
                                <img class="mx-auto d-block eyeicon" *ngIf="USIDpreview == 'ON'" src="assets/images/imageshown.png" alt="Eye view" style="cursor: pointer"/>
                                <img class="mx-auto d-block eyeicon" *ngIf="USIDpreview == 'OFF'" src="assets/images/imagebw.png" alt="Eye view" style="cursor: pointer"/>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-8" *ngIf="showselectedpreview">
                        <div style="overflow: auto; height: 400px; position: relative;" *ngIf="showimagepreview">
                            <img class="mx-auto d-block fill-image" style="height:100%; vertical-align: bottom;width: 50%;" attr.src="{{showpreivewuploaddocument}}" alt="Smiley face"/>
                        </div>
                        <div class="pdf-container" *ngIf="showpdfviewer">
                            <pdf-viewer class="pdf-size-changer" src="{{showpreivewuploaddocument}}" [original-size]="true" [fit-to-page]="true" [autoresize]="true"></pdf-viewer>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container" *ngIf="showbankaccountdetails">
            <div class="container">
                <div class="row mt-3">
                    <div class="col-6">
                        <div class="col-9 mt-3 input-effect">
                            <input
                            class="form-control input-animation" type="text" placeholder="Account Holder Name *" name="accountHolderName" autocomplete="off" id="accountHolderName" [(ngModel)]="bankDetails.accountHolderName"/>
                            <!-- <label>Account Holder Name</label> -->
                            <span class="focus-border"></span>
                        </div>

                        <!-- <div class="col-9 mt-3 input-effect">
                                      <div class="dropdown">
                                        <ng-multiselect-dropdown class="form-control col-12" [data]="banksavinglist"
                                          placeholder="Account Type *" [(ngModel)]="bankDetails.accountType" name="SelectedCategory1"
                                          [settings]="dropdownSettings_single" [disabled]="disabled">
                                        </ng-multiselect-dropdown>
                                      </div>
                                    </div> -->

                        <div class="col-9 mt-3 input-effect">
                            <ng-multiselect-dropdown class="form-control col-12"
                            [placeholder]="'Account Type *'"
                            [settings]="dropdownSettings_single"
                            [data]="bankAccountTypes"
                            [(ngModel)]="SelectedAccountType"
                            name="accountType"
                          >
                          </ng-multiselect-dropdown>
                        </div>

                        <!-- <div class="col-9 mt-3 input-effect">
                                      <div class="dropdown">
                                        <ng-multiselect-dropdown class="form-control col-12" [data]="banklist" placeholder="Bank Name *"
                                          [(ngModel)]="bankDetails.bankName" name="SelectedCategory1" [settings]="dropdownSettings_single"
                                          [disabled]="disabled">
                                        </ng-multiselect-dropdown>
                                      </div>
                                    </div> -->

                        <div class="col-9 mt-3 input-effect">
                           
                            <ng-multiselect-dropdown class="form-control col-12"
                            [placeholder]="'Bank Name *'"
                            [settings]="dropdownSettings_single"
                            [data]="bankNames"
                            [(ngModel)]="SelectedBank"
                            name="bankName" 
                          >
                          </ng-multiselect-dropdown>
                        </div>

                        <div class="col-9 mt-3 input-effect">
                            <input
                            class="form-control input-animation" type="number" onlypositivenumbers numerictype="number" placeholder="Account Number *" name="accountNumber" autocomplete="off" id="accountNumber" [(ngModel)]="bankDetails.accountNumber"/>
                            <!-- <label>Account Number</label> -->
                            <span class="focus-border"></span>
                        </div>

                        <div class="col-9 mt-3 input-effect">
                            <input
                            class="form-control input-animation" type="text" placeholder="Country *" name="country" autocomplete="off" id="country" [(ngModel)]="bankDetails.country" [disabled]="true"/>
                            <!-- <label>Country</label> -->
                            <span class="focus-border"></span>
                        </div>
                        <div class="col-9 mt-3 input-effect" *ngIf="showIndianform">
                            <input
                            class="form-control input-animation" type="text" placeholder="IFSC Code" name="ifscCode" autocomplete="off" id="ifscCode" [(ngModel)]="bankDetails.ifscCode"/>
                            <!-- <label>IFSC Code</label> -->
                            <span class="focus-border"></span>
                        </div>
                        <div class="col-9 mt-3 input-effect" *ngIf="showOtherCountryform">
                            <input
                            class="form-control input-animation" type="text" placeholder="Routing Number" name="routingNumber" autocomplete="off" id="routingNumber" [(ngModel)]="bankDetails.routingNumber"/>
                            <!-- <label>Currency</label> -->
                            <span class="focus-border"></span>
                        </div>

                        <div class="col-9 mt-3 input-effect" *ngIf="showOtherCountryform">
                            <input
                            class="form-control input-animation" type="text" placeholder="currency" name="currency" autocomplete="off" id="currency" [(ngModel)]="bankDetails.currency"/>
                            <!-- <label>Currency</label> -->
                            <span class="focus-border"></span>
                        </div>
                    </div>

                    <!-- <div class="col-6">

                            </div> -->
                </div>
            </div>
        </div>
        <div class="container mt-4 text-right">
            <div class="btn" (click)="savedetails()" *ngIf="isSave">Save</div>
            <div class="btn" (click)="savedetails()" *ngIf="isEdit">Save</div>
            <div class="btn" routerLink="/program" *ngIf="currentCategory != 'Yoga'">Back</div>
            <div class="btn" routerLink="/yogaPrograms" *ngIf="currentCategory == 'Yoga'">Back</div>
        </div>
    </div>
</section>
