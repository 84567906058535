<!-- <h2>Responsive Image Gallery</h2>
<h4>Resize the browser window to see the effect.</h4> -->
<section>
  <div class="container-fluid">
    <div class="common-heading-top-padding">
    <div class="row mb-3">
      <div class="heading1 col-md-7 col-sm-12 mb-4">
        <img src="assets/images/dashboard/progress_images.png"
          style="border: 2px solid #8bc53f;border-radius: 50%;" width="50" />
        <span><b>Progress Photos</b></span>
      </div>

        <div class="col-md-5 p-1">
          <button class="btn btn-default save-exercise-btn" style="cursor: pointer;"
            routerLink="/tprofile/{{program_id}}/{{trainee_id}}" style="margin-top: 10px;float: right;" type="button"
            tabindex="0">BACK</button>
        </div>

    </div>

    <div class="row mb-3">
        <!-- <input class="meal-plan-date-picker" type="date" name="dateofbirth" id="dateofbirth"> -->
        <div class="col col-md-2"><b>From:</b>
            <!-- <input id="fromDate" name="fromDate" type="date" class="meal-plan-date-picker" [(ngModel)]="fromDate" (change)="onDateChange()"/> -->

            <my-date-picker [options]="myDatePickerOptions"  id="fromDate" name="fromDate" [(ngModel)]="fromDate" (dateChanged)="onDateChanged($event, 'fromDate')" placeholder="date" style="width: 100%;"></my-date-picker>

        </div>
        <div class="col col-md-2"><b>To: </b>
            <!-- <input id="toDate" name="toDate" type="date" class="meal-plan-date-picker" [(ngModel)]="toDate" (change)="onDateChange()"/> -->
            <my-date-picker [options]="myDatePickerOptions"  id="toDate"name="toDate"[(ngModel)]="toDate" (dateChanged)="onDateChanged($event, 'toDate')" placeholder="date" style="width: 100%;"></my-date-picker>
        </div>
    </div>
    <div class="row ">
      <div class="col-xs-6 col-sm-4 col-md-2 col-lg-2" *ngFor="let photo of progressphotos">
        <div class="card border-0 transform-on-hover">
          <a class="lightbox" >
            <img src="{{photo.imgUrl}}" alt="Progress-image" class="trainee-progress-photo-thumb">
          </a>
            <div class="p-1" style="flex: 1 1 auto;">
                <h6 style="font-weight: 600;">{{photo.date | date}}</h6>
            </div>
        </div>
    </div>



  </div>
  </div>
  </div>
</section>
<!-- <div style="padding:6px;">
  <p>This example use media queries to re-arrange the images on different screen sizes: for screens larger than 700px wide, it will show four images side by side, for screens smaller than 700px, it will show two images side by side. For screens smaller than 500px, the images will stack vertically (100%).</p>
  <p>You will learn more about media queries and responsive web design later in our CSS Tutorial.</p>
</div>/ -->
