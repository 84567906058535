<div style="position: absolute;">
  <img class="img-responsive" height="135" src="assets/images/logov1.gif" style="z-index: 900;">
</div>

<div class="wrapper container-fluid" id="page-meeting">
 <section class="video-item" style="grid-area: span 12/span 24/13/25"><div class="video-item-box"></div>
    <div style="width: 100%;height: 24vw; overflow: hidden; border-radius: 10px;">
      <div id="remote" #remoteVideo class=" container-fluid text-left remote remote-style" ></div>
      <div id="local" class="text-left local container-fluid" #localVideo></div>
    </div>
 </section>

  <div class="btn-group">
    <!-- <button *ngIf="twilioService.microphone" title="Mute" class="pr-2 btn btn-default save-exercise-btn" style="opacity: 1;cursor: pointer;"
  (click)="mute()">Mute</button>
  <button *ngIf="!twilioService.microphone" title="Unmute" class="pr-2 btn btn-default save-exercise-btn" style="opacity: 1;cursor: pointer;"
  (click)="unmute()">Un Mute</button>
  <button title="Disconnect" class="pr-2 btn btn-default save-exercise-btn" style="opacity: 1;cursor: pointer;" (click)="disconnect()"> Close</button> -->
 <div class="col-md-2 mx-auto text-center">
   <div class="d-flex">
        <img class="video-image-background p-2" src="assets/images/mute.png" alt="mute" (click)="mute()" *ngIf="twilioService.microphone" style="width: 50px;
        height: 50px;">
        <img class="video-image-background p-2" src="assets/images/unmute.png" alt="unmute" (click)="unmute()" *ngIf="!twilioService.microphone" style="width: 50px;
        height: 50px;">
        <img class="video-image-background p-2" src="assets/images/video-call-dis.png" alt="video-call" (click)="onVideoEnable()" *ngIf="!twilioService.camera" style="width: 50px;
        height: 50px;">
        <img class="video-image-background p-2" src="assets/images/video-call-clr.png" alt="dis-video-call" (click)="onVideoDisable()" *ngIf="twilioService.camera" style="width: 50px;
        height: 50px;">
        <img class="video-image-background p-2" src="assets/images/end.png" alt="end-call" (click)="disconnect()" style="width: 48px;
        height: 48px;">
   </div>
 </div>
</div>

</div>
