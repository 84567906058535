import { Component, OnInit, Input, ViewChild, OnDestroy, Renderer2, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { UserLoginService } from "../service/user-login.service";
import { LoggedInCallback, CognitoUtil } from "../service/cognito.service";
import { AppGlobals } from '../app.globals';
import { DBService } from '../service/db.service';
import { ToastrService } from 'ngx-toastr';
import { Integer } from 'aws-sdk/clients/lambda';
import { AWSService } from '../service/aws.service';
import { FlexAlignStyleBuilder } from '@angular/flex-layout';
import { EmbedVideoService } from 'ngx-embed-video';
import { DomSanitizer } from '@angular/platform-browser';
// import { analyzeAndValidateNgModules } from '@angular/compiler';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { YogaDBService } from '../service/YogaDBService';

export class calender {
  trainerId: string;
  programId: string;
  calendarId: string;
  weeks: Object[];
  startDate: Date;
  endDate: Date;
  idleFor: number;
  creationDate: Date;
  lastUpdatedDate: Date;
  adminStatus: string;
  approvalComments: Object[]
}

@Component({
  selector: 'app-calendar-yoga',
  templateUrl: './calendar-yoga.component.html',
  styleUrls: ['./calendar-yoga.component.css']
})
export class CalendarYogaComponent implements OnInit {
  modalRef: BsModalRef;

  daysdata: object[];
  programStatus: string;
  programImagePath: string;
  public showEventPopup: boolean = false;
  public showWorkOtPopup: boolean = false;
  public showWorkoutsPopup: boolean = false;
  public isVideoAvailable: boolean = false;
  @Input() imgUrl: string = "assets/images/uploadimg.svg";
  currentCategory: string;
  currentGender: string;
  private sub: any;
  asanaCheck: boolean = false;
  cardio: string = "";
  repeats: string[] = [];
  dropdownSettings: any = {};
  dropdownSettings1: any = {};
  dropdownSettings_single: any = {};
  programdata: any;
  closeDropdownSelection = false;
  showRepeat = false;
  showWorkout = false;
  showCardio = false;
  Asanas: Object[];
  masterAsanas: Object[];
  SelectedAsanas: Object[];
  Selectedweek: [];
  Selecteddays: [];
  Selectedrepeat: string[];
  SelectedCardio: any[];
  day: number;
  week: number;
  isAsanaCk: boolean = false;
  isCardioCk: boolean = false;
  isRestCk: boolean = false;
  isRepeatCk: boolean = false;
  isProgressCk: boolean = false;

  isLastWeek: boolean = true;
  sweek: string = "";
  dayCheck: any;
  weekCheck: any;
  // Google Var
  viewWorkout: boolean = true;
  // workoutName: string;
  imgPath: string;
  googleUserEmail: string;
  private loggedIn: boolean;
  private subcrptn_1: any;
  currentLogged: string;
  currentEmail: string;
  calender: calender;
  // Calendar Vars
  calendarPlans: number = 1;  // Total no of Plans
  currentPlan: number = 1;
  calenderWeeks: number = 1;  // No of week for plan
  calendarDays: number;
  calendarDay: number;
  currentDay: number = 1;
  currentWeeks: number;

  // asana popup  constants
  dayAsanas: Object[];
  // workoutExercises: Object[];
  // currentExercise: Object;
  // exercisePlayUrl: string;
  dayCardio: Object;


  distance: number;
  cardiodata: object[] = [
    { id: "1", name: 'Running', imgUrl: 'https://d13m95frd7cvoq.cloudfront.net/static-content/cardio/running.png' },
    { id: "2", name: 'Cycling', imgUrl: 'https://d13m95frd7cvoq.cloudfront.net/static-content/cardio/running.png' },
    { id: "3", name: 'Walking', imgUrl: 'https://d13m95frd7cvoq.cloudfront.net/static-content/cardio/images.png' },
    { id: "4", name: 'Rower', imgUrl: 'https://d13m95frd7cvoq.cloudfront.net/static-content/cardio/rower.png' },
    { id: "5", name: 'Elliptical', imgUrl: 'https://d13m95frd7cvoq.cloudfront.net/static-content/cardio/ELLE.png' },
    { id: "6", name: 'General', imgUrl: 'https://d13m95frd7cvoq.cloudfront.net/static-content/cardio/gen.png' }
  ];
  calendarDaysArr: number[] = [];
  calendarDaysStrArr: string[] = [];
  calendarDayNames: string[] = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  calendarWeekNames: number[] = [];
  calendarWeekNamesStr: string[] = [];
  calendarWeek: number;
  currentWeek: number;
  // calendarWeekNAmes : string[] = [];;

  asanadata: object;
  exercises: object[];
  droppedItems: object[];
  embedYoutubeUrl: string;
  videoHidden: boolean = false;
  iframeHidden: boolean = false;
  iframeURL: any;
  rest: boolean = false;

  isActive : boolean = false;

  @Input() viewURL: string = "";
  @Input() Instructions: string = "";
  @ViewChild('viewworkLoad') viewworkLoad: any;
  repeatDays = [
    { id: 1, name: 'M', checked: false },
    { id: 2, name: 'T', checked: false },
    { id: 3, name: 'W', checked: false },
    { id: 4, name: 'T', checked: false },
    { id: 5, name: 'F', checked: false },
    { id: 6, name: 'S', checked: false },
    { id: 7, name: 'S', checked: false }
  ];
  currentAsana: any;
  asanaPlayUrl: any;
  get selectedOptions() { // right now: ['1','3']
    return this.repeatDays
      .filter(opt => opt.checked)
      .map(opt => opt.id)
  }

  constructor(sanitizer: DomSanitizer, private renderer: Renderer2, private awsService: AWSService, public router: Router,
    public route: ActivatedRoute, public userService: UserLoginService, private yoga_db_service: YogaDBService, private embedService: EmbedVideoService, private globals: AppGlobals,
    private db_service: DBService, private toastr: ToastrService, public cognitoUtil: CognitoUtil,private modalService: BsModalService) {
    this.userService.isAuthenticated(this);
  }

  ngOnInit() {
    this.calender = new calender();
    this.sub = this.route.params.subscribe(params => {
      this.calender.programId = params['programID'];
      this.getProgramDataFromerver();
    });

    this.repeats = ['1 week', '2 week', '3 week', '4 week'];
    this.dropdownSettings1 = {
      singleSelection: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      closeDropDownOnSelection: this.closeDropdownSelection
    };
    this.dropdownSettings_single = {
      singleSelection: true,
      enableCheckAll: false,
      idField: 'id',
      textField: 'name',
      allowSearchFilter: true,
      itemsShowLimit: 1,
      closeDropDownOnSelection: this.closeDropdownSelection
    };
    this.dropdownSettings = {
      singleSelection: false,
      enableCheckAll: false,
      idField: 'asanaId',
      textField: 'asanaTitle',
      allowSearchFilter: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      closeDropDownOnSelection: this.closeDropdownSelection
    };
    this.currentCategory = localStorage.getItem("ProfileCategory")
    this.currentGender = localStorage.getItem("ProfileGender");
    if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-build-male');
    }
    else if (this.currentCategory == 'Bodybuilder' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-build-female');
    }
    else if (this.currentCategory == 'Yoga' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-yoga-male');
    }
    else if (this.currentCategory == 'Yoga' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-yoga-female');
    }
    else if (this.currentCategory == 'Zumba' && this.currentGender == 'male') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-zumba-male');
    }
    else if (this.currentCategory == 'Zumba' && this.currentGender == 'female') {
      this.renderer.removeClass(document.body, 'body-main');
      this.renderer.addClass(document.body, 'bg-img-zumba-female');
    }

    this.userService.currentUserID.subscribe((val) => {
      if (val != '') {
        this.calender.trainerId = val;
      }
    });
    // Calendar Plan 1 genration
    if(this.calender.trainerId != undefined || this.calender.trainerId != null)
    this.getAsanaDataFromServer();
  }
  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'bg-img-build-male');
    this.renderer.removeClass(document.body, 'bg-img-yoga-male');
    this.renderer.removeClass(document.body, 'bg-img-zumba-male');
    this.renderer.removeClass(document.body, 'bg-img-build-female');
    this.renderer.removeClass(document.body, 'bg-img-yoga-female');
    this.renderer.removeClass(document.body, 'bg-img-zumba-female');
    this.renderer.addClass(document.body, 'body-main');
    this.renderer.removeClass(document.body, 'modal-open');
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {

    if (!isLoggedIn) {
      this.router.navigate(['/login']);
    } else {
      // if()
      if (this.loggedIn == true) {
        this.currentEmail = localStorage.getItem('lastAuthUser');
        // if(this.currentEmail != undefined)
        this.getProfileDataFromServer();
      }
      else {
        // this.currentEmail = localStorage.getItem('CognitoIdentityServiceProvider.2io8v4r253hugt5ndbjv9shkfp.LastAuthUser');
        this.currentEmail = this.cognitoUtil.getCurrentUser().getUsername();
        if(this.currentEmail == undefined || this.currentEmail == '' || this.currentEmail == null || this.currentEmail.includes("Google") || this.currentEmail.includes("google"))
        {
          this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
          if(this.currentEmail == undefined)
          this.currentEmail = this.cognitoUtil.session.idToken.payload.email;
        }
        // this.currentEmail = this.cognitoUtil.auth.signInUserSession.idToken.payload.email;
        this.getProfileDataFromServer();
      }
    }
  }
  getProfileDataFromServer() {
    var index = '/profile/';
    this.yoga_db_service.getDataFromServer(this.currentEmail, index).subscribe(
      response => {
        //console.log('Data Retrived succesfully.', response);
        response = response.data[0];
        this.calender.trainerId = response.trainerId;
        this.getAsanaDataFromServer();
      },
      error => {
        console.log("An error has occurred while retriving profile data.");
      }
    )
  }
  checkDay(day){
    var dayCheck;
    for(let i=0; i<this.daysdata.length; i++) {
    dayCheck=this.daysdata[i]["days"].find(x=>x["day"]==day);
    if(dayCheck!=undefined)
    break;
  }
  if(dayCheck==undefined)
  return true;
  else return false;
}
  getProgramDataFromerver() {

    var index = '/program/';
    this.programdata = {};
    this.programdata["nutrition"] = {};
    this.yoga_db_service.getDataFromServer(this.calender.programId, index).subscribe(
      response => {
        this.programdata = response.data;
        this.programImagePath = response.data.programImage.imgPath;
        this.calendarPlans = this.programdata["plans"];
        this.calenderWeeks = this.programdata["weeks"];
        this.calendarDaysArr.length = 0;
        this.programStatus = this.programdata["status"];
        this.calender.startDate = this.programdata["startDate"];
        this.generateCalendar();
        this.daysdata = [];
        //this.getAsanaDataFromServer();
        this.getCalenderDataFromServer();
      },
      error => {
        // alert("An error has occurred while saving data.");
        this.toastr.error('', 'An error has occurred getting my program data.', {
          timeOut: 5000,
          extendedTimeOut: 1000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true
        });
      }
    )

  }
  deleteAsana(id) {
    var AsanaArray = [];
    for (var x = 0; x < this.daysdata.length; x++) {
      for (var i = 0; i < this.daysdata[x]["days"].length; i++) {
        if (this.daysdata[x]["days"][i]["day"] === this.day) {
          const index = this.daysdata[x]["days"][i]["asanas"].findIndex(y => y["asanaId"] === id);
          this.daysdata[x]["days"][i]["asanas"].splice(index, 1);
        }
      }
    }
    this.showWorkOtPopup = false;
    this.asanaCheck = false;
    var index = '/calendar'
    this.yoga_db_service.updateDatainDB(this.calender, index).subscribe(
      response => {
        this.toastr.success('', 'Asana deleted successfully.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      },
      error => {
        this.toastr.error('', 'There is an error while deleting asana.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
      })
  }
  getAsanaDataFromServer() {
    var index = '/asana/';
    var queryparams={
      "pagesize": 0,
      "pagenumber":0,
      "pagination":false,
      "trainerId":this.calender.trainerId
    }
    this.yoga_db_service.getQueriedData("", index, queryparams).subscribe(
      response => {
        //console.log('Data Retrived succesfully.', response);
        this.Asanas = [];
        this.masterAsanas = response.data;
        this.Asanas = JSON.parse(JSON.stringify( response.data));

        for (let i = 0; i < this.masterAsanas.length; i++) {
          this.masterAsanas[i]["asanaRefId"] = this.masterAsanas[i]["asanaId"];
        }
      }, 
      // asanaRefId
      error => {
        console.log('There is an error while retriving asanas.');
      }
    )
  }
  getCalenderDataFromServer() {
    var index = '/calendar/';
    this.yoga_db_service.getDataFromServer(this.calender.programId, index).subscribe(
      response => {
        if (response.data != "") {
          this.daysdata = response.data.weeks;
          this.calender.calendarId = response.data.calendarId;
          this.calender.adminStatus = response.data.adminStatus;
          this.calender.approvalComments = response.data.approvalComments;
        } else {
          this.daysdata = [];
        }

      },
      error => {
        this.toastr.error('', 'An error has occurred getting my program data.', {
          timeOut: 5000,
          extendedTimeOut: 1000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true
        });
      }
    )

  }

  openModal(day) {

    this.getAsanaDataFromServer();
    this.week = this.weekCaluculator(day);
    this.day = day;
    this.distance = 0;
    this.SelectedAsanas = [];
    this.calendarWeekNamesStr = [];
    this.Selectedrepeat = [];
    this.Selectedweek = [];
    this.SelectedCardio = [];
    this.isCardioCk = false;
    this.isAsanaCk = false;
    this.isRestCk = false;
    this.isRepeatCk = false;
    this.isProgressCk = false;
    this.repeats = [];
    // if (this.week == (this.currentPlan * this.calenderWeeks)) {
    //   this.isLastWeek = false;
    // } else {
    //   this.isLastWeek = true;
    // }
    for (let j = 0; j < this.repeatDays.length; j++) {
      this.repeatDays[j].checked = false;
    }
    var index = this.calendarWeekNames.findIndex(x => x == this.week);
    var repeats_value = index;
    var value_repeat = 1;
    for (var i = index; i < this.calendarWeekNames.length; i++) {
      if (i > repeats_value) {
        this.repeats.push(value_repeat + " week");
        value_repeat++;
      }
      if (index == this.calendarWeekNames.length - 1 && i == repeats_value) {
        this.repeats.push(value_repeat + " week");
      }
      // if(this.calendarWeekNames[i] > this.week){
      this.calendarWeekNamesStr.push("Week " + this.calendarWeekNames[i]);
      // }
    }
    this.showEventPopup = true;
    if (this.asanaCheck == true) {
      this.showEventPopup = false;
    } else {
      if (this.daysdata != undefined) {

        var dayData = undefined;
        for (var n = 0; n < this.daysdata.length; n++) {
          if (dayData == undefined) {
            var checkData = this.daysdata[n]["days"];
            dayData = checkData.find(x => x["day"] == this.day);
          }
        }
        if (dayData != undefined) {
          for (let i = 0; i < dayData["asanas"].length; i++) {
            var asana = dayData["asanas"][i]["asanaTitle"];
            asana["asanaId"] = dayData["asanas"][i]["asanaId"];
            this.SelectedAsanas.push(JSON.parse(JSON.stringify(asana)));
          }
          if (this.SelectedAsanas.length != 0)
            this.isAsanaCk = true;
          if (Object.keys(dayData["cardio"]).length != 0) {
            this.SelectedCardio = Array.of(dayData["cardio"]);
            this.distance = dayData["cardio"]["distance"]["actual"];

            this.isCardioCk = true;
          }
          this.isRestCk = dayData["rest"];
          this.isProgressCk = dayData["progress_photo"];
        }

      }
      if (this.programStatus == "Active") {
        this.showEventPopup = false;
      }
    }
  }
  getAsanas(day,template: TemplateRef<any>) {
    this.day = day;
    this.showWorkoutsPopup = true;
    this.modalRef = this.modalService.show(template,Object.assign({}, { class: 'modal-dialog-centered modal-lg fit-modal-cus' }));
    if (this.daysdata != undefined) {
      var dayData = undefined;
      for (var n = 0; n < this.daysdata.length; n++) {
        if (dayData == undefined) {
          var checkData = this.daysdata[n]["days"];
          dayData = checkData.find(x => x["day"] == day);
        } else {
          break;
        }
      }
      if (dayData != undefined) {
        this.dayAsanas = [];
        // this.currentExercise = {};
        // this.currentExercise["exerciseVideo"] = {};
        this.dayCardio = {};
        this.embedYoutubeUrl = undefined;
        // this.exercisePlayUrl = undefined;

        this.dayAsanas = dayData["asanas"];
        this.dayCardio = dayData["cardio"];
        if(this.dayAsanas.length != 0)
        this.getAsanaById(this.dayAsanas[0]['asanaId']);
        else
        this.getAsanaById("");


        // if (this.dayAsanas.length != 0) {
        //   this.isVideoAvailable = true;
        //   if (this.currentExercise["exerciseVideo"]["youtubeUrl"] != undefined && this.currentExercise["exerciseVideo"]["youtubeUrl"] != "") {
        //     this.iframeURL = this.embedService.embed(this.currentExercise["exerciseVideo"]["youtubeUrl"], {
        //       query: { portrait: 0, color: 'black', autoplay: 1 },
        //       attr: { width: "100%", height: 250, frameborder: 0, allow: "autoplay" }
        //     })
        //     if (this.iframeURL != undefined)
        //       this.embedYoutubeUrl = this.iframeURL;

        //   } else {
        //     this.exercisePlayUrl = this.currentExercise["exerciseVideo"]["exerciseVideoSource"];
        //     this.viewworkLoad.nativeElement.load();
        //     this.viewworkLoad.nativeElement.play();
        //   }
        // } else {
        //   this.isVideoAvailable = false;
        //   this.viewworkLoad.nativeElement.load();
        //   this.viewworkLoad.nativeElement.play();
        // }

      }

    }
  }

  getAsanaById(asanaId){
    if(asanaId == undefined || asanaId == null || asanaId == ""){
      this.currentAsana = "none";
      this.isVideoAvailable = false;
      return;
  }
    this.currentAsana = this.dayAsanas.find(x => x["asanaId"] == asanaId);
    // this.workoutExercises = workout["workoutExercises"];
    // this.currentAsana = asana;
    this.isVideoAvailable = true;
    if (this.currentAsana["asanaVideo"]["youtubeUrl"] != undefined && this.currentAsana["asanaVideo"]["youtubeUrl"] != "") {
      this.iframeURL = this.embedService.embed(this.currentAsana["asanaVideo"]["youtubeUrl"], {
        query: { portrait: 0, color: 'black', autoplay: 1 },
        attr: { width: "100%", height: 250, frameborder: 0, allow: "autoplay" }

      })
      if (this.iframeURL != undefined)
        this.embedYoutubeUrl = this.iframeURL;

    } else {
      this.isVideoAvailable  = true;
      this.asanaPlayUrl = this.currentAsana["asanaVideo"]["asanaVideoSource"];
      this.viewworkLoad.nativeElement.load();
      this.viewworkLoad.nativeElement.play();
    }
  }

  SaveData() {
    var arrayIndex = 0;
    if (this.isAsanaCk == true && this.SelectedAsanas.length == 0) {
      this.toastr.error('', 'Please select asana.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return;
    }
    if (this.isCardioCk == true && this.SelectedCardio.length == 0) {
      this.toastr.error('', 'Please select cardio.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return;
    }
    if (this.isCardioCk == false) {
      this.SelectedCardio = [];
    }
    if (this.SelectedCardio.length == 0 && this.SelectedAsanas.length == 0 && this.isRestCk == false && this.isProgressCk == false) {
      this.toastr.error('', 'Please select any one option.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return;
    }
    if (this.SelectedCardio.length != 0 && this.distance == 0) {
      this.toastr.error('', 'Time should be greater than zero.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return;
    }
    if (this.isRepeatCk == true && this.Selectedrepeat.length == 0) {
      this.toastr.error('', 'Please select repeat values.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return;
    }
    if (this.isRepeatCk == true) {
      var value = this.repeatDays.find(x => x["checked"] == true);
      if (value == undefined) {
        this.toastr.error('', 'Please select repeat days.', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          progressBar: true,
          progressAnimation: 'increasing',
          tapToDismiss: true,
        });
        return;
      }
    }

    if (this.isRepeatCk == true && this.Selectedweek.length == 0) {
      this.toastr.error('', 'Please select repeat until.', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true,
      });
      return;
    }



    this.weekCheck = undefined;
    this.dayCheck = undefined;
    if (this.daysdata != undefined) {
      this.weekCheck = this.daysdata.find(x => x["week"] == this.week);
      for (var m = 0; m < this.daysdata.length; m++) {
        if (this.dayCheck == undefined) {
          var dayCheckData = this.daysdata[m]["days"];
          this.dayCheck = dayCheckData.find(x => x["day"] == this.day);
        }
      }
    }
    if (this.weekCheck == undefined) {
      var weekObj = {}
      var dayArray = [];
      var dayObj = {};
      dayObj["day"] = this.day;
      dayObj["dayStatus"] = "new";
      dayObj["dayPercentage"] = "0";
      dayObj["mealplan"] = {};
      dayObj["schedular"] = [];


      if (this.SelectedAsanas != undefined) {
        if (this.SelectedAsanas.length != 0) {
          var asanaSelected = [];
          for (var a = 0; a < this.SelectedAsanas.length; a++) {
            var current_asana = {};
            current_asana = this.masterAsanas.find(x => x["asanaId"] == [this.SelectedAsanas[a]["asanaId"]]);
            asanaSelected.push(JSON.parse(JSON.stringify(current_asana)));
          }
          dayObj["asanas"] = asanaSelected;
        } else {
          dayObj["asanas"] = [];
        }
      }
      else {
        dayObj["asanas"] = []
      }

      if (this.SelectedCardio != undefined) {
        if (this.SelectedCardio.length != 0) {
          dayObj["cardio"] = this.cardiodata.find(x => x["id"] == [this.SelectedCardio[arrayIndex].id]);
          dayObj["cardio"]["distance"] = {};
          dayObj["cardio"]["distance"]["actual"] = this.distance;
          dayObj["cardio"]["distance"]["completed"] = 0;
          dayObj["cardio"]["cardioStatus"] = "new";
          dayObj["cardio"]["cardioComments"] = [];
          dayObj["cardio"]["metrics"] = "Minutes";
        }
        else {
          dayObj["cardio"] = {}
        }
      }
      else {
        dayObj["cardio"] = {}
      }
      dayObj["rest"] = this.isRestCk;
      dayObj["progress_photo"] = this.isProgressCk;

      dayArray.push(dayObj);
      weekObj["week"] = this.week;
      weekObj["weekStatus"] = "new";
      weekObj["weekPercentage"] = "0";

      weekObj["days"] = dayArray;
      if (this.daysdata == undefined || this.daysdata == null) {
        this.daysdata = Array.of(weekObj);
      } else {
        this.daysdata.push(weekObj);
      }
    } else {
      for (let k = 0; k < this.daysdata.length; k++) {
        if (this.daysdata[k]["week"] == this.week) {
          if (this.dayCheck != undefined) {
            for (let i = 0; i < this.daysdata[k]["days"].length; i++) {
              if (this.daysdata[k]["days"][i]["day"] == this.day) {
                if (this.SelectedAsanas.length != 0) {
                  var asanaSelected = [];
                  for (var a = 0; a < this.SelectedAsanas.length; a++) {
                    var current_asana = {};
                    current_asana = this.masterAsanas.find(x => x["asanaId"] == [this.SelectedAsanas[a]["asanaId"]]);
                    asanaSelected.push(JSON.parse(JSON.stringify(current_asana)));
                  }
                  this.daysdata[k]["days"][i]["asanas"] = asanaSelected;
                  this.daysdata[k]["days"][i]["rest"] = this.isRestCk;
                  this.daysdata[k]["days"][i]["progress_photo"] = this.isProgressCk;
                  if (this.SelectedCardio != undefined) {
                    if (this.SelectedCardio.length != 0) {
                      this.daysdata[k]["days"][i]["cardio"] = this.cardiodata.find(x => x["id"] == [this.SelectedCardio[arrayIndex].id]);
                      this.daysdata[k]["days"][i]["cardio"]["distance"] = {};
                      this.daysdata[k]["days"][i]["cardio"]["distance"]["actual"] = this.distance;
                      this.daysdata[k]["days"][i]["cardio"]["distance"]["completed"] = 0;
                      this.daysdata[k]["days"][i]["cardio"]["cardioStatus"] = "new";
                      this.daysdata[k]["days"][i]["cardio"]["cardioComments"] = [];
                      this.daysdata[k]["days"][i]["cardio"]["metrics"] = "Minutes";
                    } else {
                      this.daysdata[k]["days"][i]["cardio"] = {}
                    }
                  } else {
                    this.daysdata[k]["days"][i]["cardio"] = {}
                  }
                } else {
                  if (this.rest == true) {
                    this.daysdata[k]["days"][i]["rest"] = this.isRestCk;
                    this.daysdata[k]["days"][i]["asanas"] = [];
                    this.daysdata[k]["days"][i]["cardio"] = {};
                  } else {
                    this.daysdata[k]["days"][i]["rest"] = this.isRestCk;
                    this.daysdata[k]["days"][i]["asanas"] = [];
                    if (this.SelectedCardio != undefined) {
                      if (this.SelectedCardio.length != 0) {
                        this.daysdata[k]["days"][i]["cardio"] = this.cardiodata.find(x => x["id"] == [this.SelectedCardio[arrayIndex].id]);
                        this.daysdata[k]["days"][i]["cardio"]["distance"] = {};
                        this.daysdata[k]["days"][i]["cardio"]["distance"]["actual"] = this.distance;
                        this.daysdata[k]["days"][i]["cardio"]["distance"]["completed"] = 0;
                        this.daysdata[k]["days"][i]["cardio"]["cardioStatus"] = "new";
                        this.daysdata[k]["days"][i]["cardio"]["cardioComments"] = [];
                        this.daysdata[k]["days"][i]["cardio"]["metrics"] = "Minutes";
                      } else {
                        this.daysdata[k]["days"][i]["cardio"] = {}
                      }
                    }
                    else {
                      this.daysdata[k]["days"][i]["cardio"] = {}
                    }
                  }
                  this.daysdata[k]["days"][i]["progress_photo"] = this.isProgressCk;
                }
              }
            }
          } else {
            var dayObj = {};
            dayObj["day"] = this.day;
            dayObj["dayStatus"] = "new";
            dayObj["dayPercentage"] = "0";
            dayObj["mealplan"] = {};
            dayObj["schedular"] = [];

            if (this.SelectedAsanas != undefined) {
              if (this.SelectedAsanas.length != 0) {
                var asanaSelected = [];
                for (var a = 0; a < this.SelectedAsanas.length; a++) {
                  var current_asana = {};
                  current_asana = this.masterAsanas.find(x => x["asanaId"] == [this.SelectedAsanas[a]["asanaId"]]);
                  asanaSelected.push(JSON.parse(JSON.stringify(current_asana)));
                }
                dayObj["asanas"] = asanaSelected;
              } else {
                dayObj["asanas"] = []
              }
            }
            else {
              dayObj["asanas"] = []
            }

            if (this.SelectedCardio != undefined) {
              if (this.SelectedCardio.length != 0) {
                dayObj["cardio"] = this.cardiodata.find(x => x["id"] == [this.SelectedCardio[arrayIndex].id]);
                dayObj["cardio"]["distance"] = {};
                dayObj["cardio"]["distance"]["actual"] = this.distance;
                dayObj["cardio"]["distance"]["completed"] = 0;
                dayObj["cardio"]["cardioStatus"] = "new";
                dayObj["cardio"]["cardioComments"] = [];
                dayObj["cardio"]["metrics"] = "Minutes";
              } else {
                dayObj["cardio"] = {}
              }
            }
            else {
              dayObj["cardio"] = {}
            }
            // Obj["cardio"] = this.SelectedCardio[arrayIndex];
            dayObj["rest"] = this.isRestCk;
            dayObj["progress_photo"] = this.isProgressCk;
            // if (this.rest == true) {
            //   dayObj["rest"] = "Rest Day";
            // }
            this.daysdata[k]["days"].push(JSON.parse(JSON.stringify(dayObj)));
          }
        }
      }
    }
    if (this.Selectedweek != undefined) {
      if (this.Selectedweek.length != 0) {
        this.sweek = (this.Selectedweek[arrayIndex]);
        var w = this.sweek.split(" ");
        var untilWeek = JSON.parse(w[1]);
        var repeatWeek = JSON.parse(this.Selectedrepeat[arrayIndex].split(" week")[0]);
        for (let j = this.week; j <= (this.calenderWeeks * this.currentPlan); j++) {
          var nextWeek = j;
          if (nextWeek <= untilWeek) {
            for (let m = 0; m < this.selectedOptions.length; m++) {
              var selDay = ((nextWeek - 1) * 7) + this.selectedOptions[m];
              var selWeekCheck = this.daysdata.find(x => x["week"] == nextWeek);
              var selDayCheck = undefined;

              for (var n = 0; n < this.daysdata.length; n++) {
                if (selDayCheck == undefined) {
                  var checkData = this.daysdata[n]["days"];
                  selDayCheck = checkData.find(x => x["day"] == selDay);
                }
              }
              if (selWeekCheck == undefined) {
                var wObj = {}
                var dArray = [];
                var nObj = {};
                nObj["day"] = selDay;
                nObj["dayStatus"] = "new";
                nObj["dayPercentage"] = "0";
                nObj["mealplan"] = {};
                nObj["schedular"] = [];

                if (this.SelectedAsanas != undefined) {
                  if (this.SelectedAsanas.length != 0) {
                    var asanasSelected = [];
                    for (var a = 0; a < this.SelectedAsanas.length; a++) {
                      var current_asana = {};
                      current_asana = this.masterAsanas.find(x => x["asanaId"] == [this.SelectedAsanas[a]["asanaId"]]);
                    
                      asanasSelected.push(JSON.parse(JSON.stringify(current_asana)));
                    }
                    nObj["asanas"] = asanasSelected;
                  } else {
                    nObj["asanas"] = []
                  }
                }
                else {
                  nObj["asanas"] = []
                }

                if (this.SelectedCardio != undefined) {
                  if (this.SelectedCardio.length != 0) {
                    nObj["cardio"] = this.cardiodata.find(x => x["id"] == [this.SelectedCardio[arrayIndex].id]);
                    nObj["cardio"]["distance"] = {};
                    nObj["cardio"]["distance"]["actual"] = this.distance;
                    nObj["cardio"]["distance"]["completed"] = 0;
                    nObj["cardio"]["cardioStatus"] = "new";
                    nObj["cardio"]["cardioComments"] = [];
                    nObj["cardio"]["metrics"] = "Minutes";
                  } else {
                    nObj["cardio"] = {}
                  }
                }
                else {
                  nObj["cardio"] = {}
                }
                // newObj["cardio"] = this.SelectedCardio[arrayIndex];
                nObj["rest"] = this.isRestCk;
                // if (this.rest == true) {
                //   nObj["rest"] = "Rest Day";
                // }
                nObj["progress_photo"] = this.isProgressCk;
                dArray.push(nObj);
                wObj["week"] = nextWeek;
                wObj["weekStatus"] = "new";
                wObj["weekPercentage"] = "0";

                wObj["days"] = dArray;
                this.daysdata.push(wObj)
              } else {
                for (let q = 0; q < this.daysdata.length; q++) {
                  if (this.daysdata[q]["week"] == nextWeek) {
                    if (selDayCheck == undefined) {
                      var newObj = {};
                      newObj["day"] = selDay;
                      newObj["dayStatus"] = "new";
                      newObj["dayPercentage"] = "0";
                      newObj["mealplan"] = {};
                      newObj["schedular"] = [];

                      if (this.SelectedAsanas != undefined) {
                        if (this.SelectedAsanas.length != 0) {
                          var asanasSelected = [];
                          for (var a = 0; a < this.SelectedAsanas.length; a++) {
                            var current_asana = {};
                            current_asana = this.masterAsanas.find(x => x["asanaId"] == [this.SelectedAsanas[a]["asanaId"]]);
                           
                            asanasSelected.push(JSON.parse(JSON.stringify(current_asana)));
                          }
                          newObj["asanas"] = asanasSelected;
                        } else {
                          newObj["asanas"] = []
                        }
                      }
                      else {
                        newObj["asanas"] = []
                      }

                      if (this.SelectedCardio != undefined) {
                        if (this.SelectedCardio.length != 0) {
                          newObj["cardio"] = this.cardiodata.find(x => x["id"] == [this.SelectedCardio[arrayIndex].id]);
                          newObj["cardio"]["distance"] = {};
                          newObj["cardio"]["distance"]["actual"] = this.distance;
                          newObj["cardio"]["distance"]["completed"] = 0;
                          newObj["cardio"]["cardioStatus"] = "new";
                          newObj["cardio"]["cardioComments"] = [];
                          newObj["cardio"]["metrics"] = "Minutes";
                        } else {
                          newObj["cardio"] = {}
                        }
                      }
                      else {
                        newObj["cardio"] = {}
                      }

                      newObj["rest"] = this.isRestCk;

                      newObj["progress_photo"] = this.isProgressCk;
                      this.daysdata[q]["days"].push(JSON.parse(JSON.stringify(newObj)));
                    } else {
                      for (let p = 0; p < this.daysdata[q]["days"].length; p++) {
                        if (this.daysdata[q]["days"][p]["day"] == selDay) {
                          var newObj = {};
                          newObj["day"] = selDay;
                          newObj["dayStatus"] = "new";
                          newObj["dayPercentage"] = "0";
                          newObj["mealplan"] = {};
                          newObj["schedular"] = [];

                          if (this.SelectedAsanas != undefined) {
                            if (this.SelectedAsanas.length != 0) {
                              var asanasSelected = [];
                              for (var a = 0; a < this.SelectedAsanas.length; a++) {
                                var current_asana = {};
                                current_asana = this.masterAsanas.find(x => x["asanaId"] == [this.SelectedAsanas[a]["asanaId"]]);
                                
                                asanasSelected.push(JSON.parse(JSON.stringify(current_asana)));
                              }
                              newObj["asanas"] = asanasSelected;
                            } else {
                              newObj["asanas"] = []
                            }
                          }
                          else {
                            newObj["asanas"] = []
                          }
                          if (this.SelectedCardio != undefined) {
                            if (this.SelectedCardio.length != 0) {
                              newObj["cardio"] = this.cardiodata.find(x => x["id"] == [this.SelectedCardio[arrayIndex].id]);
                              newObj["cardio"]["distance"] = {};
                              newObj["cardio"]["distance"]["actual"] = this.distance;
                              newObj["cardio"]["distance"]["completed"] = 0;
                              newObj["cardio"]["cardioStatus"] = "new";
                              newObj["cardio"]["cardioComments"] = [];
                              newObj["cardio"]["metrics"] = "Minutes";
                            } else {
                              newObj["cardio"] = {}
                            }
                          }
                          else {
                            newObj["cardio"] = {}
                          }
                          newObj["progress_photo"] = this.isProgressCk;
                          newObj["rest"] = this.isRestCk;
                          var pushObj = JSON.parse(JSON.stringify(newObj));
                          this.daysdata[q]["days"][p] = pushObj;
                        }
                      }
                    }
                  }
                }
              }
            }
            j = j + repeatWeek - 1;
          }
        }
      }
    }
    this.calender.weeks = this.daysdata;
    if (this.calender.calendarId == undefined) {
      var index = '/calendar'
      this.yoga_db_service.saveDataToDB(this.calender, index).subscribe(
        response => {
          this.getCalenderDataFromServer();
          this.showEventPopup = false;
          this.cardio = "";
          this.renderer.removeClass(document.body, 'modal-open');
          this.toastr.success('', 'Data saved successfully.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        },
        error => {
          this.toastr.error('', 'There is an error while saving data', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        })
    } else {
      var index = '/calendar'
      this.yoga_db_service.updateDatainDB(this.calender, index).subscribe(
        response => {
          this.showEventPopup = false;
          this.cardio = "";
          this.renderer.removeClass(document.body, 'modal-open');
          this.toastr.success('', 'Data saved successfully.', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        },
        error => {
          this.toastr.error('', 'There is an error while saving data', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true,
          });
        })
    }
  }
  
  showFields(event, type) {
    //var val = this.isAsanaCk;
    if (this.isAsanaCk == true && type == "asana") {
      this.rest = false;
      this.isRestCk = false;
    } if (this.isAsanaCk == false && type == "asana") {
      this.rest = false;
      this.SelectedAsanas = [];
    } if (this.isCardioCk == true && type == "cardio") {
      this.rest = false;
      this.isRestCk = false;
    } if (this.isCardioCk == false && type == "cardio") {
      this.rest = false;
      this.cardio = "";
    }

    //  if (this.isRepeatCk == true && type == "repeat") {
    //   this.showRepeat = true;
    // }
    if (this.isRepeatCk == false && type == "repeat") {
      this.calendarWeekNamesStr = [];
      this.Selectedrepeat = [];
      this.Selectedweek = [];

    }
    if (this.isRestCk == true && type == "rest") {
      // this.showCardio = false;
      // this.showWorkout = false;
      this.SelectedAsanas = [];
      this.SelectedCardio = [];
      this.cardio = "";
      this.rest = true;
      this.isAsanaCk = false;
      this.isCardioCk = false;
    }
  }
  openVideo(exerciseId) {
    for (let i = 0; i < this.droppedItems.length; i++) {
      if (exerciseId == this.droppedItems[i]["exerciseId"]) {
        if (this.droppedItems[i]["exerciseVideo"]["youtubeUrl"] == "") {
          this.viewURL = this.droppedItems[i]["exerciseVideo"]["exerciseVideoSource"];
          this.iframeHidden = false;
          this.videoHidden = true;
          this.viewworkLoad.nativeElement.load();
          this.viewworkLoad.nativeElement.play();
        } else {
          if (this.viewWorkout == false) {
            this.iframeURL = this.embedService.embed(this.droppedItems[i]["exerciseVideo"]["youtubeUrl"], {
              query: { portrait: 0, color: 'black', autoplay: 1 },
              // attr: { width: 450, height: 250, frameborder: 0, allow: "autoplay" }
              attr: { width: "100%", height: 250, frameborder: 0, allow: "autoplay" }
            })
          } else {
            this.iframeURL = this.embedService.embed(this.droppedItems[i]["exerciseVideo"]["youtubeUrl"], {
              query: { portrait: 0, color: 'black', autoplay: 1 },
              // attr: { width: 450, height: 250, frameborder: 0, allow: "autoplay" }
              attr: { width: "100%", height: 250, frameborder: 0, allow: "autoplay" }
            })
          }
          this.iframeHidden = true;
          this.videoHidden = false;
          if (this.iframeURL != undefined)
            this.embedYoutubeUrl = this.iframeURL;
        }
        this.Instructions = this.droppedItems[i]["instructions"];
      }
    }

  }
  weekCaluculator(day) {
    var weeknumber = 0;
    if (day <= 7) {
      weeknumber = 1;
    }
    else if (day % 7 === 0) {
      weeknumber = day / 7;
    } else {
      weeknumber = Math.floor(day / 7) + 1;
      // weeknumber = JSON.parse(weeknumber);
    }
    return weeknumber;
  }
  // Calendar Code
  // this method is to generate calendar as per next plan
  onNxtPlan() {
    this.currentPlan += 1;
    this.calendarDaysArr.length = 0;  // making day calendar null
    this.calendarWeekNames.length = 0;  // making week calendar null
    this.calendarDaysStrArr = [];
    this.generateCalendar();  // genrating calendar days
    // this.currentPlan = this.currentPlan++;  // on next incrementing plan
  }

  // this method is for genration of calendar for previous plan
  onPrevPlan() {
    this.currentPlan -= 1;
    this.calendarDaysArr.length = 0;
    this.calendarWeekNames.length = 0;  // making week calendar null
    this.calendarDaysStrArr = [];
    this.generateCalendar();

  }

  // in this method we can find genration of days and pushing them into array as per plan
  generateCalendar() {
    // this.currentPlan = this.currentPlan;
    this.totalDay();  // this method is for creation of of days as per plan
    // console.log("Calendar Days" +this.calendarDays);
    for (var i = 1; i <= this.calendarDays; i++) {
      if (this.currentPlan > 1 && i > this.currentDay) {
        this.calendarDay = i;
        this.calendarDaysArr.push(this.calendarDay);
        this.calendarDaysStrArr.push("Day " + this.calendarDay);
      }
      if (this.currentPlan == 1) {
        this.calendarDay = i;
        this.calendarDaysArr.push(this.calendarDay);
        this.calendarDaysStrArr.push("Day " + this.calendarDay);
      }

    }
    for (var j = 1; j <= this.currentWeeks; j++) {
      if (this.currentPlan > 1 && j > this.currentWeek) {
        // this.calendarDay = i;
        this.calendarWeek = j;
        this.calendarWeekNames.push(this.calendarWeek);
        // this.calendarWeekNamesStr.push("Week "+this.calendarWeek);
      }
      if (this.currentPlan == 1) {
        // this.calendarDay = ;
        this.calendarWeek = j;
        this.calendarWeekNames.push(this.calendarWeek);
        // this.calendarWeekNamesStr.push("Week "+this.calendarWeek);
      }
    }
    // this.currentDay = this.calendarDay;
    // console.log('Current Day : ' +this.currentDay);
    // console.log("Days Array : " + this.calendarDaysArr);
  }
  totalDay() {
    if (this.currentPlan > 1) {
      this.currentDay = (this.currentPlan - 1) * this.calenderWeeks * 7;
      this.currentWeek = (this.currentPlan * this.calenderWeeks) - this.calenderWeeks;
    }
    else {
      this.currentDay = this.currentPlan * this.calenderWeeks * 7;
      this.currentWeek = this.calenderWeeks;
    }
    this.calendarDays = this.currentPlan * this.calenderWeeks * 7;
    this.currentWeeks = this.currentPlan * this.calenderWeeks;
  }

  closePopup() {
    this.embedYoutubeUrl == '';
    this.showEventPopup = false;
    this.showWorkOtPopup = false;
    this.showWorkoutsPopup = false;
    this.asanaCheck = false;
    this.calendarWeekNamesStr = [];
    this.renderer.removeClass(document.body, 'modal-open');

  }
}
